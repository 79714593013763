import "./LinkAccount.css";
import React, { useState, useEffect } from "react";
import ERCLogo from "../../../assets/logo.png";
import BCE2mob from "../../../assets/loginBCMob2.png";
import BCE2 from "../../../assets/loginBC.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DoneModal from "../../Auth/loginSignPage/doneModal/DoneModal";
import { useDispatch } from "react-redux";
import { linkLocalAidRequest, localAidSendOtp, confirmOtpLocalAidLink } from "../../../api/index";
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";

var personId;
const LinkAccount = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [firstData, setFirstData] = useState({
    account_link_email: userLogin?.Results[0]?.email,
    eid: "",
  });
  const [mobile, setMobile] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  useEffect(() => {
    if (width > 1200) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const openFromLog = () => {
    history.push("/");
  };

  const checkingIFheHadAccount = () => {
    setLoading(true);
    if (firstData.eid == "" || firstData.eid == null) {
      setLoading(false);
      toastifyMessage("Please enter your Emirates ID", undefined, "warning");
      return false;
    }
    linkLocalAidRequest(firstData).then(res => {
      if (res.data.status == true) {
        personId = res.data.data.local_aid_person_id;
        setMobile(res.data.data.mobile_no);
        localAidSendOtp(userLogin?.Results[0]?.email, personId).then(res => {
          setLoading(false);
          if (res.data.status == true)
            setOtpSent(true);
          else
            toastifyMessage("Something went wrong...!", res.data.result, "error");
        })

      }
      else {
        setLoading(false);
        toastifyMessage("Something went wrong...!", res.data.result, "error");
      }
    })

  };

  const confirmOtp = () => {
    setLoading(true);
    if (otp===''||otp===null) {
      return toastifyMessage("Please insert the OTP", res.data.result, undefined, "warning");
    }
    confirmOtpLocalAidLink(userLogin?.Results[0]?.email, personId, otp).then(res => {
      setLoading(false);
      if (res.data.status == true) {
        setOtp("");
        setFirstData({
          ...firstData,
          eid: "",
        });
        setOtpSent(false);
        toastifyMessage("Success!", undefined, "success");
        localStorage.removeItem("userLogin")
        localStorage.setItem("userLogin", JSON.stringify({Results:[res.data.data]}));
        history.push('/profile')
      }
      else
        toastifyMessage("Something went wrong...!", res.data.result, undefined, "error");
    })
  }

  return (
    <div
      id="login_sign"
      style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", justifyContent:'flex-start' }}
    >
      <ShareHeader noTitle={true} noShare={true} forAuth={true}/>
      <div className="NeedToOtp">
        <img
          src={ERCLogo}
          onClick={openFromLog}
          alt="ERC_logo"
          className="ERC_logo_login_sign"
        />
        <h1 style={lng === "arab" ? { direction: "rtl" } : null}>
          {t("Welcome")}
        </h1>

        <React.Fragment>
          <h3 style={lng === "arab" ? { direction: "rtl" } : null}>
            {t("Please Enter Your Emirates ID To Get Your Account.")}
          </h3>

          <div className="vln_linking_wraper">
            <input
              className="vln_linking_input"
              placeholder="784xxxxxxxxxxxx"
              type="text"
              maxLength='15'
              disabled={otpSent}
              value={firstData.eid}
              onChange={(e) =>
                setFirstData({
                  ...firstData,
                  eid: e.target.value.replace(/\D/, ""),
                })
              }
            />


            {otpSent && <input
              className="vln_linking_input"
              placeholder="OTP"
              type="text"
              value={otp}
              onChange={(e) =>
                setOtp(e.target.value.replace(/\D/, ""))
              }
            />}

            {!loading ? (<button
              onClick={otpSent ? confirmOtp : checkingIFheHadAccount}
              style={{ borderRadius: "10px" }}
              className="confirm_otp"
            >
              {otpSent ? t("Submit") : t("Search")}
            </button>) : <button style={{ borderRadius: "10px" }}
              className="confirm_otp">{t("Loading...")}</button>}
            {otpSent && <p>Otp sent to {mobile.slice(0,5)+'***'+mobile.slice(8)}</p>}
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default LinkAccount;
