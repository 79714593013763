import React, { useEffect, useRef, useState } from 'react';
import './SwitchSponsers.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import StoryComponentOrphan from '../StoryComponentOrphan/StoryComponentOrphan';
import samsungPay from '../../../assets/samsungpay.png';
import applePay from '../../../assets/applePay.png';
import pdfImage from '../../../assets/picture_as_pdf.png';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSPOrphAllLatepayments } from '../../../actions/user';
import editIcon from '../../../assets/pen-to-square-solid.svg';
import { setOrphansAmount } from '../../../api';
import { FETCH_ALL_ORPHONS } from '../../../constants/actionTypes';
import { getSPFollowups, getSPOrphonsList } from '../../../actions/user';
import Loading from '../../ui/loading';
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import OrphanPaymentModal from './OrphanPaymentModal/OrphanPaymentModal';
import OrphanConfirmationModel from './OrphanConfirmationModel/OrphanConfirmationModel';
import pdf_icons from '../../../assets/pdf_1.svg';
import pdf_download from '../../../assets/ped_download.svg';
import { Autoplay, Pagination } from 'swiper';
import pdf_image from '../../../assets/pexels-hemin-suthar-4727408.jpg';
import SPPDF from '../../sponsership_pdf/SPPDF';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const SwitchSponsers = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const followUpLoading = useSelector((state) => {
    return state.auth.sponsorFollowUpLoading;
  });
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  const followupsReport = useSelector((state) => {
    return state.auth.followupsReports;
  });
  const latePaymentsState = useSelector(
    (state) => state.latePayments.orplatePayments
  );
  const loadingLatePayment = useSelector(
    (state) => state.latePayments.latePaymentLoading
  );
  const [addCardModal, setAddCardModal] = useState(false);
  const [followups, setTFollowups] = useState([]);
  const [displayedItem, setDisplayedItem] = useState('');
  const [view, setView] = useState(5);
  const [view2, setView2] = useState(5);
  const [selectedItem, setSelectedItem] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [editOrphanAmount, setEditOrphanAmount] = useState(false);
  const [orphansAmount, setOrphanAmount] = useState('');
  const [loadingOrphansAmount, setLoadingOrphansAmount] = useState(false);
  const [orphonsList, setorphonsList] = useState([]);
  const [confirmationModal, setConfirmationModel] = useState(false);

  SwiperCore.use([Navigation]);

  const breakpoints = {
    // Breakpoint for small screens
    768: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // Breakpoint for medium screens
    1024: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    // Breakpoint for large screens
    1440: {
      slidesPerView: 3,
      spaceBetween: 40
    }
  };

  useEffect(() => {
    setorphonsList(props.orphonsList);
  }, [props.orphonsList]);
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  useEffect(() => {
    if (followupsReport) {
      setTFollowups(followupsReport);
      if (followupsReport && followupsReport.length > 0) {
        setDisplayedItem(followupsReport[0].REP_SERIAL);
      }
    }
  }, [followupsReport]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth <= 900) {
      setView2(3);
    } else {
      setView2(4.8);
    }
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView(15);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(11);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(8);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(6);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(4.5);
    } else if (window.innerWidth > 700 && window.innerWidth <= 1111) {
      setView(4.5);
    } else if (window.innerWidth > 500 && window.innerWidth <= 700) {
      setView(3.2);
    } else if (window.innerWidth > 300 && window.innerWidth <= 500) {
      setView(2.5);
    } else {
      setView(1.8);
    }
  }, [width]);

  function getSelectedOrphon() {
    if (orphonsList && selectedItem) {
      return orphonsList?.find((orph) => orph.orp_id == selectedItem);
    }
  }

  useEffect(() => {
    if (orphonsList.length > 0) {
      setSelectedItem(orphonsList.slice(0).shift()?.orp_id);
      setOrphanAmount(orphonsList.slice(0).shift()?.trn_amount);
    }
  }, [orphonsList]);
  useEffect(() => {
    if (selectedItem) {
      dispatch(
        getSPFollowups(
          sponserId()?.sponser_id,
          lng === 'arab' ? 'ARA' : 'ENG',
          selectedItem
        )
      );
    }
  }, [selectedItem]);

  const handleDonationNavi = (str) => {
    setDisplayedItem(str);
  };

  useEffect(() => {
    if (selectedItem) {
      dispatch(
        getSPOrphAllLatepayments(
          sponserId()?.sponser_id,
          lng === 'arab' ? '1' : '2',
          selectedItem
        )
      );
    }
  }, [selectedItem]);
  const confirmEdit = () => {
    setConfirmationModel(false);
    setLoadingOrphansAmount(true);
    setOrphansAmount(
      sponserId()?.sponser_id,
      selectedItem,
      orphansAmount,
      lng === 'arab' ? 1 : 2
    )
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: FETCH_ALL_ORPHONS, payload: res.data.data });
          setLoadingOrphansAmount(false);
          toastifyMessage('Modified!', undefined, 'success');
        } else {
          setLoadingOrphansAmount(false);
          toastifyMessage(res.data.result, undefined, 'error');
        }
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          setLoadingOrphansAmount(false);
          toastifyMessage('Something went wrong...!', undefined, 'error');
        }
      })
      .finally(() => {
        setLoadingOrphansAmount(false);
      });
    setEditOrphanAmount(false);
  };

  const doneEditing = () => {
    setConfirmationModel(true);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setEditOrphanAmount(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  const sppdfRef = useRef();
  useOutsideAlerter(wrapperRef);
  const downloadPDF = async () => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.onload = async () => {
        try {
          // Wait for the iframe content to be fully loaded
          if (iframe.contentDocument) {
            const canvas = await html2canvas(iframe.contentDocument.body);
            const imgData = canvas.toDataURL('image/png');

            // Create a new PDF document
            const pdf = new jsPDF('p', 'pt', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 595, 842); // Adjust dimensions as needed
            pdf.save('document.pdf');
          } else {
            console.error('Iframe content is not accessible.');
          }
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };

      // If iframe is already loaded
      if (
        iframe.contentDocument &&
        iframe.contentDocument.readyState === 'complete'
      ) {
        iframe.onload(); // Manually trigger onload
      }
    }
  };
  return (
    <div className="switch_sponsers">
      {props.loadingState ? (
        <Loading height="85vh" />
      ) : (
        <React.Fragment>
          <div
            style={
              lng === 'arab'
                ? {
                    direction: 'rtl',
                    width: '100%',
                    zIndex: props.selectModeSwitch ? '1000001' : null
                  }
                : {
                    width: '100%',
                    zIndex: props.selectModeSwitch ? '1000001' : null
                  }
            }
          >
            <React.Fragment>
              <Swiper
                loop={orphonsList?.length > view ? true : false}
                slidesPerView={view}
              >
                {orphonsList?.map((orphon, index) => (
                  <SwiperSlide key={index}>
                    <StoryComponentOrphan
                      key={index}
                      itemId={orphon.orp_id}
                      img={orphon.orp_pic}
                      title={orphon.orp_name}
                      amount={orphon.trn_amount}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                      setOrphanAmount={setOrphanAmount}
                      selectModeSwitch={props.selectModeSwitch}
                      setItemSelectedMode={props.setItemSelectedMode}
                      itemSelectedMode={props.itemSelectedMode}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </React.Fragment>
          </div>
          {/* start */}
          {/* <div style={{ width: "100%" }}> */}
          {followUpLoading ? (
            <Loading height="500px" />
          ) : (
            <React.Fragment>
              {/* <div
            className="reports_dates_wraper"
            style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
          >
            <label style={{ fontWeight: "bold" }}>{t("Reports")}</label>
            <div
              className="switch_donation_header_real"
              style={{
                width: "100%",
                padding: "10px 20px 10px 20px",
                boxShadow: "none",
                height: "auto",
                padding: "0px",
              }}
            >
              <React.Fragment>
                {followups.length > 0?
                <Swiper loop={followups?.length > view2? true:false}
                className="swiper_forDonations2"
                slidesPerView={view2}
                navigation={{
                  prevEl: '.prevCustom',
                  nextEl: '.nextCustom',
                }}
                style={{width: width>1150? "80%":'100%', display:'flex', justifyContent: "space-between", alignItems:'center', marginRight:'15px' }}
              >
                <div className="prevCustom">
                <i className="fas fa-chevron-left"></i>
                </div>
                {followups?.map((followup, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <button
                        key={followup.REP_SERIAL}
                        id={followup.REP_SERIAL}
                        onClick={() => handleDonationNavi(followup.REP_SERIAL)}
                        className="month_year_item payment_numbers"
                        style={{backgroundColor:displayedItem===followup.REP_SERIAL? '#ee4236':'transparent', color:displayedItem===followup.REP_SERIAL? 'white':'black'}}
                      >
                        {followup.REP_DATE.split(" ")[0]}
                      </button>
                    </SwiperSlide>

                  );
                })}
                
                <div className="nextCustom">
                <i className="fas fa-chevron-right"></i>
                </div>
              </Swiper>: <>{t("No reports found")}</>}
              </React.Fragment>
            </div>
          </div> */}

              {getSelectedOrphon()?.orp_id ? (
                <div
                  className="two_btns"
                  style={{ zIndex: props.selectModeSwitch ? '1000001' : null }}
                >
                  <button
                    disabled={
                      orphonsList.filter(
                        (item) =>
                          item.can_gift === false &&
                          item.orp_id === getSelectedOrphon()?.orp_id
                      ).length > 0 || loadingLatePayment
                    }
                    className="send_gifts_support"
                    onClick={() =>
                      props.selectModeSwitch
                        ? props.itemSelectedMode.length === 0
                          ? toastifyMessage(
                              'Please choose an orphan',
                              undefined,
                              'warning'
                            )
                          : history.push(
                              '/sendgift?orphon_id=' +
                                getSelectedOrphon()?.orp_id +
                                '&sponsor_id=' +
                                sponserId().sponser_id,
                              {
                                itemSelectedMode: props.itemSelectedMode,
                                orphonsList: orphonsList,
                                str: 'many'
                              }
                            )
                        : history.push(
                            '/sendgift?orphon_id=' +
                              getSelectedOrphon()?.orp_id +
                              '&sponsor_id=' +
                              sponserId().sponser_id
                          )
                    }
                  >
                    {orphonsList.filter(
                      (item) =>
                        item.can_gift === false &&
                        item.orp_id === getSelectedOrphon()?.orp_id
                    ).length > 0
                      ? t(
                          "You can't send gifts if you have pending late payments"
                        )
                      : t(`Send Gifts/Support`)}
                  </button>
                  <button
                    disabled={
                      latePaymentsState.length === 0 &&
                      getSelectedOrphon().pay_mthd === '3' &&
                      !props.selectModeSwitch
                    }
                    onClick={() =>
                      props.selectModeSwitch
                        ? props.itemSelectedMode.length === 0
                          ? toastifyMessage(
                              'Please choose an orphan',
                              undefined,
                              'warning'
                            )
                          : history.push(
                              `/renewals?orphon_id=${
                                props.itemSelectedMode[0]
                              }${props.itemSelectedMode
                                .slice(1)
                                .map(
                                  (item) => '&orphon_id=' + item
                                )}&sponsor_id=${
                                sponserId().sponser_id
                              }&many=true&type=${getSelectedOrphon().pay_mthd}`,
                              {
                                itemSelectedMode: props.itemSelectedMode,
                                orphonsList: orphonsList,
                                str: 'monthly'
                              }
                            )
                        : getSelectedOrphon().pay_mthd === '3'
                        ? history.push(
                            `/renewals?orphon_id=${
                              getSelectedOrphon()?.orp_id
                            }&sponsor_id=${sponserId().sponser_id}&type=${
                              getSelectedOrphon().pay_mthd
                            }`,
                            {
                              orphon: getSelectedOrphon(),
                              sponsorId: sponserId().sponser_id
                            }
                          )
                        : history.push(
                            `/renewals?orphon_id=${
                              getSelectedOrphon()?.orp_id
                            }&sponsor_id=${sponserId().sponser_id}&type=${
                              getSelectedOrphon().pay_mthd
                            }`,
                            {
                              orphon: getSelectedOrphon(),
                              sponsorId: sponserId().sponser_id
                            }
                          )
                    }
                    className="renewPayments"
                  >
                    {getSelectedOrphon().pay_mthd !== '3'
                      ? t('Renew Payments')
                      : latePaymentsState.length === 0 &&
                        getSelectedOrphon().pay_mthd === '3' &&
                        !props.selectModeSwitch
                      ? t("You don't have pending payments")
                      : t('Late Payments')}
                  </button>
                  <button
                    onClick={() =>
                      props.selectModeSwitch
                        ? props.itemSelectedMode.length === 0
                          ? toastifyMessage(
                              'Please choose an orphan',
                              undefined,
                              'warning'
                            )
                          : history.push(`/transaction-history`, {
                              myorphons: props.myorphons,
                              sponsor_id: sponserId(),
                              itemSelectedMode: props.itemSelectedMode,
                              orphonsList: orphonsList
                            })
                        : history.push(`/transaction-history`, {
                            myorphons: props.myorphons,
                            itemSelectedMode: [getSelectedOrphon()?.orp_id],
                            sponsor_id: sponserId().sponser_id,
                            orphonsList: orphonsList
                          })
                    }
                    className="renewPayments"
                  >
                    {t('History')}
                  </button>
                </div>
              ) : null}
              {getSelectedOrphon()?.orp_id ? (
                <div
                  className="sponsership_details_display sponcer_main"
                  style={{
                    ...(lng === 'arab'
                      ? {
                          direction: 'rtl',
                          marginTop:
                            window.innerWidth < 1500 ? '10rem' : '20rem'
                        }
                      : {})
                  }}
                >
                  <div className="pdf_view">
                    {followups.length > 0 ? (
                      <div className="sponcer_view">
                        <iframe
                          src={`/sponsership/followups?orphon_id=${
                            getSelectedOrphon()?.orp_id
                          }&language=${
                            lng === 'arab' ? 'AR' : 'EN'
                          }&sponser_id=${
                            sponserId().sponser_id
                          }&repSerial=${displayedItem}&hide-footer=true`}
                          width="100%"
                          height="600px"
                          style={{ border: 'none' }}
                        >
                          Your browser does not support PDFs.
                        </iframe>
                        {/* <img src={pdf_image} alt="image" className="pdf_images" /> */}
                        <div className="child_pdf_main">
                          <div className="pdf_btns">
                            <img src={pdf_icons} alt="pdf_icons" />
                            <p>PDF Preview</p>
                          </div>
                          <div className="pdf_btn" onClick={downloadPDF}>
                            <img src={pdf_download} alt="pdf_downlaod" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="child_pdf_main">
                        <div className="pdf_btns">
                          <p>Report Not Found</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="onRow">
                    <div className="sponsership_info_pdf_wraper">
                      <div
                        style={{ borderBottom: '1px solid gray' }}
                        className="sponsership_info_title"
                      >
                        <label> {getSelectedOrphon()?.orp_name}</label>

                        <button
                          className="pdf_downloader"
                          onClick={() => {
                            followups.length === 0 && !props.selectModeSwitch
                              ? null
                              : history.push(
                                  `/sponsership/followups?orphon_id=${
                                    getSelectedOrphon()?.orp_id
                                  }&language=${
                                    lng === 'arab' ? 'AR' : 'EN'
                                  }&sponser_id=${
                                    sponserId().sponser_id
                                  }&repSerial=${displayedItem}`,
                                  { orphonsList, followups }
                                );
                            // window.open(
                            //   `/sponsership/followups?orphon_id=${getSelectedOrphon()?.orp_id
                            //   }&language=${lng === "arab" ? "AR" : "EN"}&sponser_id=${sponserId().sponser_id
                            //   }&repSerial=${displayedItem}`
                            // );
                          }}
                        >
                          {/* <i className="far fa-file-pdf"></i> */}
                          <img
                            style={{
                              width: '20px',
                              height: '10%',
                              display: followups.length > 0 ? 'inline' : 'none'
                            }}
                            src={pdfImage}
                          />
                        </button>
                      </div>
                      <div className="sponsership_info_pdf_body">
                        <table>
                          <tbody>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Beneficiary No.')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.orp_id}
                              </td>
                            </tr>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Nationality')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.nationality}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Date of Birth')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.DOB}
                              </td>
                            </tr>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Country of Residence')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.country_name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Gender')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.gender}
                              </td>
                            </tr>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Sponsor Date')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.trn_date}
                              </td>
                            </tr>
                            <tr
                              style={{
                                textAlign: 'center',
                                borderBottom: 'none'
                              }}
                            >
                              <td
                                className="sponsershipTableRecord"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'right'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign: 'left'
                                      }
                                }
                              >
                                {t('Transaction End Date')}:
                              </td>
                              <td
                                className="sponsershipTableRecord payment_numbers"
                                style={
                                  lng === 'arab'
                                    ? {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'left' : 'right',
                                        color: '#ee4236'
                                      }
                                    : {
                                        padding: '20px 10px 10px 10px',
                                        fontSize: '15px',
                                        textAlign:
                                          width <= 300 ? 'right' : 'left',
                                        color: '#ee4236'
                                      }
                                }
                              >
                                {getSelectedOrphon()?.trn_end_date ??
                                  getSelectedOrphon()?.vouch_date}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{
                        zIndex: props.selectModeSwitch ? '1000001' : null,
                        backgroundColor: props.selectModeSwitch ? 'white' : null
                      }}
                      className="sponsership_info_pdf_wraper"
                    >
                      <div
                        style={{ borderBottom: '1px solid gray' }}
                        className="sponsership_info_title"
                      >
                        <label>{t('Payment details')}</label>
                      </div>
                      <div className="orphans_payment_details_wraper">
                        <div
                          style={{
                            display: props.selectModeSwitch ? 'none' : 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                            gap: '10px'
                          }}
                          ref={wrapperRef}
                        >
                          <label className="sponsershipTableRecord">
                            {t('Payment amount per month')}
                          </label>
                          <div
                            style={{ color: '#ee4236' }}
                            className="payment_number_content"
                          >
                            {loadingOrphansAmount ? (
                              <Loading height="72px" />
                            ) : (
                              <React.Fragment>
                                {editOrphanAmount ? (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                    className="aed_mob"
                                  >
                                    <div
                                      className="columnFlex"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '2px'
                                      }}
                                    >
                                      <input
                                        className="smallInput_smallDevice"
                                        type="text"
                                        pattern="[0-9]*"
                                        value={orphansAmount}
                                        onChange={(e) =>
                                          setOrphanAmount(
                                            e.target.value.replace(/\D/, '')
                                          )
                                        }
                                      />
                                      <button
                                        onClick={() => doneEditing()}
                                        style={{
                                          width: '50px',
                                          height: '30px',
                                          backgroundColor: '#ee4236',
                                          border: 'none',
                                          color: 'white',
                                          borderRadius: '5px'
                                        }}
                                      >
                                        {t('Done')}
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="payment_numbers"
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '10px'
                                    }}
                                  >
                                    <label
                                      className="textToBeSmall"
                                      style={{
                                        minHeight: '30px',
                                        fontSize: '14px'
                                      }}
                                    >
                                      {getSelectedOrphon().trn_amount}
                                    </label>
                                    <button
                                      onClick={() =>
                                        getSelectedOrphon().pay_mthd !== '2'
                                          ? setEditOrphanAmount(true)
                                          : null
                                      }
                                      className="pdf_downloader"
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit_icon"
                                        style={{
                                          height: '16px',
                                          width: '16px'
                                        }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                        <React.Fragment>
                          {getSelectedOrphon()?.show_payment_details ? (
                            <div className="payment_number">
                              <label className="sponsershipTableRecord">
                                {t('Payment Method')}
                              </label>
                              <div className="payment_number_content">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px'
                                  }}
                                >
                                  {getSelectedOrphon()?.payment_type ===
                                  'CARD' ? (
                                    <label
                                      style={{
                                        direction: 'ltr',
                                        color: '#ee4236',
                                        minHeight: '30px',
                                        fontSize: '14px'
                                      }}
                                      className="payment_numbers textToBeSmall"
                                    >
                                      {getSelectedOrphon()?.payment_details}
                                    </label>
                                  ) : getSelectedOrphon()?.payment_type ===
                                    'APPLE PAY' ? (
                                    <img
                                      src={applePay}
                                      style={{
                                        width: '40px',
                                        height: '30px',
                                        objectFit: 'contain'
                                      }}
                                      alt="donate_btn_icon_banner"
                                    />
                                  ) : (
                                    <img
                                      src={samsungPay}
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        objectFit: 'contain'
                                      }}
                                      alt="donate_btn_icon_banner"
                                    />
                                  )}
                                  {getSelectedOrphon()?.pay_mthd === '3' ? (
                                    <button
                                      onClick={() =>
                                        getSelectedOrphon().pay_mthd === '3'
                                          ? setAddCardModal(true)
                                          : null
                                      }
                                      className="pdf_downloader"
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit_icon"
                                        style={{
                                          height: '16px',
                                          width: '16px'
                                        }}
                                      />
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      </div>
                    </div>
                    {/* <div className="slider_sponcer">
                      <Swiper
                        spaceBetween={20}
                        centeredSlides={false}
                        slidesPerView={1}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        // pagination={{
                        //   clickable: true,
                        // }}
                        // navigation={true}
                        breakpoints={breakpoints}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={pdf_image}
                            alt="pdf_image"
                            className="slider_image"
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                          />
                        </SwiperSlide>
                      </Swiper>
                    </div> */}
                    {/* <div className="date_pdf">
                      <div class="dropdown">
                        <p
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Feb 2023
                        </p>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button class="dropdown-item" type="button">
                            Mar 2023
                          </button>
                          <button class="dropdown-item" type="button">
                            Apr 2023
                          </button>
                          <button class="dropdown-item" type="button">
                            May 2023
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )}
          {/* </div> */}
          <OrphanPaymentModal
            selectmodeswitch={props.selectModeSwitch}
            backdrop="static"
            sponsor_id={sponserId()?.sponser_id}
            oldcard={getSelectedOrphon()?.payment_details}
            orphan_id={
              props.selectModeSwitch
                ? props.itemSelectedMode.map((item) => item)
                : selectedItem
            }
            show={addCardModal}
            onHide={() => setAddCardModal(false)}
          />
          <OrphanConfirmationModel
            backdrop="static"
            show={confirmationModal}
            onHide={() => setConfirmationModel(false)}
            setConfirmationModel={setConfirmationModel}
            confirmEdit={confirmEdit}
          />
        </React.Fragment>
      )}
    </div>
  );
};
export default SwitchSponsers;
