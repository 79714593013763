import { FETCH_ALL_COURSES, LOAD_VOLUNTEER_COURSE, VOLUNTEER_COURSE_LOADED } from '../../constants/actionTypes'

export default (vlnCourses = { vlnCourses: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_COURSES:
            return { ...vlnCourses, vlnCourses: action.payload, loading: false };
        case LOAD_VOLUNTEER_COURSE:
            return { ...vlnCourses, loading: true };
        case VOLUNTEER_COURSE_LOADED:
            return { ...vlnCourses, loading: false };
        default:
            return vlnCourses;
    }
}