import React, {useState, useEffect} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation} from 'swiper'
import { useSelector } from 'react-redux'
import 'swiper/swiper-bundle.css'
import FavoritesProjectComp from '../favoritesProjectComp/FavoritesProjectComp'
import { useTranslation } from "react-i18next";
import { addToFavorites, deleteFromFavorites } from '../../../actions/BagFav'
import { useDispatch } from 'react-redux'
import { FETCH_LOCALLY } from '../../../constants/actionTypes'

const OrphansSection = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    const [addingToFav, setAddingToFav] = useState(false);

    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const [change, setChange] = useState(0)
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    const [view, setView] = useState(5)
    const [width, setWidth] = useState(window.innerWidth);

    const [donationFav, setDonationFav] = useState([])
    useEffect(() => {
        if (userLogin) {
            //let sukukAll = props.favoritesDetails?.donations?.filter(outerItem=> props.favoritesDetails?.sukuks?.findIndex(item=> item.dontypeID===outerItem.dontypeID))
            setDonationFav(props.favoritesDetails?.orphans)
        } else {
            setDonationFav(currentProfile?.liked?.orphans)
        }
      }, [props.favoritesDetails?.orphans, fetchLoaclly]);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(()=> {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(15)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(11)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(8)  
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(6)
        } else if (window.innerWidth > 1300 && window.innerWidth <= 1600) {
            setView(5.8)
        } 
        else if (window.innerWidth > 1000 && window.innerWidth <= 1300) {
            setView(5.5)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(5)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(5) 
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(4)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(2.2)
        } else if (window.innerWidth > 350 && window.innerWidth <= 500) {
            setView(2.2)
        } else if (window.innerWidth > 290 && window.innerWidth <= 350) {
            setView(1.8)
        } else if (window.innerWidth > 220 && window.innerWidth <= 290) {
            setView(1.3)
        } else {
            setView(1)
        }
      }, [width])
      const handleDisLike = async (orphanData) => {
        if (!userLogin) {
            let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations, sukuks: currentProfile.liked.sukuks, projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans.filter(item => !(item.orphanId === orphanData.orphanId && item.orphanCountryId === orphanData.orphanCountryId)) } }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1) 
        } else {
            let elementWeNeed = donationFav?.filter(item => item.orphanTypeID === orphanData.orphanId && item.countryID === orphanData.orphanCountryId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }
    }
    const handleLike = async (orphanData) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile?.liked?.orphans?.push(orphanData)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: '', projectID:'', orptypeID:orphanData.orphanId, projectYear: '', countryID: orphanData.orphanCountryId}))
        }
    }
    return (
        <div className='liked_donation_section'>
                <h4 className='donation_favorites_title'>{t("Orphans")}</h4>
                <hr className='hr'></hr>
                {donationFav?.length > 0 ? 
                <React.Fragment>
                    <Swiper loop={donationFav?.length > view? true:false} slidesPerView={view} navigation={true}>
                        {donationFav?.map((item,i)=> 
                            <SwiperSlide key={i}>
                            <FavoritesProjectComp 
                                handleLike={handleLike} 
                                handleDisLike={handleDisLike}
                                currentProfile={userLogin? donationFav:currentProfile?.liked}  
                                fromOrphans={true}
                                projectId={userLogin? item.orphanTypeID:item.orphanId} 
                                projectAmount= {userLogin? item.baseAmount:item.donationAmountFromUser}
                                projectPhoto={userLogin? item.orpTypeImage:item.orphanImg} 
                                title={userLogin? item.orpTypeName:item.orphanName} 
                                countryTitle={userLogin? item.countryName:item.orphanCountryName} 
                                flag={userLogin? item.countryImage:item.orphanCountryImg}
                                countryId={userLogin? item.countryID:item.orphanCountryId}
                                />
                            </SwiperSlide>
                            )}
                        
                    </Swiper>
                </React.Fragment>
                :
                 <div style={{textAlign:'center',marginTop:'50px', minHeight: '100px'}}>
                     <h5>
                     {t("No Favorites Found")}
                     </h5>
                 </div> 
}
            </div>
    )
}

export default OrphansSection
