import { FETCH_ALL_ATTACHMENTS, LOAD_VOLUNTEER_ATTACHMENT, VOLUNTEER_ATTACHMENT_LOADED } from '../../constants/actionTypes'

export default (vlnAttachments = { vlnAttachments: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_ATTACHMENTS:
            return {
                ...vlnAttachments, vlnAttachments: action.payload, loading: false
            };
        case LOAD_VOLUNTEER_ATTACHMENT:
            return { ...vlnAttachments, loading: true };
        case VOLUNTEER_ATTACHMENT_LOADED:
            return { ...vlnAttachments, loading: false }
        default:
            return vlnAttachments;
    }
}