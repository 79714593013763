import React, { useState, useEffect, useRef } from 'react'
import './donationCom.css'
import donateIcon from '../../../../../assets/donateicon.png'
import donateIconRed from '../../../../../assets/addedBtn.png'
import PopUpPayment from '../../popUpPayment/PopUpPayment'
import { useHistory } from 'react-router-dom'
import { addToFavorites } from '../../../../../actions/BagFav'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
//import Marquee from 'react-fast-marquee'
import Marquee from 'react-double-marquee';
import Default from '../../../../../assets/default.jpg'

const DonationCom = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [donationAmount, setDonationAmount] = useState(parseInt(props.amount));
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [popUpModal, setPopUpModal] = useState(false);
    const history = useHistory();
    const [liked, setLiked] = useState(false)
    const [inTheBag, setInTheBag] = useState(false)
    const userBag = useSelector(state => state.bag.bag)
    const userFav = useSelector(state => state.favorites.favorites)
    // const userFav = useSelector(state => state.favorites.favorites)

    const [bagDetails, setBagDetails] = useState(userBag)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    const [addingToCart, setAddingToCart] = useState(false);
    const [addingToFav, setAddingToFav] = useState(false);
    const [imgSrc, setImgSrc] = useState(props.DonImg)
    useEffect(() => {
        setImgSrc(props.DonImg)
    }, [props.DonImg])
    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])

    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])

    const [choosenToDonate, setChoosenToDonate] = useState({
        donationId: props.donationID,
        amount: props.amount,
        title: props.title,
        img: props.DonImg,
        donationFreq: "N"
    })
    useEffect(() => {
        setChoosenToDonate({
            donationId: props.donationID,
            amount: props.amount,
            title: props.title,
            img: props.DonImg,
            donationFreq: "N"
        })
    }, [props.donationID, props.amount, props.title, props.DonImg])
    useEffect(() => {
        if (!userLogin) {
            if (props.currentProfile?.liked?.donations?.filter(item => item.donationId === props.donationID).length > 0) {
                setLiked(true)
            } else {
                setLiked(false)
            }
        } else {
            if (favoritesDetails?.donations?.filter(item => item.dontypeID === props.donationID).length > 0) {
                setLiked(true)
            } else {
                setLiked(false)
            }
        }

        if (!userLogin) {
            if (props.currentProfile?.cart?.donations?.filter(item => item.donationId === props.donationID && item.donationFreq === "N").length > 0) {
                setInTheBag(true)
            } else {
                setInTheBag(false)
            }
        } else {
            if (bagDetails?.donations?.filter(item => item.dontypeID === props.donationID && item.recurring === "N").length > 0) {
                setInTheBag(true)
            } else {
                setInTheBag(false)
            }
        }

    }, [props.donationID, props.currentProfile, bagDetails, userLogin, userFav])

    const handleLikeDisLike = async (str) => {
        setAddingToFav(true)
        if (liked) {
            await props.handleDisLike(str)
            if (_isMounted.current) {
                setAddingToFav(false);
            }
            
        } else {
            await props.handleLike(str)
            if (_isMounted.current) {
                setAddingToFav(false);
            }
        }
    }
    const handleAddToBag = async (str) => {
        setAddingToCart(true);
        if (inTheBag) {
            await props.handleRemoveFromBag(str)
            setAddingToCart(false);
        } else {
            await props.handleaddToBag(str)
            setAddingToCart(false);
        }
    }
    const handleMouseOver = () => {
        //     var interval_val = 2;
        //     var timeout_ =null;

    }
    return (
        <div className='donation_com' style={addingToCart ? { filter: "grayscale(100%)" } : {}} >
            <button disabled={addingToCart} style={lng === 'arab' ? { marginRight: '0px', marginLeft: '-30px' } : null} onClick={() => setPopUpModal(true)} className='donate_btn_wrap'><img src={donateIcon} alt='donate_btn_icon' className='icon_for_donation' /></button>
            <div className='donation_item' style={props.fromFav ? { boxShadow: 'unset' } : null}>
                <div className='img_donation_wraper' style={{ position: 'relative', width: '190px', borderTopRightRadius: '18px', borderTopLeftRadius: '18px' }}>
                    <img onClick={() => history.push(`/donations/${props.donationID}`)} src={imgSrc} onError={() => setImgSrc(Default)} className='donation_img' />
                    {addingToFav ?
                        <button disabled={addingToFav} style={lng === 'arab' ? { right: 'unset', left: '10px' } : null} className='like_btn'>
                            <div className="containerAnime">
                                <div className="ballFav"></div>
                                <div className="ballFav"></div>
                            </div>
                        </button> :
                        <button disabled={addingToCart || addingToFav} style={lng === 'arab' ? { right: 'unset', left: '10px' } : null} onClick={() => handleLikeDisLike(choosenToDonate)} className='like_btn'>
                            {liked ?
                                <i style={{ color: '#ee4236' }} className="fas fa-heart"></i> :
                                <i style={{ color: '#808080b0' }} className="far fa-heart"></i>
                            }
                        </button>
                    }

                </div>
                <div className='donation_details'>
                    {props.title.length >= 20 ?
                        <React.Fragment>
                            {props.forFavorites ?
                                <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{ direction: 'ltr' }}>
                                    <Marquee speed={0.025} childMargin={15} direction={lng === 'arab' ? 'right' : 'left'} className='label_of_title'><label className='label_of_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
                                </div> :
                                <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{ direction: 'ltr' }}>
                                    <Marquee speed={0.025} childMargin={15} direction={lng === 'arab' ? 'right' : 'left'} className='label_of_title'><label className='label_of_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
                                </div>
                            }
                        </React.Fragment> :
                        <React.Fragment>
                            {props.forFavorites ?
                                <div className='marquee_wraper'><label className='label_of_title' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', textAlign: 'center' }}>{props.title}</label></div>
                                :
                                <div className='marquee_wraper'><label className='label_of_title' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', textAlign: lng === 'arab' ? 'right' : 'left' }}>{props.title}</label></div>
                            }
                        </React.Fragment>
                    }
                    {!props.forFavorites ?
                        <div className='donation_inc_Dec dd-bottom-red' style={lng === 'arab' ? { flexDirection: 'row' } : null}>
                            <button disabled={addingToCart} onClick={() => history.push(`/donations/${props.donationID}`)} className='single_donate_btn'>{t('Donate now')}</button>
                            {/* <label className='label_of_price' style={lng==='arab'? {fontSize:'15px', fontStyle:'italic', paddingLeft:'10px', flex:'1', textAlign:'center'}: {fontSize:'15px', fontStyle:'italic', paddingRight:'10px'}}>{t('AED')} <span style={{fontSize:'15px', fontWeight:'bold', fontStyle:'none'}}>{donationAmount}</span></label> */}
                        </div> :
                        <div className='donation_inc_Dec' style={{ justifyContent: 'center' }}>
                            <label className='label_of_price' style={{ fontSize: '15px', fontStyle: 'italic', textAlign: 'center' }}>{t('AED')} <span style={{ fontSize: '15px', fontWeight: 'bold', fontStyle: 'none' }}>{donationAmount}</span></label>
                        </div>}
                </div>
            </div>
            <PopUpPayment
                show={popUpModal}
                amount={props.amount}
                img={props.DonImg}
                title={props.title}
                edit_flag={props.edit_flag? 'true':''}
                // setchange={props.setChange}
                change={props.change}
                currentprofile={props.currentProfile}
                recuring="N"
                donationid={props.donationID}
                onHide={() => setPopUpModal(false)}
            />
        </div>
    )
}
export default DonationCom

