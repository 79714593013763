import React, { useState, useEffect, useRef } from 'react';
import './Profile.css';
import userImg from '../../assets/user-placeholder.jpeg';
import orphanDefault from '../../assets/orphansDefaultDark.png';
import logOutIcon from '../../assets/log-out.png';
import contactUs from '../../assets/support.png';
import paymentMethodsIcon from '../../assets/plastic.png';
import sponsorIcon from '../../assets/userIcon.png';
import projectsIcon from '../../assets/projects.png';
import donationsIcon from '../../assets/quill.png';
import vlnIcon from '../../assets/hands-up.png';
import localAIDIcon from '../../assets/united-arab-emirates.png';
import accountIcon from '../../assets/account.png';
import emailPIcon from '../../assets/emailPIcon.png';
import logOutAssets from '../../assets/logout.png';
import { useDispatch } from 'react-redux';
import ProfileBtn from './profileBtn/ProfileBtn';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PendingModal from './pendingModal/PendingModal';
import NeedToRegisterModal from './needToRegisterModal/NeedToRegisterModal';
import AccountLinkOtpModal from './AccountLinkModal/AccountLinkModal';
import AccountLinkSelectionModal from './AccountLinkSelectionModal/AccountLinkSelectionModal';
import settingsIcon from '../../assets/settingForPro.png';
import { useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { getAllProjects, getAllSharedProjects } from '../../api/account';
import {
  changeAccount,
  getSPDonationSummary,
  getSPOrphonsList,
  getSPAllLatepayments,
  getSPTransactionSummary
} from '../../actions/user';
import SignOutWithGoogle from '../Auth/googleSignIn/SignOutWithGoogle';
import { toastifyMessage } from '../../handlers/toastifyMessage';
import 'react-spring-bottom-sheet/dist/style.css';
import Loading from '../ui/loading';
import CountUp from 'react-countup';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vlnRegisterProgress = useSelector((state) => state.vlnRegisterProgress);
  const [vlnindex, setVlnIndex] = useState(0);
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  const [userProfile, setUserProfile] = useState(
    userInfo?.Results[0]?.profile_pic
      ? userInfo?.Results[0]?.profile_pic
      : userImg
  );
  useEffect(() => {
    setUserProfile(
      userInfo?.Results[0]?.profile_pic
        ? userInfo?.Results[0]?.profile_pic
        : userImg
    );
  }, [userInfo]);
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });

  const myorphons = useSelector((state) => {
    return state.auth.myorphons;
  });
  const sponsorLoading = useSelector((state) => {
    return state.auth.sponsorLoading;
  });
  const donations = useSelector((state) => {
    return state.auth.donations;
  });
  const [orphonsList, setorphonsList] = useState([]);
  const [orphansNumber, setOrphansNumber] = useState(0);
  const [orphansAmount, setOrphansAmount] = useState(0);
  const [projectsNumber, setProjectNumber] = useState(0);
  const [projectSharedNumber, setProjectSharedNumber] = useState(0);
  const [projectsAmount, setProjectAmount] = useState(0);
  const [totalDonationAmount, setDonationAmount] = useState(0);
  useEffect(() => {
    setOrphansAmount(
      donations?.filter((item) => item.dontypeID === '1')?.shift()?.totalAmount
        ? donations?.filter((item) => item.dontypeID === '1')?.shift()
            ?.totalAmount
        : 0
    );
    setProjectAmount(
      donations?.filter((item) => item.dontypeID === '8')?.shift()?.totalAmount
        ? donations?.filter((item) => item.dontypeID === '8')?.shift()
            ?.totalAmount
        : 0
    );
    setDonationAmount(
      donations
        .map((item) => parseInt(item.totalAmount))
        .reduce((x, y) => x + y, 0)
        ? donations
            .map((item) => parseInt(item.totalAmount))
            .reduce((x, y) => x + y, 0)
        : 0
    );
  }, [donations]);
  useEffect(() => {
    setorphonsList(myorphons);
    setOrphansNumber(myorphons.length);
  }, [myorphons]);

  const donationLoading = useSelector((state) => {
    return state.auth.donationLoading;
  });
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  const history = useHistory();
  const logOut = () => {
    dispatch({ type: 'LOGOUT' });
  };
  const handleAccountChange = (account) => {
    dispatch(
      getSPAllLatepayments(account.extra_sponser_id, lng === 'arab' ? '1' : '2')
    );
    dispatch(
      changeAccount({
        sponser_id: account.extra_sponser_id,
        name: account.extra_sponser_name
      })
    );
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (vlnRegisterProgress.data) {
      if (!vlnRegisterProgress.data.completedPic) {
        setVlnIndex(1);
      } else if (!vlnRegisterProgress.data.completedJob) {
        setVlnIndex(2);
      } else if (!vlnRegisterProgress.data.completedEducation) {
        setVlnIndex(3);
      } else if (!vlnRegisterProgress.data.completedEmiratesID) {
        setVlnIndex(4);
      } else if (!vlnRegisterProgress.data.completedResidence) {
        setVlnIndex(5);
      } else if (!vlnRegisterProgress.data.completedPassport) {
        setVlnIndex(6);
      } else {
        setVlnIndex(-1);
      }
    }
  }, [vlnRegisterProgress]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  const [pendingModalShow, setPendingModalShow] = useState(false);
  const [needToRegisterModal, setNeedToRegisterModal] = useState(false);
  const [accountLinkModal, setAccountLinkModal] = useState(false);
  const [accountLinkSelectionModal, setAccountLinkSelectionModal] =
    useState(false);
  const [otp, setOtp] = useState();

  const [allProjects, setAllProjects] = useState([]);
  const [allSharedProjects, setAllSharedProjects] = useState([]);
  const [allProjectsLoading, setAllProjectsLoading] = useState(true);
  const [allSharedProjectsLoading, setAllSharedProjectsLoading] =
    useState(true);

  const [accountType, setAccountType] = useState('');
  const [toBeTrue, setToBeTrue] = useState(true);
  const [deviceType, setDeviceType] = useState(
    window.innerWidth > 900 ? 'WEBAPP' : 'MOBAPP'
  );
  const [sponsorSelections, setSponsorSelections] = useState([]);

  useEffect(() => {}, [allSharedProjects]);
  useEffect(() => {
    let mounted = true;

    async function fetchAllProjects() {
      setAllProjectsLoading(true);
      const allProjectsFromApi = await getAllProjects(
        sponserId()?.sponser_id,
        lng === 'arab' ? '1' : '2'
      );
      if (allProjectsFromApi?.data?.length > 0) {
        if (mounted) {
          setAllProjects(allProjectsFromApi?.data);
          setAllProjectsLoading(false);
          setProjectNumber(allProjectsFromApi?.data.length);
        }
      } else {
        if (mounted) {
          setAllProjects([]);
          setAllProjectsLoading(false);
          setProjectNumber(0);
        }
      }
    }
    async function fetchAllSharedProjects() {
      setAllSharedProjectsLoading(true);
      const allProjectsFromApi = await getAllSharedProjects(
        getUserInfo()?.email,
        lng === 'arab' ? '1' : '2'
      );
      if (allProjectsFromApi?.data?.data?.length > 0) {
        if (mounted) {
          setAllSharedProjects(allProjectsFromApi?.data.data);
          setAllSharedProjectsLoading(false);
          setProjectSharedNumber(allProjectsFromApi?.data.data.length);
        }
      } else {
        if (mounted) {
          setAllSharedProjects([]);
          setAllSharedProjectsLoading(false);
          setProjectSharedNumber(0);
        }
      }
    }
    fetchAllProjects();
    fetchAllSharedProjects();
    return () => {
      mounted = false;
    };
    //Call APIs here
  }, [sponID]);
  useEffect(() => {
    if (sponserId()?.sponser_id) {
      dispatch(
        getSPDonationSummary(
          sponserId()?.sponser_id,
          lng === 'arab' ? '1' : '2',
          deviceType
        )
      );
      // dispatch(
      //   getSPTransactionSummary(
      //     sponserId()?.sponser_id,
      //     lng === "arab" ? "1" : "2"
      //   )
      // );
      dispatch(
        getSPOrphonsList(
          sponserId()?.sponser_id,
          lng === 'arab' ? 'ARA' : 'ENG'
        )
      );
    }
  }, [sponID]);
  useEffect(() => {
    if (!userInfo) {
      history.push('/');
    }
  }, [userInfo]);
  useEffect(() => {
    if (sessionStorage.getItem('loggedInMsg')) {
      var email = userLogin?.Results[0]?.email;
      setTimeout(
        toastifyMessage(
          lng === 'arab' ? 'تم تسجيل دخولك بواسطة' : 'Logged in as',
          email,
          'success'
        ),
        2000
      );
      sessionStorage.removeItem('loggedInMsg');
    }
  }, []);
  const transactions = useSelector((state) => {
    return state.auth.transactions;
  });
  const transactionsLoading = useSelector((state) => {
    return state.auth.transactionsLoading;
  });

  return (
    <div id="profile_page" style={lng === 'arab' ? { direction: 'rtl' } : null}>
      <div className="profile_page_header">
        {userLogin?.loginType === 'GOOGLE' ? (
          <SignOutWithGoogle top={true} />
        ) : (
          <div
            onClick={() => logOut()}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'absolute',
              left: '15px',
              top: '15px'
            }}
          >
            <img
              src={logOutAssets}
              style={{
                height: '30px',
                width: '30px',
                transform: 'rotate(-180deg)'
              }}
              alt="sponsership"
            />
          </div>
        )}
        <button onClick={() => history.push('/settings')} className="settings">
          <img src={settingsIcon} alt="gear" className="settings_icon" />
        </button>
        <div className="user_data_all">
          <div className="profile_pic_outer">
            <div
              className="profile_pic_wraper"
              style={{ backgroundImage: `url(${userProfile})` }}
            >
              <button
                className="change_profile_of_user"
                onClick={() => {
                  history.push('/editprofile');
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            </div>
            <label>{getUserInfo()?.name_a}</label>
          </div>
          <div className="user_donations_numbers">
            <AnimationOnScroll
              delay={500}
              style={{ width: '100%' }}
              animateIn="animate__slideInUp"
              animateOnce={true}
            >
              <div className="number_wrapper">
                <label className="user_data_titles">{t('Sponsors')}</label>
                <label>
                  <CountUp
                    separator=","
                    delay={0.5}
                    className="payment_numbers user_data_numbers"
                    end={orphansNumber}
                  />
                </label>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="hide_for_mobile_please"
              delay={700}
              style={{ width: '100%' }}
              animateIn="animate__slideInUp"
              animateOnce={true}
            >
              <div className="number_wrapper">
                <label className="user_data_titles">
                  {t('Sponsor Donation')}
                </label>
                <label
                  className="payment_numbers user_data_numbers"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px'
                  }}
                >
                  <span>{t('AED')}</span>
                  <span>
                    <CountUp
                      separator=","
                      delay={0.7}
                      className="payment_numbers user_data_numbers"
                      end={orphansAmount}
                    />
                  </span>
                </label>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              delay={900}
              style={{ width: '100%' }}
              animateIn="animate__slideInUp"
              animateOnce={true}
            >
              <div className="number_wrapper">
                <label className="user_data_titles">{t('Projects')}</label>
                <label className="payment_numbers user_data_numbers">
                  <CountUp
                    separator=","
                    delay={0.9}
                    className="payment_numbers user_data_numbers"
                    end={projectsNumber}
                  />
                </label>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="hide_for_mobile_please"
              delay={1100}
              style={{ width: '100%' }}
              animateIn="animate__slideInUp"
              animateOnce={true}
            >
              <div className="number_wrapper">
                <label className="user_data_titles">
                  {t('Projects Donation')}
                </label>
                <label
                  className="payment_numbers user_data_numbers"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px'
                  }}
                >
                  <span>{t('AED')}</span>
                  <span>
                    <CountUp
                      separator=","
                      delay={1.1}
                      className="payment_numbers user_data_numbers"
                      end={projectsAmount}
                    />
                  </span>
                </label>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              delay={1300}
              style={{ width: '100%' }}
              animateIn="animate__slideInUp"
              animateOnce={true}
            >
              <div className="number_wrapper">
                <label className="user_data_titles">
                  {t('Total Donation')}
                </label>
                <label
                  className="payment_numbers user_data_numbers"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px'
                  }}
                >
                  <span>{t('AED')}</span>
                  <span>
                    <CountUp
                      separator=","
                      delay={1.3}
                      className="payment_numbers user_data_numbers"
                      end={totalDonationAmount}
                    />
                  </span>
                </label>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>

      <div className="profile_second_section_wraper">
        {!sponsorLoading && orphonsList.length === 0 ? null : (
          <div
            className="profile_page_sponsership"
            onClick={() => history.push('/sponsership', { navigateTo: 0 })}
            style={{ cursor: 'pointer' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignSelf: 'flex-start'
              }}
              className="profile_btn_content"
            >
              <img
                src={sponsorIcon}
                className="profile_button_icon"
                alt="sponsership"
              />
              <div className="user_items">
                <label style={{ color: '#000' }}>{t('My Sponsors')}</label>
                {sponsorLoading ? (
                  <Loading height="160px" mediaQ="70px" />
                ) : (
                  <div className="sponsership_items_section_wraper">
                    {orphonsList
                      ?.slice(
                        0,
                        width >= 700
                          ? 6
                          : width >= 400
                          ? 4
                          : width >= 300
                          ? 3
                          : 2
                      )
                      .map((orphon, i) => {
                        return (
                          <div
                            style={{
                              backgroundImage: `url(${
                                orphon.orp_pic ?? orphanDefault
                              })`
                            }}
                            className="sponserItem removeLastElement"
                            key={i}
                          >
                            <label
                              style={{
                                fontSize: '13px',
                                color: '#fff',
                                padding: '3px',
                                width: '100%',
                                textAlign: 'center',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px'
                              }}
                            >
                              {orphon.orp_name.split(' ')[0]}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>

            {lng === 'arab' ? (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-left sponserArrow"
              ></i>
            ) : (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-right sponserArrow"
              ></i>
            )}
          </div>
        )}

        {!allProjectsLoading && allProjects.length === 0 ? null : (
          <div
            className="profile_page_sponsership"
            onClick={() => history.push('/sponsor-projects', { navigateTo: 1 })}
            style={{ cursor: 'pointer' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignSelf: 'flex-start'
              }}
              className="profile_btn_content"
            >
              <img
                src={projectsIcon}
                className="profile_button_icon"
                alt="sponsership"
              />
              <div className="user_items">
                <label style={{ color: '#000' }}>{t('My Projects')}</label>
                {allProjectsLoading ? (
                  <Loading height="160px" mediaQ="70px" />
                ) : (
                  <div className="sponsership_items_section_wraper">
                    {allProjects
                      ?.slice(
                        0,
                        width >= 700
                          ? 6
                          : width >= 400
                          ? 4
                          : width >= 300
                          ? 3
                          : 2
                      )
                      .map((orphon, i) => {
                        return (
                          <div
                            style={{
                              backgroundImage: `url(${orphon.project_Type_Path})`
                            }}
                            className="sponserItem removeLastElement"
                            key={i}
                          >
                            <label
                              style={{
                                fontSize: '13px',
                                color: '#fff',
                                padding: '3px',
                                width: '100%',
                                textAlign: 'center',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px'
                              }}
                            >
                              {orphon.projectName.split(' ')[0]}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            {lng === 'arab' ? (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-left sponserArrow"
              ></i>
            ) : (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-right sponserArrow"
              ></i>
            )}
          </div>
        )}
        {!allSharedProjectsLoading && allSharedProjects.length === 0 ? null : (
          <div
            className="profile_page_sponsership"
            onClick={() =>
              history.push('/sponsor-shared-projects', { navigateTo: 1 })
            }
            style={{ cursor: 'pointer' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignSelf: 'flex-start'
              }}
              className="profile_btn_content"
            >
              <img
                src={projectsIcon}
                className="profile_button_icon"
                alt="sponsership"
              />
              <div className="user_items">
                <label style={{ color: '#000' }}>
                  {t('My Shared Projects')}
                </label>
                {allSharedProjectsLoading ? (
                  <Loading height="160px" mediaQ="70px" />
                ) : (
                  <div className="sponsership_items_section_wraper">
                    {allSharedProjects
                      ?.slice(
                        0,
                        width >= 700
                          ? 6
                          : width >= 400
                          ? 4
                          : width >= 300
                          ? 3
                          : 2
                      )
                      .map((orphon, i) => {
                        return (
                          <div
                            style={{
                              backgroundImage: `url(${orphon.projTypeImagePath})`
                            }}
                            className="sponserItem removeLastElement"
                            key={i}
                          >
                            <label
                              style={{
                                fontSize: '13px',
                                color: '#fff',
                                padding: '3px',
                                width: '100%',
                                textAlign: 'center',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px'
                              }}
                            >
                              {orphon.projectName.split(' ')[0]}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            {lng === 'arab' ? (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-left sponserArrow"
              ></i>
            ) : (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-right sponserArrow"
              ></i>
            )}
          </div>
        )}
        {!donationLoading && donations.length === 0 ? null : (
          <div
            className="profile_page_sponsership"
            onClick={() =>
              history.push('/sponsor-donations', { navigateTo: 2 })
            }
            style={{ cursor: 'pointer' }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignSelf: 'flex-start'
              }}
              className="profile_btn_content"
            >
              <img
                src={donationsIcon}
                className="profile_button_icon"
                alt="sponsership"
              />
              <div className="user_items">
                <label style={{ color: '#000' }}>{t('Donation History')}</label>
                {donationLoading ? (
                  <Loading height="160px" mediaQ="70px" />
                ) : (
                  <div className="sponsership_items_section_wraper">
                    {donations
                      ?.slice(
                        0,
                        width >= 700
                          ? 6
                          : width >= 400
                          ? 4
                          : width >= 300
                          ? 3
                          : 2
                      )
                      .map((orphon, i) => {
                        return (
                          <div
                            style={{
                              backgroundImage: `url(${orphon.imagePath})`
                            }}
                            className="sponserItem removeLastElement"
                            key={i}
                          >
                            <label
                              style={{
                                fontSize: '13px',
                                color: '#fff',
                                padding: '3px',
                                width: '100%',
                                textAlign: 'center',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px'
                              }}
                            >
                              {orphon.dontypeName.split(' ')[0]}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>

            {lng === 'arab' ? (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-left sponserArrow"
              ></i>
            ) : (
              <i
                style={{ color: '#ee4236', cursor: 'pointer' }}
                className="fas fa-angle-right sponserArrow"
              ></i>
            )}
          </div>
        )}
        {!transactionsLoading && transactions.length > 0 ? (
          <ProfileBtn
            icon={paymentMethodsIcon}
            goTo="/transaction-history"
            title={t('Transaction History')}
          />
        ) : null}

        <ProfileBtn
          email={true}
          goTo="onclick"
          onClick={() => {
            history.push('/editprofile');
          }}
          onTap={() => {
            history.push('/editprofile');
          }}
          icon={emailPIcon}
          title={
            getUserInfo()?.email
              ? width <= 1150
                ? getUserInfo()?.email?.lenght > 17
                  ? getUserInfo()?.email
                  : getUserInfo()?.email.slice(0, 15) + '...'
                : getUserInfo()?.email
              : ''
          }
        />
        <ProfileBtn
          goTo="onclick"
          icon={accountIcon}
          title={t('Account')}
          onTap={() => setOpen(true)}
        />
        <ProfileBtn
          icon={paymentMethodsIcon}
          goTo="/payment-method"
          title={t('Payment Methods')}
        />
        <ProfileBtn
          goTo={
            userLogin?.Results[0]?.vln_id_flag === '0' ||
            userLogin?.Results[0]?.vln_id_flag === null
              ? 'needToRegisterFromVln'
              : userLogin?.Results[0]?.vln_id_flag === '1' && vlnindex === -1
              ? 'pendingfromVln'
              : userLogin?.Results[0]?.vln_id_flag === '1' && vlnindex !== -1
              ? `need${vlnindex}`
              : '/volunteer'
          }
          setToBeTrue={setToBeTrue}
          setNeedToRegisterModal={setNeedToRegisterModal}
          setAccountType={setAccountType}
          setPendingModalShow={setPendingModalShow}
          icon={vlnIcon}
          title={t('Volunteering Service')}
        />
        <ProfileBtn
          goTo={
            userLogin?.Results[0]?.person_id_flag === '2'
              ? '/localAid'
              : userLogin?.Results[0]?.person_id_flag === '1'
              ? 'pendingFromLA'
              : userLogin?.Results[0]?.person_id_flag === '0' &&
                userLogin?.Results[0]?.aids_req_id
              ? 'goWithoutPending'
              : 'needToRegisterFromLocalAid'
          }
          setToBeTrue={setToBeTrue}
          setNeedToRegisterModal={setNeedToRegisterModal}
          setAccountType={setAccountType}
          setPendingModalShow={setPendingModalShow}
          icon={localAIDIcon}
          title={t('Local Aid')}
        />
        <ProfileBtn
          goTo="contact-us"
          icon={contactUs}
          title={t('Contact Us')}
        />
        {userLogin?.loginType === 'GOOGLE' ? (
          <SignOutWithGoogle />
        ) : (
          <ProfileBtn
            last_child={true}
            logoutMaan={logOut}
            email={true}
            icon={logOutIcon}
            title={t('Log Out')}
          />
        )}
        <div className="recent_donations_section">
          <h3
            style={{
              paddingBottom: '5px',
              width: '100%',
              borderBottom: '1px solid gray'
            }}
          >
            {t('Recent Donations')}
          </h3>
          {donationLoading ? (
            <Loading />
          ) : (
            <div className="recentDonations_display">
              {donations?.slice(0, 5).map((donation, index) => (
                <RecentDonationTile
                  key={index}
                  donation={donation}
                ></RecentDonationTile>
              ))}
              {donations?.length == 0 ? (
                <div>{t('No recent donations found!')}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <PendingModal
        show={pendingModalShow}
        accounttype={accountType}
        dialogClassName="modal-60w"
        onHide={() => setPendingModalShow(false)}
      />
      <NeedToRegisterModal
        show={needToRegisterModal}
        localaid={toBeTrue ? 'true' : ''}
        dialogClassName="modal-60w"
        onHide={() => setNeedToRegisterModal(false)}
      />
      <AccountLinkOtpModal
        email={
          userLogin?.Results &&
          userLogin?.Results.length > 0 &&
          userLogin?.Results[0]
            ? userLogin?.Results[0].email
            : ''
        }
        show={accountLinkModal}
        setAccountLinkSelectionModal={setAccountLinkSelectionModal}
        setSponsorSelections={setSponsorSelections}
        dialogClassName="modal-60w"
        setOtp={setOtp}
        onHide={() => {
          setAccountLinkModal(false);
        }}
      />
      <AccountLinkSelectionModal
        email={
          userLogin?.Results &&
          userLogin?.Results.length > 0 &&
          userLogin?.Results[0]
            ? userLogin?.Results[0].email
            : ''
        }
        show={accountLinkSelectionModal}
        sponsorSelections={sponsorSelections}
        otp={otp}
        dialogClassName="modal-60w"
        onHide={() => {
          setAccountLinkSelectionModal(false);
        }}
      />
      {open && (
        <div
          className="boomCheet"
          style={{ zIndex: '10000001', width: '100%' }}
        >
          <BottomSheet
            open={open}
            onDismiss={() => setOpen(false)}
            header={<h6>{t('Accounts')}</h6>}
            style={{
              fontFamily: lng === 'arab' ? 'arab_font' : 'english_font',
              direction: lng === 'arab' ? 'rtl' : 'ltr'
            }}
          >
            {userLogin?.Results &&
            userLogin?.Results.length > 0 &&
            userLogin?.Results[0].extra_spons
              ? userLogin?.Results[0].extra_spons.map((spons, i) => {
                  return (
                    <AccountTile
                      account={spons}
                      onClick={(account) => {
                        handleAccountChange(account);
                      }}
                      onDismiss={() => setOpen(false)}
                      key={i}
                    ></AccountTile>
                  );
                })
              : null}
            <div
              onClick={() => {
                setOpen(false);
                setAccountLinkModal(true);
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
            >
              {t('Add Account')}
            </div>
            <div style={{ height: '100px' }}></div>
          </BottomSheet>
        </div>
      )}
    </div>
  );
  function RecentDonationTile(props) {
    const dn = props.donation;
    return (
      <button
        className="recent_donation_item"
        style={{
          backgroundImage: `url(${dn.imagePath})`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '0px',
          padding: '0px'
        }}
      >
        <span style={{ width: '100%', backgroundColor: '#000000a6' }}>
          {dn.dontypeName}
        </span>
        <span
          style={{
            width: '100%',
            backgroundColor: '#000000a6',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px'
          }}
        >
          {t('AED')} <span className="payment_numbers">{dn.totalAmount}</span>
        </span>
      </button>
    );
  }
  function AccountTile(pr) {
    const account = pr.account;
    return (
      <div
        style={{ cursor: 'pointer' }}
        className="account_tile_card"
        onClick={() => {
          pr.onClick(account);
          pr.onDismiss();
        }}
      >
        <div className="account_name_avatar">
          <div style={{ color: 'white' }}>{account.extra_sponser_name[0]}</div>
        </div>
        <div>
          <div className="link_account_data">{account.extra_sponser_name}</div>
          <div className="payment_numbers link_account_data">
            {account.extra_sponser_id}
          </div>
        </div>
      </div>
    );
  }
};

export default Profile;
