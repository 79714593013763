import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Mousewheel } from "swiper";
import LatePaymentItem from "./latePaymentItem/LatePaymentItem";
import foodBasket2 from "../../../assets/pexels-kindel-media-7979606.jpg";
import "../MyBag.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addToBag, deleteFromBag } from '../../../actions/BagFav'
import {
  getSPAllLatepayments,
  getSPOrphAllLatepayments,
} from "../../../actions/user";
import Loading from "../../ui/loading";

const LatePaymentDisplay = () => {
  const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
  const { t } = useTranslation();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  SwiperCore.use([Pagination, Mousewheel]);
  const [totalLatePaymentAmount, setTotalLatePaymentAmount] = useState(0);
  const latePaymentsLoadingState = useSelector((state) => state.latePayments.latePaymentLoading);
  const [loadingState, setLoadingState] = useState(true)
  const [addingToBag, setAddingToBag] = useState(false)
  useEffect(() => {
    setLoadingState(latePaymentsLoadingState)
  }, [latePaymentsLoadingState])

  const latePaymentsState = useSelector((state) => state.latePayments.latePayments);
  const userBag = useSelector(state => state.bag.bag)
  const [bagDetails, setBagDetails] = useState(userBag)
  useEffect(() => {
    setBagDetails(userBag)
  }, [userBag])
  const [latePayments, setLatePayments] = useState([]);
  useEffect(() => {
    if (latePaymentsState?.length>0) {
      let latePaymentBag = userBag?.latePayments?.slice(0)
      let latePaymentClean = []
      if (latePaymentBag?.length>0) {
        latePaymentClean = latePaymentsState?.filter(item=> latePaymentBag?.map(inner => { return inner.trn_serial}).indexOf(item.trn_serial) ==-1)
      } else {
        latePaymentClean = latePaymentsState
      }
      setLatePayments(latePaymentClean)
      if (latePaymentClean?.length > 0) {
        let amounts = latePaymentClean?.map(item => parseInt(item.trn_amount))
        let amountInNumber = amounts?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        setTotalLatePaymentAmount(amountInNumber)
    }    
    } else {
      setTotalLatePaymentAmount(0)
    }
  }, [latePaymentsState, userBag]);
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  const handleaddToBag = async (item, setAddingToBag) => {
    //setAddingToBag(true)
    await dispatch(addToBag({
      cart: {
        donID: "1", userName: userLogin?.Results[0]?.name_a, userMob: userLogin?.Results[0]?.mobile_no,
        userEmail: userLogin?.Results[0]?.email, itemType: '6', amount: item.trn_amount, recuring: null, cart_serial: null,
        orphan_id: item.orphanID, sponsor_id: userLogin?.Results[0]?.sponser_id, transaction_number: item.trn_serial,
        transaction_year: item.trn_year
      }, language: lng === 'arab' ? 1 : 2
    }))
    //setAddingToBag(false)
  }
  const handleaddAllToBag = async (bag) => {
    setAddingToBag(true)
    let newBag = []
    bag.forEach(item => {
      newBag.push({
        donID: "1", userName: userLogin?.Results[0]?.name_a, userMob: userLogin?.Results[0]?.mobile_no,
        userEmail: userLogin?.Results[0]?.email, itemType: '6', amount: item.trn_amount, recuring: null, cart_serial: null,
        orphan_id: item.orphanID, sponsor_id: userLogin?.Results[0]?.sponser_id, transaction_number: item.trn_serial,
        transaction_year: item.trn_year
      })
    })
    await dispatch(addToBag({
      cart: newBag, language: lng === 'arab' ? 1 : 2
    }))
    if (_isMounted.current) {
      setAddingToBag(false)
    }
  }
  // useEffect(() => {
  //   if (getUserInfo()?.sponser_id) {
  //     dispatch(
  //       getSPAllLatepayments(
  //         getUserInfo()?.sponser_id,
  //         lng === "arab" ? "1" : "2"
  //       )
  //     );
  //   }
  // }, []);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div className="latePayment" id="my_bag_right_side">
      <h5 className="title_to_be_none" style={{ fontWeight: "bold" }}>
        {t("Late Payment")}
      </h5>
      {loadingState ? <Loading height='calc(100vh - 300px)'/> : latePayments.length>0? <div style={{width:'100%'}}>
        <div className="items_on_bag_display">
          {addingToBag?
          <Loading/>:
          <div className="forMob_bag">
            {latePayments?.map((latePayment, i) => {
              return (
                <LatePaymentItem
                name={latePayment.orphan_name}
                amount={latePayment.trn_amount}
                latePay={latePayment}
                handleaddToBag={handleaddToBag}
                userBag={bagDetails}
                allItem={latePayment}
                late={true}
                date={latePayment.payment_date}
                key={latePayment.trn_serial}
                lateImg={latePayment.imagePath}
                />
              );
            })}
          </div>
        }
        </div>
        <div className="total_amount_for_bag" id="add_all_btn">
          <div className="text_of_total_amount">
            <label style={{ fontSize: width>500?"18px":'16px', textAlign: lng==='arab'? 'right':'left' }}>{t("Total Late Payments")}</label>
            <label style={{ fontSize: '14px' }}>{t("AED")} <span className='payment_numbers' style={{ fontWeight: 'bold', fontSize: width>500?"20px":'17px' }}>{totalLatePaymentAmount}</span>
            </label>
          </div>
          <button className="Donate_now_add_to_cart smallBtn_smallDevice" onClick={()=> handleaddAllToBag(latePayments)}>
            {t("Add All To Bag")}
          </button>
        </div>
        </div>:
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 300px)', width: '100%' }}>
            <label>{t("You don't have any items in late payments")}</label>
          </div>
      }
    </div >
  );
};

export default LatePaymentDisplay;
