import {
  FETCH_VOLUNTEER_FILE_DETAILS,
  FETCH_VOLUNTEER_EVENT_DETAILS,
  FETCH_PARTICIPANTS_DETALS,
  FETCH_ALL_ATTACHMENTS,
  FETCH_ALL_COURSES,
  FETCH_ALL_COURSES_FOR_VOLUNTEER,
  FETCH_ALL_COUNTRIES,
  FETCH_ALL_LEVELS,
  FETCH_ALL_LANGUAGES,
  FETCH_VOLUNTEER_LANGUAGES,
  FETCH_ALL_VOLUNTEER_SKILLS,
  FETCH_ALL_SKILLS,
  FETCH_ALL_INTERESTS,
  FETCH_ALL_VOLUNTEER_INTERESTS,
  FETCH_VOLUNTEER_EVENTS,
  FETCH_EVENT_DETAILS,
  FETCH_LOADING_EVENT_DETAILS,
  FETCH_LOADED_EVENT_DETAILS,
  FETCH_ALL_VOLUNTEER_SERVICES,
  FETCH_ALL_BRANCHES,
  FETCH_ALL_EDUCATIONS,
  FETCH_VLN_REGISTER_PROGRESS,
  GET_ALL_JOBS,
  GET_ALL_HEALTHS,
  GET_ALL_MARRIAGE,
  GET_RELATIONSHIP_STATUS,
  LOAD_VOLUNTEER,
  VOLUNTEER_LOADED,
  LOAD_VOLUNTEER_PARTICIPANT,
  VOLUNTEER_PARTICIPANT_LOADED,
  LOAD_VOLUNTEER_ATTACHMENT,
  VOLUNTEER_ATTACHMENT_LOADED,
  LOAD_VOLUNTEER_COURSE,
  VOLUNTEER_COURSE_LOADED,
  LOAD_ALL_LANGUAGES,
  ALL_LANGUAGES_LOADED,
  LOAD_SKILLS,
  SKILLS_LOADED,
  LOAD_INTERESTS,
  INTERESTS_LOADED,
  LOAD_EVENTS,
  EVENTS_LOADED,
  LOAD_SERVICES,
  SERVICES_LOADED,
  FETCH_ALL_COUNTRIES_FOR_VOLUNTEERS,
  GET_ALL_JOBS_NEW
} from '../constants/actionTypes';
import * as api from '../api';
import { toastifyMessage } from '../handlers/toastifyMessage';

export const getVoulnteerFile =
  (volunteerID, langNumber) => async (dispatch) => {
    dispatch({ type: LOAD_VOLUNTEER });
    try {
      var { data } = await api.getVolunteerDetails(volunteerID, langNumber);
      dispatch({ type: FETCH_VOLUNTEER_FILE_DETAILS, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({ type: VOLUNTEER_LOADED });
    }
  };
export const getCountriesForVolunteers = (language) => async (dispatch) => {
  try {
    const { data } = await api.getCountriesForVolun(language);
    dispatch({ type: FETCH_ALL_COUNTRIES_FOR_VOLUNTEERS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getVoulnteerEvents =
  (volunteerID, langNumber, emiratesId, email) => async (dispatch) => {
    dispatch({ type: LOAD_VOLUNTEER });
    try {
      var { data } = await api.getVolunteerActiveEvents(
        volunteerID,
        langNumber,
        emiratesId,
        email
      );

      dispatch({ type: FETCH_VOLUNTEER_EVENT_DETAILS, payload: data.data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({ type: VOLUNTEER_LOADED });
    }
  };
export const getVolunteersCount = (volunteerID, language) => async () => {
  try {
    const userData = {
      volunteerID: volunteerID,
      language: language
    };

    const { data } = await api.vulunteerCounts(userData);
    return { data };
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getPatricipantsDetals =
  (volunteerID, langNumber) => async (dispatch) => {
    dispatch({
      type: LOAD_VOLUNTEER_PARTICIPANT
    });
    try {
      var { data } = await api.getTotalHourSpentAsVolunteer(
        volunteerID,
        langNumber
      );
      dispatch({ type: FETCH_PARTICIPANTS_DETALS, payload: data.data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({
        type: VOLUNTEER_PARTICIPANT_LOADED
      });
    }
  };

export const getAttachments = (volunteerID) => async (dispatch) => {
  dispatch({
    type: LOAD_VOLUNTEER_ATTACHMENT
  });
  try {
    var { data } = await api.getVolunteerAndAcademicDetails(volunteerID);
    dispatch({ type: FETCH_ALL_ATTACHMENTS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({
      type: VOLUNTEER_ATTACHMENT_LOADED
    });
  }
};

export const getCourses = (langNumber) => async (dispatch) => {
  // LOAD_VOLUNTEER_COURSE
  dispatch({ type: LOAD_VOLUNTEER_COURSE });
  try {
    var { data } = await api.getCourseLookup(langNumber);
    dispatch({ type: FETCH_ALL_COURSES, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: VOLUNTEER_COURSE_LOADED });
  }
};

export const getVolunteerCourses =
  (volunteerID, langNumber) => async (dispatch) => {
    try {
      var { data } = await api.getVolunteerCourses(volunteerID, langNumber);
      dispatch({ type: FETCH_ALL_COURSES_FOR_VOLUNTEER, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const getAllCountries = (langNumber) => async (dispatch) => {
  try {
    var { data } = await api.getCountryLookup(langNumber);
    dispatch({ type: FETCH_ALL_COUNTRIES, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const getLevels = (langNumber) => async (dispatch) => {
  try {
    var { data } = await api.getAllLevels(langNumber);
    dispatch({ type: FETCH_ALL_LEVELS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const getLanguages = (langNumber) => async (dispatch) => {
  dispatch({
    type: LOAD_ALL_LANGUAGES
  });
  try {
    var { data } = await api.getAllLanguages(langNumber);
    dispatch({ type: FETCH_ALL_LANGUAGES, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({
      type: ALL_LANGUAGES_LOADED
    });
  }
};

export const getVolunteerLanguages =
  (langNumber, volunteerID) => async (dispatch) => {
    try {
      var { data } = await api.getVolunteerLanguageDetails(
        langNumber,
        volunteerID
      );
      dispatch({ type: FETCH_VOLUNTEER_LANGUAGES, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const insertVolunteerLanguage =
  (langNumber, volunteerID, level, setLoadingModalShow, setDoneModal) =>
  async (dispatch) => {
    try {
      var { data } = await api.addVolunteerLanguage(
        langNumber,
        volunteerID,
        level
      );
      if (data.status) {
        setLoadingModalShow(false);
        setDoneModal(true);
      } else {
        setLoadingModalShow(false);
        toastifyMessage(data.result, undefined, 'error');
        //alert(data.result);
      }
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const getAllSkills = (langNumber) => async (dispatch) => {
  dispatch({ type: LOAD_SKILLS });
  try {
    var { data } = await api.getAbilitesLookup(langNumber);
    dispatch({ type: FETCH_ALL_SKILLS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({ type: SKILLS_LOADED });
  }
};

export const getVolunteerSkills =
  (volunteerID, langNumber) => async (dispatch) => {
    try {
      var { data } = await api.getVolunteerAbilities(volunteerID, langNumber);
      dispatch({ type: FETCH_ALL_VOLUNTEER_SKILLS, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const addVolunteerSkills =
  (
    abilityID,
    volunteerID,
    remarks,
    setLoadingModalShow,
    setDoneModal,
    setAdded,
    added
  ) =>
  async (dispatch) => {
    try {
      var { data } = await api.addVolunteerAbilities(
        abilityID,
        volunteerID,
        remarks
      );
      if (data.status) {
        setLoadingModalShow(false);
        setDoneModal(true);
        setAdded(added + 1);
      } else {
        setLoadingModalShow(false);
        toastifyMessage(data.result, undefined, 'error');
        //alert(data.result);
      }
    } catch (error) {
      toastifyMessage('Something went wrong...!', undefined, 'error');
    }
  };

export const getAllInterests = (langNumber) => async (dispatch) => {
  dispatch({
    type: LOAD_INTERESTS
  });
  try {
    var { data } = await api.getAllPrograms(langNumber);
    dispatch({ type: FETCH_ALL_INTERESTS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({
      type: INTERESTS_LOADED
    });
  }
};

export const getVolunteerInterests =
  (volunteerID, langNumber) => async (dispatch) => {
    try {
      var { data } = await api.getVolunteerPrograms(volunteerID, langNumber);
      dispatch({ type: FETCH_ALL_VOLUNTEER_INTERESTS, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };

export const insertVolunteerInterest =
  (
    programID,
    volunteerID,
    setLoadingModalShow,
    setDoneModal,
    setAdded,
    added
  ) =>
  async (dispatch) => {
    try {
      var { data } = await api.addVolunteerPrograms(programID, volunteerID);
      if (data.status) {
        setLoadingModalShow(false);
        setDoneModal(true);
        setAdded(added + 1);
      } else {
        setLoadingModalShow(false);
        toastifyMessage(data.result, undefined, 'error');
        //alert(data.result);
      }
    } catch (error) {
      toastifyMessage('Something went wrong...!', undefined, 'error');
    }
  };

export const getVolunteerEvents =
  (volunteerID, langNumber) => async (dispatch) => {
    dispatch({
      type: LOAD_EVENTS
    });
    try {
      var { data } = await api.getVolunteerEvents(volunteerID, langNumber);
      dispatch({ type: FETCH_VOLUNTEER_EVENTS, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({
        type: EVENTS_LOADED
      });
    }
  };

export const getEventDetails =
  (langNumber, volunteerID, eventID, eventYear) => async (dispatch) => {
    dispatch({ type: FETCH_LOADING_EVENT_DETAILS });
    try {
      var { data } = await api.getVolunteerEventDetails(
        langNumber,
        volunteerID,
        eventID,
        eventYear
      );
      dispatch({ type: FETCH_EVENT_DETAILS, payload: data });
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
      dispatch({ type: FETCH_LOADED_EVENT_DETAILS });
    }
  };

export const getServices = (langNumber, volunteerID) => async (dispatch) => {
  dispatch({
    type: LOAD_SERVICES
  });
  try {
    var { data } = await api.getAllEServices(langNumber, volunteerID);
    dispatch({ type: FETCH_ALL_VOLUNTEER_SERVICES, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
    dispatch({
      type: SERVICES_LOADED
    });
  }
};

export const getAllBranches = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getBranchDetails(langString);
    dispatch({ type: FETCH_ALL_BRANCHES, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const getEdu = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getEduQualLookUp(langString);
    dispatch({ type: FETCH_ALL_EDUCATIONS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getJobs = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getJobs(langString);
    dispatch({ type: GET_ALL_JOBS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getRelationshipStatus = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getRelationshipStatus(langString);
    dispatch({ type: GET_RELATIONSHIP_STATUS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getMarriage = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getMarriage(langString);
    dispatch({ type: GET_ALL_MARRIAGE, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getVolunteerJobsNew = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getJobsNew(langString);
    dispatch({ type: GET_ALL_JOBS_NEW, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getHealths = (langString) => async (dispatch) => {
  try {
    var { data } = await api.getHealthStatus(langString);
    dispatch({ type: GET_ALL_HEALTHS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};

export const createVlnAccount =
  (
    arabNAme,
    engNAme,
    mobNumber,
    email,
    eid,
    password,
    workSpaceID,
    gender,
    DOB,
    langToConatct,
    nationality,
    job,
    placeOfWork,
    whatsAppNumber,
    maritalStatus,
    setVlnId,
    setView,
    setLoadingModalShow,
    setFromState
  ) =>
  async (dispatch) => {
    try {
      var { data } = await api.createVLNAccount(
        arabNAme,
        engNAme,
        mobNumber,
        email,
        eid,
        password,
        workSpaceID,
        gender,
        DOB,
        langToConatct,
        nationality,
        job,
        placeOfWork,
        whatsAppNumber,
        maritalStatus
      );
      if (data.status) {
        setLoadingModalShow(false);
        let oldData = JSON.parse(localStorage.getItem('userLogin'));
        localStorage.removeItem('userLogin');
        localStorage.setItem(
          'userLogin',
          JSON.stringify({
            Results: [
              {
                DOB: DOB,
                aids_req_id: oldData.Results[0].aids_req_id,
                aids_req_year: oldData.Results[0].aids_req_year,
                email: oldData.Results[0].email,
                emiratesID: eid,
                extra_spons: oldData.Results[0].extra_spons,
                mobile_no: mobNumber,
                name_a: oldData.Results[0].name_a,
                person_id: oldData.Results[0].person_id,
                person_id_flag: oldData.Results[0].person_id_flag,
                profileImage: oldData.Results[0].profileImage,
                sponser_id: oldData.Results[0].sponser_id,
                uniq_id: oldData.Results[0].uniq_id,
                user_tokens: oldData.Results[0].user_tokens,
                vln_id: data.data.vln_acc,
                vln_id_flag: '1'
              }
            ]
          })
        );
        //setFromState('please_let_him_pass_to_complete')
        setVlnId(data.data.vln_acc);
        setView(1);
      } else {
        setLoadingModalShow(false);
        toastifyMessage(data.result, undefined, 'error');
      }
    } catch (error) {
      toastifyMessage('Something went wrong...!', undefined, 'error');
    }
  };

export const uploadProfilePic = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerProfilePic(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage('Something went wrong...!', undefined, 'error');
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};
export const uploadIntro = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerIntroduction(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage(
        data?.result ?? 'Something went wrong...!',
        undefined,
        'error'
      );
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};
export const uploadJobData = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerJobDetails(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage(
        data?.result ?? 'Something went wrong...!',
        undefined,
        'error'
      );
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};

export const uploadEduData = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteereduDetails(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage(
        data?.result ?? 'Something went wrong...!',
        undefined,
        'error'
      );
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};

export const uploadEidDetails = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerNathDetails(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage(
        data?.result ?? 'Something went wrong...!',
        undefined,
        'error'
      );
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};
export const uploadResiData = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerResiDetails(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};
export const uploadPassData = (userData, setLoadingModalShow) => async () => {
  try {
    var { data } = await api.uploadVolunteerPassDetails(userData);
    if (data.status) {
      setLoadingModalShow(false);
    } else {
      setLoadingModalShow(false);
      toastifyMessage(
        data?.result ?? 'Something went wrong...!',
        undefined,
        'error'
      );
    }
  } catch (error) {
    toastifyMessage('Something went wrong...!', undefined, 'error');
  }
};
export const checkingUserRegisterSteps = (vlnID) => async (dispatch) => {
  try {
    var { data } = await api.checkingUserRegisterStepss(vlnID);

    dispatch({ type: FETCH_VLN_REGISTER_PROGRESS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const checkingVlnAcc =
  (vln, setLoadingModalShow, setDisplay, setvlnId) => async (dispatch) => {
    try {
      var { data } = await api.linkingVlnAccount(
        vln.account_link_email,
        vln.volunteer_eid
      );

      if (data.status) {
        var sendOtp = await api.sendingOTPForVln(
          vln.account_link_email,
          data.volunteer_id
        );
        if (sendOtp.data.status) {
          setvlnId(data.volunteer_id);
          setLoadingModalShow(false);
          setDisplay(2);
        } else {
          setLoadingModalShow(false);
          toastifyMessage('Email does not exist', undefined, 'warning');
        }
      } else {
        setLoadingModalShow(false);
        toastifyMessage(
          "You don't have a Volunteer Account",
          undefined,
          'warning'
        );
      }
    } catch (error) {
      //toastifyMessage("Something went wrong...!", undefined, "error");
    }
  };
export const confirmLinking =
  (email, id, otp, setLoadingModalShow, setDoneModal) => async (dispatch) => {
    try {
      var { data } = await api.confirmLinking(email, id, otp);

      if (data.status) {
        localStorage.setItem(
          'userLogin',
          JSON.stringify({ Results: [data.data] })
        );
        setLoadingModalShow(false);
        setDoneModal(true);
      } else {
        setLoadingModalShow(false);
        toastifyMessage(
          "You don't have a Volunteer Account",
          undefined,
          'warning'
        );
      }
    } catch (error) {
      toastifyMessage('Something went wrong...!', undefined, 'error');
    }
  };

export const enrollEvent = async (
  eventID,
  eventYear,
  volunteerID,
  setLoadingModalShow
) => {
  var res = await api.enrollEvent(eventID, eventYear, volunteerID);
  setLoadingModalShow(false);
  if (res.data.data === 'A') {
    toastifyMessage(
      'You have successfully applied to the event',
      undefined,
      'success'
    );
  }
  return res.data.data;
};
