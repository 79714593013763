import { APIForVolunteerV3V2, tokenizeCard, deleteCard } from ".";
import { addCardApi } from ".";

export const submitExtraSponser = (email, mobile) =>
  APIForVolunteerV3V2.get(
    `link_sponsor_request?user_email=${email}&sponsor_mobile=${mobile}`
  );
export const submitExtraSponserOTP = (email, mobile, otp) =>
  APIForVolunteerV3V2.get(
    `confirm_otp_sponsor_link_new?user_email=${email}&sponsor_mobile=${mobile}&otp=${otp}`
  );
export const getDonationSummary = (sponserID, language, device_type) =>
  APIForVolunteerV3V2.get(
    `donation_summary?sponserID=${sponserID}&language=${language}&device_type=${device_type}`
  );
export const getTransactionsSummary = (sponserID, language) =>
  APIForVolunteerV3V2.get(
    `sponsor_transactions_v2?sponsor_id=${sponserID}&language=${language}`
  );
export const getMyOrphonFollowups = (sponserID, lang, orphanId) =>
  APIForVolunteerV3V2.get(
    `orphan_followup_reports?sponser_id=${sponserID}&Lang=${lang}&orphan_id=${orphanId}`
  );
export const getMyOrphons = (sponserID, lang) =>
  APIForVolunteerV3V2.get(`my_orp?sponser_id=${sponserID}&Lang=${lang}`);
export const getMyNotifications = (email, language) =>
  APIForVolunteerV3V2.get(
    `get_notifications?email=${email}&language=${language}`
  );
export const readMyNotifications = (email) =>
  APIForVolunteerV3V2.get(
    `read_all_notification?email=${email}`
  );

export const addCard = (email, token) =>
  addCardApi(`?email=${email}&Token=${token}`);

export const tokenize = (expMonth, expYear, cardNumber, securityNumber) =>
  tokenizeCard(expMonth, expYear, cardNumber, securityNumber);

export const deletCard = (email, token) => deleteCard(email, token);
export const getLatePaymentsByOrp = (sponserID, language, orphanID) =>
  APIForVolunteerV3V2.get(
    `late_payment_check?sponsorID=${sponserID}&language=${language}&orphanID=${orphanID}`
  );
export const getAllLatePayments = (sponserID, language) =>
  APIForVolunteerV3V2.get(
    `all_late_payment_check?sponsorID=${sponserID}&language=${language}`
  );
export const getAllProjects = (sponserID, language) =>
  APIForVolunteerV3V2.get(
    `sponsors_projects?sponsorID=${sponserID}&language=${language}`
  );

export const getAllSharedProjects = (email, language) =>
  APIForVolunteerV3V2.get(
    `user_project_shares?email=${email}&language=${language}`
  );


export const getProjectsReport = (sponserID, project_id, project_year) =>
  APIForVolunteerV3V2.get(
    `project_report_all?sponser_id=${sponserID}&project_id=${project_id}&project_year=${project_year}`
  );
export const editProfile = async (data) =>
  await APIForVolunteerV3V2.get(
    `update_profile?user_email=${data.user_email}&new_userName=${data.new_userName}&new_DOB=${data.new_DOB}&new_EID=${data.new_EID}`
  );

export const uploadProfilePic = async (data) =>
  await APIForVolunteerV3V2.post(`update_profile_pic`, data);
