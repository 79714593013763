import { combineReducers } from "redux";
import orphanSliders from "./orphanSliders";
import projects from "./projects/projects";
import countriesForProjectType from "./projects/countriesForProject";
import projectsCountryDetails from "./projects/projectsCountryDetails";
import sukuk from "./sukuk";
import landingPages from "./landingPages";
import compaginsSlider from "./compaginsSlider";
import donations from "./donations";
import humanCases from "./humanCases";
import auth from "./auth";
import user from "./user";
import countries from "./countries";
import countriesForProject from "./countriesForProject";
import countriesForOrphan from "./countriesForOrphan";
import payment from "./payment";
import language from "./translation";
import laCompleted from "./Local Aid/laCompleted";
import laPending from "./Local Aid/laPending";
import laProfile from "./Local Aid/laProfile";
import laRelatives from "./Local Aid/laRelatives";
import laReturned from "./Local Aid/laReturned";
import laTypes from "./Local Aid/laTypes";
import vlnAttachments from "./Volunteer/vlnAttahcments";
import vlnCountries from "./Volunteer/vlnCountries";
import vlnCourses from "./Volunteer/vlnCourses";
import vlnEventDetails from "./Volunteer/vlnEventDetails";
import vlnEvents from "./Volunteer/vlnEvents";
import vlnInterests from "./Volunteer/vlnInterests";
import vlnLanguages from "./Volunteer/vlnLanguages";
import vlnLevels from "./Volunteer/vlnLevels";
import vlnParticipantsDetails from "./Volunteer/vlnParticipantsDetails";
import vlnServices from "./Volunteer/vlnServices";
import vlnSkills from "./Volunteer/vlnSkills";
import vlnVolunteerCourses from "./Volunteer/vlnVolunteerCourses";
import vlnVolunteerInterests from "./Volunteer/vlnVolunteerInterests";
import vlnVolunteerLanguages from "./Volunteer/vlnVolunteerLanguages";
import vlnVolunteerSkills from "./Volunteer/vlnVolunteerSkills";
import vlnFileDteails from "./Volunteer/vlnFileDteails";
import vlnEventsDetails from "./Volunteer/vlnEventsDetails";

import vlnBranches from "./Volunteer/vlnBranches";
import countriesForVolunteers from "./Volunteer/countriesForVolunteers";
import vlnEdu from "./Volunteer/vlnEdu";
import vlnRegisterProgress from "./Volunteer/vlnRegisterProgress";
import branchLocations from "./branch_map/branch_reducers";
import getAllNews from "./media/media_reducers";
import allGallery from "./media/gallery_reducers";
import allgrace from "./media/grace_reducers";
import latePayments from "./latepayment";
import aboutus from "./branch_map/about_us_reducer";
import bag from "./bag/bag";
import favorites from "./favorites/favorites";
import vlnJobs from "./Volunteer/vlnJobs";
import vlnHealths from "./Volunteer/vlnHealths";
import vlnMarriage from "./Volunteer/vlnMarraige";
import volunteerJobsNew from "./Volunteer/volunteerJobsNew";
import vlnRelations from "./Volunteer/vlnRelations";
import countries_proj_sec from './projects/countries'
import projectsForCountries from './projects/projectsPerCountry'
import fullProjects from './projects/fullProjects'
import projectSharesCreate from './projectSharesCreate'
import events from './events'
import finalRecieptDetails from './finalReciept'
import fetchLocallyState from './bag/refetchLocally'
// import loadingState from './bigLoading'
import tutorials_load from "./tutorial/tutorials_load";
import tutorials_fetch from "./tutorial/tutorials_fetch";
import projectShares from "./projectShares";
export default combineReducers({
  orphanSliders,
  allGallery,
  allgrace,
  branchLocations,
  getAllNews,
  projects,
  sukuk,
  compaginsSlider,
  donations,
  humanCases,
  auth,
  user,
  countries,
  countriesForProject,
  countriesForOrphan,
  payment,
  language,
  laCompleted,
  laPending,
  laProfile,
  laRelatives,
  laReturned,
  laTypes,
  vlnFileDteails,
  vlnEventsDetails,
  vlnParticipantsDetails,
  vlnAttachments,
  vlnCourses,
  vlnVolunteerCourses,
  vlnCountries,
  vlnLanguages,
  vlnLevels,
  vlnVolunteerLanguages,
  vlnSkills,
  vlnVolunteerSkills,
  vlnVolunteerInterests,
  vlnInterests,
  vlnEventDetails,
  vlnEvents,
  vlnServices,
  vlnBranches,
  vlnEdu,
  vlnRegisterProgress,
  latePayments,
  aboutus,
  bag,
  favorites,
  vlnJobs,
  vlnHealths,
  vlnMarriage,
  volunteerJobsNew,
  vlnRelations,
  countriesForProjectType,
  projectsCountryDetails,
  countries_proj_sec,
  projectsForCountries,
  fullProjects,
  finalRecieptDetails,
  fetchLocallyState,
  events,
  tutorials_load,
  tutorials_fetch,
  countriesForVolunteers,
  projectShares,
  projectSharesCreate,
  landingPages
});