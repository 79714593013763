import { useState } from 'react'
import './SuccessModal.css'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ErcLogo from "../../../../../assets/ERC_App_Icon_02.png";
import HandsGlobe from "../../../../../assets/hands.png";

const SuccessModal = (props) => {
  const history = useHistory();
    const { setshowCopyCard, ...rest} = props;
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  return (
    <Modal
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='donation_pop_up_body'
    >
      <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px', padding: null, position:'relative', fontFamily: lng==='arab'? "'arab_font":'english_font' }} >
        <div className='share_create_success'>
            <img src={ErcLogo} />
            <img src={HandsGlobe} />
            <label>{t("Thank you for your contribution")}</label>
            <button
            onClick={()=>{history.push('/project_share')}}
                style={
                    {
                    color: "#000000",
                    backgroundColor: "#ffffff",
                    }
                }
                className={`salary_choosen_project_btn_share payment_numbers`}
              >
                {t("View all projects")}
              </button>
              <button
              onClick={() => {
                setshowCopyCard(true);
                props.onHide();
              }}
                style={
                    {
                    color: "#ffffff",
                    backgroundColor: "#ee4236",
                    }
                }
                className={`salary_choosen_project_btn_share payment_numbers`}
              >
                {t("Share your project")}
              </button>
        </div>
        
      </Modal.Body>
    </Modal>
  )
}

export default SuccessModal
