import { FETCH_FAMILY_MEMBERS, FETCH_LOCAL_AID_PROFILE, FETCH_ALL_COMPLETED_REQU, FETCH_ALL_RETURNED_REQU, FETCH_ALL_PENDING_REQU, FETCH_ALL_LOCAL_AID_TYPES, LOAD_RELATIVES, RELATIVES_LOADED, LOAD_LOCAL_AID_PROFILE, LOCAL_AID_PROFILE_LOADED } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getRelatives = (langString, pUserID) => async (dispatch) => {
    dispatch({
        type: LOAD_RELATIVES
    });
    try {
        var { data } = await api.getRelatives(langString, pUserID);
        dispatch({ type: FETCH_FAMILY_MEMBERS, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({
            type: RELATIVES_LOADED
        });
    }
}
export const getLocalAIDProfile = (langString, pUserID) => async (dispatch) => {
    dispatch({ type: LOAD_LOCAL_AID_PROFILE });
    try {
        var { data } = await api.getLocalAidProfile(langString, pUserID);
        dispatch({ type: FETCH_LOCAL_AID_PROFILE, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: LOCAL_AID_PROFILE_LOADED });
    }
}
export const getCompletedRequ = (langString, pUserID) => async (dispatch) => {
    try {
        var { data } = await api.getLocalAidCompletedRequ(langString, pUserID);
        dispatch({ type: FETCH_ALL_COMPLETED_REQU, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}
export const getReturnedRequ = (langString, pUserID) => async (dispatch) => {
    try {
        var { data } = await api.getLocalAidReturnedRequ(langString, pUserID);
        dispatch({ type: FETCH_ALL_RETURNED_REQU, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}
export const getPendingRequ = (langString, pUserID) => async (dispatch) => {
    try {
        var { data } = await api.getLocalAidPendingRequ(langString, pUserID);
        dispatch({ type: FETCH_ALL_PENDING_REQU, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}
export const getLOCALAIDTypes = (langString, PUserID, pRequYear) => async (dispatch) => {
    try {
        var { data } = await api.getLocalAidTypes(langString, PUserID, pRequYear);
        dispatch({ type: FETCH_ALL_LOCAL_AID_TYPES, payload: data });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}