import React, { useState, useEffect } from 'react'
import './Participants.css'
import LocalAidProfileComp from '../../LocalAidMainPage/localAidProfilePage/localAidProfileComp/LocalAidProfileComp'
import icon1 from '../../../assets/family name.png'
import icon4 from '../../../assets/date of birth.png'
import { useTranslation } from "react-i18next";
import { getPatricipantsDetals } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loading from '../../ui/loading'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'

const Participants = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const dispatch = useDispatch()
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getPatricipantsDetals(userLogin?.Results[0]?.vln_id, 1))
            } else {
                dispatch(getPatricipantsDetals(userLogin?.Results[0]?.vln_id, 2))
            }
        }
    }, [lng]);
    const vlnParticipantsDetails = useSelector(state => state.vlnParticipantsDetails.vlnParticipantsDetails);
    const loading = useSelector(state => state.vlnParticipantsDetails.loading);
    const [vlnParticipantDetails, setParticipantDetails] = useState(vlnParticipantsDetails)
    useEffect(() => {
        setParticipantDetails(vlnParticipantsDetails)
    }, [vlnParticipantsDetails])
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='file_details' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Participants")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Participants")}</h3>
                </div>
                <div className='volunteer_portal_lobby_both_sides_participants container'>
                {loading?
                <Loading height='500px' />:
                    <div className='volunteer_portal_lobby_both_sides_participants row'>
                        {vlnParticipantDetails?.participations?.map((item) => {
                             return <div className='volunteer_portal_lobby_left_side_participants col-lg-4 col-md-6 col-sm-12'>
                                     <div className='local_aid_profile_comp' style={{alignItems: 'unset'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}> 
                                        <label><span style={{fontFamily: 'english_font_bold'}}>{t("Event ID: ")}</span><span className='payment_numbers'> {item.id}</span></label>
                                        <label><span style={{fontFamily: 'english_font_bold'}}>{t("Event Name: ")}</span><span> {item.name}</span></label>
                                        <label><span style={{fontFamily: 'english_font_bold'}}>{t("Hour Count: ")}</span><span className='payment_numbers'> {item.hoursCount}</span></label>
                                        <label><span style={{fontFamily: 'english_font_bold'}}>{t("From: ")}</span><span className='payment_numbers'> {new Date(item.dateFrom).toLocaleDateString("en-GB")}</span></label>
                                        <label><span style={{fontFamily: 'english_font_bold'}}>{t("To: ")}</span><span className='payment_numbers'> {new Date(item.dateTo).toLocaleDateString("en-GB")}</span></label>
                                        </div>
                                        </div>
                         </div>
                        })}
                        <div className='volunteer_portal_lobby_left_side'>
                            <LocalAidProfileComp icon={icon1} keyOf={t('Number')} value={vlnParticipantDetails?.totalParticipationsCount} englishFont={true}/>
                        </div>
                        <div className='volunteer_portal_lobby_right_side'>
                            <LocalAidProfileComp icon={icon4} keyOf={t('Hour Count')} value={vlnParticipantDetails?.totalHoursCount} englishFont={true}/>
                        </div>
                    </div>
    }
                </div>
            </div>
        )
    } else {
        window.location.replace("/profile");
    }
}

export default Participants
