import { GET_ALL_MARRIAGE } from "../../constants/actionTypes";

export default (vlnMarriage = [], action) => {
  switch (action.type) {
    case GET_ALL_MARRIAGE:
      return action.payload || [];
    default:
      return vlnMarriage || [];
  }
};
