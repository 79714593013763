import React, { useState, useEffect } from "react";
import './OTPUAE.css'
import "../../loginSignPage/loginSign.css";
import ERCLogo from "../../../../assets/logo.png";
import BCE2mob from "../../../../assets/loginBCMob2.png";
import BCE2 from "../../../../assets/loginBC.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../loginSignPage/loadingPopUp/loadingPopUp";
import { useLocation } from "react-router-dom";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import { signInWithUAEPASS } from "../../../../actions/user";
import * as api from '../../../../api/index'
export const OTPUAE = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [content, setContent] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const location = useLocation();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [contentForLogin, setContentForLogin] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  useEffect(() => {
    if (width > 1200) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }

  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
setLoadingSubmit(true)
let res = await api.signInWithUAEPASS_Setup_OTP({uae_user: {...props.uaeLinkBody, otp: email}})
setLoadingSubmit(false)
if(res.data.status)
{
  dispatch(signInWithUAEPASS(res.data.data, null, props.setLoadingModalShow, null, props.setLoadingSwitch, props.lodaingSwitch, props.setWait, props.setUAEAuth, props.setUAELinkBody, 1))
  props.setUAEAuth(false)
  props.setUAEAuthStep2(false)
}
else
{
  toastifyMessage("Wrong OTP", undefined, "error");

}

// dispatch(signInWithUAEPASS({...props.uaeLinkBody, otp: email}, null, props.setLoadingModalShow, null, props.setLoadingSwitch, props.lodaingSwitch, props.setWait, null, null, 2))

};

  const openFromLog = () => {
    history.push("/");
  };
  return (
    <div
      id="login_sign"
      style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", minHeight: props.finalDonationModal? 'unset':null }}
    >
      <form
        className="login_form"
        onSubmit={handleLoginSubmit}
        style={{ direction: lng === "arab" ? "rtl" : "ltr", filter: "none", width: props.finalDonationModal? '100%':null }}
      >
        <img
          src={ERCLogo}
          onClick={openFromLog}
          alt="ERC_logo"
          className="ERC_logo_login_sign"
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <label>{t("Please enter the OTP")}:</label>
          <br />
        </div>
        
        <div
          className="whole_input"
          style={{
            background: emailError ? "#ee4236" : "transparent",
            transition: "all 1s",
          }}
        >
          <i
            style={{
              fontSize:width>900? "30px":'20px',
              color: emailError ? "white" : "#474646",
              opacity: emailError ? ".55" : "",
              transition: "all 1s",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="far fa-envelope"
          ></i>

          <div
            className="input"
            style={{
              background: "transparent",
            }}
          >
            <label
              htmlFor="email"
              style={{
                color: emailError ? "white" : "#474646",
                // opacity: emailError ? "1" : "",
                transition: "all 1s",
                fontSize:width>500? '15px': '13px'
                
              }}
            >
              {t("OTP")}
            </label>
            <input
              name="email"
              value={email}
              onChange={handleChange}
              type="text"
              className="form-control translateFillMsg"
              id="email"
              style={{
                background: "transparent",
                color: emailError ? "white" : "",
                opacity: emailError ? ".55" : "",
                paddingLeft: "0",
                paddingRight: "0",
                transition: "all 1s",
              }}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
            />
          </div>
        </div>
       
        <button
          type="submit"
          className="btn btn-block btn-danger btn-lg btn_for_form smallBtn_smallDevice"
          style={{
            background: "grey",
            border: "none",
            boxShadow: "none",
          }}
        >
          {loadingSubmit ?  
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

          :
          <span>{t("Submit")} </span>}
        </button>
      </form>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default OTPUAE;
