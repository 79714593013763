import React, { useState, useEffect } from "react";
import icon1 from "../../../../assets/nation id.png";
import icon3 from "../../../../assets/ntionality.png";
import icon4 from "../../../../assets/mobile number.png";
import icon11 from "../../../../assets/email.png";
import icon5 from "../../../../assets/date of birth.png";
import icon6 from "../../../../assets/gender.png";
import icon8 from "../../../../assets/occupation.png";
import { useTranslation } from "react-i18next";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllBranches,
  createVlnAccount,
  getMarriage,
} from "../../../../actions/volunteer";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import {getVolunteerTerms} from "../../../../api/index";
import './vlnMainForm.css'
import Select from 'react-select'
const MainForm = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    container: styles => ({ ...styles,  width: window.innerWidth }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();

  const vlnBranches = useSelector((state) => state.vlnBranches);
  const countriesForVolunteers = useSelector((state) => state.countriesForVolunteers);
  const vlnMarriage = useSelector((state) => state.vlnMarriage);
  const volunteerJobsNew = useSelector((state) => state.volunteerJobsNew);
  const [vlnAllBranches, setVlnAllBranches] = useState(vlnBranches);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [termsLink, setTermsLink] = useState(null);
  useEffect(() => {
    setVlnAllBranches(vlnBranches);
  }, [vlnBranches]);
  useEffect(() => {
    getVolunteerTerms(lng === "arab"? 1: 2).then((res) => {
      setTermsLink(res.data.result)
    });
    
  }, [])
  const [userMainInfoVln, setUserMainInfoVln] = useState({
    volunteerNameEnglish: '',
    volunteerNameArabic: userLogin?.Results[0]?.name_a? userLogin?.Results[0]?.name_a:'',
    mobile: userLogin?.Results[0]?.mobile_no? userLogin?.Results[0]?.mobile_no:'',
    email: userLogin?.Results[0]?.email? userLogin?.Results[0]?.email:'',
    eid: userLogin?.Results[0]?.emiratesID? userLogin?.Results[0]?.emiratesID:"",
    password: "",
    workplaceID: "",
    gender: "",
    dateOfBirth: userLogin?.Results[0]?.DOB? userLogin?.Results[0]?.DOB.split("/")
    .reverse()
    .join("-"):"",
    contactLanguage: "",
    nationality: "",
    job: "",
    placeOfWork: "",
    whatsapp: "",
    maritalStatus: "",
  });

  const handleChange = (e) => {
    document.getElementById("submitBtn_for_local_aid").disabled = false;
    setUserMainInfoVln({ ...userMainInfoVln, [e.target.name]: e.target.value });
  };
  const validateEmail = (email) => {
    const re = new RegExp(
      /^(?:([^<]*?)\s*<)?((?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))>?$/gi
    );
    return re.test(email);
  };

  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    return re.test(phone);
  };

  const validateEmiratesId = (eId) => {
    const re = /^[0-9]{15}$/;
    return re.test(eId);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userMainInfoVln.email === '' || userMainInfoVln.email === null) {
      toastifyMessage('Email field cannot be empty!', undefined, "warning")
      return false;
    } else if (!validateEmail(userMainInfoVln.email)) {
      toastifyMessage('Email is not proper email address.', 'e.g: "someone@example.com"', 'warning');
      return false;
    }

    if (userMainInfoVln.mobile === '' || userMainInfoVln.mobile === null) {
      toastifyMessage("Mobile number field cannot be empty!", undefined, "warning")
      return false;
    } else if (!validateMobile(userMainInfoVln.mobile)) {
      toastifyMessage(
        'Mobile number should be 10 digits long and start with "05"',
        'Mobile number cannot contain characters',
        "warning"
      );
      return false;
    }
    if (userMainInfoVln.eid === '' || userMainInfoVln.eid === null) {
      toastifyMessage("EmiratesID field cannot be empty!", undefined, "warning")
      return false;
    } else if (!validateEmiratesId(userMainInfoVln.eid)) {
      toastifyMessage(
        "EmiratesID number should be 15 digits long",
        undefined,
        "warning"
      );
      return false;
    } else if (userMainInfoVln.nationality === '' || userMainInfoVln.nationality === null) {
      return toastifyMessage("Please choose a nationality", undefined, "warning");
    } else if (userMainInfoVln.job === '' || userMainInfoVln.job === null) {
      return toastifyMessage("Please choose a job", undefined, "warning");
    } else if (userMainInfoVln.placeOfWork.replace(/\s/g,"") === "" || userMainInfoVln.placeOfWork === null) {
      return toastifyMessage("Please choose a place of work", undefined, "warning");
    } else if (userMainInfoVln.maritalStatus === '' || userMainInfoVln.maritalStatus === null) {
      return toastifyMessage("Please choose a marital status", undefined, "warning");
    } else if (userMainInfoVln.volunteerNameEnglish.replace(/\s/g,"") === '' || userMainInfoVln.volunteerNameEnglish === null) {
      return toastifyMessage("Please enter name in English", undefined, "warning");
    } else {
      props.setVolunteerNationality(userMainInfoVln.nationality)
      setLoadingModalShow(true);
    dispatch(
      createVlnAccount(
        userMainInfoVln.volunteerNameArabic,
        userMainInfoVln.volunteerNameEnglish,
        userMainInfoVln.mobile,
        userMainInfoVln.email,
        userMainInfoVln.eid,
        userMainInfoVln.password,
        userMainInfoVln.workplaceID,
        userMainInfoVln.gender,
        userMainInfoVln.dateOfBirth.split("-")
        .reverse()
        .join("/"),
        userMainInfoVln.contactLanguage,
        userMainInfoVln.nationality,
        userMainInfoVln.job,
        userMainInfoVln.placeOfWork,
        userMainInfoVln.whatsapp,
        userMainInfoVln.maritalStatus,
        props.setVlnId,
        props.setView,
        setLoadingModalShow,
        props.setFromState
      )
    );
    }
    
  };
    return (
      <div
        className="local_aid_info_form_page default_min_height"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
          {t("Setup your information")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          <div className="just_the_form_without_submit">
            <div className="form_left_side">
            <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img
                    src={icon11}
                    className="local_aid_icon"
                    alt="form_icon"
                  />
                </div>
                <input
                  type="email"
                  onChange={handleChange}
                  className={userMainInfoVln.email? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userMainInfoVln.email}
                  name="email"
                  placeholder={t("E-mail")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  disabled= {userLogin?.Results[0]?.email}
                />
              </div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userMainInfoVln.volunteerNameEnglish}
                  name="volunteerNameEnglish"
                  placeholder={t("Volunteer name in English (As shown in Emirates ID)")}
                  // disabled = {userLogin?.Results[0]?.name_a}
                />
              </div>
              
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={userMainInfoVln.volunteerNameArabic? "local_aid_input force_arabic_font": lng==='arab'? 'local_aid_input force_arabic_font': "local_aid_input"}
                  value={userMainInfoVln.volunteerNameArabic}
                  name="volunteerNameArabic"
                  placeholder={t("Your name in Arabic")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
              </div>
              <div style={{width:'90%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', gap:'5px', flexDirection:'column'}}>
              <div className="input_wraper" style={{width:'100%'}}>
                <div className="local_aid_input_icon_wraper">
                  <img src={icon5} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="date"
                  onChange={handleChange}
                  className="local_aid_input"
                  value={userMainInfoVln.dateOfBirth}
                  name="dateOfBirth"
                  placeholder={t("Date of Birth")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  // disabled={userLogin?.Results[0]?.DOB}
                  onKeyDown={(e) => {
                    e.preventDefault();
                 }}
                  min={`${new Date().getFullYear() - 100}-01-02`}
                  max={`${new Date().getFullYear() - 18}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
                />
              </div>
              </div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon4} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  maxLength={10}
                  onChange={(e)=> setUserMainInfoVln({ ...userMainInfoVln, mobile: e.target.value.replace(/\D/, "") })}
                  className={userMainInfoVln.mobile? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userMainInfoVln.mobile}
                  name="mobile"
                  placeholder={t("Mobile Number")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  // disabled= {userLogin?.Results[0]?.mobile_no}
                />
              </div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon4} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  maxLength={10}
                  onChange={(e)=> setUserMainInfoVln({ ...userMainInfoVln, whatsapp: e.target.value.replace(/\D/, "") })}
                  className={userMainInfoVln.whatsapp? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userMainInfoVln.whatsapp}
                  name="whatsapp"
                  placeholder={t("Whatsapp Number")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  // disabled= {userLogin?.Results[0]?.mobile_no}
                />
              </div>
              
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <Select placeholder={t("Nationality")} onChange={(e) => handleChange({target: {value: e.value, name: "nationality"}})} styles={customStyles} options={countriesForVolunteers.map((item) => {
                  return {value: item.countryID, label: item.countryName}
                })} />

              </div>

              
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <Select placeholder={t("Job")} onChange={(e) => handleChange({target: {value: e.value, name: "job"}})} styles={customStyles} options={volunteerJobsNew.map((item) => {
                  return {value: item.job_id, label: item.job_name}
                })} />

              </div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userMainInfoVln.placeOfWork}
                  name="placeOfWork"
                  placeholder={t("Place of Work")}
                  // disabled = {userLogin?.Results[0]?.name_a}
                />
              </div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <Select placeholder={t("Marital Status")} onChange={(e) => handleChange({target: {value: e.value, name: "maritalStatus"}})} styles={customStyles} options={vlnMarriage.map((item) => {
                  return {value: item.martstat_id, label: item.martstat_name}
                })} />

              </div>
             
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  maxLength={15}
                  onChange={(e)=> setUserMainInfoVln({ ...userMainInfoVln, eid: e.target.value.replace(/\D/, "") })}
                  className={userMainInfoVln.eid? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userMainInfoVln.eid}
                  name="eid"
                  placeholder={t("National ID")}
                  disabled= {userLogin?.Results[0]?.emiratesID}
                  required
                />
              </div>
              <div className="input_wraper">
                <img src={icon8} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userMainInfoVln.workplaceID}
                  onChange={handleChange}
                  name="workplaceID"
                  className="selectedCourse"
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option key={-1} value="" disabled defaultValue>
                    {t("Select Branch")}
                  </option>
                  {vlnAllBranches?.map((item, i) => (
                    <option key={i} value={item.workplace_id}>
                      {item.workplace_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input_wraper">
                <img src={icon6} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userMainInfoVln.gender}
                  onChange={handleChange}
                  name="gender"
                  className="selectedCourse"
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                >
                  <option value="" disabled defaultValue>
                    {t("Select your gender")}
                  </option>
                  <option value="F">{t("Female")}</option>
                  <option value="M">{t("Male")}</option>
                </select>
              </div>
              
              <div className="input_wraper">
                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userMainInfoVln.contactLanguage}
                  onChange={handleChange}
                  name="contactLanguage"
                  className="selectedCourse"
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Select contact language")}
                  </option>
                  <option value="1">{t("Arabic")}</option>
                  <option value="2">{t("English")}</option>
                </select>
              </div>
            </div>

          </div>
          {termsLink?
          <label className="terms_label">{t("View the")} <a className="terms_anchor" href={termsLink} target="_blank">{t("terms document")}</a> {t("for your reference")}</label>
          :null}
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
          >
            <span style={{ flex: "16" }}>{t("Next")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default MainForm;
