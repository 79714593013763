import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import bc2 from "../../assets/chooseLangModalBC2.png";
import Loading from "../../components/ui/loading";
import bc2Dark from "../../assets/chooseLangModalBC2.png";
import adStyle from './Advertisement.module.css';
function Advertisement(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    let matched = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (matched) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleSkip = () => {
    props.onHide();
  };
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={adStyle.donation_pop_up_body_forWelcome}
    >
      <Modal.Body>
        <div
          className={adStyle.welcome_modal_slideOne}
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          <div
            className={adStyle.back_skip_btns_wraper}
            style={lng === "arab" ? { direction: "rtl", fontFamily:'arab_font' } : {fontFamily:'english_font'}}
          >
            
            <label style={{fontWeight: 'bold', fontSize: 'larger'}}>
              {t(props?.advert?.title)}
            </label>
            <i style={{cursor: 'pointer'}}  onClick={() => props.onHide()} className="fas fa-times"></i>
          </div>
          <React.Fragment>
            
              <img
                style={{ display: "block" }}
                src={props?.advert?.imageUrl}
                alt="modal-slide-img"
                className={adStyle.modalSlideImg}
              />
              

              <div className="someThingLike_Form">
                <label style={{ fontWeight: "bold", fontFamily:lng==='arab'?'arab_font':'english_font' }}>
                  {props?.advert?.description}
                </label>
                
              </div>
            </React.Fragment>
          <div
            className="bottom_of_the_modal"
            style={{ position: "absolute", bottom: "20px" }}
          >
            
            <button onClick={ () =>!props?.advert?.redirecUrl? handleSkip(): window.location.href= props?.advert?.redirecUrl } style={{fontFamily: lng==='arab'? 'arab_font':'english_font'}} className={`${adStyle.moveOn_btn} smallBtn_smallDevice`}>
              {props?.advert?.buttonText ?? "Close"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default Advertisement;
