import React, { Fragment, useEffect, useState } from 'react';
import donate_idea from '../../assets/donate_idea.svg';
import './donate.scss';
import download_img from '../../assets/download_img.svg';
import Loading from '../ui/loading';
import { toastifyMessage } from '../../handlers/toastifyMessage';
import { donateIdea, getCountryCode } from '../../api/index';
import { Modal } from 'react-bootstrap';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { set } from 'date-fns';
import { useTranslation } from 'react-i18next';
const DonateIdea = () => {
  let userLogin = JSON.parse(localStorage.getItem('userLogin'))?.Results || '';
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(userLogin[0]?.name_a || '');
  const [description, setDescription] = useState('');
  const [file64, setFile64] = useState('');
  const [file, setFile] = useState('');
  const [show, setShow] = useState(false);
  const [fileDescription, setFileDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const { t } = useTranslation();

  const handldeSend = async () => {
    if (
      !title ||
      !description ||
      !attachments ||
      attachments.length === 0 ||
      !countryCode
    )
      return toastifyMessage('Please fill all fields', undefined, 'warning');
    try {
      // const files = await readFileAsBase64(fileBase64);

      setLoading(true);
      const apiRequestBody = {
        newIdea: {
          // OrgId: 1,
          // EmpId : 1,
          FullName: title,
          ideaDesc: description,
          UserId: 'WEBAPP',
          CatId: 1,
          ideaAttachments: attachments,
          COUNTRY_ID: countryCode
        }
        // newIdea: {
        //   IdeaTitle: "Hi",
        //   ideaDesc: "Hii",
        //   UserId: "MOBAPP",
        //   CatId: 1,
        //   ideaAttachments: [
        //     {
        //       fileDesc: "anyDesc",
        //       fileName: "anytitle.png",
        //       file_64: "",
        //     },
        //   ],
        // },
      };
      if (apiRequestBody) {
        let res = await donateIdea(apiRequestBody);
        if (res?.data?.status === true) {
          toastifyMessage(
            'Your request has been sent successfully',
            undefined,
            'success'
          );
          setTitle('');
          setFile('');
          setFile64('');
          setDescription('');
          setAttachments([]);
        } else {
          toastifyMessage(
            res?.data?.result || 'Something  went wrong',
            undefined,
            'error'
          );
        }
      }
    } catch (err) {
      toastifyMessage(
        err?.response?.data?.result || 'Something  went wrong',
        undefined,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => setShow(false);
  const handleFileChange = (e) => {
    const files = e.target.files[0];
    if (files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile64(reader.result.toString().split(',')[1]);
        setFile(files.name);
      };
      reader.readAsDataURL(files);
    }
  };
  const handleAttachments = () => {
    if (!fileDescription || !file || !file64)
      return toastifyMessage(
        `${t('Please fill all fields')}`,
        undefined,
        'warning'
      );
    const newAttachment = {
      fileDesc: fileDescription,
      fileName: file,
      file_64: file64
    };

    setAttachments([...attachments, newAttachment]);
    setFileDescription('');
    setShow(false);
  };
  const handleFileDelete = () => {
    const newUploadedFiles = [...attachments];
    newUploadedFiles.splice(deleteIndex, 1);
    setAttachments(newUploadedFiles);
    setDeleteModal(false);
  };
  const handleShoWDelete = (index) => {
    setDeleteModal(true);
    setDeleteIndex(index);
  };

  const getCodes = () => {
    getCountryCode(lng === 'arab' ? '1' : '2').then((res) => {
      if (res?.data?.data) {
        const options = res?.data?.data?.map((item) => {
          return {
            value: item?.countryId,
            label: item?.countryName
          };
        });
        setCountryOptions(options);
      }
    });
  };
  useEffect(() => {
    getCodes();
  }, [loading]);
  return (
    <Fragment>
      <div className="donate_idea">
        <div className="left_side_donate">
          <div className="contents_donate">
            <h1>{t('DONATE YOUR IDEA')}</h1>
          </div>
          <div className="child_donate_main">
            <p>
              {t(
                "Transform your ideas into a force for good! Share your vision and make a difference. Contribute your creative spark by filling out the form below. Let's collaborate to turn your ideas into an impactful reality. Your innovation matters – donate your idea today!"
              )}
            </p>
            <input
              type="text"
              placeholder={t('Full Name')}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <textarea
              type="text"
              placeholder={t('Write your Idea Description')}
              rows={10}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <select
              placeholder={t('Select country')}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option>{t('Select country')}</option>
              {countryOptions.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <div className="file-input-container">
              <div
                style={{
                  padding: ' 10px 10px',
                  backgroundColor: '#efefef',
                  border: 'none',
                  cursor: 'pointer'
                }}
                onClick={() => setShow(true)}
              >
                {t('Click to Upload File')}
              </div>
              {attachments.length > 0 &&
                attachments.map((uploadedFile, index) => (
                  <div
                    key={index}
                    style={{
                      border: 'solid 0.5px lightgrey',
                      marginTop: '1rem',
                      padding: ' 10px'
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <span>{uploadedFile.fileName}</span>
                      <p style={{ fontSize: '13px', textAlign: 'left' }}>
                        {uploadedFile.fileDesc}
                      </p>
                    </div>
                    <i
                      style={{ cursor: 'pointer' }}
                      class="fa fa-times"
                      onClick={() => handleShoWDelete(index)}
                    ></i>
                  </div>
                ))}
            </div>
            <button onClick={handldeSend}>
              {loading ? <Loading height="30px" /> : `${t('SEND')}`}
            </button>
          </div>
        </div>
        <div
          className="right_side_donate"
          style={lng === 'arab' ? { direction: 'rtl' } : null}
        >
          <div className="image_donate">
            <img src={donate_idea} alt="image_donate" />
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          style={lng === 'arab' ? { direction: 'rtl', margin: 0 } : null}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Upload File')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-input-container">
              <div
                style={{
                  padding: ' 10px 10px',
                  backgroundColor: '#efefef',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <input type="file" onChange={handleFileChange} />
                <img src={download_img} alt="download" width="30px" />
              </div>
              {/* </label> */}
              <span style={{ fontSize: '13px', margin: '0px' }}>
                {t('Allowed file types: pdf, png, jpeg, jpg')}
                <br />
              </span>
              <div>
                <input
                  style={{
                    padding: ' 10px 10px',
                    backgroundColor: '#efefef',
                    border: 'none',
                    width: '100%'
                  }}
                  placeholder={t('Description of File')}
                  type="text"
                  onChange={(e) => setFileDescription(e.target.value)}
                  required
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ backgroundColor: '#b11818', border: 'none' }}
              className="btn btn-primary"
              onClick={handleAttachments}
            >
              {t('Add Attachments')}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              {t('Are you sure you want to delete this file?')}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{
                backgroundColor: '#b11818',
                border: 'none',
                width: 'fit-content',
                alignSelf: 'center'
              }}
              className="btn btn-primary m-3 "
              onClick={handleFileDelete}
            >
              {t('Confirm')}
            </button>
            <button
              style={{
                backgroundColor: '#b11818',
                border: 'none',
                width: 'fit-content',
                alignSelf: 'center'
              }}
              className="btn btn-primary m-3 "
              onClick={() => setDeleteModal(false)}
            >
              {t('No')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default DonateIdea;
