import { EVENTS_LOADED, FETCH_VOLUNTEER_EVENTS, LOAD_EVENTS } from '../../constants/actionTypes'

export default (vlnEvents = { vlnEvents: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_VOLUNTEER_EVENTS:
            return { ...vlnEvents, vlnEvents: action.payload, loading: false };
        case LOAD_EVENTS:
            return { ...vlnEvents, loading: true };
        case EVENTS_LOADED:
            return { ...vlnEvents, loading: false }
        default:
            return vlnEvents;
    }
}