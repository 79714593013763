import { FETCH_ALL_BAG_ITEMS, DELETE_FROM_BAG, ADD_ITEM_TO_BAG, LOAD_BAG, BAG_LOADED, REMOVE_ALL_BAG_ITEMS } from '../../constants/actionTypes'

export default (bag = {
    bag: [],
    loading: false
}, action) => {
    switch (action.type) {
        case FETCH_ALL_BAG_ITEMS:
            return { ...bag, bag: action.payload, loading: false }
        // return action.payload;
        case REMOVE_ALL_BAG_ITEMS:
            return {...bag, bag: action.payload, loading: false }
        case ADD_ITEM_TO_BAG:
            return { ...bag, bag: action.payload, loading: false }
        case DELETE_FROM_BAG:
            return { ...bag, bag: action.payload, loading: false }
        case LOAD_BAG:
            return { ...bag, loading: true };
        case BAG_LOADED:
            return { ...bag, loading: false };
        default:
            return bag;
    }
}