import React from 'react'
import './VolunteerPortal.css'
import ProfileBtn from '../../userPage/profileBtn/ProfileBtn'
import fileDetails from '../../../assets/setting.png'
import participats from '../../../assets/deal.png'
import attachments from '../../../assets/download.png'
import trainingCourses from '../../../assets/lines-and-squares.png'
import languages from '../../../assets/world.png'
import skills from '../../../assets/pencil.png'
import areasOfInterest from '../../../assets/like.png'
import events from '../../../assets/event.png'
import services from '../../../assets/online-service.png'
import { useTranslation } from "react-i18next";
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'


const VolunteerPortal = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    if (userLogin?.Results[0]?.vln_id_flag === "2") {
        return (
            <div className='volunteer_portal_lobby' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Volunteer Portal")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Volunteer Portal")}</h3>
                </div>
                <div className='volunteer_portal_lobby_both_sides'>
                    <div className='volunteer_portal_lobby_left_side'>
                        {/* NOTE OLD UI ROUTE */}
                        {/* <ProfileBtn goTo='/volunteer/file-details'  icon={fileDetails} title={t("File Details")} opacity={true}/> */}
                        <ProfileBtn goTo='/volunteer/volunteer-details' icon={fileDetails} title={t("Volunteer Details")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/participants' icon={participats} title={t("Participants")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/attachments' icon={attachments} title={t("Attachments")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/training-courses' icon={trainingCourses} title={t("Training courses")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/languages' icon={languages} title={t("Languages")} opacity={true} />
                    </div>
                    <div className='volunteer_portal_lobby_right_side'>
                        <ProfileBtn goTo='/volunteer/skills' icon={skills} title={t("Skills")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/areas-of-interest' icon={areasOfInterest} title={t("Areas of interest")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/events' icon={events} title={t("Events")} opacity={true} />
                        <ProfileBtn goTo='/volunteer/E-services' icon={services} title={t("E-services")} opacity={true} />
                    </div>
                </div>
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default VolunteerPortal
