/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./ProjectShareCreateDisplay.css";
import ProjectDisplayComp from "../../../projectsAllPage/projectsPage/sections/projectsDisplay/projectDisplaycomp/ProjectDisplayComp";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import cash from "../../../../assets/donateByCash.png";
import CountryProComp from "../../../projectsAllPage/projectsPage/sections/countriesDisplay/countryProjectComp/CountryProComp";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "animate.css";
import { startApplePay } from "../../../../actions/handleApplePayment";
import samPay from "../../../../assets/Pay_button_basic_pos_RGB.png";
import { handleSamsungDirectPayment } from "../../../../actions/handleSamsungPayment";
import Loading from "../../../../components/ui/loading";
import ShareAlertModel from "../../../../components/LoaclComponents/shareHeader/share_model/ShareModelAlert";
import DonationFinalPopUp from "../../../../components/checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import LoadingModal from "../../../../components/Auth/loginSignPage/loadingPopUp/loadingPopUp";
import smsicon from "../../../../assets/samsungpay.png";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import TermsModal from "../../../ui/TermsModal";

const ProjectShareCreateDisplay = (props) => {
  const [showfilterModal, setShowFilterModal] = useState(false)
  const [isPublicProject, setIsPublicProject] = useState(true);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const vlnBranches = useSelector((state) => state.vlnBranches);
  const [workPlace, setWorkPlace] = useState(vlnBranches);
  useEffect(() => {
    setWorkPlace(vlnBranches);
  }, [vlnBranches]);
  const { id } = useParams();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const location = useLocation();
  const [locationState, setLocationState] = useState(location.state);
  useEffect(() => {
    setLocationState(location.state);
  }, [location.state]);
  const history = useHistory();
  const dispatch = useDispatch();
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [copied, setCopied] = useState(false);
  const [selectedIdForShareLocal, setSelectedIdForShareLocal] = useState("all");
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));

  const [displayiedCountries, setDisplayedCountries] = useState([]);
  const [selectedAmount, setSelectedAmounts] = useState("");
  const [amountRanges, setAmountRanges] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const [showCopyCard, setshowCopyCard] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [amountRangesLoading, setAmountRangesLoading] = useState(false);
  const [projectTypeID, setProjectTypeID] = useState("");


  const [view2, setView2] = useState(5);


  const [display, setDisplay] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [maximumAmount, setMaximumAmount] = useState(10);
  const [minimumAmount, setMinimumAmount] = useState(0);

  const [descr, setdescr] = useState({
    title: "",
    body: "",
  });
  const [projectData, setProjectData] = useState({
    projectID: "",
    projectYear: new Date().getFullYear(),
    projectName: "",
    projectImg: "",
    projectAmount: "",
    countryName: "",
    countryID: "",
    countryImg: "",
    workPlaceID: "1",
    title: "",
    donationAmountFromUser: 150,
    public_flag: true
  });
  useEffect(() => {
    setProjectData({...projectData, public_flag: isPublicProject})
  }, [isPublicProject])
  useEffect(() => {
  }, [projectData])
  useEffect(() => {
    setdescr({
      title: "",
      body: "",
    });
    let projectsByTheSameProjectType = props.allProjects?.slice(0);
    let projectWeNeed = projectsByTheSameProjectType.filter(
      (item) => parseInt(item.projTypeID) === parseInt(selectedProject)
    );

    projectWeNeed = projectWeNeed.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.countryID === value.countryID)
    );
    setDisplayedCountries(projectWeNeed);
    if (location.state?.selectedCountry) {
      setSelectedCountry(location.state?.selectedCountry);
      let CountriesWeNeed = displayiedCountries
        .filter((item) => item.countryID === location.state?.selectedCountry)
        .slice(0)
        .shift();
      setProjectData({
        ...projectData,
        projectID: projectData?.projectID,
        projectName: projectData?.name,
        projectImg: projectData?.imagePath,
        projectAmount: projectData?.projectAmount,
        countryName: CountriesWeNeed?.name,
        countryID: CountriesWeNeed?.countryID,
        countryImg: CountriesWeNeed?.imagePath,
        projectYear: new Date().getFullYear(),
        workPlaceID: projectData.workPlaceID,
      });

      setdescr({
        title: projectData?.shortDesc,
        body: projectData?.longDesc,
      });
    } else {
      setSelectedAmounts(0);
      props.setOpenButton(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    let projectsByTheSameProjectType = props.allProjects?.slice(0);
    const operationalArray = projectsByTheSameProjectType?.slice(0);
    const paginationObj = [];
    let projectWeNeed = projectsByTheSameProjectType
      .filter((item) => item.projTypeID === id)
      .slice(0)
      .shift();

    let itemWeSelect = operationalArray
      .filter((item) => item.projTypeID === projectWeNeed?.projTypeID)
      .slice(0)
      .shift();
    var uniqueProjects = [];
    projectsByTheSameProjectType.filter(function (item) {
      var i = uniqueProjects.findIndex((x) => x.projTypeID == item.projTypeID);
      if (i <= -1) {
        uniqueProjects.push(item);
      }
      return null;
    });
    let allTtemsWeNeed = uniqueProjects.filter(
      (item) => item.projTypeID !== itemWeSelect?.projTypeID
    );

    const howMany12Projects = uniqueProjects?.length;
    for (let i = 0; i < howMany12Projects / 12; i++) {
      const arra1 = [];
      for (
        let j = i * 12;
        j <
        ((i + 1) * 12 < howMany12Projects ? (i + 1) * 12 : howMany12Projects);
        j++
      ) {
        arra1.push(uniqueProjects[j]);
      }
      paginationObj.push(arra1);
    }
    if (id === "all") {
      setSelectedProject(
        projectsByTheSameProjectType?.slice(0, 1).shift()?.projTypeID
      );
      setProjectTypeID(
        projectsByTheSameProjectType?.slice(0, 1).shift()?.projTypeID
      );
      setProjectData({
        ...projectData,
        projectID: projectData.projectID,
        projectName: projectsByTheSameProjectType?.slice(0, 1).shift()
          ?.projectName,
        projectImg: projectsByTheSameProjectType?.slice(0, 1).shift()
          ?.projTypeImagePath,
        projectAmount: projectData.projectAmount,
        countryName: projectData.countryName,
        countryID: projectData.countryID,
        countryImg: projectData.countryImg,
        projectYear: new Date().getFullYear(),
        workPlaceID: "1",
      });
      setDisplay(paginationObj);
    } else {
      if (location.state?.selectedProject) {
        paginationObj.splice(0);

        for (let i = 0; i < howMany12Projects / 12; i++) {
          const arra1 = [];
          for (
            let j = i * 12;
            j <
            ((i + 1) * 12 < howMany12Projects
              ? (i + 1) * 12
              : howMany12Projects);
            j++
          ) {
            arra1.push(uniqueProjects[j]);
          }
          paginationObj.push(arra1);
        }
        for (var k = 0; k < paginationObj.length; k++) {
          for (var i = 0; i < paginationObj[k].length; i++) {
            if (paginationObj[k][i]?.projTypeID == itemWeSelect?.projTypeID) {
              paginationObj[k]?.splice(i, 1);
              paginationObj[0]?.unshift(itemWeSelect);
            }
          }
        }

        setSelectedProject(location.state?.selectedProject);
        setProjectTypeID(location.state?.selectedProject);
        setProjectData({
          ...projectData,
          projectID: projectData.projectID,
          projectName: projectWeNeed?.name,
          projectImg: projectWeNeed?.imagePath,
          projectAmount: projectData.projectAmount,
          countryName: projectData.countryName,
          countryID: projectData.countryID,
          countryImg: projectData.countryImg,
          projectYear: new Date().getFullYear(),
          workPlaceID: "1",
        });
      } else if (projectWeNeed == undefined) {
        setSelectedProject(
          projectsByTheSameProjectType?.slice(0, 1).shift()?.projTypeID
        );
        setProjectTypeID(
          projectsByTheSameProjectType?.slice(0, 1).shift()?.projTypeID
        );
        setProjectData({
          ...projectData,
          projectID: projectData.projectID,
          projectName: projectsByTheSameProjectType?.slice(0, 1).shift()?.name,
          projectImg: projectsByTheSameProjectType?.slice(0, 1).shift()
            ?.imagePath,
          projectAmount: projectData.projectAmount,
          countryName: projectData.countryName,
          countryID: projectData.countryID,
          countryImg: projectData.countryImg,
          projectYear: new Date().getFullYear(),
          workPlaceID: "1",
        });
      } else {
        paginationObj.splice(0);

        for (let i = 0; i < howMany12Projects / 12; i++) {
          const arra1 = [];
          for (
            let j = i * 12;
            j <
            ((i + 1) * 12 < howMany12Projects - 1
              ? (i + 1) * 12
              : howMany12Projects - 1);
            j++
          ) {
            arra1.push(allTtemsWeNeed[j]);
          }
          paginationObj.push(arra1);
        }

        paginationObj[0]?.unshift(itemWeSelect);
        paginationObj[paginationObj.length - 1]?.push(
          paginationObj[0][paginationObj[0].length - 1]
        );
        paginationObj[0]?.splice(paginationObj[0].length - 1, 1);

        setSelectedProject(projectWeNeed?.projTypeID);
        setProjectTypeID(projectWeNeed?.projTypeID);
        setProjectData({
          ...projectData,
          projectID: projectData.projectID,
          projectName: projectWeNeed?.name,
          projectImg: projectWeNeed?.imagePath,
          projectAmount: projectData.projectAmount,
          countryName: projectData.countryName,
          countryID: projectData.countryID,
          countryImg: projectData.countryImg,
          projectYear: new Date().getFullYear(),
          workPlaceID: "1",
        });
      }
      setDisplay(paginationObj);
    }

    setMaximumAmount(props.max);
    setMinimumAmount(props.min);
  }, [props.allProjects, id]);

  useEffect(() => {
    if (selectedCountry === "" || selectedProject === "") {
      setAmountRanges([]);
    } else {
      let projectsByTheSameProjectType = props.allProjects?.slice(0);

      let CountriesWeNeed = projectsByTheSameProjectType.filter(
        (item) =>
          parseInt(item.projTypeID) === parseInt(selectedProject) &&
          selectedCountry === item.countryID
      );
      setAmountRanges(CountriesWeNeed?.map((item) => item.amount));
      setProjectData({
        ...projectData,
        projectID: projectData?.projectID,
        projectName: projectData?.name,
        projectImg: projectData?.imagePath,
        projectAmount: projectData?.projectAmount,
        countryName: CountriesWeNeed?.name,
        countryID: CountriesWeNeed?.countryID,
        countryImg: CountriesWeNeed?.imagePath,
        projectYear: new Date().getFullYear(),
        workPlaceID: projectData?.workPlaceID,
      });

      if (locationState) {
        let projectSelected2 = props.allProjects?.filter(
          (item) =>
            item.projTypeID === location.state?.selectedProject &&
            item.countryID === location.state?.selectedCountry
        );

        let indexFromFavShare = projectSelected2
          ?.map((item) => item.amount)
          .indexOf(location.state?.selectedAmount);
        setSelectedAmounts(
          `${location.state?.selectedAmount}_${indexFromFavShare}`
        );
        props.setOpenButton(true);
        let projectSelected = props.allProjects
          ?.filter(
            (item) =>
              item.projTypeID === location.state?.selectedProject &&
              item.countryID === location.state?.selectedCountry &&
              item.amount === location.state?.selectedAmount
          )
          .slice(0, 1)
          .shift();
        setProjectData({
          ...projectData,
          projectID: projectSelected?.projectID,
          projectName: projectSelected?.prjTypeName,
          projectImg: projectSelected?.projectImagePath,
          projectAmount: projectSelected?.amount,
          countryName: projectSelected?.countryName,
          countryID: projectSelected?.countryID,
          countryImg: projectSelected?.countryImagePath,
          projectYear: new Date().getFullYear(),
          workPlaceID: projectData.workPlaceID,
        });
        // setProjectTypeID(location.state?.selectedProject)
        // setSelectedCountry(location.state?.selectedCountry)
        setdescr({
          title: projectSelected?.shortDesc,
          body: projectSelected?.longDesc,
        });
        setLocationState(null);
      } else {
        setdescr({
          title: "",
          body: "",
        });
        setSelectedAmounts(0);
        props.setOpenButton(false);
      }
    }
  }, [selectedCountry, selectedProject]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView2(15);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView2(13);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView2(10);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView2(7.1);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView2(5.4);
    } else if (window.innerWidth > 700 && window.innerWidth <= 1111) {
      setView2(4.2);
    } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
      setView2(4.3);
    } else if (window.innerWidth > 300 && window.innerWidth <= 600) {
      setView2(3.2);
    } else {
      setView2(2);
    }
  }, [width]);
  useEffect(() => {
  }, [isPublicProject])
  const handleChoosenSalary = (str) => {
    setProjectData({ ...projectData, projectAmount: str.amount });
    setSelectedAmounts(`${str.amount}_${str.index}`);
    let projectSelected = props.allProjects
      .filter(
        (item) =>
          item.projTypeID === selectedProject &&
          item.countryID === selectedCountry &&
          item.amount === str.amount
      )
      .slice(0, 1)
      .shift();
    setProjectData({
      ...projectData,
      projectID: projectSelected?.projectID,
      projectYear: new Date().getFullYear(),
      projectName: projectSelected?.prjTypeName,
      projectImg: projectSelected?.projTypeImagePath,
      projectAmount: projectSelected?.amount,
      countryName: projectSelected?.countryName,
      countryID: projectSelected?.countryID,
      countryImg: projectSelected?.countryImagePath,
      workPlaceID: projectData.workPlaceID,
      donationAmountFromUser: 150,
    });
    props.setSelectedIDForShare({
      projectID: projectSelected?.projectID,
      projectName: projectSelected?.prjTypeName,
      projectImg: projectSelected?.projTypeImagePath,
      projectAmount: projectSelected?.amount,
      countryName: projectSelected?.countryName,
      countryID: projectSelected?.countryID,
      countryImg: projectSelected?.countryImagePath,
      projectYear: new Date().getFullYear(),
      workPlaceID: projectData.workPlaceID,
    });
    setdescr({
      title: projectSelected?.shortDesc,
      body: projectSelected?.longDesc,
    });
    props.setDisableUntill({
      status: false,
      dataObject: {
        projectID: projectSelected?.projectID,
        projectName: projectSelected?.prjTypeName,
        projectImg: projectSelected?.projTypeImagePath,
        projectAmount: projectSelected?.amount,
        countryName: projectSelected?.countryName,
        countryID: projectSelected?.countryID,
        countryImg: projectSelected?.countryImagePath,
        projectYear: new Date().getFullYear(),
        projectTypeID: projectSelected?.projTypeID,
        workPlaceID: projectData.workPlaceID,
      },
    });
    props.setOpenButton(true);
  };
  
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: projectData.projectAmount,
      url: window.location.href,
      item: [
        {
          dontype_id: 8,
          share_id: null,
          amount: projectData.donationAmountFromUser,
          share_year: null,
          item_type: 8,
          country_id: projectData.countryID ? projectData.countryID : null,
          template_id: projectData.projectID,
          prj_name: projectData.title,
          projectYear: projectData.projectYear,
          workPlaceID: projectData.workPlaceID,
        },
      ],
    });
    setLoadingModalShow(false);
  };

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };
  const isValid = () => {
    if (!(projectData.donationAmountFromUser >= 5)) {
      toastifyMessage(
        "Can not pay less than 5 AED",
        undefined,
        "warning"
      );
      return false

    } else if (!(parseFloat(projectData.projectAmount) >= parseFloat(projectData.donationAmountFromUser))) {
      toastifyMessage(
        "Can not pay more than project amount",
        undefined,
        "warning"
      );
      return false

    } else if(!projectData.title || projectData.title === "") {
      toastifyMessage(
        "Enter name for project please",
        undefined,
        "warning"
      );
      return false

    } else if(!isTermsChecked) {
      toastifyMessage(
        "Please approve the terms and conditions",
        undefined,
        "warning"
      );
      return false
    } else if(parseInt(projectData.projectAmount) / (parseInt(projectData.projectAmount) > 50000? 5: 10) > parseInt(projectData.donationAmountFromUser)) {
      const error_message =  t("Donation amount must be at least AED ");
      toastifyMessage(
        error_message + Math.ceil((parseInt(projectData.projectAmount) * (parseInt(projectData.projectAmount) > 50000? 5: 10)) / 100),
        undefined,
        "warning"
      );
      return false
    } 
    else
    return true
  }
  return (
    <div
      id="projects_page_display"
      style={lng === "arab" ? { direction: "rtl" } : null}
    >
      <div style={{ width: "100%" }}>
        {props.projectsLoadingState ? (
          <Loading height="600px" />
        ) : (
          <Carousel id="projects_carousel" style={{ width: "100%" }}>
            {display.map((item, i) => (
              <Carousel.Item key={i} interval={15000}>
                <div
                  className="projects_display_for_prjects_page"
                  style={{ minHeight: width <= 900 ? "200px" : "450px" }}
                >
                  {item.map((innerItem, i) => (
                    <ProjectDisplayComp
                      key={i}
                      countryLoading={countryLoading}
                      setProjectTypeID={setProjectTypeID}
                      projectData={projectData}
                      setSelectedIdForShareLocal={setSelectedIdForShareLocal}
                      setProjectData={setProjectData}
                      selected={selectedProject}
                      setSelectedIdForShare={props.setSelectedIDForShare}
                      setSelectedProject={setSelectedProject}
                      projectTypeId={innerItem?.projTypeID}
                      projectId={innerItem?.projTypeID}
                      projectPhoto={innerItem?.projTypeImagePath}
                      title={innerItem?.prjTypeName}
                    />
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
      <div className="country_sliders">
        <h2 className="donation_favorites_title title_to_be_modify">
          {t("Choose a Country")}
        </h2>
        <div
          id="donation_display_mobile"
          style={{ width: "100%", padding: "20px" }}
        >
          {countryLoading ? (
            <div className="Spinner">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">{t("Loading...")}</span>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <Swiper slidesPerView={view2} navigation={true}>
                {displayiedCountries?.map((item, index) => (
                  <SwiperSlide
                    className="orphan_sliders_flags"
                    key={item?.countryID}
                  >
                    <CountryProComp
                      projectData={projectData}
                      setProjectData={setProjectData}
                      style={{ order: index + 1 }}
                      selected={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                      key={item.countryID}
                      CountryId={item.countryID}
                      ImagePath={item.countryImagePath}
                      Name_En={
                        item.countryName.length > 9
                          ? item.countryName.slice(0, 10) + "."
                          : item.countryName
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </React.Fragment>
          )}
        </div>
      </div>
      {selectedCountry ? (
        <div
          className="country_sliders second"
          style={{
            minHeight: "136px",
            padding:
              width > 900 ? "10px 40px 10px 40px" : "20px 10px 20px 10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h2 className="donation_favorites_title title_to_be_modify">
            {t("Choose a branch")}
          </h2>
          <select
            style={{
              // marginLeft: "45px",
              backgroundPositionX: lng === "arab" ? "0px" : "100%",
              width: width <= 900 ? "90%" : null,
            }}
            name="workPlaceID"
            value={projectData.workPlaceID}
            onChange={(e) =>
              setProjectData({ ...projectData, workPlaceID: e.target.value })
            }
            className="select-issue select-form"
          >
            {workPlace?.map((item, i) => (
              <option key={i} value={item.workplace_id}>
                {item.workplace_name}
              </option>
            ))}
          </select>

          <h2 className="donation_favorites_title title_to_be_modify">
            {t("Project Name")}
          </h2>
        <input             className="select-issue"
 style={{
              // marginLeft: "45px",
              backgroundPositionX: lng === "arab" ? "0px" : "100%",
              width: width <= 900 ? "90%" : null,
            }} value={projectData.title}
            onChange={(e) =>
              setProjectData({ ...projectData, title: e.target.value })
            } />

        </div>
      ) : null}

      <div
        className="salary_choosen_projects"
        style={{ padding: !amountRanges.length ? "0px" : null }}
      >
        {amountRangesLoading ? (
          <div className="Spinner">
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">{t("Loading...")}</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {amountRanges.map((item, i) => (
              <button
                key={i}
                style={
                  selectedAmount === `${item}_${i}`
                    ? {
                        transform: "scale(1.03)",
                        color: "#ffffff",
                        backgroundColor: "#ee4236",
                        boxShadow: "rgb(0 0 0 / 67%) 0px 0px 20px 2px",
                      }
                    : null
                }
                id={`btn_of_${i}_of_${selectedCountry}`}
                onClick={() => handleChoosenSalary({ amount: item, index: i })}
                className={`salary_choosen_project_btn payment_numbers`}
              >
                {t("AED")} {numberWithCommas(item)}
              </button>
            ))}
          </div>
        )}
      </div>
      <div
        className="project_desc"
        style={{ padding: !descr.title ? "0px" : null }}
      >
        <label className="project_desc_title">{descr.title}</label>
        <p
          style={
            lng === "arab" ? { textAlign: "right" } : { textAlign: "left" }
          }
          className="project_desc_content"
        >
          {descr.body}
        </p>
      </div>

      
      <div className="full-project-public-private-div">
      {width < 500?
      <label className="switch">
      <input type="checkbox" checked={isPublicProject} onChange={() => setIsPublicProject(!isPublicProject)} />
      <span className="slider_share round"></span>
    </label>:null}
      <div className="project-private-public">
        <h3 style={{fontSize: width < 500? '15px': '',  textShadow: '0px 0px 0px grey'}}>{t("Would you like to share this project publicly?")}</h3>
        <label style={{fontSize: "13px",
    color: "#918e8ede"}}>*{t("Sharing the project to public means the project will be visible on Emirates Red Crescent where others can donate to complete the total project amount")}</label>
        {width >= 500?<div style={{display: 'flex', gap: '40px', padding: '40px'}}>
          <button disabled={isPublicProject} onClick={() => setIsPublicProject(!isPublicProject)} className="project_share_public_btn" style={{backgroundColor: isPublicProject?'rgb(238, 66, 54)': '', color: isPublicProject? 'white': ''}}>{t("Yes, Make it Public")}</button>
          <button disabled={!isPublicProject} onClick={() => setIsPublicProject(!isPublicProject)} className="project_share_public_btn" style={{backgroundColor: isPublicProject? '': 'rgb(238, 66, 54)', color: isPublicProject?'': 'white'}}>{t("No, Make it Private")}</button>
        </div>
      :null}</div>
      </div>

     <div className="project-share-donation">
        <h3 style={{fontSize: width < 500? '15px': '',  textShadow: '0px 0px 0px grey'}}>{t("Donation Amount")}</h3>
        <div className="donation-input-box" style={{backgroundColor: '#80808014', height: '100px'}}>
        <div className="input-box">
        <div className="input-box-field">
          <span className="unit">{t("AED")}</span>
        <input             className="select-issue-share user-pay-share"
 style={{
              // marginLeft: "45px",
              backgroundPositionX: lng === "arab" ? "0px" : "100%",
              width: "80%" ,
            }} value={projectData.donationAmountFromUser}
            onChange={(e) =>
              setProjectData({ ...projectData, donationAmountFromUser: e.target.value })
            } />
        </div>

        </div>
        </div>
      </div> 
     <div className="terms_and_conditions_share" style={{display: 'flex', padding: '40px', width: '100%', gap: "20px"}}>
     <input value={isTermsChecked} onChange={() => setIsTermsChecked(!isTermsChecked)} type="checkbox" />
      <label>{t("I hereby accept the ")} <a onClick={() => setShowFilterModal(true)} className="project_share_terms_link">{t("terms and conditions")}</a></label>
     </div>

      <div className="human_donation_btns">
              <div className="human_donation_btns_above">
               
                


                <div className="btn_with_text">
                  <button
                   style={!props.openButtons ? { filter: "grayscale(100%)" } : null}
                   disabled={!props.openButtons}
                    onClick={() =>
                      {
                       
                      
                        if(isValid())
                        history.push(`/checkout`, {
                          from: "projectShareCreate",
                          elements: [
                            {
                              title: projectData.title,
                              price: projectData.donationAmountFromUser,
                            },
                          ],
                          totalAmount: projectData.donationAmountFromUser,
                          details: projectData,
                        });
                      }
                    }
                    className="human_donate_ways btn3"
                  >
                    <img
                      src={cash}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Donate Now")}</label>
                </div>
              </div>
              {window.ApplePaySession ? 
                <div
                  id="applepay_btn"
                  className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn"
                  locale="ar-AB"
                  style={{borderRadius: '30px'}}
                  onClick={() => {
                    if(isValid()) handleGuestPopUp(()=> startApplePay({
                      amount: projectData.donationAmountFromUser,
                      cart: [
                        {
            share_id: null,
            amount: projectData.donationAmountFromUser,
            share_year: null,
            item_type: 8,
            country_id: projectData.countryID ? projectData.countryID : null,
            template_id: projectData.projectID,
            prj_name: projectData.title,
            cart_serial: null,
            projectYear: projectData.projectYear,
            public_flag: projectData.public_flag,
            workPlaceID: projectData.workPlaceID,

                        },
                      ],
                    }, dispatch, history))
                  }
                    
                  }
                ></div>
               :  
                <button
                disabled={!props.openButtons}
                style={
                  !props.openButtons
                    ? {
                        filter: "grayscale(100%)",
                        width: "80%",
                        backgroundColor: "black",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                    : {
                        width: "80%",
                        backgroundColor: "black",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                }
                  onClick={() => {
                     if(isValid())
                    handleGuestPopUp(startSamsungPay)}}
                  className="btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn"
                >
                  <img
                    src={samPay}
                    style={{
                      height: "60px",
                      width: width <= 300 ? "100%" : "auto",
                    }}
                    alt="samsung_pay"
                    className='samApple_btn_img'
                  />
                </button>
              }
            </div>
      <ShareAlertModel
        path="projects"
        shareData={{ ...projectData, projectTypeID: projectTypeID }}
        show={showCopyCard}
        onHide={() => {
          setshowCopyCard(false);
        }}
      ></ShareAlertModel>
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <TermsModal
      url={"https://mobapp.rcuae.ae/MOBAPP_V3_PROD/project_terms.aspx"}
        show={showfilterModal}
            dialogClassName="modal-60w"
            backdrop="static"
            onHide={() => {
              setShowFilterModal(false)
            }}  />
            
    </div>
  );
};

export default ProjectShareCreateDisplay;
