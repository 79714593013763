/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import './sukuk.css'
import SukukCom from "./sukukCom/sukukCom";
import SukukComMob from "./sukukCom/sukukComMob";
import Carousel from 'react-bootstrap/Carousel'
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag, handleAddToBag } from '../../../../actions/BagFav'
import Loading from "../../../ui/loading";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import { addToFavorites, deleteFromFavorites } from '../../../../actions/BagFav'
import {FETCH_LOCALLY} from '../../../../constants/actionTypes'

SwiperCore.use([Navigation, Pagination])

const Sukuk = () => {
    const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [view, setView] = useState(1.2)
    const sukuk = useSelector(state => state.sukuk.sukuk);
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const userBag = useSelector(state => state.bag.bag)
    const dispatch = useDispatch()
    const [bagDetails, setBagDetails] = useState(userBag)
    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const [change, setChange] = useState(0)
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    const userFav = useSelector(state => state.favorites.favorites)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    const [addingToFav, setAddingToFav] = useState(false);
    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])
    
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(() => {
        if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
            setView(2)
        } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
            setView(1.6)
        } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
            setView(1.1)
        } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
            setView(1.2)
        } else if (window.innerWidth > 330 && window.innerWidth <= 700) {
            setView(1.08)
        } else {
            setView(1)
        }
    }, [width])
    const handleLike = async (sukukID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile.liked.sukuks.push(sukukID)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: sukukID.sukId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
        }
    }
    const handleDisLike = async (sukukID) => {
        if (!userLogin) {
            let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations, sukuks: currentProfile.liked.sukuks.filter(item => item.sukId !== sukukID.sukId), projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans } }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            let elementWeNeed = favoritesDetails?.sukuks?.filter(item => item.dontypeID === sukukID.sukId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }
    }

    const handleaddToBag = async (sukukID, setAddingToCart, setAnimationClass, setInTheBagText) => {
        setAddingToCart(true)
        await handleAddToBag("sukuks", sukukID, setChange, change, dispatch)
        if (_isMounted.current) {
           setAnimationClass("btn_with_text animate__animated animate__bounceOut")
        setTimeout(() => {
            if (_isMounted.current) {
               setAnimationClass("btn_with_text animate__animated animate__bounceIn")
          setAddingToCart(false);
          setInTheBagText("Add to Bag"); 
            }
          
          //setInTheBagText('Added')
        }, 1000);
         setTimeout(() => {
            if (_isMounted.current) {
                setAnimationClass("btn_with_text");
            }
          
          //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
          //setInTheBagText("Add to Bag");
         }, 2000); 
        }
        

    }
    // const handleRemoveFromBag = async (sukukID) => {
    //     if (!userLogin) {
    //         let newProfile = { cart: { donations: currentProfile.cart.donations, sukuks: currentProfile.cart.sukuks.filter(item => !(item.sukId === sukukID.sukId && parseInt(item.baseAmount) === parseInt(sukukID.amount) && item.donationFreq === sukukID.donationFreq)), projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
    //         localStorage.setItem('profile', JSON.stringify(newProfile))
    //         setChange(change + 1)
    //     } else {
    //         let elementWeNeed = bagDetails?.sukuks?.filter(item => item.dontypeID === sukukID.sukId && parseInt(item.baseAmount) === parseInt(sukukID.amount) && item.recurring === sukukID.donationFreq).slice(0, 1).shift()
    //         if (elementWeNeed) {
    //             await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1))
    //         }
    //     }
    // }
    return (
        <section id='sukuk_sec' >
            <label className='all_section_title' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontSize: '25px', width: '100%', textAlign: 'left' }}>{t('Sukuk / Vouchers')}</label>
            <div className='sukuk_display' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                {sukuk?.map(item =>
                    <SukukCom recurring={item.recurring==='N'? true:false} donationID={item.dontypeID} handleaddToBag={handleaddToBag} handleLike={handleLike} handleDisLike={handleDisLike} currentProfile={currentProfile} key={item.dontypeID} title={item.dontypeName} sukukImg={item.imagePath} amount={item.amount} />
                )}
            </div>
            {
                sukuk.length > 0 ?
                    <div style={lng === 'arab' ? { direction: 'rtl', width: '100%' } : { width: '100%' }}>
                        <React.Fragment>
                            <Swiper loop={sukuk?.length > view? true:false} id='sukuk_slider' spaceBetween={10} slidesPerView={view} navigation={true} pagination={{ clickable: true }}>
                                {sukuk?.map(item =>
                                    <SwiperSlide key={`suk_mob_${item.dontypeID}`}>
                                        <SukukComMob recurring={item.recurring==='N'? true:false} donationID={item.dontypeID} handleaddToBag={handleaddToBag}  handleLike={handleLike} handleDisLike={handleDisLike} key={item.dontypeID} currentProfile={currentProfile} title={item.dontypeName} sukukImg={item.imagePath} amount={item.amount} />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </React.Fragment>
                    </div> : <Loading />}

        </section>
    )
}
export default Sukuk