//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//Translations
import { useTranslation } from "react-i18next";
//Assets
import footer from "../../assets/pdf/Footer.png";
import headerAr from "../../assets/pdf/Header-AR.png";
import UserPlaceholder from "../../assets/user-placeholder.jpeg";
//Actions
import { getSPFollowups, getSPOrphonsList } from "../../actions/user";
//CSS
import "./SPPDF.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Spinner } from "react-bootstrap";

const SPPDF = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingImage, setLoadingImage] = useState(true);
  const ref = React.createRef();
  const [orphanDetails, setOrphanDetails] = useState(null);
  const [followupsReport, setFollowupsReport] = useState(null);
  const [sponsorId, setSponsorId] = useState(null);
  const [orphanId, setOrphanId] = useState(null);
  const [repSerial, setRepSerial] = useState(null);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const logOut = () => {
    history.push("/");
  };
  const [toBeTrue, setToBeTrue] = useState(true);
  let allReports = props?.history?.location?.state?.followups;
  let myorphons = props?.history?.location?.state?.orphonsList ?? [];
  useEffect(() => {
    if (props.location.search) {
      
        let qParms = props.location.search.replace("?", "");
        let qObj = JSON.parse(
          '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        setSponsorId(qObj.sponser_id)
        setOrphanId(qObj.orphon_id)
        setRepSerial(qObj.repSerial)
        if (qObj && qObj.orphon_id && qObj.sponser_id && qObj.language && qObj.repSerial) {}
      else
    {
      logOut();
    }
    }
    else
    {
      logOut();
    }
  }, []);
  useEffect(() => {
    if (orphanId && myorphons.length === 0) {
      dispatch(
        getSPFollowups(
          sponsorId,
          lng === "arab" ? "ARA" : "ENG",
          orphanId
        )
      );
    }
  }, [orphanId]);

  if(myorphons.length === 0)
  {
    myorphons = useSelector((state) => {return state.auth.myorphons;});
    allReports = useSelector((state) => {
      return state.auth.followupsReports;
    });
  }
  useEffect(() => {
    if(sponsorId && myorphons.length === 0)
    {
      dispatch(
        getSPOrphonsList(sponsorId, lng === "arab" ? "ARA" : "ENG")
      );
    }
  }, [sponsorId]);

  
    useEffect(() => {
      if(myorphons && myorphons?.length > 0 && allReports.length > 0 && repSerial)
        {
          loadFollowups();
        }
    }, [myorphons, allReports, repSerial])
  
  
  useEffect(() => {
   
    if (followupsReport != null) setToBeTrue(false);
  }, [followupsReport]);

  function loadFollowups() {
    setOrphanDetails(myorphons?.filter((item) => item.orp_id === orphanId).shift());
    setFollowupsReport(allReports.filter((item) => item.REP_SERIAL === repSerial).shift());
    }
 

  const RowWithFixedPositions = (props) => {
    if(props.customValue !== "N/A")
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <div
          style={{
            width: "50%",
            color: "gray",
            lineHeight: "20px",
          }}
        >
          {props.customKey}
        </div>
        <div
          style={{
            width: "10%",
          }}
        >
          :
        </div>
        <div
          style={{
            width: "40%",
          }}
          className={props.statusNumber? 'payment_numbers':''}
        >
          {props.customValue}
        </div>
      </div>
    );
    return null
  };

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 1150px)").matches
  );

  useEffect(() => {
    const handler = (e) => {
      setIsMobile(e.matches);
    };
    window
      .matchMedia("(max-width: 1150px)")
      .addEventListener("change", handler);
  }, []);

  const staticImageUrl =
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80";

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      // allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4", false);
      pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
      pdf.save("download.pdf");
    });
  };

  // if (userInfo) {
  return (
    <>
      {/* <button onClick={printDocument}>Save PDF</button> */}
      <div id="divToPrint" ref={ref}>
        <div>
          <img id="orphanImage" src={headerAr} style={{ width: "100%" }} />
        </div>
        {!toBeTrue ? (
          <div className="sppdf-body">
            <div className="report-header">
              <div
                className="report-header-details"
                style={{
                  flexDirection: lng === "arab" ? "row-reverse" : "row",
                }}
              >
                <div
                  className="report-user-details"
                  style={{
                    width: isMobile ? "100%" : "50%",
                    flexDirection: lng === "arab" ? "row-reverse" : "row",
                  }}
                >
                  <div>
                    <img
                      src={
                        followupsReport?.REPORT_IMAGE != null
                          ? followupsReport?.REPORT_IMAGE
                          : UserPlaceholder
                      }
                      alt="Orphan"
                      style={{
                        width: "150px",
                        display: loadingImage ? "none" : "block",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                      onLoad={() => setLoadingImage(false)}
                    />
                    {loadingImage ? (
                      <div
                        style={{
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      >
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div
                    className="report-user-data"
                    style={{
                      direction: lng === "arab" ? "rtl" : "ltr",
                    }}
                  >
                    <span className="report-user-name reportHeaderStyle">
                      {orphanDetails?.orp_name != null
                        ? orphanDetails?.orp_name
                        : t("N/A")}
                    </span>
                    <div className="report-user-nationality">
                      <span className="fadedOutReport">
                        {t("Nationality")}:
                      </span>
                      <span
                        style={{
                          flexGrow: "1",
                          textAlign: "end",
                        }}
                      >
                        {orphanDetails?.country_name != null
                          ? orphanDetails?.country_name
                          : t("N/A")}
                      </span>
                    </div>
                    <div className="report-user-DOB">
                      <span className="fadedOutReport">
                        {t("Date of birth")}:
                      </span>
                      <span
                      className='payment_numbers'
                        style={{
                          flexGrow: "1",
                          textAlign: "end",
                        }}
                      >
                        {orphanDetails?.DOB != null
                            ? orphanDetails?.DOB
                            : t("N/A")}
                      </span>
                    </div>
                    <div className="report-user-adoption-date">
                      <span className="fadedOutReport">
                        {t("Sponsor date")}:
                      </span>
                      <span
                      className='payment_numbers'
                        style={{
                          flexGrow: "1",
                          textAlign: "end",
                        }}
                      >
                        {orphanDetails?.trn_date != null
                          ? 
                              orphanDetails?.trn_date
                          : t("N/A")}
                      </span>
                    </div>
                    {isMobile && (
                      <>
                        <div className="report-date-value">
                          <span className="fadedOutReport">
                            {t("Report date")}:
                          </span>
                          <span
                          className='payment_numbers'
                            style={{
                              flexGrow: "1",
                              textAlign: "end",
                            }}
                          >
                            {followupsReport?.REP_DATE != null
                              ? new Date(
                                  followupsReport?.REP_DATE
                                ).toLocaleDateString("en-US")
                              : t("N/A")}
                          </span>
                        </div>
                        <div className="report-date-number">
                          <span className="fadedOutReport">
                            {t("Beneficiary number")}:
                          </span>
                          <span
                          className='payment_numbers'
                            style={{
                              flexGrow: "1",
                              textAlign: "end",
                            }}
                          >
                            {orphanDetails?.orp_id
                              ? orphanDetails?.orp_id
                              : t("N/A")}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!isMobile && (
                  <div
                    className="report-date"
                    style={{
                      direction: lng === "arab" ? "rtl" : "ltr",
                    }}
                  >
                    <div className="report-date-value">
                      <span className="fadedOutReport">
                        {t("Report date")}:
                      </span>
                      <span className='payment_numbers'>
                        {followupsReport?.REP_DATE != null
                          ? new Date(
                              followupsReport?.REP_DATE
                            ).toLocaleDateString("en-US")
                          : t("N/A")}
                      </span>
                    </div>
                    <div className="report-date-number">
                      <span className="fadedOutReport">
                        {t("Beneficiary number")}:
                      </span>
                      <span className='payment_numbers'>
                        {orphanDetails?.orp_id != null
                          ? orphanDetails?.orp_id
                          : t("N/A")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <hr
                  style={{
                    width: "80vw",
                    margin: "20px auto",
                  }}
                />
              </div>
            </div>
            <div
              className="report-content"
              style={{
                display: "flex",
                flexDirection: "column",
                direction: lng === "arab" ? "rtl" : "ltr",
              }}
            >
              <div className="report-content-intro">
                <h5
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {t("Beneficiary message")}
                </h5>
                <span
                  style={{
                    fontWeight: "200",
                  }}
                >
                  {followupsReport?.ORP_MESSAGE != null
                    ? followupsReport?.ORP_MESSAGE
                    : t("N/A")}
                </span>
              </div>
              <div className="report-content-info">
                <div className="report-content-info-left">
                <h5
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    {t("Family situation")}
                  </h5>
                  <div className="report-family-details">
                    
                    
                    <RowWithFixedPositions
                      customKey={t("Is the mother alive")}
                      customValue={
                        followupsReport?.MOTHER_ALIVE != null
                          ? followupsReport?.MOTHER_ALIVE == "Y"
                            ? t("Yes")
                            : t("No")
                          : t("N/A")
                      }
                    />
                    <RowWithFixedPositions
                      customKey={t("Family Members")}
                      statusNumber = {true}
                      customValue={
                        followupsReport?.FAMILY_COUNT != null
                          ? followupsReport?.FAMILY_COUNT
                          : t("N/A")
                      }
                    />
                    
                    
                    
                  </div>
                  <h5
                    style={{
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    {t("Health Background")}
                  </h5>
                  <div className="report-family-details">
                    
                    
                    <RowWithFixedPositions
                      customKey={t("Health Condition")}
                      customValue={
                        followupsReport?.HEALTH_STAT_NAME_A != null
                          ? followupsReport?.HEALTH_STAT_NAME_A
                          : t("N/A")
                      }
                    />
                    <RowWithFixedPositions
                      customKey={t("Needs Medicine")}
                      statusNumber = {true}
                      customValue={
                        followupsReport?.MEDICINE_NEEDS != null
                          ? followupsReport?.MEDICINE_NEEDS == "Y"
                            ? t("Yes")
                            : t("No")
                          : t("N/A")
                      }
                    />
                     <RowWithFixedPositions
                      customKey={t("Sickness Name")}
                      customValue={
                        followupsReport?.SICKNESS_NAME != null
                          ? followupsReport?.SICKNESS_NAME
                          : t("N/A")
                      }
                    />
                    
                    
                    
                  </div>
                </div>
                <div className="report-content-info-right">
                  <h5
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    {t("Educational background")}
                  </h5>
                  <div className="report-family-details">
                  <RowWithFixedPositions
                      customKey={t("Is Studying")}
                      customValue={
                        followupsReport?.STUDY_FLAG != null
                          ? followupsReport?.STUDY_FLAG
                          : t("N/A")
                      }
                    />
                    
                    <RowWithFixedPositions
                      customKey={t("Education Stage")}
                      customValue={
                        followupsReport?.STUDY_LEVEL != null
                          ? followupsReport?.STUDY_LEVEL
                          : t("N/A")
                      }
                    />
                    <RowWithFixedPositions
                      customKey={t("Study Type")}
                      customValue={
                        followupsReport?.STUDY_TYPE != null
                          ? followupsReport?.STUDY_TYPE
                          : t("N/A")
                      }
                    />
                    <RowWithFixedPositions
                      customKey={t("Hobbies")}
                      customValue={
                        followupsReport?.ORP_HOBBIES != null
                          ? followupsReport?.ORP_HOBBIES
                          : t("N/A")
                      }
                    />
                    <RowWithFixedPositions
                      customKey={t("Needs")}
                      customValue={
                        followupsReport?.ORP_NEEDS != null
                          ? followupsReport?.ORP_NEEDS
                          : t("N/A")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="report-content-more-info">
                <h5
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {t("Supervisor message")}
                </h5>
                <span
                  style={{
                    fontWeight: "200",
                  }}
                >
                  {followupsReport?.OFFICE_REMARKS != null
                    ? followupsReport?.OFFICE_REMARKS
                    : t("N/A")}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              minHeight: "50vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="grow" />
          </div>
        )}
        <div>
          <img src={footer} style={{ width: "100%" }} />
        </div>
      </div>
    </>
  );
  // } else {
  //   window.location.replace("/");
  // }
};

export default SPPDF;
