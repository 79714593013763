import React, { useState, useEffect } from 'react'
import './SubmitRequ.css'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getLOCALAIDTypes } from '../../../actions/localAID'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import { getAidConstraint, submitLocalAidRequest } from '../../../api';
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
    applyValidation,
    resetValidation,
} from "../../../handlers/formValidation";
const SubmitRequ = () => {
    const [constraint, setConstraint] = useState(null)

    const [prisonerName, setPrisonerName] = useState(null)
    const [prisonerEID, setPrisonerEID] = useState(null)
    const [prisonerMobile, setPrisonerMobile] = useState(null)
    const [isTermsChecked, setIsTermsChecked] = useState(false)
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const [imgField, setImgField] = useState('');
    const [requFormData, setRequFormData] = useState({
        personId: userLogin?.Results[0]?.person_id,
        type: '',
        details: '',
        attachments: [],
        P_CREDITOR_NAME: "",
        P_CREDITOR_ID: "",
        P_CREDITOR_MOBILE: "",
    })
    const [attcahmentObj, setAttachmentObj] = useState({
        att_desc: "",
        file_type: "",
        file_64: "",
        img_pre: ''
    });
    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setAttachmentObj({ ...attcahmentObj, file_64: reader.result.toString().split(',')[1], file_type: file.type.split('/')[1], img_pre: reader.result.toString().split(',')[0]});
        };
        reader.onerror = function (error) {
        };
    }
    const handleChangeFile = async (e) => {
        if(e.target.files[0].size > constraint?.max_size * 1048576)
        {
            toastifyMessage("File is too big to upload. Max size allowed is: " + constraint?.max_size, undefined, "error");
            return false;
        }
        getBase64(e.target.files[0]);
        setImgField(e.target.value);

    }
    const downloadAgreement = () => {
        window.open('https://www.emiratesrc.ae/ercdoc.pdf', '_blank');
        // window.location.href = "https://www.emiratesrc.ae/ercdoc.pdf"
    }
    const add = () => {
        if (attcahmentObj.file_64 == null || attcahmentObj.file_64 == "") {
            toastifyMessage("Choose file", undefined, "error");
            return false;
        }
        else if (attcahmentObj.att_desc == null || attcahmentObj.att_desc == "") {
            toastifyMessage("Please enter description", undefined, "error");
            return false;
        }

        var temp = requFormData.attachments;
        if(temp.length < constraint?.max_number_of_files)
        {

        temp.push(attcahmentObj);
        setRequFormData({ ...requFormData, attachments: temp })
        setAttachmentObj({
            att_desc: "",
            file_type: "",
            file_64: "",
            img_pre: ''
        })
        setImgField("");
        }
        else
        {
            toastifyMessage("Maximum file limit reached. Please remove a file or submit", undefined, "error");
            return false;
        }
    }
    const removeFile = (i) => {
        var temp = requFormData.attachments;
        temp.splice(i, 1)
        setRequFormData({ ...requFormData, attachments: temp });
    }
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch();
    useEffect(() => {
        if (userLogin?.Results[0]?.person_id !== '') {
            if (lng === 'arab') {
                dispatch(getLOCALAIDTypes('AR', userLogin?.Results[0]?.person_id, userLogin?.Results[0]?.aids_req_year))
            } else {
                dispatch(getLOCALAIDTypes('EN', userLogin?.Results[0]?.person_id, userLogin?.Results[0]?.aids_req_year))
            }
        }
    }, [lng]);
    const laTypes = useSelector(state => state.laTypes);
    const [localAIDTypes, setLocalAIDTypes] = useState(laTypes?.AIDSTYPE_ALL?.slice(0))
    useEffect(() => {
        setLocalAIDTypes(laTypes?.AIDSTYPE_ALL?.slice(0))
    }, [laTypes])
    const handleChange = (e) => {
        setRequFormData({ ...requFormData, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        //console.dir(requFormData);
        if (requFormData.type == "" || requFormData.type == null || requFormData.details == "" || requFormData.details == null) {
            toastifyMessage(
                "All fields are mandatory",
                undefined,
                "error"
            );
        }
        else if (requFormData.attachments.length == 0) {
            toastifyMessage("Choose file", undefined, "error");
            return false;
        }
        else if (!isTermsChecked) {
            toastifyMessage("Please approve the terms and conditions", undefined, "error");
            return false;
        }
        else {
            setRequFormData({...requFormData, attachments: requFormData.attachments.map((item) => {
                delete item['img_pre']
                return item
            })})
            submitLocalAidRequest({aid_data: requFormData}).then(res => {
                if(res.data.status)
                    toastifyMessage("Your request has been successfully submitted", undefined, "success");
                else
                    toastifyMessage(res.data.result, undefined, "error");

            }).catch(err => {
                toastifyMessage("Something went wrong...!", undefined, "error");
            })
        }
    }
    useEffect(() => {
        getAidConstraint().then((res) => {
            setConstraint(res.data.data)
        })
    }, [])
    if (userLogin?.Results[0]?.person_id !== '') {
        return (
            <div className='submit_requ_page' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Submit a request")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Submit a request")}</h3>
                </div>
                <div className='submitRequestWraper'>
                    <div className='left_side_of_submit_requ_form'>
                        <div className='selectItemInForm'>
                            <label htmlFor='aid_type'>{t("Please select the aid type")}</label>
                            <select value={requFormData.type} onChange={handleChange} name="type" id="aid_type" required>
                                <option value='' disabled defaultValue>{t("Aid type")}</option>
                                {localAIDTypes?.map(item =>
                                    <option key={item.AIDTYPE_ID} value={item.AIDTYPE_ID}>{item.AIDTYPE_NAME_A}</option>
                                )}
                            </select>
                        </div>
                        
                        <div className='textAreaItemInForm'>
                        {requFormData?.type === "43"?
                            <React.Fragment>
                            <label htmlFor='requ_details'>{t("Prisoner Name")}</label>
                            <input name="P_CREDITOR_NAME" value={requFormData.P_CREDITOR_NAME} onChange={handleChange} className='prisoner_inputs' />

                            <label htmlFor='requ_details'>{t("Prisoner Emirates ID")}</label>
                            <input name="P_CREDITOR_ID" value={requFormData.P_CREDITOR_ID}  onChange={handleChange} className='prisoner_inputs' />

                            <label htmlFor='requ_details'>{t("Prisoner Mobile Number")}</label>
                            <input name="P_CREDITOR_MOBILE" value={requFormData.P_CREDITOR_MOBILE} onChange={handleChange}  className='prisoner_inputs' />
                            </React.Fragment>
                        :null}
                            <label htmlFor='requ_details'>{t("Note / Request Details")}</label>
                            <textarea value={requFormData.details} onChange={handleChange} id="requ_details" name="details" rows="10" required />
                        </div>
                        <label style={{ fontWeight: 'bold', padding: '20px' }}>{t("Note")}: <span style={{ fontWeight: 'normal' }}>{t("Submitting requests for students aids is only for one student")}</span></label>
                    </div>
                    
                    <div className='right_side_of_submit_requ_form'>
                    <div className="form_left_side">
                    
                    
                    <div>
                    <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold", alignSelf:'flex-start' }}>
                    {t("Upload attachments and agreement")}
                    </label>
                        <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold", alignSelf:'flex-start', fontSize: '10px' }}>
                        {t("Max File Size: ")} <span className='payment_numbers' style={{color:'red'}}>{constraint?.max_size + "MB"}</span>
                        </label>
                        <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold", alignSelf:'flex-start', fontSize: '10px' }}>
                        {t("Max Number of files: ")} <span className='payment_numbers' style={{color:'red'}}>{constraint?.max_number_of_files}</span>
                        </label>
                    </div>
                    
                    <div className="input_wraper">
                        <input
                            type="file"
                            onChange={handleChangeFile}
                            className="local_aid_input"
                            value={imgField}
                            name="PO_BOX"
                            placeholder={t("Choose file")}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                        />
                    </div>
                    <div className="form_right_side">
                    <div className="input_wraper">
                        <input
                            type="text"
                            onChange={(e) => setAttachmentObj({...attcahmentObj, att_desc: e.target.value})}
                            className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                            value={attcahmentObj.att_desc}
                            name="att_desc"
                            placeholder={t("Description")}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                        />
                    </div>
                </div>
                <button onClick={add} className='upload_button'>{t("ADD")}</button>
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', flexWrap:'wrap', gap:'10px'}} className="selected-images">
                {requFormData.attachments?.map((item, i) => (
                    <div key={i} style={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center", width:'auto', gap: '7px', padding:'10px', flexWrap:'wrap'}} className="image-row">
                        <img src={`${item.img_pre},${item.file_64}`} style={{ width: "150px", height: "100px" }} alt="" />
                        <p style={{textAlign:'center', margin:'0px', fontWeight:'bold'}}>{item.att_desc}</p>
                        <button onClick={() => removeFile(i)} style={{ background: "#ee4236", color: "white", border: "none" }}>{t("Delete")}</button>
                    </div>
                ))}
                
            </div>
            
            </div>
            <div className="form_left_side">
                    
                    <label style={{ fontWeight: "bold" }}>
                    {t("Download Agreement")}
                    </label>
                   
                <button onClick={downloadAgreement} className='upload_agreement_button'>{t("Click Here to Download the Agreement")}</button>

               
            
            </div>
                    </div>
                
                
                    <div className='right_side_of_submit_requ_form' style={{flexDirection: 'column'}}>
                    <div className="terms_and_conditions_share" style={{display: 'flex', fontSize: '1rem', width: '100%', gap: "20px", justifyContent: 'center'}}>
     <input value={isTermsChecked} onChange={() => setIsTermsChecked(!isTermsChecked)} type="checkbox" />
      <label>{t("I hereby accept the ")} <a href='https://emiratesrc.ae/en/terms.aspx' target="_blank" className="project_share_terms_link">{t("terms and conditions")}</a></label>
     </div>
                        <button onClick={handleSubmit} style={{ width: '80%', alignSelf: 'flex-end', alignSelf: 'center' }} type='button' className='local_aid_submit'>
                        
                            <span style={{ flex: '16' }}>{t("Submit")}</span>
                            {lng === 'arab' ?
                                <i className="fas fa-arrow-left"></i> :
                                <i className="fas fa-arrow-right"></i>
                            }

                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        window.location.replace("/");
    }

}

export default SubmitRequ
