import React, {useState, useEffect} from 'react'
import './RequestsLobby.css'
import Pending from './RequPenging/Pending'
import Returned from './ReuqhReturned/Returned'
import Completed from './RequCompleted/Completed'
import { useTranslation } from "react-i18next";
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'

const RequestsLobby = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [displayedSection, setDisplayedSection] = useState(0)
    const [selectedNaviItem, setSelectedNaviItem] = useState('pending')


    const handleNavigation = (str) => {
        if (str==='pending') {
            setDisplayedSection(0)
        } else if (str==='completed') {
            setDisplayedSection(1)
        } else {
            setDisplayedSection(2)
        }
        setSelectedNaviItem(str)
    }

    useEffect(()=> {
        var elements = document.getElementsByClassName('requestsLobby_navi_item')
        for (let i=0; i<elements.length; i++) {
            elements[i].style.filter = 'grayscale(100%)'
        }
        document.getElementById(selectedNaviItem).style.filter='unset'
    }, [selectedNaviItem])

    const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

    if (userLogin?.Results[0]?.person_id_flag==='2') {
        return (
        <div className='requestsLobby' style={lng==='arab'? {direction:'rtl'}:null}>
            <ShareHeader pageName={t("Local Aid Requests")} noShare={true}/>
            <div className='submit_requ_header'>
                <h3 style={{color:'gray'}}>{t("Local Aid Requests")}</h3>
            </div>
            <div className='requestsLobby_navi'>
                <button onClick={()=> handleNavigation('pending')} id='pending' className='requestsLobby_navi_item'><span style={{borderBottom: '3px solid #ee4236', padding:width>300?'10px 12px 10px 12px':'0px'}}>{t("Pending")}</span></button>
                <button onClick={()=> handleNavigation('completed')} id='completed' className='requestsLobby_navi_item'><span style={{borderBottom: '3px solid #ee4236', padding:width>300?'10px 12px 10px 12px':'0px'}}>{t("Completed")}</span></button>
                <button onClick={()=> handleNavigation('returned')} id='returned' className='requestsLobby_navi_item'><span style={{borderBottom: '3px solid #ee4236', padding:width>300?'10px 12px 10px 12px':'0px'}}>{t("Returned")}</span></button>
            </div>
            {displayedSection===0?
            <Pending/>:
            displayedSection===1?
            <Completed/>:
            <Returned/>
        }
        </div>
    )
    } else {
        window.location.replace("/profile");
    }
    
}

export default RequestsLobby
