import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import BackToModal from './BackToAppModal/BackToAppModal'
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";
const RedirectForMob = () => {
    const history = useHistory()
    const [backToAppModalShow, setBackToAppModalShow] = useState(false);

    function useQuery() {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }
    let [searchParams, setSearchParams] = useState();
    let query = useQuery();
    let location = query.get("location")
    useEffect(()=> {
        let location = query.get("location")
        if (location==='mobapp') {
            //window.location.replace('ercapp://');
            setBackToAppModalShow(true)
        } else {
          history.push('/')  
        }
    })
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <BackToModal
                show={backToAppModalShow}
                dialogClassName="modal-60w"
                backdrop="static"
            />
            </div>
    )
}

export default RedirectForMob
