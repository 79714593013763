import React, { useState, useEffect } from "react";
import "./WelcomeModal.css";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bc1 from "../assets/chooseLangModalBC.png";
import bc2 from "../assets/chooseLangModalBC2.png";
import bc3 from "../assets/chooseLangModalBC3.png";
import bc4 from "../assets/chooseLangModalBC4.png";
import bc5 from "../assets/chooseLangModalBC5.png";
import Loading from "../components/ui/loading";
import bc1Dark from "../assets/chooseLangModalBC.png";
import bc2Dark from "../assets/chooseLangModalBC2.png";
import bc3Dark from "../assets/chooseLangModalBC3.png";
import bc4Dark from "../assets/chooseLangModalBC4.png";
import bc5Dark from "../assets/chooseLangModalBC5.png";

import samsung from "../assets/samsun.png";
import apple from "../assets/apple-pay.png";
import Layer1 from "../assets/flag2.png";
import Layer2 from "../assets/Layer 2.png";

function WelcomeModal(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loadingImage, setLoadingImage] = useState(true);

  const history = useHistory();
  const [pageNo, setPageNO] = useState(1);
  const [choosenLang, setChoosenLang] = useState("eng");
  const choosenOne = (str) => {
    setChoosenLang(str);
  };
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    let matched = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (matched) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);
  const moveOnSubmitLang = () => {
    setLoadingImage(true);
    if (pageNo < 5) {
      setPageNO(pageNo + 1);
    } else {
      setPageNO(pageNo + 1);
      localStorage.setItem("language", JSON.stringify({ lang: choosenLang }));
      props.onHide();
    }
  };
  const handleBackBtn = () => {
    setLoadingImage(true);
    setPageNO(pageNo - 1);
  };
  const handleSkip = () => {
    localStorage.setItem("language", JSON.stringify({ lang: choosenLang }));
    props.onHide();
  };
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body_forWelcome"
    >
      <Modal.Body>
        <div
          className="welcome_modal_slideOne"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          <div
            className="back_skip_btns_wraper"
            style={choosenLang === "arab" ? { direction: "rtl", fontFamily:'arab_font' } : {fontFamily:'english_font'}}
          >
            {pageNo !== 1 ? (
              <button onClick={handleBackBtn} className="skip_btn">
                {choosenLang === "arab" ? "العودة" : "Back"}
              </button>
            ) : (
              <span></span>
            )}
            <button onClick={handleSkip} className="skip_btn">
              {choosenLang === "arab" ? "تخطي" : "Skip"}
            </button>
          </div>
          {pageNo === 1 ? (
            <React.Fragment>
              <img
                onLoad={() => setLoadingImage(false)}
                style={{ display: loadingImage ? "none" : "block" }}
                src={darkMode ? bc1Dark : bc1}
                alt="modal-slide-img"
                className="modalSlideImg"
              />
              {loadingImage ? (
                <Loading
                  height={width <= 1920 ? "300px" : "400px"}
                  mediaQ="300px"
                />
              ) : (
                <span></span>
              )}
              <div className="someThingLike_Form">
                <label style={{fontFamily:choosenLang === "arab" ? 'arab_font':'english_font'}}>
                  {choosenLang === "arab"
                    ? "اختر لغتك المفضلة"
                    : "Choose your language preference"}
                </label>
                <div className="chooseLang_btn_wraper">
                  <button
                    style={
                      choosenLang === "eng"
                        ? {
                            border: "2px solid #ee4236",
                            boxShadow: "#ab9898 1px 0px 8px",
                            fontFamily: 'english_font'
                          }
                        : {fontFamily: 'english_font'}
                    }
                    id="btn_eng"
                    onClick={() => choosenOne("eng")}
                    className=" Lang_one_btn"
                  >
                    <img
                      src={Layer1}
                      alt="lang_one"
                      className="Lang_one_btn_img"
                    />
                    English
                  </button>
                  <button
                    style={
                      choosenLang === "arab"
                        ? {
                            border: "2px solid #ee4236",
                            boxShadow: "#ab9898 1px 0px 8px",
                            fontFamily: 'arab_font'
                          }
                        : {fontFamily: 'arab_font'}
                    }
                    id="btn_arab"
                    onClick={() => choosenOne("arab")}
                    className=" Lang_one_btn"
                  >
                    <img
                      src={Layer2}
                      alt="lang_one"
                      className="Lang_one_btn_img"
                    />
                    العربية
                  </button>
                </div>
              </div>
            </React.Fragment>
          ) : pageNo === 2 ? (
            <React.Fragment>
              <img
                onLoad={() => setLoadingImage(false)}
                style={{ display: loadingImage ? "none" : "block" }}
                src={darkMode ? bc2Dark : bc2}
                alt="modal-slide-img"
                className="modalSlideImg"
              />
              {loadingImage ? (
                <Loading
                  height={width <= 1920 ? "300px" : "400px"}
                  mediaQ="300px"
                />
              ) : (
                <span></span>
              )}
              <div className="someThingLike_Form">
                <label style={{ textAlign: "center", fontWeight: "bold", fontFamily:choosenLang==='arab'?'arab_font':'english_font' }}>
                  {choosenLang === "arab"
                    ? "مرحبًا بكم في موقع الهلال الأحمر الإماراتي"
                    : "Welcome to Emirates Red Crescent Website"}
                </label>
                <label
                  style={{
                    textAlign: "center",
                    direction: choosenLang === "arab" ? "rtl" : "ltr",
                    fontFamily:choosenLang==='arab'?'arab_font':'english_font'
                  }}
                >
                  {choosenLang === "arab"
                    ? "اكتشف الموقع الآن"
                    : "Explore the website Now."}
                </label>
              </div>
            </React.Fragment>
          ) : pageNo === 3 ? (
            <React.Fragment>
              <img
                onLoad={() => setLoadingImage(false)}
                style={{ display: loadingImage ? "none" : "block" }}
                src={darkMode ? bc3Dark : bc3}
                alt="modal-slide-img"
                className="modalSlideImg"
              />
              {loadingImage ? (
                <Loading
                  height={width <= 1920 ? "300px" : "400px"}
                  mediaQ="300px"
                />
              ) : (
                <span></span>
              )}
              <div className="someThingLike_Form">
                <label style={{ textAlign: "center", fontWeight: "bold", fontFamily:choosenLang==='arab'?'arab_font':'english_font' }}>
                  {choosenLang === "arab"
                    ? "أبواب التبرع"
                    : "Available Donations"}
                </label>
                <label
                  style={{
                    textAlign: "center",
                    direction: choosenLang === "arab" ? "rtl" : "ltr",
                    fontFamily:choosenLang==='arab'?'arab_font':'english_font'
                  }}
                >
                  {choosenLang === "arab"
                    ? "شاهد أحدث حملاتنا وتبرعاتنا."
                    : "View our latest campaigns, and donations."}
                </label>
              </div>
            </React.Fragment>
          ) : pageNo === 4 ? (
            <React.Fragment>
              <img
                onLoad={() => setLoadingImage(false)}
                style={{ display: loadingImage ? "none" : "block" }}
                src={darkMode ? bc4Dark : bc4}
                alt="modal-slide-img"
                className="modalSlideImg"
              />
              {loadingImage ? (
                <Loading
                  height={width <= 1920 ? "300px" : "400px"}
                  mediaQ="300px"
                />
              ) : (
                <span></span>
              )}
              <div className="someThingLike_Form">
                <label style={{ textAlign: "center", fontWeight: "bold", fontFamily:choosenLang==='arab'?'arab_font':'english_font' }}>
                  {choosenLang === "arab" ? "تقاريري" : "My Reports"}
                </label>
                <label
                  style={{
                    textAlign: "center",
                    direction: choosenLang === "arab" ? "rtl" : "ltr",
                    fontFamily:choosenLang==='arab'?'arab_font':'english_font'
                  }}
                >
                  {choosenLang === "arab"
                    ? "تتبع وأدر مشاريعك، رعاياك وتبرعاتك."
                    : "Track, and manage your projects, orphans, and donations."}
                </label>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                onLoad={() => setLoadingImage(false)}
                style={{ display: loadingImage ? "none" : "block" }}
                src={darkMode ? bc5Dark : bc5}
                alt="modal-slide-img"
                className="modalSlideImg"
              />
              {loadingImage ? (
                <Loading
                  height={width <= 1920 ? "300px" : "400px"}
                  mediaQ="300px"
                />
              ) : (
                <span></span>
              )}
              <div
                className="someThingLike_Form"
                style={{ marginBottom: "0px" }}
              >
                <label style={{ textAlign: "center", fontWeight: "bold", fontFamily:choosenLang==='arab'?'arab_font':'english_font' }}>
                  {choosenLang === "arab" ? "حقيبة التبرع" : "Donation Bag"}
                </label>
                <label
                  style={{
                    textAlign: "center",
                    direction: choosenLang === "arab" ? "rtl" : "ltr",
                    fontFamily:choosenLang==='arab'?'arab_font':'english_font'
                  }}
                >
                  {choosenLang === "arab"
                    ? "أضف كل تبرعاتك في مكان واحد ، وادفعها جميعًا مرة واحدة بسهولة."
                    : "Add all your donations in one place, easily pay for them all at once."}
                </label>
              </div>
              <div
                className="minHeight"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginBottom: "74px",
                }}
              >
                <img
                  src={apple}
                  alt="apple_pay"
                  style={{ height: "50px", width: "60px", objectFit: "cover" }}
                />
                <img
                  src={samsung}
                  alt="samsung_pay"
                  style={{ height: "50px", width: "60px", objectFit: "fill" }}
                />
              </div>
            </React.Fragment>
          )}

          <div
            className="bottom_of_the_modal"
            style={{ position: "absolute", bottom: "20px" }}
          >
            <div
              className="pagination_dots"
              style={choosenLang === "arab" ? { direction: "rtl" } : null}
            >
              <i
                style={
                  pageNo === 1
                    ? { fontSize: "10px", color: "black" }
                    : { fontSize: "7px", color: "gray" }
                }
                className="fas fa-circle"
              ></i>
              <i
                style={
                  pageNo === 2
                    ? { fontSize: "10px", color: "black" }
                    : { fontSize: "7px", color: "gray" }
                }
                className="fas fa-circle"
              ></i>
              <i
                style={
                  pageNo === 3
                    ? { fontSize: "10px", color: "black" }
                    : { fontSize: "7px", color: "gray" }
                }
                className="fas fa-circle"
              ></i>
              <i
                style={
                  pageNo === 4
                    ? { fontSize: "10px", color: "black" }
                    : { fontSize: "7px", color: "gray" }
                }
                className="fas fa-circle"
              ></i>
              <i
                style={
                  pageNo === 5
                    ? { fontSize: "10px", color: "black" }
                    : { fontSize: "7px", color: "gray" }
                }
                className="fas fa-circle"
              ></i>
            </div>
            <button style={{fontFamily: choosenLang==='arab'? 'arab_font':'english_font'}} onClick={moveOnSubmitLang} className="moveOn_btn smallBtn_smallDevice">
              {pageNo >= 5
                ? choosenLang === "arab"
                  ? "تم"
                  : "Done"
                : choosenLang === "arab"
                ? "متابعة"
                : "Continue"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default WelcomeModal;
