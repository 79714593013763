import { FETCH_ALL_TUTORIALS } from '../constants/actionTypes'
import * as api from '../api'

export const getAllTutorials = (langNumber) => async (dispatch) => {
    try {
        const { data } = await api.getTutorials(langNumber);
        dispatch({ type: FETCH_ALL_TUTORIALS, payload: data.status? data.data : [] })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}