import React from 'react'
import './becomeASponCom.css'
import { useHistory } from 'react-router-dom'


const BecomeASponCom = (props) => {
    const history = useHistory();

    return (
        <div onClick={() => history.push(`/beasponser/${props.becomeASponId}`)} className='becomeASpon_com'>
            <div className='becomeASpon_border'>
                <img src={props.image} alt={props.alt_text} className='spon_img' />
            </div>
            <label className='become_label' style={{ fontWeight: 'normal' }}>{props.text}</label>
        </div>
    )
}
export default BecomeASponCom