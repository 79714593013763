import React, { useState, useEffect, useRef } from 'react'
import './SukukItemBag.css'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag } from '../../../../actions/BagFav'
import DonationItemBag from '../donationItemBag/DonationItemBag';
import Loading from '../../../ui/loading';
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import { FETCH_LOCALLY } from '../../../../constants/actionTypes';


const styleForWraper = {
    filter: 'blur(8px)',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const bigWraperStyle = {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const styleForNotAv = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2000'
}
const styleForXBtn = {
    color: 'white'
}
const SukukItemBag = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    const sukuk = useSelector(state => state.sukuk.sukuk);
    let itemWeNeed = sukuk?.filter(item => item.dontypeID === props.sukId).slice(0, 1).shift()
    const [realAmount, setRealAmount] = useState(props.baseAmount)
    const [addingToCart, setAddingToCart] = useState(false);
    useEffect(() => {
        //itemWeNeed = sukuk?.filter(item => item.dontypeID === props.sukId).slice(0, 1).shift()
        setRealAmount(props.baseAmount)
    }, [sukuk, props.sukId])
    const [sukukItemOnBagDetails, setSukukItemBagDetails] = useState({
        amount: props.amount,
        donationFreq: props.donationFreq,
        img: props.sukukImg,
        sukId: props.sukId,
        title: props.title,
        baseFreq: props.baseFreq,
        baseAmount: props.baseAmount,
        dateOfAdding: props.cart_serial
    })
    useEffect(() => {
        setSukukItemBagDetails({
            amount: props.amount,
            donationFreq: props.donationFreq,
            img: props.sukukImg,
            sukId: props.sukId,
            title: props.title,
            baseFreq: props.baseFreq,
            baseAmount: props.baseAmount,
            dateOfAdding: props.cart_serial
        })
    }, [props.amount, props.donationFreq])
    const handleClick = async (op) => {
        setAddingToCart(true);
        if (op === '-') {
                if (!userLogin) {
                    let copyOf = props.currentProfile.cart.sukuks.slice(0);
                    copyOf.splice(copyOf.findIndex(item => item.sukId === props.sukId && parseInt(item.baseAmount) === parseInt(props.baseAmount) && item.donationFreq === props.donationFreq), 1)
                    let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: copyOf, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                    const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                    localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                    await dispatch({type: FETCH_LOCALLY})
                    toastifyMessage("Modified!", undefined, "success");
                    if (_isMounted.current) {
                      props.setChange(props.change + 1)  
                    }
                } else {
                    if (props.quantity>1) {
                        await dispatch(addToBag({
                        cart: {
                            userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity - 1
                        }, language: lng === 'arab' ? 1 : 2
                    }, false, 'justEdit'))
                    } else {
                        await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.cart_serial, lng === "eng" ? 2 : 1, ""))
                    }
                    
                }
        } else {
            if (!userLogin) {
                let copyOf = props.currentProfile.cart.sukuks.slice(0);
                copyOf.splice(copyOf.findIndex(item => item.sukId === props.sukId && parseInt(item.baseAmount) === parseInt(props.baseAmount) && item.donationFreq === props.donationFreq), 0, { ...sukukItemOnBagDetails, amount: parseInt(props.baseAmount)})
                let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: copyOf, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                await dispatch({type: FETCH_LOCALLY})
                toastifyMessage("Modified!", undefined, "success");
                if (_isMounted.current) {
                    props.setChange(props.change + 1)  
                  }
            } else {
                await dispatch(addToBag({
                    cart: {
                        userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity + 1
                    }, language: lng === 'arab' ? 1 : 2
                }, false, 'justEdit'))
            }

        }
        if (_isMounted.current) {
            setAddingToCart(false); 
          }
    }
    // const handleFrequ = async (op) => {
    //     setAddingToCart(true);
    //     if (!userLogin) {
    //         let copyOf = props.currentProfile.cart.sukuks.slice(0);
    //         copyOf.splice(copyOf.findIndex(item => item.sukId === props.sukId && item.baseFreq === props.baseFreq && parseInt(item.baseAmount) === parseInt(props.baseAmount) && item.donationFreq === props.donationFreq), 1, { ...sukukItemOnBagDetails, donationFreq: op })
    //         let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: copyOf, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
    //         const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
    //         localStorage.setItem('profile', JSON.stringify(newProfileFinal))
    //         props.setChange(props.change + 1)
    //     } else {
    //         // let elementWeNeed = props.bag?.sukuks?.filter(item => item.dontypeID === sukukItemOnBagDetails.sukId).slice(0, 1).shift()
    //         await dispatch(addToBag({
    //             cart: {
    //                 donID: sukukItemOnBagDetails.sukId, userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
    //                 userEmail: userLogin.Results[0]?.email, itemType: 0, amount: props.amount, recuring: op, cart_serial: props.item.cart_serial, quantity: props.item.quantity
    //             }, language: lng === 'arab' ? 1 : 2
    //         }))
    //     }
    //     setAddingToCart(false);
    // }
    const handleInBag = async (str) => {
        setAddingToCart(true);
        if (!userLogin) {
            let newProfile = {
                cart: {
                    donations: props.currentProfile.cart.donations,
                    sukuks: props.currentProfile.cart.sukuks.filter(item => !(item.sukId === str.id && item.donationFreq === str.donationFreq)),
                    projects: props.currentProfile.cart.projects,
                    humanCases: props.currentProfile.cart.humanCases,
                    orphans: props.currentProfile.cart.orphans
                },
                liked: props.currentProfile.liked
            }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            toastifyMessage("Removed!", undefined, "success");
            if (_isMounted.current) {
             props.setChange(props.change + 1)   
            }
        } else {
                await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.cart_serial, lng === "eng" ? 2 : 1, ""))
        }
        if (_isMounted.current) {
           setAddingToCart(false); 
        }
        
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <div className='big_wraper_bag' style={props.status === 'not' ? bigWraperStyle : { width: '100%' }}>
            <button disabled={addingToCart} style={lng === 'arab' ? { right: 'unset', left: '10px', color: 'white' } : { color: 'white' }} onClick={() => handleInBag({id: props.sukId, donationFreq: props.donationFreq, baseAmount: props.baseAmount, baseFreq: props.baseFreq})} className='removeBtn_from_bag'><i className="fas fa-times"></i></button>
            <div style={{position:'absolute', top:'0px', left: '0px', right:'0px', bottom:'0px', display: addingToCart? 'block':'none'}}>
                <Loading height={'100%'} width={'100%'}/>
            </div>
            <div className={addingToCart ? 'bag_item_com_Bag disable_page' : 'bag_item_com_Bag'} style={props.status === 'not' ? styleForWraper : null}>
                <img src={props.sukukImg} alt={props.title} className='sukuk_item_img img_sukuk_onBag' style={lng === 'arab' ? { height: '120px', width: '150px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' } : { height: '120px', width: '150px', borderTopLeftRadius: '30px', borderBottomLeftRadius: '30px' }} />
                <div style={width <= 1150 ? {display:'flex', flexDirection:'column', width: '90%', textAlign: 'right', flex: '7'}:{display:'flex', flexDirection:'column', width: '100%', textAlign: 'right', flex: '7'}}>
                    {lng === 'arab' ?
                        <label style={width <= 1150 ? { width: '100%', textAlign: 'right', fontWeight: 'bold', fontSize: '13px' } : { width: '100%', textAlign: 'right', fontWeight: 'bold' }}>{props.title}</label> :
                        <label style={width <= 1150 ? { width: '100%', textAlign: 'left', fontWeight: 'bold', fontSize: '13px' } : { width: '100%', textAlign: 'left', fontWeight: 'bold' }}>{props.title}</label>
                    }
                    <label style={{fontSize: width <= 1150 ? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}>{t("Payment")}: {props.donationFreq === 'N' ? t("One Time") : props.donationFreq === 'M'? t("Monthly") : t("Daily")}</label>
                    <label style={{fontSize: width <= 1150 ? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}>{t("Quantity")}: <span className='payment_numbers'>{props.quantity}</span></label>
                </div>
                <div className='donation_inc_Dec_bag_sukuk'>
                    {/* {props.status === 'not' ? */}
                    {/* <button className='inc' onClick={() => handleClick('-')} disabled>-</button> : */}
                    <button disabled={addingToCart || props.status === 'not'} className='inc' onClick={() => handleClick('-')}>-</button>
                    {/* } */}
                    <label className='label_to_beSmall payment_numbers' style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold', minHeight:'unset'}}>{t("AED")} <span style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'none' }}>{sukukItemOnBagDetails.amount}</span></label>
                    {/* {props.status === 'not' ? */}
                    {/* <button className='dec' onClick={() => handleClick('+')} disabled>+</button> : */}
                    <button disabled={addingToCart || props.status === 'not'} className='dec' onClick={() => handleClick('+')}>+</button>
                    {/* } */}
                </div>
            </div>
            <label style={props.status === 'not' ? styleForNotAv : { display: 'none' }}>Not available</label>
        </div>
    )
}

export default SukukItemBag
