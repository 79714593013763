import React from 'react'
import './EventMonthSelector.css'
import left_arrow from '../../../../../../assets/angle-small-left.svg';
import right_arrow from '../../../../../../assets/angle-small-right.svg';
const EventMonthSelector = (props) => {
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const rollBack = () => {
        //structuredClone does a complete deep object clone including its functions. 
        let date = structuredClone(props.month);
        date.setDate(1)
        date.setMonth(date.getMonth() - 1)    
        props.setMonth(date)
    }
    const rollForward = () => {
        //Same effect as structuredClone
        let date = new Date(props.month);
        date.setDate(1)
        date.setMonth(date.getMonth() + 1)    
        props.setMonth(date)
    }
  return (
    <div id="event-month-selector">
      <img src={left_arrow} className='button_date' onClick={() => rollBack()}/>
            <h1>{props.month.toLocaleDateString(lng === "arab"?"ar-GB": "en-GB", {
                month: "long",
                year: "numeric",
                })}</h1>
                 <img src={right_arrow} className='button_date' onClick={() => rollForward()}/>

    </div>
  )
}
export default EventMonthSelector