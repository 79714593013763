import React, { useState, useEffect } from "react";
import ERCLogo from "../../../../assets/logo.png";
import BCE2mob from "../../../../assets/loginBCMob2.png";
import BCE2 from "../../../../assets/loginBC.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DoneModal from "../doneModal/DoneModal";
import LoadingModal from "../loadingPopUp/loadingPopUp";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { oTPVefi, reSendOTP } from "../../../../actions/user";
import ShareHeader from "../../../LoaclComponents/shareHeader/ShareHeader";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";

export const NeedToOTP = (props) => {
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  let query = useQuery();
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [content, setContent] = useState("");
  const [contentOF, setContentOf] = useState(
    "But If You Didn't Recieve It, Click Here."
  );
  const [formData, setFormData] = useState({
    email: "",
    otpEmail: "",
    //otpMob: "",
    vln_id: "",
    persn_id: "",
    sponser_id: "",
  });
  useEffect(() => {
    if (props.location.state?.from === "signIn") {
      setLoadingModalShow(true);
      dispatch(reSendOTP(props.location.state.email, setLoadingModalShow, setContentOf));
      setFormData({ ...formData, email: props.location.state.email });
    }
    else if(props.location.state?.from === "signUp")
    {
      setFormData({ ...formData, email: props.location.state.email });
    }
      
  }, [props.location.state?.email]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (width > 900) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const [doneModal, setDoneModal] = useState(false);
  const openFromLog = () => {
    history.push("/");
  };
  const handleConfirmOTP = (e) => {
    e.preventDefault()
    setLoadingModalShow(true);
    dispatch(oTPVefi(formData, setLoadingModalShow, setDoneModal, setContent));
  };
  const reSendOTPS = () => {
    setLoadingModalShow(true);
    dispatch(reSendOTP(formData.email, setLoadingModalShow, setContentOf));
  };
  if (!userLogin) {
    return (
      <div
        id="login_sign"
        style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", justifyContent: 'flex-start' }}
      >
        <ShareHeader noTitle={true} noShare={true} forAuth={true} />
        <div className="NeedToOtp">
          <div className="ERC_logo_border">
            <div className="ERC_logo_border_inner" onClick={openFromLog} alt="ERC_logo">
              <img
                src={ERCLogo}
                onClick={openFromLog}
                alt="ERC_logo"
                className="ERC_logo_login_sign"
              />
            </div>
          </div>
          <h1 style={lng === "arab" ? { direction: "rtl" } : null}>
            {t("Welcome")}
          </h1>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', gap: '40px' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
              <h5 style={lng === "arab" ? { direction: "rtl" } : null}>
                {t("Please Enter The Verification Code You Received By E-mail")}
              </h5>
              <form
                onSubmit={handleConfirmOTP}
                className="otp_input_wraper"
                style={{
                  width: "90%",
                  flexDirection: "row",
                  padding: "0px",
                  gap: "0px",
                  alignItems: "unset",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}
              >
                <input
                  className="input_for_verfication_code"
                  type="text"
                  pattern="[0-9]*"
                  maxLength="4"
                  style={{ width: '100%' }}
                  required
                  value={formData.otpEmail}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      otpEmail: e.target.value.replace(/\D/, ""),
                    })
                  }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
                <button style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}} className="confirm_otp">{t("Confirm")}</button>
              </form>
            </div>

            {/*<div style={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
          <h5 style={lng === "arab" ? { direction: "rtl" } : null}>
            {t("Also, Enter The Verification Code You Received By Mobile")}
          </h5>
          <form
            onSubmit={handleConfirmOTP}
            className="otp_input_wraper"
            style={{
              width: "80%",
              flexDirection: "row",
              padding: "0px",
              gap: "0px",
              alignItems: "unset",
            }}
          >
            <input
              className="input_for_verfication_code"
              type="text"
              pattern="[0-9]*"
              maxLength="4"
              style={{width:'100%'}}
              required
              value={formData.otpMob}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  otpMob: e.target.validity.valid ? e.target.value : formData.otpMob,
                })
              }
              onInvalid={applyValidation}
              onInput={resetValidation}
            />
            <button className="confirm_otp">{t("Confirm")}</button>
          </form>
            </div>*/}
          </div>
          <h1>{content}</h1>
          <button
            onClick={reSendOTPS}
            style={lng === "arab" ? { direction: "rtl" } : null}
            className="btn_for_Resend_OTP"
          >
            {t(contentOF)}
          </button>
          <button
            className="opt_confirm_back_btn"
            onClick={() => history.push("/")}
          >
            {t("Back")}
          </button>
        </div>
        <DoneModal
          show={doneModal}
          dialogClassName="modal-60w"
          onHide={() => {
            setDoneModal(false);
            window.open('/profile', '_self')
          }}
        />
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace("/");
  }
};
export default NeedToOTP;
