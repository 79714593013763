import React, { useCallback } from 'react';
import fac from '../../../assets/facebook.svg';
import { useDispatch } from 'react-redux';
import { signInWithFacebook } from '../../../actions/user';
import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import { logOutFromFB } from "./logoutFromFacebook";

function SignInWithFacebook(props) {

    const dispatch = useDispatch();
    const responseFacebook = (response) => {
        if (response.email === undefined || !response.email) {
            props.setLoadingModalShow(false);
            toastifyMessage(`Login Failed.`, 'Authentication Error!', "error");
        }
        else {
            dispatch(signInWithFacebook(response.accessToken, props.history, props.setLoadingModalShow, props.fromState, props.completeInfo, props.lodaingSwitch, props.setWait));
        }
    };
    const onFailure = (error) => {
        props.setLoadingModalShow(false);
        toastifyMessage(`Login Failed: Pop-up closed by user!`, undefined, "error");
    };

    const authStatus = (status: false) => {
        (function ($) {
            $(window).bind('fbAsyncInit', function () {
                //Code using the FB object goes here.
                window.FB.getLoginStatus(function (response) {
                    if (response.status && response.status === 'unknown' || response.status === 'not_authorized') {
                        props.setLoadingModalShow(false);
                        // window.location.reload();
                    }

                });
            });

        })(jQuery);
    }


    return (
        <>
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name, email"
                scope="public_profile, email"
                cookie={true}
                autoLoad={false}
                xfbml={true}
                version="12.0"
                reAuthenticate
                authType="reauthenticate"
                disableMobileRedirect={true}
                // isMobile={true}
                // render={renderProps => (
                //     <button style={{ border: 'none', backgroundColor: 'transparent' }}
                //         onClick={(e) => {
                //             logOutFromFB();
                //             renderProps.onClick();
                //             // props.setLoadingModalShow(true);
                //         }}
                //         disabled={renderProps.disabled}
                //     >
                //         <img src={fac} alt='facebook_logo' className='login_sign_social_logos' />
                //     </button>
                // )
                // }
                onClick={() => {
                    authStatus();
                    logOutFromFB();
                    props.setLoadingModalShow(true);
                }}
                textButton=''
                cssClass='login_sign_social_logos'
                buttonStyle={{ border: 'none', backgroundColor: 'transparent' }
                }
                icon={<img src={fac} alt='facebook_logo' className='login_sign_social_logos' />}
                callback={responseFacebook}
                onFailure={onFailure}
            />
        </>
    );
};

export default SignInWithFacebook;