import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AccountLinkModal.css";
import { useDispatch } from "react-redux";
import {
  submitExtraSponser,
  submitExtraSponserOTP,
} from "../../../api/account";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

function AccountLinkModal(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
  });
  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    return re.test(phone);
  }
  const [isSendOTP, setOTPstatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  return (
    <Modal
      {...props}
      onExit={()=> {setFormData({mobile:'05', otp:''}); setOTPstatus(false)}}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body"
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          className="modal_body_for_donation_final"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          {isSendOTP ? (
            <label>{t("OTP SEND")} </label>
          ) : (
            <label>{t("Enter the mobile number ")} </label>
          )}
          {!isSendOTP ? (
            <div className="input_wraper">
              <input
                type="text"
                pattern='[0-9]*'
                onChange={(e) => setFormData({ ...formData, mobile: e.target.value.replace(/\D/, "")})}
                className={formData.mobile? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                value={formData.mobile}
                name="mobile"
                placeholder={t("Enter the mobile number here")}
                maxLength='10'
                required
              />
            </div>
          ) : (
            <div className="input_wraper">
              <input
                className={formData.otp? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                type="text"
                onChange={handleChange}
                value={formData.otp}
                name="otp"
                placeholder={t("Enter the OTP here")}
                required
              />
            </div>
          )}
          {errors ? <div>{t(errors)}</div> : <div></div>}
          <div className="donation_final_popUp_btns_wraper">
            <button
              onClick={() => {
                setError("");
                setLoading(true);
                if (isSendOTP) {
                  submitExtraSponserOTP(
                    props.email,
                    formData.mobile,
                    formData.otp
                  )
                    .then((res) => {
                      if (res.data.status) {
                        props.setOtp(formData.otp)
                        //alert()
                        // localStorage.setItem('userLogin', JSON.stringify({Results: res.data.data}))
                        // toastifyMessage("Added!", undefined, "success");
                        props.setSponsorSelections(res.data.data);
                        props.setAccountLinkSelectionModal(true)
                        props.onHide()
                      } else {
                        setError(res.data.result);
                      }
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  if (!(validateMobile(formData.mobile))) {
                    setLoading(false);
                    toastifyMessage("\"Mobile number\" should be 10 digits long and start with \"05\"", undefined, "error");
                    return false;
                  } else {
                    submitExtraSponser(props.email, formData.mobile)
                      .then((res) => {
                        if (res.data.status) {
                          setOTPstatus(true);
                        } else {
                          setError(t('Can not find sponser account related to this number'));
                        }
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }

                }
              }}
              className=" donation_final_popUp_btn_Third"
            >
              {isLoading ? t("Loading...") : t("Submit")}
            </button>

            <button
              onClick={() => {
                props.onHide();
              }}
              className="donation_final_popUp_btn_first"
            >
              {t("Not Now")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default AccountLinkModal;
