import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../../actions/media";
import "./News.css";
import NewsModal from "./newsModal";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";

const News = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const newsAll = useSelector((state) => state.getAllNews.allnews);
  const loading = useSelector((state) => state.getAllNews.loading);

  const [allNews, setNews] = useState(newsAll);
  // const [loadingNews, setLoadingNews] = useState(loading);
  useEffect(() => {
    dispatch(getAllNews(lng == "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    if (allNews?.constructor?.name === "Array") {
      setNews(newsAll);
    }
  }, [newsAll]);
  return (
    <div className="ourLoactions" style={lng === 'arab' ? { direction: 'rtl' } : null}>
      <ShareHeader pageName={t("News")} noShare={true} />
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("News")}</h3>
      </div>
      {loading ? (
        <div
          style={{
            maxWidth: "1366px",
            height: "85vh",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="danger" />
        </div>
      ) : !loading && allNews?.length>0? (
        <div className="branch_info_grid news_grid">
          {allNews?.length > 0 &&
            allNews?.map((item, i) => {
              return <NewsCard key={i} news={item} />;
            })}
        </div>
      ): <div style={{ minHeight: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}><p>{t("No news found")}</p></div>
      }
    </div>
  );
  function NewsCard(props) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const news = props.news;
    const content =
      news.content.length > 150
        ? news.content.replaceAll("&nbsp;", " ").replaceAll("&quot;", "\"").substring(0, 150) + "..."
        : news.content;
        const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
      <>
        <NewsModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          news={news}
        />
        <div
          className="news_info"
          style={{
            flexDirection: lng == "arab" ? width>1150? "row-reverse" : 'column-reverse': width>1150? "row-reverse":'column-reverse',
            
          }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <div className="branch_card">
            <h6
              className="branch_info_title"
              style={{
                color: "black",
              }}
            >
              {news?.title}
            </h6>
            <p className="branch_info_subtitle">{content} </p>
          </div>
          <div
            className="news_img"
            style={{
              display: "flex",
              justifyContent: lng == "en" ? "end" : "start",
            }}
          >
            {!imageLoaded && (
              <div
                style={{
                  height: "100px",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner animation="border" variant="danger" />
              </div>
            )}
            <img
              src={news?.filePath}
              style={{
                display: imageLoaded ? "block" : "none",
                height: "100px",
                width: "150px",
                objectFit: "fill",
                borderRadius: "5px",
              }}
              onLoad={() => {
                setImageLoaded(true);
              }}
            />
          </div>
        </div>
      </>
    );
  }
};

export default News;
