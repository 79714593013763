import React, { useEffect, useState } from 'react'
import './EventCalendar.css'
import { useDispatch, useSelector } from "react-redux";
import EventBottomSheet from '../EventBottomSheet/EventBottomSheet';
import ShareHeader from '../../../../LoaclComponents/shareHeader/ShareHeader';
import Modal from "react-bootstrap/Modal";

const EventCalendar = (props) => {

    const loading = useSelector(state => state.events.loading);
    const [open, setOpen] = useState(false);
    const [activeEvent, setActiveEvent] = useState(null);
    const [day, setDay] = useState(null);
    const [weekday, setWeekday] = useState(null);
    const [month, setMonth] = useState(null);
    
    // const dates = new Date(props.allEvents[0]?.start_date.split('/')[1] + "/" + props.allEvents[0]?.start_date.split('/')[0] + "/" + props.allEvents[0]?.start_date.split('/')[2])
        
    const startCalendar = {
        "Sunday": "1",
        "Monday": "2",
        "Tuesday": "3",
        "Wednesday": "4",
        "Thursday": "5",
        "Friday": "6",
        "Saturday": "7",
        
    }
    const getDaysInMonth = (year, month) => (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
    const date = new Date();
    const currentYear = date.getFullYear();
    let year = currentYear - 1;
    const currentMonth = date.getMonth();
    let months = currentMonth;
    const [years, setYears] = useState([])
    useEffect(() => {
        setYears(Array(24)
        .fill()
        .map(() => getDaysInMonth(months === 13? ++year:year, months === 13? months = 1:++months)).filter((item) => item.length !== 0));
    }, [])
    const handleClose = () => props.onHide();
    useEffect(() => {
        if(!open)
        {
            setActiveEvent(null);
            setDay(null);
        }
    },[open])
    useEffect(() => {
        if(activeEvent)
        {
            var dayz = new Date(activeEvent.start_date.split('/')[1] + "/" + activeEvent.start_date.split('/')[0] + "/" + activeEvent.start_date.split('/')[2])
                setDay(dayz.toLocaleDateString("en-GB", {
                    day: "numeric",
                    }))
                setWeekday(dayz.toLocaleDateString("en-GB", {
                    weekday: "long",
                    }))
                setMonth(dayz.toLocaleDateString("en-GB", {
                    month: "short"
                    }))
            
        }
        else
        {
            setOpen(false)
        }
    }, [activeEvent])

    useEffect(() => {
        if(day && weekday && month)
                setOpen(true)
        
    }, [day, weekday, month])
    const handleEventClick = (i) => {
        var allActiveEvents = props.allEvents?.filter((event) => {
            return new Date(event.start_date.split('/')[1] + "/" + event.start_date.split('/')[0] + "/" + event.start_date.split('/')[2])?.toLocaleDateString("en-GB", {
                 day: "numeric",
                 month: "numeric",
                 year: "numeric",
                 }) === i.toLocaleDateString("en-GB", {
                 day: "numeric",
                 month: "numeric",
                 year: "numeric",
                     })
         })
         console.log(allActiveEvents)
         if(allActiveEvents.length > 0)
         {
            setActiveEvent(allActiveEvents?.filter((event) => {
                return new Date(event.start_date.split('/')[1] + "/" + event.start_date.split('/')[0] + "/" + event.start_date.split('/')[2])?.toLocaleDateString("en-GB", {
                     day: "numeric",
                     month: "numeric",
                     year: "numeric",
                     }) === i.toLocaleDateString("en-GB", {
                     day: "numeric",
                     month: "numeric",
                     year: "numeric",
                         })
             }).slice(0).shift());
         }
    }
  return (
    <Modal {...props} size="lg" centered className="" onHide={handleClose}>
      <Modal.Header className='event_modal' closeButton>
        <h2 className='event_modal_title'>Events</h2>
      </Modal.Header>
      <Modal.Body
        style={{ fontFamily:  "english_font" }}
      >
      <div>
        {/* <ShareHeader pageName={"Events"} noShare={true}/> */}
        {years.length > 0?
    <>
    {years.map((item, i) => {
        return <div key={i} className="calendar-wrapper">
        <div style={{display:'flex', alignItems: "flex-end",
    paddingBottom: "22px"}}>
        <h1 style={{color: "#ee4236"}} className='month_year'>{item[0].toLocaleDateString("en-GB", {
                month: "long"
                })}</h1>
                <span style={{color: "black", marginBottom: "5px"}}>{item[0].toLocaleDateString("en-GB", {
                    year: 'numeric'
                    })}</span>
        </div>
        <ol className="calendar">
          <li className="day-name">S</li>
          <li className="day-name">M</li>
          <li className="day-name">T</li>
          <li className="day-name">W</li>
          <li className="day-name">T</li>
          <li className="day-name">F</li>
          <li className="day-name">S</li>
          <div className="row-border"></div>
          {item.map((i, index) => {
            if(index === 0)
                return <li key={index} onClick={() => {handleEventClick(i)}} style={{gridColumnStart: startCalendar[i?.toLocaleDateString("en-GB", {
                    weekday: "long",
                    })]}}>1</li>
            return <li key={index} className='calendar_list_days' onClick={() => {handleEventClick(i)}} style={{flex: '1', cursor: 'pointer'}}>
                <span>
                {i?.toLocaleDateString("en-GB", {
                day: "numeric",
                })}    
                </span>
                <div>
                    <label style={{height: '5px', width: '5px', backgroundColor: props.allEvents?.filter((event) => {
               return new Date(event?.start_date?.split('/')[1] + "/" + event?.start_date?.split('/')[0] + "/" + event?.start_date?.split('/')[2])?.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    }) === i.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                        })
            }).length > 0?'red':'gray', borderRadius: '50%'}}></label>
                </div>
                </li>
        })}</ol>
        </div>
    })}
     {open && activeEvent?<EventBottomSheet open={open} event={activeEvent} day={day} weekday={weekday}  month={month}
     onHide={() => {setOpen(false);}}
     />:null}

    </>
:<></>}
</div>   
      </Modal.Body>
     
    </Modal>
   
  )
}

export default EventCalendar