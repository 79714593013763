import "./projectShareCom.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import cashbills from "../../../../../assets/donateByCash.png";
import applePay from "../../../../../assets/applePay.png";
import smsicon from "../../../../../assets/samsungpay.png";
import donateIcon from "../../../../../assets/donateIconForBanner.png";
import React, { useState, useEffect, useRef } from "react";
import { handleSamsungDirectPayment } from "../../../../../actions/handleSamsungPayment";
import DonationFinalPopUp from "../../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import LoadingModal from "../../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { startApplePay } from "../../../../../actions/handleApplePayment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastifyMessage } from "../../../../../handlers/toastifyMessage";

const ProjectShareCom = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const { t } = useTranslation();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const dispatch = useDispatch();

  const [functionToSend, setFunctionToSend] = useState(() => {});
  const [geustModal, setGuestModal] = useState(false);

  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  const history = useHistory();
  const [projectShareData, setProjectShareData] = useState({
    projectID: props.projectShare.projectID,
    projectYear: props.projectShare.projectYear,
    projectShareID: props.projectShare.projectShareID,
    projectShareYear: props.projectShare.projectShareYear,
    title: props.projectShare.projectName,
    total: props.projectShare.amountCollected,
    finalAmount: props.projectShare.amount,
    projectImg: props.projectShare.projTypeImagePath,
    donationAmountFromUser: 150,
    caseYear: props.projectShare.projectID,
    projectShareStatus: props.projectShare.projectShareStatus,
    item_type: 8,
  });
  useEffect(() => {
    setProjectShareData({
      projectID: props.projectShare.projectID,
      countryID: props.projectShare.countryID,
      projTypeID: props.projectShare.projTypeID,
      projectYear: props.projectShare.projectYear,
      projectShareID: props.projectShare.projectShareID,
      projectShareYear: props.projectShare.projectShareYear,
      title: props.projectShare.projectName,
      total: props.projectShare.amountCollected,
      finalAmount: props.projectShare.amount,
      projectImg: props.projectShare.projTypeImagePath,
      donationAmountFromUser: 150,
      caseYear: props.projectShare.projectID,
      item_type: 8,
    });
  }, [props]);

  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: projectShareData.donationAmountFromUser,
      url: window.location.href,
      item: [
        {
          dontype_id: 8,
          share_id: projectShareData.projectShareID,
          amount: projectShareData.donationAmountFromUser,
          share_year: projectShareData.projectShareYear,
          item_type: 8,
          country_id: projectShareData.countryID ? projectShareData.countryID : null,
          template_id: projectShareData.projectID,
          prj_name: projectShareData.title,
          projectYear: projectShareData.projectYear
        },
      ],
    });
    setLoadingModalShow(false);
  };

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };

  const donateNow = () => {
    startApplePay(
      {
        amount: projectShareData.donationAmountFromUser,
        cart: [
          {
            dontype_id: 8,
            share_id: projectShareData.projectShareID,
            amount: projectShareData.donationAmountFromUser,
            share_year: projectShareData.projectShareYear,
            item_type: 8,
            country_id: projectShareData.countryID ? projectShareData.countryID : null,
            template_id: projectShareData.projectID,
            prj_name: projectShareData.title,
            cart_serial: null,
            projectYear: projectShareData.projectYear
          },
        ],
      },
      dispatch,
      history
    );
  };

  return (
    <div className={"project_share_com" + " " + props.className ?? ""}>
      <div className="project-share-card">
        <div
          onClick={() => {
            history.push(
              `/project_share/${props.projectShare.projectShareYear}/${props.projectShare.projectShareID}`, {projectShare: props.projectShare}
            );
          }}
          className="image-title-desc"
        >
          <div className="project-share-images">
            <img
              className="project-type-image"
              src={props.projectShare.projTypeImagePath}
            />
            <img
              className="country-image"
              src={props.projectShare.countryImagePath}
            />
          </div>
          <div className="title_desc_only">
            <h4 style={{ fontWeight: "bold" }}>
              {props.projectShare.projectName}
            </h4>

            <label style={{overflow: "hidden"}}>
              {props.projectShare.longDesc.substring(0, 100) + "..."}
            </label>
          </div>
        </div>
        <div className="remainder_project_share" style={{direction: lng === "arab"? "rtl": "ltr"}}>
          <div style={{display: "inline-flex", direction: lng === "arab"? "ltr": "ltr"}}>
          <div className="remainder_project_share_inner payment_numbers">
            <span className="remainder_aed_text">AED</span>
            <span>{props.projectShare.amountCollected}</span>
          </div>
          <span>{t("Raised")}</span>
          </div>
          <div>{"-".repeat(window.innerWidth > 500? 8: window.innerWidth > 400? 5:window.innerWidth > 350?4: window.innerWidth > 300? 3: 1)}</div>
          <div style={{display: "inline-flex", direction: lng === "arab"? "rtl": "ltr"}}>
          <span>{t("of")} </span>
          <div className="remainder_project_share_inner payment_numbers">
            <span className="remainder_aed_text">AED</span>
            <span>{props.projectShare.amount}</span>
          </div>
          </div>
          {/* <span className="remainder_aed_text">AED</span><label className="payment_numbers" style={{marginRight: '5px'}}>{props.projectShare.amountCollected} {t("Raised")} <span>{"-".repeat(window.innerWidth > 500? 15: 10)}</span> {t("of")}  </label><span className="remainder_aed_text">AED</span> <label className="payment_numbers">{props.projectShare.amount}</label> */}
        </div>
        {props.projectShare.projectShareStatus?<div className="project_share_donation">
          <div className="share_input_and_label_div">
            <label style={{ color: "#9d9d9d" }}>{t("Donation Amount")}</label>
          <div className="share_input_div">
            <label className="inc_dec_label_proj">AED</label>
            <input
              value={projectShareData.donationAmountFromUser}
              onChange={(e) =>
                {
                  if(parseInt(e.target.value.replace(/\D/, "")) > props.projectShare.amount - props.projectShare.amountCollected)
                  {
                    setProjectShareData({
                      ...projectShareData,
                      donationAmountFromUser: props.projectShare.amount - props.projectShare.amountCollected,
                    })
                  }
                  else {
                    setProjectShareData({
                      ...projectShareData,
                      donationAmountFromUser: e.target.value.replace(/\D/, ""),
                    })
                  }
                }
              }
              className="share_input"
              type="text"
              pattern="[0-9]*"
            />
          </div>
          </div>
          <button
          style={{backgroundColor: projectShareData.donationAmountFromUser === props.projectShare.amount - props.projectShare.amountCollected? '#8080806e': ''}}
          disabled={projectShareData.donationAmountFromUser === props.projectShare.amount - props.projectShare.amountCollected}
              onClick={() =>
                {setProjectShareData({
                  ...projectShareData,
                  donationAmountFromUser: props.projectShare.amount - props.projectShare.amountCollected,
                }) }
              }
              className="project_share_btn_remaining"
            >
              {t("Pay remaining")}
            </button>
        </div>:<label style={{alignSelf: 'center', color: 'red'}}>{props.projectShare.projectStatusReason}</label>}
        {props.projectShare.projectShareStatus? <div
            className="payment_btn_wraper2"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              marginBottom: '0px'
            }}
          >
            <div
              className="share_item_board_payment noWidth"
            >
              
              <div className="div_for_share_pay">
                <button
                  onClick={() =>
                    parseInt(projectShareData.donationAmountFromUser) < 5
                      ? toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        )
                      : handleGuestPopUp(
                          window.ApplePaySession ? donateNow : startSamsungPay
                        )
                  }
                  className="button_for_share_pay"
                >
                  <img
                  src={window.ApplePaySession ? applePay : smsicon}
                    alt="donate_btn_icon_banner"
                    className="share_apple_pay_icon"
                  />
                </button>
                <label className="btn_label_for_project_share">
                  {window.ApplePaySession ? t("Apple Pay") : t("Samsung Pay")}
                </label>
              </div>
              <div className="div_for_share_pay">
              <button
                    onClick={() => {
                      if (!(projectShareData.donationAmountFromUser >= 5)) {
                        toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        );
                      } else
                        history.push(`/checkout`, {
                          from: "projectShare",
                          elements: [
                            {
                              title: projectShareData.title,
                              price: projectShareData.donationAmountFromUser,
                            },
                          ],
                          totalAmount: projectShareData.donationAmountFromUser,
                          details: projectShareData,
                        });
                    }}
                    className="button_for_share_pay"
                  >
                    <img
                      src={cashbills}
                      alt="donate_btn_icon_banner"
                      className="share_btn_img_apple"
                    />
                  </button>
                  <label className="btn_label_for_project_share">
                    {t("Donate Now")}
                  </label>
              </div>
            </div>
          </div>:<></>}
       
          <div className="project_share_bar">
          <div style={{flex: '1', position: 'relative', display: 'flex', height: '100%'}}>
            <div style={{width: (Math.floor((props.projectShare.amountCollected * 100) / props.projectShare.amount)) + "%"}} className="bar_display_red" />
            <div style={{width: (100 - Math.floor((props.projectShare.amountCollected * 100) / props.projectShare.amount)) + "%"}} className="bar_display_grey" />
            <label className="payment_numbers" style={{position: 'absolute', left: '50%', top: '20%', color: 'white'}}>{(Math.floor((props.projectShare.amountCollected * 100) / props.projectShare.amount)) + "%"}</label>
          </div>
        </div>
        </div>
        
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default ProjectShareCom;
