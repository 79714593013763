import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
// import { regularPayment } from '../../../actions/payment'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router'



const BackToModal = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  
  const history = useHistory();
  const dispatch = useDispatch()
  // const handleRegularPayment = (body) => {
  //   dispatch(regularPayment(JSON.stringify(body), props.onHide))
  // }
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='donation_pop_up_body'
    >
      <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px' }} >
        <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
          <label style={{textAlign:'center'}}>{t("Thank you for your donation")}</label>
          <div className='donation_final_popUp_btns_wraper'>
            <button onClick={() => window.location.replace('ercapp://')} className='donation_final_popUp_btn_first'>
              {t("Back to app")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BackToModal
