import React, { useEffect, useState } from 'react'
import './SwitchDonations.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'
import SwitchDonationComp from './swtichDonationComp/SwitchDonationComp';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loading from '../../ui/loading';
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import { useHistory } from "react-router-dom";

SwiperCore.use([Navigation]);





const SwitchDonations = () => {
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();

  useEffect(() => {
    if (!userLogin) {
      history.push('/')
    }
  }, [])
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [view, setView] = useState(5)
  const [displayedItem, setDisplayedItem] = useState('Alms')
  const donations = useSelector((state) => {
    return state.auth.donations;
  });
  const donationsLoading = useSelector((state) => {
    return state.auth.donationLoading;
  });
  const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  useEffect(() => {
    if (width <= 900) {
      setView(3)
    } else {
      setView(4)
    }
  }, [width])
  useEffect(() => {
    var elem = document.getElementsByClassName('switch_donations_header_item');
    for (let i = 0; i < elem.length; i++) {
      elem[i].style.fontWeight = 'unset'
    }
    // document.getElementById(displayedItem).style.fontWeight='bold'
  }, [displayedItem])

  const handleDonationNavi = (str) => {
    setDisplayedItem(str)
  }
  return (
    // <div className='switch_donations' style={lng==='arab'? {direction:'rtl', width:'100%'}:{width:'100%'}}>
    //     <div className='switch_donation_header_real' style={{width:'100%', padding:'10px 20px 10px 20px'}}>
    //         <React.Fragment>
    //             <Swiper className='swiper_forDonations' slidesPerView={view} navigation={true} style={{width:'80%', justifyContent:'center'}}>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('Alms')} id='Alms' className='switch_donations_header_item'>Alms</button>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('Zakat')} id='Zakat' className='switch_donations_header_item'>Zakat</button>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('Digging Well')} id='Digging Well' className='switch_donations_header_item'>Digging Well</button>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('For Beruit')} id='For Beruit' className='switch_donations_header_item'>For Beruit</button>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('Students Blog')} id='Students Blog' className='switch_donations_header_item'>Students Blog</button>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <button onClick={()=> handleDonationNavi('Orphans')} id='Orphans' className='switch_donations_header_item'>Orphans</button>
    //                 </SwiperSlide>
    //             </Swiper>
    //         </React.Fragment>
    //     </div>
    //     <div className='switch_donations_header'>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //         <SwitchDonationComp date='05/08/2021' amount={50} time='8:27 AM'/>
    //     </div>

    // </div>
    <div className="switch_project_page_wraper" style={{minHeight: '85vh', width:'100%'}}>
      <ShareHeader pageName={t("Recent Donations")} noShare={true} />
      <h3
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid gray",
        }}
      >
        {t("Recent Donations")}
      </h3>
      {donationsLoading ? <Loading height='85vh' /> : <div className="recentDonations_display" style={{ padding: '10px' }}>
        {donations.length > 0 ? donations?.map((donation, index) => (
          <RecentDonationTile
            key={index}
            donation={donation}
          ></RecentDonationTile>
        )) : <div>{t("No donations found")}</div>}
      </div>}
    </div>)

  function RecentDonationTile(props) {
    const dn = props.donation;
    return (
      <button
        className="recent_donation_item"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${dn.imagePath})`, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end', gap:'5px' }}
      >
        <span>{dn.dontypeName}</span>
        <span >{t("AED")} <span className='payment_numbers'>{dn.totalAmount}</span></span>
      </button>
    );
  }
}

export default SwitchDonations
