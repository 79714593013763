import React from 'react';
import './BeASponserPage.css';
import SponserRedirect from './sponserRedirect/SponserRedirect';
import SponsorPage from './sponsorPage/SponsorPage';
import { Route, Switch } from 'react-router-dom';


function BeAsponserPage() {

  return (
    <div className="App">
      <Switch>
        <Route path="/beasponser" component={SponserRedirect} exact />
        <Route path="/beasponser/:id" component={SponsorPage} />
      </Switch>
    </div>
  );
}

export default BeAsponserPage;
