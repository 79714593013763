import React, { useState, useEffect, useRef } from 'react'
import './FavoritesProjectComp.css'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import Loading from '../../ui/loading';
import Marquee from 'react-double-marquee';

const FavoritesProjectComp = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const fullProjects = useSelector((state) => state.fullProjects.fullProjects);
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [addingToCart, setAddingToCart] = useState(false);
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;

    const fullProjectsLoading = useSelector((state) => state.fullProjects.loading);
    const [fullProjectsState, setFullProjectsState] = useState(fullProjects);
    const [fullProjectsLoadingState, setfullProjectsLoadingState] = useState(fullProjectsLoading);
    const [idForLink, setIdForLink] = useState('');
    useEffect(() => {
        setFullProjectsState(fullProjects);
        setfullProjectsLoadingState(fullProjectsLoading)
    }, [fullProjects, fullProjectsLoading]);
    const style = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
    const styleForCountry = {
        width: 'unset',
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        border: 'none'
    }
    const styleForIMG = {
        height: '90px',
        width: '119px',
        cursor: 'pointer'
    }
    const styleForTitle = {
        color: 'black',
        position: 'unset',
        transform: 'unset',
        fontSize: '11px'
    }
    const styleForWraper = {
        position: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto',
        width: 'auto'
    }
    const [liked, setLiked] = useState(false)
    const history = useHistory()
    const [projectData, setProjectData] = useState({
        projectId: props.projectId,
        projectName: props.title,
        projectImg: props.projectPhoto,
        projectAmount: props.projectAmount,
        countryName: props.countryTitle,
        countryId: props.countryId,
        countryImg: props.flag,
        projectYear: props.projectYear
    })
    const [orphansData, setOrphansData] = useState({
        orphanId: props.projectId,
        orphanImg: props.projectPhoto,
        orphanName: props.title,
        orphanCountryId: props.countryId,
        orphanCountryName: props.countryTitle,
        orphanCountryImg: props.flag,
        paymentWay: 'N',
        years: '2',
        donationAmountFromUser: 200,
        item_type: 1
    })
    useEffect(() => {
        if (props.fromProjects) {
            setProjectData({
                projectId: props.projectId,
                projectName: props.title,
                projectImg: props.projectPhoto,
                projectAmount: props.projectAmount,
                countryName: props.countryTitle,
                countryId: props.countryId,
                countryImg: props.flag,
                projectYear: props.projectYear
            })
        } else {
            setOrphansData({
                orphanId: props.projectId,
                orphanImg: props.projectPhoto,
                orphanName: props.title,
                orphanCountryId: props.countryId,
                orphanCountryName: props.countryTitle,
                orphanCountryImg: props.flag,
                paymentWay: 'N',
                years: '2',
                donationAmountFromUser: 200,
                item_type: 1
            })
        } 
    }, [props.projectId])
    // fullProjectsState
    useEffect(() => {
        if (props.fromProjects) {
            let idWeNeed = fullProjectsState?.filter(item => item.projectID === props.projectId).slice(0).shift()
            setIdForLink(idWeNeed?.projTypeID)
        }
    }, [fullProjectsState, props.projectId])
    useEffect(() => {
        if (props.fromProjects) {
            if (!userLogin) {
                if (props.currentProfile?.projects.filter(item => item.projectId === projectData.projectId && item.countryId === projectData.countryId && item.projectAmount === projectData.projectAmount && item.projectYear === projectData.projectYear).length > 0) {
                    setLiked(true)
                } else {
                    setLiked(false)
                }
            } else {
                if (props.currentProfile?.filter(item => item.projectID === projectData.projectId && item.projectYear === projectData.projectYear).length > 0) {
                    setLiked(true)
                  } else {
                    setLiked(false)
                  }
            }
        } else {
            if (!userLogin) {
                if (props.currentProfile?.orphans?.filter(item => item.orphanId === orphansData.orphanId && item.orphanCountryId === orphansData.orphanCountryId).length > 0) {
                    setLiked(true)
                } else {
                    setLiked(false)
                }
            } else {
                if (props.currentProfile?.filter(item => item.orphanTypeID === orphansData.orphanId && item.countryID === orphansData.orphanCountryId).length > 0) {
                  setLiked(true)
                } else {
                  setLiked(false)
                }
            }
        }
    }, [props.currentProfile, props])
    const handleClick = (str) => {
        if (props.fromOrphans) {
            history.push(`/beasponser?orphanId=${props.projectId}&orphanCountryId=${props.countryId}`)
        } else {
            history.push(`/projects?projectTypeID=${idForLink}&countryId=${props.countryId}&projectAmount=${props.projectAmount}`)

        }
    }
    const handleLikeDisLike = async (str) => {
        setAddingToCart(true);
        if (liked) {
           await props.handleDisLike(str)
           if (_isMounted.current) {
              setAddingToCart(false) 
           }
            
        } else {
           await props.handleLike(str)
           if (_isMounted.current) {
              setAddingToCart(false) 
           }
        }
    }
    return (
        <React.Fragment>
            {fullProjectsLoadingState ?
            <Loading width='170px' height='238px'/> :
            <div className='favorites_project_comp' style={props.projectForBag ? style : null}>
                <React.Fragment>
                    <div className='wraper_for_project_fav_img' style={props.projectForBag ? styleForWraper : null}>
                        <img style={props.projectForBag ? styleForIMG : null} onClick={() => handleClick(props.projectId)} src={props.projectPhoto} alt={props.title} className='project_img_for_fav_page' />
                        {addingToCart?
                            <button disabled={addingToCart} className='like_btn'>
                                <div className="containerAnime">
                                    <div className="ballFav"></div>
                                    <div className="ballFav"></div>
                                </div>
                            </button>:
                            <button onClick={() => handleLikeDisLike(props.fromProjects? projectData:orphansData)} className='like_btn'>{liked ? <i style={{ color: '#ee4236' }} className="fas fa-heart"></i> : <i style={{ color: 'white' }} className="fas fa-heart"></i>}</button>
                        }
                        {props.title.length >= 20?
                        <React.Fragment>
                                <div className={lng === 'arab' ? 'cssmarquee_arab btn_img_label_fav_projects' : 'cssmarquee btn_img_label_fav_projects'} style={{direction:'ltr'}}>
                                    <Marquee speed={0.025} childMargin={15} direction={lng==='arab'? 'right':'left'}><label className='label_of_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
                                </div>
                        </React.Fragment>:
                        <React.Fragment>
                                <div className='btn_img_label_fav_projects'><label className='label_of_title' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', textAlign: lng === 'arab' ? 'right' : 'left' }}>{props.title}</label></div>
                        </React.Fragment>
                    }
                    </div>
                    <div className='country_for_project_favorites' style={props.projectForBag ? styleForCountry : null}>
                        <img src={props.flag} alt={props.countryTitle} className='flag_space_img' />
                        <label className='flag_space_label'>{props.countryTitle?.length > 9 ? props.countryTitle.slice(0, 10) + '.' : props.countryTitle}</label>
                    </div>
                </React.Fragment>
            </div>
            }
        </React.Fragment>
    )
}

export default FavoritesProjectComp
