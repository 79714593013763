import React, { useState } from 'react'
import './UAEPASSAuthOptions.css'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
// import { regularPayment } from '../../../actions/payment'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router'
import closeIcon from '../../../../assets/close.png'
import Guest from '../LinkAccount/Guest';
import OTPUAE from '../LinkAccount/OTPUAE';


const UAEPASSAuthOptions = (props) => {
  const { paymentfunction, fromcheckout, ...rest } = props
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [display, setDisplay] = useState(0)
  const history = useHistory();
  const dispatch = useDispatch()
  return (
    <Modal
      {...rest}
      size={display===0 && !props.show2? "sm":'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='donation_pop_up_body'
    >
      <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px', padding: display !==0? '0px':null, position:'relative', fontFamily: lng==='arab'? "'arab_font":'english_font' }} >
        <div  style={{backgroundColor:'transparent', display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%', padding:'7px', position:'absolute', top:'0px', left:'0px'}}>
          <img onClick={()=> {props.onHide(); setDisplay(0)}} src={closeIcon} alt='close_icon' style={{width:'15px', height:'15px', cursor:'pointer'}}/>
        </div>
        {display===0 && !props.show2?
        <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
          <label>{t("Would you like to ")} <span style={{ fontWeight: 'bold' }}>{t("Register")}</span> {t("using your existing UAE PASS details or link to an existing account?")}</label>
          <div className='donation_final_popUp_btns_wraper'>
             {<button onClick={() => props.setIsManualLink(false)} className='donation_final_popUp_btn_first'>
              {t("Register using UAE PASS account")}
            </button>} 
            <button onClick={() => setDisplay(1)} className='donation_final_popUp_btn_second'>
              {t("Link existing account")}
            </button>
            <button onClick={async() => await props.logout()} className='donation_final_popUp_btn_second_logout'>
              {t("Logout")}
            </button>
          </div>
        </div>:
        props.show2?
        <OTPUAE 
        setUAEAuth={props.setUAEAuth}
        setUAEAuthStep2={props.setUAEAuthStep2}
        setLoadingModalShow={props.setLoadingModalShow}
        fromState={null}
        setLoadingSwitch={props.setLoadingSwitch}
        lodaingSwitch={props.lodaingSwitch}
        setWait={props.setWait}
         uaeLinkBody={props.uaeLinkBody} finalDonationModal={true} fromCheckoutInner={fromcheckout} paymentFunc = {paymentfunction} email={props.email} setManualLink={props.setManualLink} setIsManualLink={props.setIsManualLink}/>
        :<Guest 
        setUAEAuth={props.setUAEAuth}
        setUAEAuthStep2={props.setUAEAuthStep2}
        finalDonationModal={true} fromCheckoutInner={fromcheckout} paymentFunc = {paymentfunction} email={props.email} setManualLink={props.setManualLink} setIsManualLink={props.setIsManualLink}/>
      }
        
      </Modal.Body>
    </Modal>
  )
}

export default UAEPASSAuthOptions
