import "./OTP_MODEL.css";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { otpVerfication } from "../../../../api";
import { NEW_LAID_ACCOUNT } from "../../../../constants/actionTypes";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";

function OTPModal(props) {
  const email = props.email;
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  const [userInfoLocalAid, setUserInfoLocalAid] = useState({
    otp: "",
  });
  const history = useHistory();
  const handleChange = (e) => {
    setUserInfoLocalAid({
      ...userInfoLocalAid,
      [e.target.name]: e.target.value.replace(/\D/, ""),
    });
  };
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const checkOTP = () => {
    setLoading(true);
    setError("");
    otpVerfication({
      email: email,
      otp: parseInt(userInfoLocalAid.otp),
      vln_id: "",
      persn_id: "",
      sponser_id: "",
    })
      .then((response) => {
        if (response.data.result) {
          dispatch({ type: NEW_LAID_ACCOUNT, payload: response.data.data });
          history.push("/localAid/setup-relative-identity");
        } else {
          setError(response.data.details);
        }
      })
      .catch((error) => {
        toastifyMessage("Error while creating new local AID", undefined, "warning");
      })
      .finally(() => setLoading(false));
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="otpModalDialog"
      contentClassName="otpModalContent"
      centered
    >
      <Modal.Body className="otpModalBody">
        <div>
          <div className="form_left_side">
            <div
              className="custom_input_wraper"
              style={{
                width: "100% !important",
              }}
            >
              <input
                maxLength="15"
                type="text"
                onChange={handleChange}
                className="local_aid_input"
                value={userInfoLocalAid.otp}
                name="otp"
                placeholder={t("Enter the OTP here")}
                required
                disabled={isLoading}
              />
            </div>
          </div>
          <div
            style={{ justifyContent: "center", display: "flex", color: "red" }}
          >
            {error || ""}
          </div>
          <div
            style={{
              marginTop: "10px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* {isLoading ? (
              <div>Loading...</div>
            ) : ( */}
            <button
              type="submit"
              className="otp_modal_submit"
              onClick={isLoading ? () => { } : checkOTP}
              style={
                isLoading
                  ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                  : {}
              }
            >
              {isLoading ? (
                <div>
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                <>
                  <span style={{ flex: "16" }}>{t("Submit")}</span>
                  {lng === "arab" ? (
                    <i className="fas fa-arrow-left"></i>
                  ) : (
                    <i className="fas fa-arrow-right"></i>
                  )}
                </>
              )}
            </button>
            {/* )} */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default OTPModal;
