// import React from "react";
import donate_estate from '../../assets/estate_donate.svg';
import download_img from '../../assets/download_img.svg';
import './donateestate.scss';
// import { useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import React, {
  useState,
  useEffect,
  useRef,
  useTransition,
  Fragment
} from 'react';
import { set } from 'date-fns';
import { donateRealestate } from '../../api/index';
import Loading from '../ui/loading';
import { toastifyMessage } from '../../handlers/toastifyMessage';
import { tr } from 'date-fns/locale';
import { Modal } from 'react-bootstrap';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { useTranslation } from 'react-i18next';
import { $ } from 'dom7';

const DonateEstate = () => {
  let userLogin = JSON.parse(localStorage.getItem('userLogin'))?.Results || '';
  const [name, setName] = useState(userLogin[0]?.name_a || '');
  const [email, setEmail] = useState(userLogin[0]?.email || '');
  const [phone, setPhone] = useState(userLogin[0]?.mobile_no || '');
  const [file64, setFile64] = useState('');
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [lat, setLat] = useState(24.422139);
  const [long, setLong] = useState(54.445503);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [show, setShow] = useState(false);
  const [fileDescription, setFileDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [emiratesId, setEmiratesId] = useState(userLogin[0]?.emiratesID || '');
  const [description, setDescription] = useState('');
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;

  useEffect(() => {
    // Load the Google Maps JavaScript API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBQonMikYiu-cJ6VrDuPLIxuCPWP3gF7tY&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onload = () => initMap();
    document.head.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);
  // useEffect(() => {
  //   if(userLogin){
  //     console.log(userLogin);
  //     setName(userLogin[0].name_a)
  //     setEmail(userLogin[0].email)
  //     setPhone(userLogin[0].mobile_no)
  //     setEmiratesId(userLogin[0].emiratesID)
  //   }
  // })
  const initMap = () => {
    const mapInstance = new window.google.maps.Map(
      document.getElementById('map'),
      {
        center: { lat: 24.422139, lng: 54.445503 },
        zoom: 14,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP // Set the position to the right top
        }
      }
    );

    setMap(mapInstance);

    let existingMarker = null; // Variable to store the existing marker

    mapInstance.addListener('click', (e) => {
      // Remove the existing marker if it exists
      if (existingMarker) {
        existingMarker.setMap(null);
      }

      // Create a new marker at the clicked position
      const newMarker = new window.google.maps.Marker({
        position: e.latLng,
        map: mapInstance
      });

      // Set the new marker as the existing marker
      existingMarker = newMarker;

      // Print the latitude and longitude
      setLat(e.latLng.lat());
      setLong(e.latLng.lng());
    });
  };
  const handleClose = () => setShow(false);
  const handleFileChange = (e) => {
    const files = e.target.files[0];
    if (files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile64(reader.result.toString().split(',')[1]);
        setFile(files.name);
      };
      reader.readAsDataURL(files);
    }
  };
  const handleAttachments = () => {
    if (!fileDescription || !file || !file64)
      return toastifyMessage('Please fill all fields', undefined, 'warning');
    const newAttachment = {
      file_desc: fileDescription,
      file_name: file,
      file_64: file64
    };

    setAttachments([...attachments, newAttachment]);
    setFileDescription('');
    setShow(false);
  };
  const handleSend = async () => {
    // Construct your API request body
    if (
      !name ||
      !email ||
      !phone ||
      !lat ||
      !long ||
      !attachments ||
      attachments.length === 0 ||
      !emiratesId
    )
      return toastifyMessage('Please fill all fields', undefined, 'warning');
    try {
      // const files = await readFileAsBase64(fileBase64);

      setLoading(true);
      const apiRequestBody = {
        realstate: {
          name_a: name,
          mobile_no: phone,
          email: email,
          emirate_id: emiratesId,
          desc_a: description,
          x_location: lat,
          y_location: long,
          user_id: 'WEBAPP',
          attachments: attachments
        }
      };
      if (apiRequestBody) {
        let res = await donateRealestate(apiRequestBody);
        if (res?.data?.status === true) {
          toastifyMessage(
            'Your request has been sent successfully',
            undefined,
            'success'
          );
          setName('');
          setEmail('');
          setPhone('');
          setEmiratesId('');
          setFile('');
          setFile64('');
          setLat(24.422139);
          setLong(54.445503);
          setAttachments([]);
          setDescription('');
        } else {
          toastifyMessage(
            res?.data?.result || 'Something went wrong',
            undefined,
            'error'
          );
        }
      }
    } catch (err) {
      toastifyMessage(
        err?.response?.data?.result || 'Something went wrong',
        undefined,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  const handleFileDelete = () => {
    const newUploadedFiles = [...attachments];
    newUploadedFiles.splice(deleteIndex, 1);
    setAttachments(newUploadedFiles);
    setDeleteModal(false);
  };
  const handleShoWDelete = (index) => {
    setDeleteModal(true);
    setDeleteIndex(index);
  };

  const [shareUrl, setShareUrl] = useState(false);
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    const truncatedValue = numericValue.slice(0, 15);
    setPhone(truncatedValue);
  };
  const handleEidChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    const truncatedValue = numericValue.slice(0, 15);
    setEmiratesId(truncatedValue);
  };
  useEffect(() => {
    let textOfPageArr = window.location.href.split('/');
    textOfPageArr.splice(textOfPageArr.length - 1, 1);
    setShareUrl(textOfPageArr.join('/'));
  }, []);

  return (
    <Fragment>
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        textToBeCopied={shareUrl}
        pageName={t('DONATE WITH REAL ESTATE')}
        path={'beasponser'}
      />

      <div
        className="donate_estate"
        style={lng === 'arab' ? { direction: 'rtl' } : null}
      >
        <div className="left_side_donate">
          <div className="contents_donate">
            <h1>{t('DONATE WITH REAL ESTATE')}</h1>
          </div>
          <div className="child_donate_main">
            <p>
              {t(
                'Unlock the power of giving through real estate! Your generous donation can make a lasting impact. Complete the form below to donate real estate and be a catalyst for positive change. Join us in building a better future together!'
              )}
            </p>
            <input
              type="text"
              placeholder={t('Name')}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <input
              type="email"
              placeholder={t('Email')}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              type="text"
              placeholder={t('Phone Number  Eg:- 971 5X XXXXXXX')}
              // onChange={(e) => setPhone(e.target.value)}
              value={phone}
              onChange={(e) => handlePhoneChange(e)}
              pattern="[0-9]*"
            />
            <input
              type="text"
              placeholder={t('Emirates ID Eg:- 784 5X XXXXXXXXX')}
              onChange={(e) => handleEidChange(e)}
              value={emiratesId}
              maxLength="15"
              pattern="[0-9]*"
            />
            <textarea
              type="text"
              placeholder={t('Write your Idea Description')}
              rows={10}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <div className="maps">
              <div id="map" style={{ width: '100%', height: '250px' }}></div>
            </div>

            <div className="file-input-container">
              <div
                style={{
                  padding: ' 10px 10px',
                  backgroundColor: '#efefef',
                  border: 'none',
                  cursor: 'pointer'
                }}
                onClick={() => setShow(true)}
              >
                {t('Click to Upload File')}
              </div>
              {attachments.length > 0 &&
                attachments.map((uploadedFile, index) => (
                  <div
                    key={index}
                    style={{
                      border: 'solid 0.5px lightgrey',
                      marginTop: '1rem',
                      padding: ' 10px'
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <span>{uploadedFile.file_name}</span>
                      <p style={{ fontSize: '13px', textAlign: 'left' }}>
                        {uploadedFile.file_desc}
                      </p>
                    </div>
                    <i
                      style={{ cursor: 'pointer' }}
                      class="fa fa-times"
                      onClick={() => handleShoWDelete(index)}
                    ></i>
                  </div>
                ))}
              {/* <label className="btn-up">
              <input type="file" onChange={handleFileChange} />
              <img src={download_img} alt="download" width="30px" />
            </label>
            <span style={{fontSize:"13px",margin:"0px"}}>Allowed file types: pdf, png, jpeg, jpg</span> */}
            </div>
            <button onClick={handleSend}>
              {loading ? <Loading height="30px" /> : `${t('SEND')}`}
            </button>
          </div>
        </div>
        <div className="right_side_donate">
          <div className="image_donate">
            <img src={donate_estate} alt="image_donate" />
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          style={lng === 'arab' ? { direction: 'rtl' } : null}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Upload File')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-input-container">
              <div
                style={{
                  padding: ' 10px 10px',
                  backgroundColor: '#efefef',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <input type="file" onChange={handleFileChange} />
                <img src={download_img} alt="download" width="30px" />
              </div>
              {/* </label> */}
              <span style={{ fontSize: '13px', margin: '0px' }}>
                {t('Allowed file types: pdf, png, jpeg, jpg')} <br />
              </span>
              <div>
                <input
                  style={{
                    padding: ' 10px 10px',
                    backgroundColor: '#efefef',
                    border: 'none',
                    width: '100%'
                  }}
                  placeholder={t('Description of File')}
                  type="text"
                  onChange={(e) => setFileDescription(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ backgroundColor: '#b11818', border: 'none' }}
              className="btn btn-primary"
              onClick={handleAttachments}
            >
              {t('Add Attachmnets')}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              {t('Are you sure you want to delete this file?')}
            </div>
          </Modal.Body>
          {/* <hr /> */}
          <Modal.Footer>
            <button
              style={{
                backgroundColor: '#b11818',
                border: 'none',
                width: 'fit-content',
                alignSelf: 'center'
              }}
              className="btn btn-primary m-3 "
              onClick={handleFileDelete}
            >
              {t('Confirm')}
            </button>
            <button
              style={{
                backgroundColor: '#b11818',
                border: 'none',
                width: 'fit-content',
                alignSelf: 'center'
              }}
              className="btn btn-primary m-3 "
              onClick={() => setDeleteModal(false)}
            >
              {t('No')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default DonateEstate;
