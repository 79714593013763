import React, { useState, useEffect } from 'react'
import './LocalAidProfile.css'
import LocalAidProfileComp from './localAidProfileComp/LocalAidProfileComp'
import icon1 from '../../../assets/nation id.png'
import icon2 from '../../../assets/edducation.png'
import icon3 from '../../../assets/ntionality.png'
import icon4 from '../../../assets/mobile number.png'
import icon5 from '../../../assets/date of birth.png'
import icon6 from '../../../assets/gender.png'
import icon7 from '../../../assets/family name.png'
import icon8 from '../../../assets/occupation.png'
import icon9 from '../../../assets/marital status.png'
import icon10 from '../../../assets/health.png'
import icon12 from '../../../assets/profile 2.png'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getLocalAIDProfile } from '../../../actions/localAID'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'
import Loading from '../../ui/loading'



const LocalAidProfile = () => {
    const dispatch = useDispatch();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;

    useEffect(() => {
        if (userLogin?.Results[0]?.person_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getLocalAIDProfile('AR', userLogin?.Results[0]?.person_id))
            } else {
                dispatch(getLocalAIDProfile('EN', userLogin?.Results[0]?.person_id))
            }
        }
    }, [lng]);

    const laRProfile = useSelector(state => state.laProfile.laRProfile);
    const loading = useSelector(state => state.laProfile.loading);
    const [profileDetails, setProfileDetails] = useState(laRProfile?.Local_Aids_Info?.slice(0).shift())
    useEffect(() => {
        setProfileDetails(laRProfile?.Local_Aids_Info?.slice(0).shift())
    }, [laRProfile])
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();

    if (userLogin?.Results[0]?.person_id !== '') {
        return (
            <div className='file_details' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader noShare={true} pageName={t("Profile")} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Profile")}</h3>
                </div>
                {loading?
                <Loading height='85vh'/>:
                <React.Fragment>
                <div className='VIP'>
                    <LocalAidProfileComp englishFont={true} icon={icon1} keyOf={t('National ID')} value={profileDetails?.NATIONAL_ID} />
                    <LocalAidProfileComp englishFont={true} icon={icon12} keyOf={t('Profile Number')} value={profileDetails?.PERSON_ID} />
                </div>
                <div className='personalInfo'>
                    <label style={{ fontWeight: 'bold', padding: '0px 10px 0px 10px' }}>{t("Personal Information")}</label>
                    <div className='VIP'>
                        <LocalAidProfileComp icon={icon6} keyOf={t('Gender')} value={profileDetails?.GENDER} />
                        <LocalAidProfileComp icon={icon7} keyOf={t('Family Name')} value={profileDetails?.FAMILY_NAME} />
                    </div>
                    <div className='VIP'>
                        <LocalAidProfileComp englishFont={true} icon={icon5} keyOf={t('Date of Birth')} value={profileDetails?.DOB} />
                        <LocalAidProfileComp icon={icon3} keyOf={t('Nationality')} value={profileDetails?.COUNTRY_NAME_A} />
                    </div>
                    <div className='VIP'>
                        <LocalAidProfileComp englishFont={true} icon={icon4} keyOf={t('Mobile')} value={profileDetails?.PRM_MOBILE_NO} />
                        <LocalAidProfileComp icon={icon9} keyOf={t('Martial Status')} value={profileDetails?.MARTSTAT_NAME_A} />
                    </div>
                </div>
                <div className='personalInfo'>
                    <label style={{ fontWeight: 'bold', padding: '0px 10px 0px 10px' }}>{t("Qualifcation")}</label>
                    <div className='VIP'>
                        <LocalAidProfileComp icon={icon2} keyOf={t('Education')} value={profileDetails?.EDU_LEVEL_NAME_A} />
                        <LocalAidProfileComp icon={icon8} keyOf={t('Occupation')} value={profileDetails?.JOB_NAME_A} />
                    </div>
                    <div className='VIP lastOnee'>
                        <LocalAidProfileComp icon={icon10} keyOf={t('Health Status')} value={profileDetails?.HEALTH_STAT_NAME_A} />
                    </div>
                </div>
                </React.Fragment>}
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default LocalAidProfile
