import React, { useState, useEffect } from "react";
import "./Settings.css";
import { useTranslation } from "react-i18next";
import ProfileBtn from "../userPage/profileBtn/ProfileBtn";
import accounts from "../../assets/relatedAccounts.png";
import ChangePass from "../../assets/password.png";

import creditcard from "../../assets/creditcard.png";
import languageForPro from "../../assets/languageForPro.png";
import chatForProfile from "../../assets/chatForProfile.png";
import { useDispatch, useSelector } from "react-redux";
import LangChangeModel from "./LangChangeModel/LangChangeModel";
import { BottomSheet } from "react-spring-bottom-sheet";
import AccountLinkModal from "../userPage/AccountLinkModal/AccountLinkModal";
import { changeAccount } from "../../actions/user";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";

const Settings = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  const [showLangChange, setLangChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [accountLinkModal, setAccountLinkModal] = useState(false);
  const handleAccountChange = (account) => {
    dispatch(
      changeAccount({
        sponser_id: account.extra_sponser_id,
        name: account.extra_sponser_name,
      })
    );
  };
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  if (userInfo) {
    return (
      <div
        className="volunteer_portal_lobby"
        style={
          
          lng === "arab"
            ? { direction: "rtl", minHeight: "85vh", padding: '0px', gap:'30px' }
            : { minHeight: "85vh", padding: '0px', gap:'30px'}
        }
      >
        <ShareHeader pageName={t("Settings")} noShare={true}/>
        <div className="submit_requ_header">
          <h3 style={{ color: "gray" }}>{t("Settings")}</h3>
        </div>
        <div className="volunteer_portal_lobby_both_sides">
          <div className="volunteer_portal_lobby_left_side">
            <ProfileBtn
              goTo="/editprofile"
              icon='none'
              title={
                <span
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <i
                    style={{ color: "#ee4236", fontSize: width>900? "37px":'23px' }}
                    className="fas fa-user"
                  ></i>{" "}
                  {t("Profile settings")}
                </span>
              }
            />
            <ProfileBtn goTo="onclick" icon={languageForPro} onTap={() => {setLangChange(true);}} title={t("Language settings")}/>
            <ProfileBtn
              goTo="contact-us"
              icon='none'
              title={
                <span
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <i
                    style={{ color: "#ee4236", fontSize: width>900? "37px":'23px' }}
                    className="far fa-comments"
                  ></i>{" "}
                  {t("Contact Us")}
                </span>
              }
            />
          </div>
          <div className="volunteer_portal_lobby_right_side">
            <ProfileBtn goTo="onclick" onTap={() => {setOpen(true);}} icon={accounts} title={t("Accounts settings")}/>
            <ProfileBtn goTo="/payment-method" icon={creditcard} title={t("Payment Method settings")}/>
            <ProfileBtn goTo="/auth/reset-password" icon= {ChangePass} title={t("Change your password")}/>
          </div>
        </div>
        <LangChangeModel
          show={showLangChange}
          dialogClassName="modal-60w"
          onHide={() => {
            setLangChange(false);
          }}
        />
        <BottomSheet
          open={open}
          onDismiss={() => setOpen(false)}
          header={<h6>{t("Accounts")}</h6>}
          style={{fontFamily: lng==='arab'? "arab_font":'english_font', direction: lng==='arab'? "rtl":'ltr'}}
        >
          {userInfo?.Results &&
          userInfo?.Results.length > 0 &&
          userInfo?.Results[0].extra_spons
            ? userInfo?.Results[0].extra_spons.map((spons,i) => {
                return (
                  <AccountTile
                  key={i}
                    account={spons}
                    onClick={(account) => {
                      handleAccountChange(account);
                    }}
                    onDismiss={() => setOpen(false)}
                  ></AccountTile>
                );
              })
            : null}
          <div
            onClick={() => {
              setOpen(false);
              setAccountLinkModal(true);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {t("Add Account")}
          </div>
          <div style={{ height: "100px" }}></div>
        </BottomSheet>
        <AccountLinkModal
          email={
            userInfo?.Results &&
            userInfo?.Results.length > 0 &&
            userInfo?.Results[0]
              ? userInfo?.Results[0].email
              : ""
          }
          show={accountLinkModal}
          dialogClassName="modal-60w"
          onHide={() => {
            setAccountLinkModal(false);
          }}
        />
      </div>
    );
  } else {
    window.location.replace("/");
  }
  function AccountTile(pr) {
    const account = pr.account;
    return (
      <div
        className="account_tile_card"
        onClick={() => {
          pr.onClick(account);
          pr.onDismiss();
        }}
      >
        <div className="account_name_avatar">
          <div style={{ color: "white" }}>{account.extra_sponser_name[0]}</div>
        </div>
        <div>
          <div>{account.extra_sponser_name}</div>
          <div className='payment_numbers'>{account.extra_sponser_id}</div>
        </div>
      </div>
    );
  }
};
export default Settings;
