import React, {useEffect, useState} from 'react'
import './Pending.css'
import notFound from '../../../../assets/notThere.png'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {getPendingRequ} from '../../../../actions/localAID'
import RequItem from '../requestItem/RequItem'
import Loading from '../../../ui/loading';
import LoadingModal from '../../../Auth/loginSignPage/loadingPopUp/loadingPopUp';

const Pending = () => {
    const dispatch = useDispatch();
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [innerLoading, setInnerLoading] = useState(true)

    useEffect(()=> {
        if (userLogin?.Results[0]?.person_id!=='') {
            if (lng==='arab') {
                dispatch(getPendingRequ('AR',userLogin?.Results[0]?.person_id))
            } else {
                dispatch(getPendingRequ('EN',userLogin?.Results[0]?.person_id))
            }
        }
            
      }, [lng]);
      const laPending = useSelector(state=> state.laPending);
    const [pendingRequ, setPendingRequ] = useState(laPending?.UserLocalAidsRequestsDetails?.slice(0))
    useEffect(()=> {
        setPendingRequ(laPending?.UserLocalAidsRequestsDetails?.slice(0))
        if (laPending.hasOwnProperty('UserLocalAidsRequestsDetails')) {
            setInnerLoading(false)
        }
    }, [laPending])
        return (
            <div className='pending'>
                {pendingRequ?.length===0 && !innerLoading?
                <div className='notFoundWraper'>
                    <label style={{opacity:'0.8'}}>{t("No Requests Found")}</label>
                    <img src={notFound} alt='notFound' className='noutFoundImg'/>
                </div>:
                innerLoading?
                <Loading height='600px'/>:
                <div className='completed'>
            {pendingRequ?.map(item=> 
                <RequItem key={item.REQ_ID} title={item.AIDTYPE_NAME_A} requestNumber={item.REQ_ID} year={item.REQ_YEAR} requClassi={item.CATGRP_NAME_A}/>
                )}
        </div>
            }  
            </div>
        )
}

export default Pending
