import "./OTP_MODEL_PHONE.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { otpVerfication, updateNumber, confirmPhoneOtp } from "../../../api/index";
import { Spinner } from "react-bootstrap";
import Loading from "../../ui/loading";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
function OTPModalPhone(props) {
  const email = props.email;
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const history = useHistory();
  const handleChange = (e) => {
    switch (e.target.name) {
      case "phoneNumber":
        setPhoneNumber(e.target.value.replace(/\D/, ""));
      case "otp":
        setOTP(e.target.value.replace(/\D/, ""));
        break;
      default:
        break;
    }
  };

  useLayoutEffect(() => {
    return () => {
      setStepNumber(0);
      setOTP("");
      setPhoneNumber("");
      setLoading(false);
    };
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const checkOTP = () => {
    const dataToSend = {
      user_email: userLogin?.Results[0]?.email,
      new_number: phoneNumber?.slice(0,4) === '+971'? '0' + phoneNumber?.slice(4): phoneNumber.slice(1),
      otp: OTP,
    };
    setLoading(true);
    confirmPhoneOtp(dataToSend)
      .then((res) => {
        if (res.data.status == true) {
          props.onHide();
          toast.success(t("Phone number has been edited succesfully"));
          localStorage.setItem("userLogin", JSON.stringify({Results:res.data.data}));
          props.setChange(props.change + 1)
          if (!props.fromCompleteModal) {
            window.location.reload();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkNumber = () => {
    if (phoneNumber?.slice(0,4) === '+971') {
      if (('0' + phoneNumber?.slice(4)).length != 10) {
      setError(t("\"Mobile number\" should be 10 digits long and start with \"05\""));
      setTimeout(() => {
        setError("");
      }, 50000);
      return;
    }
    if (!('0' + phoneNumber?.slice(4)).startsWith("05")) {
      setError(t("Phone number should start with 05"));
      setTimeout(() => {
        setError("");
      }, 50000);
      return;
    }
    }
    if (phoneNumber === userLogin.Results[0]?.mobile_no || '0' + phoneNumber?.slice(4) === userLogin.Results[0]?.mobile_no) {
      setError(t("This number is already active"));
      setTimeout(() => {
        setError("");
      }, 50000);
      return;
    }
    if (!phoneNumber) {
      setError(t("Please add your mobile number."));
      setTimeout(() => {
        setError("");
      }, 50000);
      return;
    }
    
    const dataToSend = {
      user_email: userLogin?.Results[0]?.email,
      new_number: phoneNumber?.slice(0,4) === '+971'? '0' + phoneNumber?.slice(4): phoneNumber.slice(1),
    };
    setLoading(true);
    updateNumber(dataToSend)
      .then((res) => {
        if (res.data.data.length === 0) {
          setOTP("");
          setStepNumber(1);
        } else {
          props.onHide();
          toast.success(t("Phone number has been edited succesfully"));
          localStorage.setItem("userLogin", JSON.stringify({Results:res.data.data}));
          props.setChange(props.change + 1)
          if (!props.fromCompleteModal) {
            window.location.reload();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // API: /update_number
    // parameters: user_email, new_number
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="otpModalDialog"
      contentClassName="otpModalContent"
      centered
    >
      <Modal.Body className="otpModalBody" style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            direction: lng == "arab" ? "rtl" : "ltr",
          }}
        >
          {stepNumber == 0 ? (
            <h5>{t("Enter your phone number")}</h5>
          ) : (
            <h5>{t("Verify your OTP")}</h5>
          )}
          <div className="form_left_side">
            <div
              className="custom_input_wraper"
              style={{
                width: "100% !important",
                background: error ? "#ff7474" : "transparent",
                transition: "1s all",
                direction: 'ltr'
              }}
            >
              {stepNumber == 0 ? (
                <PhoneInput
                  disabled={isLoading}
                  defaultCountry= 'AE'
                  name="phoneNumber"
                  sstyle={{
                    background: "transparent",
                  }}
                  id="mobile"
                  value={phoneNumber}
                  onChange={(phone)=> setPhoneNumber(phone)}
                  required
                  className={phoneNumber? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                />
              ) : (
                <input
                  maxLength="4"
                  type="text"
                  onChange={handleChange}
                  className={OTP? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={OTP}
                  style={{
                    background: "transparent",
                  }}
                  name="otp"
                  placeholder={t("Enter the OTP here")}
                  required
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
          <div
            style={{
              // justifyContent: "center",
              // display: "flex",
              color: "#ff7474",
              // display: error ? "block" : "none",
              transition: "1s all",
            }}
          >
            {error}
          </div>
          <div
            style={{
              marginTop: "10px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <button
              type="submit"
              className="otp_modal_submit"
              onClick={
                isLoading ? () => {} : stepNumber == 0 ? checkNumber : checkOTP
              }
              style={
                isLoading
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  : {}
              }
            >
              {isLoading ? (
                <div>
                  <Loading height="24px" small={'24px'} color={'white'} />
                </div>
              ) : (
                <>
                  <span style={{ flex: "16" }}>{t("Submit")}</span>
                  {lng === "arab" ? (
                    <i className="fas fa-arrow-left"></i>
                  ) : (
                    <i className="fas fa-arrow-right"></i>
                  )}
                </>
              )}
            </button>
            {/* )} */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default OTPModalPhone;
