import React from 'react'

const DonateBehalf = ({width, lng, t, isDonatedBehalf, setIsDonatedBehalf, donateBehalfName, setDonateBehalfName}) => {
  return (
    <div style={{display:'flex', flexDirection: 'column', gap: width >= 900 ? '10px': null}}>
                 
                  <div className="behalf_donate_div">
                  <h4 className="" htmlFor="terms">{t("Sponsor on behalf of another person:")}</h4>
                  <div className="behalf_buttons">
                  <button
                  disabled={false}
                  style={
                    isDonatedBehalf
                      ? { backgroundColor: "#ee4236", color: "white" }
                      : null
                  }
                  id="btn_one"
                  
                  className="btn btn-block btn-light btn-frequ btn-frequ-donate"
                  onClick={() => setIsDonatedBehalf(true)}
                >
                  {t("Yes")}
                </button>
                <button
                  disabled={false}
                  style={
                    !isDonatedBehalf
                      ? { backgroundColor: "#ee4236", color: "white" }
                      : null
                  }
                  id="btn_one"
                  onClick={() => setIsDonatedBehalf(false)}

                  className="btn btn-block btn-light btn-frequ btn-frequ-donate"
                >
                  {t("No")}
                </button>
                
              </div>
                  </div>
                  {isDonatedBehalf?<input
              style={{
                // marginLeft: "45px",
                backgroundPositionX: lng === "arab" ? "0px" : "100%",
                width: width <= 900 ? "90%" : null,
              }}
              name="workPlaceID"
              placeholder='Enter name of person/people you would like to sponsor on behalf of'
              className="donate-behalf-input"
              value={donateBehalfName}
              onChange={(e) => setDonateBehalfName(e.target.value)}
            />:null}
                  
                </div>
  )
}

export default DonateBehalf