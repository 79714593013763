import { GET_ALL_JOBS } from "../../constants/actionTypes";

export default (vlnJobs = [], action) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return action.payload || [];
    default:
      return vlnJobs || [];
  }
};
