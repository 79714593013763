import React, { useState, useEffect } from 'react'
import './banner.css'
import Carousel from 'react-bootstrap/Carousel'
import donateIcon from '../../../../assets/donateIconForBanner.png'
import replace from '../../../../assets/replacement.png'
import { useSelector } from 'react-redux'
import PopUpPayment from "../popUpPayment/PopUpPayment";
import PopUpPaymentCompagin from '../popUpPayment/popUpPaymentCompagin'
import { useTranslation } from "react-i18next";
import Loading from '../../../ui/loading'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper'
SwiperCore.use([Navigation, Autoplay, Pagination])

const Banner = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [popUpModal, setPopUpModal] = useState(false);
  const [imgswithoutError, setImgsWithoutErrors] = useState([])
  const [data, setData] = useState({
    compaginId: '',
    title: '',
    campaignDetSerial: ''
  });
  const compaginsSlider = useSelector(state => state.compaginsSlider.compaginSliders);
  const compaginsSliderLoading = useSelector(state => state.compaginsSlider.loading);
  const [loadingState, setLoadingState] = useState(compaginsSliderLoading);
  const [campaignData, setCampaignData] = useState(compaginsSlider);

  const handleClick = (data) => {
    setData({
      compaginId: data.compaginId,
      title: data.title,
      campaignDetSerial: data.campaignDetSerial
    })
    setPopUpModal(true)
  }
  /* function checkIfImageExists(url) {
     const img = new Image();
     img.src = url;
     if (img.complete | img.onload) {
       return true
     } else {
       img.onerror = () => {
         return false
       }   
       };
     }*/
   useEffect(()=> {
    setLoadingState(compaginsSliderLoading)
    setCampaignData(compaginsSlider)
   }, [compaginsSlider, compaginsSliderLoading])

   const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);

  return (
    
    <div style={{ width: '100%' }}>
      {loadingState?
    <Loading height='600px' mediaQ='45vh'/>:
    <React.Fragment>
      <Swiper id='banner' autoplay={{delay: 15000}}  loop={campaignData?.length > 1 ? true:false} className='please' slidesPerView={1} navigation={true} pagination={{ clickable: true }}>
        {campaignData?.map((item, i) =>
          <SwiperSlide key={i}>
            <div style={{width:'100%', position:'relative'}}>
            <img
              className='banner_for_home_page'
              id={item.imagePath}
              style={{ height: '60vh', minHeight: '60vh', width: '100%', objectFit: 'cover', filter: 'brightness(90%)' }}
              src={item.imagePath}
              alt={item.name}
            />
            {!item.name || !item.description?
            null:
            <div className='animate__animated animate__fadeInDown animate__delay-1s' style={{position:'absolute', top:width>=900?'30%':width>=700? '28%':width>=400? '35%':'32%', right: width>=1150? '110px':width>=900?'20px':'10px', width:width>=1150?'35%':width>=500?'50%':'60%', padding:'10px', backgroundColor:'rgba(0, 0, 0, 0.2)', color:'white', borderRadius:'15px'}}>
                    <label style={{fontSize:width>=1200? '35px':width>=700?'20px':'13px', textAlign: lng==='arab'? 'right':'left', width:'100%'}} className='banner_title'>{item.name}</label>
                    <p style={{fontSize:width>=900? '15px':width>=700? '10px':width>=400?'9px':'8px',textAlign: lng==='arab'? 'right':'left', width:'100%', direction:lng==='arab'?"rtl":"ltr", marginBottom:'0px'}} className='banner_description'>
                      {item.description.length>=120? item.description + '...':item.description}
                    </p>
                </div>
          }  
            </div>
            <div style={{position:'absolute', bottom:'60px', display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
              {/*<h3 style={{ marginBottom: '1.2rem' }} className='fonmt_for_banner'>{item.name}</h3>*/}
              <button onClick={() => handleClick({ compaginId: item.campaignActiveID, title: item.name, campaignDetSerial: item.campaignDetSerial })} className='btn btn-light btn-block btn-donate btn_for_text banner-taptodonate' style={{ fontWeight: 'normal' }}>{t('Donate now')} <img src={donateIcon} alt='donate_btn_icon_banner ' className='donate_btn_icon_banner doto_banner_hp' /></button>
            </div>
          </SwiperSlide>
        )}

      </Swiper>
      <PopUpPaymentCompagin
        show={popUpModal}
        data={data}
        onHide={() => setPopUpModal(false)}
      />
    </React.Fragment>
    }
      
    </div>
  )
}
export default Banner