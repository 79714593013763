import { GET_RELATIONSHIP_STATUS } from "../../constants/actionTypes";

export default (vlnRelations = [], action) => {
  switch (action.type) {
    case GET_RELATIONSHIP_STATUS:
      return action.payload || [];
    default:
      return vlnRelations || [];
  }
};
