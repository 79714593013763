import React, { useEffect } from 'react'
import HumanitarianCom from '../../homePage/sections/humanitarian/humanitarianCom/humanitarianCom'
import './HumanCasesPage.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from "react-i18next";
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';
const HumanCasesPage = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const humanCases = useSelector(state => state.humanCases.humanCases);
    const loading = useSelector(state => state.humanCases.loading);
    const history = useHistory()
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [humanCases])

    function loadQueryParams() {
        if (props.location.search) {
          let qParms = props.location.search.replaceAll("?", "");
          let qObj = JSON.parse(
            '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function (key, value) {
              return key === "" ? value : decodeURIComponent(value);
            }
          );
          return qObj;
        }
      }
    useEffect(() => {
        if(loadQueryParams()&& loadQueryParams().humanId)
        {
            history.push(`/humancases/${loadQueryParams().humanId}`)
        }         
    }, [])

return (
            <div id='human_cases_display_page'>
                <ShareHeader noShare={true} pageName={t("Humanitarian Cases")} />
                <div className='header_of_human_cases' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                    <h2 style={{ padding: '0px 0px 10px 0px', borderBottom: '1px solid gray', textAlign: 'center' }}>{t("Humanitarian Cases")}</h2>
                </div>
                {loading?
                <Loading height={'85vh'} />:
                <div className='body_of_human_cases'>
                    {humanCases?.map((item, i) =>
                        <HumanitarianCom
                        key={i}
                            HumanPage={true}
                            ID={item.caseID}
                            caseYear={item.caseYear}
                            humanImg={item.imagePath}
                            title={item.caseTitle}
                            text={item.description}
                            raised={item.amountCollected}
                            goal={item.totalCost}
                        />
                    )}
                </div>}
            </div>
        )
}

export default HumanCasesPage