import {
    FETCH_ALL_DONATIONS, LOAD_DONATIONS,
    DONATIONS_LOADED
} from '../constants/actionTypes'


export default (
    // donations = [],
    state = {
        donations: [],
        loading: false,
    },
    action) => {
    switch (action.type) {
        case FETCH_ALL_DONATIONS:
            // return action.payload;
            return { ...state, donations: action?.payload, loading: false };
        case LOAD_DONATIONS:
            return { ...state, loading: true };
        case DONATIONS_LOADED:
            return { ...state, loading: false };
        default:
            return state;
    }
}