import React, { useState, useEffect } from 'react';
import './LatePaymentRenewals.css';
import LatePayment from './latePayment_page_Display/LatePayment';
import Renewals from './renewals_page_Display/Renewals';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSPOrphAllLatepayments,
  getSPAllLatepayments
} from '../../actions/user';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { useHistory } from 'react-router-dom';
import { addToBag } from '../../actions/BagFav';
import LoadingModal from '../Auth/loginSignPage/loadingPopUp/loadingPopUp';
import { useLocation } from 'react-router-dom';
const LatePaymentRenewals = (props) => {
  const [monthCount, setMonthCount] = useState(6);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  function loadQueryParams() {
    if (props.location.search) {
      let qParms = props.location.search.replaceAll('?', '');
      let qObj = JSON.parse(
        '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value);
        }
      );
      return qObj;
    }
  }

  const dispatch = useDispatch();

  const latePaymentsState = useSelector((state) =>
    loadQueryParams().many
      ? state.latePayments.latePayments
      : state.latePayments.orplatePayments
  );

  const loading = useSelector((state) => state.latePayments.latePaymentLoading);
  const history = useHistory();

  const [latePayments, setLatePayments] = useState([]);
  const [renewals, setRenewals] = useState(props.location?.state);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });

  const [disableLate, setDisableLate] = useState(true);
  const [disableRenew, setDisableRenew] = useState(true);

  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }

  useEffect(() => {
    if (!loading) {
      if (loadQueryParams().many) {
        if (props?.location?.state?.str === 'monthly') {
          setDisableLate(false);
          setDisableRenew(true);

          let selectedItems = props?.location?.state?.itemSelectedMode.slice(0);
          let newItems = [];
          for (let i = 0; i < selectedItems.length; i++) {
            if (
              latePaymentsState.filter(
                (item) => item.orphanID === selectedItems[i]
              ).length > 0
            ) {
              newItems.push(
                ...latePaymentsState.filter(
                  (item) => item.orphanID === selectedItems[i]
                )
              );
            }
          }
          setTotalAmount(
            newItems
              .map((item) => parseInt(item.trn_amount))
              .reduce((x, y) => x + y, 0)
          );
          setLatePayments(newItems);
        } else if (props?.location?.state?.str === 'full') {

          setDisableLate(true);
          setDisableRenew(false);
          let selectedItems = props?.location?.state?.itemSelectedMode.slice(0);
          let newItems = [];
          for (let i = 0; i < selectedItems.length; i++) {
            if (
              props?.location?.state?.orphonsList?.filter(
                (item) => item.orp_id === selectedItems[i]
              ).length > 0
            ) {
              newItems.push(
                ...props?.location?.state?.orphonsList?.filter(
                  (item) => item.orp_id === selectedItems[i]
                )
              );
            }
          }
          setTotalAmount(
            newItems
              .filter((inner) => inner.pay_mthd !== '3')
              .map((item) => parseInt(item.trn_amount))
              .reduce((x, y) => x + y, 0)
          );
          setRenewals(newItems.filter((inner) => inner.pay_mthd !== '3'));
          handleNavigation('else');
        }
      } else {
        if (latePaymentsState.length > 0) {
          
          setDisableLate(false);
          setDisableRenew(true);
          setLatePayments(latePaymentsState.filter(orphan => orphan.orphanID === loadQueryParams().orphon_id));
          setTotalAmount(
            latePayments
              .map((item) => parseInt(item.trn_amount))
              .reduce((x, y) => x + y, 0)
          );
          if (type !== 3) {
            handleNavigation('renewals');
          } else {
            handleNavigation('late');
          }
        } else {
          setDisableLate(true);
          setDisableRenew(false);
          setTotalAmount(0);
          handleNavigation('else');
        }
        if (
          props.location?.state &&
          props?.location?.state?.orphon?.pay_mthd !== '3'
        ) {

          setDisableRenew(false);
          setDisableLate(true);
          let newRenewals = [];
          newRenewals.push(props?.location?.state);
          setTotalAmount(0);
          setRenewals(newRenewals);
        } else {
          if (latePaymentsState.length === 0) {
            setTotalAmount(0);
            // history.goBack()
          } else {
            setDisableRenew(true);
            setDisableLate(false);
            // setTotalAmount(0)
            handleNavigation('late');
          }
        }
      }
    }
  }, [latePaymentsState, props.location?.state]);
  useEffect(() => {
    if (
      loadQueryParams() &&
      loadQueryParams().orphon_id &&
      !loadQueryParams().many
    )
      dispatch(
        getSPOrphAllLatepayments(
          loadQueryParams().sponsor_id,
          lng === 'arab' ? '1' : '2',
          loadQueryParams().orphon_id
        )
      );
  }, [props.location?.state]);
  let mounted = true;
  const [display, setDisplay] = useState(0);
  const handleNavigation = (str) => {
    if (str === 'late') {
      setDisplay(0);
      document.getElementById('navi_late_payment').style.color = '#ee4236';
      document.getElementById('navi_late_payment').style.borderBottom =
        '5px solid #ee4236';

      document.getElementById('navi_renewals').style.color = 'rgb(87, 86, 86)';
      document.getElementById('navi_renewals').style.borderBottom = 'none';
    } else {
      setDisplay(1);
      document.getElementById('navi_renewals').style.color = '#ee4236';
      document.getElementById('navi_renewals').style.borderBottom =
        '5px solid #ee4236';

      document.getElementById('navi_late_payment').style.color =
        'rgb(87, 86, 86)';
      document.getElementById('navi_late_payment').style.borderBottom = 'none';
    }
  };
  const [scrolled, setScrolled] = useState(0);
  const [heightAll, setHeightAll] = useState(0);

  const userBag = useSelector((state) => state.bag.bag);
  const [inTheCart, setInTheCart] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (mounted) {
        setScrolled(window.scrollY);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  const addAllToBag = async () => {
    setLoadingModalShow(true);
    let newBag = [];
    //-----
    if (display === 0) {
      latePayments.forEach((item) => {
        newBag.push({
          donID: '1',
          userName: userLogin?.Results[0]?.name_a,
          userMob: userLogin?.Results[0]?.mobile_no,
          userEmail: userLogin?.Results[0]?.email,
          itemType: '6',
          amount: item.trn_amount,
          recuring: null,
          cart_serial: null,
          orphan_id: item.orphanID,
          sponsor_id: loadQueryParams().sponsor_id,
          transaction_number: item.trn_serial,
          transaction_year: item.trn_year
        });
      });
    } else if (display === 1) {
      renewals?.orphon?.forEach((item) => {
        newBag.push({
          donID: '1',
          userName: userLogin?.Results[0]?.name_a,
          userMob: userLogin?.Results[0]?.mobile_no,
          userEmail: userLogin?.Results[0]?.email,
          itemType: '5',
          amount: item.trn_amount,
          recuring: null,
          cart_serial: null,
          orphan_id: item.orp_id,
          sponsor_id: loadQueryParams().sponsor_id,
          month_count: monthCount
        });
      });
    }
    //----
    await dispatch(
      addToBag(
        {
          cart: newBag,
          language: lng === 'arab' ? 1 : 2
        },
        undefined,
        undefined,
        history
      )
    );
    setLoadingModalShow(false);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const handleClick = (str) => {
    if (str === '-') {
      setMonthCount(monthCount - 1);
    } else {
      setMonthCount(monthCount + 1);
    }
  };
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  useEffect(() => {}, [scrolled, heightAll]);
  if (userInfo) {
    return (
      <div
        className="latePayment_Renewals_page"
        style={
          lng === 'arab'
            ? { direction: 'rtl', width: '100%', position: 'relative' }
            : { width: '100%', position: 'relative' }
        }
      >
        <ShareHeader pageName={t('Payments')} noShare={true} />

        <div className="sponsership_page_navigation">
          <button
            disabled={disableLate}
            onClick={() => handleNavigation('late')}
            id="navi_late_payment"
            className="sponsership_navigation_item"
            style={{
              borderRadius: '0px',
              color: '#ee4236',
              borderBottom: '5px solid #ee4236'
            }}
          >
            {t('Late Payment')}
          </button>
          <button
            disabled={disableRenew}
            onClick={() => handleNavigation('renewals')}
            id="navi_renewals"
            className="sponsership_navigation_item"
            style={{ borderRadius: '0px' }}
          >
            {t('Renewals')}
          </button>
        </div>
        {display === 0 ? (
          <LatePayment
            setHeightAll={setHeightAll}
            latePayments={latePayments}
            location={props.location}
          />
        ) : (
          <Renewals
            setHeightAll={setHeightAll}
            renewal={renewals}
            disablerenew={setDisableRenew}
            disablelate={disableLate}
            handlenavi={handleNavigation}
            props={props.location?.state}
            location={props.location}
          />
        )}
        {totalAmount > 0 ? (
          <div
            className="total_amount_for_bag"
            style={{
              position:
                width > 900
                  ? scrolled < heightAll - 700
                    ? 'fixed'
                    : 'static'
                  : 'fixed',
              bottom: width <= 900 ? '55px' : null,
              width: '100%',
              margin: '0px',
              transition: 'position 1s ease-in',
              boxShadow: 'rgb(128 128 128 / 34%) 1px -1px 5px'
            }}
            id="donate_now_btn"
          >
            <div
              className="text_of_total_amount forceStyle"
              style={{ width: '30%' }}
            >
              <label style={{ fontSize: '18px' }}>{t('Total Payments')}</label>
              <label style={{ fontSize: '14px' }}>
                {t('AED')}{' '}
                <span
                  className="payment_numbers"
                  style={{
                    fontWeight: 'bold',
                    fontSize: width > 500 ? '24px' : '17px'
                  }}
                >
                  {totalAmount}
                </span>
              </label>
            </div>
            {display === 1 ? (
              <div
                className="sukuk_inc_Dec forceStyle"
                style={{ width: '50%' }}
              >
                <button
                  className="sukukdec btn btn-light"
                  onClick={() => {
                    if (monthCount > 1) {
                      handleClick('-');
                    }
                  }}
                >
                  -
                </button>
                <label
                  className="labell"
                  style={{
                    fontSize: '15px',
                    direction: lng === 'arab' ? 'rtl' : 'ltr'
                  }}
                >
                  {t('Months')}:{' '}
                  <span
                    className="payment_numbers"
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      fontStyle: 'none'
                    }}
                  >
                    {monthCount}
                  </span>
                </label>
                <button
                  className="sukukinc btn btn-light"
                  onClick={() => handleClick('+')}
                >
                  +
                </button>
              </div>
            ) : null}

            <button
              onClick={() => addAllToBag()}
              style={{ width: '50%' }}
              className="Donate_now_add_to_cart forceStyle smallBtn_smallDevice"
            >
              {t('Add All To Bag')}
            </button>
          </div>
        ) : null}
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace('/');
  }
};

export default LatePaymentRenewals;
