import "animate.css/animate.min.css";
import React, { Component, useEffect } from 'react';
import { toast, ToastContainer, cssTransition } from "react-toastify";
import i18n from "i18next";
import "./toastifyMessage.module.css";
/**
 * Function returns toast component with translated message depends on selected language.
 * @param msg the message to be tosted out.
 * @param ext optional value to append to the message.
 * @param errType the type of toast message, e.g. [success, error, warning]. Default type is "info".
 * @param div optional prop for messages to display in custom block. Messages sent in this prop are not translated.
 */
export const toastifyMessage = (message: string, extra: any, errorType: string, blockMessage: any) => {
    const language = JSON.parse(localStorage.getItem("language"))?.lang;
    const bounce = cssTransition({
        enter: "animate__animated animate__bounceIn",
        exit: "animate__animated animate__bounceOut"
    });
    toast.configure({
        position: language === 'arab' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        rtl: language === "arab" ? true : false,
        limit: 3,
        transition: bounce,
        autoClose: 3500,
    });

    let component = (props) => (
        { props }
    );
    try {

        if (blockMessage) {
            component = (blockMessage);
        }
        else {
            component = (
                <span style={{fontFamily: language==='arab'? 'arab_font':'english_font'}}>
                    <span  className="payment_numbers" >{i18n.t(message)}</span><br />
                    {
                        typeof (extra) == "object" ? (
                            Object.values(extra)?.map(element => (<li style={{ fontSize: '0.875em' }} key={`toast-div-msg-${Math.floor(Math.random() * 99999) + 1000}`}>{i18n.t(element)}</li>))) : (
                            (<p style={{ fontSize: '0.875em', fontFamily: extra? extra.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)? 'english_font':null:null }}>{i18n.t(extra != undefined ? extra : "")}</p>)
                        )
                    }
                </span >
            );
        }
    } catch (error) {
        toastifyMessage("Something went wrong...!", undefined, "error")
    }
    switch (errorType) {
        case "warning":
            toast.warning(component);
            break;
        case "error":
            toast.error(component);
            break;
        case "success":
            toast.success(component);
            break;
        default:
            toast.info(component);
            break;
    }
    toast.clearWaitingQueue();
};
