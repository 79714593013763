/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./ProjectShare.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cash from "../../../assets/donateByCash.png";
import { useTranslation } from "react-i18next";
import samPay from "../../../assets/Pay_button_basic_pos_RGB.png";
import ShareAlertModel from "../../LoaclComponents/shareHeader/share_model/ShareModelAlert";
import Loading from "../../ui/loading";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DonationFinalPopUp from "../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import NotFound from "../../NotFound/NotFound";
import { startApplePay } from "../../../actions/handleApplePayment";
import { getProjectShareSingle } from "../../../api";
const ProjectShare = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory()
  const _isMounted = useRef(true);
  const [projectShareSingle, setProjectShareSingle] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getProjectShareSingle(id, year, lng === "arab"? 1: 2)
      if(result?.data?.status && result?.data?.data?.projectShareID)
      {
        setProjectShareSingle(result?.data?.data)
      }
    }
    fetchData();
    return () => {
      

        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [showCopyCard, setshowCopyCard] = useState(false);

  const { id, year } = useParams();
  const loading = useSelector((state) => state.projectShares.loading);
  const projectShares = useSelector(state => state.projectShares.projectShares);
  const [projectSharesDisplay, setProjectSharesDisplay] = useState(projectShares);
  useEffect(() => {
      setProjectSharesDisplay(projectShares)
  }, [projectShares])

  const [itemOfProjectShare, setItemOfProjectShare] = useState({});
  const [projectShareData, setProjectShareData] = useState({
    projectShareID: "",
    title: "",
    total: "",
    finalAmount: "",
    projectID: "",
    donationAmountFromUser: 150,
    projectShareYear: "",
    projectYear: "",
  });
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    const projectShareWeNeed = projectSharesDisplay?.filter((item) => item.projectShareID === id && item.projectShareYear === year);
    if(!projectShareWeNeed.length && location?.state?.projectShare)
    {
      projectShareWeNeed.push(location?.state?.projectShare)
    }
    else {
      projectShareWeNeed.push(projectShareSingle)
    }
    
    setItemOfProjectShare(projectShareWeNeed?.slice(0, 1).shift());
    setProjectShareData({
      projectID: projectShareWeNeed?.slice(0, 1).shift()?.projectID,
      projectShareID: projectShareWeNeed?.slice(0, 1).shift()?.projectShareID,
      projectShareYear: projectShareWeNeed?.slice(0, 1).shift()?.projectShareYear,
      donationAmountFromUser: projectShareWeNeed?.slice(0, 1).shift()?.donationAmountFromUser,
      title: projectShareWeNeed?.slice(0, 1).shift()?.projectName,
      total: projectShareWeNeed?.slice(0, 1).shift()?.amountCollected,
      finalAmount: projectShareWeNeed?.slice(0, 1).shift()?.amount,
      donationAmountFromUser:
        projectShareWeNeed?.slice(0, 1).shift()?.amountCollected /
          projectShareWeNeed?.slice(0, 1).shift()?.amount >=
        1
          ? t("Completed")
          : projectShareWeNeed?.slice(0, 1).shift()?.amount -
          projectShareWeNeed?.slice(0, 1).shift()?.amountCollected >=
        150? 150: 5,
          projectYear: projectShareWeNeed?.slice(0, 1).shift()?.projectYear,
    });

  }, [id, projectSharesDisplay, projectShareSingle]);
  const [width, setWidth] = useState(window.innerWidth);
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: projectShareData.donationAmountFromUser,
      url: window.location.href,
      item: [
        {
          dontype_id: 8,
          share_id: projectShareData.projectShareID,
          amount: projectShareData.donationAmountFromUser,
          share_year: projectShareData.projectShareYear,
          item_type: 8,
          country_id: projectShareData.countryID ? projectShareData.countryID : null,
          template_id: projectShareData.projectID,
          prj_name: projectShareData.title,
          projectYear: projectShareData.projectYear
        },
      ],
    });
    setLoadingModalShow(false);
  };
  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };
  if (loading) {
    return <Loading height={"85vh"} />;
  } else if (itemOfProjectShare) {
    return (
      <div id="human_case_page">
        <ShareHeader
          textToBeCopied={window.location.href}
          pageName={itemOfProjectShare?.projectName}
          path={"humancases"}
          noShare={true}
        />
        <h1
          className="headerForDesktop notShown"
          style={
            lng === "arab"
              ? { padding: "40px 40px 10px 0px", textAlign: "right" }
              : { padding: "40px 0px 10px 40px" }
          }
        >
          <span
            style={{ paddingBottom: "5px", borderBottom: "3px solid #ee4236" }}
          >
            {t("Support")}
          </span>{" "}
          {itemOfProjectShare?.projectName}
        </h1>
        <div className="human_case_display">
          <div
            className="project_share_img"
            style={lng === "arab" ? { padding: "40px 0px 40px 40px" } : null}
          >
            <img
              src={itemOfProjectShare?.projTypeImagePath}
              alt={itemOfProjectShare?.projectName}
              className="proj_img"
            />

          </div>
          <div
            className="human_case_details"
            style={lng === "arab" ? { direction: "rtl" } : null}
          >
            <p
              className="humancases_p"
              style={lng === "arab" ? { textAlign: "right" } : null}
            >
              {itemOfProjectShare?.longDesc}
            </p>
            <div className="remainder_project_share" style={{direction: lng === "arab"? "rtl": "ltr"}}>
          <div style={{display: "inline-flex", direction: lng === "arab"? "ltr": "ltr"}}>
          <div className="remainder_project_share_inner payment_numbers">
            <span className="remainder_aed_text">AED</span>
            <span>{itemOfProjectShare?.amountCollected}</span>
          </div>
          <span>{t("Raised")}</span>
          </div>
          <div>{"-".repeat(window.innerWidth > 500? 8: window.innerWidth > 400? 5:window.innerWidth > 350?4: 3)}</div>
          <div style={{display: "inline-flex", direction: lng === "arab"? "rtl": "ltr"}}>
          <span>{t("of")} </span>
          <div className="remainder_project_share_inner payment_numbers">
            <span className="remainder_aed_text">AED</span>
            <span>{itemOfProjectShare?.amount}</span>
          </div>
          </div>
          {/* <span className="remainder_aed_text">AED</span><label className="payment_numbers" style={{marginRight: '5px'}}>{props.projectShare.amountCollected} {t("Raised")} <span>{"-".repeat(window.innerWidth > 500? 15: 10)}</span> {t("of")}  </label><span className="remainder_aed_text">AED</span> <label className="payment_numbers">{props.projectShare.amount}</label> */}
        </div>
            {/* <div className="remainder_project_share">
          <span className="remainder_aed_text">AED</span>
          <label className="payment_numbers" style={{marginRight: '5px'}}>{itemOfProjectShare?.amountCollected} {t("Raised")} <span>{"-".repeat(window.innerWidth > 500? 15: 10)}</span> {t("of")}  </label><span className="remainder_aed_text">AED</span> <label className="payment_numbers">{itemOfProjectShare?.amount}</label>

        </div> */}
        <div className="project_share_bar_page">
          <div style={{flex: '1'}} />
          <div style={{flex: '1', position: 'relative', display: 'flex'}}>
            <div style={{width: (Math.floor((itemOfProjectShare?.amountCollected * 100) / itemOfProjectShare?.amount)) + "%"}} className="bar_display_red" />
            <div style={{width: (100 - Math.floor((itemOfProjectShare?.amountCollected * 100) / itemOfProjectShare?.amount)) + "%"}} className="bar_display_grey" />
            <label className="payment_numbers" style={{position: 'absolute', left: '45%', top: '25%', color: 'white'}}>{(Math.floor((itemOfProjectShare?.amountCollected * 100) / itemOfProjectShare?.amount)) + "%"}</label>
          </div>
          
        </div>
        {itemOfProjectShare?.projectShareStatus?<div>
            <label className="payment_numbers" style={{direction: lng === "arab"? "rtl": "ltr"}}>{itemOfProjectShare?.daysLeft} {t("day(s) remaining")}</label>
          </div>:null}
            {itemOfProjectShare?.projectShareStatus?<div className="human_donation_amount">
              <h4>{t("Donation Amount")}</h4>
              <input
                className={
                  lng === "arab"
                    ? projectShareData.donationAmountFromUser === t("Completed")? "force_arabic_font donation_amount_input translateFillMsg" 
                    : projectShareData.donationAmountFromUser ||
                      projectShareData.donationAmountFromUser === 0
                      ? "payment_numbers donation_amount_input translateFillMsg"
                      : "force_arabic_font donation_amount_input translateFillMsg"
                    : "payment_numbers donation_amount_input translateFillMsg"
                }
                type="text"
                pattern="[0-9]*"
                value={projectShareData.donationAmountFromUser}
                onChange={(e) => {
                  setProjectShareData({ ...projectShareData, donationAmountFromUser: e.target.value })

                }}
                placeholder={t("Enter amount in AED")}
              />
            </div>:null}
            {itemOfProjectShare?.projectShareStatus?<div className="human_donation_btns">
              <div className="human_donation_btns_above">
                <div className="btn_with_text">
                  <button
                    onClick={() =>
                      {
                       
                        if (!(projectShareData.donationAmountFromUser >= 5)) {
                        toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        );
                      } else
                        history.push(`/checkout`, {
                          from: "projectShare",
                          elements: [
                            {
                              title: projectShareData.title,
                              price: projectShareData.donationAmountFromUser,
                            },
                          ],
                          totalAmount: projectShareData.donationAmountFromUser,
                          details: projectShareData,
                        });}
                    }
                    className="human_donate_ways btn3"
                  >
                    <img
                      src={cash}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Donate Now")}</label>
                </div>
              </div>
              {window.ApplePaySession ? 
                <div
                  id="applepay_btn"
                  className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn"
                  locale="ar-AB"
                  style={{borderRadius: '30px'}}
                  onClick={() => handleGuestPopUp(()=> startApplePay({
                    
                    amount: projectShareData.donationAmountFromUser,
                    cart: [
                      {
                          dontype_id: 8,
                          share_id: projectShareData.projectShareID,
                          amount: projectShareData.donationAmountFromUser,
                          share_year: projectShareData.projectShareYear,
                          item_type: 8,
                          country_id: projectShareData.countryID ? projectShareData.countryID : null,
                          template_id: projectShareData.projectID,
                          prj_name: projectShareData.title,
                          cart_serial: null,
                          projectYear: projectShareData.projectYear
                      },
                    ],
                  }, dispatch, history))
                    
                  }
                ></div>
               :  
                <button
               style={
                  {
                        width: "80%",
                        backgroundColor: "black",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                }
                  onClick={() => handleGuestPopUp(startSamsungPay)}
                  className="btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn"
                >
                  <img
                    src={samPay}
                    style={{
                      height: "60px",
                      width: width <= 300 ? "100%" : "auto",
                    }}
                    alt="samsung_pay"
                    className='samApple_btn_img'
                  />
                </button>
              }
            </div>:<span style={{color: 'red'}}>{itemOfProjectShare?.projectStatusReason}</span>}
          </div>
        </div>

        <ShareAlertModel
          path={"humancases"}
          shareData={projectShareData}
          show={showCopyCard}
          onHide={() => {
            setshowCopyCard(false);
          }}
        ></ShareAlertModel>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
        <DonationFinalPopUp
          show={geustModal}
          paymentfunction={functionToSend}
          onHide={() => setGuestModal(false)}
          dialogClassName="modal-60w"
        />
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default ProjectShare;
