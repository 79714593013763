export const FETCH_ALL_SUKUK = "FETCH_ALL_SUKUK";
export const FETCH_ALL_LANDING = "FETCH_ALL_LANDING";
export const SHOW_TUTORIAL = "SHOW_TUTORIAL";
export const HIDE_TUTORIAL = "HIDE_TUTORIAL";
export const FETCH_ALL_TUTORIALS = "FETCH_ALL_TUTORIALS";
export const GET_ALL_JOBS_NEW = "GET_ALL_JOBS_NEW";
export const FETCH_ALL_PROJECTS_FULL_SHARE_CREATE = "FETCH_ALL_PROJECTS_FULL_SHARE_CREATE";
export const FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING = "FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING";

//---------------------------------------------------

export const FETCH_ALL_PROJECTS = "FETCH_ALL_PROJECTS";
export const FETCH_COUNTRIES_FOR_PROJECT_TYPE = "FETCH_COUNTRIES_FOR_PROJECT_TYPE"
export const FETCH_ALL_PROJECTS_UPDATED = "FETCH_ALL_PROJECTS_UPDATED"
export const FETCH_ALL_COUNTRIES_PROJETCS_SEC = "FETCH_ALL_COUNTRIES_PROJETCS_SEC"
export const FETCH_ALL_PROJECTS_FOR_COUNTRY = "FETCH_ALL_PROJECTS_FOR_COUNTRY"
export const FETCH_ALL_PROJECTS_FULL = "FETCH_ALL_PROJECTS_FULL"
//---------------------------------------------------

export const FETCH_ALL_ORPHAN_SLIDERS = "FETCH_ALL_ORPHAN_SLIDERS";
export const LOAD_ALL_NEWS = 'LOAD_ALL_NEWS'
export const ENOUGH_LOADING_NEWS = 'ENOUGH_LOADING_NEWS'
//---------------------------------------------------

export const FETCH_ALL_COMPAGINS_SLIDERS = "FETCH_ALL_COMPAGINS_SLIDERS";

//---------------------------------------------------

export const FETCH_ALL_DONATIONS = "FETCH_ALL_DONATIONS";

//---------------------------------------------------

export const FETCH_ALL_HUMANCASES = "FETCH_ALL_HUMANCASES";
export const FETCH_ALL_PROJECT_SHARES = "FETCH_ALL_PROJECT_SHARES";

//---------------------------------------------------

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const LOGOUT_WITH_GOOGLE = "LOGOUT_WITH_GOOGLE";

//---------------------------------------------------

export const CREATE_USER = "CREATE_USER";

//---------------------------------------------------

export const FETCH_COUNTRIES_FOR_PROJECTS = "FETCH_COUNTRIES_FOR_PROJECTS";
export const FETCH_ALL_COUNTRIES_FOR_ORPHAN = "FETCH_ALL_COUNTRIES_FOR_ORPHAN";

//---------------------------------------------------

export const REGULAR_PAYMENT = "REGULAR_PAYMENT";
export const CHANGE_TRANSLATION_ENG = "CHANGE_TRANSLATION_ENG";
export const CHANGE_TRANSLATION_ARAB = "CHANGE_TRANSLATION_ARAB";

//---------------------------------------------------

export const FETCH_ALL_COUNTRIES_FOR_VOLUNTEERS =
  "FETCH_ALL_COUNTRIES_FOR_VOLUNTEERS";

//---------------------------------------------------

export const FETCH_FAMILY_MEMBERS = "FETCH_FAMILY_MEMBERS";
export const FETCH_LOCAL_AID_PROFILE = "FETCH_LOCAL_AID_PROFILE";
export const FETCH_ALL_COMPLETED_REQU = "FETCH_ALL_COMPLETED_REQU";
export const FETCH_ALL_RETURNED_REQU = "FETCH_ALL_RETURNED_REQU";
export const FETCH_ALL_PENDING_REQU = "FETCH_ALL_PENDING_REQU";
export const FETCH_ALL_LOCAL_AID_TYPES = "FETCH_ALL_LOCAL_AID_TYPES";

//---------------------------------------------------

export const FETCH_VOLUNTEER_FILE_DETAILS = "FETCH_VOLUNTEER_FILE_DETAILS";
export const FETCH_VOLUNTEER_EVENT_DETAILS = "FETCH_VOLUNTEER_EVENTDETAILS";

export const FETCH_PARTICIPANTS_DETALS = "FETCH_PARTICIPANTS_DETALS";
export const FETCH_ALL_ATTACHMENTS = "FETCH_ALL_ATTACHMENTS";
export const FETCH_ALL_COURSES = "FETCH_ALL_COURSES";
export const FETCH_ALL_COURSES_FOR_VOLUNTEER =
  "FETCH_ALL_COURSES_FOR_VOLUNTEER";
export const FETCH_ALL_COUNTRIES = "FETCH_ALL_COUNTRIES";
export const FETCH_ALL_LEVELS = "FETCH_ALL_LEVELS";
export const FETCH_ALL_LANGUAGES = "FETCH_ALL_LANGUAGES";
export const FETCH_VOLUNTEER_LANGUAGES = "FETCH_VOLUNTEER_LANGUAGES";
export const FETCH_ALL_VOLUNTEER_SKILLS = "FETCH_ALL_VOLUNTEER_SKILLS";
export const FETCH_ALL_SKILLS = "FETCH_ALL_SKILLS";
export const FETCH_ALL_INTERESTS = "FETCH_ALL_INTERESTS";
export const FETCH_ALL_VOLUNTEER_INTERESTS = "FETCH_ALL_VOLUNTEER_INTERESTS";
export const FETCH_VOLUNTEER_EVENTS = "FETCH_VOLUNTEER_EVENTS";
export const FETCH_EVENT_DETAILS = "FETCH_EVENT_DETAILS";
export const FETCH_ALL_VOLUNTEER_SERVICES = "FETCH_ALL_VOLUNTEER_SERVICESs";
export const FETCH_ALL_BRANCHES = "FETCH_ALL_BRANCHES";
export const FETCH_ALL_EDUCATIONS = "FETCH_ALL_EDUCATIONS";
export const FETCH_VLN_REGISTER_PROGRESS = "FETCH_VLN_REGISTER_PROGRESS";
export const FETCH_ALL_MAP_LOCATIONS = "FETCH_ALL_MAP_LOCATIONS";
export const FETCH_ALL_NEWS = "FETCH_ALL_NEWS";
export const FETCH_ALL_GALLERY = "FETCH_ALL_GALLERY";
export const FETCH_ALL_GRACE = "FETCH_ALL_GRACE";
export const FETCH_ALL_DONATIONSSUMMARY = "FETCH_ALL_DONATIONSSUMMARY";
export const FETCH_ALL_TRANSACTIONSSUMMARY = "FETCH_ALL_TRANSACTIONSSUMMARY";
export const FETCH_SPONSORED_FOLLOW_UPS = "FETCH_SPONSORED_FOLLOW_UPS";

export const FETCH_ALL_NOTIFICATIONS = "FETCH_ALL_NOTIFICATIONS";
export const FETCH_ALL_ORPHONS = "FETCH_ALL_ORPHONS";

export const DELETE_CARD = "DELETE_CARD";
// ----- Late Payment
export const ALL_ORPHONS_LATE_PAYMENTS = "ALL_ORPHONS_LATE_PAYMENTS";
export const ALL_ORPHON_LATE_PAYMENTS = "ALL_ORPHON_LATE_PAYMENTS";
export const ALL_ORPHON_LATE_PAYMENTS_ERROR = "ALL_ORPHON_LATE_PAYMENTS_ERROR";
export const ALL_ORPHON_LATE_PAYMENTS_LOADING = "ALL_ORPHON_LATE_PAYMENTS_LOADING";

// ----- End Late Payment

// -Start account switching
export const SET_SPONSER_ACCOUNT = "SET_SPONSER_ACCOUNT";
export const CLEAR_SPONSER_ACCOUNT = "CLEAR_SPONSER_ACCOUNT";
// -End account switching

// start about us switching
export const GET_ABOUT_US = "GET_ABOUT_US";
// end about us switching
export const FETCH_ALL_BAG_ITEMS = "FETCH_ALL_BAG_ITEMS";
export const FETCH_ALL_FAVORITES = "FETCH_ALL_FAVORITES";
export const DELETE_FROM_BAG = "DELETE_FROM_BAG";
export const ADD_ITEM_TO_BAG = "ADD_ITEM_TO_BAG";
export const DELETE_FROM_FAVORITES = "DELETE_FROM_FAVORITES";

export const REMOVE_ALL_BAG_ITEMS = 'REMOVE_ALL_BAG_ITEMS';
export const FETCH_LOCALLY = 'FETCH_LOCALLY';

//------------------------------ Start Volunteer  Register ----------------
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_ALL_HEALTHS = "GET_ALL_HEALTHS";
export const GET_ALL_MARRIAGE = "GET_ALL_MARRIAGE";
export const SET_LAID_ACCOUNT = "SET_LAID_ACCOUNT"; //used for LOCAL_AID
export const NEW_LAID_ACCOUNT = "NEW_LAID_ACCOUNT"; //used for LOCAL_AID
export const GET_RELATIONSHIP_STATUS = "GET_RELATIONSHIP_STATUS";

//------------------------------ End Volunteer  Register ----------------

export const LOADING = "LOADING";
export const FETCH_FINAL_RECIEPT = "FETCH_FINAL_RECIEPT"

// ------------Recent donatiopns loading-----
export const DONATIONS_LOADING = "DONATIONS_LOADING";
export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export const DONATIONS_LOADING_FALSE = "DONATIONS_LOADING_FALSE";
export const TRANSACTIONS_LOADING_FALSE = "TRANSACTIONS_LOADING_FALSE";
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const NOTIFICATIONS_LOADED = "NOTIFICATIONS_LOADED";

// -----DOnations loading
export const LOAD_DONATIONS = "LOAD_DONATIONS";
export const DONATIONS_LOADED = "DONATIONS_LOADED";
// bag loading
export const LOAD_BAG = "LOAD_BAG";
export const BAG_LOADED = "BAG_LOADED";

//Sponsor loading
export const LOAD_SPONSOR = "LOAD_SPONSOR";
export const SPONSOR_LOADED = "SPONSOR_LOADED";

//human cases loading
export const LOAD_HUMANCASES = "LOAD_HUMANCASES";
export const HUMANCASES_LOADED = "HUMANCASES_LOADED";

export const LOAD_PROJECT_SHARES = "LOAD_PROJECT_SHARES";
export const PROJECT_SHARES_LOADED = "PROJECT_SHARES_LOADED";

//sukuk loading
export const LOAD_SUKUK = "LOAD_SUKUK";
export const SUKUK_LOADED = "SUKUK_LOADED";

export const LOAD_LANDING = "LOAD_LANDING";
export const LANDING_LOADED = "LANDING_LOADED";

//Volunteer loading
export const LOAD_VOLUNTEER = "LOAD_VOLUNTEER";
export const VOLUNTEER_LOADED = "VOLUNTEER_LOADED";

//Volunteer loading
export const LOAD_VOLUNTEER_PARTICIPANT = "LOAD_VOLUNTEER_PARTICIPANT";
export const VOLUNTEER_PARTICIPANT_LOADED = "VOLUNTEER_PARTICIPANT_LOADED";

//Volunteer loading
export const LOAD_VOLUNTEER_ATTACHMENT = "LOAD_VOLUNTEER_ATTACHMENT";
export const VOLUNTEER_ATTACHMENT_LOADED = "VOLUNTEER_ATTACHMENT_LOADED";


//Volunteer courses loading
export const LOAD_VOLUNTEER_COURSE = "LOAD_VOLUNTEER_COURSE";
export const VOLUNTEER_COURSE_LOADED = "VOLUNTEER_COURSE_LOADED";

export const LOAD_ALL_LANGUAGES = "LOAD_ALL_LANGUAGES";
export const ALL_LANGUAGES_LOADED = "ALL_LANGUAGES_LOADED";

export const LOAD_SKILLS = "LOAD_SKILLS";
export const SKILLS_LOADED = "SKILLS_LOADED";

export const LOAD_INTERESTS = "LOAD_INTERESTS";
export const INTERESTS_LOADED = "INTERESTS_LOADED";

export const LOAD_EVENTS = "LOAD_EVENTS";
export const EVENTS_LOADED = "EVENTS_LOADED";

export const LOAD_SERVICES = "LOAD_SERVICES";
export const SERVICES_LOADED = "SERVICES_LOADED";

// ==========Local aid=============

export const LOAD_RELATIVES = "LOAD_RELATIVES";
export const RELATIVES_LOADED = "RELATIVES_LOADED";

export const LOAD_LOCAL_AID_PROFILE = "LOAD_LOCAL_AID_PROFILE";
export const LOCAL_AID_PROFILE_LOADED = "LOCAL_AID_PROFILE_LOADED";

//be a sponsor
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const COUNTRIES_LOADED = "COUNTRIES_LOADED";

export const LOAD_ORPHANS = "LOAD_ORPHANS";
export const ORPHANS_LOADED = "ORPHANS_LOADED";

export const LOAD_SPONSOR_FOLLOWUPS = "LOAD_SPONSOR_FOLLOWUPS";
export const SPONSOR_FOLLOWUPS_LOADED = "SPONSOR_FOLLOWUPS_LOADED";

export const LOADED_FAVORITES = 'LOADED_FAVORITES'
export const LOAD_FAVORITES = 'LOAD_FAVORITES'

export const FETCH_LOADING_EVENT_DETAILS = "FETCH_LOADING_EVENT_DETAILS"
export const FETCH_LOADED_EVENT_DETAILS = 'FETCH_LOADED_EVENT_DETAILS'
export const FETCH_ALL_PROJECTS_FULL_LOADING = 'FETCH_ALL_PROJECTS_FULL_LOADING'

export const FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING = 'FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING'
export const FETCH_ALL_COMPAGINS_SLIDERS_LOADING = 'FETCH_ALL_COMPAGINS_SLIDERS_LOADING'

export const FETCH_ALL_PROJECTS_LOADING = 'FETCH_ALL_PROJECTS_LOADING'
export const FETCH_ALL_PROJECTS_ENOUGH_LOADING = 'FETCH_ALL_PROJECTS_ENOUGH_LOADING'

export const FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING = 'FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING'
export const FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING = 'FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING'

export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS'
export const FETCH_ALL_EVENTS_LOADING = 'FETCH_ALL_EVENTS_LOADING'