import React, {useState, useEffect} from 'react'
import './RequItem.css'
import { useTranslation } from "react-i18next";
import {
    applyValidation,
    resetValidation,
} from "../../../../handlers/formValidation";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { reSubmitReturnedLocalAid } from "../../../../api";

const RequItem = (props) => {
    const { t } = useTranslation();
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const [imgField, setImgField] = useState('');
    const [loadingModalShow, setLoadingModalShow] = useState(false);

    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [displayedItem, setDisplayedItem] = useState(true)
    const [editDisplay, setEditDisplay] = useState(false);
    const [returnedInfoForm, setReturnedInfoForm] = useState({
        person_id: userLogin?.Results[0]?.person_id,
        req_id: props.requestNumber,
        req_year: props.year,
        user_remarks: props?.user_remarks,
        attachments: [],
    });

    const [attcahmentObj, setAttachmentObj] = useState({
        att_desc: "",
        file_type: "",
        file_64: "",
        img_pre: ''
    });

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setAttachmentObj({ ...attcahmentObj, file_64: reader.result.toString().split(',')[1], file_type: file.type.split('/')[1], img_pre: reader.result.toString().split(',')[0]});
        };
        reader.onerror = function (error) {
        };
    }
    const handleChangeFile = async (e) => {
        getBase64(e.target.files[0]);
        setImgField(e.target.value);

    }
    const add = () => {
        if (attcahmentObj.file_64 == null || attcahmentObj.file_64 == "") {
            toastifyMessage("Choose file", undefined, "error");
            return false;
        }
        else if (attcahmentObj.att_desc == null || attcahmentObj.att_desc == "") {
            toastifyMessage("Please enter description", undefined, "error");
            return false;
        }
        var temp = returnedInfoForm.attachments;
        temp.push(attcahmentObj);
        setReturnedInfoForm({ ...returnedInfoForm, attachments: temp })
        setAttachmentObj({
            att_desc: "",
            file_type: "",
            file_64: "",
            img_pre: ''
        })
        setImgField("");
    }
    const removeFile = (i) => {
        var temp = returnedInfoForm.attachments;
        temp.splice(i, 1)
        setReturnedInfoForm({ ...returnedInfoForm, attachments: temp });
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(()=> {
        if (width <= 700) {
            setDisplayedItem(false)
        } else {
            setDisplayedItem(true)
        }
      }, [])

      const submitForm = () => {
        if (returnedInfoForm.attachments.length == 0) {
            toastifyMessage("Choose file", undefined, "error");
            return false;
        }
        setLoadingModalShow(true)
        
        setReturnedInfoForm({...returnedInfoForm, attachments: returnedInfoForm.attachments.map((item) => {
            delete item['img_pre']
            return item
        }), user_remarks: returnedInfoForm.user_remarks == null || returnedInfoForm.user_remarks === ''? props?.user_remarks: returnedInfoForm.user_remarks})
        reSubmitReturnedLocalAid(returnedInfoForm).then(res => {
            if (res.data["status"]) {
                setLoadingModalShow(false)
                toastifyMessage("You have successfully submited your request", undefined, "success");
                window.location.reload(); 
            } else {
                setLoadingModalShow(false)
                toastifyMessage("Something went wrong...!", undefined, "error");
            }
        }) .catch((e) => {
            toastifyMessage("Something went wrong...!", undefined, "error");
            setLoadingModalShow(false)
        });
    }
    return (
        <div className='requestItem'>
            <label style={{fontWeight:'bold', width:'100%', display:"flex", alignItems:'center', justifyContent:'space-between'}}>
                {props.title}
                <span className='toBeShowen' style={{cursor:'pointer', display:'none'}} onClick={()=> displayedItem? setDisplayedItem(false): setDisplayedItem(true)}>
                {displayedItem?
                    <i className="fas fa-angle-down"></i>:
                    lng==='arab'?
                    <i className="fas fa-angle-left"></i>:
                    <i className="fas fa-angle-right"></i>
                }
                </span>
            </label>
            {displayedItem?
            <React.Fragment>
            <div id={props.id} className='request_full_details'>
                <div className='record_for_requ_item'>
                    <label style={{color:'#000'}}>{t("Request Number")}: </label>
                    <label className='payment_numbers' style={{color:'gray'}}>{props.requestNumber} </label>
                </div>
                <div className='record_for_requ_item'>
                    <label style={{color:'#000'}}>{t("Year")}: </label>
                    <label className='payment_numbers' style={{color:'gray'}}>{props.year} </label>
                </div>
                <div style={{display: props.fromReturned? 'none':null}} className='record_for_requ_item'>
                    <label style={{color:'#000'}}>{t("Request Classification")}: </label>
                    <label style={{color:'gray'}}>{props.requClassi} </label>
                </div>
            </div>
            <div style={{display: props.fromReturned? 'flex':'none', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start', gap:'15px', width:'100%'}}>
                <div style={{display:'grid', alignItems:'flex-start', justifyContent:'center', gap:'10px', gridTemplateColumns:'20% 80%', width:'100%'}}>
                    <label style={{color:'#000', textAlign: lng==='arab'? 'right':'left'}}>{t("User Remarks")}: </label>
                    <label style={{color:'gray', textAlign: lng==='arab'? 'right':'left'}}>{props?.user_remarks} </label>
                </div>
                <div style={{display:'grid', alignItems:'flex-start', justifyContent:'center', gap:'10px', gridTemplateColumns:'20% 80%', width:'100%'}}>
                    <label style={{color:'#000', textAlign: lng==='arab'? 'right':'left'}}>{t("ERC Remarks")}: </label>
                    <label style={{color:'#ee4236', textAlign: lng==='arab'? 'right':'left'}}>{props?.erc_remarks} </label>
                </div>
                {editDisplay?
                <div className='edit_returned_display'>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start', width:'100%'}}>
                        <label>{t('Remarks')}</label>
                        <textarea style={{height:'160px'}} value={returnedInfoForm.user_remarks} onChange={(e)=> setReturnedInfoForm({...returnedInfoForm, user_remarks: e.target.value})} name='user_remarks' className='returned_text_area'/>
                    </div>
                    <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold", alignSelf:'flex-start' }}>
                        {t("Upload attachments")}
                    </label>
                    <div className="just_the_form_without_submit">
                        <div className="form_left_side">
                            <div className="input_wraper">
                                <input
                                    type="file"
                                    onChange={handleChangeFile}
                                    className="local_aid_input"
                                    value={imgField}
                                    name="PO_BOX"
                                    placeholder={t("Choose file")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>
                        </div>
                    <div className="form_right_side">
                        <div className="input_wraper">
                            <input
                                type="text"
                                onChange={(e) => setAttachmentObj({...attcahmentObj, att_desc: e.target.value})}
                                className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                                value={attcahmentObj.att_desc}
                                name="att_desc"
                                placeholder={t("Description")}
                                required
                                onInvalid={applyValidation}
                                onInput={resetValidation}
                            />
                        </div>
                    </div>
                    <button onClick={add} className='upload_button'>{t("ADD")}</button>
                </div>
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', flexWrap:'wrap', gap:'10px'}} className="selected-images">
                    {returnedInfoForm.attachments?.map((item, i) => (
                        <div key={i} style={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center", width:'auto', gap: '7px', padding:'10px', flexWrap:'wrap'}} className="image-row">
                            <img src={`${item.img_pre},${item.file_64}`} style={{ width: "150px", height: "100px" }} alt="" />
                            <p style={{textAlign:'center', margin:'0px', fontWeight:'bold'}}>{item.att_desc}</p>
                            <button onClick={() => removeFile(i)} style={{ background: "#ee4236", color: "white", border: "none" }}>{t("Delete")}</button>
                        </div>
                    ))}
                </div>
                </div>:null
            }
            <div style={{display:'flex', alignItems: 'center', justifyContent:'space-evenly', width:'100%'}}>
                <button onClick={()=> setEditDisplay(!editDisplay)} className='editSubmit_btn'>{editDisplay? t("Cancel"): t("Edit Request")}</button>
                <button onClick={submitForm} style={{display:editDisplay?'flex':'none', backgroundColor: '#ee4236'}} className='editSubmit_btn'>{t("Submit")}</button>
            </div>
                
            </div>
            </React.Fragment>: 
            null
        }
            <LoadingModal
                show={loadingModalShow}
                dialogClassName="modal-60w"
                backdrop="static"
            />
        </div>
    )
}

export default RequItem
