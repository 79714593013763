import React, { useState, useEffect } from "react";
import "./TrainingCourses.css";
import icon7 from "../../../assets/family name.png";
import icon9 from "../../../assets/pencil.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'
import {
  getCourses,
  getVolunteerCourses,
  getAllCountries,
} from "../../../actions/volunteer";
import { useDispatch } from "react-redux";
import DoneModal from "../../Auth/loginSignPage/doneModal/DoneModal";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import {
  applyValidation,
  resetValidation,
} from "../../../handlers/formValidation";
import Loading from "../../ui/loading";
import { useHistory } from "react-router-dom";
import { getVolunteerCoursePDF } from "../../../api";

const TrainingCourses = () => {
  const history = useHistory();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [added, setAdded] = useState(0);

  useEffect(() => {
    if (userLogin?.Results[0]?.vln_id_flag === "2") {
      if (lng === "arab") {
        dispatch(getCourses(1));
        dispatch(getVolunteerCourses(userLogin?.Results[0]?.vln_id, 1));
        dispatch(getAllCountries(1));
      } else {
        dispatch(getCourses(2));
        dispatch(getVolunteerCourses(userLogin?.Results[0]?.vln_id, 2));
        dispatch(getAllCountries(2));
      }
    }
  }, [lng, added]);
  const vlnCourses = useSelector((state) => state.vlnCourses.vlnCourses);
  const loading = useSelector((state) => state.vlnCourses.loading);
  const [vlnAllCourses, setVlnAllCourses] = useState(vlnCourses);
  useEffect(() => {
    setVlnAllCourses(vlnCourses);
  }, [vlnCourses]);

  const vlnVolunteerCourses = useSelector((state) => state.vlnVolunteerCourses);
  const [vlnVolunteerAllCourses, setVlnVolunteerAllCourses] =
    useState(vlnVolunteerCourses);
  useEffect(() => {
    setVlnVolunteerAllCourses(vlnVolunteerCourses);
  }, [vlnVolunteerCourses]);

  const vlnCountries = useSelector((state) => state.vlnCountries);
  const [vlnAllCountries, setVlnAllCountries] = useState(vlnCountries);
  useEffect(() => {
    setVlnAllCountries(vlnCountries);
  }, [vlnCountries]);

  const [courseData, setCourseData] = useState({
    selectedCourse: "",
    trainingPlace: "",
    startDate: "",
    endDate: "",
    countryID: "",
  });
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const getVolunteerCertPDF = (item) => {
      getVolunteerCoursePDF(userLogin?.Results[0]?.vln_id,item.courseID,lng === "arab"? "1": "2").then((data) => {
        var binary = '';
        var bytes = new Uint8Array( data.data );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
        }
        var link = document.createElement("a");
        link.href = 'data:application/octet-stream;base64,' + window.btoa( binary );

        //Set properties as you wise
        link.download = `Certificate for Volunteer ${userLogin?.Results[0]?.vln_id} Course: ${item.courseName}.pdf`;
        link.target = "blank";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  }
  const handleChange = (e) => {
    setCourseData({ ...courseData, [e.target.name]: e.target.value });
  };

  if (userLogin?.Results[0]?.vln_id_flag === "2") {
    return (
      <div
        className="Adding_course"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <ShareHeader pageName={t("Training Courses")} noShare={true}/>
        <div className="submit_requ_header">
          <h3 style={{ color: "gray" }}>{t("Training Courses")}</h3>
        </div>
        
        {loading ? <Loading /> : <div className="courses_display">
          {vlnVolunteerAllCourses
            ?.slice(0)
            .reverse()
            .map((item, i) => (
              <div key={i} className="course_Item">
                <i
                className="fas fa-file-pdf"
                  onClick={() => getVolunteerCertPDF(item)}
                  style={{
                    cursor: "pointer",
                  }}
                ></i>
                <label>
                  <span style={{ fontWeight: "bold" }}>{item.courseName}</span>{" "}
                  {t("at")} {"   " + item.courseLocation}
                </label>
                <label>
                  {t("Start Date")}:
                  <span className='payment_numbers' style={{ fontWeight: "bold" }}>
                    {"   " + item.startDate}
                  </span>
                </label>
                <label>
                  {t("End Date")}:
                  <span className='payment_numbers' style={{ fontWeight: "bold" }}>
                    {"   " + item.endDate}
                  </span>
                </label>
                <label>
                  {t("Country")}:
                  <span style={{ fontWeight: "bold" }}>
                    {"   " + item.countryName}
                  </span>
                </label>
                  
              </div>
            ))}
        </div>}
        <DoneModal
          show={doneModal}
          dialogClassName="modal-60w"
          onHide={() => {
            setDoneModal(false);
          }}
        />
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace("/profile");
  }
};

export default TrainingCourses;
