import "./LinkAccount.css";
import React, { useState, useEffect } from "react";
import ERCLogo from "../../../assets/logo.png";
import BCE2mob from "../../../assets/loginBCMob2.png";
import BCE2 from "../../../assets/loginBC.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DoneModal from "../../Auth/loginSignPage/doneModal/DoneModal";
import { useDispatch } from "react-redux";
import { checkingVlnAcc, confirmLinking } from "../../../actions/volunteer";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
  applyValidation,
  resetValidation,
} from "../../../handlers/formValidation";
const LinkAccount = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const dispatch = useDispatch();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [content, setContent] = useState("yes");
  const [display, setDisplay] = useState(1);
  const [vlnId, setvlnId] = useState("");
  const [firstData, setFirstData] = useState({
    account_link_email: userLogin?.Results[0]?.email? userLogin?.Results[0]?.email:'',
    volunteer_eid: '',
  });
  const [vlnOtp, setVlnOtp] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  useEffect(() => {
    if (width > 900) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const [doneModal, setDoneModal] = useState(false);
  const openFromLog = () => {
    history.push("/");
  };
  const checkingIFheHadAccount = (e) => {
    e.preventDefault()
    setLoadingModalShow(true);
    dispatch(
      checkingVlnAcc(
        firstData,
        setLoadingModalShow,
        setDisplay,
        setvlnId
      )
    );
  };
  const confirmLinkingVln = (e) => {
    e.preventDefault()
    setLoadingModalShow(true);
    dispatch(
      confirmLinking(
        firstData.account_link_email,
        vlnId,
        vlnOtp,
        setLoadingModalShow,
        setDoneModal
      )
    );
  };
  if (props.location.state?.from === "profile") {
    return (
      <div
        id="login_sign"
        style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", justifyContent:'flex-start' }}
      >
        <ShareHeader noTitle={true} noShare={true} forAuth={true}/>
        <div className="NeedToOtp">
          <img
            src={ERCLogo}
            onClick={openFromLog}
            alt="ERC_logo"
            className="ERC_logo_login_sign"
          />
          <h1 style={lng === "arab" ? { direction: "rtl" } : null}>
            {t("Welcome")}
          </h1>
          {display === 1 ? (
            <React.Fragment>
              <h3 style={lng === "arab" ? { direction: "rtl" } : null}>
                {t(
                  "Please Enter Your E-mail and Your Emirates ID To Get Your Account."
                )}
              </h3>
              {content === "yes" ? (
                <form className="vln_linking_wraper" onSubmit={checkingIFheHadAccount} style={{width:'100%', direction:lng==='arab'? 'rtl':'ltr'}}>
                  <input
                    className="vln_linking_input"
                    placeholder="example@example.com"
                    type="email"
                    value={firstData.account_link_email}
                    onChange={(e) =>
                      setFirstData({
                        ...firstData,
                        account_link_email: e.target.value,
                      })
                    }
                    disabled = {userLogin?.Results[0]?.email}
                  />
                  <input
                    className={firstData.volunteer_eid? 'payment_numbers vln_linking_input': lng==='arab'? 'force_arabic_font vln_linking_input':'payment_numbers vln_linking_input'}
                    placeholder={t("Enter Emirates ID related to volunteer account")}
                    maxLength='15'
                    type="text"
                    value={firstData.volunteer_eid}
                    onChange={(e) =>
                      setFirstData({
                        ...firstData,
                        volunteer_eid: e.target.value.replace(/\D/, ""),
                      })
                    }
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                  />
                  <button
                    style={{ borderRadius: "10px", alignSelf:'flex-end' }}
                    className="confirm_otp"
                  >
                    {t("Confirm")}
                  </button>
                </form>
              ) : (
                <React.Fragment>
                  <h1
                    style={
                      lng === "arab"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {t(content)}
                  </h1>
                  <button
                    onClick={() => history.push("/profile")}
                    style={{
                      backgroundColor: "gray",
                      border: "none",
                      padding: "7px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {t("Back")}
                  </button>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className='centered' style={lng === "arab" ? { direction: "rtl" } : null}>
                {t("Please Enter The Verification Code You Received By E-mail/Mobile")}
              </h3>
              {content === "yes" ? (
                <form className="otp_input_wraper" onSubmit={confirmLinkingVln} style={{
                  width: "80%",
                  flexDirection: lng==='arab'? 'row':"row-reverse",
                  padding: "0px",
                  gap: "0px",
                  alignItems: "unset",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}>
                  <input
                    style={{ width: "100%", padding: "10px" }}
                    className="input_for_verfication_code"
                    type="text"
                    value={vlnOtp}
                    onChange={(e) => setVlnOtp(e.target.value.replace(/\D/, ""))}
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                  />
                  <button type='submit'  className="confirm_otp"
                  style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}}>
                    {t("Confirm")}
                  </button>
                </form>
              ) : (
                <React.Fragment>
                  <h1
                    style={
                      lng === "arab"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {content}
                  </h1>
                  <button
                    onClick={() => setDisplay(1)}
                    style={{
                      backgroundColor: "gray",
                      border: "none",
                      padding: "7px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {"Re-Send OTP"}
                  </button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        <DoneModal
          show={doneModal}
          dialogClassName="modal-60w"
          onHide={() => {
            setDoneModal(false);
            window.location.replace("/");
          }}
        />
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace("/");
  }
};

export default LinkAccount;
