import ProjectShare from "./ProjectSharePage/ProjectShare";
import { Route, Switch } from "react-router-dom";
import ProjectSharesPage from "./ProjectSharesPage/ProjectSharesPage";
import ProjectShareCreate from "./ProjectShareCreate/ProjectShareCreate";

function ProjectShareRoute() {
  return (
    <div className="App">
      <Switch>
        <Route path="/project_share/" component={ProjectSharesPage} exact />
        <Route path="/project_share/:year/:id" component={ProjectShare} />
        <Route path="/project_share/all" component={ProjectShareCreate} />

      </Switch>
    </div>
  );
}

export default ProjectShareRoute;
