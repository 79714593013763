import React, { useState, useEffect } from 'react'
import './ProjectDisplayComp.css'
import { useTranslation } from "react-i18next";
import Default from '../../../../../../assets/defaultA.jpg'
import Marquee from 'react-double-marquee';

const ProjectDisplayComp = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let checkBoxStyle = lng === 'arab' ? 'rightSign_btn_arab--active' : 'rightSign_btn--active';
    const [imgSrc, setImgSrc] = useState(props.projectPhoto)
    useEffect(() => {
        setImgSrc(props.projectPhoto)
    }, [props.projectPhoto])
    // useEffect(() => {
    //     if (props.selected === props.projectId) {
    //         // document.getElementById('project_' + props.projectId).style.border = '1px solid #ee4236';
    //         // document.getElementById('rightSign_btn_of_' + props.projectId).className = checkBoxStyle;
    //         // document.getElementById('img_projects_' + props.projectId).style.filter = 'unset';
    //     } else {
    //         // document.getElementById('project_' + props.projectId).style.border = '1px solid transparent';
    //         // document.getElementById('rightSign_btn_of_' + props.projectId).className = 'rightSign_btn';
    //         // document.getElementById('img_projects_' + props.projectId).style.filter = 'grayscale(100%)';
    //     }
    // }, [props.selected, props.projectId])
    const handleClick = (str) => {
        props.setSelectedProject(str)
        props.setProjectTypeID(props.projectTypeId)
        //props.setSelectedIdForShare(props.projectTypeId)
        props.setSelectedIdForShareLocal(props.projectTypeId)
        /*  props.setProjectData({
              projectId: props.projectId,
              projectName: props.title,
              projectImg: props.projectPhoto,
              projectAmount: props.projectData.projectAmount,
              countryName: props.projectData.countryName,
              countryId: props.projectData.countryId,
              countryImg: props.projectData.countryImg,
              projectYear: new Date().getFullYear()
          })*/
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <div className='project_display_component'>
            <span style={lng === 'arab' ? { marginRight: '0px', marginLeft: '-2px' } : null} id={`rightSign_btn_of_${props.projectId}`} className={props.selected === props.projectId? checkBoxStyle:'rightSign_btn'}><i className="fas fa-check" ></i></span>
            <div className='wraper_for_project_img' style={{border: props.selected === props.projectId? '1px solid #ee4236':'1px solid transparent'}} id={`project_${props.projectId}`}>
                <img style={{filter: props.selected === props.projectId? 'unset':'grayscale(100%)'}} onClick={() => props.countryLoading ? null : handleClick(props.projectId)} src={imgSrc} onError={() => setImgSrc(Default)} alt={props.title} id={`img_projects_${props.projectId}`} className='project_img_for_projects_page' />
                {props.title?.length >= 15 ?
                    <div className={lng === 'arab' ? 'btn_img_label_projects cssmarquee_arab' : 'btn_img_label_projects cssmarquee'} style={{ direction: 'ltr', minHeight: 'auto' }} >
                        <Marquee speed={0.025} childMargin={15} direction={lng === 'arab' ? 'right' : 'left'} className='label_of_title'><label className='.btn_img_label_projects charity-projects_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
                    </div> :
                    <div className={props.title?.length >= 19 ? lng === 'arab' ? ' btn_img_label_projects' : ' btn_img_label_projects' : 'btn_img_label_projects'}><label className='charity-projects_title'>{props.title}</label></div>
                }
            </div>
        </div>
    )
}

export default ProjectDisplayComp