import React, { useState, useEffect } from "react";
import ERCLogo from "../../../../assets/logo.png";
import BCE2mob from "../../../../assets/loginBCMob2.png";
import BCE2 from "../../../../assets/loginBC.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DoneModal from "../doneModal/DoneModal";
import LoadingModal from "../loadingPopUp/loadingPopUp";
import ShareHeader from "../../../LoaclComponents/shareHeader/ShareHeader";

import { useDispatch } from "react-redux";
import {
  setTheEsetOTP,
  askingForReset,
  updatePassword,
} from "../../../../actions/user";
import * as api from "../../../../api";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import i18n from "i18next";

export const ResetPassword = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [content, setContent] = useState("yes");
  const [FromState, setFromState] = useState('');

  const [display, setDisplay] = useState(1);
  const [firstData, setFirstData] = useState({
    email: "",
    otp_type: "EMAIL",
  });
  const [secondPAssword, setSecondPassword] = useState('');
  const [secondData, setSecondData] = useState({
    email: firstData.email,
    otp: "",
  });
  const [thirdData, setThirdData] = useState({
    email: "",
    newPassword: "",
  });
  const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
useEffect(() => {
  if (userLogin) {
    setDisplay(3)
    setFromState('profile')
  }
}, []);
  useEffect(() => {
    if (width > 1200) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const [doneModal, setDoneModal] = useState(false);
  const openFromLog = () => {
    history.push("/");
  };
  const askForReset = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    dispatch(
      askingForReset(firstData, setLoadingModalShow, setContent, setDisplay)
    );
  };
  const reSendOTPS = async (e) => {
    e.preventDefault()
    setLoadingModalShow(true);
    dispatch(
      setTheEsetOTP(
        { email: firstData.email, otp: secondData.otp },
        setLoadingModalShow,
        setContent,
        setDisplay
      )
    );
  };
  const validatePassword = (password) => {
    const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,21})/);
    return re.test(password);
  };
  const message = {
    main: "\"Password\" length must be 8-20 characters long and contain",
    p1: "at least 1 uppercase letter",
    p2: "at least 1 lowercase letter",
    p3: "at least 1 numeric character",
    p4: "at least 1 special character"
  }
  const newPassword = async (e) => {
    e.preventDefault()
    if (!validatePassword(thirdData.newPassword)) {
      toastifyMessage(
        undefined, undefined, "warning",
        <div>
          {i18n.t(message.main)}:
          <br /> - {i18n.t(message.p1)}
          <br /> - {i18n.t(message.p2)}
          <br /> - {i18n.t(message.p3)}
          <br /> - {i18n.t(message.p4)}
        </div >
      );
      return false;
    } else {
      setLoadingModalShow(true);
      let newUserData = await api.updatePassword({
        email: firstData.email,
        newPassword: thirdData.newPassword,
      });
      if (newUserData.data === "SUCCESS") {
        setLoadingModalShow(false);
        toastifyMessage(
          "Success!",
          undefined,
          "success");
        history.push("/auth");
      } else {
        setLoadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");
      }
    }

    // dispatch(
    //   updatePassword(
    //     { email: firstData.email, newPassword: thirdData.newPassword },
    //     setLoadingModalShow,
    //     setContent,
    //     setDoneModal
    //   )
    // );
  };
  const newPAsswordFromUser = async (e) => {
    e.preventDefault()
    if (!validatePassword(thirdData.newPassword) || !validatePassword(secondPAssword)) {
      toastifyMessage(
        undefined, undefined, "warning",
        <div>
          {i18n.t(message.main)}:
          <br /> - {i18n.t(message.p1)}
          <br /> - {i18n.t(message.p2)}
          <br /> - {i18n.t(message.p3)}
          <br /> - {i18n.t(message.p4)}
        </div >
      );
      return false;
    } else if (thirdData.newPassword !== secondPAssword) {
      toastifyMessage("The two passwords don't match", undefined, "warning");
    } else {
      setLoadingModalShow(true);
      api.changeUserPassword(userLogin?.Results[0]?.email, secondPAssword).then((data) => {
        if (data.data === 'SUCCESS') {
          setLoadingModalShow(false);
          setDoneModal(true);
          toastifyMessage(
            "Success!",
            undefined,
            "success");
        } else {
          setLoadingModalShow(false);
          toastifyMessage("Something went wrong...!", data.data.result, "error");
        }
      });
    }   
  }
  return (
    <div
      id="login_sign"
      style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", justifyContent: 'flex-start', minHeight:'100vh' }}
    >
      <ShareHeader noTitle={true} noShare={true} forAuth={true} />
      <div className="NeedToOtp">
        <img
          src={ERCLogo}
          onClick={openFromLog}
          alt="ERC_logo"
          className="ERC_logo_login_sign"
        />
        <h1 style={lng === "arab" ? { direction: "rtl" } : null}>
          {t("Welcome")}
        </h1>
        {display === 1 ? (
          <React.Fragment>
            <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
              {t("Please Enter Your E-mail To Get a Verification Code.")}
            </h3>
            {content === "yes" ? (
              <form
                onSubmit={askForReset}
                className="otp_input_wraper"
                style={{
                  width: "90%",
                  flexDirection: "row",
                  padding: "0px",
                  gap: "0px",
                  alignItems: "unset",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}
              >
                <input
                  placeholder="example@example.com"
                  style={{ width: "100%", padding: "10px" }}
                  className="input_for_verfication_code"
                  type="email"
                  value={firstData.email}
                  onChange={(e) =>
                    setFirstData({ ...firstData, email: e.target.value })
                  }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                />
                <button style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}} type="submit" className="confirm_otp">
                  {t("Confirm")}
                </button>
              </form>
            ) : (
              <React.Fragment>
                <h1>{content}</h1>
                <button
                  onClick={() => history.push("/auth")}
                  style={{
                    backgroundColor: "gray",
                    border: "none",
                    padding: "7px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  {t("Back")}
                </button>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : display === 2 ? (
          <React.Fragment>
            <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
              {t("Please Enter The Verification Code You Received By E-mail")}
            </h3>
            {content === "yes" ? (
              <form
                onSubmit={reSendOTPS}
                className="otp_input_wraper"
                style={{
                  width: "90%",
                  flexDirection: "row",
                  padding: "0px",
                  gap: "0px",
                  alignItems: "unset",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}
              >
                {" "}
                <input
                  style={{ width: "100%", padding: "10px" }}
                  className="input_for_verfication_code"
                  type="number"
                  maxLength="4"
                  value={secondData.otp}
                  onChange={(e) =>
                    setSecondData({ ...secondData, otp: e.target.value })
                  }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                />
                <button style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}} type="submit" className="confirm_otp">
                  {t("Confirm")}
                </button>
              </form>
            ) : (
              <React.Fragment>
                <h1>{content}</h1>
                <button
                  onClick={() => history.push("/auth")}
                  style={{
                    backgroundColor: "gray",
                    border: "none",
                    padding: "7px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  {t("Back")}
                </button>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
              {t("Please Enter The New Password")}
            </h3>
            {content === "yes" ? (
              <form
                onSubmit={FromState==='profile'? newPAsswordFromUser : newPassword}
                className="otp_input_wraper"
                
                style={{
                  width: "90%",
                  flexDirection: FromState==='profile'? 'column':"row",
                  padding: "0px",
                  gap: FromState==='profile'? '15px':"0px",
                  boxShadow:FromState==='profile'? 'unset':null,
                  direction: lng==='arab'? 'rtl':'ltr',
                  alignItems: "unset",
                }}
              >
                <input
                  style={{ width: "100%", padding: "10px", boxShadow:FromState==='profile'? '1px 0px 8px gray':null, borderRadius: FromState==='profile'? '5px':null }}
                  className={lng==='arab'? "force_arabic_font input_for_verfication_code":"payment_numbers input_for_verfication_code"}
                  placeholder={t("Enter your new password")}
                  type="password"
                  value={thirdData.newPassword}
                  onChange={(e) =>
                    setThirdData({ ...thirdData, newPassword: e.target.value })
                  }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                />
                {FromState==='profile'?
                <input
                  style={{ width: "100%", padding: "10px", boxShadow:FromState==='profile'? '1px 0px 8px gray':null, borderRadius: FromState==='profile'? '5px':null }}
                  className={lng==='arab'? "force_arabic_font input_for_verfication_code":"payment_numbers input_for_verfication_code"}
                  placeholder={t("Re-enter your new password")}
                  type="password"
                  value={secondPAssword}
                  onChange={(e) =>
                    setSecondPassword(e.target.value)
                  }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                />:null
              }
                
                <button style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}} type="submit" className="confirm_otp">
                  {t("Confirm")}
                </button>
              </form>
            ) : (
              <h1>{content}</h1>
            )}
          </React.Fragment>
        )}
      </div>
      <DoneModal
        show={doneModal}
        dialogClassName="modal-60w"
        onHide={() => {
          setDoneModal(false);
          history.push("/");
        }}
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default ResetPassword;
