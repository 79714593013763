export const TRANSLATIONS_ARAB = {
  'Our':'ملكنا',
  عربى: 'عربى',
  'Hello,': 'أهلًا،',
  Raised: 'وصل إلى',
  'About Us': 'من نحن',
  Goal: 'الهدف',
  'Donate Now': 'تبرع الآن',
  Home: 'الرئيسية',
  'Donate to': 'تبرع إلى',
  'Remove from Bag': 'أزل من الحقيبة',
  'One Time': 'مرة واحدة',
  'View All': 'رؤية المزيد',
  'Your request has been successfully submitted': 'لقد تم تقديم طلبك بنجاح',
  'Contact Us': 'تواصل معنا',
  Contact: 'تواصل',
  'Please Login to Continue': 'الرجاء تسجيل الدخول للمتابعة',
  'Forgot Account': 'نسيت الحساب',
  'Forgot Password': 'هل نسيت كلمة السر',
  'SIGN IN': 'تسجيل الدخول',
  'Create an Account': 'انشئ حسابًا',
  'Or Sign in with': 'أو تسجيل الدخول بواسطة ',
  'Emirates Red Crescent': 'الهلال الأحمر الإماراتي',
  Upload: 'إرفاق',
  'Choose a branch': 'اختر الفرع',
  'Hi, User': 'مرحبًا، المستخدم',
  Hi: 'مرحبًا',
  'My Sponsors': 'كفالاتي',
  'Be a Volunteer': 'كن متطوعًا',
  '5% VAT will be included for each service':
    'سيتم إضافة ٥٪ ضريبة القيمة المضافة لكل خدمة',
  'User Remarks': 'ملاحظات العضو',
  'ERC Remarks': 'ملاحظات الهلال الأحمر الإماراتي',
  'Edit Request': 'تعديل الطلب',
  'Please choose a related service': 'من فضلك اختر خدمة متعلقة',
  'Local Aid': 'المساعدات المحلية',
  'Recent Donation': 'التبرع الأخير',
  'Sponsor Profile': 'الملف الشخصي للكافل',
  Charity: 'صدقة',
  Projects: 'مشاريع',
  Donations: 'التبرعات',
  'Payment Method': 'طريقة الدفع أو السداد',
  Account: 'حساب',
  'Beneficiary No.': 'رقم المستفيد',
  'Date of Birth': 'تاريخ الولادة',
  Nationality: 'الجنسية',
  'Sponsor Date': 'تاريخ الكفالة',
  'Beneficiary Message': 'رسالة المستفيد',
  'Send Gifts/ Support': 'إرسال الهدايا / الدعم',
  Call: 'مكالمة',
  AED: 'د.إ',
  Pay: 'ادفع',
  'Add to Bag': 'أضف إلى الحقيبة',
  'Family Status': 'الوضع العائلي',
  'Educational Status': 'الحالة التعليمية',
  Supervisor: 'مشرف',
  'Support History': 'تاريخ الدعم',
  'Kindly select the Payment Option': 'يرجى تحديد خيار الدفع',
  'Select the Country': 'حدد البلد',
  'Monthly Payment': 'الدفع الشهري',
  'Full Payment': 'دفع كامل',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada.':
    'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي. و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟     علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم .',
  Register: 'تسجيل',
  'Please fill all the fields': 'الرجاء تعبئة جميع الحقول',
  'CREATE ACCOUNT': 'إنشاء حساب',
  'Humantarian Cases': 'الحالات الإنسانية.',
  'Donate Now': 'تبرع الآن',
  'Sukuk / Vouchers': 'الصكوك / القسائم',
  'Become a Sponsor': 'اكفل',
  Family: 'أسرة',
  Together: 'سويًا',
  Favorites: 'المفضلة',
  'Your information has been successfully added': 'تم إضافة معلوماتك بنجاح',
  'Please choose a valid date': 'من فضلك اختر تاريخ صحيح',
  'Please set a valid date!': 'من فضلك اختر تاريخ صحيح',
  'Profile Details': 'المعلومات الشخصية',
  'Save this method for future payments':
    'حفظ البطاقة لعمليات الدفع المستقبلية',
  'For recurring payments, The same amount will be automatically deducted on the same date':
    'للدفعات المتكررة، سيتم خصم المبلغ تلقائيًا في نفس ميعاد الدفع، سواء كان شهريًا أو يوميًا',
  'Help Build The World': 'نساعد في بناء العالم',
  'View All Charity Projects': 'عرض جميع المشاريع الخيرية',
  Providing: 'توفير',
  'Food for the Poor': 'الغذاء للفقراء',
  'Education Qualification': 'التخصص العلمي',
  'Relative registration': 'تسجيل الأقارب',
  'Select relation': 'اختر صلة القرابة',
  'Family name': 'اسم العائلة',
  'Address registration': 'تسجيل العنوان',
  'Please enter description': 'من فضلك اكتب الوصف',
  'Upload attachments': 'إرفاق الوثائق',
  ADD: 'إضافة',
  'Select Address': 'اختر العنوان',
  'Select Emirate': 'اختر الإمارة',
  'Once you sumbit your files, you will not be able to go back or add new information.':
    'بمجرد رفع الملفات لن تتمكن من العودة وإضافة معلومات جديدة',
  Emirate: 'الإمارة',
  'Step one': 'الخطوة الأولى',
  'Step two': 'الخطوة الثانية',
  'Step three': 'الخطوة الثالثة',
  'Step four': 'الخطوة الرابعة',
  'Step five': 'الخطوة الخامسة',
  'Final step': 'الخطوة الأخيرة',
  'Local Aid registration': 'التسجيل للمساعدات المحلية',
  Job: 'الوظيفة',
  'Tap to Donate': 'اضغط للتبرع',
  'Support Kids': 'دعم الأطفال',
  "Support Annjke's medical expenses": 'دعم النفقات الطبية في Annjke',
  'Read More': 'اقرأ أكثر',
  'Apple Pay': 'الدفع بواسطة أبل',
  SMS: 'رسالة قصيرة',
  Mosque: 'مسجد',
  Well: 'حسنًا',
  'Class Room': 'قاعة الدراسة',
  Ambulance: 'سيارة اسعاف',
  'Providing ': 'توفير ',
  Country: 'الدولة',
  'Haaj for Individual': 'حج للفرد',
  Cow: 'بقرة',
  'Building Construction': 'تشييد المباني',
  'Air Conditioner': 'مكيف هواء',
  'Project Description': 'وصف المشروع',
  'Digging a well that will cover a sub urban area':
    'حفر جيدا والتي ستغطي منطقة حضرية فرعية',
  'Donate now': 'تبرع الآن',
  'Add to bag': 'أضف إلى الحقيبة',
  'Donate by SMS': 'تبرع بالرسائل القصيرة',
  'Google Pay': ' الدفع بواسطة جوجل',
  Categories: 'الأقسام',
  'All categories': 'جميع الأقسام ',
  'Also, Enter The Verification Code You Received By Mobile':
    'أيضًا، يرجى إدخال رمز التفعيل المرسل عبر الهاتف',
  'Please Enter The Verification Code You Received By E-mail':
    'يرجى إدخال رمز التفعيل المرسل عبر البريد الإلكتروني',
  Sponsorships: 'الكفالة',
  Sukuk: 'الصكوك',
  'Humanitarian Cases': 'الحالات الإنسانية',
  '6 Months': 'ستة أشهر',
  Months: 'الشهور',
  'Months of renewal': 'أشهر التجديد:',
  Renewal: 'التجديد',
  '1 Year': 'سنة واحدة',
  '2 Years': 'سنتان',
  '3 Years': 'ثلاث سنوات',
  'Medical Suk': 'الصك الطبي',
  Daily: 'يوميًا',
  Monthly: 'شهريًا',
  Yearly: 'سنويًا',
  'My Bag': 'حقيبتي',
  'Donation Summary': 'ملخص التبرع',
  'Some elements have a value less than the minimum!':
    'بعض قيم التبرعات أقل من الحد الأدنى للتبرع',
  Profile: 'الملف الشخصي',
  'Email, Mobile, File ': 'البريد الإلكتروني، المحمول رقم الملف',
  Password: 'كلمة المرور',
  Name: 'الإسم بالكامل',
  Email: 'البريد الإلكتروني',
  'Confirm Password': 'تأكيد كلمة المرور',
  'Kindly Select the Payment Option': 'يرجى تحديد خيار الدفع',
  'Search for a donation..': 'البحث عن تبرع ..',
  'Winter Clothes': 'ملابس الشتاء',
  'Student desks': 'مكاتب الطلاب',
  'DONATE NOW': 'تبرع الآن',
  'Total Payments': 'إجمالي المدفوعات',
  "You can't send gifts if you have pending late payments":
    'لا يمكنك إرسال الهدايا طالما لديك مدفوعات متأخرة',
  'SEND A GIFT TO YOUR SPONSORS': 'ارسال هدية للمكفولين',
  'Total Donation': 'إجمالي التبرع',
  'Select the Project': 'حدد المشروع',
  'Establishment and international recognition': 'التأسيس والإعتراف الدولي',
  'Our course': 'دوراتنا',
  'Our objectives': 'أهدافنا',
  'Our Role': 'دورنا',
  'Fundamental Principles': 'المباديء الأساسية',
  'Our values': 'قيمنا',
  Services: 'الخدمات',
  'Volunteer now': 'تطوع الآن',
  'Sponsor an orphan': 'اكفل يتيمآ',
  'Sponsors portal': 'بوابة الكفالة',
  'Local aid portal': 'بوابة المساعدات المحلية',
  Recruitment: 'توظيف',
  'Donate now': 'تبرع الآن',
  'Need Help?': 'تحتاج إلى مساعدة؟',
  'Contact Us Now': 'تواصل معنا الآن',
  'Payment details': 'تفاصيل المدفوعات',
  Name: 'الإسم',
  'E-mail': ' البريد الإلكتروني',
  'The amount you selected is less than the intial amount':
    'القيمة التي اخترتها أقل من القيمة الأساسية للتبرع',
  Message: 'الرسالة',
  '@ ALL RIGHT RESERVED EMIRATES RED CRESCENT 2021':
    '@ جميع الحقوق محفوظة للهلال الأحمر الإماراتي 2021',
  Send: 'إرسال',
  Notifications: 'الإشعارات',
  'Search for a donation': 'ابحث في التبرعات',
  'Donation Frequency': 'عدد مرات التبرع',
  'Donation Amount': 'قيمة التبرع',
  'Search for a Human Case': 'ابحث عن حالة إنسانية',
  Support: 'ادعم',
  'Raised of': 'من أصل',
  Donors: 'متبرع',
  'Enter amount in AED': 'أدخل القيمة بالدرهم الإماراتي',
  'The Emirates Red Crescent Authority, in cooperation with Abu Dhabi Islamic Bank, in launching the first initiative of its kind, Sukuk Al-Khair are sustainable humanitarian financial instruments, The Authority invsts it and takes advantage of the investment profits to serve various humanitarian programs.':
    'تطلق هيئة الهلال الأحمر الإماراتي بالتعاون مع مصرف أبوظبي الإسلامي المبادرة الأولى من نوعها ، وهي صكوك الخير ، وهي أدوات مالية إنسانية مستدامة ، وتستثمر الهيئة فيها وتستفيد من أرباح الاستثمار لخدمة البرامج الإنسانية المختلفة.',
  'Be a Sponsor': 'اكفل',
  'Choose a Country': 'اختر دولة',
  'Total Amount:': 'المبلغ الكلي:',
  Like: 'أضف إلى المفضلة',
  Unlike: 'أزل من المفضلة',
  Countries: 'الدول',
  'Choose a Project': 'اختر مشروعًا',
  Orphans: 'أيتام',
  'You May Also Donate For...': 'يمكنك أيضًا التبرع بـ',
  'My Bag': 'حقيبتي',
  'Late Payment': 'المدفوعات المتأخرة',
  Total: 'القيمة الكلية',
  Payment: 'نظام الدفع',
  'Add All To Bag': 'أضف الكل إلى الحقيبة',
  Added: 'تمت الإضافة',
  Sponsorships: 'الكفالة',
  'Volunteering Service': 'خدمة التطوع',
  'Recent Donations': 'التبرعات الأخيرة',
  'Setup your information': 'قم بإعداد ملفك الشخصي',
  Submit: 'تقديم',
  'Add More': 'أضف المزيد',
  'National ID': 'الهوية الوطنية',
  Education: 'التعليم',
  Nationality: 'الجنسية',
  Mobile: 'رقم الهاتف المحمول',
  'Date of Birth': 'تاريخ الميلاد',
  Gender: 'الجنس',
  'Family Name': 'اسم العائلة',
  'Family Member Name': 'اسم فرد العائلة',
  Occupation: 'المهنة',
  'Martial Status': 'الحالة الإجتماعية',
  'Health Status': 'الحالة الصحية',
  Relatives: 'الأقارب',
  Requests: 'الطلبات',
  'Submit a request': 'تقديم طلب',
  'Please select the aid type': 'الرجاء تحديد نوع المساعدة',
  'Note / Request Details': 'ملاحظة / تفاصيل الطلب',
  'Submitting requests for students aids is only for one student':
    'تقديم طلبات المساعدات الطلابية لطالب واحد فقط',
  Note: 'ملاحظة',
  'Profile Number': 'رقم الملف',
  'Personal Information': 'المعلومات الشخصية',
  Qualifcation: 'المؤهلات',
  'Local Aid Requests': 'طلبات المساعدة المحلية',
  Pending: 'قيد الانتظار',
  Completed: 'مكتمل',
  Returned: 'مرتجع',
  'No Requests Found': 'لا توجد طلبات',
  'Request Number': 'رقم الطلب',
  Year: 'سنة',
  'Request Classification': 'تصنيف الطلب',
  'Volunteer Portal': 'بوابة المتطوع',
  'File Details': 'تفاصيل الملف',
  Participants: 'المشاركات',
  Attachments: 'المرفقات',
  'Training courses': 'دورات تدريبية',
  Languages: 'اللغات',
  Skills: 'المهارات',
  'Areas of interest': 'مجالات الإهتمام',
  Events: 'الأحداث',
  'E-services': 'الخدمات الإلكترونية',
  'Can not pay less than 5 AED': 'لا يمكنك التبرع بمبلغ أقل من ٥ درهم',
  'Please select from campaign elements': 'أنت لم تختر أي تبرع',
  'The element has a value less than the minimum!':
    'قيمة التبرع أقل من الحد الأدنى',
  'Please select an amount to donate': 'من فضلك اختر قيمة للتبرع',
  Branch: 'الفرع',
  Number: 'رقم',
  'Job ID': 'الهوية الوظيفية',
  'End Date': 'تاريخ الإنتهاء',
  'JOB LICENSE': 'رخصة العمل',
  'EDUCATION LEVEL': 'الدرجة العلمية',
  'Education Level': 'الدرجة العلمية',
  Major: 'التخصص',
  'Place of Issuance': 'مكان الإصدار',
  'Date of Issuance': 'تاريخ الإصدار',
  'Start Date': 'تاريخ البدء',
  'Document ID': 'هوية الملف',
  PASSPORT: 'جواز السفر',
  RESIDENCE: 'الإقامة',
  'NATIONALITY IDENTITY': 'الهوية الوطنية',
  'Training Courses': 'الدورات التدريبية',
  'Training Place': 'مكان التدريب',
  at: 'في',
  'Select Language': 'اختر اللغة',
  'Select Level': 'اختر المستوى',
  'You are': 'أنت',
  Skill: 'المهارة',
  Remarks: 'محلاظة',
  'Select Skill': 'اختر مهارة',
  'Skills and Interests': 'المهارات والإهتمامات',
  Reports: 'التقارير',
  'Send Gifts/Support': 'إرسال الهدايا / الدعم',
  'Renew Payments': 'تجديد المدفوعات',
  'Sponsor Date': 'تاريخ الكفالة',
  'Family status': 'الوضع العائلي',
  'Educational status': 'الوضع الدراسي',
  'Your bag is empty!': 'حقيبتك فارغة بالفعل!',
  Project: 'مشروع',
  Date: 'التاريخ',
  Time: 'الوقت',
  Renewals: 'التجديدات',
  Select: 'اختر',
  'Please select': 'من فضلك قم بالإختيار',
  'Total amount': 'القيمة الكلية',
  'Search for a country': 'البحث في الدول',
  'Please choose an orphan': 'من فضلك اختر يتيم للاستمرار',
  'Select all': 'اختر الكل',
  Close: 'إغلاق',
  'Payment amount per month': 'قيمة الدفع لكل شهر',
  "You don't have any sponsors": 'ليس لديك أية كفالات',
  'Payment amount': 'قيمة الدفع',
  'New Card': 'بطاقة جديدة',
  'Existed Card': 'بطاقة محفوظة',
  'Change Card': 'تغيير البطاقة',
  'needs your help': 'يحتاج إلى مساعدتك',
  'Your past payment for': 'مدفوعاتك الأخيرة لـ',
  'Could not complete the payment due to unexpected issue':
    'تعذر إتمام الدفع بسبب خطأ غير متوقع',
  'to support': 'لرعاية',
  'has been Declined': 'قد مضت',
  'Your full payment to support': 'مدفوعاتك الكاملة لرعاية',
  'is needed': 'منتظرة',
  'Add New Card': 'أضف بطاقة جديدة',
  Reciept: 'فاتورة',
  Type: 'النوع',
  Amount: 'القيمة',
  'Would you like to': 'هل تفضل',
  'Would you like to ': 'هل تفضل ',
  'using your existing UAE PASS details or link to an existing account?':
    'باستخدام تفاصيل تصريح دخول UAE PASS الحالية الخاصة بك أو الارتباط بحساب موجود؟',
  'Register using UAE PASS account': 'التسجيل باستخدام حساب UAE PASS',
  'Link existing account': 'ربط حساب متوفر في الهلال الاحمر',
  Logout: 'تسجيل الخروج',
  'Please enter your E-mail to continue':
    'الرجاء إدخال البريد الإلكتروني الخاص بك للمتابعة',
  email: 'البريد الإلكتروني',
  'Please enter the OTP': 'الرجاء إدخال كلمة المرور لمرة واحدة',
  OTP: ' كلمة المرور لمرة واحدة',
  'Your Account is not verified in UAE PASS. ':
    'لم يتم توثيق حسابك في UAE PASS. ',
  'Please verify your account on the UAE PASS application.':
    'يرجى توثيق حسابك في برنامج UAE PASS.',
  Register: 'التسجيل',
  'for easier donations in the future?': 'لتبرع أسهل في المستقبل؟',
  'Please choose a payment method': 'من فضلك اختر طريقة دفع',
  'Thank you for your donation': 'شكرًا لك على التبرع',
  'Back to app': 'العودة للتطبيق',
  'Not Now': 'ليس الآن',
  'Emirates ID already exists': 'رقم الهوية متواجد بالغعل',
  'Enter your new password': 'أدخل كلمة السر الجديدة',
  'Re-enter your new password': 'أعد إدخال كلمة المرور',
  "The two passwords don't match": 'كلمتا المرور غير متطابقتين',
  Yes: 'نعم',
  'E-mail, Mobile, File Number': 'البريد الإلكتروني، رقم الهاتف، رقم الملف',
  'Change your password': 'تغيير كلمة المرور',
  'Forgot Account?': 'نسيت الحساب؟',
  'Forgot Password?': 'نسيت كلمة السر؟',
  'Creact an Account': 'إنشاء حساب جديد',
  'Create Account': 'إنشاء حساب',
  'Mobile Number': 'رقم الهاتف',
  'Choose file': 'اختر ملف',
  'You have successfully submited your request':
    'لقد قمت بإعادة تقديم طلبك بنجاح',
  Description: 'الوصف',
  'Flat No.': 'رقم الشقة',
  Address: 'العنوان',
  'P.O Box': 'رقم البريد',
  'Emirates ID': 'الهوية الإماراتية',
  'SIGN UP': 'تسجيل',
  'Already have an account?': 'لديك حساب؟',
  'Or Sign up with': 'أو قم بالتسجيل بواسطة',
  'needs your next payment': 'يحتاج إلى الدفعة القادمة',
  'needs your help': 'يحتاج إلى مساعدتك',
  'Your full payment for': 'السداد الكامل الخاص بـ',
  'is has past the due date': 'قد مر عليه الموعد',
  'You havent made the payment to support him in':
    'لم تتمكن من سداد مبلغ الرعاية الخاصة به لمدة',
  Menu: 'القائمة',
  'Log Out': 'تسجيل الخروج',
  ENG: 'الإنجليزية',
  UAE: 'العربية',
  'Please Check Your Mail For The Verification':
    'يرجى التأكد من البريد الإلكتروني لتفعيل الحساب',
  Welcome: 'مرحبا بك!',
  "But If You Didn't Recieve It, Click Here.":
    'ولكن إن لم يصل إليك بريد التفعيل يرجى الضغط هنا.',
  'This E-mail Exists, Please Log In':
    'هــذا البريد الإلكتروني متواجد، يرجى تسجيل الدخول.',
  'Please Enter The Verification Code You Received By E-mail/Mobile':
    'يرجى إدخال رمز التفعيل الذي استلمته من خلال البريد الإلكتروني/الهاتف',
  Campaign: 'اسم الحملة',
  'User Not Found': 'الحساب غير موجود',
  'Wrong Password': 'كلمة السر غير صحيحة',
  'Incorrect email/password is entered':
    'تم إدخال بريد إلكتروني / كلمة مرور غير صحيحة',
  'OTP not Verified': 'لم يتم تأكيد رمز التحقق',
  'Please insert the OTP': 'من فضلك قم بإدخال رمز التحقق',
  'Please Enter Your E-mail To Get a Verification Code.':
    'يرجى إدخال البريد الإلكتروني لاستلام رمز التفعيل.',
  'The Verification Code Is Wrong': 'رمز التفعيل الـذي أدخلته ربما يكون خاطئًا',
  'Log In': 'تسجيل الدخول',
  Media: 'الوسائط',
  'Donation History': 'التبرعات السابقة',
  'Please Enter Your Emirates ID To Get Your Account.':
    'يرجى إدخال رقم الهوية الإماراتية.',
  'Please Enter Your Emirates ID/Sponsor ID To Get Your Account.':
    'يرجى إدخال رقم الهوية الإماراتية / هوية الكفالة، للبحث عن حسابك',

  'Here Is Your Account': 'إليك معلومات الحساب',
  Relation: 'الصلة',
  'Hour Count': 'عدد الساعات',
  'Select Course': 'الدورات التدريبية',
  'Select Country': 'اختر دولة',
  'Select Program': 'اختر البرنامج',
  'Event Date': 'تاريخ الحدث',
  'Event Title': 'عنوان الحدث',
  'Event Description': 'الوصف',
  'Event Location': 'الموقع',
  'Starts At': 'يبدأ في تاريخ',
  'Ends At': 'ينتهي في تاريخ',
  From: 'من الساعة',
  To: 'إلى الساعة',
  'Select Related Service': 'اختر خدمة متعلقة',
  Cost: 'التكلفة',
  Checkout: 'الدفع',
  'We are reviewing your ': 'نحن نعمل على مراجعة',
  'request for creating Local Aid account, ':
    'طلبك الخاص بإنشاء حساب المساعدات المحلية، ',
  'Please wait.': 'يرجى الانتظار.',
  'request for creating Volunteer Service account, ':
    'طلبك الخاص بإنشاء حساب لخدمات التطوّع',
  'Volunteer name in Arabic': 'اسم المتطوع باللغة العربية',
  'Volunteer name in English (As shown in Emirates ID)':
    'اسم المتطوع باللغة الإنجليزية (كما هو مطبوع في الهوية الإماراتية)',
  'Work Place ID': 'معرف مكان العمل',
  'Contact Language': 'لغة التواصل',
  'Complete Your Personal Information': 'أكمل معلوماتك الشخصية',
  'Enter your mobile number': 'أدخل رقم هاتفك',
  'Charity projects': 'مشاريع خيرية',
  'Add Credit/Debit Card': 'إضافة بطاقة ائتمان / خصم',
  'Save and pay (only for this time)': 'حفظ البطاقة والدفع (لهذه المرة فقط)',
  Next: 'متابعة',
  for: 'ل',
  'List of Locations': 'قائمة المواقع',
  'Please complete your information': 'من فضلك قم بإكمال معلوماتك',
  English: 'الإنجليزية',
  Arabic: 'العربية',
  'Select contact language': 'اختر لغة التواصل',
  'Pay for delivery': 'ادفع للتوصيل',
  'Select your gender': 'اختر الجنس',
  Female: 'أنثى',
  Male: 'ذكر',
  'Select Branch': 'اختر الفرع',
  Qualification: 'المؤهل',
  "It seems you don't have a ": 'يبدو أنك لا تمتلك',
  'Local Aid account': 'حساب خاص بالمساعدات المحلية،',
  'Volunteer account': 'حساب خاص بخدمات التطوّع',
  ', so we recommend to create one.': 'لـذلك نحن ندعوك لإنشاء حساب جديد',
  'Create account': 'إنشاء حساب',
  'Select Martial Status': 'اختر الحالة الإجتماعية',
  'Volunteer name': 'اسم المتطوع',
  'Upload your picture': 'قم بإرفاق صورتك الشخصية',
  'Insert your Job details': 'قم بإدخال معلوماتك الوظيفية',
  'Upload your Job License': 'إرفق صورة للرخصة الوظيفية',
  'Upload your Attachment': 'إرفق صورة التوثيق',
  'Insert your Education details': 'قم بإدخال معلوماتك الدراسية',
  'Upload your Educational License': 'إرفق صورة للشهادة التعليمية',
  'Insert your National details': 'قم بإدخال معلومات الهوية الوطنية',
  'Upload your National ID': 'قم بإرفاق صورة الهوية الوطنية',
  'Accounts settings': 'إعدادات الحسابات ذات الصلة',
  'Profile settings': 'إعدادات الحساب الشخصي',
  'Payment Method settings': 'إعدادات طرق الدفع والسداد',
  'Language settings': 'إعدادات اللغة',
  'Chat with Us': 'تحدث معنا',
  Settings: 'الإعدادات',
  'About us': 'من نحن',
  'Arm of the United Arab Emirates for humanitarian work':
    'ذراع الإمارات العربية المتحدة للعمل الإنساني',
  'What distinguishes us': 'ما يميزنا',
  'Our Partners': 'شركاؤنا',
  'Our Locations': 'فروعنا',
  'UAE Red Crescent Authority was established on 31/01/1983 and gained the international recognition as a member No.: 139 of the International Federation of Red Crescent and Red Cross on 08/27/1986. His Highness Sheikh Hamdan Bin Zayed Al Nahyan took the position of the Chairman of the Board of the Red Crescent in 1986, and then took the position of the Chairman of the Authority in 1993, which gave impetus to the efforts of the human Authority. In 1997, the role of the Authority had been enhanced by the acceptance of Her Highness Sheikha Fatima bint Mubarak, for the position of the honorary president of the UAE Red Crescent. In 2001, the UAE Red Crescent had been selected as the second best humanitarian Autjority at the level of the continent of Asia.':
    'تأسست هيئة الهلال الأحمر لدولة الإمارات العربية المتحدة في 1983/1/31 ونالت الاعتراف الدولي باعتبارها العضو رقم 139 في الاتحاد الدولي لجمعيات الهلال الأحمر والصليب الأحمر بتاريخ 1986/8/27. تولى سمو الشيخ حمدان بن زايد آل نهيان، منصب رئيس مجلس إدارة الهلال الأحمر عام 1986 ثم رئيساً للهيئة عام 1993 مما أعطى قوة دافعة لجهود الهيئة الإنسانية . وفي عام 1997 تعزز دور الهيئة بقبول سمو الشيخة فاطمة بنت مبارك لمنصب الرئيسة الفخرية للهلال الأحمر الإماراتي . وفي عام 2001 تم اختيار الهلال الأحمر الإماراتي ثاني أفضل هيئة إنسانية على مستوى قارة آسيا ....',
  'Your name in Arabic': 'اسمك باللغة العربية',
  'Insert your Residence details': 'قم بإدخال معلومات الإقامة',
  'Upload your Residence ID': 'قم بإرفاق صورة الإقامة',
  'Residence ID': 'رقم الإقامة',
  'Insert your Passport details': 'قم بإدخال معلومات جواز السفر',
  'Upload your Passport ID': 'قم بإرفاق صورة جواز السفر',
  'Passport ID': 'رقم جواز السفر',
  'Insert your Training Courses': 'قم بإدخال دوراتك التدريبية',
  'I have an account': 'لدي حساب بالفعل',
  Confirm: 'تأكيد',
  'Please Enter Your E-mail and Your Emirates ID To Get Your Account.':
    'يرجى التأكد من البريد الإلكتروني والهوية الوطنية',
  "You don't have a Volunteer Account": 'أنت لا تمتلك حساب لخدمات التطوّع',
  'Grace conservation': 'حفظ النعمة',
  'Grace Conservation': 'حفظ النعمة',
  'Live chat': 'دردشة مباشرة',
  Max: 'الحد الأقصى',
  'Search for a project': 'البحث عن مشروع',
  "You don't have pending payments": 'ليس لديك أية مدفوعات في الإنتظار',
  'Get Direction': 'احصل على اتجاه',
  Alphabetically: 'أبجديًا',
  'Project No': 'رقم المشروع',
  'Project Name': 'اسم المشروع',
  'Total Cost': 'التكلفة الكلية',
  'Country Name': 'الدولة',
  'Project Year': 'سنة المشروع',
  'Work in Percentage': 'العمل بالنسبة المئوية',
  'The report has been sent to your email successfully!':
    'لقد تم إرسال التقرير إلى البريد الإلكتروني بنجاح',
  'Could not send to your email. Please check your email account or contact support!':
    'لم نتمكن من إرسال التقرير عبر البريد الإلكتروني الخاص بكم، يرجى التحدث مع الدعم الفني، أو المحاولة في وقت لاحق',
  'Low amount to high amount': 'من الأقل إلى الأعلى',
  'High amount to low amount': 'من الأعلى إلى الأقل',
  Sorting: 'الترتيب',
  Filter: 'تصفية',
  Done: 'تم',
  Back: 'العودة',
  'Date of birth': 'تاريخ الميلاد',
  'Sponsor date': 'تاريخ الكفالة',
  'Report date': 'تاريخ التقرير',
  'Beneficiary message': 'رسالة المستفيد',
  'Beneficiary number': 'رقم المستفيد',
  'Family situation': 'الوضع العائلي',
  'Educational background': 'الوضع التعليمي',
  'Number of sisters': 'عدد الأخوات',
  'Number of brothers': 'عدد الإخوان',
  'Is the mother alive': 'هل الأم على قيد الحياة',
  "Parent's name": 'اسم ولي الأمر',
  'Is Studying': 'يدرس',
  'Relationship with parent': 'العلاقة مع ولي الأمر',
  'School name': 'اسم المدرسة',
  'Education level': 'المستوى التعليمي',
  'Education Type': 'نوع التعليم',
  'Education Stage': 'مرحلة التعليم',
  'Supervisor message': 'رسالة المشرف',
  'Family income': 'مدخول العائلة',
  'SEND A GIFT TO YOUR SPONSOR': 'أرسل هدية إلى المكفول الخاص بك',
  'Enter Amount': 'أدخل المبلغ',
  'Enter the message here': 'أدخل الرسالة هنا',
  'Send Now': 'أرسل الآن',
  'Send a Gift': 'أرسل هدية',
  Account: 'حساب',
  'Sponsor ID': 'هوية الكفالة',
  'Please enter your information to continue':
    'الرجاء إدخال المعلومات الخاصة بك للمتابعة',
  'N/A': 'غير متوفر',
  'Identity type': 'نوع الهوية',
  'Identity number': 'رقم الهوية',
  'I agree to all terms and conditions of the sponsorship':
    'أوافق على كافة الشروط والأحكام الخاصة بالكفالة',
  "You Can't continue without accepting sponsorship terms and conditions":
    'لا يمكنك المتابعة دون الموافقة على الشروط والأحكام الخاصة بالكفالة',
  'TERMS AND CONDITIONS OF SPONSORSHIP': 'الشروط والأحكام الخاصة بالكفالة',
  Remarks: 'ملاحظات',
  'Samsung Pay': 'الدفع بواسطة سامسونج',
  'Edit Profile': 'تعديل الملف الشخصي',
  'No DOB available': 'لا يوجد تاريخ ميلاد متوفر',
  'No Payment Method Found': 'لا توجد أي وسيلة للدفع',
  'Purchasing Committees Accreditation Portal': 'بوابة اعتماد لجان المشتريات',
  'Portal of aid and accreditation of committees':
    'بوابة المساعدات و إعتماد اللجان',
  'Sponsorships and Orphans Affairs Portal': ' بوابة الكفالات و شؤون الأيتام',
  'External project management system': 'نظام إدارة المشاريع الخارجية',
  'Internal Services': 'خدمات داخلية',
  'Supplier Services': 'خدمات الموردين',
  'Supplier login': 'تسجيل دخول لمورد',
  'Register a new supplier': 'تسجيل مورد جديد',
  'Choose a language': 'اختر لغة',
  Save: 'حفظ',
  'Do you want to delete this method?': 'هل تريد إزالة تلك البطاقة',
  'Enter Emirates ID related to volunteer account':
    'أدخل الهوية الإماراتية المتعلقة بحساب المساعدات المحلية',
  'language already exists in user profile': 'لقد قمت بإضافة تلك اللغة بالفعل',
  Cancel: 'تراجع',
  'Volunteer ID': 'هوية المتطوع',
  'Change mobile number': 'تغيير رقم الجوال',
  'Enter your phone number': 'أدخل رقم هاتفك',
  'Enter your phone number here': 'أدخل رقم هاتفك هنا',
  'Verify your OTP': 'تأكيد رمز التحقق الخاص بك',
  'Enter the OTP here': 'أدخل رمز التحقق هنا',
  'Enter your emirates ID': 'أدخل رقم هويتك الإماراتية',
  'Please enter your name': 'من فضلك أدخل اسمك',
  'Building Name': 'اسم المبنى',
  'Phone Number': 'رقم الهاتف',
  'Phone number has been edited succesfully': 'تم تعديل رقم الهاتف بنجاح',
  'Something went wrong...!': 'حدث خطا ما، يرجى المحاولة لاحقًا...!',
  'Beneficent Portal': 'بوابة المحسنين',
  'Login Failed: Pop-up closed by user!': '!فشل تسجيل الدخول',
  'The Email/Mobile Number/EID is already linked':
    'تم ربط البريد الإلكتروني / رقم الهاتف المحمول / رقم الهوية بالفعل',
  'Email does not exist': 'البريد الإلكتروني غير موجود',
  'Sent!': 'تم الإرسال!',
  'Please enter your number': 'من فضلك أدخل رقم هاتفك',
  'Please enter your name': 'من فضلك أدخل اسمك',
  'Error while creating new local AID':
    'حدث خطأ أثناء إنشاء حسابك الخاص بالمساعدات المحلية',
  'Passwords do not match!': 'كلمة السر لا تتطابق',
  'All fields are mandatory!': 'هناك بعض الحقول الفارغة',
  'Email format is not correct!': 'هناك خطأ في صيغة البريد الإلكتروني',
  'Please choose amount to add to bag':
    'من فضلك اختر قمية لتتم الإضافة إلى الحقيبة',
  'Please enter a valid donation!': 'من فضلك أدخل قيمة صحيحة للتبرع',
  'Please enter your Emirates ID ': 'من فضلك أدخل رقم الهوية الوطنية',
  'Min value is less than or equal Max!':
    'الحد الأدنى أكبر من الحد الأقصى أو يساويه ',
  'Please Select:': 'من فضلك قم بالإختيار:',
  'Samsung Pay': 'الدفع بواسطة سامسونج',
  'Success!': 'تم بنجاح ',
  Min: 'الحد الأدنى',
  'No recent donations found!': 'ليس لديك أية تبرعات مؤخرًا!',
  'Minimum Value': 'الحد الأدنى',
  'Select project type': 'اختر المشروع',
  All: 'الجميع',
  'CONTACT INFO': 'معلومات التواصل',
  'You can contact us any way that is convenient for you. We are available 24/7 via fax,email or telephone. You can also use a quick contact form on the right or visit our office personally. Email us with any questions or inquiries or use our contact data. We would be happy to answer your inquiries':
    'يمكنك التواصل معنا بأي طريقة تفضلها، نحن متاحون طوال الأوقات، من خلال الفاكس، البريد الإلكتروني، والهاتف، كما يمكنك التواصل معنا من خلال استمارة التواصل..أرسل لنا رسالة، بما تود أن تستفسر عنه، وسنكون سعداء بالرد عليك.',
  'Emirates Red Crescent': 'الهلال الأحمر الإماراتي',
  'Abu Dhabi': 'أبو ظبي',
  'Zayed Sports City': 'مدينة زايد الرياضية',
  'PO Box': 'صندوق بريد',
  'Abu Dhabi-United Arab Emirates': 'أبو ظبي-الإمارات العربية المتحدة',
  'Customer Service': 'خدمة العملاء',
  'CONTACT FORM': 'استمارة التواصل',
  'Select issue type': 'اختر مسألة التواصل',
  'Full Name...': 'الاسم الكامل',
  'Mobile Number...': 'رقم الهاتف',
  'E-mail...': 'البريد الإلكتروني',
  'Subject...': 'الموضوع',
  'Message...': 'الرسالة',
  'Loading...': 'معالجة',
  'SUBMIT A REQUEST': 'إتمام الطلب',
  'Total Late Payments': 'إجمالي المدفوعات المتأخرة',
  'Late Payments': 'المدفوعات المتأخرة',
  'Add to favorites': 'أضف إلى المفضلة',
  'Remove from favorites': 'أزل من المفضلة',
  'Copied!': 'تم النسخ',
  'Copy the Link': 'انسخ الرابط',
  'Donate SMS': 'الدفع بالرسائل النصية',
  'No notifications': 'لا توجد إشعارات',
  Receipt: 'الفاتورة',
  'Grace Request': 'طلب سماح',
  'Enter your name here': 'أدخل اسمك هنا',
  'Enter your mobile number here': 'أدخل رقم هاتفك هنا',
  'Select your grace type': 'اختر نوع الخدمة',
  'Choose Branch': 'حدد الفرع',
  'Pick up Date': 'حدد التاريخ',
  'Pay Now': 'ادفع الآن',
  'Loading...': 'تحميل...',
  'Save Card': 'حفظ البطاقة',
  'Cardholder Name': 'اسم حامل البطاقة',
  'Card Number': 'رقم البطاقة',
  'Save and pay': 'حفظ البطاقة والدفع',
  Pay: 'دفع',
  'Save only for this time': 'احفظ فقط لهذه المرة',
  'for this payment only': 'لهذه المرة فقط',
  'Bag Items': 'الحقيبة',
  'Mobile Number...': 'رقم الهاتف',
  'E-mail...': 'البريد الإلكتروني',
  'Subject...': 'الموضوع',
  'Message...': 'الرسالة',
  'Loading...': 'معالجة',
  'SUBMIT A REQUEST': 'إتمام الطلب',
  'Total Late Payments': 'إجمالي المدفوعات المتأخرة',
  'Add to favorites': 'أضف إلى المفضلة',
  'Remove from favorites': 'أزل من المفضلة',
  'Copied!': 'تم النسخ',
  'Copy the Link': 'انسخ الرابط',
  'Donate SMS': 'الدفع بالرسائل النصية',
  'No notifications': 'لا توجد إشعارات',
  Receipt: 'الفاتورة',
  'Grace Request': 'طلب سماح',
  'Enter your name here': 'أدخل اسمك هنا',
  'Enter your mobile number here': 'أدخل رقم هاتفك هنا',
  'Select your grace type': 'اختر نوع الخدمة',
  'Choose Branch': 'حدد الفرع',
  'Pick up Date': 'حدد التاريخ',
  'Copy link': 'نسخ الرابط',
  'Pay Now': 'ادفع الآن',
  'Loading...': 'تحميل...',
  Search: 'بحث',
  Services: 'الخدمات',
  'Al Ghadeer UAE Crafts': 'الغدير للحرف الإماراتية',
  'Select payment Method': 'اختر وسيلة الدفع',
  'Fill out this field': 'لا يمكن أن يكون هذا النص فارغًا',
  "You Don't Have Apple Pay": 'ليس لديك حساب على أبل باي',
  'Please add a Card in your Apple Pay Wallet!':
    'من فضلك قم بإضافة بطاقة إلى حافظة أبل باي',
  '"Mobile number" should be 10 digits long and start with "05"':
    'رقم الهاتف يجب أن يكون ١٠ أرقام ويبدأ ب ٠٥',
  '"Emirates ID" number should be 15 digits long':
    'رقم الهوية الإماراتية يجب أن يكون ١٥ رقمًا',
  'Your full payment for': 'لم تقم بالدفع منذ',
  Yes: 'نعم',
  'You havent made the payment to support him since': 'أنت لم تقم بالدفع منذ',
  '"Password" length must be 8-20 characters long and contain':
    'يجب ألا تقل كلمة السر عن ٨ ولا تزيد عن ٢٠. ويجب ان تحتوي على',
  'at least 1 uppercase letter': 'حرف كبير',
  'at least 1 lowercase letter': 'حرف صغير',
  'at least 1 numeric character': 'رقم',
  'at least 1 special character': 'رمز',
  'Please Enter The New Password': 'من فضلك قم بإدخال كلمة السر الجديدة',
  'Can not find sponser account related to this number':
    'لا يوجد حساب رعاية بهذا الرقم',
  'Are you sure you want to remove all contents of the bag?':
    'هل أنت متأكد من رغبتك في إزالة جميع محتويات الحقيبة؟',
  Added: 'تمت الإضافة',
  Removed: 'تمت الإزالة',
  'Logged in as': 'تم تسجيل دخولك بواسطة',
  'Login Failed!': 'خطأ في تسجيل الخروج',
  'Logout Failed!': 'فشل في تسجيل الدخول',
  'Ramadan Tent': 'خيم رمضان',
  Ataya: 'عطايا',
  Sponsors: 'كفالات',
  'Sponsor Donation': 'تبرعات الكفالة',
  'My Projects': 'مشاريعي',
  'My Sponsors': 'كفالاتي',
  Projects: 'مشاريع',
  'Projects Donation': 'تبرعات المشاريع',
  'Total Donation': 'إجمالي التبرعات',
  'Please check your credentials and try again.':
    'من فضلك تحقق من المعلومات، وحاول مجددًا',
  'No Favorites Found': 'لا يوجد شيء في المفضلة',
  'List View': 'القائمة',
  'Map View': 'الخريطة',
  'No reports found': 'لا توجد تقارير',
  "Please select a report to view it's details":
    'من فضلك اختر تقرير لمشاهدة التفاصيل',
  'No Projects found': 'لا توجد مشاريع',
  'Project Details': 'تفاصيل المشروع',
  'Our Partners': 'شركاؤنا',
  Payments: 'المدفوعات',
  'No renewals available for this orphans': 'لا تجديدات متاحة',
  'Volunteer Register': 'تسجيل متطوع',
  'Local Aid Profile': 'الصفحة الشخصية',
  'Local Aid Registration': 'تسجيل المساعدات المحلية',
  Delete: 'إزالة',
  Identity: 'الهوية',
  Accounts: 'حسابات',
  'Add Account': 'أضف حسابًا',
  'Our Mission': 'مهمتنا',
  'Our Vision': 'رؤيتنا',
  'No donations found': 'لا توجد تبرعات',
  News: 'الأخبار',
  Gallery: 'المعرض',
  'Social Media': 'التواصل الإجتماعي',
  'Payment Methods': 'طرق الدفع',
  'No countries found': 'لا توجد دول',
  'No projects found': 'لا توجد مشاريع',
  'You now have ': 'أنت لديك الآن ',
  ' in your Bag': ' في حقيبتك',
  Quantity: 'العدد',
  'OTP did not match': 'رمز التحقق لا يتشابه',
  'Sponsor already added': 'الكافل متواجد بالفعل',
  'Added Extra sponsor': 'تمت إضافة كافل',

  'One Time': 'مرة واحدة',
  Months: 'أشهر',
  'You have ': 'أنت لديك ',
  PAY: 'ادفع',
  'There is no order with this MCO': 'لا توجد عملية دفع بهذا الرقم',
  'Added!': 'تمت الإضافة!',
  'Removed!': 'تمت الإزالة!',
  '1 Years': 'سنة واحدة',
  '1.5 Years': 'سنة ونصف',
  'Modified!': 'تم التعديل!',
  '"E-mail" field cannot be empty!':
    'الحقل الخاص بالبريد الالكتروني لا يمكن أن يكون فارغًا',
  '"Emirates ID" field cannot be empty!':
    'الحقل الخاص بالهوية الإماراتية لا يمكن أن يكون فارغًا',
  'Donation completed': 'إكتمل جمع التبرعات',
  'This human Case is complete': 'الحالة الإنسانية مكتملة',
  'Thanks for your Registration': 'شكرًا لتسجيلك',
  'We are now reviewing your ': 'نعمل حاليًا على مراجعة',
  'Insert your Interests': 'قم بإدخال اهتماماتك',
  'Insert all Languages you have': 'قم بإدخال اللغات التي تتقنها',
  'Insert your Skills': 'قم بإدخال مهاراتك',
  'Enter Emirates ID related to volunteer account':
    'أدخل الهوية الإماراتية المتعلقة بحساب المتطوع',
  'Please select a service': 'من فضلك اختر خدمة',
  'Please choose a date': 'من فضلك اختر تاريخ',
  'Choose amount to donate': 'اختر قيمة للتبرع',
  'Insert Identity details': 'قم بإدخال معلومات التعريف ',
  'Choose your provider': ' اختر مزود الخدمة',
  'Aid type': 'نوع المساعدة',
  'Insert Identity details': 'قم بإدخال معلومات التعريف ',
  'Choose your provider': ' اختر مزود الخدمة',
  'Aid type': 'نوع المساعدة',
  Share: 'مشاركة',
  "You don't have any items in your bag": 'ليس لديك أية أغراض في الحقيبة',
  'Enter your name': 'أدخل اسمك',
  'Maximum Value': 'القيمة الكلية',
  "You don't have any items in late payments": 'ليس لديك أية مدفوعات متأخرة',
  'Enter the mobile number ': 'أدخل رقم الهاتف',
  'Enter the mobile number here': 'أدخل رقم الهاتف هنا',
  'OTP SEND': 'إرسال رمز التحقق',
  Back: 'العودة',
  '"Name" field cannot be empty!': 'الحلق الخاص بالإسم لا يمكن أن يكون فارغًا',
  '"Password" field cannot be empty!':
    'الحقل الخاص بكلمة المرور لا يمكن أن يكون فارغًا',
  'Invalid Mobile number': 'رقم هاتف محمول خاطئ',
  'Invalid E-mail address.': 'بريد إليكتروني خاطئ',
  'Invalid E-mail, Mobile or File number.':
    'Invalid E-mail, Mobile or File number.',
  '"Mobile number" cannot contain special characters':
    'حقل هاتف المحمول لا يجب أن يحتوي أحرف أو رموز',
  'John Smith': 'John Smith',
  '"Full Name" field cannot be empty!':
    'الحقل الخاص بالإسم لا يمكن أن يكون فارغًا',
  '"Full Name" length should be 2-60 characters long.':
    'لا يجب أن يقل الإسم عن حرفين، ولا يزيد عن 60 حرفًا',
  'Please add your name.': 'من فضلك أضف اسمك',
  'Please select "Issue type".': 'من فضلك اختر نوع المشكلة',
  'Please add your mobile number.': 'من فضلك أضف رقم هاتفك',
  'No news found': 'لا توجد أخبار',
  'Invalid mobile number format.': 'صيغة الهاتف المحمول خاطئة.',
  'Please add your E-mail address.': 'من فضلك أضف بريدك الإلكتروني.',
  'Valid format: "someone@example.com"': 'Valid format: "someone@example.com"',
  '"Subject" field cannot be empty!':
    'الحقل الخاص بالموضوع لا يمكن أن يكون فارغًا',
  'Please describe your issue in the "Content" section':
    'من فضلك صف مشكلتك في القسم الخاص بالـمحتوى',
  'Submitting...': 'تقديم...',
  'Thank you for contacting us! Your request will be reviewed shortly.':
    'شكرًا لك على التواصل، سيتم مراجعة طلبك قريبًا',
  'This number is already active': 'هذا الرقم مفعل بالفعل',
  'Local Aid Registration is currently unavailable':
    'تسجيل المساعدة المحلية مغلق حاليا',
  'Upload attachments and agreement': 'تحميل المرفقات والاتفاقية',
  'Click Here to Download the Agreement': 'انقر هنا لتنزيل الاتفاقية',
  'Download Agreement': 'قم بتنزيل الاتفاقية',
  'For non-volunteer service click here': 'لخدمة غير المتطوعين انقر هنا',
  'Volunteer Satisfaction and Happiness Form': 'اﺳﺘﻤﺎرة رﺿﺎ وﺳﻌﺎدة اﻟﻤﺘﻄﻮﻋﻴﻦ',
  Login: 'تسجيل الدخول',
  'Your account is unverified. Please upgrade your account following instructions on the UAE PASS app':
    'حسابك غير موثق. يرجى ترقية حسابك حسب التعليمات على تطبيق الهوية الرقمية',
  'User cancelled the login': 'قام المستخدم بإلغاء تسجيل الدخول',
  'Summer Course Registration Form': 'تسجيل التدريب الصيفي',
  'Successfully added a request': 'تمت إضافة طلب بنجاح',
  'Number of Orphans': 'عدد الأيتام',
  'Amount to send': 'المبلغ المراد إرساله',
  'Select Account(s) to link': 'اختر الحساب (الحسابات) للربط',
  'Are you sure you wish to update the monthly amount donated to the orphan?':
    'هل أنت متأكد من رغبتك في تحديث المبلغ الشهري المتبرع لليتيم؟',
  No: 'لا',
  'Country of Residence': 'بلد الإقامة',
  'Training Courses ': 'تسجيل التدريب العملي',
  'Bank Payment': 'الدفع المصرفي',
  'Transaction History': 'تاريخ المعاملات',
  Instructions: 'تعليمات',
  'Family Members': 'عدد الاسرة',
  'Study Type': 'نوع التعليم',
  'Health Condition': 'الحالة الصحية',
  'Needs Medicine': 'يحتاج لعلاج',
  'Sickness Name': 'اسم المرض',
  Hobbies: 'الهوايات',
  Needs: 'الاحتياجات',
  'Health Background': 'الوضع الصحي',
  History: 'السجل',
  'Project Share': 'مشاركة المشروع',
  of: 'من',
  'Project Shares': 'مشاركة المشاريع',
  'Create Project': 'أنشئ مشروعًا',
  Sort: 'الترتيب',
  'Clear All': 'مسح الكل',
  'Search by name or id...': 'البحث بالاسم او الرقم',
  Reason: 'السبب',
  'Max File Size: ': 'اكبر حجم للملف: ',
  'Max Number of files: ': 'اكبر عدد من الملفات: ',
  'Would you like to share this project publicly?':
    'هل ترغب في مشاركة هذا المشروع بشكل عام؟',
  'Sharing the project to public means the project will be visible on Emirates Red Crescent where others can donate to complete the total project amount':
    'تعني مشاركة المشروع بشكل عام أن المشروع سيكون مرئيًا على الهلال الأحمر الإماراتي حيث يمكن للآخرين التبرع لإكمال المبلغ الإجمالي للمشروع',
  'Yes, Make it Public': 'نعم، اجعله عام',
  'No, Make it Private': 'لا، اجعله خاص',
  'Create New Project': 'إنشاء مشروع جديد',
  'day(s) remaining': 'الايام المتبقية ',
  'Pay remaining': 'دفع المتبقي',
  'Enter name for project please': 'الرجاء إدخال اسم للمشروع',
  'Please approve the terms and conditions':
    'الرجاء الموافقة على الشروط والاحكام',
  'I hereby accept the ': ' أنا أوافق على ',
  'terms and conditions': 'الشروط والاحكام',
  'My Shared Projects': 'مشاريعي المشتركة',
  'Donation amount must be at least AED ':
    'يجب ألا يقل مبلغ التبرع بالدرهم الإماراتي ',
  'Whatsapp Number': 'رقم الواتس اب',
  'Place of Work': 'مكان العمل',
  'Marital Status': 'الحالة الاجتماعية',
  'View the': 'راجع',
  'terms document': 'وثيقة الشروط',
  'for your reference': '',
  '*JPG format': 'ملف JPG',
  'Upload your National ID (Front)': 'قم بتحميل هويتك الوطنية (امامي)',
  'Upload your National ID (Back)': 'قم بتحميل هويتك الوطنية (خلفي)',
  'Go Back': 'الرجوع',
  'Sign the PDF, and re-upload': 'وقّع على ملف PDF وأعد تحميله',
  'Download PDF': 'تحميل PDF',
  'Prisoner Name': 'اسم السجين',
  'Prisoner Emirates ID': 'رقم هوية السجين',
  'Prisoner Mobile Number': 'رقم هاتف السجين',
  'View All Volunteer Programs': 'أعرض جميع برامج التطوع',
  'Event ID: ': 'رقم الحدث: ',
  'Event Name: ': 'اسم الحدث: ',
  'Hour Count: ': 'عدد الساعات: ',
  'To: ': 'الى: ',
  'From: ': 'من: ',
  'Can not pay more than project amount':
    'لا يمكن بالدفع بقيمة اكبر من مبلغ المشروع',
  'View all projects': 'عرض جميع المشاريع',
  'Share your project': 'شارك مشروعك',
  'Thank you for your contribution': 'شكرا لك على مساهمتك الكريمة',
  Add: 'إضافة',
  'Supplier Invoice Guide': 'ارشادات خدمة التوريد',
  'No Events Found': 'لا توجد أحداث',
  'No Project Shares Available': 'لا توجد مشاريع مشتركة متاحة',
  Course: 'دورة',
  'Event Attendance': 'حضور الحدث',
  'Events Join': 'الأحداث الانضمام',
  'Donate With Real Estate': 'التبرع بالعقارات',
  'Community Awareness links': 'روابط التوعية المجتمعية',
  'Donate Idea': 'تبرع بالفكرة',
  'DONATE YOUR IDEA': 'قم بالتبرع بفكرتك',
  'Donate Your Idea': 'قم بالتبرع بفكرتك',
  "Transform your ideas into a force for good! Share your vision and make a difference. Contribute your creative spark by filling out the form below. Let's collaborate to turn your ideas into an impactful reality. Your innovation matters – donate your idea today!":
    'قم بتحويل أفكارك إلى قوة إيجابية! شارك رؤيتك وأحدث تأثيرًا. ساهم بإشعال إبداعك عبر ملء النموذج أدناه. دعونا نتعاون لتحويل أفكارك إلى واقع ذو تأثير. إبداعك يهم - قدم فكرتك اليوم!',
  'Idea Title': 'عنوان الفكرة',
  'Write your Idea Description': 'اكتب وصف فكرتك',
  'Click to Upload File': 'أنقر لتحميل الملف',
  'Upload File': 'تحميل الملف',
  SEND: 'إرسال',
  'Please fill all fields': 'الرجاء ملء كل الحقول',
  'Your request has been sent successfully': 'تم إرسال طلبك بنجاح',
  'Upload File': 'رفع الملف',
  'Description of File': 'وصف الملف',
  'Allowed file types: pdf, png, jpeg, jpg':
    'أنواع الملفات المسموح بها: pdf، png، jpeg، jpg',
  'Add Attachments': 'إضافة مرفقات',
  'Are you sure you want to delete this file?':
    'هل أنت متأكد أنك تريد حذف هذا الملف؟',
  'Donate with Real Estate': 'تبرع بالعقارات',
  'Events Join': 'الانضمام إلى الفعاليات',
  'DONATE WITH REAL ESTATE': 'تبرع بالعقارات',
  'Unlock the power of giving through real estate! Your generous donation can make a lasting impact. Complete the form below to donate real estate and be a catalyst for positive change. Join us in building a better future together!':
    'فتح قوة العطاء من خلال العقارات! يمكن أن يكون تبرعك الكريم له تأثير دائم. قم بملء النموذج أدناه لتبرع بالعقارات وكن عامل تحفيز للتغيير الإيجابي. انضم إلينا في بناء مستقبل أفضل معًا!',
  'Phone Number  Eg:- 971 5X XXXXXXX': ' رقم الهاتف مثال : 9715',
  'EVENT TITLE': 'عنوان الحدث',
  'National Identity': 'الهوية الوطنية',
  'Document ID': 'معرف الوثيقة',
  'JOIN EVENT': 'إنضم للحدث',
  'Abu Dubai': 'أبوظبي',
  'Add Attachmnets': 'أضف ملحقات',
  'Emirates ID Eg:- 784 5X XXXXXXXXX': 'الهوية الإماراتية مثال : 784 5',
  'Events Attendance': 'حضور الأحداث',

  'Volunteer Details': 'تفاصيل التطوع',
  'Available courses': 'دوراتنا المتاحة',
  عربى: 'عربى',
  'Hello,': 'أهلًا،',
  Raised: 'وصل إلى',
  'About Us': 'من نحن',
  Goal: 'الهدف',
  'Donate Now': 'تبرع الآن',
  Home: 'الرئيسية',
  'Donate to': 'تبرع إلى',
  'Remove from Bag': 'أزل من الحقيبة',
  'One Time': 'مرة واحدة',
  'View All': 'رؤية المزيد',
  'Your request has been successfully submitted': 'لقد تم تقديم طلبك بنجاح',
  'Contact Us': 'تواصل معنا',
  Contact: 'تواصل',
  'Please Login to Continue': 'الرجاء تسجيل الدخول للمتابعة',
  'Forgot Account': 'نسيت الحساب',
  'Forgot Password': 'هل نسيت كلمة السر',
  'SIGN IN': 'تسجيل الدخول',
  'Create an Account': 'انشئ حسابًا',
  'Or Sign in with': 'أو تسجيل الدخول بواسطة ',
  'Emirates Red Crescent': 'الهلال الأحمر الإماراتي',
  Upload: 'إرفاق',
  'Choose a branch': 'اختر الفرع',
  'Hi, User': 'مرحبًا، المستخدم',
  Hi: 'مرحبًا',
  'My Sponsors': 'كفالاتي',
  'Be a Volunteer': 'كن متطوعًا',
  '5% VAT will be included for each service':
    'سيتم إضافة ٥٪ ضريبة القيمة المضافة لكل خدمة',
  'User Remarks': 'ملاحظات العضو',
  'ERC Remarks': 'ملاحظات الهلال الأحمر الإماراتي',
  'Edit Request': 'تعديل الطلب',
  'Please choose a related service': 'من فضلك اختر خدمة متعلقة',
  'Local Aid': 'المساعدات المحلية',
  'Recent Donation': 'التبرع الأخير',
  'Sponsor Profile': 'الملف الشخصي للكافل',
  Charity: 'صدقة',
  Projects: 'مشاريع',
  Donations: 'التبرعات',
  'Payment Method': 'طريقة الدفع أو السداد',
  Account: 'حساب',
  'Beneficiary No.': 'رقم المستفيد',
  'Date of Birth': 'تاريخ الولادة',
  Nationality: 'الجنسية',
  'Sponsor Date': 'تاريخ الكفالة',
  'Beneficiary Message': 'رسالة المستفيد',
  'Send Gifts/ Support': 'إرسال الهدايا / الدعم',
  Call: 'مكالمة',
  AED: 'د.إ',
  Pay: 'ادفع',
  'Add to Bag': 'أضف إلى الحقيبة',
  'Family Status': 'الوضع العائلي',
  'Educational Status': 'الحالة التعليمية',
  Supervisor: 'مشرف',
  'Support History': 'تاريخ الدعم',
  'Kindly select the Payment Option': 'يرجى تحديد خيار الدفع',
  'Select the Country': 'حدد البلد',
  'Monthly Payment': 'الدفع الشهري',
  'Full Payment': 'دفع كامل',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada.':
    'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي. و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟     علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم .',
  Register: 'تسجيل',
  'Please fill all the fields': 'الرجاء تعبئة جميع الحقول',
  'CREATE ACCOUNT': 'إنشاء حساب',
  'Humantarian Cases': 'الحالات الإنسانية.',
  'Donate Now': 'تبرع الآن',
  'Sukuk / Vouchers': 'الصكوك / القسائم',
  'Become a Sponsor': 'اكفل',
  Family: 'أسرة',
  Together: 'سويًا',
  Favorites: 'المفضلة',
  'Your information has been successfully added': 'تم إضافة معلوماتك بنجاح',
  'Please choose a valid date': 'من فضلك اختر تاريخ صحيح',
  'Please set a valid date!': 'من فضلك اختر تاريخ صحيح',
  'Profile Details': 'المعلومات الشخصية',
  'Save this method for future payments':
    'حفظ البطاقة لعمليات الدفع المستقبلية',
  'For recurring payments, The same amount will be automatically deducted on the same date':
    'للدفعات المتكررة، سيتم خصم المبلغ تلقائيًا في نفس ميعاد الدفع، سواء كان شهريًا أو يوميًا',
  'Help Build The World': 'نساعد في بناء العالم',
  'View All Charity Projects': 'عرض جميع المشاريع الخيرية',
  Providing: 'توفير',
  'Food for the Poor': 'الغذاء للفقراء',
  'Education Qualification': 'التخصص العلمي',
  'Relative registration': 'تسجيل الأقارب',
  'Select relation': 'اختر صلة القرابة',
  'Family name': 'اسم العائلة',
  'Address registration': 'تسجيل العنوان',
  'Please enter description': 'من فضلك اكتب الوصف',
  'Upload attachments': 'إرفاق الوثائق',
  ADD: 'إضافة',
  'Select Address': 'اختر العنوان',
  'Select Emirate': 'اختر الإمارة',
  'Once you sumbit your files, you will not be able to go back or add new information.':
    'بمجرد رفع الملفات لن تتمكن من العودة وإضافة معلومات جديدة',
  Emirate: 'الإمارة',
  'Step one': 'الخطوة الأولى',
  'Step two': 'الخطوة الثانية',
  'Step three': 'الخطوة الثالثة',
  'Step four': 'الخطوة الرابعة',
  'Step five': 'الخطوة الخامسة',
  'Final step': 'الخطوة الأخيرة',
  'Local Aid registration': 'التسجيل للمساعدات المحلية',
  Job: 'الوظيفة',
  'Tap to Donate': 'اضغط للتبرع',
  'Support Kids': 'دعم الأطفال',
  "Support Annjke's medical expenses": 'دعم النفقات الطبية في Annjke',
  'Read More': 'اقرأ أكثر',
  'Apple Pay': 'الدفع بواسطة أبل',
  SMS: 'رسالة قصيرة',
  Mosque: 'مسجد',
  Well: 'حسنًا',
  'Class Room': 'قاعة الدراسة',
  Ambulance: 'سيارة اسعاف',
  'Providing ': 'توفير ',
  Country: 'الدولة',
  'Haaj for Individual': 'حج للفرد',
  Cow: 'بقرة',
  'Building Construction': 'تشييد المباني',
  'Air Conditioner': 'مكيف هواء',
  'Project Description': 'وصف المشروع',
  'Digging a well that will cover a sub urban area':
    'حفر جيدا والتي ستغطي منطقة حضرية فرعية',
  'Donate now': 'تبرع الآن',
  'Add to bag': 'أضف إلى الحقيبة',
  'Donate by SMS': 'تبرع بالرسائل القصيرة',
  'Google Pay': ' الدفع بواسطة جوجل',
  Categories: 'الأقسام',
  'All categories': 'جميع الأقسام ',
  'Also, Enter The Verification Code You Received By Mobile':
    'أيضًا، يرجى إدخال رمز التفعيل المرسل عبر الهاتف',
  'Please Enter The Verification Code You Received By E-mail':
    'يرجى إدخال رمز التفعيل المرسل عبر البريد الإلكتروني',
  Sponsorships: 'الكفالة',
  Sukuk: 'الصكوك',
  'Humanitarian Cases': 'الحالات الإنسانية',
  '6 Months': 'ستة أشهر',
  Months: 'الشهور',
  'Months of renewal': 'أشهر التجديد:',
  Renewal: 'التجديد',
  '1 Year': 'سنة واحدة',
  '2 Years': 'سنتان',
  '3 Years': 'ثلاث سنوات',
  'Medical Suk': 'الصك الطبي',
  Daily: 'يوميًا',
  Monthly: 'شهريًا',
  Yearly: 'سنويًا',
  'My Bag': 'حقيبتي',
  'Donation Summary': 'ملخص التبرع',
  'Some elements have a value less than the minimum!':
    'بعض قيم التبرعات أقل من الحد الأدنى للتبرع',
  Profile: 'الملف الشخصي',
  'Email, Mobile, File ': 'البريد الإلكتروني، المحمول رقم الملف',
  Password: 'كلمة المرور',
  Name: 'الإسم بالكامل',
  Email: 'البريد الإلكتروني',
  'Confirm Password': 'تأكيد كلمة المرور',
  'Kindly Select the Payment Option': 'يرجى تحديد خيار الدفع',
  'Search for a donation..': 'البحث عن تبرع ..',
  'Winter Clothes': 'ملابس الشتاء',
  'Student desks': 'مكاتب الطلاب',
  'DONATE NOW': 'تبرع الآن',
  'Total Payments': 'إجمالي المدفوعات',
  "You can't send gifts if you have pending late payments":
    'لا يمكنك إرسال الهدايا طالما لديك مدفوعات متأخرة',
  'SEND A GIFT TO YOUR SPONSORS': 'ارسال هدية للمكفولين',
  'Total Donation': 'إجمالي التبرع',
  'Select the Project': 'حدد المشروع',
  'Establishment and international recognition': 'التأسيس والإعتراف الدولي',
  'Our course': 'دوراتنا',
  'Our objectives': 'أهدافنا',
  'Our Role': 'دورنا',
  'Fundamental Principles': 'المباديء الأساسية',
  'Our values': 'قيمنا',
  Services: 'الخدمات',
  'Volunteer now': 'تطوع الآن',
  'Sponsor an orphan': 'اكفل يتيمآ',
  'Sponsors portal': 'بوابة الكفالة',
  'Local aid portal': 'بوابة المساعدات المحلية',
  Recruitment: 'توظيف',
  'Donate now': 'تبرع الآن',
  'Need Help?': 'تحتاج إلى مساعدة؟',
  'Contact Us Now': 'تواصل معنا الآن',
  'Payment details': 'تفاصيل المدفوعات',
  Name: 'الإسم',
  'E-mail': ' البريد الإلكتروني',
  'The amount you selected is less than the intial amount':
    'القيمة التي اخترتها أقل من القيمة الأساسية للتبرع',
  Message: 'الرسالة',
  '@ ALL RIGHT RESERVED EMIRATES RED CRESCENT 2021':
    '@ جميع الحقوق محفوظة للهلال الأحمر الإماراتي 2021',
  Send: 'إرسال',
  Notifications: 'الإشعارات',
  'Search for a donation': 'ابحث في التبرعات',
  'Donation Frequency': 'عدد مرات التبرع',
  'Donation Amount': 'قيمة التبرع',
  'Search for a Human Case': 'ابحث عن حالة إنسانية',
  Support: 'ادعم',
  'Raised of': 'من أصل',
  Donors: 'متبرع',
  'Enter amount in AED': 'أدخل القيمة بالدرهم الإماراتي',
  'The Emirates Red Crescent Authority, in cooperation with Abu Dhabi Islamic Bank, in launching the first initiative of its kind, Sukuk Al-Khair are sustainable humanitarian financial instruments, The Authority invsts it and takes advantage of the investment profits to serve various humanitarian programs.':
    'تطلق هيئة الهلال الأحمر الإماراتي بالتعاون مع مصرف أبوظبي الإسلامي المبادرة الأولى من نوعها ، وهي صكوك الخير ، وهي أدوات مالية إنسانية مستدامة ، وتستثمر الهيئة فيها وتستفيد من أرباح الاستثمار لخدمة البرامج الإنسانية المختلفة.',
  'Be a Sponsor': 'اكفل',
  'Choose a Country': 'اختر دولة',
  'Total Amount:': 'المبلغ الكلي:',
  Like: 'أضف إلى المفضلة',
  Unlike: 'أزل من المفضلة',
  Countries: 'الدول',
  'Choose a Project': 'اختر مشروعًا',
  Orphans: 'أيتام',
  'You May Also Donate For...': 'يمكنك أيضًا التبرع بـ',
  'My Bag': 'حقيبتي',
  'Late Payment': 'المدفوعات المتأخرة',
  Total: 'القيمة الكلية',
  Payment: 'نظام الدفع',
  'Add All To Bag': 'أضف الكل إلى الحقيبة',
  Added: 'تمت الإضافة',
  Sponsorships: 'الكفالة',
  'Volunteering Service': 'خدمة التطوع',
  'Recent Donations': 'التبرعات الأخيرة',
  'Setup your information': 'قم بإعداد ملفك الشخصي',
  Submit: 'تقديم',
  'Add More': 'أضف المزيد',
  'National ID': 'الهوية الوطنية',
  Education: 'التعليم',
  Nationality: 'الجنسية',
  Mobile: 'رقم الهاتف المحمول',
  'Date of Birth': 'تاريخ الميلاد',
  Gender: 'الجنس',
  'Family Name': 'اسم العائلة',
  'Family Member Name': 'اسم فرد العائلة',
  Occupation: 'المهنة',
  'Martial Status': 'الحالة الإجتماعية',
  'Health Status': 'الحالة الصحية',
  Relatives: 'الأقارب',
  Requests: 'الطلبات',
  'Submit a request': 'تقديم طلب',
  'Please select the aid type': 'الرجاء تحديد نوع المساعدة',
  'Note / Request Details': 'ملاحظة / تفاصيل الطلب',
  'Submitting requests for students aids is only for one student':
    'تقديم طلبات المساعدات الطلابية لطالب واحد فقط',
  Note: 'ملاحظة',
  'Profile Number': 'رقم الملف',
  'Personal Information': 'المعلومات الشخصية',
  Qualifcation: 'المؤهلات',
  'Local Aid Requests': 'طلبات المساعدة المحلية',
  Pending: 'قيد الانتظار',
  Completed: 'مكتمل',
  Returned: 'مرتجع',
  'No Requests Found': 'لا توجد طلبات',
  'Request Number': 'رقم الطلب',
  Year: 'سنة',
  'Request Classification': 'تصنيف الطلب',
  'Volunteer Portal': 'بوابة المتطوع',
  'File Details': 'تفاصيل الملف',
  Participants: 'المشاركات',
  Attachments: 'المرفقات',
  'Training courses': 'دورات تدريبية',
  Languages: 'اللغات',
  Skills: 'المهارات',
  'Areas of interest': 'مجالات الإهتمام',
  Events: 'الأحداث',
  'E-services': 'الخدمات الإلكترونية',
  'Can not pay less than 5 AED': 'لا يمكنك التبرع بمبلغ أقل من ٥ درهم',
  'Please select from campaign elements': 'أنت لم تختر أي تبرع',
  'The element has a value less than the minimum!':
    'قيمة التبرع أقل من الحد الأدنى',
  'Please select an amount to donate': 'من فضلك اختر قيمة للتبرع',
  Branch: 'الفرع',
  Number: 'رقم',
  'Job ID': 'الهوية الوظيفية',
  'End Date': 'تاريخ الإنتهاء',
  'JOB LICENSE': 'رخصة العمل',
  'EDUCATION LEVEL': 'الدرجة العلمية',
  'Education Level': 'الدرجة العلمية',
  Major: 'التخصص',
  'Place of Issuance': 'مكان الإصدار',
  'Date of Issuance': 'تاريخ الإصدار',
  'Start Date': 'تاريخ البدء',
  'Document ID': 'هوية الملف',
  PASSPORT: 'جواز السفر',
  RESIDENCE: 'الإقامة',
  'NATIONALITY IDENTITY': 'الهوية الوطنية',
  'Training Courses': 'الدورات التدريبية',
  'Training Place': 'مكان التدريب',
  at: 'في',
  'Select Language': 'اختر اللغة',
  'Select Level': 'اختر المستوى',
  'You are': 'أنت',
  Skill: 'المهارة',
  Remarks: 'محلاظة',
  'Select Skill': 'اختر مهارة',
  'Skills and Interests': 'المهارات والإهتمامات',
  Reports: 'التقارير',
  'Send Gifts/Support': 'إرسال الهدايا / الدعم',
  'Renew Payments': 'تجديد المدفوعات',
  'Sponsor Date': 'تاريخ الكفالة',
  'Family status': 'الوضع العائلي',
  'Educational status': 'الوضع الدراسي',
  'Your bag is empty!': 'حقيبتك فارغة بالفعل!',
  Project: 'مشروع',
  Date: 'التاريخ',
  Time: 'الوقت',
  Renewals: 'التجديدات',
  Select: 'اختر',
  'Please select': 'من فضلك قم بالإختيار',
  'Total amount': 'القيمة الكلية',
  'Search for a country': 'البحث في الدول',
  'Please choose an orphan': 'من فضلك اختر يتيم للاستمرار',
  'Select all': 'اختر الكل',
  Close: 'إغلاق',
  'Payment amount per month': 'قيمة الدفع لكل شهر',
  "You don't have any sponsors": 'ليس لديك أية كفالات',
  'Payment amount': 'قيمة الدفع',
  'New Card': 'بطاقة جديدة',
  'Existed Card': 'بطاقة محفوظة',
  'Change Card': 'تغيير البطاقة',
  'needs your help': 'يحتاج إلى مساعدتك',
  'Your past payment for': 'مدفوعاتك الأخيرة لـ',
  'Could not complete the payment due to unexpected issue':
    'تعذر إتمام الدفع بسبب خطأ غير متوقع',
  'to support': 'لرعاية',
  'has been Declined': 'قد مضت',
  'Your full payment to support': 'مدفوعاتك الكاملة لرعاية',
  'is needed': 'منتظرة',
  'Add New Card': 'أضف بطاقة جديدة',
  Reciept: 'فاتورة',
  Type: 'النوع',
  Amount: 'القيمة',
  'Would you like to': 'هل تفضل',
  'Would you like to ': 'هل تفضل ',
  'using your existing UAE PASS details or link to an existing account?':
    'باستخدام تفاصيل تصريح دخول UAE PASS الحالية الخاصة بك أو الارتباط بحساب موجود؟',
  'Register using UAE PASS account': 'التسجيل باستخدام حساب UAE PASS',
  'Link existing account': 'ربط حساب متوفر في الهلال الاحمر',
  Logout: 'تسجيل الخروج',
  'Please enter your E-mail to continue':
    'الرجاء إدخال البريد الإلكتروني الخاص بك للمتابعة',
  email: 'البريد الإلكتروني',
  'Please enter the OTP': 'الرجاء إدخال كلمة المرور لمرة واحدة',
  OTP: ' كلمة المرور لمرة واحدة',
  'Your Account is not verified in UAE PASS. ':
    'لم يتم توثيق حسابك في UAE PASS. ',
  'Please verify your account on the UAE PASS application.':
    'يرجى توثيق حسابك في برنامج UAE PASS.',
  Register: 'التسجيل',
  'for easier donations in the future?': 'لتبرع أسهل في المستقبل؟',
  'Please choose a payment method': 'من فضلك اختر طريقة دفع',
  'Thank you for your donation': 'شكرًا لك على التبرع',
  'Back to app': 'العودة للتطبيق',
  'Not Now': 'ليس الآن',
  'Emirates ID already exists': 'رقم الهوية متواجد بالغعل',
  'Enter your new password': 'أدخل كلمة السر الجديدة',
  'Re-enter your new password': 'أعد إدخال كلمة المرور',
  "The two passwords don't match": 'كلمتا المرور غير متطابقتين',
  Yes: 'نعم',
  'E-mail, Mobile, File Number': 'البريد الإلكتروني، رقم الهاتف، رقم الملف',
  'Change your password': 'تغيير كلمة المرور',
  'Forgot Account?': 'نسيت الحساب؟',
  'Forgot Password?': 'نسيت كلمة السر؟',
  'Creact an Account': 'إنشاء حساب جديد',
  'Create Account': 'إنشاء حساب',
  'Mobile Number': 'رقم الهاتف',
  'Choose file': 'اختر ملف',
  'You have successfully submited your request':
    'لقد قمت بإعادة تقديم طلبك بنجاح',
  Description: 'الوصف',
  'Flat No.': 'رقم الشقة',
  Address: 'العنوان',
  'P.O Box': 'رقم البريد',
  'Emirates ID': 'الهوية الإماراتية',
  'SIGN UP': 'تسجيل',
  'Already have an account?': 'لديك حساب؟',
  'Or Sign up with': 'أو قم بالتسجيل بواسطة',
  'needs your next payment': 'يحتاج إلى الدفعة القادمة',
  'needs your help': 'يحتاج إلى مساعدتك',
  'Your full payment for': 'السداد الكامل الخاص بـ',
  'is has past the due date': 'قد مر عليه الموعد',
  'You havent made the payment to support him in':
    'لم تتمكن من سداد مبلغ الرعاية الخاصة به لمدة',
  Menu: 'القائمة',
  'Log Out': 'تسجيل الخروج',
  ENG: 'الإنجليزية',
  UAE: 'العربية',
  'Please Check Your Mail For The Verification':
    'يرجى التأكد من البريد الإلكتروني لتفعيل الحساب',
  Welcome: 'مرحبا بك!',
  "But If You Didn't Recieve It, Click Here.":
    'ولكن إن لم يصل إليك بريد التفعيل يرجى الضغط هنا.',
  'This E-mail Exists, Please Log In':
    'هــذا البريد الإلكتروني متواجد، يرجى تسجيل الدخول.',
  'Please Enter The Verification Code You Received By E-mail/Mobile':
    'يرجى إدخال رمز التفعيل الذي استلمته من خلال البريد الإلكتروني/الهاتف',
  Campaign: 'اسم الحملة',
  'User Not Found': 'الحساب غير موجود',
  'Wrong Password': 'كلمة السر غير صحيحة',
  'Incorrect email/password is entered':
    'تم إدخال بريد إلكتروني / كلمة مرور غير صحيحة',
  'OTP not Verified': 'لم يتم تأكيد رمز التحقق',
  'Please insert the OTP': 'من فضلك قم بإدخال رمز التحقق',
  'Please Enter Your E-mail To Get a Verification Code.':
    'يرجى إدخال البريد الإلكتروني لاستلام رمز التفعيل.',
  'The Verification Code Is Wrong': 'رمز التفعيل الـذي أدخلته ربما يكون خاطئًا',
  'Log In': 'تسجيل الدخول',
  Media: 'الوسائط',
  'Donation History': 'التبرعات السابقة',
  'Please Enter Your Emirates ID To Get Your Account.':
    'يرجى إدخال رقم الهوية الإماراتية.',
  'Please Enter Your Emirates ID/Sponsor ID To Get Your Account.':
    'يرجى إدخال رقم الهوية الإماراتية / هوية الكفالة، للبحث عن حسابك',

  'Here Is Your Account': 'إليك معلومات الحساب',
  Relation: 'الصلة',
  'Hour Count': 'عدد الساعات',
  'Select Course': 'الدورات التدريبية',
  'Select Country': 'اختر دولة',
  'Select Program': 'اختر البرنامج',
  'Event Date': 'تاريخ الحدث',
  'Event Title': 'عنوان الحدث',
  'Event Description': 'الوصف',
  'Event Location': 'الموقع',
  'Starts At': 'يبدأ في تاريخ',
  'Ends At': 'ينتهي في تاريخ',
  From: 'من الساعة',
  To: 'إلى الساعة',
  'Select Related Service': 'اختر خدمة متعلقة',
  Cost: 'التكلفة',
  Checkout: 'الدفع',
  'We are reviewing your ': 'نحن نعمل على مراجعة',
  'request for creating Local Aid account, ':
    'طلبك الخاص بإنشاء حساب المساعدات المحلية، ',
  'Please wait.': 'يرجى الانتظار.',
  'request for creating Volunteer Service account, ':
    'طلبك الخاص بإنشاء حساب لخدمات التطوّع',
  'Volunteer name in Arabic': 'اسم المتطوع باللغة العربية',
  'Volunteer name in English (As shown in Emirates ID)':
    'اسم المتطوع باللغة الإنجليزية (كما هو مطبوع في الهوية الإماراتية)',
  'Work Place ID': 'معرف مكان العمل',
  'Contact Language': 'لغة التواصل',
  'Complete Your Personal Information': 'أكمل معلوماتك الشخصية',
  'Enter your mobile number': 'أدخل رقم هاتفك',
  'Charity projects': 'مشاريع خيرية',
  'Add Credit/Debit Card': 'إضافة بطاقة ائتمان / خصم',
  'Save and pay (only for this time)': 'حفظ البطاقة والدفع (لهذه المرة فقط)',
  Next: 'متابعة',
  for: 'ل',
  'List of Locations': 'قائمة المواقع',
  'Please complete your information': 'من فضلك قم بإكمال معلوماتك',
  English: 'الإنجليزية',
  Arabic: 'العربية',
  'Select contact language': 'اختر لغة التواصل',
  'Pay for delivery': 'ادفع للتوصيل',
  'Select your gender': 'اختر الجنس',
  Female: 'أنثى',
  Male: 'ذكر',
  'Select Branch': 'اختر الفرع',
  Qualification: 'المؤهل',
  "It seems you don't have a ": 'يبدو أنك لا تمتلك',
  'Local Aid account': 'حساب خاص بالمساعدات المحلية،',
  'Volunteer account': 'حساب خاص بخدمات التطوّع',
  ', so we recommend to create one.': 'لـذلك نحن ندعوك لإنشاء حساب جديد',
  'Create account': 'إنشاء حساب',
  'Select Martial Status': 'اختر الحالة الإجتماعية',
  'Volunteer name': 'اسم المتطوع',
  'Upload your picture': 'قم بإرفاق صورتك الشخصية',
  'Insert your Job details': 'قم بإدخال معلوماتك الوظيفية',
  'Upload your Job License': 'إرفق صورة للرخصة الوظيفية',
  'Upload your Attachment': 'إرفق صورة التوثيق',
  'Insert your Education details': 'قم بإدخال معلوماتك الدراسية',
  'Upload your Educational License': 'إرفق صورة للشهادة التعليمية',
  'Insert your National details': 'قم بإدخال معلومات الهوية الوطنية',
  'Upload your National ID': 'قم بإرفاق صورة الهوية الوطنية',
  'Accounts settings': 'إعدادات الحسابات ذات الصلة',
  'Profile settings': 'إعدادات الحساب الشخصي',
  'Payment Method settings': 'إعدادات طرق الدفع والسداد',
  'Language settings': 'إعدادات اللغة',
  'Chat with Us': 'تحدث معنا',
  Settings: 'الإعدادات',
  'About us': 'من نحن',
  'Arm of the United Arab Emirates for humanitarian work':
    'ذراع الإمارات العربية المتحدة للعمل الإنساني',
  'What distinguishes us': 'ما يميزنا',
  'Our Partners': 'شركاؤنا',
  'Our Locations': 'فروعنا',
  'UAE Red Crescent Authority was established on 31/01/1983 and gained the international recognition as a member No.: 139 of the International Federation of Red Crescent and Red Cross on 08/27/1986. His Highness Sheikh Hamdan Bin Zayed Al Nahyan took the position of the Chairman of the Board of the Red Crescent in 1986, and then took the position of the Chairman of the Authority in 1993, which gave impetus to the efforts of the human Authority. In 1997, the role of the Authority had been enhanced by the acceptance of Her Highness Sheikha Fatima bint Mubarak, for the position of the honorary president of the UAE Red Crescent. In 2001, the UAE Red Crescent had been selected as the second best humanitarian Autjority at the level of the continent of Asia.':
    'تأسست هيئة الهلال الأحمر لدولة الإمارات العربية المتحدة في 1983/1/31 ونالت الاعتراف الدولي باعتبارها العضو رقم 139 في الاتحاد الدولي لجمعيات الهلال الأحمر والصليب الأحمر بتاريخ 1986/8/27. تولى سمو الشيخ حمدان بن زايد آل نهيان، منصب رئيس مجلس إدارة الهلال الأحمر عام 1986 ثم رئيساً للهيئة عام 1993 مما أعطى قوة دافعة لجهود الهيئة الإنسانية . وفي عام 1997 تعزز دور الهيئة بقبول سمو الشيخة فاطمة بنت مبارك لمنصب الرئيسة الفخرية للهلال الأحمر الإماراتي . وفي عام 2001 تم اختيار الهلال الأحمر الإماراتي ثاني أفضل هيئة إنسانية على مستوى قارة آسيا ....',
  'Your name in Arabic': 'اسمك باللغة العربية',
  'Insert your Residence details': 'قم بإدخال معلومات الإقامة',
  'Upload your Residence ID': 'قم بإرفاق صورة الإقامة',
  'Residence ID': 'رقم الإقامة',
  'Insert your Passport details': 'قم بإدخال معلومات جواز السفر',
  'Upload your Passport ID': 'قم بإرفاق صورة جواز السفر',
  'Passport ID': 'رقم جواز السفر',
  'Insert your Training Courses': 'قم بإدخال دوراتك التدريبية',
  'I have an account': 'لدي حساب بالفعل',
  Confirm: 'تأكيد',
  'Please Enter Your E-mail and Your Emirates ID To Get Your Account.':
    'يرجى التأكد من البريد الإلكتروني والهوية الوطنية',
  "You don't have a Volunteer Account": 'أنت لا تمتلك حساب لخدمات التطوّع',
  'Grace conservation': 'حفظ النعمة',
  'Grace Conservation': 'حفظ النعمة',
  'Live chat': 'دردشة مباشرة',
  Max: 'الحد الأقصى',
  'Search for a project': 'البحث عن مشروع',
  "You don't have pending payments": 'ليس لديك أية مدفوعات في الإنتظار',
  'Get Direction': 'احصل على اتجاه',
  Alphabetically: 'أبجديًا',
  'Project No': 'رقم المشروع',
  'Project Name': 'اسم المشروع',
  'Total Cost': 'التكلفة الكلية',
  'Country Name': 'الدولة',
  'Project Year': 'سنة المشروع',
  'Work in Percentage': 'العمل بالنسبة المئوية',
  'The report has been sent to your email successfully!':
    'لقد تم إرسال التقرير إلى البريد الإلكتروني بنجاح',
  'Could not send to your email. Please check your email account or contact support!':
    'لم نتمكن من إرسال التقرير عبر البريد الإلكتروني الخاص بكم، يرجى التحدث مع الدعم الفني، أو المحاولة في وقت لاحق',
  'Low amount to high amount': 'من الأقل إلى الأعلى',
  'High amount to low amount': 'من الأعلى إلى الأقل',
  Sorting: 'الترتيب',
  Filter: 'تصفية',
  Done: 'تم',
  Back: 'العودة',
  'Date of birth': 'تاريخ الميلاد',
  'Sponsor date': 'تاريخ الكفالة',
  'Report date': 'تاريخ التقرير',
  'Beneficiary message': 'رسالة المستفيد',
  'Beneficiary number': 'رقم المستفيد',
  'Family situation': 'الوضع العائلي',
  'Educational background': 'الوضع التعليمي',
  'Number of sisters': 'عدد الأخوات',
  'Number of brothers': 'عدد الإخوان',
  'Is the mother alive': 'هل الأم على قيد الحياة',
  "Parent's name": 'اسم ولي الأمر',
  'Is Studying': 'يدرس',
  'Relationship with parent': 'العلاقة مع ولي الأمر',
  'School name': 'اسم المدرسة',
  'Education level': 'المستوى التعليمي',
  'Education Type': 'نوع التعليم',
  'Education Stage': 'مرحلة التعليم',
  'Supervisor message': 'رسالة المشرف',
  'Family income': 'مدخول العائلة',
  'SEND A GIFT TO YOUR SPONSOR': 'أرسل هدية إلى المكفول الخاص بك',
  'Enter Amount': 'أدخل المبلغ',
  'Enter the message here': 'أدخل الرسالة هنا',
  'Send Now': 'أرسل الآن',
  'Send a Gift': 'أرسل هدية',
  Account: 'حساب',
  'Sponsor ID': 'هوية الكفالة',
  'Please enter your information to continue':
    'الرجاء إدخال المعلومات الخاصة بك للمتابعة',
  'N/A': 'غير متوفر',
  'Identity type': 'نوع الهوية',
  'Identity number': 'رقم الهوية',
  'I agree to all terms and conditions of the sponsorship':
    'أوافق على كافة الشروط والأحكام الخاصة بالكفالة',
  "You Can't continue without accepting sponsorship terms and conditions":
    'لا يمكنك المتابعة دون الموافقة على الشروط والأحكام الخاصة بالكفالة',
  'TERMS AND CONDITIONS OF SPONSORSHIP': 'الشروط والأحكام الخاصة بالكفالة',
  Remarks: 'ملاحظات',
  'Samsung Pay': 'الدفع بواسطة سامسونج',
  'Edit Profile': 'تعديل الملف الشخصي',
  'No DOB available': 'لا يوجد تاريخ ميلاد متوفر',
  'No Payment Method Found': 'لا توجد أي وسيلة للدفع',
  'Purchasing Committees Accreditation Portal': 'بوابة اعتماد لجان المشتريات',
  'Portal of aid and accreditation of committees':
    'بوابة المساعدات و إعتماد اللجان',
  'Sponsorships and Orphans Affairs Portal': ' بوابة الكفالات و شؤون الأيتام',
  'External project management system': 'نظام إدارة المشاريع الخارجية',
  'Internal Services': 'خدمات داخلية',
  'Supplier Services': 'خدمات الموردين',
  'Supplier login': 'تسجيل دخول لمورد',
  'Register a new supplier': 'تسجيل مورد جديد',
  'Choose a language': 'اختر لغة',
  Save: 'حفظ',
  'Do you want to delete this method?': 'هل تريد إزالة تلك البطاقة',
  'Enter Emirates ID related to volunteer account':
    'أدخل الهوية الإماراتية المتعلقة بحساب المساعدات المحلية',
  'language already exists in user profile': 'لقد قمت بإضافة تلك اللغة بالفعل',
  Cancel: 'تراجع',
  'Volunteer ID': 'هوية المتطوع',
  'Change mobile number': 'تغيير رقم الجوال',
  'Enter your phone number': 'أدخل رقم هاتفك',
  'Enter your phone number here': 'أدخل رقم هاتفك هنا',
  'Verify your OTP': 'تأكيد رمز التحقق الخاص بك',
  'Enter the OTP here': 'أدخل رمز التحقق هنا',
  'Enter your emirates ID': 'أدخل رقم هويتك الإماراتية',
  'Please enter your name': 'من فضلك أدخل اسمك',
  'Building Name': 'اسم المبنى',
  'Phone Number': 'رقم الهاتف',
  'Phone number has been edited succesfully': 'تم تعديل رقم الهاتف بنجاح',
  'Something went wrong...!': 'حدث خطا ما، يرجى المحاولة لاحقًا...!',
  'Beneficent Portal': 'بوابة المحسنين',
  'Login Failed: Pop-up closed by user!': '!فشل تسجيل الدخول',
  'The Email/Mobile Number/EID is already linked':
    'تم ربط البريد الإلكتروني / رقم الهاتف المحمول / رقم الهوية بالفعل',
  'Email does not exist': 'البريد الإلكتروني غير موجود',
  'Sent!': 'تم الإرسال!',
  'Please enter your number': 'من فضلك أدخل رقم هاتفك',
  'Please enter your name': 'من فضلك أدخل اسمك',
  'Error while creating new local AID':
    'حدث خطأ أثناء إنشاء حسابك الخاص بالمساعدات المحلية',
  'Passwords do not match!': 'كلمة السر لا تتطابق',
  'All fields are mandatory!': 'هناك بعض الحقول الفارغة',
  'Email format is not correct!': 'هناك خطأ في صيغة البريد الإلكتروني',
  'Please choose amount to add to bag':
    'من فضلك اختر قمية لتتم الإضافة إلى الحقيبة',
  'Please enter a valid donation!': 'من فضلك أدخل قيمة صحيحة للتبرع',
  'Please enter your Emirates ID ': 'من فضلك أدخل رقم الهوية الوطنية',
  'Min value is less than or equal Max!':
    'الحد الأدنى أكبر من الحد الأقصى أو يساويه ',
  'Please Select:': 'من فضلك قم بالإختيار:',
  'Samsung Pay': 'الدفع بواسطة سامسونج',
  'Success!': 'تم بنجاح ',
  Min: 'الحد الأدنى',
  'No recent donations found!': 'ليس لديك أية تبرعات مؤخرًا!',
  'Minimum Value': 'الحد الأدنى',
  'Select project type': 'اختر المشروع',
  All: 'الجميع',
  'CONTACT INFO': 'معلومات التواصل',
  'You can contact us any way that is convenient for you. We are available 24/7 via fax,email or telephone. You can also use a quick contact form on the right or visit our office personally. Email us with any questions or inquiries or use our contact data. We would be happy to answer your inquiries':
    'يمكنك التواصل معنا بأي طريقة تفضلها، نحن متاحون طوال الأوقات، من خلال الفاكس، البريد الإلكتروني، والهاتف، كما يمكنك التواصل معنا من خلال استمارة التواصل..أرسل لنا رسالة، بما تود أن تستفسر عنه، وسنكون سعداء بالرد عليك.',
  'Emirates Red Crescent': 'الهلال الأحمر الإماراتي',
  'Abu Dhabi': 'أبو ظبي',
  'Zayed Sports City': 'مدينة زايد الرياضية',
  'PO Box': 'صندوق بريد',
  'Abu Dhabi-United Arab Emirates': 'أبو ظبي-الإمارات العربية المتحدة',
  'Customer Service': 'خدمة العملاء',
  'CONTACT FORM': 'استمارة التواصل',
  'Select issue type': 'اختر مسألة التواصل',
  'Full Name...': 'الاسم الكامل',
  'Mobile Number...': 'رقم الهاتف',
  'E-mail...': 'البريد الإلكتروني',
  'Subject...': 'الموضوع',
  'Message...': 'الرسالة',
  'Loading...': 'معالجة',
  'SUBMIT A REQUEST': 'إتمام الطلب',
  'Total Late Payments': 'إجمالي المدفوعات المتأخرة',
  'Late Payments': 'المدفوعات المتأخرة',
  'Add to favorites': 'أضف إلى المفضلة',
  'Remove from favorites': 'أزل من المفضلة',
  'Copied!': 'تم النسخ',
  'Copy the Link': 'انسخ الرابط',
  'Donate SMS': 'الدفع بالرسائل النصية',
  'No notifications': 'لا توجد إشعارات',
  Receipt: 'الفاتورة',
  'Grace Request': 'طلب سماح',
  'Enter your name here': 'أدخل اسمك هنا',
  'Enter your mobile number here': 'أدخل رقم هاتفك هنا',
  'Select your grace type': 'اختر نوع الخدمة',
  'Choose Branch': 'حدد الفرع',
  'Pick up Date': 'حدد التاريخ',
  'Pay Now': 'ادفع الآن',
  'Loading...': 'تحميل...',
  'Save Card': 'حفظ البطاقة',
  'Cardholder Name': 'اسم حامل البطاقة',
  'Card Number': 'رقم البطاقة',
  'Save and pay': 'حفظ البطاقة والدفع',
  Pay: 'دفع',
  'Save only for this time': 'احفظ فقط لهذه المرة',
  'for this payment only': 'لهذه المرة فقط',
  'Bag Items': 'الحقيبة',
  'Mobile Number...': 'رقم الهاتف',
  'E-mail...': 'البريد الإلكتروني',
  'Subject...': 'الموضوع',
  'Message...': 'الرسالة',
  'Loading...': 'معالجة',
  'SUBMIT A REQUEST': 'إتمام الطلب',
  'Total Late Payments': 'إجمالي المدفوعات المتأخرة',
  'Add to favorites': 'أضف إلى المفضلة',
  'Remove from favorites': 'أزل من المفضلة',
  'Copied!': 'تم النسخ',
  'Copy the Link': 'انسخ الرابط',
  'Donate SMS': 'الدفع بالرسائل النصية',
  'No notifications': 'لا توجد إشعارات',
  Receipt: 'الفاتورة',
  'Grace Request': 'طلب سماح',
  'Enter your name here': 'أدخل اسمك هنا',
  'Enter your mobile number here': 'أدخل رقم هاتفك هنا',
  'Select your grace type': 'اختر نوع الخدمة',
  'Choose Branch': 'حدد الفرع',
  'Pick up Date': 'حدد التاريخ',
  'Copy link': 'نسخ الرابط',
  'Pay Now': 'ادفع الآن',
  'Loading...': 'تحميل...',
  Search: 'بحث',
  Services: 'الخدمات',
  'Al Ghadeer UAE Crafts': 'الغدير للحرف الإماراتية',
  'Select payment Method': 'اختر وسيلة الدفع',
  'Fill out this field': 'لا يمكن أن يكون هذا النص فارغًا',
  "You Don't Have Apple Pay": 'ليس لديك حساب على أبل باي',
  'Please add a Card in your Apple Pay Wallet!':
    'من فضلك قم بإضافة بطاقة إلى حافظة أبل باي',
  '"Mobile number" should be 10 digits long and start with "05"':
    'رقم الهاتف يجب أن يكون ١٠ أرقام ويبدأ ب ٠٥',
  '"Emirates ID" number should be 15 digits long':
    'رقم الهوية الإماراتية يجب أن يكون ١٥ رقمًا',
  'Your full payment for': 'لم تقم بالدفع منذ',
  Yes: 'نعم',
  'You havent made the payment to support him since': 'أنت لم تقم بالدفع منذ',
  '"Password" length must be 8-20 characters long and contain':
    'يجب ألا تقل كلمة السر عن ٨ ولا تزيد عن ٢٠. ويجب ان تحتوي على',
  'at least 1 uppercase letter': 'حرف كبير',
  'at least 1 lowercase letter': 'حرف صغير',
  'at least 1 numeric character': 'رقم',
  'at least 1 special character': 'رمز',
  'Please Enter The New Password': 'من فضلك قم بإدخال كلمة السر الجديدة',
  'Can not find sponser account related to this number':
    'لا يوجد حساب رعاية بهذا الرقم',
  'Are you sure you want to remove all contents of the bag?':
    'هل أنت متأكد من رغبتك في إزالة جميع محتويات الحقيبة؟',
  Added: 'تمت الإضافة',
  Removed: 'تمت الإزالة',
  'Logged in as': 'تم تسجيل دخولك بواسطة',
  'Login Failed!': 'خطأ في تسجيل الخروج',
  'Logout Failed!': 'فشل في تسجيل الدخول',
  'Ramadan Tent': 'خيم رمضان',
  Ataya: 'عطايا',
  Sponsors: 'كفالات',
  'Sponsor Donation': 'تبرعات الكفالة',
  'My Projects': 'مشاريعي',
  'My Sponsors': 'كفالاتي',
  Projects: 'مشاريع',
  'Projects Donation': 'تبرعات المشاريع',
  'Total Donation': 'إجمالي التبرعات',
  'Please check your credentials and try again.':
    'من فضلك تحقق من المعلومات، وحاول مجددًا',
  'No Favorites Found': 'لا يوجد شيء في المفضلة',
  'List View': 'القائمة',
  'Map View': 'الخريطة',
  'No reports found': 'لا توجد تقارير',
  "Please select a report to view it's details":
    'من فضلك اختر تقرير لمشاهدة التفاصيل',
  'No Projects found': 'لا توجد مشاريع',
  'Project Details': 'تفاصيل المشروع',
  'Our Partners': 'شركاؤنا',
  Payments: 'المدفوعات',
  'No renewals available for this orphans': 'لا تجديدات متاحة',
  'Volunteer Register': 'تسجيل متطوع',
  'Local Aid Profile': 'الصفحة الشخصية',
  'Local Aid Registration': 'تسجيل المساعدات المحلية',
  Delete: 'إزالة',
  Identity: 'الهوية',
  Accounts: 'حسابات',
  'Add Account': 'أضف حسابًا',
  'Our Mission': 'مهمتنا',
  'Our Vision': 'رؤيتنا',
  'No donations found': 'لا توجد تبرعات',
  News: 'الأخبار',
  Gallery: 'المعرض',
  'Social Media': 'التواصل الإجتماعي',
  'Payment Methods': 'طرق الدفع',
  'No countries found': 'لا توجد دول',
  'No projects found': 'لا توجد مشاريع',
  'You now have ': 'أنت لديك الآن ',
  ' in your Bag': ' في حقيبتك',
  Quantity: 'العدد',
  'OTP did not match': 'رمز التحقق لا يتشابه',
  'Sponsor already added': 'الكافل متواجد بالفعل',
  'Added Extra sponsor': 'تمت إضافة كافل',

  'One Time': 'مرة واحدة',
  Months: 'أشهر',
  'You have ': 'أنت لديك ',
  PAY: 'ادفع',
  'There is no order with this MCO': 'لا توجد عملية دفع بهذا الرقم',
  'Added!': 'تمت الإضافة!',
  'Removed!': 'تمت الإزالة!',
  '1 Years': 'سنة واحدة',
  '1.5 Years': 'سنة ونصف',
  'Modified!': 'تم التعديل!',
  '"E-mail" field cannot be empty!':
    'الحقل الخاص بالبريد الالكتروني لا يمكن أن يكون فارغًا',
  '"Emirates ID" field cannot be empty!':
    'الحقل الخاص بالهوية الإماراتية لا يمكن أن يكون فارغًا',
  'Donation completed': 'إكتمل جمع التبرعات',
  'This human Case is complete': 'الحالة الإنسانية مكتملة',
  'Thanks for your Registration': 'شكرًا لتسجيلك',
  'We are now reviewing your ': 'نعمل حاليًا على مراجعة',
  'Insert your Interests': 'قم بإدخال اهتماماتك',
  'Insert all Languages you have': 'قم بإدخال اللغات التي تتقنها',
  'Insert your Skills': 'قم بإدخال مهاراتك',
  'Enter Emirates ID related to volunteer account':
    'أدخل الهوية الإماراتية المتعلقة بحساب المتطوع',
  'Please select a service': 'من فضلك اختر خدمة',
  'Please choose a date': 'من فضلك اختر تاريخ',
  'Choose amount to donate': 'اختر قيمة للتبرع',
  'Insert Identity details': 'قم بإدخال معلومات التعريف ',
  'Choose your provider': ' اختر مزود الخدمة',
  'Aid type': 'نوع المساعدة',
  'Insert Identity details': 'قم بإدخال معلومات التعريف ',
  'Choose your provider': ' اختر مزود الخدمة',
  'Aid type': 'نوع المساعدة',
  Share: 'مشاركة',
  "You don't have any items in your bag": 'ليس لديك أية أغراض في الحقيبة',
  'Enter your name': 'أدخل اسمك',
  'Maximum Value': 'القيمة الكلية',
  "You don't have any items in late payments": 'ليس لديك أية مدفوعات متأخرة',
  'Enter the mobile number ': 'أدخل رقم الهاتف',
  'Enter the mobile number here': 'أدخل رقم الهاتف هنا',
  'OTP SEND': 'إرسال رمز التحقق',
  Back: 'العودة',
  '"Name" field cannot be empty!': 'الحلق الخاص بالإسم لا يمكن أن يكون فارغًا',
  '"Password" field cannot be empty!':
    'الحقل الخاص بكلمة المرور لا يمكن أن يكون فارغًا',
  'Invalid Mobile number': 'رقم هاتف محمول خاطئ',
  'Invalid E-mail address.': 'بريد إليكتروني خاطئ',
  'Invalid E-mail, Mobile or File number.':
    'Invalid E-mail, Mobile or File number.',
  '"Mobile number" cannot contain special characters':
    'حقل هاتف المحمول لا يجب أن يحتوي أحرف أو رموز',
  'John Smith': 'John Smith',
  '"Full Name" field cannot be empty!':
    'الحقل الخاص بالإسم لا يمكن أن يكون فارغًا',
  '"Full Name" length should be 2-60 characters long.':
    'لا يجب أن يقل الإسم عن حرفين، ولا يزيد عن 60 حرفًا',
  'Please add your name.': 'من فضلك أضف اسمك',
  'Please select "Issue type".': 'من فضلك اختر نوع المشكلة',
  'Please add your mobile number.': 'من فضلك أضف رقم هاتفك',
  'No news found': 'لا توجد أخبار',
  'Invalid mobile number format.': 'صيغة الهاتف المحمول خاطئة.',
  'Please add your E-mail address.': 'من فضلك أضف بريدك الإلكتروني.',
  'Valid format: "someone@example.com"': 'Valid format: "someone@example.com"',
  '"Subject" field cannot be empty!':
    'الحقل الخاص بالموضوع لا يمكن أن يكون فارغًا',
  'Please describe your issue in the "Content" section':
    'من فضلك صف مشكلتك في القسم الخاص بالـمحتوى',
  'Submitting...': 'تقديم...',
  'Thank you for contacting us! Your request will be reviewed shortly.':
    'شكرًا لك على التواصل، سيتم مراجعة طلبك قريبًا',
  'This number is already active': 'هذا الرقم مفعل بالفعل',
  'Local Aid Registration is currently unavailable':
    'تسجيل المساعدة المحلية مغلق حاليا',
  'Upload attachments and agreement': 'تحميل المرفقات والاتفاقية',
  'Click Here to Download the Agreement': 'انقر هنا لتنزيل الاتفاقية',
  'Download Agreement': 'قم بتنزيل الاتفاقية',
  'For non-volunteer service click here': 'لخدمة غير المتطوعين انقر هنا',
  'Volunteer Satisfaction and Happiness Form': 'اﺳﺘﻤﺎرة رﺿﺎ وﺳﻌﺎدة اﻟﻤﺘﻄﻮﻋﻴﻦ',
  Login: 'تسجيل الدخول',
  'Your account is unverified. Please upgrade your account following instructions on the UAE PASS app':
    'حسابك غير موثق. يرجى ترقية حسابك حسب التعليمات على تطبيق الهوية الرقمية',
  'User cancelled the login': 'قام المستخدم بإلغاء تسجيل الدخول',
  'Summer Course Registration Form': 'تسجيل التدريب الصيفي',
  'Successfully added a request': 'تمت إضافة طلب بنجاح',
  'Number of Orphans': 'عدد الأيتام',
  'Amount to send': 'المبلغ المراد إرساله',
  'Select Account(s) to link': 'اختر الحساب (الحسابات) للربط',
  'Are you sure you wish to update the monthly amount donated to the orphan?':
    'هل أنت متأكد من رغبتك في تحديث المبلغ الشهري المتبرع لليتيم؟',
  No: 'لا',
  'Country of Residence': 'بلد الإقامة',
  'Training Courses ': 'تسجيل التدريب العملي',
  'Bank Payment': 'الدفع المصرفي',
  'Transaction History': 'تاريخ المعاملات',
  Instructions: 'تعليمات',
  'Family Members': 'عدد الاسرة',
  'Study Type': 'نوع التعليم',
  'Health Condition': 'الحالة الصحية',
  'Needs Medicine': 'يحتاج لعلاج',
  'Sickness Name': 'اسم المرض',
  Hobbies: 'الهوايات',
  Needs: 'الاحتياجات',
  'Health Background': 'الوضع الصحي',
  History: 'السجل',
  'Project Share': 'مشاركة المشروع',
  of: 'من',
  'Project Shares': 'مشاركة المشاريع',
  'Create Project': 'أنشئ مشروعًا',
  Sort: 'الترتيب',
  'Clear All': 'مسح الكل',
  'Search by name or id...': 'البحث بالاسم او الرقم',
  Reason: 'السبب',
  'Max File Size: ': 'اكبر حجم للملف: ',
  'Max Number of files: ': 'اكبر عدد من الملفات: ',
  'Would you like to share this project publicly?':
    'هل ترغب في مشاركة هذا المشروع بشكل عام؟',
  'Sharing the project to public means the project will be visible on Emirates Red Crescent where others can donate to complete the total project amount':
    'تعني مشاركة المشروع بشكل عام أن المشروع سيكون مرئيًا على الهلال الأحمر الإماراتي حيث يمكن للآخرين التبرع لإكمال المبلغ الإجمالي للمشروع',
  'Yes, Make it Public': 'نعم، اجعله عام',
  'No, Make it Private': 'لا، اجعله خاص',
  'Create New Project': 'إنشاء مشروع جديد',
  'day(s) remaining': 'الايام المتبقية ',
  'Pay remaining': 'دفع المتبقي',
  'Enter name for project please': 'الرجاء إدخال اسم للمشروع',
  'Please approve the terms and conditions':
    'الرجاء الموافقة على الشروط والاحكام',
  'I hereby accept the ': ' أنا أوافق على ',
  'terms and conditions': 'الشروط والاحكام',
  'My Shared Projects': 'مشاريعي المشتركة',
  'Donation amount must be at least AED ':
    'يجب ألا يقل مبلغ التبرع بالدرهم الإماراتي ',
  'Whatsapp Number': 'رقم الواتس اب',
  'Place of Work': 'مكان العمل',
  'Marital Status': 'الحالة الاجتماعية',
  'View the': 'راجع',
  'terms document': 'وثيقة الشروط',
  'for your reference': '',
  '*JPG format': 'ملف JPG',
  'Upload your National ID (Front)': 'قم بتحميل هويتك الوطنية (امامي)',
  'Upload your National ID (Back)': 'قم بتحميل هويتك الوطنية (خلفي)',
  'Go Back': 'الرجوع',
  'Sign the PDF, and re-upload': 'وقّع على ملف PDF وأعد تحميله',
  'Download PDF': 'تحميل PDF',
  'Prisoner Name': 'اسم السجين',
  'Prisoner Emirates ID': 'رقم هوية السجين',
  'Prisoner Mobile Number': 'رقم هاتف السجين',
  'View All Volunteer Programs': 'أعرض جميع برامج التطوع',
  'Event ID: ': 'رقم الحدث: ',
  'Event Name: ': 'اسم الحدث: ',
  'Hour Count: ': 'عدد الساعات: ',
  'To: ': 'الى: ',
  'From: ': 'من: ',
  'Can not pay more than project amount':
    'لا يمكن بالدفع بقيمة اكبر من مبلغ المشروع',
  'View all projects': 'عرض جميع المشاريع',
  'Share your project': 'شارك مشروعك',
  'Thank you for your contribution': 'شكرا لك على مساهمتك الكريمة',
  Add: 'إضافة',
  'Supplier Invoice Guide': 'ارشادات خدمة التوريد',
  'No Events Found': 'لا توجد أحداث',
  'No Project Shares Available': 'لا توجد مشاريع مشتركة متاحة',
  PROFILE: 'حساب تعريفي',
  RELATIVES: 'الأقارب',
  REQUEST: 'طلب',
  'Profile No': 'رقم الملف الشخصي',
  'National ID': 'الهوية الوطنية',
  'Mobile No': 'رقم المحمول',
  'See All': 'اظهار الكل',
  Name: 'اسم',
  Relation: 'علاقة',
  'Health Status': 'الحالة الصحية',
  'Request No': 'رقم الطلب',
  Year: 'سنة',
  Status: 'حالة',
  'No pending requests': 'لا توجد طلبات معلقة',
  'No relatives found': 'لم يتم العثور على أقارب',
  'Landing Page': 'الصفحة المقصودة',
  'Select country': 'اختر البلد',
  'DONATE YOUR IDEA': 'تبرع بفكرتك',
  'Full Name':'الاسم الكامل'
};
