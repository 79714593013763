import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";

const SponserRedirect = () => {
    const history = useHistory()
    function useQuery() {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }
    let [searchParams, setSearchParams] = useState();
    let query = useQuery();
    let projectWeNeed = query.get("projectTypeID")
    useEffect(()=> {
        let projectWeNeed = query.get("orphanId")
        let selectedC = query.get("orphanCountryId")
        if (projectWeNeed) {
            history.push(`/beasponser/${projectWeNeed}`, {selectedCountry: selectedC})
        } else {
            history.push('/beasponser/all')
        }
    })
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        </div>
    )
}

export default SponserRedirect
