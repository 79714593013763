import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EventHomeSlider.css";
import EventMonthSelector from "./EventMonthSelector/EventMonthSelector";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import EventHomeComp from "./EventHomeComp/EventHomeComp";
SwiperCore.use([Navigation])
import { useTranslation } from "react-i18next";

const EventHomeSlider = ({events}) => {
    // const events = useSelector(state => state.events.events)
    const { t } = useTranslation();
    const [month, setMonth] = useState(new Date())
    const [view, setView] = useState(3)
    const [eventsOfMonth, setEventsOfMonth] = useState([])
    useEffect(() => {
        setEventsOfMonth(events?.filter((item) => {
            let date = new Date(item.start_date.split('/')[1] + "/" + item.start_date.split('/')[0] + "/" + item.start_date.split('/')[2])
            return date.getMonth() === month.getMonth()
        }))
    }
    ,[month, events])
  return (
    <React.Fragment>
        <EventMonthSelector month={month} setMonth={setMonth} />
        {eventsOfMonth?.length > 0?
        <Swiper className="swiper-container-event" loop={eventsOfMonth?.length > view? true:false} slidesPerView={view} navigation={true} >
        {eventsOfMonth?.map((item, i) =>
            <SwiperSlide className="swiper-slide-event"  key={i} style={{width:"100px !important"}}>
                <EventHomeComp key={i} event_id={i} item={item} />
            </SwiperSlide>
        )}
    </Swiper>
        : 
        <div style={{alignSelf: 'center', minHeight: '135px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {t("No Events Found")}
        </div>
        }
    </React.Fragment>
  )
}

export default EventHomeSlider