import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import donateIcon from "../../../../assets/donateIconForBannerWhite2.png";
import "./PopUpPayment.css";
import { useSelector, useDispatch, ReactReduxContext } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { addToBag, deleteFromBag } from "../../../../actions/BagFav";
import samPay from "../../../../assets/Pay_button_basic_rev_RGB.png";
import { handleSamsungDirectPayment } from "../../../../actions/handleSamsungPayment";
import Loading from "../../../ui/loading";
import SMSIcon from "../../../../assets/sms.png";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import SmsModal from "../../../ui/smsModal/SmsModal";
import Marquee from "react-double-marquee";
import { FETCH_LOCALLY } from "../../../../constants/actionTypes";
import { startApplePay } from "../../../../actions/handleApplePayment";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DonationFinalPopUp from "../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
const PopUpPaymentCompagin = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});

  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const compagins = useSelector(
    (state) => state.compaginsSlider.compaginSliders
  );
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  const [smsModalShow, setSmsModalShow] = useState(false);

  const history = useHistory();
  const userBag = useSelector((state) => state.bag.bag);
  const dispatch = useDispatch();
  const [bagDetails, setBagDetails] = useState(userBag);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [change, setChange] = useState(0);
  const [inTheBag, setInTheBag] = useState(0);
  const [addingToCart, setAddingToCart] = useState(false);

  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);

  let compaginweNeed = compagins?.filter(
    (item) =>
      item.campaignActiveID === props.data?.compaginId &&
      item.campaignDetSerial === props.data.campaignDetSerial
  );
  const [selectedItemsAmounts, setSelectedItemsAmounts] = useState([]);
  const [theRealAmountForSelectedItem, setTheRealAmountForSelectedItem] =
    useState(0);
  useEffect(() => {
    compaginweNeed = compagins?.filter(
      (item) =>
        item.campaignActiveID === props.data?.compaginId &&
        item.campaignDetSerial === props.data.campaignDetSerial
    );
    let itemLocal = compaginweNeed
      ?.slice(0)
      .shift()
      ?.donations?.slice(0)
      .shift();
    let firstElementArr = [];
    firstElementArr.push({
      donationId: itemLocal?.dontypeID,
      amount: parseInt(itemLocal?.amount),
      realAmount: parseInt(itemLocal?.amount),
      title: itemLocal?.dontypeName,
      isMulti: itemLocal?.isMulti,
      img: itemLocal?.imagePath,
      donationFreq: "N",
      edit_flag: itemLocal?.edit_flag,
      remarks: "slider",

    });
    setSelectedItemsAmounts(firstElementArr);
  }, [compagins, props.data]);

  //const [selectedItems, setSelectedItems] = useState([])

  const handleChangeForCampElement = (e) => {
    let selectedItemsAll = selectedItemsAmounts.slice(0);
    let newItem = selectedItemsAll.slice(selectedItemsAll.length - 1).shift();
    newItem.amount = e.target.validity.valid
      ? e.target.value
      : selectedItemsAll.slice(selectedItemsAll.length - 1).shift().amount;
    selectedItemsAll.splice(
      selectedItemsAll.findIndex(
        (item) => item.donationId === newItem.donationId
      ),
      1,
      newItem
    );
    setSelectedItemsAmounts(selectedItemsAll);
  };
  const handleClickFromCompagin = (op) => {
    let selectedItemsAll = selectedItemsAmounts.slice(0);
    if (selectedItemsAll.length > 0) {
      if (op === "-") {
        if (
          selectedItemsAll[selectedItemsAll.length - 1].amount >
          selectedItemsAll[selectedItemsAll.length - 1].realAmount
        ) {
          let newItem = selectedItemsAll
            .slice(selectedItemsAll.length - 1)
            .shift();
          newItem.amount =
            parseInt(selectedItemsAll[selectedItemsAll.length - 1].amount) -
            parseInt(selectedItemsAll[selectedItemsAll.length - 1].realAmount);
          selectedItemsAll.splice(
            selectedItemsAll.findIndex(
              (item) => item.donationId === newItem.donationId
            ),
            1,
            newItem
          );
          setSelectedItemsAmounts(selectedItemsAll);
        }
      } else {
        let newItem = selectedItemsAll
          .slice(selectedItemsAll.length - 1)
          .shift();
        newItem.amount =
          parseInt(selectedItemsAll[selectedItemsAll.length - 1].amount) +
          parseInt(selectedItemsAll[selectedItemsAll.length - 1].realAmount);
        selectedItemsAll.splice(
          selectedItemsAll.findIndex(
            (item) => item.donationId === newItem.donationId
          ),
          1,
          newItem
        );
        setSelectedItemsAmounts(selectedItemsAll);
      }
    }
  };
  //handle select and check baging
  useEffect(() => {
    if (selectedItemsAmounts.length > 0) {
      let theCurrentItem = selectedItemsAmounts.slice(0).reverse().shift();
      if (!userLogin) {
        setInTheBag(
          currentProfile?.cart?.donations?.filter(
            (item) => item.donationId === theCurrentItem.donationId
          ).length
            ? currentProfile?.cart?.donations?.filter(
                (item) => item.donationId === theCurrentItem.donationId
              ).length
            : 0
        );
      } else {
        setInTheBag(
          bagDetails?.donations
            ?.filter(
              (item) =>
                item.dontypeID === theCurrentItem.donationId &&
                parseInt(item.baseAmount) === theCurrentItem.amount &&
                item.recurring === "N"
            )
            .slice(0)
            .shift()?.quantity
            ? bagDetails?.donations
                ?.filter(
                  (item) =>
                    item.dontypeID === theCurrentItem.donationId &&
                    parseInt(item.baseAmount) === theCurrentItem.amount &&
                    item.recurring === "N"
                )
                .slice(0)
                .shift()?.quantity
            : 0
        );
      }
      //let amountOfElement = [];
      //let salaryRealArr = [];
      // let idsArray = selectedItemsAmounts.map(item => item.donationId)
      // let idsToCheck = []
      // for (let i = 0; i < idsArray.length; i++) {
      //     document.getElementById(`choosen_for_${idsArray[i]}`).style.display = 'flex'
      //     document.getElementById(`choosen_warper_for_${idsArray[i]}`).style.border = '1px solid #ee4236'
      //amountOfElement = selectedItemsAmounts?.filter(item => item.donationId === idsArray[i]).slice(0, 1).shift()
      //salaryRealArr.push(parseInt(amountOfElement?.amount))
      // if (!userLogin) {
      //     if (currentProfile?.cart?.donations?.filter(item => item.donationId === idsArray[i]).length > 0) {
      //         idsToCheck.push(idsArray[i])
      //     }
      // } else {
      //     if (bagDetails?.donations?.filter(item => item.dontypeID === idsArray[i]).length > 0) {
      //         idsToCheck.push(idsArray[i])
      //     }
      // }
      //}
      // if (idsToCheck.length === idsArray.length) {
      //     setInTheBag(true)
      // } else {
      //     setInTheBag(false)
      // }
      //let priceForAllDonationsIn = selectedItemsAmounts?.map(item => parseInt(item.amount));
      //let sumOfPriceForAllDonationsIn = priceForAllDonationsIn?.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      //setcompaginAmount(sumOfPriceForAllDonationsIn);
      //let sumOfAllRealAmounts = salaryRealArr?.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      //setTheRealAmount(sumOfAllRealAmounts)
    } else {
      //setcompaginAmount(0)
      //     //setTheRealAmount(0)
      setInTheBag(0);
    }
  }, [selectedItemsAmounts, bagDetails]);
  //adding style for selected items
  /*  useEffect(() => {
          if (compaginamount > 0 && compaginamount <= theRealAmount * 5) {
              var ele = document.getElementsByClassName('compagins')
              for (var i = 0; i < ele.length; i++) {
                  ele[i].style.backgroundColor = "white";
                  ele[i].style.color = "black";
              }
              document.getElementById(`mob_${compaginamount}`).style.backgroundColor = "#ee4236";
              document.getElementById(`mob_${compaginamount}`).style.color = "white";
          } else {
              var elem = document.getElementsByClassName('compagins')
              for (var y = 0; y < elem.length; y++) {
                  elem[y].style.backgroundColor = "white";
                  elem[y].style.color = "black";
              }
          }
  
      }, [compaginamount])*/

  //checking from Bag
  /* useEffect(() => {
         if (selectedItems.length > 0) {
             let idsArray = selectedItems.map(item => item?.donationId)
             let idsToCheck = []
             for (let i = 0; i < idsArray.length; i++) {
                 if (!userLogin) {
                     if (currentProfile?.cart?.donations?.filter(item => item.donationId === idsArray[i]).length > 0) {
                         idsToCheck.push(idsArray[i])
                     }
                 } else {
                     if (bagDetails?.donations?.filter(item=> item.dontypeID === idsArray[i]).length > 0) {
                         idsToCheck.push(idsArray[i])
                     }
                 }
             }
             if (idsToCheck.length >= 1) {
                 setInTheBag(true)
             } else {
                 setInTheBag(false)
             }
         } else {
             setInTheBag(false)
         }
         
     }, [currentProfile, bagDetails])*/
  const handleSelect = (itemLocal, e) => {
    // if (e.detail === 1) {
    //     let itemBefore = selectedItemsAmounts.slice(0)
    //     let checkIf = itemBefore.filter(innerItem => innerItem.donationId === itemLocal.dontypeID)
    //     if (checkIf.length > 0) {
    //         let elementsWithout = selectedItemsAmounts.map(item => item).filter(innerItem => innerItem.donationId!== itemLocal.dontypeID)
    //         elementsWithout.push(checkIf.slice(0).shift())
    //         setSelectedItemsAmounts(elementsWithout)
    //     }
    // } else {
    let itemBefore = selectedItemsAmounts.slice(0);
    //check in the bag or not when selecting
    let checkIf = itemBefore.filter(
      (innerItem) => innerItem.donationId === itemLocal.dontypeID
    );
    if (checkIf.length > 0) {
      setSelectedItemsAmounts(
        selectedItemsAmounts
          .map((item) => item)
          .filter((innerItem) => innerItem.donationId !== itemLocal.dontypeID)
      );
      // document.getElementById(`choosen_for_${itemLocal.dontypeID}`).className = 'rightSign_btn';
      // document.getElementById(`choosen_warper_for_${itemLocal.dontypeID}`).style.border = '2px solid white'
    } else {
      // document.getElementById(`choosen_for_${itemLocal.dontypeID}`).className = 'rightSign_btn--active';
      let newselected = selectedItemsAmounts.slice(0);
      newselected.push({
        donationId: itemLocal.dontypeID,
        amount: parseInt(itemLocal.amount),
        realAmount: parseInt(itemLocal.amount),
        title: itemLocal.dontypeName,
        isMulti: itemLocal.isMulti,
        img: itemLocal.imagePath,
        donationFreq: "N",
        edit_flag: itemLocal.edit_flag,
        remarks: "slider",
      });
      setSelectedItemsAmounts(newselected);
    }
    // }
  };
  const handleaddToBag = async () => {
    setAddingToCart(true);
    let amountsArr = selectedItemsAmounts.map((item) => parseInt(item.amount));
    let amountCheck = amountsArr.filter((item) => item >= 5);
    if (amountCheck.length < amountsArr.length) {
      setAddingToCart(false);
      return toastifyMessage(
        "Can not pay less than 5 AED",
        undefined,
        "warning"
      );
    }
    if (selectedItemsAmounts.length === 0) {
      setAddingToCart(false);
      return toastifyMessage(
        "Please select from campaign elements",
        undefined,
        "warning"
      );
    }
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      let copyOfSelectedItems = selectedItemsAmounts.slice();
      for (let i = 0; i < copyOfSelectedItems.length; i++) {
        currentProfile.cart.donations.push({
          ...copyOfSelectedItems[i],
          baseAmount: copyOfSelectedItems[i].amount,
        });
      }
      for (let i = 0; i < copyOfSelectedItems.length; i++) {
        if (
          currentProfile.cart.donations.filter(
            (item) =>
              parseInt(copyOfSelectedItems[i].amount) !==
                parseInt(item.baseAmount) &&
              copyOfSelectedItems[i].donationId === item.donationId &&
              copyOfSelectedItems[i].donationFreq === item.donationFreq
          ).length > 0
        ) {
          const filteredArray = currentProfile.cart.donations.filter(
            (item) =>
              copyOfSelectedItems[i].donationId === item.donationId &&
              copyOfSelectedItems[i].donationFreq === item.donationFreq
          );
          const newBaseAmount = filteredArray.reduce(
            (previousValue, currentValue) =>
              parseInt(previousValue) + parseInt(currentValue.baseAmount),
            0
          );
          currentProfile.cart.donations = currentProfile.cart.donations.filter(
            (item) =>
              item.donationId !== copyOfSelectedItems[i].donationId ||
              copyOfSelectedItems[i].donationFreq !== item.donationFreq
          );
          currentProfile.cart.donations.push({
            ...copyOfSelectedItems[i],
            baseAmount: newBaseAmount,
            amount: newBaseAmount,
          });
        }
      }

      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
      toastifyMessage("Added!", undefined, "success");
      props.onHide();
    } else {
      //let lengthOfSelectedItems = selectedItems.length
      let copyOfSelectedItems = selectedItemsAmounts.slice();
      //let eachOneAmount = compaginamount/lengthOfSelectedItems;
      //copyOfSelectedItems = copyOfSelectedItems.map(item=> {return {...item, amount: eachOneAmount}})
      let items = copyOfSelectedItems.map((item) => {
        return {
          remarks: item.remarks ? item.remarks : null,
          dontype_id: item.donationId,
          doner_name: userLogin.Results[0]?.name_a,
          mobile: userLogin.Results[0]?.mobile_no,
          email: userLogin.Results[0]?.email,
          orptype_id: item.orpTypeID ? item.orpTypeID : null,
          wrkplc_id: item.workPlaceID ? item.workPlaceID : null,
          prj_name: item.prj_name ? item.prj_name : null,
          projectYear: item.projectYear ? item.projectYear : null,
          template_id: item.template_id ? item.template_id : null,
          vln_id: item.vln_id ? item.vln_id : null,
          vln_service_cod: item.vln_service_cod ? item.vln_service_cod : null,
          vln_date_from: item.vln_date_from ? item.vln_date_from : null,
          vln_date_to: item.vln_date_to ? item.vln_date_to : null,
          vln_desc: item.vln_desc ? item.vln_desc : null,
          Mastercard_order_id: item.Mastercard_order_id
            ? item.Mastercard_order_id
            : null,
          item_id: "1",
          item_type: 0,
          country_id: item.country_id ? item.country_id : null,
          amount: item.amount,
          recuring: "N",
          month_count: item.month_count ? item.month_count : null,
          humanCase: item.humanCase ? item.humanCase : null,
          humanYear: item.humanYear ? item.humanYear : null,
          valid_flag: item.valid_flag ? item.valid_flag : null,
          sponsor_id: item.sponsor_id ? item.sponsor_id : null,
          cart_serial: null,
          orphan_id: item.orphan_id ? item.orphan_id : null,
          transaction_number: item.transaction_number
            ? item.transaction_number
            : null,
          transaction_year: item.transaction_year
            ? item.transaction_year
            : null,
        };
      });
      await dispatch(
        addToBag(
          { cart: items, language: lng === "arab" ? 1 : 2, from: "camPop" },
          props.onHide
        )
      );
    }
    if (_isMounted.current) {
      setAddingToCart(false);
    }
      
  };

  const handleChoosenAmountByBtn = (donation) => {
    if (selectedItemsAmounts.length > 0) {
      let newSelected = selectedItemsAmounts.slice(0);
      let newItem = newSelected.slice(newSelected.length - 1).shift();
      newItem.amount = donation;
      newItem.baseAmount = donation;
      newSelected.splice(
        newSelected.findIndex((item) => item.donationId === newItem.donationId),
        1,
        newItem
      );
      setSelectedItemsAmounts(newSelected);
    }

    const handleSMS = async (donationId) => {
      setSmsModalShow(true);
      //var data = await getSMS(donationId);
    };
    //let itemWeNeed = selectedItemsAmounts[.filter(item=> item.donationId===donation.dontypeID).slice(0).shift()]
    // itemWeNeed.amount = donation.amount
    //newSelected.splice(newSelected.findIndex(x=> x.donationId===donation.dontypeID),1,itemWeNeed)
    // setSelectedItemsAmounts(newSelected)
  };
  const handleSMS = async (donationId) => {
    if (selectedItemsAmounts.length > 0) {
      setSmsModalShow(true);
    }
    //var data = await getSMS(donationId);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: selectedItemsAmounts.reduce(
        (sum, item) => sum + parseInt(item.amount),
        0
      ),
      url: window.location.href,
      item: selectedItemsAmounts.map(function (item) {
        return {
          dontype_id: item.donationId,
          item_type: 0,
          amount: item.amount,
          recuring: item.recuring,
          cart_serial: null,
        };
      }),
    });
    setLoadingModalShow(false);
  };
  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      centered
      style={{ paddingRight: "0px", paddingLeft: "0px" }}
      className="popUp"
      onExit={() => {
        setSelectedItemsAmounts([]);
        setInTheBag(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={
          lng === "arab"
            ? { direction: "rtl", fontFamily: "'arab_font'" }
            : { fontFamily: "english_font" }
        }
      >
        <h2 style={{ color: "white" }}>{t("Please Select:")}</h2>
        <div className="display_in_pop_up">
          {compaginweNeed
            ?.slice(0, 1)
            .shift()
            ?.donations?.map((item, i) => (
              <div
                className="donation_item"
                style={{ boxShadow: "none", borderRadius: "none" }}
                key={i}
              >
                <div
                  id={`choosen_warper_for_${item.dontypeID}`}
                  onClick={(e) => handleSelect(item, e)}
                  style={{
                    backgroundImage: `url(${item.imagePath})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                    position: "relative",
                    height:
                      width >= 900 &&
                      compaginweNeed?.slice(0, 1)?.shift()?.donations
                        ?.length === 1
                        ? "160px"
                        : width < 900 &&
                          compaginweNeed?.slice(0, 1)?.shift()?.donations
                            ?.length === 1
                        ? "120px"
                        : null,
                    width:
                      width >= 900 &&
                      compaginweNeed?.slice(0, 1)?.shift()?.donations
                        ?.length === 1
                        ? "200px"
                        : width < 900 &&
                          compaginweNeed?.slice(0, 1)?.shift()?.donations
                            ?.length === 1
                        ? "160px"
                        : null,
                    border:
                      selectedItemsAmounts.filter(
                        (innerItem) => innerItem.donationId === item.dontypeID
                      ).length > 0
                        ? "1px solid #ee4236"
                        : "2px solid white",
                  }}
                  className="donation_img_forBanner"
                >
                  <span
                    id={`choosen_for_${item.dontypeID}`}
                    style={{ position: "absolute", top: "5px", left: "5px" }}
                    className={
                      selectedItemsAmounts.filter(
                        (innerItem) => innerItem.donationId === item.dontypeID
                      ).length > 0
                        ? "rightSign_btn--active"
                        : "rightSign_btn"
                    }
                  >
                    <i className="fas fa-check"></i>
                  </span>
                </div>
                {item.dontypeName.length >= 16 ? (
                  <div
                    className={
                      lng === "arab" ? "cssmarquee_arab" : "cssmarquee"
                    }
                    style={{
                      direction: "ltr",
                      width: width >= 1200 ? "160px" : "120px",
                      color: "white",
                    }}
                  >
                    <Marquee
                      speed={0.025}
                      childMargin={15}
                      direction={lng === "arab" ? "right" : "left"}
                      className="label_of_title"
                    >
                      <label>
                        {item.dontypeName ? item.dontypeName : "Loading"}
                      </label>
                    </Marquee>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      color: "white",
                    }}
                    className={
                      item.dontypeName.length >= 19
                        ? lng === "arab"
                          ? "marquee"
                          : "marquee_eng"
                        : "normalDiv"
                    }
                  >
                    <label style={{ width: "100%", whiteSpace: "unset" }}>
                      {item.dontypeName}
                    </label>
                  </div>
                )}
                {selectedItemsAmounts.filter((inner) => inner.donationId === item.dontypeID).length > 0? 
                    selectedItemsAmounts.filter((inner) => inner.donationId === item.dontypeID).slice(0).shift()?.amount? 
                    selectedItemsAmounts.filter((inner) => inner.donationId === item.dontypeID).slice(0).shift()?.amount.length > 9?
                    <label className='camp_amount'>
                      <span>{t("AED")}</span>
                      <span className='payment_numbers'>99999999+</span>
                    </label>:
                    <label className='camp_amount'>
                        <span>{t("AED")}</span>
                        <span className='payment_numbers'>{
                          selectedItemsAmounts.filter((inner) => inner.donationId === item.dontypeID).slice(0).shift()?.amount
                        }</span>
                  </label>:
                   <label className='camp_amount'></label>: 
                   <label className='camp_amount'></label>
                }
              </div>
            ))}
        </div>
        <div className="choosen_amount_for_mob">
          <button
            disabled={addingToCart}
            style={
              selectedItemsAmounts[selectedItemsAmounts.length - 1]?.amount ===
              selectedItemsAmounts[selectedItemsAmounts.length - 1]?.realAmount
                ? { backgroundColor: "#ee4236", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
            onClick={() =>
              handleChoosenAmountByBtn(
                selectedItemsAmounts[selectedItemsAmounts.length - 1]
                  ?.realAmount
              )
            }
            className="spin_popUp_for_mob compagins"
          >
            <span style={{ fontSize: "15px", opacity: "0.7" }}>{t("AED")}</span>
            <span
              className="payment_numbers"
              style={{ fontSize: "15px", fontWeight: "bold" }}
            >
              {selectedItemsAmounts[selectedItemsAmounts.length - 1]?.realAmount
                ? selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount
                : 0}
            </span>
          </button>
          {selectedItemsAmounts[selectedItemsAmounts.length - 1]?.isMulti ? (
            <React.Fragment>
              <button
                disabled={addingToCart}
                style={
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.amount ===
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount *
                    2
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : { backgroundColor: "white", color: "black" }
                }
                onClick={() =>
                  handleChoosenAmountByBtn(
                    selectedItemsAmounts[selectedItemsAmounts.length - 1]
                      ?.realAmount * 2
                  )
                }
                className="spin_popUp_for_mob compagins"
              >
                <span style={{ fontSize: "15px", opacity: "0.7" }}>
                  {t("AED")}
                </span>
                <span
                  className="payment_numbers"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount * 2
                    ? selectedItemsAmounts[selectedItemsAmounts.length - 1]
                        ?.realAmount * 2
                    : 0}
                </span>
              </button>
              <button
                disabled={addingToCart}
                style={
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.amount ===
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount *
                    3
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : { backgroundColor: "white", color: "black" }
                }
                onClick={() =>
                  handleChoosenAmountByBtn(
                    selectedItemsAmounts[selectedItemsAmounts.length - 1]
                      ?.realAmount * 3
                  )
                }
                className="spin_popUp_for_mob compagins"
              >
                <span style={{ fontSize: "15px", opacity: "0.7" }}>
                  {t("AED")}
                </span>
                <span
                  className="payment_numbers"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount * 3
                    ? selectedItemsAmounts[selectedItemsAmounts.length - 1]
                        ?.realAmount * 3
                    : 0}
                </span>
              </button>
              <button
                disabled={addingToCart}
                style={
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.amount ===
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount *
                    4
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : { backgroundColor: "white", color: "black" }
                }
                onClick={() =>
                  handleChoosenAmountByBtn(
                    selectedItemsAmounts[selectedItemsAmounts.length - 1]
                      ?.realAmount * 4
                  )
                }
                className="spin_popUp_for_mob compagins"
              >
                <span style={{ fontSize: "15px", opacity: "0.7" }}>
                  {t("AED")}
                </span>
                <span
                  className="payment_numbers"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount * 4
                    ? selectedItemsAmounts[selectedItemsAmounts.length - 1]
                        ?.realAmount * 4
                    : 0}
                </span>
              </button>
              <button
                disabled={addingToCart}
                style={
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.amount ===
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount *
                    5
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : { backgroundColor: "white", color: "black" }
                }
                onClick={() =>
                  handleChoosenAmountByBtn(
                    selectedItemsAmounts[selectedItemsAmounts.length - 1]
                      ?.realAmount * 5
                  )
                }
                className="spin_popUp_for_mob compagins"
              >
                <span style={{ fontSize: "15px", opacity: "0.7" }}>
                  {t("AED")}
                </span>
                <span
                  className="payment_numbers"
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                >
                  {selectedItemsAmounts[selectedItemsAmounts.length - 1]
                    ?.realAmount * 5
                    ? selectedItemsAmounts[selectedItemsAmounts.length - 1]
                        ?.realAmount * 5
                    : 0}
                </span>
              </button>
            </React.Fragment>
          ) : null}
        </div>
        <div
          style={{ justifyContent: "center", gap: "20px" }}
          className="sukuk_inc_Dec"
        >
          <button
            disabled={
              addingToCart ||
              !selectedItemsAmounts[selectedItemsAmounts.length - 1]?.isMulti
            }
            style={{ backgroundColor: "white", color: "black" }}
            className="sukukdec btn btn-light"
            onClick={() => handleClickFromCompagin("-")}
          >
            -
          </button>
          {selectedItemsAmounts[selectedItemsAmounts.length - 1]?.edit_flag ? (
            <div
              style={{
                padding: "5px 30px 5px 30px",
                borderRadius: width <= 250 ? "10px" : "30px",
                minWidth: "40px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                backgroundColor: "white",
                flexDirection: width <= 250 ? "column" : "row",
              }}
            >
              <label
                htmlFor="Pop_up_payment"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {t("AED")}
              </label>
              <input
                id="Pop_up_payment"
                disabled={addingToCart}
                className="translateFillMsg"
                type="text"
                pattern="[0-9]*"
                onChange={handleChangeForCampElement}
                value={
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]?.amount
                }
                style={{
                  border: "none",
                  fontSize: "20px",
                  fontWeight: "bold",
                  width: width <= 350 ? "50px" : "114px",
                  paddingTop: "2px",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          ) : (
            <label
              style={{
                padding: "5px 30px 5px 30px",
                borderRadius: "30px",
                backgroundColor: "white",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {t("AED")}{" "}
              <span
                className="payment_numbers"
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  fontStyle: "none",
                }}
              >
                {isNaN(
                  selectedItemsAmounts[selectedItemsAmounts.length - 1]?.amount
                )
                  ? 0
                  : selectedItemsAmounts[selectedItemsAmounts.length - 1]
                      ?.amount}
              </span>
            </label>
          )}
          <button
            disabled={
              addingToCart ||
              !selectedItemsAmounts[selectedItemsAmounts.length - 1]?.isMulti
            }
            style={{ backgroundColor: "white", color: "black" }}
            className="sukukinc btn btn-light"
            onClick={() => handleClickFromCompagin("+")}
          >
            +
          </button>
        </div>
        <div className="popUp_btns">
          {window.ApplePaySession ? (
            <div
              id="applepay_btn"
              className="apple-pay-button-outside apple-pay-button-white"
              onClick={() =>
                selectedItemsAmounts.length === 0
                  ? toastifyMessage(
                      "Please select from campaign elements",
                      undefined,
                      "warning"
                    )
                  : handleGuestPopUp(() =>
                      startApplePay(
                        {
                          amount: selectedItemsAmounts.reduce(
                            (sum, item) => sum + parseInt(item.amount),
                            0
                          ),
                          cart: selectedItemsAmounts.map(function (item) {
                            return {
                              dontype_id: item.donationId,
                              item_type: 0,
                              amount: item.amount,
                              recuring: item.recuring,
                              cart_serial: null,
                            };
                          }),

                          item: selectedItemsAmounts,
                        },
                        dispatch,
                        history
                      )
                    )
              }
              style={{ width: "80%", height: "44px" }}
              locale="ar-AB"
            ></div>
          ) : (
            <button
              style={{
                width: "80%",
                backgroundColor: "white",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                selectedItemsAmounts.length === 0
                  ? toastifyMessage(
                      "Please select from campaign elements",
                      undefined,
                      "warning"
                    )
                  : handleGuestPopUp(startSamsungPay)
              }
              className="btn btn-block btn-dark apple_human_btn"
            >
              <img
                src={samPay}
                style={{
                  height: "44px",
                  width: width <= 250 ? "100%" : "auto",
                }}
                alt="samsung_pay"
              />
            </button>
          )}
          {/* {<button disabled={addingToCart} style={lng === 'arab' ? { direction: 'ltr' } : { direction: 'ltr' }} onClick={handleRemoveFromBag} className='btn btn-block btn-danger popUP_outline'>
                            <span style={{ width: '100%' }}>{t('Remove from Bag')}</span>
                            <img style={{ marginBottom: '0px' }} src={donateIcon} alt='donate_btn_icon_banner' className='donate_btn_icon_banner' />
                        </button> } */}
          <div
            className="mobBtn_display_none"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              width: "80%",
            }}
          >
            <button
              disabled={addingToCart}
              style={
                lng === "arab"
                  ? { direction: "rtl", width: "100%" }
                  : { direction: "ltr", width: "100%" }
              }
              onClick={handleSMS}
              className="btn btn-block btn-outline-light popUP_outline"
            >
              <React.Fragment>
                <span style={{ width: "100%" }}>{t("Donate by SMS")}</span>
                <img
                  style={{
                    width: "12px",
                    marginRight: lng === "arab" ? "5px" : "0px",
                    marginLeft: lng === "arab" ? "5px" : "0px",
                  }}
                  src={SMSIcon}
                  alt="donate_btn_icon_banner"
                  className="donate_btn_icon_banner"
                />
              </React.Fragment>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "80%",
            }}
          >
            <button
              disabled={addingToCart}
              style={
                lng === "arab"
                  ? { direction: "rtl", width: "100%" }
                  : { direction: "ltr", width: "100%" }
              }
              onClick={handleaddToBag}
              className="btn btn-block btn-outline-light popUP_outline"
            >
              {addingToCart ? (
                <Loading small={"30px"} height={"30px"} color={"white"} />
              ) : (
                <React.Fragment>
                  <span style={{ width: "100%" }}>{t("Add to Bag")}</span>
                  <img
                    src={donateIcon}
                    alt="donate_btn_icon_banner"
                    className="donate_btn_icon_banner"
                  />
                </React.Fragment>
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
      {/* {modalShow ? <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='donation_pop_up_body'
            >
                <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px' }} >
                    <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                        <label>{t("Would you like to")} <span style={{ fontWeight: 'bold' }}>{t("Register")}</span> {t("for easier donations in the future?")}</label>
                        <div className='donation_final_popUp_btns_wraper'>
                            <button onClick={() => history.push('/guest')} className='donation_final_popUp_btn_first'>
                                {t("Not Now")}
                            </button>
                            <button onClick={() => history.push('/auth')} className='donation_final_popUp_btn_second'>
                                {t("Yes")}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> : null} */}
      <SmsModal
        show={smsModalShow}
        fromcamp="true"
        donationid={
          selectedItemsAmounts[selectedItemsAmounts.length - 1]?.donationId
        }
        onHide={() => setSmsModalShow(false)}
      />
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </Modal>
  );
};

export default PopUpPaymentCompagin;
