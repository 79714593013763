import React, { useEffect, useState } from 'react';
import './loginSponsor.css';
import ERCLogo from '../../../../assets/logo.png';
import BCE2mob from '../../../../assets/loginBCMob2.png';
import BCE2 from '../../../../assets/loginBC.png';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoadingModal from '../loadingPopUp/loadingPopUp';
import { useDispatch } from 'react-redux';
import { getingYourAcc } from '../../../../actions/user';
import { toastifyMessage } from '../../../../handlers/toastifyMessage';
import ShareHeader from '../../../LoaclComponents/shareHeader/ShareHeader';
import {
  applyValidation,
  resetValidation
} from '../../../../handlers/formValidation';
import * as api from '../../../../api';

const LoginSponsor = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [content, setContent] = useState('yes');
  const [display, setDisplay] = useState(1);
  const [sponsorID, setSponsorID] = useState('');
  const [otpVerify, setOtpVerify] = useState('');
  const [email, setEmail] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  useEffect(() => {
    if (width > 900) {
      document.getElementById(
        'login_sign'
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        'login_sign'
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const [doneModal, setDoneModal] = useState(false);
  const openFromLog = () => {
    history.push('/');
  };
  const getOTP = async (e) => {
    e.preventDefault();
    const response = await api.sponsorGetOtp(sponsorID);
    if (response?.data?.status) {
      toastifyMessage('OTP is sent successfully', undefined, 'success');
      setDisplay(0);
    }
  };
  const verifyOTP = async (e) => {
    e.preventDefault();
    try {
      var response;
      response = await api.sponsorVerifyOtp(sponsorID, otpVerify);
      if (response?.data?.status) {
        if (response?.data?.data?.result === 'EmailRequired') {
          setContent(response?.data?.data?.result);
          setDisplay(1);
        } else if (response?.data?.data?.data) {
          localStorage.setItem('access_token', response?.data?.data?.data);
          const userResult = await api.whoAmI();
          if (userResult) {
            localStorage.setItem(
              'userLogin',
              JSON.stringify({ Results: [userResult] })
            );
            history.push('/');
          }
        }
      }
    } catch (error) {
      if (error?.response) {
        toastifyMessage(error?.response?.data?.result, undefined, 'warning');
      } else {
        toastifyMessage('Some Error occured', undefined, 'error');
      }
    }
  };
  const sponsorUpdateEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await api.updateSponsorEmail(
        sponsorID,
        otpVerify,
        email
      );
      if (response?.data?.status) {
        if (response?.data?.data?.data) {
          setContent('');
          setDisplay(1);
          localStorage.setItem('access_token', response?.data?.data?.data);
          const userResult = await api.whoAmI();
          console.log(userResult,"userResult");
          if (userResult) {
            localStorage.setItem(
              'userLogin',
              JSON.stringify({ Results: [userResult] })
            );
            history.push('/');
          }
        }
      }
    } catch (error) {
      if (error?.response) {
        toastifyMessage(error?.response?.data?.result, undefined, 'warning');
      } else {
        toastifyMessage('Some Error occured', undefined, 'error');
      }
    }
  };

  const validateEmiratesId = (eId) => {
    const re = /^[0-9]{15}$/;
    return re.test(eId);
  };
  if (!userLogin) {
    return (
      <div
        id="login_sign"
        style={{
          backgroundImage: `url(${BCE2})`,
          backgroundSize: 'cover',
          justifyContent: 'flex-start',
          justifyContent: 'flex-start',
          minHeight: '100vh'
        }}
      >
        <ShareHeader noTitle={true} noShare={true} forAuth={true} />
        <div className="NeedToOtp">
          <img
            src={ERCLogo}
            onClick={openFromLog}
            alt="ERC_logo"
            className="ERC_logo_login_sign"
          />
          {display === 1 ? (
            <React.Fragment>
              {content === 'yes' ? (
                <>
                  <h3
                    className="titleSmall_deviceSmall"
                    style={lng === 'arab' ? { direction: 'rtl' } : null}
                  >
                    {t('Please Enter Your Sponsor ID.')}
                  </h3>
                  <form
                    onSubmit={getOTP}
                    className="otp_input_wraper"
                    style={{
                      width: '90%',
                      flexDirection: 'row',
                      padding: '0px',
                      gap: '0px',
                      alignItems: 'unset',
                      direction: lng === 'arab' ? 'rtl' : 'ltr'
                    }}
                  >
                    <input
                      maxLength="15"
                      style={{ width: '100%', padding: '10px' }}
                      className="input_for_verfication_code"
                      type="text"
                      value={sponsorID}
                      onChange={(e) => setSponsorID(e.target.value)}
                      required
                      onInvalid={applyValidation}
                      onInput={resetValidation}
                    />
                    <button
                      style={{
                        borderTopRightRadius: lng === 'arab' ? '0px' : '5px',
                        borderBottomRightRadius: lng === 'arab' ? '0px' : '5px',
                        borderTopLeftRadius: lng === 'arab' ? '5px' : '0px',
                        borderBottomLeftRadius: lng === 'arab' ? '5px' : '0px'
                      }}
                      type="submit"
                      className="confirm_otp"
                    >
                      {t('Confirm')}
                    </button>
                  </form>
                </>
              ) : (
                <React.Fragment>
                  <h1>{content}</h1>
                  <form
                    onSubmit={sponsorUpdateEmail}
                    className="otp_input_wraper"
                    style={{
                      width: '90%',
                      flexDirection: 'row',
                      padding: '0px',
                      gap: '0px',
                      alignItems: 'unset',
                      direction: lng === 'arab' ? 'rtl' : 'ltr'
                    }}
                  >
                    <input
                      maxLength="15"
                      style={{ width: '100%', padding: '10px' }}
                      className="input_for_verfication_code"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      onInvalid={applyValidation}
                      onInput={resetValidation}
                    />
                    <button
                      style={{
                        borderTopRightRadius: lng === 'arab' ? '0px' : '5px',
                        borderBottomRightRadius: lng === 'arab' ? '0px' : '5px',
                        borderTopLeftRadius: lng === 'arab' ? '5px' : '0px',
                        borderBottomLeftRadius: lng === 'arab' ? '5px' : '0px'
                      }}
                      type="submit"
                      className="confirm_otp"
                    >
                      {t('Confirm')}
                    </button>
                  </form>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
                {t("Here Is Your Account")}
              </h3>
              <div
                className="otp_input_wraper"
                style={{
                  width: width<= 900? '95%':"80%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "10px",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}
              >
                <label>
                  {t("E-mail")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold" }}>
                    {userAccount.email}
                  </span>
                </label>
                <label>
                  {t("Mobile")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold", direction:'ltr', display:'inline-block' }}>
                    {userAccount.mobile}
                  </span>
                </label>
              </div> */}
              <h3
                className="titleSmall_deviceSmall"
                style={lng === 'arab' ? { direction: 'rtl' } : null}
              >
                {t('Enter the OTP')}
              </h3>
              <div
                className="otp_input_wraper"
                style={{
                  width: width <= 900 ? '95%' : '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  borderRadius: '10px',
                  direction: lng === 'arab' ? 'rtl' : 'ltr'
                }}
              >
                {/* <label>
                  {t("E-mail")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold" }}>
                    {userAccount.email}
                  </span>
                </label>
                <label>
                  {t("Mobile")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold", direction:'ltr', display:'inline-block' }}>
                    {userAccount.mobile}
                  </span>
                </label> */}
                <form
                  onSubmit={verifyOTP}
                  className="otp_input_wraper"
                  style={{
                    width: '90%',
                    flexDirection: 'row',
                    padding: '0px',
                    gap: '0px',
                    alignItems: 'unset',
                    direction: lng === 'arab' ? 'rtl' : 'ltr'
                  }}
                >
                  <input
                    maxLength="15"
                    style={{ width: '100%', padding: '10px' }}
                    className="input_for_verfication_code"
                    type="text"
                    value={otpVerify}
                    onChange={(e) => setOtpVerify(e.target.value)}
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                  />
                  <button
                    style={{
                      borderTopRightRadius: lng === 'arab' ? '0px' : '5px',
                      borderBottomRightRadius: lng === 'arab' ? '0px' : '5px',
                      borderTopLeftRadius: lng === 'arab' ? '5px' : '0px',
                      borderBottomLeftRadius: lng === 'arab' ? '5px' : '0px'
                    }}
                    type="submit"
                    className="confirm_otp"
                  >
                    {t('Confirm')}
                  </button>
                </form>
              </div>
              <button
                onClick={() => history.push('/auth')}
                style={{
                  backgroundColor: 'gray',
                  border: 'none',
                  padding: '7px',
                  color: 'white',
                  borderRadius: '5px'
                }}
              >
                {t('Back')}
              </button>
            </React.Fragment>
          )}
        </div>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace('/');
  }
};
export default LoginSponsor;
