import React, { useState, useEffect } from "react";
// import "./LocalAidRelativeIdentity.css";
import icon1 from "../../../assets/nation id.png";
// import icon2 from "../../../assets/edducation.png";
import icon3 from "../../../assets/ntionality.png";
// import icon4 from "../../../assets/mobile number.png";
import icon5 from "../../../assets/date of birth.png";
// import icon6 from "../../../assets/gender.png";
// import icon8 from "../../../assets/occupation.png";
// import icon9 from "../../../assets/marital status.png";
// import icon10 from "../../../assets/health.png";
// import icon12 from "../../../assets/email.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
    addLocalAidRelation,
} from "../../../api/local_aid";
import {
    getAllCountries,
    getRelationshipStatus,
} from "../../../actions/volunteer";
import { SET_VLN_ACCOUNT } from "../../../constants/actionTypes";
import DatePickerModal from "../../userPage/EditProfile/DatePickerModal";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
    applyValidation,
    resetValidation,
} from "../../../handlers/formValidation";

const LocalAidRelation = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const { t } = useTranslation();
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    // const userInfo = useSelector((state) => {
    //     return state.auth.authData;
    // });
    // function getUserInfo() {
    //     if (
    //         userLogin?.Results &&
    //         userLogin?.Results.length > 0 &&
    //         userLogin?.Results[0]
    //     ) {
    //         return userLogin?.Results[0];
    //     }
    // }
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getAllCountries(lng == "arab" ? 1 : 2));
        dispatch(getRelationshipStatus(lng == "arab" ? 1 : 2));
    }, [lng]);

    const vlnRelations = useSelector((state) => state.vlnRelations);
    const [vlnvlnRelationsAll, setVlnvlnRelationsAll] = useState(vlnRelations);
    useEffect(() => {
        setVlnvlnRelationsAll(vlnRelations);
    }, [vlnRelations]);
    const [userInfoLocalAid, setUserInfoLocalAid] = useState({
        req_year: props.reqYear,
        relation_id: "",
        fam_name: "",
        fam_dob: "",
        country_id: "",
        req_id: props.reqID,
    });

    const vlnCountries = useSelector((state) => state.vlnCountries);
    const [vlnAllCountries, setVlnAllCountries] = useState(vlnCountries);
    useEffect(() => {
        setVlnAllCountries(vlnCountries);
    }, [vlnCountries]);
    const handleChange = (e) => {
        document.getElementById("submitBtn_for_local_aid").disabled = false;
        setUserInfoLocalAid({
            ...userInfoLocalAid,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (userInfoLocalAid.fam_dob.split('-')[0].length>4 || parseInt(userInfoLocalAid.fam_dob.split('-')[0]) <  1850) {
            return toastifyMessage("Please choose a valid date", undefined, "warning");
          }
        setLoadingModalShow(true)
        var dataToSend = {...userInfoLocalAid, fam_dob: userInfoLocalAid.fam_dob.split('-').reverse().join('/')};
        
        addLocalAidRelation(dataToSend)
            .then((res) => {
                setLoadingModalShow(false)
                //toastifyMessage("Success! Please fill the next form", undefined, "success");
                props.setSubmitRefetch(props.submitRefetch + 1)
                //props.setView(3)
                //history.push("/localAid/local-aid-relative-address");
                // /localAid/local - aid - relative - address
            })
            .catch((e) => {
                setLoadingModalShow(false)
                toastifyMessage("Something Went Wrong...!", undefined, "error");
            });

        // localAIDAddFamily(data).then((res) => {
        //   if (res.data.status) {
        //     alert("Success");
        //   } else {
        //     alert(res.data.result);
        //   }
        // });
    };

    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);

        return (
            <div
                className="local_aid_info_form_page"
                style={
                    lng === "arab"
                        ? { direction: "rtl", minHeight: "500px" }
                        : { minHeight: "500px" }
                }
            >
                <div className='identity_data_uploaded'>
                {props.allformsData?.family?.map((item,i)=>
                    <div key={i} className='identity_inserted_element'>
                      <label className='fontFor_iserted'>{t("Relation")}: <span style={{}}>{item.relationshipType}</span></label>
                      <label className='fontFor_iserted'>{t("Family name")}: <span style={{}}>{item.name}</span></label>
                      <label className='fontFor_iserted'>{t("Country")}: <span style={{}}>{item.countryName}</span></label>
                      <label className='fontFor_iserted'>{t("Date of Birth")}: <span style={{fontFamily:'english_font'}}>{item.dob}</span></label>
                    </div>
                    )}
                </div>
                <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
                    {t("Relative registration")}
                </label>
                <form className="local_aid_info_form" onSubmit={handleSubmit}>
                    <div className="just_the_form_without_submit">
                        <div className="form_left_side">
                            <div className="input_wraper">
                                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                                <select
                                    value={userInfoLocalAid.relation_id}
                                    onChange={handleChange}
                                    name="relation_id"
                                    className="selectedCourse"
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                >
                                    <option value="" disabled defaultValue>
                                        {t("Select relation")}
                                    </option>
                                    {vlnvlnRelationsAll?.map((item, i) => (
                                        <option key={i} value={item.relation_id}>
                                            {item.relation_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon1} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                                    value={userInfoLocalAid.fam_name}
                                    name="fam_name"
                                    placeholder={t("Family name")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>

                        </div>
                        <div className="form_right_side">


                            <div className="input_wraper">
                                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                                <select
                                    value={userInfoLocalAid.country_id}
                                    onChange={handleChange}
                                    name="country_id"
                                    className="selectedCourse"
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                >
                                    <option value="" disabled>
                                        {t("Select Country")}
                                    </option>
                                    {/* {vlnAllCountries?.map((item, i) => (
                                        <option value={"item.CountryId"}>
                                            {lng == "arab" ? item.Name_Ar : item.Name_En}
                                            {item.CountryId}
                                        </option>
                                    ))} */}

                                    {vlnAllCountries?.map((item, i) => (
                                        <option key={item.countryID} value={item.countryID}>
                                            {item.countryName}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}><label>{t("Date of Birth")}</label>
                                <div className="input_wraper" style={{ width: '100%' }}>
                                    <div className="local_aid_input_icon_wraper">
                                        <img
                                        src={icon5}
                                        className="local_aid_icon"
                                        alt="form_icon"
                                        />
                                    </div>
                                    <input
                                      type="date"
                                      onChange={handleChange}
                                      className="local_aid_input"
                                      value={userInfoLocalAid.fam_dob}
                                      // value="2014-10-13"
                                      name="fam_dob"
                                      required
                                      onInvalid={applyValidation}
                                      onInput={resetValidation}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                      min={`${new Date().getFullYear() - 100}-01-02`}
                                      max={`${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
                                    />
                                </div>
                            </div>



                        </div>
                    </div>
                    <button
                        id="submitBtn_for_local_aid"
                        type="submit"
                        className="local_aid_submit"
                        disabled
                    >
                        <span style={{ flex: "16" }}>{t("Save")}</span>
                        {lng === "arab" ? (
                            <i className="fas fa-arrow-left"></i>
                        ) : (
                            <i className="fas fa-arrow-right"></i>
                        )}
                    </button>
                </form>
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        );
};

export default LocalAidRelation;
