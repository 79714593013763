/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './CheckOutPage.css';
import Visa from '../../assets/visa.png';
import MasterCard from '../../assets/masterMethods.png';
import ApplePay from '../../assets/applePayMethods.png';
import samsung from '../../assets/samsun.png';
import BarCode from '../../assets/BarCode.png';
import RecieptLogo from '../../assets/logo.png';
import FavoritesCom from '../homePage/sections/favorites/favoritesCom/FavoritesCom';
import DonationFinalPopUp from './DonationFinalPopUp/DonationFinalPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../Auth/loginSignPage/loadingPopUp/loadingPopUp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getSamsungToken,
  handleSamsungPayment
} from '../../actions/handleSamsungPayment';
import { toastifyMessage } from '../../handlers/toastifyMessage';
import { handleCardPayment } from '../../actions/handleCardPayment';
import { startApplePay } from '../../actions/handleApplePayment';
import {
  deleteCard,
  getPaymentMethod,
  redirectURL,
  baseURL
} from '../../api/index';
import {
  graceRequest,
  encryptPaymentData,
  decryptPaymentData,
  createPaymentSession
} from '../../api/index';
import AddCardModal from './addCardModal/AddCardModal';
import { tokenize } from '../../api/account';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { addToFavorites, deleteFromFavorites } from '../../actions/BagFav';
import { FETCH_LOCALLY } from '../../constants/actionTypes';
import { handleRegularPayment } from '../../actions/handleRegularPayment';

toast.configure();
const CheckOutPage = (props) => {
  const isInitialMount = useRef(true);
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  let currentProfile = JSON.parse(localStorage.getItem('profile'));
  const [change, setChange] = useState(0);

  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem('profile'));
  }, [change, fetchLoaclly]);

  const userFav = useSelector((state) => state.favorites.favorites);
  useEffect(() => {
    if (userLogin) {
      let donationWithoutSukuk = userFav?.donations?.filter(
        (outerItem) =>
          userFav?.sukuks?.filter(
            (item) => item.dontypeID === outerItem.dontypeID
          ).length === 0
      );
      setDonationFav(donationWithoutSukuk);
    } else {
      setDonationFav(currentProfile?.liked?.donations);
    }
  }, [userFav, change, fetchLoaclly]);
  const [donationFav, setDonationFav] = useState(userFav);
  const loading = useSelector((state) => state.bag.loading);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadedBag, setLoadedBag] = useState(false);
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let visitor = JSON.parse(localStorage.getItem('visitorDetails'));
  const history = useHistory();

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });

  const [recieptFor, setRevieptFor] = useState('many');
  const [nameOfElement, setNameOfElement] = useState();
  const [token, setToken] = useState('');
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [useTokenOnly, setuseTokenOnly] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [checked, setChecked] = useState('adding new card');
  const [addCardModal, setAddCardModal] = useState(false);
  const donations = useSelector((state) => state.donations.donations);
  const [donationNotInTheBag, setDonationNotOnTheBag] = useState([]);
  const userBag = useSelector((state) => state.bag.bag);
  const [fromBag, setFromBag] = useState([]);
  const [paymentTokens, setPaymentTokens] = useState([]);
  const [newBag, setNewBag] = useState([]);
  const [localBag, setLocalBag] = useState(currentProfile);
  useEffect(() => {
    setLocalBag(currentProfile);
  }, []);

  useEffect(() => {
    let donationaInTheBag = [];
    let itemNotInTheBag = [];
    if (userLogin) {
      donationaInTheBag = userBag?.donations;
      for (let i = 0; i < donations.length; i++) {
        if (
          donationaInTheBag?.findIndex(
            (x) => x.dontypeID === donations[i].dontypeID
          ) < 0
        ) {
          itemNotInTheBag.push(donations[i]);
        }
      }
    } else if (currentProfile) {
      donationaInTheBag = currentProfile?.cart?.donations?.slice(0);
      for (let i = 0; i < donations.length; i++) {
        if (
          donationaInTheBag?.findIndex(
            (x) => x.donationId === donations[i].dontypeID
          ) < 0
        ) {
          itemNotInTheBag.push(donations[i]);
        }
      }
    } else {
      itemNotInTheBag = donations.slice(0);
    }
    setDonationNotOnTheBag(itemNotInTheBag);
  }, [userBag, donations, change]);

  let totalOfDonationsArr = currentProfile?.cart?.donations?.map((item) =>
    parseInt(item.amount)
  );
  let totalOfDonations = 0;
  let totalOfProjectsArr = 0;
  let totalOfProjects = 0;
  let totalOfSukuArr = 0;
  let totalOfSuku = 0;
  let totalOfhumanArr = 0;
  let totalOfhuman = 0;
  let totalOforphansArr = 0;
  let totalOforphans = 0;
  let totalLatePaymentArr = 0;
  let totalLatePayment = 0;
  let totalRenewalsArr = 0;
  let totalRenewals = 0;
  const [totalInBag, setTotalInBag] = useState(0);
  useEffect(() => {
    if (userLogin) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      totalOfDonationsArr = userBag?.donations?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfDonations = totalOfDonationsArr?.reduce(reducer, 0);

      totalOfProjectsArr = userBag?.projects?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfProjects = totalOfProjectsArr?.reduce(reducer, 0);

      totalOfSukuArr = userBag?.sukuks?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfSuku = totalOfSukuArr?.reduce(reducer, 0);

      totalOfhumanArr = userBag?.humanCases?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfhuman = totalOfhumanArr?.reduce(reducer, 0);

      totalOforphansArr = userBag?.orphans?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOforphans = totalOforphansArr?.reduce(reducer, 0);

      totalLatePaymentArr = userBag?.latePayments?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalLatePayment = totalLatePaymentArr?.reduce(reducer, 0);

      totalRenewalsArr = userBag?.renewalPayments?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalRenewals = totalRenewalsArr?.reduce(reducer, 0);

      setTotalInBag(
        totalOfDonations +
          totalOfProjects +
          totalOfSuku +
          totalOfhuman +
          totalOforphans +
          totalLatePayment +
          totalRenewals
      );
    } else if (currentProfile) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      totalOfDonations = totalOfDonationsArr?.reduce(reducer, 0);

      totalOfProjectsArr = currentProfile?.cart?.projects?.map((item) =>
        parseInt(item.projectAmount)
      );
      totalOfProjects = totalOfProjectsArr?.reduce(reducer, 0);

      totalOfSukuArr = currentProfile?.cart?.sukuks?.map((item) =>
        parseInt(item.amount)
      );
      totalOfSuku = totalOfSukuArr?.reduce(reducer, 0);

      totalOfhumanArr = currentProfile?.cart?.humanCases?.map((item) =>
        parseInt(item.donationAmountFromUser)
      );
      totalOfhuman = totalOfhumanArr?.reduce(reducer, 0);

      totalOforphansArr = currentProfile?.cart?.orphans?.map((item) =>
        parseInt(item.donationAmountFromUser)
      );
      totalOforphans = totalOforphansArr?.reduce(reducer, 0);

      setTotalInBag(
        totalOfDonations +
          totalOfProjects +
          totalOfSuku +
          totalOfhuman +
          totalOforphans
      );
    } else {
      setTotalInBag(0);
    }
  }, [userBag, change]);
  let mounted = true;
  const getPaymentTokens = async () => {
    await getPaymentMethod(userInfo?.Results[0]?.email).then((res) => {
      if (mounted) {
        setPaymentTokens(res.data.data);
      }
    });
  };
  useEffect(() => {
    getPaymentTokens();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    var samsungPay = localStorage.getItem('samsungDirectPay');
    if (
      (props.location.state?.from === '/volunteer/E-services') |
      (props.location.state?.from === 'donations') |
      (props.location.state?.from === 'humanCases') |
      (props.location.state?.from === 'projects') |
      (props.location.state?.from === 'orphans') |
      (props.location.state?.from === 'gifts') |
      (props.location.state?.from === 'renewals') |
      (props.location.state?.from === 'grace') |
      ((props.location.state?.from === 'projectShare') |
        (props.location.state?.from === 'projectShareCreate'))
    ) {
      setRevieptFor('one');
      setTotalInBag(props.location.state?.totalAmount);
      setNameOfElement(props.location.state);
    } else if (samsungPay) {
      setRevieptFor('one');
    } else if (props.location.state?.from === '/mybag') {
      setRevieptFor('many');
      if (userLogin) {
        setFromBag(userBag);
      } else if (currentProfile) {
        let newBag = {
          donations: [],
          projects: [],
          sukuks: [],
          humanCases: [],
          orphans: [],
          latePayments: []
        };
        for (const property in currentProfile?.cart) {
          for (let i = 0; i < currentProfile?.cart[property].length; i++) {
            if (property === 'donations') {
              let index = newBag.donations.findIndex(
                (x) =>
                  currentProfile?.cart[property][i].donationId ===
                    x.donationId &&
                  currentProfile?.cart[property][i].donationFreq ===
                    x.donationFreq
              );
              if (index < 0) {
                newBag.donations.push({
                  ...currentProfile?.cart[property][i],
                  quantity: 1
                });
              } else {
                newBag.donations.splice(index, 1, {
                  ...currentProfile?.cart[property][i],
                  quantity: newBag.donations[index].quantity + 1,
                  amount:
                    parseInt(newBag.donations[index].amount) +
                    parseInt(currentProfile?.cart[property][i].amount)
                });
              }
            } else if (property === 'projects') {
              let index = newBag.projects.findIndex(
                (x) =>
                  currentProfile?.cart[property][i].projectId === x.projectId &&
                  parseInt(currentProfile?.cart[property][i].projectYear) ===
                    parseInt(x.projectYear)
              );
              if (index < 0) {
                newBag.projects.push({
                  ...currentProfile?.cart[property][i],
                  quantity: 1
                });
              } else {
                newBag.projects.splice(index, 1, {
                  ...currentProfile?.cart[property][i],
                  quantity: newBag.projects[index].quantity + 1,
                  projectAmount:
                    parseInt(newBag.projects[index].projectAmount) +
                    parseInt(currentProfile?.cart[property][i].projectAmount)
                });
              }
            } else if (property === 'sukuks') {
              let index = newBag.sukuks.findIndex(
                (x) =>
                  currentProfile?.cart[property][i].sukId === x.sukId &&
                  currentProfile?.cart[property][i].donationFreq ===
                    x.donationFreq
              );
              if (index < 0) {
                newBag.sukuks.push({
                  ...currentProfile?.cart[property][i],
                  quantity: 1
                });
              } else {
                newBag.sukuks.splice(index, 1, {
                  ...currentProfile?.cart[property][i],
                  quantity: newBag.sukuks[index].quantity + 1,
                  amount:
                    parseInt(newBag.sukuks[index].amount) +
                    parseInt(currentProfile?.cart[property][i].amount)
                });
              }
            } else if (property === 'humanCases') {
              let index = newBag.humanCases.findIndex(
                (x) => currentProfile?.cart[property][i].humanId === x.humanId
              );
              if (index < 0) {
                newBag.humanCases.push(currentProfile?.cart[property][i]);
              } else {
                newBag.humanCases.splice(index, 1, {
                  ...currentProfile?.cart[property][i],
                  donationAmountFromUser:
                    parseInt(newBag.humanCases[index].donationAmountFromUser) +
                    parseInt(
                      currentProfile?.cart[property][i].donationAmountFromUser
                    )
                });
              }
            } else if (property === 'orphans') {
              let index = newBag.orphans.findIndex(
                (x) =>
                  currentProfile?.cart[property][i].orphanId === x.orphanId &&
                  currentProfile?.cart[property][i].orphanCountryId ===
                    x.orphanCountryId &&
                  parseInt(currentProfile?.cart[property][i].baseAmount) ===
                    parseInt(x.baseAmount) &&
                  currentProfile?.cart[property][i].paymentWay === x.paymentWay
              );
              if (index < 0) {
                newBag.orphans.push({
                  ...currentProfile?.cart[property][i],
                  quantity: 1
                });
              } else {
                newBag.orphans.splice(index, 1, {
                  ...currentProfile?.cart[property][i],
                  quantity: newBag.orphans[index].quantity + 1,
                  donationAmountFromUser:
                    parseInt(newBag.orphans[index].donationAmountFromUser) +
                    parseInt(
                      currentProfile?.cart[property][i].donationAmountFromUser
                    )
                });
              }
            }
          }
        }
        setFromBag(newBag);
      } else {
        setFromBag([]);
      }
    } else {
      var url = new URL(window.location.href);
      var ref_id = url.searchParams.get('ref_id');
      var samsung_status = url.searchParams.get('status');
      if (ref_id || samsung_status) {
        // Don't redirect to home if the user came from a Samsung Pay Callback URL
      } else history.push('/');
    }
  }, [props.location, userBag, change]);

  useEffect(() => {
    const fetchData = async () => {
      if (nameOfElement != null) {
        if (nameOfElement?.from === 'orphans') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 1,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 1,
            amount: nameOfElement.details.donationAmountFromUser,
            recuring: nameOfElement.details.paymentWay
              ? nameOfElement.details.paymentWay
              : 'N',
            month_count: nameOfElement.details.years * 12,
            orptype_id: nameOfElement.details.orphanId,
            country_id: nameOfElement.details.orphanCountryId,
            workPlaceID: nameOfElement.details.workPlaceID
          };
          if (nameOfElement.details.paymentWay === 'Y') {
            setuseTokenOnly(true);
          }

          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'renewals') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 1,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 5,
            amount: nameOfElement.elements[0].amount,
            recuring: 'N',
            month_count: nameOfElement.elements[0].month_count,
            orphan_id: nameOfElement.elements[0].orphan_id,
            sponsor_id: nameOfElement.elements[0].sponsor_id
          };
          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'donations') {
          let donateNow = {
            cart_serial: null,
            dontype_id: nameOfElement.details.donationId
              ? nameOfElement.details.donationId
              : nameOfElement.details.sukId,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 0,
            amount: nameOfElement.details.amount,
            recuring: nameOfElement.details.recuring
              ? nameOfElement.details.recuring
              : nameOfElement.details.donationFreq
          };
          donateNow.recuring == 'N'
            ? setuseTokenOnly(false)
            : setuseTokenOnly(true);
          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'humanCases') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 158,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 4,
            amount: nameOfElement.details.donationAmountFromUser,
            humanCase: nameOfElement.details.humanId,
            humanYear: nameOfElement.details.caseYear
          };
          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'projects') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 8,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 2,
            amount: nameOfElement.details.projectAmount,
            projectYear: nameOfElement.details.projectYear,
            template_id: nameOfElement.details.projectId,
            country_id: nameOfElement.details.countryId,
            workPlaceID: nameOfElement.details.workPlaceID
          };
          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'gifts') {
          let donateNow = nameOfElement.details.map((item) => {
            return {
              cart_serial: null,
              dontype_id: 1,
              doner_name: nameOfElement.userName
                ? nameOfElement.userName
                : nameOfElement.doner_name
                ? nameOfElement.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: nameOfElement.userMob
                ? nameOfElement.userMob
                : nameOfElement.mobile
                ? nameOfElement.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: nameOfElement.userEmail
                ? nameOfElement.userEmail
                : nameOfElement.email
                ? nameOfElement.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 7,
              amount: item.amount,
              orphan_id: item.orphan_id,
              remarks: item.remarks,
              sponsor_id: item.sponsor_id
            };
          });
          setNewBag(donateNow);
        } else if (nameOfElement?.from === '/volunteer/E-services') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 161,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 3,
            amount: nameOfElement.details.cost,
            vln_id: nameOfElement.details.vln_id,
            vln_service_cod: nameOfElement.details.vln_service_cod,
            vln_date_from: nameOfElement.details.vln_date_from
              .split('-')
              .reverse()
              .join('/'),
            vln_date_to: nameOfElement.details.vln_date_to
              ? nameOfElement.details.vln_date_to.split('-').reverse().join('/')
              : null,
            vln_desc: nameOfElement.details.vln_desc
          };
          setNewBag((oldArray) => [donateNow]);
        } else if (nameOfElement?.from === 'grace') {
          const query = `?branchID=${nameOfElement.details.workplaceID}&requestType=${nameOfElement.details.type_id}&locY=${nameOfElement.details.latitude}&locX=${nameOfElement.details.longitude}&remarks=${nameOfElement.details.remarks}&contactName=${nameOfElement.details.name}&contactPhone=${nameOfElement.details.mobile}&pickupDate=${nameOfElement.details.pickupDate}&language=${nameOfElement.details.contactLanguage}`;
          // await graceRequest(query).then((data) => {
          //   if (data.data.status === true) {

          //   }
          // });
          let donateNow = {
            cart_serial: null,
            dontype_id: 97,
            doner_name: userLogin
              ? userLogin?.Results[0]?.name_a
              : visitor
              ? visitor.name
              : '',
            mobile: userLogin
              ? userLogin?.Results[0]?.mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: userLogin
              ? userLogin?.Results[0]?.email
              : visitor
              ? visitor.email
              : '',
            item_type: 0,
            amount: nameOfElement.details.amount,
            recurring: 'N'
            // "orphan_id": nameOfElement.details.orphan_id,
            // "remarks": nameOfElement.details.remarks,
            // "sponsor_id": nameOfElement.details.sponsor_id
          };
          setNewBag((oldArray) => [donateNow]);
        } else if (nameOfElement?.from === 'projectShare') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 8,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 8,
            amount: nameOfElement.details.donationAmountFromUser,
            projectYear: nameOfElement.details.projectYear,
            template_id: nameOfElement.details.projectID,
            country_id: nameOfElement.details.countryId,
            share_id: nameOfElement.details.projectShareID,
            share_year: nameOfElement.details.projectShareYear,
            prj_name: nameOfElement.details.title
          };
          setNewBag([donateNow]);
        } else if (nameOfElement?.from === 'projectShareCreate') {
          let donateNow = {
            cart_serial: null,
            dontype_id: 8,
            doner_name: nameOfElement.userName
              ? nameOfElement.userName
              : nameOfElement.doner_name
              ? nameOfElement.doner_name
              : userLogin
              ? userLogin.Results[0].name_a
              : visitor
              ? visitor.name
              : '',
            mobile: nameOfElement.userMob
              ? nameOfElement.userMob
              : nameOfElement.mobile
              ? nameOfElement.mobile
              : userLogin
              ? userLogin.Results[0].mobile_no
              : visitor
              ? visitor.phone
              : '',
            email: nameOfElement.userEmail
              ? nameOfElement.userEmail
              : nameOfElement.email
              ? nameOfElement.email
              : userLogin
              ? userLogin.Results[0].email
              : visitor
              ? visitor.email
              : '',
            item_type: 8,
            amount: nameOfElement.details.donationAmountFromUser,
            projectYear: nameOfElement.details.projectYear,
            template_id: nameOfElement.details.projectID,
            country_id:
              nameOfElement.details.countryId ||
              nameOfElement.details.countryID,
            prj_name: nameOfElement.details.title,
            public_flag: nameOfElement.details.public_flag
          };
          setNewBag([donateNow]);
        }
      } else if (!userLogin) {
        setNewBag([]);

        let temp = [];
        temp = currentProfile?.cart?.donations?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: null,
              dontype_id: item.donationId,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 0,
              amount: item.amount,
              recuring: item.recuring
                ? item.recuring
                : item.recurring
                ? item.recurring
                : item.donationFreq
                ? item.donationFreq
                : 'N'
            }
          ]);
        });
        temp = currentProfile?.cart?.humanCases?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: null,
              dontype_id: 158,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 4,
              amount: item.donationAmountFromUser,
              humanCase: item.humanId,
              humanYear: item.caseYear
            }
          ]);
        });
        temp = currentProfile?.cart?.orphans?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: null,
              dontype_id: 1,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 1,
              amount: item.baseAmount,
              recuring: item.paymentWay ? item.paymentWay : 'N',
              month_count: item.years * 12,
              orptype_id: item.orphanId,
              country_id: item.orphanCountryId,
              workPlaceID: item.workPlaceID
            }
          ]);
        });
        temp = currentProfile?.cart?.projects?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: null,
              dontype_id: 8,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 2,
              amount: item.projectAmount,
              projectYear: item.projectYear,
              country_id: item.countryId,
              template_id: item.projectId,
              workPlaceID: item.workPlaceID
            }
          ]);
        });
        temp = currentProfile?.cart?.sukuks?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: null,
              dontype_id: item.sukId,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 0,
              amount: item.amount,
              recuring: item.donationFreq ? item.donationFreq : 'N'
            }
          ]);
        });
      } else {
        setNewBag([]);
        let temp = [];
        temp = userBag?.donations?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: item.dontypeID,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 0,
              amount: item.baseAmount,
              recuring: item.recurring ? item.recurring : 'N',
              quantity: item.quantity ? item.quantity : 1
            }
          ]);
        });
        temp = userBag?.humanCases?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: 158,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 4,
              amount: item.baseAmount,
              humanCase: item.humanCaseID,
              humanYear: item.humanCaseYear,
              quantity: item.quantity ? item.quantity : 1
            }
          ]);
        });
        temp = userBag?.latePayments?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: 1,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 6,
              amount: item.baseAmount,
              transaction_number: item.trn_serial,
              transaction_year: item.trn_year,
              orphan_id: item.orphanID,
              sponsor_id: item.sponsor_id
                ? item.sponsor_id
                : userLogin?.Results[0]?.sponser_id,
              quantity: item.quantity ? item.quantity : 1
            }
          ]);
        });

        temp = userBag?.renewalPayments?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: 1,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 5,
              amount: item.baseAmount,
              orphan_id: item.orphanID,
              sponsor_id: item.sponsor_id
                ? item.sponsor_id
                : userLogin?.Results[0]?.sponser_id,
              quantity: item.quantity ? item.quantity : 1,
              month_count: item.month_count
            }
          ]);
        });
        temp = userBag?.orphans?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: 1,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 1,
              amount: item.baseAmount,
              recuring: item.recurring ? item.recurring : 'N',
              // month_count: item.years * 12,
              month_count: item.recurring === 'Y' ? '1' : item.month_count,
              orptype_id: item.orphanTypeID,
              country_id: item.countryID,
              quantity: item.quantity ? item.quantity : 1,
              workPlaceID: item.wrkplc_id
            }
          ]);
        });
        temp = userBag?.projects?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: 8,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 2,
              amount: item.baseAmount,
              projectYear: item.projectYear,
              template_id: item.projectID,
              country_id: item.countryID,
              quantity: item.quantity ? item.quantity : 1,
              workPlaceID: item.wrkplc_id
            }
          ]);
        });
        temp = userBag?.sukuks?.map((item) => {
          setNewBag((oldArray) => [
            ...oldArray,
            {
              cart_serial: item.cart_serial,
              dontype_id: item.dontypeID,
              doner_name: item.userName
                ? item.userName
                : item.doner_name
                ? item.doner_name
                : userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : '',
              mobile: item.userMob
                ? item.userMob
                : item.mobile
                ? item.mobile
                : userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : '',
              email: item.userEmail
                ? item.userEmail
                : item.email
                ? item.email
                : userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : '',
              item_type: 0,
              amount: item.baseAmount,
              recuring: item.recurring ? item.recurring : 'N',
              quantity: item.quantity ? item.quantity : 1
            }
          ]);
        });
      }
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        setLoadedBag(true);
      }
    };
    fetchData();
  }, [nameOfElement, userBag, localBag]);
  useEffect(() => {
    if (nameOfElement != null) return;
    let anyRecurring = [];
    anyRecurring = newBag?.filter((item) => {
      return (
        item.recuring === 'Y' || item.recuring === 'D' || item.recuring === 'M'
      );
    });
    if (anyRecurring.length > 0) {
      setuseTokenOnly(true);
    }
  }, [nameOfElement, newBag]);
  useEffect(() => {
    const samsungPay = async () => {
      var url = new URL(window.location.href);
      var ref_id = url.searchParams.get('ref_id');
      var samsung_status = url.searchParams.get('status');
      if (loadedBag) {
        if (ref_id) {
          if (ref_id) {
            setLoadingModalShow(true);
            var res = await getSamsungToken();
            if (res.status) {
              let obj = JSON.parse(localStorage.getItem('samsungDirectPay'));
              localStorage.removeItem('samsungDirectPay');
              var res = await handleRegularPayment(
                {
                  tokn: JSON.parse(res.data)['3DS'].data,
                  payment_type: 'SAMSUNG',
                  bag: obj ? obj.newBag : newBag
                },
                dispatch,
                null,
                recieptFor
              );
              if (res) {
                if (res.status) history.push(`/receipt/${res.mco}`);
              }
            }
            setLoadingModalShow(false);
          }
        }
      }
      if (!window.ApplePaySession) {
        return;
      }
    };
    samsungPay();
  }, [loadedBag]);
  const handleCheck = (str, data) => {
    setChecked(str);
    if (str === 'token') {
      setToken(data.token);
    } else if (str === 'ApplePay') {
      setToken(str);
    } else if (str === 'SamsungPay') {
      setToken(str);
    } else {
      setToken(str);
    }
  };
  const handleRegularPayment_Inner = async (tokenToSave, sessionData) => {
    if (checked === 'ApplePay') {
      startApplePay(
        { amount: totalInBag, cart: newBag },
        dispatch,
        history,
        recieptFor
      );
    } else if (checked === 'SamsungPay') {
      nameOfElement
        ? localStorage.setItem('samsungDirectPay', JSON.stringify({ newBag }))
        : null;
      setLoadingModalShow(true);
      await handleSamsungPayment({
        amount: totalInBag,
        url: window.location.href
      });
      setLoadingModalShow(false);
    } else if (checked === 'adding new card' && !addCardModal) {
      setAddCardModal(true);
    } else {
      setLoadingModalShow(true);
      var res = await handleCardPayment(
        {
          tokn: checked === 'token' ? token : null,
          payment_type: 'NEWCARD',
          cart: newBag,
          captcha: sessionData?.captcha
        },
        dispatch,
        tokenToSave,
        recieptFor
      );
      if (checked === 'token') {
        let sessionType = {
          amount: totalInBag,
          actionType: 'TOKEN_PAY',
          token: token,
          userEmail: userLogin?.Results[0]?.email
        };
        let resultSession = await createPaymentSession(sessionType);
        let tokenSession = {
          ...sessionType,
          redirectUrl: window.location.origin,
          session: resultSession.data.session.id,
          mco: res.mco,
          actionType: 'TOKEN_PAY'
        };
        //process.env.NODE_ENV === "production"
        let encrypted_data = await encryptPaymentData(tokenSession);
        var form = document.createElement('form');
        var element1 = document.createElement('input');

        form.method = 'POST';
        form.action = `${redirectURL}/AuthGetData.aspx`;

        element1.value = encrypted_data.data.data;
        element1.name = 'sessionData';
        form.appendChild(element1);
        // window.location.replace(`${redirectURL}/Auth.aspx?sessionData=` + encodeURIComponent(encrypted_data.data.data));
        document.body.appendChild(form);

        form.submit();
      } else if (nameOfElement?.from === 'grace') {
        const query = `${baseURL}/grace_request?branchID=${nameOfElement.details.workplaceID}&requestType=${nameOfElement.details.type_id}&locY=${nameOfElement.details.latitude}&locX=${nameOfElement.details.longitude}&remarks=${nameOfElement.details.remarks}&contactName=${nameOfElement.details.name}&contactPhone=${nameOfElement.details.mobile}&pickupDate=${nameOfElement.details.pickupDate}&language=${nameOfElement.details.contactLanguage}`;

        sessionData = {
          ...sessionData,
          graceUrl: query,
          redirectUrl: window.location.origin,
          mco: res.mco,
          actionType: 'GRACE_PAY'
        };
        let encrypted_data = await encryptPaymentData(sessionData);
        var form = document.createElement('form');
        var element1 = document.createElement('input');

        form.method = 'POST';
        form.action = `${redirectURL}/AuthGetData.aspx`;

        element1.value = encrypted_data.data.data;
        element1.name = 'sessionData';
        form.appendChild(element1);
        // window.location.replace(`${redirectURL}/Auth.aspx?sessionData=` + encodeURIComponent(encrypted_data.data.data));
        document.body.appendChild(form);

        form.submit();
        setLoadingModalShow(false);
      } else {
        sessionData = {
          ...sessionData,
          redirectUrl: window.location.origin,
          mco: res.mco
        };
        let encrypted_data = await encryptPaymentData(sessionData);
        var form = document.createElement('form');
        var element1 = document.createElement('input');

        form.method = 'POST';
        form.action = `${redirectURL}/AuthGetData.aspx`;

        element1.value = encrypted_data.data.data;
        element1.name = 'sessionData';
        form.appendChild(element1);
        // window.location.replace(`${redirectURL}/Auth.aspx?sessionData=` + encodeURIComponent(encrypted_data.data.data));
        document.body.appendChild(form);

        form.submit();
        setLoadingModalShow(false);
      }
    }
  };
  function donateNow() {
    if (!checked) {
      toastifyMessage('Please choose a payment method', undefined, 'warning');
    } else {
      handleRegularPayment_Inner();
    }
  }

  const handleLike = async (donationID) => {
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          'profile',
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: []
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] }
          })
        );
        currentProfile = JSON.parse(localStorage.getItem('profile'));
      }
      dispatch({ type: 'addLikeLocally', payload: donationID });
      currentProfile.liked.donations.push(donationID);
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked
      };
      localStorage.setItem('profile', JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
    } else {
      await dispatch(
        addToFavorites(lng === 'arab' ? 1 : 2, {
          userEmail: userLogin.Results[0]?.email,
          dontypeID: donationID.donationId,
          projectID: '',
          orptypeID: '',
          projectYear: '',
          countryID: ''
        })
      );
    }
  };
  const handleDisLike = async (donationID) => {
    if (!userLogin) {
      let newProfile = {
        cart: currentProfile.cart,
        liked: {
          donations: currentProfile.liked.donations.filter(
            (item) => item.donationId !== donationID.donationId
          ),
          sukuks: currentProfile.liked.sukuks,
          projects: currentProfile.liked.projects,
          orphans: currentProfile.liked.orphans
        }
      };
      localStorage.setItem('profile', JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
    } else {
      let elementWeNeed = donationFav
        ?.filter((item) => item.dontypeID === donationID.donationId)
        .slice(0, 1)
        .shift();
      if (elementWeNeed) {
        await dispatch(
          deleteFromFavorites(
            lng === 'arab' ? 1 : 2,
            userLogin.Results[0]?.email,
            elementWeNeed.cart_serial
          )
        );
      }
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  const handleAddCardModal = () => {
    setAddCardModal(false);
    window.location.reload();
  };
  return (
    <div
      className="check_out_page"
      style={
        lng === 'arab'
          ? { direction: 'rtl', padding: width <= 900 ? '0px' : null }
          : { padding: width <= 900 ? '0px' : null }
      }
    >
      <ShareHeader pageName={t('Donation Summary')} noShare={true} />
      <div
        className="checkOut_total"
        style={{ padding: width <= 900 ? '10px' : null }}
      >
        <div
          className="checkOut_left_side"
          style={{
            borderLeft: lng === 'arab' ? '1px solid gray' : 'none',
            borderRight: lng === 'arab' ? 'none' : '1px solid gray'
          }}
        >
          <h5
            style={{
              fontWeight: 'normal',
              marginLeft: '10px',
              display: width <= 500 ? 'none' : null
            }}
          >
            {t('Select payment Method')}
          </h5>
          <div className="payment_methods_body">
            {paymentTokens?.map((card, index) => {
              return (
                <div
                  key={index}
                  className="check_box_item_wraper"
                  onClick={() => handleCheck('token', { token: card })}
                >
                  <div className="check_box_item">
                    <div
                      className={
                        checked === 'token' && token === card
                          ? 'check_radio_selected'
                          : 'check_radio_unselected'
                      }
                    ></div>
                    <label
                      className="payment_numbers label_of_title"
                      style={{
                        direction: 'ltr',
                        fontFamily: 'english_font',
                        height: 'auto'
                      }}
                      htmlFor="visa"
                    >
                      {card.slice(0, 5) + '****' + card.slice(8)}
                    </label>
                  </div>
                  <img
                    src={card?.startsWith('5') ? MasterCard : Visa}
                    alt="card"
                    className="payment_methods_icons"
                  />
                </div>
              );
            })}

            {useTokenOnly ? null : (
              <React.Fragment>
                {!window.ApplePaySession ? (
                  <div
                    className="check_box_item_wraper"
                    onClick={() => handleCheck('SamsungPay')}
                  >
                    <div className="check_box_item">
                      <div
                        className={
                          checked === 'SamsungPay'
                            ? 'check_radio_selected'
                            : 'check_radio_unselected'
                        }
                      ></div>
                      <label
                        className="label_of_title"
                        style={{ height: 'auto' }}
                        htmlFor="SamsungPay"
                      >
                        {t('Samsung Pay')}
                      </label>
                    </div>
                    <img
                      id="applePay_icon"
                      src={samsung}
                      alt="donate_btn_icon_banner"
                      className="sukuk_btn_img_general"
                    />
                  </div>
                ) : (
                  <div
                    className="check_box_item_wraper"
                    onClick={() => handleCheck('ApplePay')}
                  >
                    <div className="check_box_item">
                      <div
                        className={
                          checked === 'ApplePay'
                            ? 'check_radio_selected'
                            : 'check_radio_unselected'
                        }
                      ></div>
                      <label
                        className="label_of_title"
                        style={{ height: 'auto' }}
                        htmlFor="ApplePay"
                      >
                        {t('Apple Pay')}
                      </label>
                    </div>
                    <img
                      id="applePay_icon"
                      src={ApplePay}
                      alt="ApplePay"
                      className="apple_pay_icon"
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            <div
              className="check_box_item_wraper"
              onClick={() => handleCheck('adding new card')}
            >
              <div className="check_box_item">
                <div
                  className={
                    checked === 'adding new card'
                      ? 'check_radio_selected'
                      : 'check_radio_unselected'
                  }
                ></div>
                <label
                  className="label_of_title"
                  style={{ height: 'auto' }}
                  htmlFor="ApplePay"
                >
                  {t('Add Credit/Debit Card')}
                </label>
              </div>
            </div>
            <button
              onClick={() =>
                !(userLogin || visitor) ? setModalShow(true) : donateNow()
              }
              className="Donate_now_add_to_cart title_to_be_remove smallBtn_smallDevice"
              style={{ width: '80%', alignSelf: 'center', marginTop: '30px' }}
            >
              {t('Donate Now')}
            </button>
          </div>
        </div>
        <div className="checkOut_right_side">
          <h5 className="title_to_be_remove" style={{ fontWeight: 'bold' }}>
            {t('Donation Summary')}
          </h5>
          <div className="reciept">
            <img
              src={RecieptLogo}
              alt="ERC_logo"
              className="ERC_logo_for_reciept"
            />
            <div className="reciept_box_title">
              <label style={{ color: '#ee4236' }}>{t('Receipt')}</label>
            </div>
            {recieptFor === 'one' ? (
              <table>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="label_of_title" style={{ padding: '10px' }}>
                      {t('Type')}
                    </th>
                    <th className="label_of_title" style={{ padding: '10px' }}>
                      {t('Amount')}
                    </th>
                  </tr>
                  {nameOfElement?.elements.map((item, i) => (
                    <tr key={`service_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        className="label_of_title"
                        style={{ padding: '20px 10px 10px 10px' }}
                      >
                        {item.title}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '20px 10px 10px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">{item.price}</span>
                      </td>
                    </tr>
                  ))}
                  <tr style={{ textAlign: 'center' }}>
                    <td
                      className="label_of_title titleSmall_deviceSmall"
                      style={{
                        padding: width > 900 ? '20px' : '10px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}
                    >
                      {t('Total')}
                    </td>
                    <td
                      className="label_of_title titleSmall_deviceSmall"
                      style={{
                        padding: width > 900 ? '20px' : '10px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}
                    >
                      {t('AED')}{' '}
                      <span className="payment_numbers">
                        {isNaN(nameOfElement?.totalAmount)
                          ? 0
                          : nameOfElement?.totalAmount}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="label_of_title" style={{ padding: '10px' }}>
                      {t('Type')}
                    </th>
                    <th className="label_of_title" style={{ padding: '10px' }}>
                      {t('Amount')}
                    </th>
                  </tr>
                  {fromBag?.donations?.map((item, i) => (
                    <tr key={`donation_${i}`} style={{ textAlign: 'center' }}>
                      {!userLogin ? (
                        <td
                          className="label_of_title"
                          style={{ padding: '20px 10px 10px 10px' }}
                        >
                          {item.title ? item.title : item.campName}
                        </td>
                      ) : (
                        <td
                          className="label_of_title"
                          style={{ padding: '20px 10px 10px 10px' }}
                        >
                          {item.name}
                        </td>
                      )}
                      <td
                        className="label_of_title"
                        style={{ padding: '20px 10px 10px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {userLogin ? item.displayAmount : item.amount}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {fromBag?.sukuks?.map((item, i) => (
                    <tr key={`sukuk_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px' }}
                        className="label_of_title"
                      >
                        {userLogin ? item.name : item.title}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {userLogin ? item.displayAmount : item.amount}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {fromBag?.humanCases?.map((item, i) => (
                    <tr key={`humanCases_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px' }}
                        className="label_of_title"
                      >
                        {userLogin ? item.caseTitle : item.title}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {userLogin
                            ? item.displayAmount
                            : item.donationAmountFromUser}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {fromBag?.orphans?.map((item, i) => (
                    <tr key={`orphans_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px 10px 20px 10px' }}
                        className="label_of_title"
                      >
                        {userLogin ? item.orpTypeName : item.orphanName}{' '}
                        {t('at')}{' '}
                        {userLogin ? item.countryName : item.orphanCountryName}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px 10px 20px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {userLogin
                            ? item.displayAmount
                            : item.donationAmountFromUser}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {fromBag?.projects?.map((item, i) => (
                    <tr key={`projects_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px 10px 20px 10px' }}
                        className="label_of_title"
                      >
                        {userLogin ? item.projectName : item.projectName}{' '}
                        {t('at')}{' '}
                        {userLogin ? item.countryName : item.countryName}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px 10px 20px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {userLogin ? item.displayAmount : item.projectAmount}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {fromBag?.latePayments?.map((item, i) => (
                    <tr key={`projects_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px 10px 20px 10px' }}
                        className="label_of_title"
                      >
                        {t('Late Payment')} {t('for')} {item.orphan_name}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px 10px 20px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {item.displayAmount}
                        </span>
                      </td>
                    </tr>
                  ))}

                  {fromBag?.renewalPayments?.map((item, i) => (
                    <tr key={`projects_${i}`} style={{ textAlign: 'center' }}>
                      <td
                        style={{ padding: '10px 10px 20px 10px' }}
                        className="label_of_title"
                      >
                        {t('Renewals')} {t('for')} {item.orphan_name}
                      </td>
                      <td
                        className="label_of_title"
                        style={{ padding: '10px 10px 20px 10px' }}
                      >
                        {t('AED')}{' '}
                        <span className="payment_numbers">
                          {item.displayAmount}
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr style={{ textAlign: 'center' }}>
                    <td
                      className="titleSmall_deviceSmall"
                      style={{
                        padding: width > 900 ? '20px' : '10px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}
                    >
                      {t('Total')}
                    </td>
                    <td
                      className="titleSmall_deviceSmall"
                      style={{
                        padding: width > 900 ? '20px' : '10px',
                        fontSize: '20px',
                        ontWeight: 'bold'
                      }}
                    >
                      {t('AED')}{' '}
                      <span className="payment_numbers">
                        {isNaN(totalInBag) ? 0 : totalInBag}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <div className="reciept_total_wraper">
              <div className="reciept_ball rightBall" />
              <div className="reciept_ball leftBall" />
            </div>
            <div className="reciept_barCode_wraper">
              <img className="barCode" src={BarCode} alt="bar_code" />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() =>
          !(userLogin || visitor) ? setModalShow(true) : donateNow()
        }
        className="btn_for_mob_only smallBtn_smallDevice"
      >
        {t('Donate Now')}
        {lng === 'arab' ? (
          <i className="fas fa-long-arrow-alt-left"></i>
        ) : (
          <i className="fas fa-long-arrow-alt-right"></i>
        )}
      </button>
      {recieptFor === 'one' ? null : (
        <div className="my_bag_bottom" style={{ width: '100%' }}>
          <h5 style={{ fontWeight: 'bold' }}>
            {t('You May Also Donate For...')}
          </h5>

          <div className="donation_display_my_bag" style={{ width: '100%' }}>
            {donationNotInTheBag?.slice(0, 3)?.map((item) => (
              <FavoritesCom
                change={change}
                donationFav={donationFav}
                setChange={setChange}
                handleLike={handleLike}
                handleDisLike={handleDisLike}
                currentProfile={currentProfile}
                key={item.dontypeID}
                donationID={item.dontypeID}
                DonImg={item.imagePath}
                amount={parseInt(item.amount)}
                title={item.dontypeName}
              />
            ))}
          </div>
        </div>
      )}
      <AddCardModal
        paymentamount={isNaN(totalInBag) ? 0 : totalInBag}
        handleregularpayment_inner={handleRegularPayment_Inner}
        frompopup="true"
        nooption={useTokenOnly}
        show={addCardModal}
        backdrop="static"
        onHide={handleAddCardModal}
      />
      <DonationFinalPopUp
        show={modalShow}
        fromcheckout={true}
        backdrop="static"
        onHide={() => setModalShow(false)}
      />

      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};

export default CheckOutPage;
