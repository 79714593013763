import { FETCH_ALL_COMPAGINS_SLIDERS, FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING, FETCH_ALL_COMPAGINS_SLIDERS_LOADING } from '../constants/actionTypes'

export default (compaginSliders = {
    compaginSliders: [], 
    loading: true
}, action) => {
    switch (action.type) {
        case FETCH_ALL_COMPAGINS_SLIDERS:
            return {...compaginSliders, compaginSliders:action.payload, loading: false};
        case FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING :
            return { ...compaginSliders, loading: false };
        case FETCH_ALL_COMPAGINS_SLIDERS_LOADING:
      return { ...compaginSliders, loading: true };
        default:
            return compaginSliders;
    }
}