import './events.css'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getVolunteerEvents } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
const Events = () => {
    
      const [value, setValue] = useState(new Date());
      const [event, setEvent] = useState([])
      function onChange(nextValue) {
        setEvent(vlnEventsAll.filter((i) => moment(i.date).format('DD/MM/YYYY') === moment(nextValue).format('DD/MM/YYYY')))
        setValue(nextValue);
      }
      useEffect(() => {
        if(event.length === 1)
        {
            history.push(`/volunteer/event?no=${event[0].req_id}&at=${event[0].req_year}`)   
        }
      }, [event])  
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const history = useHistory();
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getVolunteerEvents(userLogin?.Results[0]?.vln_id, 1))
            } else {
                dispatch(getVolunteerEvents(userLogin?.Results[0]?.vln_id, 2))
            }
        }
    }, [lng]);
    const vlnEventsSelector = useSelector(state => state.vlnEvents.vlnEvents);
    const loading = useSelector(state => state.vlnEvents.loading);
    const [vlnEventsAll, setVlnEventsAll] = useState(vlnEventsSelector)
    useEffect(() => {
        setVlnEventsAll(vlnEventsSelector?.map((item, i) => {
            // converting the dd/mm/yyyy format coming from the API to mm/dd/yyyy for formatting issues
            return {...item, date: new Date(item.date.split('/')[1] + "/" + item.date.split('/')[0] + "/" + item.date.split('/')[2])}
        }))
    }, [vlnEventsSelector])
    var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
       };
    const datesToAddContentTo = vlnEventsSelector?.map((item, i) => {
        // converting the dd/mm/yyyy format coming from the API to mm/dd/yyyy for formatting issues
        return new Date(item.date.split('/')[1] + "/" + item.date.split('/')[0] + "/" + item.date.split('/')[2])
    });
    function isSameDay(a, b) {
        return differenceInCalendarDays(a, b) === 0;
      }
      function tileContent({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
          // Check if a date React-Calendar wants to check is on the list of dates to add class to
          if (datesToAddContentTo.find(dDate => isSameDay(dDate, date))) {
            return '*';
          }
        }
      }
      function tileClassName({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
          // Check if a date React-Calendar wants to check is on the list of dates to add class to
          if (datesToAddContentTo.find(dDate => isSameDay(dDate, date))) {
            return 'event-found';
          }
        }
      }
    const handleClick = (item) => {
        history.push(`/volunteer/event?no=${item.req_id}&at=${item.req_year}`)
    }
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Events")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Events")}</h3>
                </div>
                <Calendar   onChange={onChange}       tileContent={tileContent}
 value={value} 
 tileClassName={tileClassName}

 />

                {loading ? <Loading /> : <div className='relatives_display'>
                    {vlnEventsAll?.map((item, i) =>
                        <div key={i} style={{ cursor: 'pointer' }} onClick={() => handleClick(item)} className='course_Item'>
                            <label style={{ fontWeight: 'bold' }}>{item?.req_title}</label>
                        </div>
                    )}
                </div>}
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default Events

// import './events.css'
// import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import { useTranslation } from "react-i18next";
// import { getVolunteerEvents } from '../../../actions/volunteer'
// import { useDispatch } from 'react-redux'
// import { useHistory } from 'react-router';
// import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
// import Loading from '../../ui/loading';
// const Events = () => {
//     let userLogin = JSON.parse(localStorage.getItem('userLogin'))
//     const { t } = useTranslation();
//     let lng = JSON.parse(localStorage.getItem('language'))?.lang;
//     const dispatch = useDispatch()
//     const history = useHistory();
//     useEffect(() => {
//         if (userLogin?.Results[0]?.vln_id_flag === '2') {
//             if (lng === 'arab') {
//                 dispatch(getVolunteerEvents(userLogin?.Results[0]?.vln_id, 1))
//             } else {
//                 dispatch(getVolunteerEvents(userLogin?.Results[0]?.vln_id, 2))
//             }
//         }
//     }, [lng]);
//     const vlnEventsSelector = useSelector(state => state.vlnEvents.vlnEvents);
//     const loading = useSelector(state => state.vlnEvents.loading);
//     const [vlnEventsAll, setVlnEventsAll] = useState(vlnEventsSelector)
//     useEffect(() => {
//         setVlnEventsAll(vlnEventsSelector)
//     }, [vlnEventsSelector])

//     const handleClick = (item) => {
//         history.push(`/volunteer/event?no=${item.req_id}&at=${item.req_year}`)
//     }
//     if (userLogin?.Results[0]?.vln_id_flag === '2') {
//         return (
//             <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
//                 <ShareHeader pageName={t("Events")} noShare={true} />
//                 <div className='submit_requ_header'>
//                     <h3 style={{ color: 'gray' }}>{t("Events")}</h3>
//                 </div>
//                 {loading ? <Loading /> : <div className='relatives_display'>
//                     {vlnEventsAll?.map((item, i) =>
//                         <div key={i} style={{ cursor: 'pointer' }} onClick={() => handleClick(item)} className='course_Item'>
//                             <label style={{ fontWeight: 'bold' }}>{item?.req_title}</label>
//                         </div>
//                     )}
//                 </div>}
//             </div>
//         )
//     } else {
//         window.location.replace("/profile");
//     }

// }

// export default Events
