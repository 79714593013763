import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation } from "swiper";
import LoadingModal from '../Auth/loginSignPage/loadingPopUp/loadingPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_TUTORIAL, SHOW_TUTORIAL } from '../../constants/actionTypes';
import './TutorialModel.css'
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);
const TutorialModel = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()
    const [showSlider, setShowSlider] = useState(false)
    const [loadingModalShow, setLoadingModalShow] = useState(true);

    let alltutorials = useSelector(state => state.tutorials_fetch.allTutorials)
    const tutorialCategory = useSelector(state => state.tutorials_load.tutorialCategory)
    useEffect(() => {
        if(alltutorials && alltutorials.length > 0)
        {
          alltutorials = alltutorials.filter((item) => item.screen_id === tutorialCategory)
          setTimeout(() => {
            setLoadingModalShow(false)
            setShowSlider(true)
          }, 1000)
        }
    }, [alltutorials])
        const [view, setView] = useState(1)
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body_forWelcome remove_padding_modal_body"
    >
      <Modal.Body>
        <div>
        <i className="fas fa-times" style={{ float: "right", cursor: 'pointer', color: 'gray', marginTop: '15px'}} onClick={() => dispatch({type: HIDE_TUTORIAL})}></i>
        <h3 style={{padding: '20px'}}>{t("Instructions")}</h3>
        {showSlider?<Swiper loop={true} slidesPerView={1} navigation={true}>
            {alltutorials?.map((item,i)=> 
                    <SwiperSlide key={i} >
                        <img style={{width:'100%', height:"80vh", objectFit:"contain"}} src={item.image} />
                        <h4 className='swiper-no-swiping'>{item.title}</h4>
                        <div className='swiper-no-swiping'>{item.description}</div>
                    </SwiperSlide>
                    )}
            </Swiper>:
            <LoadingModal
            show={loadingModalShow}
            dialogClassName="modal-60w"
            backdrop="static"
          />}
            
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default TutorialModel