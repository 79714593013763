import './Skills.css'
import React, { useState, useEffect } from 'react'
import icon9 from '../../../assets/pencil.png'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getAllSkills, getVolunteerSkills, addVolunteerSkills } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import DoneModal from '../../Auth/loginSignPage/doneModal/DoneModal'
import LoadingModal from '../../Auth/loginSignPage/loadingPopUp/loadingPopUp'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';
import {
    applyValidation,
    resetValidation,
  } from "../../../handlers/formValidation";
const Skills = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const [added, setAdded] = useState(0)
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getAllSkills(1))
                dispatch(getVolunteerSkills(userLogin?.Results[0]?.vln_id, 1))
            } else {
                dispatch(getAllSkills(2))
                dispatch(getVolunteerSkills(userLogin?.Results[0]?.vln_id, 2))
            }
        }
    }, [lng, added]);
    const vlnSkills = useSelector(state => state.vlnSkills.vlnSkills);
    const loading = useSelector(state => state.vlnSkills.loading);
    const [vlnAllSkills, setVlnAllSkills] = useState(vlnSkills)
    useEffect(() => {
        setVlnAllSkills(vlnSkills)
    }, [vlnSkills])

    const vlnVolunteerSkills = useSelector(state => state.vlnVolunteerSkills);
    const [vlnVolunteerAllSkills, setVlnVolunteerAllSkills] = useState(vlnVolunteerSkills)
    useEffect(() => {
        setVlnVolunteerAllSkills(vlnVolunteerSkills)
    }, [vlnVolunteerSkills])

    const [skills, setSkills] = useState({
        selectedSkill: '',
        remarks: '',
    })
    const handleChange = (e) => {
        setSkills({ ...skills, [e.target.name]: e.target.value })
    }
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [doneModal, setDoneModal] = useState(false)
    const handleAddCourse = (e) => {
        e.preventDefault()
        setLoadingModalShow(true)
        dispatch(addVolunteerSkills(skills.selectedSkill, userLogin?.Results[0]?.vln_id, skills.remarks, setLoadingModalShow, setDoneModal, setAdded, added))
    }
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Skills and Interests")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Skills and Interests")}</h3>
                </div>
                <form className='courses_form' onSubmit={handleAddCourse}>
                    <div className='selectCourseInputWraper'>
                        <img src={icon9} alt='selected_course' className='iconForSelectCourse' />
                        <select value={skills.selectedSkill} onChange={handleChange} name="selectedSkill" className="selectedCourse" required
              onInvalid={applyValidation}
              onInput={resetValidation}>
                            <option value='' disabled defaultValue>{t("Select Skill")}</option>
                            {vlnAllSkills?.map(item =>
                                <option key={item.abilityID} value={item.abilityID}>{item.abilityName}</option>
                            )}
                        </select>
                    </div>
                    <textarea style={{ width: '100%' }} value={skills.remarks} onChange={handleChange} id="requ_details" name="remarks" placeholder={t('Remarks')} rows="10" required/>
                    <br />
                    <button className='btn_for_submiting_courses'>{t("Submit")}</button>
                </form>
                {loading ? <Loading /> : <div className='courses_display'>
                    {vlnVolunteerAllSkills?.slice(0).reverse().map((item, i) =>
                        <div key={i} className='course_Item'>
                            <label>{t("Skill")}: <span style={{ fontWeight: 'bold' }}>{item.abilityName}</span></label>
                            <label>{t("Remarks")}: <span style={{ fontWeight: 'bold', wordBreak:'break-all' }}>{item.remarks}</span></label>
                        </div>
                    )}
                </div>}
                <DoneModal
                    show={doneModal}
                    dialogClassName="modal-60w"
                    onHide={() => { setDoneModal(false) }}
                />
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default Skills