import { FETCH_ALL_HUMANCASES, HUMANCASES_LOADED, LOAD_HUMANCASES } from '../constants/actionTypes'

export default (humanCases = { humanCases: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_HUMANCASES:
            return { ...humanCases, humanCases: action.payload, loading: false };
        case LOAD_HUMANCASES:
            return { ...humanCases, loading: true };
        case HUMANCASES_LOADED:
            return { ...humanCases, loading: false };
        default:
            return humanCases;
    }
}