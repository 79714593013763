import React, { useState, useEffect } from 'react'
import './Favorites.css'
import FavoritesCom from './favoritesCom/FavoritesCom'
import favImgTest from '../../../../assets/foodBox.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useHistory, useLocation } from 'react-router-dom'
import 'swiper/swiper-bundle.css'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../../ui/loading'
import { addToFavorites, deleteFromFavorites } from '../../../../actions/BagFav'
import { FETCH_LOCALLY } from '../../../../constants/actionTypes'

const Favorites = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const userFav = useSelector(state => state.favorites.favorites)
    const userFavLoading = useSelector(state => state.favorites.loading)
    const [favoritesDetailsLoading, setFavoritesDetailsLoading] = useState(userFavLoading)
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const [change, setChange] = useState(0)
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    const dispatch = useDispatch()

    useEffect(() => {
        setFavoritesDetailsLoading(userFavLoading)
    }, [userFavLoading])
    const [donationFav, setDonationFav] = useState([])
    useEffect(() => {
        if (userLogin) {
            let donationWithoutSukuk = userFav?.donations?.filter(outerItem=> userFav?.sukuks?.filter(item=> item.dontypeID===outerItem.dontypeID).length===0)
            setDonationFav(donationWithoutSukuk)
        } else {
            setDonationFav(currentProfile?.liked?.donations)
        }
      }, [userFav, change, fetchLoaclly]);
    const history = useHistory();
    const [view, setView] = useState(currentProfile?.liked?.donations?.length)
    const location = useLocation();

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
      useEffect(() => {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(15)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(11)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(8)
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(6)
        } else if (window.innerWidth > 1300 && window.innerWidth <= 1600) {
            setView(5.8)
        } else if (window.innerWidth > 1111 && window.innerWidth <= 1300) {
            setView(5)
        }
        else if (window.innerWidth > 1000 && window.innerWidth <= 1111) {
            setView(5.4)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(5)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(5)
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(4)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(2.8)
        }
        else if (window.innerWidth > 350 && window.innerWidth <= 500) {
            setView(2.2)
        } else if (window.innerWidth > 250 && window.innerWidth <= 350) {
            setView(1.4)
        } else {
            setView(1)
        }
    }, [width])

    const handleLike = async (donationID) => {
        if (!userLogin) {
        if (!currentProfile) {
            localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
            currentProfile = JSON.parse(localStorage.getItem('profile'));
        }
        dispatch({ type: 'addLikeLocally', payload: donationID })
        currentProfile.liked.donations.push(donationID)
        const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: donationID.donationId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
        }
    }
    const handleDisLike = async (donationID) => {
        if (!userLogin) {
        let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.liked.sukuks, projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans } }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1)
        } else {
            let elementWeNeed = donationFav?.filter(item => item.dontypeID === donationID.donationId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }

    }

    
    const handleRemoveFromBag = async (donationID) => {
        let newProfile = { cart: { donations: currentProfile.cart.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1)
    }
    return (
        <section id='favorites_sec'>
            <div className='section_title_div' style={lng === 'arab' ? { flexDirection: 'row-reverse' } : null}>
                <label className='all_section_title' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'left' }}>{t('Favorites')}</label>
                    <button onClick={() => history.push('/favorites')} className='section_button'> {t("View All")}</button>
            </div>
            {favoritesDetailsLoading?
            <Loading height='267.5px' mediaQ='250px'/>:
            <div id='favorites_display' style={lng === 'arab' ? { width: '100%', direction: 'rtl' } : { width: '100%' }}>
                {donationFav?.length > 0 ?
                    <React.Fragment>
                        <Swiper loop={donationFav?.length > view? true:false} slidesPerView={view} navigation={true}>
                            {donationFav?.map((item,i) =>
                                <SwiperSlide key={i}>
                                    <FavoritesCom setChange={setChange} donationFav={donationFav} change={change}  handleRemoveFromBag={handleRemoveFromBag} handleLike={handleLike} handleDisLike={handleDisLike} currentProfile={currentProfile} donationID={userLogin? item.dontypeID:item.donationId} amount={userLogin? item.baseAmount:item.amount} DonImg={userLogin? item.imagePath:item.img} title={userLogin? item.name:item.title} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </React.Fragment> : null
                }

            </div>}
        </section>
    )
}

export default Favorites
