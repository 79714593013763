import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import Loading from "../ui/loading";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import defaultImg from '../../assets/defaultA.jpg'
import ReactHtmlParser from 'react-html-parser'; 

const AboutUs = (props) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const nameFromQuery = query.get("name");
  const [displayProp, setDisplayProp] = useState(1);
  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState();
  const [imgSrc, setImgSrc] = useState(true)
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const aboutus = useSelector((state) => {
    return state.aboutus;
  });

  const handleSelectedItem = (str) => {
    setSelectedItem(str);
  };
  // useEffect(() => {
  //   window.scrollTo(10, 0);
  // }, [location]);
  useEffect(() => {
    if (nameFromQuery) {
      let element = aboutus.filter(item=> item.title === nameFromQuery).slice(0).shift();
      if (element) {
        setSelectedItem(element);
      } else {
        setSelectedItem(aboutus[0]);
      }
      // for (let index = 0; index < aboutus.length; index++) {
      //   const element = aboutus[index];
      //   if (element && element.title == nameFromQuery) {
      //     
      //   } else {
      //     
      //   }
      // }
    } else {
      setSelectedItem(aboutus[0])
    }
    // if (aboutus && aboutus.length > 0 && !selectedItem) {
    //   setSelectedItem(aboutus[0]);
    // }
  }, [aboutus, nameFromQuery]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div
      className="aboutWraper"
      style={
        lng === "arab"
          ? {
            direction: "rtl",
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }
          : {
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }
      }
    >
      <ShareHeader pageName={t("About Us")} noShare={true}/>
      <div className="submit_requ_header" style={{ borderBottom: "none" }}>
        <h2 style={{ color: "gray", fontWeight: "900" }}>{t("About Us")}</h2>
      </div>
      {
        aboutus.length > 0 ?
          <div className="about_us">
            <div className="navigation_menu">
              <div className="about_us_table">
                {aboutus?.map((item, index) => (
                  <button
                  key = {index}
                  style={{textAlign: width<=900? 'center':lng==='arab'? 'right':'left'}}
                    onClick={() => handleSelectedItem(item)}
                    className={
                      selectedItem?.title == item.title
                        ? "about_us_nav_item about_us_nav_item_active"
                        : "about_us_nav_item"
                    }
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            </div>
            <div className="content_section">
              {selectedItem?.imagePath?
              <React.Fragment>
                <img style={{height:'250px', width:'250px'}} alt={selectedItem?.title} className="ERc_about" src={selectedItem?.imagePath} />
                <br />
              </React.Fragment>:null
            }
              
              <h4
                style={{
                  color: "#ee4236",
                  fontWeight: "700",
                  borderBottom: "1px solid gray",
                  padding: "0px 10px 15px 10px",
                }}
              >
                {selectedItem?.title}
              </h4>
              <div className="order_list">
              {ReactHtmlParser(selectedItem?.content)}
               </div>
            </div>
          </div> : <div style={{width:'100%', alignSelf: 'center', display:'flex', alignItems:'center', justifyContent:'center'}}><Loading height='85vh' /></div>}
    </div>
  );
};

export default AboutUs;
