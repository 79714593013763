import { FETCH_ALL_EVENTS, FETCH_ALL_EVENTS_LOADING } from '../constants/actionTypes'

export default (events = {
    events: [],
    loading: true
}, action) => {

    switch (action.type) {
        case FETCH_ALL_EVENTS:
            return {...events, events: action.payload, loading: false};
        case FETCH_ALL_EVENTS_LOADING:
            return {...events,loading: action.status};
        default:
            return events;
    }
}