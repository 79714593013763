import './AreasOfInterests.css'
import React, { useState, useEffect } from 'react'
import icon9 from '../../../assets/pencil.png'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getAllInterests, getVolunteerInterests, insertVolunteerInterest } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import DoneModal from '../../Auth/loginSignPage/doneModal/DoneModal'
import LoadingModal from '../../Auth/loginSignPage/loadingPopUp/loadingPopUp'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';
import {
    applyValidation,
    resetValidation,
  } from "../../../handlers/formValidation";
import { getGroupPDF } from '../../../api';
const AreasOfInterests = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const [added, setAdded] = useState(0)
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === "2") {
            if (lng === 'arab') {
                dispatch(getAllInterests(1))
                dispatch(getVolunteerInterests(userLogin?.Results[0]?.vln_id, 1))
            } else {
                dispatch(getAllInterests(2))
                dispatch(getVolunteerInterests(userLogin?.Results[0]?.vln_id, 2))
            }
        }
    }, [lng, added]);
    const vlnInterests = useSelector(state => state.vlnInterests.vlnInterests);
    const loading = useSelector(state => state.vlnInterests.loading);
    const [vlnAllInterests, setVlnAllInterests] = useState(vlnInterests)
    useEffect(() => {
        setVlnAllInterests(vlnInterests)
    }, [vlnInterests])

    const vlnVolunteerInterests = useSelector(state => state.vlnVolunteerInterests);
    const [vlnVolunteerAllInterests, setVlnVolunteerAllInterests] = useState(vlnVolunteerInterests)
    useEffect(() => {
        setVlnVolunteerAllInterests(vlnVolunteerInterests)
    }, [vlnVolunteerInterests])

    const [interest, setInterest] = useState('')
    const handleChange = (e) => {
        setInterest(e.target.value)
    }
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [doneModal, setDoneModal] = useState(false)
    const handleAddCourse = (e) => {
        e.preventDefault()
        setLoadingModalShow(true)
        dispatch(insertVolunteerInterest(interest, userLogin?.Results[0]?.vln_id, setLoadingModalShow, setDoneModal, setAdded, added))
    }
    const [volunteerPDFUrl, setVolunteerPDFUrl] = useState(null);
    const [loadingPDFShow, setLoadingPDFShow] = useState(false);
    useEffect(() => {
        setLoadingPDFShow(true)
        getGroupPDF(lng === "arab"? 1: 2).then((data) => {
          setVolunteerPDFUrl(data.data.result)
          setLoadingPDFShow(false)
    
        })
      }, [])

    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Areas of interest")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Areas of interest")}</h3>
                </div>
                <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button disabled={loadingPDFShow} onClick={() => { window.open(volunteerPDFUrl, "_blank")}} className="btn_for_submiting_courses">
              {loadingPDFShow?
                          <Loading width={"150px"} height={'40px'} small={'30px'} animate={true} />
:t("View All Volunteer Programs")
              }
              </button>
            
          </div>
                <form className='courses_form' onSubmit={handleAddCourse}>
                    <div className='selectCourseInputWraper'>
                        <img src={icon9} alt='selected_course' className='iconForSelectCourse' />
                        <select value={interest} onChange={handleChange} name="selectedSkill" className="selectedCourse" required
              onInvalid={applyValidation}
              onInput={resetValidation}>
                            <option value='' disabled defaultValue>{t("Select Program")}</option>
                            {vlnAllInterests?.map(item =>
                                <option key={item.programID} value={item.programID}>{item.programName}</option>
                            )}
                        </select>
                    </div>
                    <br />
                    <button className='btn_for_submiting_courses'>{t("Register")}</button>
                </form>
                {loading ? <Loading /> : <div className='courses_display'>
                    {vlnVolunteerAllInterests?.slice(0).reverse().map((item, i) =>
                        <div key={i} className='course_Item'>
                            <label style={{ fontWeight: 'bold' }}>{item.programName}</label>
                        </div>
                    )}
                </div>}
                <DoneModal
                    show={doneModal}
                    dialogClassName="modal-60w"
                    onHide={() => { setDoneModal(false) }}
                />
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default AreasOfInterests