import React, { useState, useEffect, useRef } from "react";
import "./humanitarianCom.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PopUpPayment from "../../popUpPayment/PopUpPayment";
import donateIcon from "../../../../../assets/donateIconForBanner.png";
import smsicon from "../../../../../assets/samsungpay.png";
import cashbills from "../../../../../assets/donateByCash.png";
import applePay from "../../../../../assets/applePay.png";
import { toastifyMessage } from "../../../../../handlers/toastifyMessage";
import { startApplePay } from "../../../../../actions/handleApplePayment";

import cash from "../../../../../assets/mobile.png";
import SmsModal from "../../../../ui/smsModal/SmsModal";
import Loading from "../../../../ui/loading";
import { useSelector, useDispatch } from "react-redux";
import { addToBag } from "../../../../../actions/BagFav";
import { handleSamsungDirectPayment } from "../../../../../actions/handleSamsungPayment";
import DonationFinalPopUp from "../../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import { FETCH_LOCALLY } from "../../../../../constants/actionTypes";
import LoadingModal from "../../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
const HumanitarianCom = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [change, setChange] = useState(0);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);
  const dispatch = useDispatch();
  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");
  const [addingToCart, setAddingToCart] = useState(false);
  const [smsModalShow, setSmsModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const wraperStyling = {
    boxShadow: "2px 0px 5px #88888836",
    borderRadius: "30px",
  };
  const [popUpHModal, setPopUpHModal] = useState(false);
  const [presentage, setPresentage] = useState(
    parseFloat(
      `${parseFloat(props.raised) / parseFloat(props.goal)}`.slice(0, 4)
    )
  );
  const handleSMS = (caseId) => {
    setSmsModalShow(true);
    //var data = await getSMS_Human(caseId);
  };
  const [humanCaseData, setHumanCaseData] = useState({
    humanId: props.ID,
    title: props.title,
    total: props.raised,
    finalAmount: props.goal,
    humanImg: props.humanImg,
    donationAmountFromUser: 5,
    caseYear: props.caseYear,
    item_type: 4,
  });

  useEffect(() => {
    setHumanCaseData({
      humanId: props.ID,
      title: props.title,
      total: props.raised,
      finalAmount: props.goal,
      humanImg: props.humanImg,
      donationAmountFromUser: humanCaseData.donationAmountFromUser,
      caseYear: props.caseYear,
      item_type: 4,
    });
  }, [props]);

  const [IMG, setIMG] = useState(props.humanImg);
  const [humancaserest, sethumancaserest] = useState({
    title: props.title,
    text: props.text,
  });
  const history = useHistory();
  useEffect(() => {
    setPresentage(
      parseFloat(
        `${parseFloat(props.raised) / parseFloat(props.goal)}`.slice(0, 4)
      )
    );
    setIMG(props.humanImg);
    sethumancaserest({
      title: props.title,
      text: props.text,
    });
  }, [props.humanImg, props.title, props.text, props.raised, props.goal]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleInBag = async (str) => {
    if (str.donationAmountFromUser < 5) {
      toastifyMessage("Please enter a valid donation!", undefined, "warning");
      return;
    }
    setAddingToCart(true);
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile.cart?.humanCases.push({
        ...str,
        baseAmount: str.donationAmountFromUser,
      });
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      toastifyMessage("Added!", undefined, "success");
      setChange(change + 1);
    } else {
      await dispatch(
        addToBag({
          cart: {
            donID: "158",
            userName: userLogin.Results[0]?.name_a,
            userMob: userLogin.Results[0]?.mobile_no,
            userEmail: userLogin.Results[0]?.email,
            itemType: 4,
            amount: str.donationAmountFromUser,
            cart_serial: null,
            humanCase: str.humanId,
            humanYear: str.caseYear,
          },
          language: lng === "arab" ? 1 : 2,
        })
      );
    }
    if (_isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut");
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text animate__animated animate__bounceIn");
      setAddingToCart(false);
      setInTheBagText("Add to Bag");
      }
      
    }, 1000);
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text");
      }
      
    }, 2000);
    }
    
  };

  const handleClick = (op) => {
    if (op === "-") {
      setHumanCaseData({
        ...humanCaseData,
        donationAmountFromUser:
          parseInt(humanCaseData.donationAmountFromUser) - 100,
      });
    } else {
      setHumanCaseData({
        ...humanCaseData,
        donationAmountFromUser:
          parseInt(humanCaseData.donationAmountFromUser) + 100,
      });
    }
  };
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: humanCaseData.donationAmountFromUser,
      url: window.location.href,
      item: [
        {
          dontype_id: 158,
          humanCase: humanCaseData.humanId,
          amount: humanCaseData.donationAmountFromUser,
          humanYear: humanCaseData.caseYear,
          item_type: 4,
        },
      ],
    });
    setLoadingModalShow(false);
  };
  const donateNow = () => {
    startApplePay(
      {
        amount: humanCaseData.donationAmountFromUser,
        cart: [
          {
            dontype_id: "158",
            item_type: 4,
            amount: humanCaseData.donationAmountFromUser,
            humanCase: humanCaseData.humanId,
            humanYear: humanCaseData.caseYear,
            cart_serial: null,
          },
        ],
      },
      dispatch,
      history
    );
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };

  return (
    <div
      className="humanitarian_com"
      style={props.fromMyBag ? wraperStyling : { backgroundColor: "#f7f7f7" }}
    >
      <div
        className="container_human"
        style={
          lng === "arab" && width <= 900
            ? { gridTemplateColumns: "67% 33%" }
            : lng === "arab" && width > 900
            ? { gridTemplateColumns: "80% 20%" }
            : lng !== "arab" && width <= 900
            ? { gridTemplateColumns: "33% 67%" }
            : lng !== "arab" && width > 900
            ? { gridTemplateColumns: "20% 80%" }
            : null
        }
      >
        <div
          className="circle_svg"
          style={lng === "arab" ? { order: "2" } : { order: "1" }}
        >
          <svg
          style={{cursor:'pointer'}}
          onClick={()=> history.push(`/humancases/${props.ID}`)}
            viewBox="0 0 550 550"
            version="1.1"
            xmlns="http://www.we.org/2000/svg"
            className="svg_content svg-shadow"
            preserveAspectRatio="xMinYMin meet"
          >
            <g transform="translate(0,20)">
              <defs>
                <pattern
                  viewBox="0 0 1 1"
                  preserveAspectRatio="xMidYMid slice"
                  id={`img_${props.ID}`}
                  x="0"
                  y="0"
                  patternUnits="objectBoundingBox"
                  height="100%"
                  width="100%"
                >
                  <image
                    width="1"
                    height="1"
                    preserveAspectRatio="xMidYMid slice"
                    className="img"
                    x="0"
                    y="0"
                    href={IMG}
                    xlinkHref={IMG}
                  ></image>
                </pattern>
              </defs>
              <circle
                cx="250"
                cy="250"
                r="240"
                fill={`url(#img_${props.ID})`}
              />
              <circle
                className="circle-bg"
                cx="250"
                cy="250"
                strokeWidth="20"
                r="240"
              />
              <circle
                className="circle"
                cx="250"
                cy="250"
                r="240"
                stroke="red"
                strokeWidth="20"
                strokeDasharray={`calc(${presentage} * ${
                  3.14 * 2 * 250
                }) calc(${3.14 * 2 * 250})`}
              />
            </g>
          </svg>

          <h5
            className="humani_cent payment_numbers"
            style={{ color: "black !important" }}
          >
            {Math.round(presentage * 100) > 100
              ? 100
              : Math.round(presentage * 100)}
            %
          </h5>
        </div>

        <div
          className="details_human"
          style={
            lng === "arab"
              ? { order: "1", alignItems: "flex-end" }
              : { order: "2", alignItems: "flex-start" }
          }
        >
          <div
            className="human_desc"
            onClick={() => history.push(`/humancases/${props.ID}`)}
          >
            <label
            className="human_case_title"
              style={
                lng === "arab"
                  ? {
                      fontWeight: "bold",
                      color: "black",
                      width: "100%",
                      textAlign: "right",
                      fontSize: "24px",
                    }
                  : {
                      color: "black",
                      width: "100%",
                      textAlign: "left",
                      fontSize: "24px",
                    }
              }
            >
              {humancaserest.title}
            </label>
            <p
              style={
                lng === "arab"
                  ? {
                      width: "100%",
                      textAlign: "right",
                      direction: "rtl",
                      color: "black",
                      fontWeight: "normal",
                      fontSize: width <= 600 ? "0.5em" : "0.859em",
                      marginBottom: "0px",
                    }
                  : {
                      width: "100%",
                      textAlign: "left",
                      color: "black",
                      fontWeight: "normal",
                      fontSize: width <= 600 ? "0.5em" : "0.859em",
                      marginBottom: "0px",
                    }
              }
            >
              {humancaserest.text.slice(0, 155)}...
            </p>
            <label
              onClick={() => history.push(`/humancases/${props.ID}`)}
              style={
                lng === "arab"
                  ? {
                      width: "100%",
                      fontSize: width <= 600 ? "0.5em" : "12px",
                      textAlign: "left",
                      direction: "rtl",
                      cursor: "pointer",
                      color: "black",
                    }
                  : {
                      width: "100%",
                      fontSize: width <= 600 ? "0.5em" : "12px",
                      textAlign: "right",
                      cursor: "pointer",
                      color: "black",
                    }
              }
            >
              {t("Read More")}
            </label>
          </div>

          {width <= 900 ? (
            <svg
              height="85"
              width="98%"
              className="svg_board"
              id="svg_board_Mob"
              style={
                lng === "arab"
                  ? {
                      alignSelf: "flex-start",
                      marginRight: "0px",
                      marginLeft: "20px",
                      marginBottom: "30px",
                    }
                  : {
                      alignSelf: "flex-end",
                      marginRight: "20px",
                      marginBottom: "30px",
                    }
              }
            >
              {presentage >= 1 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x="96%"
                  y="10"
                  fill="#a6a6a6"
                >
                  {t("Raised")}
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x={`${Math.round(presentage * 96)}%`}
                  y="10"
                  fill="#a6a6a6"
                >
                  {t("Raised")}
                </text>
              ) : (
                <text
                  className="text-bold"
                  textAnchor="start"
                  x={`${Math.round(presentage * 96)}%`}
                  y="10"
                  fill="#a6a6a6"
                >
                  {t("Raised")}
                </text>
              )}
              {/* When does this occur? */}
              {presentage >= 1 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x="96%"
                  y="25"
                  fill="grey"
                >
                  {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(props.raised)}</tspan>
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x={`${Math.round(presentage * 96)}%`}
                  y="25"
                  fill="grey"
                >
                  {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(props.raised)}</tspan>
                </text>
              ) : (
                <text
                  className="text-bold"
                  textAnchor="start"
                  x={`${Math.round(presentage * 96)}%`}
                  y="25"
                  fill="grey"
                >
                  {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(props.raised)}</tspan>
                </text>
              )}
              {presentage >= 1 ? (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="end"
                  className="arrow"
                  x="96%"
                  y="37"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="end"
                  className="arrow"
                  x={`${Math.round(presentage * 96)}%`}
                  y="37"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              ) : (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  className="arrow"
                  x={`${Math.round(presentage * 96)}%`}
                  y="37"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              )}
              <line
                x1="10"
                y1="47"
                x2="96%"
                y2="47"
                stroke="#e9eaeb"
                strokeLinecap="round"
                strokeWidth="10"
              />
              {presentage >= 1 ? (
                <line
                  x1="10"
                  y1="47"
                  x2="96%"
                  y2="47"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="11"
                />
              ) : (
                <line
                  x1="10"
                  y1="47"
                  x2={`${Math.round(presentage * 96)}%`}
                  y2="47"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="11"
                />
              )}
              <text x="96%" textAnchor="end" y="65" fill="grey">
                {t("Goal")}
              </text>
              <text
                x="96%"
                
                textAnchor="end"
                y="80"
                fill="black"
              >
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(props.goal)}</tspan>
              </text>
            </svg>
          ) : (
            <svg
              height="125"
              width="70%"
              className="svg_board"
              style={
                lng === "arab"
                  ? {
                      alignSelf: "flex-start",
                      marginRight: "0px",
                      marginLeft: "20px",
                    }
                  : { alignSelf: "flex-end", marginRight: "20px" }
              }
            >
              {presentage >= 1 ? (
                <text textAnchor="end" x="96%" y="30" fill="#a6a6a6">
                  {t("Raised")}
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  textAnchor="end"
                  x={`${Math.round(presentage * 96)}%`}
                  y="30"
                  fill="#a6a6a6"
                >
                  {t("Raised")}
                </text>
              ) : (
                <text
                  textAnchor="start"
                  x={`${Math.round(presentage * 96)}%`}
                  y="30"
                  fill="#a6a6a6"
                >
                  {t("Raised")}
                </text>
              )}
              {presentage >= 1 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x="96%"
                  y="47"
                  fill="black"
                >
                  {t("AED")}{" "}
                  <tspan className='payment_numbers'>{numberWithCommas(
                    props.raised > props.goal ? props.goal : props.raised
                  )}</tspan>
                  
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  className="text-bold"
                  textAnchor="end"
                  x={`${Math.round(presentage * 96)}%`}
                  y="47"
                  fill="black"
                >
                  {t("AED")}{" "}
                  <tspan className='payment_numbers'>{numberWithCommas(
                    props.raised > props.goal ? props.goal : props.raised
                  )}</tspan>
                  
                </text>
              ) : (
                <text
                  className="text-bold"
                  textAnchor="start"
                  x={`${Math.round(presentage * 96)}%`}
                  y="47"
                  fill="black"
                >
                  {t("AED")}{" "}
                  <tspan className='payment_numbers'>{numberWithCommas(
                    props.raised > props.goal ? props.goal : props.raised
                  )}</tspan>
                  
                </text>
              )}
              {presentage >= 1 ? (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="end"
                  className="arrow"
                  x="96%"
                  y="64"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              ) : presentage >= 0.7 ? (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="end"
                  className="arrow"
                  x={`${Math.round(presentage * 96)}%`}
                  y="64"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              ) : (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="start"
                  className="arrow"
                  x={`${Math.round(presentage * 96)}%`}
                  y="64"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              )}
              <line
                x1="10"
                y1="81"
                x2="96%"
                y2="81"
                stroke="#e9eaeb"
                strokeLinecap="round"
                strokeWidth="15"
              />
              {presentage >= 1 ? (
                <line
                  x1="10"
                  y1="81"
                  x2="96%"
                  y2="81"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="15"
                />
              ) : (
                <line
                  x1="10"
                  y1="81"
                  x2={`${Math.round(presentage * 96)}%`}
                  y2="81"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="15"
                />
              )}
              <text x="96%" textAnchor="end" y="106" fill="#a6a6a6">
                {t("Goal")}
              </text>
              <text
                x="96%"
                className="text-bold"
                textAnchor="end"
                y="123"
                fill="black"
              >
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(props.goal)}</tspan>
              </text>
            </svg>
          )}
          <div
            className="payment_btn_wraper"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "70%",
              backgroundColor: "#f7f7f7",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              opacity: presentage >= 1 ? "0.5" : "unset",
            }}
          >
            <div
              className="sukuk_item_board_payment noWidth"
              style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
            >
              {inTheBagText === "Added" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className={
                    "btn_with_text animate__animated animate__bounceIn"
                  }
                >
                  <button disabled={addingToCart} className="btn_for_sukuk">
                    <label style={{ fontSize: "10px", color: "#ee4236" }}>
                      {t("You now have ") + inTheBag + t(" in your Bag")}
                    </label>
                  </button>
                </div>
              ) : (
                <div className={animationClass}>
                  {addingToCart ? (
                    <Loading
                      height={"70px"}
                      small={"40px"}
                      mediaQ={"51px"}
                      animate={true}
                    />
                  ) : (
                    <React.Fragment>
                      <button
                        disabled={addingToCart || presentage >= 1}
                        onClick={() => {
                          if (
                            parseInt(humanCaseData.donationAmountFromUser) >= 5
                          )
                            handleInBag(humanCaseData);
                          else
                            toastifyMessage(
                              "Can not pay less than 5 AED",
                              undefined,
                              "warning"
                            );
                        }}
                        className="btn_for_sukuk"
                      >
                        <img
                          src={donateIcon}
                          alt="donate_btn_icon_banner"
                          className="sukuk_btn_img_general"
                        />
                      </button>
                      <label className="btn_label_for_sukul">
                        {t(inTheBagText)}
                      </label>
                    </React.Fragment>
                  )}
                </div>
              )}
              <div className="btn_with_text">
                <button
                  disabled={presentage >= 1}
                  onClick={() =>
                    parseInt(humanCaseData.donationAmountFromUser) < 5
                      ? toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        )
                      : handleGuestPopUp(
                          window.ApplePaySession ? donateNow : startSamsungPay
                        )
                  }
                  className="btn_for_sukuk"
                >
                  <img
                    src={window.ApplePaySession ? applePay : smsicon}
                    alt="donate_btn_icon_banner"
                    className={
                      window.ApplePaySession
                        ? "apple_pay_icon"
                        : "sukuk_btn_img_general"
                    }
                  />
                </button>
                <label className="btn_label_for_sukul">
                  {window.ApplePaySession ? t("Apple Pay") : t("Samsung Pay")}
                </label>
              </div>
              <div className="sukuk_inc_Dec noWidth">
                <div className="btn_with_text">
                  <button
                    disabled={presentage >= 1}
                    onClick={() => {
                      if (!(humanCaseData.donationAmountFromUser >= 5)) {
                        toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        );
                      } else
                        history.push(`/checkout`, {
                          from: "humanCases",
                          elements: [
                            {
                              title: humanCaseData.title,
                              price: humanCaseData.donationAmountFromUser,
                            },
                          ],
                          totalAmount: humanCaseData.donationAmountFromUser,
                          details: humanCaseData,
                        });
                    }}
                    className="btn_for_sukuk"
                  >
                    <img
                      src={cashbills}
                      alt="donate_btn_icon_banner"
                      className="sukuk_btn_img_apple"
                    />
                  </button>
                  <label className="btn_label_for_sukul">
                    {t("Donate Now")}
                  </label>
                </div>
              </div>
            </div>
            <div
              className="sukuk_inc_Dec noWidth"
              style={{ marginBottom: "10px" }}
            >
              <button
                disabled={presentage >= 1}
                className="sukukdec btn btn-light"
                onClick={() => {
                  if (humanCaseData.donationAmountFromUser > 100) {
                    return handleClick("-");
                  }
                }}
              >
                -
              </button>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <label
                  className="inc_dec_label_sukuk"
                  style={{ fontSize: "15px" }}
                >
                  {t("AED")}
                </label>
                <input
                  disabled={presentage >= 1}
                  type="number"
                  style={{
                    border: "none",
                    padding: "5px",
                    borderBottom: "1px solid gray",
                    backgroundColor: "transparent",
                    width: "60%",
                  }}
                  onChange={(e) =>
                    setHumanCaseData({
                      ...humanCaseData,
                      donationAmountFromUser: e.target.value.replace(/\D/, ""),
                    })
                  }
                  value={humanCaseData.donationAmountFromUser}
                />
              </div>
              <button
                disabled={presentage >= 1}
                className="sukukinc btn btn-light"
                onClick={() => handleClick("+")}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*New Payment Methods*/}
      <div
        className="payment_btn_wraper2"
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "70%",
          backgroundColor: "#f7f7f7",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
      >
        <div
          className="sukuk_item_board_payment noWidth"
          style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
        >
          {inTheBagText === "Added" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={"btn_with_text animate__animated animate__bounceIn"}
            >
              <button disabled={addingToCart} className="btn_for_sukuk">
                <label style={{ fontSize: "10px", color: "#ee4236" }}>
                  {t("You now have ") + inTheBag + t(" in your Bag")}
                </label>
              </button>
            </div>
          ) : (
            <div className={animationClass}>
              {addingToCart ? (
                <Loading
                  height={"70px"}
                  small={"40px"}
                  mediaQ={"51px"}
                  animate={true}
                />
              ) : (
                <React.Fragment>
                  <button
                    style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
                    disabled={addingToCart || presentage >= 1}
                    onClick={() => {
                      if (parseInt(humanCaseData.donationAmountFromUser) > 0)
                        handleInBag(humanCaseData);
                      else
                        toastifyMessage(
                          "Please enter a valid donation!",
                          undefined,
                          "warning"
                        );
                    }}
                    className="btn_for_sukuk"
                  >
                    <img
                      src={donateIcon}
                      alt="donate_btn_icon_banner"
                      className="sukuk_btn_img_general"
                    />
                  </button>
                  <label className="btn_label_for_sukul">
                    {t(inTheBagText)}
                  </label>
                </React.Fragment>
              )}
            </div>
          )}
          <div className="btn_with_text">
            <button
              style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
              disabled={presentage >= 1}
              onClick={() =>
                parseInt(humanCaseData.donationAmountFromUser) < 5
                  ? toastifyMessage(
                      "Can not pay less than 5 AED",
                      undefined,
                      "warning"
                    )
                  : handleGuestPopUp(
                      window.ApplePaySession ? donateNow : startSamsungPay
                    )
              }
              className="btn_for_sukuk"
            >
              <img
                src={window.ApplePaySession ? applePay : smsicon}
                alt="donate_btn_icon_banner"
                className={
                  window.ApplePaySession
                    ? "apple_pay_icon"
                    : "sukuk_btn_img_general"
                }
              />
            </button>
            <label className="btn_label_for_sukul">
              {window.ApplePaySession ? t("Apple Pay") : t("Samsung Pay")}
            </label>
          </div>
          {/* SMS Donation on Web/Desktop view should be hidden */}
          {width < 900 ? (
            <div className="btn_with_text">
              <button
                style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
                onClick={handleSMS}
                disabled={presentage >= 1}
                className="btn_for_sukuk"
              >
                <img
                  src={cash}
                  alt="donate_btn_icon_banner"
                  className="sukuk_btn_img_sms"
                />
              </button>
              <label className="btn_label_for_sukul">{t("Donate SMS")}</label>
            </div>
          ) : null}
          <div className="sukuk_inc_Dec noWidth">
            <div className="btn_with_text">
              <button
                style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
                disabled={presentage >= 1}
                onClick={() => {
                  if (!(humanCaseData.donationAmountFromUser >= 5)) {
                    toastifyMessage(
                      "Please enter a valid donation!",
                      undefined,
                      "warning"
                    );
                  } else
                    history.push(`/checkout`, {
                      from: "humanCases",
                      elements: [
                        {
                          title: humanCaseData.title,
                          price: humanCaseData.donationAmountFromUser,
                        },
                      ],
                      totalAmount: humanCaseData.donationAmountFromUser,
                      details: humanCaseData,
                    });
                }}
                className="btn_for_sukuk"
              >
                <img
                  src={cashbills}
                  alt="donate_btn_icon_banner"
                  className="sukuk_btn_img_apple"
                />
              </button>
              <label className="btn_label_for_sukul">{t("Donate Now")}</label>
            </div>
          </div>
        </div>
        <div
          className="sukuk_inc_Dec noWidth"
          style={{ marginBottom: "40px", justifyContent: "center", gap: "1px" }}
        >
          <button
            style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
            disabled={presentage >= 1}
            className="sukukdec btn btn-light"
            onClick={() => {
              if (humanCaseData.donationAmountFromUser > 100) {
                return handleClick("-");
              }
            }}
          >
            -
          </button>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <label
              className="inc_dec_label_sukuk"
              style={{
                fontSize: "15px",
                opacity: presentage >= 1 ? "0.5" : "unset",
              }}
            >
              {t("AED")}
            </label>
            <input
              disabled={presentage >= 1}
              type="text"
              pattern="[0-9]*"
              style={{
                border: "none",
                padding: "5px",
                borderBottom: "1px solid gray",
                backgroundColor: "transparent",
                width: "100%",
                opacity: presentage >= 1 ? "0.5" : "unset",
              }}
              onChange={(e) =>
                setHumanCaseData({
                  ...humanCaseData,
                  donationAmountFromUser: e.target.value.replace(/\D/, ""),
                })
              }
              value={humanCaseData.donationAmountFromUser}
            />
          </div>
          <button
            style={{ opacity: presentage >= 1 ? "0.5" : "unset" }}
            disabled={presentage >= 1}
            className="sukukinc btn btn-light"
            onClick={() => handleClick("+")}
          >
            +
          </button>
        </div>
      </div>

      {/* {props.HumanPage ? (
        presentage<1?
        <button
          onClick={() => history.push(`/humancases/${props.ID}`)}
          style={
            lng === "arab"
              ? {
                backgroundColor: "#f3f3f3", direction: "rtl", boxShadow: '1px 0px 8px #80808040',
                marginTop: '-15px'
              }
              : {
                backgroundColor: "#f3f3f3", boxShadow: '1px 0px 8px #80808040',
                marginTop: '-15px'
              }
          }
          className="btn btn-block btn-light btn-donate text-bold standAlone"
        >
          {t("Donate Now")} {lng === "arab" ? (
            <i className="fas fa-chevron-left"></i>
          ) : (
            <i className="fas fa-chevron-right"></i>
          )}
        </button>:null
      ) : (
        <div
          className="btn_human"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {presentage<1?
           <button
            onClick={() => setPopUpHModal(true)}
            style={
              lng === "arab"
                ? {
                  backgroundColor: "#f3f3f3",
                  padding: "5px 20px 5px 20px",
                  direction: "rtl",
                  boxShadow: '1px 0px 8px #80808040',
                  marginTop: '-15px'
                }
                : { backgroundColor: "#f3f3f3", padding: "5px 20px 5px 20px", boxShadow: '1px 0px 8px #80808040', marginTop: '-15px' }
            }
            className="btn btn-block btn-light btn-donate"
          >
            {t("Donate Now")}{" "}
            {lng === "arab" ? (
              <i className="fas fa-chevron-left"></i>
            ) : (
              <i className="fas fa-chevron-right"></i>
            )}
          </button>:<span style={{height:'21px'}}></span>
        }
         
        </div>
      )} */}
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <SmsModal
        show={smsModalShow}
        humancase={"true"}
        donationid={humanCaseData.humanId}
        onHide={() => setSmsModalShow(false)}
      />
      <PopUpPayment
        show={popUpHModal}
        //amount={props.amount}
        img={props.humanImg}
        title={props.title}
        edit_flag={true}
        //recuring="N"
        donationId={props.ID}
        caseYear={props.caseYear}
        presentage={presentage}
        total={props.raised}
        finalAmount={props.goal}
        from="humanCases"
        onHide={() => setPopUpHModal(false)}
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default HumanitarianCom;
