import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import "./FilterModalTransaction.css";
import FilterModalTransactionItem from "./FilterModalTransactionItem/FilterModalTransactionItem";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const FilterModalTransaction = (props) => {
  const setItems = () => {
    props.setitemselectedlist(selectedOrphans.filter((item) => item.active))

  }
  const [selectedOrphans, setSelectedOrphans] = useState([...props.myorphons.map((item) => {return {...item, active: true, orphan_id: item.orp_id}})]);
  
  
  return (<Modal
    show={props.show} onHide={props.onHide}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    // className={adStyle.donation_pop_up_body_forWelcome}
  >
             
    <Modal.Body>
    <div style={{position: 'relative', marginBottom: "40px"}}>
      <div className="close-transaction-filter" style={{cursor: 'pointer', position: 'absolute'}} >
      <i onClick={() => props.onHide()} className="fas fa-times"></i>
      </div>
      <h3 style={{textAlign: "center"}}>{t("Filter")}</h3>
      <button onClick={() => {setSelectedOrphans([...props.myorphons.map((item) => {return {...item, active: false, orphan_id: item.orp_id}})])}} className="clear-all-button" style={{position: 'absolute', right: '0px', top: '0px'}}>{t("Clear All")}</button>
    </div>
      <div className="all-orphan-filter-list">
        {selectedOrphans?.length <= 0?
        <div>No orphans to filter</div>
        :selectedOrphans?.map((item, i) => {
          return <FilterModalTransactionItem key={i} item={item}/>
        })}
        
      </div>
      <div className="orphan-save-section">
        <button className="orphan-save-button" onClick={() => { setItems()}}>{t("Save")}</button>
      </div>
    </Modal.Body>
  </Modal>
  )
};
export default FilterModalTransaction;