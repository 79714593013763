import React from 'react'
import './CategoryComp.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const CategoryComp = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const history = useHistory()
    const handleClick = (str) => {
          history.push(`${str}`)  
    }
    return (
        <div onClick={() => handleClick(props.navigateTo)} className='category_comp' >
            <div className='category_img'>
            <img src={props.categoryImg} alt={props.categoryTitle} className='category_img' />
            </div>
            <label className='category_label'>
                <span style={lng === 'arab' ? { width: '100%', textAlign: 'center' } : { width: '100%', textAlign: 'center' }}>{props.categoryTitle}</span>
                <span style={lng === 'arab' ? { textAlign: 'left', color: 'black', width: '80%' } : { textAlign: 'right', color: 'black', width: '80%' }}>{lng === 'arab' ?
                    <i className="fas fa-arrow-left leftarrow-i"></i> : <i className="fas fa-arrow-right rightarrow-i"></i>}
                </span>
            </label>
        </div>
    )
}

export default CategoryComp