import React from 'react'
import './Mission.css'
import { useTranslation } from "react-i18next";
const Mission = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    return (
        <div className='mission_page'>
            <div className='submit_requ_header'>
                <h3 style={{color:'gray'}}>{t("Our Mission")}</h3>
            </div>
            <div className='mission_content'>
                <p>
                {("UAE Red Crescent Authority was established on 31/01/1983 and gained the international recognition as a member No.: 139 of the International Federation of Red Crescent and Red Cross on 08/27/1986. His Highness Sheikh Hamdan Bin Zayed Al Nahyan took the position of the Chairman of the Board of the Red Crescent in 1986, and then took the position of the Chairman of the Authority in 1993, which gave impetus to the efforts of the human Authority. In 1997, the role of the Authority had been enhanced by the acceptance of Her Highness Sheikha Fatima bint Mubarak, for the position of the honorary president of the UAE Red Crescent. In 2001, the UAE Red Crescent had been selected as the second best humanitarian Autjority at the level of the continent of Asia.")}
                </p>
            </div>
        </div>
    )
}

export default Mission
