import React, { useState, useEffect } from "react";
import './Guest.css'
import "../Auth/loginSignPage/loginSign.css";
import ERCLogo from "../../assets/logo.png";
import BCE2mob from "../../assets/loginBCMob2.png";
import BCE2 from "../../assets/loginBC.png";
import fac from "../../assets/facebook.svg";
import finger from "../../assets/fingerprint-scan.png";
import apple from "../../assets/apple.svg";
import goo from "../../assets/google.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signIn, signUp } from "../../actions/user";
import { useTranslation } from "react-i18next";
import LoadingModal from "../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { useLocation } from "react-router-dom";
import { toastifyMessage } from "../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../handlers/formValidation";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
export const Guest = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [content, setContent] = useState("");
  const location = useLocation();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [contentForLogin, setContentForLogin] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  useEffect(() => {
    if (width > 1200) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
    var htmlInput = document.getElementById("name");
    htmlInput.oninvalid = function (e) {
      e.target.setCustomValidity("This can't be left blank!");
    };
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleChange = (e) => {
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value.replace(/\D/, ""));
        break;
      default:
        break;
    }
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Error");
      return setTimeout(() => {
        setEmailError("");
      }, 1000);
    }
    if (phone.slice(0,4) === '+971') {
      if (!validateMobile('0' + phone.slice(4))) {
      setPhoneError("Error");
      return setTimeout(() => {
        setPhoneError("");
      }, 1000);
    }
    }
    
    localStorage.setItem(
      "visitorDetails",
      JSON.stringify({ email, name, phone: phone.slice(1) })
    ); 
    if (props.fromCheckoutInner) {
       toastifyMessage("Your information has been successfully added", undefined, "success")
    }
    if (props.paymentFunc) {
      await props.paymentFunc()
    } else {
      history.goBack()
    }
  };

  const openFromLog = () => {
    history.push("/");
  };
  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    return re.test(phone);
  };
  if (!userLogin) {
    return (
      <div
        id="login_sign"
        style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", minHeight: props.finalDonationModal? 'unset':null }}
      >
        <form
          className="login_form"
          onSubmit={handleLoginSubmit}
          style={{ direction: lng === "arab" ? "rtl" : "ltr", filter: "none", width: props.finalDonationModal? '100%':null }}
        >
          <img
            src={ERCLogo}
            onClick={openFromLog}
            alt="ERC_logo"
            className="ERC_logo_login_sign"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <label>{t("Please enter your information to continue")}:</label>
            <br />
          </div>
          <div className="whole_input" style={{backgroundColor:'transparent'}}>
            <i
              style={{
                fontSize: width>900? "30px":'20px',
                color: "#474646",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="fas fa-user"
            ></i>
            <div
              className="input"
              style={{
                background: "transparent",
              }}
            >
              <label htmlFor="name" style={{
                  color: emailError ? "white" : "#474646",
                  // opacity: emailError ? "1" : "",
                  transition: "all 1s",
                  fontSize: width>500? '15px': '13px'
                }}>{t("Name")}</label>
              <input
                name="name"
                value={name}
                onChange={handleChange}
                type="text"
                className={lng==='arab'? "form-control translateFillMsg force_arabic_font":"form-control translateFillMsg"}
                id="name"
                onInvalid="this.setCustomValidity('Enter User Name Here')"
                onInput="this.setCustomValidity('')"
                style={{
                  background: "transparent",
                  paddingLeft: "0",
                  paddingRight: "0",
                  fontFamily: lng==='arab'? 'arab_font':'english_font'
                }}
                onInvalid={applyValidation}
                onInput={resetValidation}
                required
              />
            </div>
          </div>
          <div
            className="whole_input"
            style={{
              background: emailError ? "#ee4236" : "transparent",
              transition: "all 1s",
            }}
          >
            <i
              style={{
                fontSize:width>900? "30px":'20px',
                color: emailError ? "white" : "#474646",
                opacity: emailError ? ".55" : "",
                transition: "all 1s",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="far fa-envelope"
            ></i>

            <div
              className="input"
              style={{
                background: "transparent",
              }}
            >
              <label
                htmlFor="email"
                style={{
                  color: emailError ? "white" : "#474646",
                  // opacity: emailError ? "1" : "",
                  transition: "all 1s",
                  fontSize:width>500? '15px': '13px'
                  
                }}
              >
                {t("E-mail")}
              </label>
              <input
                name="email"
                value={email}
                onChange={handleChange}
                type="text"
                className="form-control translateFillMsg"
                id="email"
                style={{
                  background: "transparent",
                  color: emailError ? "white" : "",
                  opacity: emailError ? ".55" : "",
                  paddingLeft: "0",
                  paddingRight: "0",
                  transition: "all 1s",
                }}
                required
                onInvalid={applyValidation}
                onInput={resetValidation}
              />
            </div>
          </div>
          <div
            className="whole_input"
            style={{
              background: phoneError ? "#ee4236" : "transparent",
              transition: "all 1s",
            }}
          >
            <i
              style={{
                fontSize: width>900? "30px":'20px',
                color: phoneError ? "white" : "#474646",
                opacity: phoneError ? ".55" : "",
                transition: "all 1s",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="fas fa-mobile-alt"
            ></i>

            <div
              className="input"
              style={{
                background: "transparent",
              }}
            >
              <label
                htmlFor="phone"
                style={{
                  color: phoneError ? "white" : "#474646",
                  opacity: phoneError ? ".55" : "",
                  transition: "all 1s",
                  fontSize:width>500? '15px': '13px'
                }}
              >
                {t("Mobile")}
              </label>
                <PhoneInput
                  defaultCountry= 'AE'
                  name="phone"
                  style={{
                    background: "transparent",
                    color: phoneError ? "white" : "",
                    opacity: phoneError ? ".55" : "",
                    transition: "1s all",
                    paddingLeft: "0",
                    paddingRight: "0",
                    border: 'none',
                    backgroundColor: 'transparent',
                    
                  }}
                  value={phone}
                  onChange={(phone)=> setPhone(phone)}
                  required
                  className={phone? 'payment_numbers form-control translateFillMsg': lng==='arab'? 'force_arabic_font form-control translateFillMsg':'payment_numbers form-control translateFillMsg'}
                  id="phone"
                />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-block btn-danger btn-lg btn_for_form smallBtn_smallDevice"
            style={{
              background: "grey",
              border: "none",
              boxShadow: "none",
            }}
          >
            {t("Submit")}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace("/");
  }
};
export default Guest;
