import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./LangChangeModel.css";
import { useDispatch } from "react-redux";
import Layer1 from "../../../assets/flag2.png";
import Layer2 from "../../../assets/Layer 2.png";

function LangChangeModel(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();

  const [choosenLang, setChoosenLang] = useState(lng);

  const dispatch = useDispatch();
  const choosenOne = (str) => {
    setChoosenLang(str);
    localStorage.setItem("language", JSON.stringify({ lang: str }));
    props.onHide();
    window.location.reload();
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body"
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          className="modal_body_for_donation_final"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          <label>{t("Choose a language")} </label>
          <div className="chooseLang_btn_wraper" style={{ margin: "10px" }}>
            <button
              style={
                choosenLang === "eng"
                  ? {
                      border: "2px solid #ee4236",
                      boxShadow: "#ab9898 1px 0px 8px",
                      fontFamily:"english_font"
                    }
                  : {fontFamily:"english_font"}
              }
              id="btn_eng"
              onClick={() => choosenOne("eng")}
              className=" Lang_one_btn"
              
            >
              <img src={Layer1} alt="lang_one" className="Lang_one_btn_img" />
              English
            </button>

            <button
              style={
                choosenLang === "arab"
                  ? {
                      border: "2px solid #ee4236",
                      boxShadow: "#ab9898 1px 0px 8px",
                      fontFamily:"'arab_font'"
                    }
                  : {fontFamily:"'arab_font'"}
              }
              id="btn_arab"
              onClick={() => choosenOne("arab")}
              className=" Lang_one_btn"
            >
              <img src={Layer2} alt="lang_one" className="Lang_one_btn_img" />
              العربية
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default LangChangeModel;
