import React, { useState, useEffect, useRef } from "react";
import "../orphanItemBag/OrphanItemBag.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { addToBag, deleteFromBag } from "../../../../actions/BagFav";
import Loading from "../../../ui/loading";

const LatePaymentItemBag = (props) => {
  const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    const [latePaymentItemBagDetails, setLatePaymentItemBagDetails] = useState({
        name: props.allItem?.orphan_name,
        trn_amount: props.allItem?.trn_amount,
        trn_year: props.allItem?.trn_year,
        trn_serial: props.allItem?.trn_serial,
        orphanID: props.allItem?.orphanID
    })
    useEffect(() => {
        setLatePaymentItemBagDetails({
            name: props.allItem?.orphan_name,
            trn_amount: props.allItem?.trn_amount,
            trn_year: props.allItem?.trn_year,
            trn_serial: props.allItem?.trn_serial,
            orphanID: props.allItem?.orphanID
        })
    }, [props.allItem])
    const [addingToBag, setAddingToBag] = useState(false);
    const handleInBag = async (str) => {
        setAddingToBag(true);
        //let elementWeNeed = props?.bag?.latePayments?.filter(item => item.trn_serial === str.trn_serial).slice(0, 1).shift()
       // if (elementWeNeed) {
           await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.allItem?.cart_serial, lng === "arab" ? 1 : 2, props.allItem?.orphan_serial))
        //}
        if (_isMounted.current) {
          setAddingToBag(false);
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  return (
    <div
      className="big_wraper_bag"
      style={props.status === "not" ? bigWraperStyle : { width: "100%" }}
    >
      <div style={{position:'absolute', top:'0px', left: '0px', right:'0px', bottom:'0px', display: addingToBag? 'block':'none'}}>
        <Loading height={'100%'} width={'100%'}/>
      </div>
      <button
        disabled={addingToBag}
        style={
          lng === "arab"
            ? { right: "unset", left: "10px", color: "white" }
            : { color: "white" }
        }
        onClick={() => handleInBag(latePaymentItemBagDetails)}
        className="removeBtn_from_bag"
      >
        <i className="fas fa-times"></i>
      </button>
      <div
        className={addingToBag ? 'bag_item_com disable_page' : 'bag_item_com'}
        style={props.status === "not" ? styleForWraper : null}
      >
        <img
          src={props.allItem?.imagePath}
          alt="item_img"
          className="bag_item_img"
        />
        <div
          className="project_Details_bag"
          style={{ justifyContent: "flex-start", height: "100%", gap:'0px' }}
        >
          {lng === "arab" ? (
            <label
              style={
                width <= 1150
                  ? { fontSize: "13px", fontWeight: "bold", textAlign: "right" }
                  : { fontWeight: "bold", textAlign: "right" }
              }
            >
              {props.allItem?.orphan_name.split(" ")[0]}{" "}
              {props.renewalItem? t("needs your help"):t("needs your next payment")}
            </label>
          ) : (
            <label
              style={
                width <= 1150
                  ? { fontSize: "13px", fontWeight: "bold", textAlign: "left" }
                  : { fontWeight: "bold", textAlign: "left" }
              }
            >
              {props.allItem?.orphan_name.split(" ")[0]}{" "}
              {props.renewalItem? t("needs your help"):t("needs your next payment")}
            </label>
          )}
          {props.renewalItem?
          <label
          style={{fontSize: width<=1150? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}
          
          >
            {t("Months of renewal")}{" "}
            <span className='payment_numbers'>{props.allItem?.month_count}</span>
          </label>:
          <label
          style={{fontSize: width<=1150? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}
          
          >
            {t("You havent made the payment to support him since")}{" "}
            <span className='payment_numbers'>{`${props.allItem?.payment_date?.split("/")[0]}/${props.allItem?.payment_date?.split("/")[1]}/${props.allItem?.payment_date?.split("/")[2].split(" ")[0]}`}</span>
          </label>
        }
          
        </div>
        <label
          style={
            width <= 1150
              ? {
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "20px",
                  flex: "2",
                  alignSelf: "center",
                  fontSize: "13px",
                }
              : {
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "20px",
                  flex: "2",
                  alignSelf: "center",
                }
          }
        >
          {t("AED")} <span className='payment_numbers'>{props.allItem?.displayAmount}</span>
        </label>
      </div>
      <label
        style={props.status === "not" ? styleForNotAv : { display: "none" }}
      >
        {t("Not available")}
      </label>
    </div>
  );
}

export default LatePaymentItemBag;
