import { FETCH_ALL_MAP_LOCATIONS } from "../../constants/actionTypes";

export default (locations = [], action) => {
  switch (action.type) {
    case FETCH_ALL_MAP_LOCATIONS:
      return action.payload.Results;
    default:
      return locations;
  }
};
