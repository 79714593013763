import React, {useEffect, useState} from 'react'
import './FavoritesPage.css'
import DonationSection from './donationsSection/DonationSection'
import SukukSection from './sukukSection/SukukSection'
import ProjectsSection from './projectsSection/ProjectsSection'
import OrphansSection from './orphansSection/OrphansSection'
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader'
import Loading from '../ui/loading'
import { useSelector, useDispatch } from 'react-redux'

const FavoritesPage = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const history = useHistory()
    const userFav = useSelector(state => state.favorites.favorites)
    const userFavLoading = useSelector(state => state.favorites.loading)
    const [addingToFav, setAddingToFav] = useState(false);
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    const [favoritesDetailsLoading, setFavoritesDetailsLoading] = useState(userFavLoading)

    useEffect(() => {
        setFavoritesDetails(userFav)
        setFavoritesDetailsLoading(userFavLoading)
    }, [userFav, userFavLoading])
    

    return (
        <div id='favorites_page' style={lng === 'arab' ? { direction: 'rtl', position: 'relative' } : { position: 'relative' }}>
            <ShareHeader noShare={true} fromFavorites={true} pageName={t("Favorites")}/>
            <h2 className='headerForDesktop'>{t("Favorites")}</h2>
            {favoritesDetailsLoading?
            <Loading height='600px'/>:
            <React.Fragment>
                <DonationSection favoritesDetails={favoritesDetails} />
                <SukukSection favoritesDetails={favoritesDetails}/>
                <ProjectsSection favoritesDetails={favoritesDetails}/>
                <OrphansSection favoritesDetails={favoritesDetails}/> 
            </React.Fragment>
        } 
        </div>
    )
}

export default FavoritesPage