import React, { useEffect, useState } from "react";
import "./footer.css";
import ERC from "../../../assets/logo_desktop_whiteV2.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const location = useLocation();
  const history = useHistory();
  const [vlnindex, setVlnIndex] = useState(0);
  const vlnRegisterProgress = useSelector((state) => state.vlnRegisterProgress);
  const aboutus = useSelector((state) => {
    return state.aboutus;
  });
  useEffect(() => {
    if (vlnRegisterProgress.data) {
      if (!vlnRegisterProgress.data.completedPic) {
        setVlnIndex(1);
      } else if (!vlnRegisterProgress.data.completedJob) {
        setVlnIndex(2);
      } else if (!vlnRegisterProgress.data.completedEducation) {
        setVlnIndex(3);
      } else if (!vlnRegisterProgress.data.completedEmiratesID) {
        setVlnIndex(4);
      } else if (!vlnRegisterProgress.data.completedResidence) {
        setVlnIndex(5);
      } else if (!vlnRegisterProgress.data.completedPassport) {
        setVlnIndex(6);
      } else {
        setVlnIndex(-1);
      }
    }
  }, [vlnRegisterProgress]);
  useEffect(() => {
    if (
      (location.pathname === "/auth") |
      (location.pathname === "/auth/needToOTP") |
      (location.pathname === "/auth/reset-password") |
      (location.pathname === "/auth/get-account") |
      (location.pathname === "/sponsership/followups") |
      (location.pathname === "/guest") |
      (location.pathname === "/volunteer/certificate-pdf")

    ) {
      document.getElementById("footer").style.display = "none";
    } else {
      document.getElementById("footer").style.display = "flex";
    }
  }, [location]);
  return (
    <footer id="footer">
      <div
        id="footer_up"
        style={
          lng === "arab"
            ? {
              clipPath: "polygon(100% 0%, 100% 100%, 0% 0%)",
              direction: "rtl",
            }
            : null
        }
      >
        <img src={ERC} alt="ERC_logo" className="ERC_logo_footer" />
      </div>
      <div
        id="real_footer"
        style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
      >
        <ul id="about_US" style={lng === "arab" ? { direction: "rtl" } : null}>
          <li>
            <label
              style={{
                padding: lng==='arab'? '0px 0px 0px 10px':'0px 10px 0px 0px',
                marginBottom: '5px',
                borderBottom: '1px solid #cc0000',
                fontSize: "20px",
                color: "#cc0000",
                fontWeight: "bold",
              }}
            >
              {t("About Us")}
            </label>
          </li>
          {aboutus?.map((aboutus, i) => (
            <li key={i}>
              <a
                key={i}
                className="AlignLeft"
                onClick={() => history.push(`/about?name=${aboutus.title}`)}
              >
                {t(aboutus.title)}
              </a>
            </li>
          ))}
          <li>
              <a
                className="AlignLeft"
                href="/our-partners"
              >
                {t("Our Partners")}
              </a>
            </li>
        </ul>
        <ul id="about_US" style={lng === "arab" ? { direction: "rtl" } : null}>
          <li>
            <label
              style={{
                padding: lng==='arab'? '0px 0px 0px 10px':'0px 10px 0px 0px',
                marginBottom: '5px',
                borderBottom: '1px solid #cc0000',
                fontSize: "20px",
                color: "#cc0000",
                fontWeight: "bold",
              }}
            >
              {t("Services")}
            </label>
          </li>
          <li>
            <a
              className="cursorclick"
              onClick={() => 
                userLogin?.Results[0]?.vln_id_flag === '2' ?
                  history.push("/volunteer"):
                    userLogin?.Results[0]?.vln_id_flag === '1' && vlnindex >-1? 
                    history.push("/volunteer/vln-registration", {stepNo: vlnindex}):
                    history.push("/volunteer/vln-registration")}>
              {t("Volunteer now")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              onClick={() => history.push("/beasponser/1")}
            >
              {t("Sponsor an orphan")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='https://www.atayaprojects.ae/'
            >
              {t("Ataya")}
            </a>
          </li>
          {userLogin?.Results[0]?.sponser_id ?
          <li>
            <a
              className="cursorclick"
              onClick={() => history.push("/sponsership", {navigateTo: 0})}
            >
              {t("Sponsors portal")}
            </a>
          </li>:null
        }
          <li>
            <a
              className="cursorclick"
              onClick={() => history.push(userLogin? '/profile':"/auth")}
            >
              {t("Beneficent Portal")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='https://erp.rcuae.ae/OA_HTML/AppsLocalLogin.jsp'
            >
              {t("Supplier login")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href={lng === 'arab'?'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=AR': 'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=US'}
            >
              {t("Register a new supplier")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='http://portal.rcuae.ae/local_aid/user_login_log.aspx'
            >
              {t("Purchasing Committees Accreditation Portal")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='http://portal.rcuae.ae/local_aid/user_login.aspx'
            >
              {t("Portal of aid and accreditation of committees")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='https://portal.rcuae.ae/orphans_sys/'
            >
              {t("Sponsorships and Orphans Affairs Portal")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              href='https://apex.rcuae.ae/ords/r/erc_apps/erc-hub/login'
            >
              {t("External project management system")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              onClick={() => history.push(userLogin?.Results[0]?.person_id_flag === '2' ? "/localAid" : '/localAid/setup-information')}
            >
              {t("Local aid portal")}
            </a>
          </li>
          <li>
            <a
              className="cursorclick"
              onClick={() => history.push("/donations")}>{t("Donate now")}</a>
          </li>
          <li>
            <a href="/our-locations">{t("Our Locations")}</a>
          </li>
        </ul>
        <div
          id="contact_us"
          style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
        >
          <div
            style={
              lng === "arab"
                ? {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  direction: "rtl",
                }
                : { display: "flex", flexDirection: "column", width: "100%" }
            }
            className="ques"
          >
            <label
              style={{ fontWeight: "bold", fontSize: "25px", width: "100%" }}
            >
              {t("Need Help?")}
            </label>
            <label
              style={{ fontSize: "17px" }}
              onClick={() => history.push("/contact-us")}
              className="contactusnow"
            >
              {t("Contact Us Now")}
            </label>
            {/* <form>
              <div id="first_inputs">
                <div
                  className="form_inputs_inline"
                  style={
                    lng === "arab" ? { flexDirection: "row-reverse" } : null
                  }
                >
                  <i className="fas fa-user"></i>
                  <div
                    className="input"
                    style={lng === "arab" ? { alignItems: "flex-end" } : null}
                  >
                    <label for="name">{t("Name")}</label>
                    <input type="text" id="name"></input>
                  </div>
                </div>
                <div
                  className="form_inputs_inline"
                  style={
                    lng === "arab" ? { flexDirection: "row-reverse" } : null
                  }
                >
                  <i className="far fa-envelope"></i>
                  <div
                    className="input"
                    style={lng === "arab" ? { alignItems: "flex-end" } : null}
                  >
                    <label for="email">{t("E-mail")}</label>
                    <input type="text" id="email"></input>
                  </div>
                </div>
              </div>
              <div className="messageInput">
                <label
                  for="message"
                  style={
                    lng === "arab"
                      ? { width: "100%", textAlign: "right" }
                      : null
                  }
                >
                  {t("Message")}
                </label>
                <textarea type="text" id="message"></textarea>
              </div>
              <button className="btn btn-block btn-danger contactBtn">
                {t("Send")}
              </button>
            </form> */}
          </div>
        </div>
      </div>
      <div
        id="footer_down"
        style={
          lng === "arab"
            ? {
              clipPath: "polygon(100% 100%, 0% 100%, 0% 0%)",
              direction: "rtl",
            }
            : null
        }
      >
        <label style={{ color: "white", width: "100%", textAlign: "center" }}>
          {t("@ ALL RIGHT RESERVED EMIRATES RED CRESCENT 2021")}
        </label>
        <div className="social_media">
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://m.facebook.com/emiratesrc"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.instagram.com/emiratesrc/"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://mobile.twitter.com/emiratesrc"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.snapchat.com/add/emiratesrc"
          >
            <i className="fab fa-snapchat"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
