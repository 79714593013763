import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addToBag } from '../../../actions/BagFav'
import Loading from "../../ui/loading";
import { useSelector, useDispatch } from "react-redux";


import "./RenewelsDialog.css";
function RenewelsDialog(props) {
  const [monthCount, setMonthCount] = useState(6)
  const [loadingState, setLoadingState] = useState(false)
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const userInfo = userLogin
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  function loadQueryParams() {
    if (props.location.search) {
      let qParms = props.location.search.replaceAll("?", "");
      let qObj = JSON.parse(
        '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      return qObj;
    }
  }
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  let months = [
    { title: "6 Months", value: 6 },
    { title: "1 Years", value: 12 },
    { title: "1.5 Years", value: 18 },
    { title: "2 Years", value: 24 },
  ];
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch()

  const handleClick = (str) => {
    if (str === '-') {
      setMonthCount(monthCount-1)
    } else {
      setMonthCount(monthCount+1)
    }
  }
  const handleaddToBag = async (item, setAddingToBag) => {
    setLoadingState(true)
    await dispatch(addToBag({
      cart: {
        donID: "1", userName: userLogin?.Results[0]?.name_a, userMob: userLogin?.Results[0]?.mobile_no,
        userEmail: userLogin?.Results[0]?.email, itemType: '5', amount: item.trn_amount, recuring: null, cart_serial: null,
        orphan_id: item.orp_id, sponsor_id: loadQueryParams().sponsor_id, month_count: monthCount
      }, language: lng === 'arab' ? 1 : 2
    }))
    setMonthCount(6);
    setLoadingState(false)
    props.onHide();
  }
  return (
    <Modal style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}} className='Choose_month_modal' centered show={props.show} onHide={()=> {props.onHide(); setMonthCount(6); setLoadingState(false)}}>
      {loadingState?
      <Loading height='189px'/>:
      <React.Fragment>
      <div style={{ padding: "10px", display:'flex', alignItems:'center', justifyContent:'space-evenly', gap: '10px', flexWrap:'wrap', cursor:'pointer'}}>
        {months.map((element) => {
          return (
            <div
            onClick={()=> setMonthCount(element.value)}
              key={element.value}
              style={{backgroundColor: monthCount===element.value? '#ee4236':null, color: monthCount===element.value? 'white':null, minWidth:'63.2px'}}
              className={"renewals-month-tile"}
              
            >
              {t(element.title)}
            </div>
          );
        })}
      </div>
      <div className='sukuk_inc_Dec'>
          <button className='sukukdec btn btn-light' onClick={() => { if (monthCount > 1) { handleClick('-') } }}>-</button>
              <label className='inc_dec_label_sukuk' style={{ fontSize: '15px', direction: lng==='arab'? 'rtl':'ltr' }}>{t('Months')}: <span className='payment_numbers' style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'none' }}>{monthCount}</span></label>
          <button className='sukukinc btn btn-light' onClick={() => handleClick('+')}>+</button>
      </div>
      <button
            onClick={() => handleaddToBag(props.data)}
            className="Donate_now_add_to_cart"
            style={{ width: "80%", alignSelf: "center", marginTop: "30px" }}
          >
            {t("Add to Bag")}
          </button>
          </React.Fragment>
    }
    
    </Modal>
  );
}
export default RenewelsDialog;
