import { t } from "i18next";
import Modal from "react-bootstrap/Modal";
import "./OrphanConfirmationModel.css";

function OrphanConfirmationModel(props) {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div>
              {t('Are you sure you wish to update the monthly amount donated to the orphan?')}
        </div>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
              <label onClick={props.confirmEdit} style={{minHeight:'auto', backgroundColor:'green', color:'white', height:'30px', width:'60px', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer'}}>{t("Yes")} </label>
              <label onClick={() => props.setConfirmationModel(false)} style={{minHeight:'auto', backgroundColor:'red', color:'white', height:'30px', width:'60px', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer'}}>{t("No")} </label>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default OrphanConfirmationModel;
