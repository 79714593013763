import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./projectShare.css";
import ProjectShareCom from "./projectShareCom/projectShareCom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../../../ui/loading";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
SwiperCore.use([Navigation, Autoplay, Pagination]);

const ProjectShare = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const projectShares = useSelector(
    (state) => state.projectShares.projectShares
  );
  const loading = useSelector(
    (state) => state.projectShares.loading
  );
  const [projectSharesDisplay, setProjectSharesDisplay] =
    useState(projectShares);
    const [projectSharesLoading, setProjectSharesLoading] =
    useState(loading);
  useEffect(() => {
    setProjectSharesLoading(loading);
  }, [loading]);

  useEffect(() => {
    setProjectSharesDisplay(projectShares);
  }, [projectShares]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const history = useHistory();

  const handleClick = () => {
    history.push("/project_share");
  };

  return (
    <section id="project-shares">
      <div
        className="section_title_div"
        style={
          lng === "arab"
            ? { flexDirection: "row-reverse" }
            : { flexDirection: "row" }
        }
      >
        <label
          className="all_section_title_hum"
          style={
            lng === "arab"
              ? {
                  fontWeight: "bold",
                  fontSize: "25px",
                  width: "100%",
                  textAlign: "right",
                }
              : {
                  fontSize: "25px",
                  width: "100%",
                  textAlign: "left",
                }
          }
        >
          {t("Project Share")}
        </label>
        {lng === "arab" ? (
          <button onClick={handleClick} className="section_button">
            {t("View All")}
          </button>
        ) : (
          <button onClick={handleClick} className="section_button">
            {t("View All")}
          </button>
        )}
      </div>
      {!projectSharesLoading? 
      projectSharesDisplay.length > 0 ? (
        <Swiper
          id="project-share"
          autoplay={{ delay: 999999000 }}
          loop={projectSharesDisplay.length > (width <= 900? 1 : width <= 1250? 2 : 3)}
          className="please"
          slidesPerView={width <= 900? 1 : width <= 1250? 2 : 3}
          spaceBetween={20}
          navigation={true}
          pagination={{ clickable: true }}
          
        >
          {projectSharesDisplay?.map((item, i) => (
            <SwiperSlide
              id="project-content"
              // style={{ padding: width <= 250 ? "0px" : "10px 10px 0px 10px" }}
              key={i}
            >
              <ProjectShareCom
                key={i}
                shareID={item.projectShareID}
                projectTypeImg={item.projTypeImagePath}
                projectCountryImg={item.countryImagePath}
                title={item.projectName}
                text={item.longDesc}
                raised={item.amountCollected}
                goal={item.amount}
                shareYear={item.projectShareYear}
                projectShare={item}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div style={{textAlign: 'center', minHeight: '135px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{t("No Project Shares Available")}</div>
      ):<Loading />}
    </section>
  );
};
export default ProjectShare;
