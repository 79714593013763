import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./SortModalTransaction.css";
import { useTranslation } from "react-i18next";

const SortModalTransaction = (props) => {
    const [activeSortBtn, setActiveSortBtn] = useState('ASC')
  const [width, setWidth] = useState(window.innerWidth);

  const { t } = useTranslation();
  const [checked, setChecked] = useState('')

  let lng = JSON.parse(localStorage.getItem('language'))?.lang;

  const handleCheck = (str) => {
    setChecked(str)
  }
  const handleSort = () => {
    props.sortOrphans(checked, activeSortBtn)

  }
  
  return (
  <Modal
    show={props.show} onHide={props.onHide}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    // className={adStyle.donation_pop_up_body_forWelcome}
  >
             
    <Modal.Body>
    <div className='filter_modal' style={lng === 'arab' ? { backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px', direction: 'rtl' } : { backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
                    <div className='filter_inner_wraper' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: '10px', width: '100%' }}>
                        <div className='headerForAnimate animate__animated animate__fadeInUp'>
                            <h4 style={{ textAlign: 'center' }}>{t("Sorting")}</h4>
                            <div className='payment_methods_body' style={{ backgroundColor: 'transparent', boxShadow: 'unset', width: '100%' }}>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item_new'>
                                        <input onChange={() => handleCheck('name')} type="checkbox" id="name" name="order" className='check_input' checked={checked === 'name' ? true : false} />
                                        <label className='filter_choises' htmlFor="name">{t("NAME")}</label>
                                    </div>
                                </div>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item_new'>
                                        <input onChange={() => handleCheck('price')} type="checkbox" id="price" className='check_input' name="order" checked={checked === 'price' ? true : false} />
                                        <label className='filter_choises' htmlFor="price">{t("PRICE")}</label>
                                    </div>
                                </div>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item_new'>
                                        <input onChange={() => handleCheck('date')} type="checkbox" id="date" className='check_input' name="order" checked={checked === 'date' ? true : false} />
                                        <label className='filter_choises' htmlFor="date">{t("DATE")}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className="sortChoice">
                        <button onClick={() => setActiveSortBtn("ASC")} className={`sortBtn ${activeSortBtn === "ASC"? 'sortActive':''}`}>{t("ASC")}</button>
                        <button onClick={() => setActiveSortBtn("DESC")} className={`sortBtn ${activeSortBtn === "DESC"? 'sortActive':''}`}>{t("DESC")}</button>
                    </div>
                    <button onClick={() => handleSort()} className='doneBtn sortSaveBtn'>{t("Save")}</button>
                </div>
    </Modal.Body>
  </Modal>
  )
};
export default SortModalTransaction;