import { GET_ABOUT_US } from "../../constants/actionTypes";

export default (aboutus = [], action) => {
  switch (action.type) {
    case GET_ABOUT_US:
      return action.payload
    default:
      return aboutus;
  }
};
