import React from 'react';
import './DonationsPage.css';
import DonationPage from './donationPage/DonationPage';
import DonationSupportPage from './donationSupportPage/DonationSupportPage';
import { Route, Switch } from 'react-router-dom';

  
function DonationsPage() {    
  
  return (
    <div className="Donations_page">
      <Switch>
        <Route path="/donations/" component={DonationPage} exact />
        <Route path="/donations/:id" component={DonationSupportPage} />
      </Switch>
    </div>
  );
}

export default DonationsPage;
