import React, { useState } from 'react'
import './EventHomeComp.css'
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useTranslation } from "react-i18next";
var arabic = /[\u0600-\u06FF]/;
const EventHomeComp = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const date = new Date(props.item.start_date.split('/')[1] + "/" + props.item.start_date.split('/')[0] + "/" + props.item.start_date.split('/')[2])
    const day = date.toLocaleDateString(lng === "arab"?"ar-GB": "en-GB", {
        day: "numeric",
        })
    const weekday = date.toLocaleDateString(lng === "arab"?"ar-GB": "en-GB", {
        weekday: "long",
        })
    const month = date.toLocaleDateString(lng === "arab"?"ar-GB": "en-GB", {
        month: "short"
        })
  return (
    <React.Fragment>
        <div className='event-card' onClick={() => setOpen(true)}>
        <div className='left-red-thing' />
        <div className='right-red-thing' />
        <label style={{fontFamily: arabic.test(weekday)? 'arab_font':"english_font_bold"}} className='weekday_label'>{weekday}</label>
        <label className='day_label payment_numbers'>{day}</label>
        <label style={{fontFamily: arabic.test(month)? 'arab_font':"english_font_bold"}} className='month_label'>{month}</label>
        <label className='event_label'style={{textAlign: 'center', fontFamily: arabic.test(props.item.title)? 'arab_font':"english_font_bold"}}>{window.innerWidth < 500? props.item.title.length > 10? props.item.title.slice(0, 10) + "..." : props.item.title : props.item.title}</label>
    </div>
    {open && (
        <BottomSheet open={open} onDismiss={() => setOpen(false)} header={<div><h6>{weekday}</h6><h6>{day}</h6><h6>{month}</h6><h6>{props.item.title}</h6></div>}
        style={{fontFamily: lng==='arab'? "arab_font":'english_font', direction: lng==='arab'? "rtl":'ltr'}}                      >
    
      <div  style={{display: "flex",justifyContent: "center",cursor: "pointer", textAlign: "center", padding: "20px 20px 20px 20px"}}>
          {props.item.description}
      </div>
      <div style={{ height: "100px" }}></div>
          </BottomSheet>
      )}
    </React.Fragment>
  )
}

export default EventHomeComp