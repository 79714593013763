import React, { useEffect, useState } from "react";
import "./GetAccount.css";
import ERCLogo from "../../../../assets/logo.png";
import BCE2mob from "../../../../assets/loginBCMob2.png";
import BCE2 from "../../../../assets/loginBC.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LoadingModal from "../loadingPopUp/loadingPopUp";
import { useDispatch } from "react-redux";
import { getingYourAcc } from "../../../../actions/user";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import ShareHeader from "../../../LoaclComponents/shareHeader/ShareHeader";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";

const GetAccount = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [content, setContent] = useState("yes");
  const [display, setDisplay] = useState(1);
  const [userID, setUserID] = useState("");
  const [userAccount, setUserAccount] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  useEffect(() => {
    if (width > 900) {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2})`;
    } else {
      document.getElementById(
        "login_sign"
      ).style.backgroundImage = `url(${BCE2mob})`;
    }
  }, [width]);
  const [doneModal, setDoneModal] = useState(false);
  const openFromLog = () => {
    history.push("/");
  };
  const getYourAcc = (e) => {
    e.preventDefault();
    // if (!validateEmiratesId(userID)) {
    //   toastifyMessage(
    //     "EmiratesID number should be 15 digits long",
    //     undefined,
    //     "warning"
    //   );
    //   return false;
    // } else {
      setLoadingModalShow(true);
      dispatch(
        getingYourAcc(userID, setLoadingModalShow, setUserAccount, setDisplay)
      );
    //}
  };
  const validateEmiratesId = (eId) => {
    const re = /^[0-9]{15}$/;
    return re.test(eId);
  };
  if (!userLogin) {
    return (
      <div
        id="login_sign"
        style={{ backgroundImage: `url(${BCE2})`, backgroundSize: "cover", justifyContent:'flex-start', justifyContent:'flex-start', minHeight:'100vh' }}
      >
        <ShareHeader noTitle={true} noShare={true} forAuth={true}/>
        <div className="NeedToOtp">
          <img
            src={ERCLogo}
            onClick={openFromLog}
            alt="ERC_logo"
            className="ERC_logo_login_sign"
          />
          <h1 style={lng === "arab" ? { direction: "rtl" } : null}>
            {t("Welcome")}
          </h1>
          {display === 1 ? (
            <React.Fragment>
              <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
                {t("Please Enter Your Emirates ID/Sponsor ID To Get Your Account.")}
              </h3>
              {content === "yes" ? (
                <form
                  onSubmit={getYourAcc}
                  className="otp_input_wraper"
                  style={{
                    width: "90%",
                    flexDirection: "row",
                    padding: "0px",
                    gap: "0px",
                    alignItems: "unset",
                    direction: lng==='arab'? 'rtl':'ltr'
                  }}
                >
                  <input
                    maxLength="15"
                    style={{ width: "100%", padding: "10px" }}
                    className="input_for_verfication_code"
                    type="text"
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                  />
                  <button style={{borderTopRightRadius: lng==='arab'?  '0px':'5px', borderBottomRightRadius: lng==='arab'? '0px':'5px', borderTopLeftRadius: lng==='arab'? '5px':'0px', borderBottomLeftRadius:lng==='arab'? '5px':'0px'}} type="submit" className="confirm_otp">
                    {t("Confirm")}
                  </button>
                </form>
              ) : (
                <React.Fragment>
                  <h1>{content}</h1>
                  <button
                    onClick={() => history.push("/auth")}
                    style={{
                      backgroundColor: "gray",
                      border: "none",
                      padding: "7px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {t("Back")}
                  </button>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className='titleSmall_deviceSmall' style={lng === "arab" ? { direction: "rtl" } : null}>
                {t("Here Is Your Account")}
              </h3>
              <div
                className="otp_input_wraper"
                style={{
                  width: width<= 900? '95%':"80%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "10px",
                  direction: lng==='arab'? 'rtl':'ltr'
                }}
              >
                <label>
                  {t("E-mail")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold" }}>
                    {userAccount.email}
                  </span>
                </label>
                <label>
                  {t("Mobile")}:{" "}
                  <span className='payment_numbers' style={{ fontWeight: "bold", direction:'ltr', display:'inline-block' }}>
                    {userAccount.mobile}
                  </span>
                </label>
              </div>
              <button
                onClick={() => history.push("/auth")}
                style={{
                  backgroundColor: "gray",
                  border: "none",
                  padding: "7px",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                {t("Back")}
              </button>
            </React.Fragment>
          )}
        </div>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
  } else {
    window.location.replace("/");
  }
};
export default GetAccount;
