import React, { useState, useEffect, useRef } from 'react'
import './ProjectItemBag.css'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag } from '../../../../actions/BagFav'
import Loading from '../../../ui/loading';
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import { FETCH_LOCALLY } from '../../../../constants/actionTypes';

const styleForWraper = {
    filter: 'blur(8px)',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const bigWraperStyle = {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const styleForNotAv = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2000'
}
const styleForXBtn = {
    color: 'white'
}
const ProjectItemBag = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    const [projectItemOnBagDetails, setProjectItemBagDetails] = useState({
        projectYear: new Date().getFullYear(),
        countryId: props.countryId,
        countryImg: props.flag,
        countryName: props.countryTitle,
        projectAmount: props.projectAmount,
        projectId: props.projectId,
        projectImg: props.projectPhoto,
        projectName: props.title,
        baseAmount: props.baseAmount
        //projyear,
        //branch id 1(for now)
    })
    useEffect(() => {
        setProjectItemBagDetails({
        projectYear: new Date().getFullYear(),
        countryId: props.countryId,
        countryImg: props.flag,
        countryName: props.countryTitle,
        projectAmount: props.projectAmount,
        projectId: props.projectId,
        projectImg: props.projectPhoto,
        projectName: props.title,
        baseAmount: props.baseAmount
        })
    }, [props.projectId, props.projectAmount])
    const [addingToCart, setAddingToCart] = useState(false);
    const handleInBag = async (str) => {
        setAddingToCart(true);
        if (!userLogin) {
            let newProfile = {
                cart: {
                    donations: props.currentProfile.cart.donations,
                    sukuks: props.currentProfile.cart.sukuks,
                    projects: props.currentProfile.cart.projects.filter(item => !(item.projectId === str.projectId && item.projectYear === str.projectYear)),
                    humanCases: props.currentProfile.cart.humanCases,
                    orphans: props.currentProfile.cart.orphans
                },
                liked: props.currentProfile.liked
            }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            toastifyMessage("Removed!", undefined, "success");
            if (_isMounted.current) {
              props.setChange(props.change + 1)  
            }
        } else {
            await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.cart_serial, lng === "eng" ? 2 : 1, ""))

        }
        if (_isMounted.current) {
            setAddingToCart(false);
          }
    }

    const handleClick = async (op) => {
        setAddingToCart(true);
        if (op === '-') {
                if (!userLogin) {
                    let copyOf = props.currentProfile.cart.projects.slice(0);
                    copyOf.splice(copyOf.findIndex(item => item.projectId === props.projectId && item.projectYear === props.projectYear), 1)
                    let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: copyOf, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                    const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                    localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                    await dispatch({type: FETCH_LOCALLY})
                    toastifyMessage("Modified!", undefined, "success");
                    if (_isMounted.current) {
                        props.setChange(props.change + 1)  
                      }
                } else {
                    if (props.quantity>1) {
                        await dispatch(addToBag({
                        cart: {
                            userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity - 1
                        }, language: lng === 'arab' ? 1 : 2
                    }, false, 'justEdit'))
                    if (_isMounted.current) {
                        props.setChange(props.change + 1)  
                      }
                    } else {
                        await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.cart_serial, lng === "eng" ? 2 : 1, ""))
                    }
                    

                }
        } else {
            if (!userLogin) {
                let copyOf = props.currentProfile.cart.projects.slice(0);
                copyOf.splice(copyOf.findIndex(item => item.projectId === props.projectId && item.projectYear === props.projectYear), 0,
                {...projectItemOnBagDetails, projectAmount: parseInt(props.baseAmount)})
                let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: copyOf , humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans}, liked: props.currentProfile.liked }
                const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                await dispatch({type: FETCH_LOCALLY})
                toastifyMessage("Modified!", undefined, "success");
                if (_isMounted.current) {
                    props.setChange(props.change + 1)  
                  }
            } else {
                await dispatch(addToBag({
                    cart: {
                        userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity + 1
                    }, language: lng === 'arab' ? 1 : 2
                }, false, 'justEdit'))
                if (_isMounted.current) {
                    props.setChange(props.change + 1)  
                  }
            }
        }
        if (_isMounted.current) {
            setAddingToCart(false);
          }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);

    return (
        <div className='big_wraper_bag' style={props.status === 'not' ? bigWraperStyle : { width: '100%' }}>
            <button style={lng === 'arab' ? { right: 'unset', left: '10px', color: 'white' } : { color: 'white' }} disabled={addingToCart} onClick={() => handleInBag({ projectId: props.projectId, projectYear: props.projectYear })} className='removeBtn_from_bag'><i className="fas fa-times"></i></button>
            <div style={{position:'absolute', top:'0px', left: '0px', right:'0px', bottom:'0px', display: addingToCart? 'block':'none'}}>
                <Loading height={'100%'} width={'100%'}/>
            </div>
            <div className={addingToCart ? 'bag_item_com disable_page' : 'bag_item_com'} style={props.status === 'not' ? styleForWraper : null}>
                <img src={props.projectPhoto} alt='item_img' className='bag_item_img_projects' />
                <div className='project_Details_bag'>
                    {lng === 'arab' ?
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'right' } : { fontWeight: 'bold', textAlign: 'right' }}>{props.title}</label> :
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'left' } : { fontWeight: 'bold', textAlign: 'left' }}>{props.title}</label>
                    }
                    <div className='country_img_wraper_Bag'>
                        <img style={width <= 1150 ? { height: '20px', width: '20px' } : { height: '20px', width: '20px' }} src={props.flag} className='country_Img_Bag' alt='country_img' />
                        <label style={width <= 1150 ? { fontSize: '13px' } : null}>{props.countryTitle}</label>
                    </div>
                    <label style={{fontSize: width <=1150? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}>{t("Quantity")}: <span className='payment_numbers'>{props.quantity}</span></label>
                </div>
                <div className='donation_inc_Dec_bag'>
                    <button disabled={addingToCart || props.status === 'not'} className='inc' onClick={() => handleClick('-')}>-</button>
                        <label className='label_to_beSmall payment_numbers' style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold', minHeight:'unset'}}>{t("AED")} {props.projectAmount}</label>
                    <button disabled={addingToCart || props.status === 'not'} className='dec' onClick={() => handleClick('+')}>+</button>
                </div>
            </div>
            <label style={props.status === 'not' ? styleForNotAv : { display: 'none' }}>{t("Not available")}</label>
        </div>
    )
}

export default ProjectItemBag
