import React from 'react'
import './LocalAidProfileComp.css'

const LocalAidProfileComp = (props) => {
    return (
        <div className='local_aid_profile_comp'>
            <div className='left_side_of_local_aid_profile_comp'>
                <img src={props.icon} alt={props.keyOf} className='local_aid_profile_comp_icon'/>
                <label style={{color:'gray'}}>{props.keyOf}</label>
            </div>
            <div className='right_side_of_local_aid_profile_comp'>
                <label className={props.englishFont? 'valuee payment_numbers centered':'valuee centered'} style={{color:'black'}}>{props.value}</label>
            </div>
        </div>
    )
}

export default LocalAidProfileComp
