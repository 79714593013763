import React, { useEffect, useState } from "react";
import "./homepage.css";
import Banner from "./sections/banner/banner";
import BecomeASpon from "./sections/becomeASpon/becomeASpon";
import Donation from "./sections/donations/donation";
import Humanitarian from "./sections/humanitarian/humanitarian";
import Projects from "./sections/projects/projects";
import Sukuk from "./sections/sukuk/sukuk";
import Favorites from "./sections/favorites/Favorites";
import Events from "./sections/events/Events";
import ProjectShare from "./sections/projectShare/projectShare";
import { getSamsungToken } from "../../actions/handleSamsungPayment";
import { handleRegularPayment } from "../../actions/handleRegularPayment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import LoadingModal from "../Auth/loginSignPage/loadingPopUp/loadingPopUp";
const HomePage = () => {
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const history = useHistory();
  const userFav = useSelector((state) => state.favorites.favorites);
  const userFavLoading = useSelector((state) => state.favorites.loading);
  const [favoritesDetailsLoading, setFavoritesDetailsLoading] =
    useState(userFavLoading);
  useEffect(() => {
    setFavoritesDetailsLoading(userFavLoading);
  }, [userFavLoading]);
  const [donationFav, setDonationFav] = useState([]);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [fetchLoaclly]);
  useEffect(() => {
    if (userLogin) {
      let donationWithoutSukuk = userFav?.donations?.filter(
        (outerItem) =>
          userFav?.sukuks?.filter(
            (item) => item.dontypeID === outerItem.dontypeID
          ).length === 0
      );
      setDonationFav(donationWithoutSukuk);
    } else {
      setDonationFav(currentProfile?.liked?.donations);
    }
  }, [userFav, fetchLoaclly]);
  const dispatch = useDispatch();

  const userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let obj = JSON.parse(localStorage.getItem("samsungDirectPay"));
  localStorage.removeItem("samsungDirectPay");

  useEffect(() => {
    const fetchData = async () => {
      if (obj && obj.item && obj.item.length > 0) {
        var url = new URL(window.location.href);
        var ref_id = url.searchParams.get("ref_id");
        if (!ref_id) {
          return;
        }
        setLoadingModalShow(true);
        var res = await getSamsungToken();
        if (res.status) {
          // setToken(res.data);
          var bag = [];
          obj.item.forEach((item) => {
            bag.push({
              remarks: item.remarks ? item.remarks : null,
              dontype_id:
                item.dontype_id != null
                  ? item.dontype_id
                  : item.donationId
                  ? item.donationId
                  : item.sukId
                  ? item.sukId
                  : item.donID != null
                  ? item.donID
                  : item.dontype_id != null
                  ? item.dontype_id
                  : null,
              doner_name: userLogin
                ? userLogin.Results[0].name_a
                : visitor
                ? visitor.name
                : "Samsung User",
              mobile: userLogin
                ? userLogin.Results[0].mobile_no
                : visitor
                ? visitor.phone
                : "Samsung User",
              email: userLogin
                ? userLogin.Results[0].email
                : visitor
                ? visitor.email
                : "Samsung User",
              orptype_id:
                item.orpTypeID != null
                  ? item.orpTypeID
                  : item.orptype_id != null
                  ? item.orptype_id
                  : null,
              wrkplc_id:
                item.workPlaceID != null
                  ? item.workPlaceID
                  : item.branch
                  ? item.branch
                  : 1,
              prj_name: item.prj_name
                ? item.prj_name
                : item.project_name
                ? item.project_name
                : null,
              projectYear: item.projectYear ? item.projectYear : null,
              template_id: item.template_id
                ? item.template_id
                : item.projectID
                ? item.projectID
                : null,
              vln_id: item.vln_id
                ? item.vln_id
                : item.volunteer_id
                ? item.volunteer_id
                : null,
              vln_service_cod:
                item.vln_service_cod != null ? item.vln_service_cod : null,
              vln_date_from: item.vln_date_from ? item.vln_date_from : null,
              vln_date_to: item.vln_date_to ? item.vln_date_to : null,
              vln_desc: item.vln_desc ? item.vln_desc : null,
              Mastercard_order_id: item.Mastercard_order_id
                ? item.Mastercard_order_id
                : null,
              item_type:
                item.itemType != null
                  ? item.itemType
                  : item.item_type != null
                  ? item.item_type
                  : null,
              country_id: item.country_id ? item.country_id : null,
              amount: item.amount,
              recuring:
                item.donationFreq != null
                  ? item.donationFreq
                  : item.recuring != null
                  ? item.recuring
                  : item.recurring
                  ? item.recurring
                  : null,
              month_count: item.month_count != null ? item.month_count : 1,
              humanCase:
                item.humanCase != null
                  ? item.humanCase
                  : item.caseID
                  ? item.caseID
                  : null,
              humanYear: item.humanYear ? item.humanYear : null,
              valid_flag: item.valid_flag ? item.valid_flag : null,
              sponsor_id: item.sponsor_id
                ? item.sponsor_id
                : item.sponser_id
                ? item.sponser_id
                : userLogin
                ? userLogin.Results[0].sponser_id
                  ? userLogin.Results[0].sponser_id
                  : null
                : null,
              cart_serial: item.cart_serial != null ? item.cart_serial : null,
              orphan_id: item.orphan_id ? item.orphan_id : null,
              transaction_number: item.transaction_number
                ? item.transaction_number
                : item.trn_serial
                ? item.trn_serial
                : null,
              transaction_year: item.transaction_year
                ? item.transaction_year
                : item.trn_year
                ? item.trn_year
                : null,
              quantity: item.quantity ? item.quantity : 1,
              share_id: item.share_id
          ? item.share_id
          : null,
          share_year: item.share_year
          ? item.share_year
          : null,
          public_flag: item?.public_flag
            });
          });

          var res = await handleRegularPayment(
            {
              tokn: JSON.parse(res.data)["3DS"].data,
              payment_type: "SAMSUNG",
              bag: bag,
            },
            dispatch,
            null,
            null
          );
          if (res && res.status) history.push(`/receipt/${res.mco}`);
        }
        window.history.pushState({}, document.title, window.location.pathname);
        setLoadingModalShow(false);
        // localStorage.removeItem("samsungDirectPay");
      } else {
        // localStorage.removeItem("samsungDirectPay");
      }
      setLoadingModalShow(false);
    };
    fetchData();
  }, []);

  return (
    <div id="home">
      <Banner />
      <Donation />
      <Humanitarian />
      <ProjectShare />
      <Sukuk />
      <BecomeASpon />
      <Events />
      <Projects />
      {donationFav?.length > 0 || favoritesDetailsLoading ? (
        <Favorites />
      ) : null}

      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default HomePage;
