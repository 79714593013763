import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./TransactionList.css";
import TransactionItem from "./TransactionItemComp/TransactionItem";
import { addToBag, deleteFromBag } from '../../../actions/BagFav'
import Loading from "../../ui/loading";
import { useTranslation } from "react-i18next";

const TransactionList = (props) => {
  const [latePaymentsState, setLatePaymentsState] = useState(props.transactions);
  
  const loading = useSelector(state => state.latePayments.latePaymentLoading);
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const { t } = useTranslation();

  const dispatch = useDispatch()


  useEffect(() => {
    setLatePaymentsState(props.transactions)
  }, [props.transactions])
  const userInfo = useSelector((state) => {return state.auth.authData;});




    return (
      <React.Fragment>
        {loading?
        <Loading height='85vh' />:
        latePaymentsState.length > 0?
        <div className="latepayment_page" >
          {latePaymentsState?.map((late, i) => (
            <TransactionItem
              item={late}
              late={true}
              key={i}
            />
          ))}
        </div>:
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '85vh', width: '100%' }}>
            <label>{t("You don't have any transactions")}</label>
       </div>

      }
        
      </React.Fragment>
    );
};

export default TransactionList;
