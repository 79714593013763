import React from 'react'
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import './SponsorTerms.css'

const SponsorTerms = (props) => {
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const { t } = useTranslation();

    const arabicEnglishTerms = [
        {textAR: "اتعهد بصحة واستكمال كافة البيانات الواردة اعلاه، وبخلاف ذلك فانه يحق للهلال الاحمر الاماراتي عدم تخصيص المبلغ الى يتيم معين، وافوضهم بتحويل مبلغ الكفالة الى تبرع عام لصالح الايتام.",
        textEN: "I hereby undertake to validate and complete all the aforementioned information. Otherwise, Emirates Red Crescent has the right not to allocate the amount to a specific orphan. I hereby authorize   it to convert the sponsorship amount into a public donation for orphans.",
        or: 1},

        {textAR: "اتعهد بتبليغ الهلال الاحمر الاماراتي باي تغييرات في البيانات الشخصية للمحسن وخاصة ارقام التواصل.",
        textEN: "I hereby undertake to inform the Emirates Red Crescent Emirates Red Crescent of any changes in the personal data of the donor, especially the contact numbers.",
        or: 2},

        {textAR: "يحق لهيئة الهلال الاحمر استبدال المستفيد المكفول بمستفيد اخر في حال عدم اهليته لاستمرار الكفالة على ان يتم تبليغ المحسن بذلك.",
        textEN: "The Red Crescent Authority has the right to replace the sponsored beneficiary with another beneficiary in the event of his disqualification for continued sponsorship provided that the donor is notified accordingly.",
        or: 3},

        {textAR: "يحق للمحسن طلب تحويل المبالغ المخصصة للكفالة والتي لم يستلمها المستفيد المكفول لصالح مستفيد اخر، او تحويلها لصالح اي تبرع او مشروع اخر بالهيئة ولا يحق له طلب استرجاعها .",
        textEN: "The Donor shall have the right to request transfer of the amounts allocated for the sponsorship not received by the sponsored beneficiary  in favor of another beneficiary, or to transfer them in favor of any other donation or project executed by the Authority. He is not entitled to request a refund.",
        or: 4},

        {textAR: "يتم الغاء الكفالة تلقائيا في حال عدم تسديد قيمة الكفالة للمستفيد لمدة 3 اشهر متتالية.",
        textEN: "The sponsorship shall be automatically canceled in case the sponsorship amount is not paid to the beneficiary for three (3) consecutive months.",
        or: 5}
    ]
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size='lg'
      dialogClassName="popUpTerms"
    >
      <Modal.Body style={{direction: lng==='arab'? 'rtl':'ltr', padding:'0px', fontFamily: lng==='arab'? 'arab_font':'english_font'}}>
            <div className='complet_info_title' style={{backgroundColor:'rgb(255 252 252)',width:'100%', display:'flex',borderTopRightRadius:'15px',borderTopLeftRadius:'15px', alignItems:'center', justifyContent:'space-between', padding:'10px', minHeight:'100px', direction: lng==='arab'? 'rtl':'ltr'}}>
                <h5 style={{marginBottom:'0px'}}>{t("TERMS AND CONDITIONS OF SPONSORSHIP")}</h5>
                <div onClick={()=> {props.onHide()}} style={{width:'40px', height:'40px', borderRadius:'50%', display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #ee4236', cursor:'pointer'}}>
                    <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"
                    style={{height:'15px', width:'15px'}} alt='close_btn'/>
                </div>
            </div>
            <div className='terms_body'>
                {arabicEnglishTerms.map((item,i)=> 
                <div className='element_terms' key={i}>
                    <label>{item.or}-</label>
                    <label>{lng==='arab'? item.textAR:item.textEN}</label>
                </div>
                )
            }
            </div>
      </Modal.Body>
    </Modal>
  )
}

export default SponsorTerms