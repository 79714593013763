import React, {useEffect} from 'react'
import './StoryComponent.css'
import orphanDefault from '../../../assets/orphansDefaultDark.png'

const StoryComponent = (props) => {
    const handleClick = (id, amount) => {
        if (props.selectModeSwitch) {
            let currentItems = [...props.itemSelectedMode]
            if (currentItems.filter(item=> item===props.itemId).length>0) {
                currentItems = currentItems.filter(item=> item !== props.itemId)
                props.setItemSelectedMode(currentItems)
            } else {
              currentItems.push(id)
              props.setItemSelectedMode(currentItems)
            }
        } else {
            props.setSelectedItem(id)
            props.setOrphanAmount(amount)
        }
        
    }
    return (
        <button id={`Item_${props.itemId}`} onClick={()=> handleClick(props.itemId, props.amount)} className='story_component' style={{backgroundImage:`url(${props.img ?? orphanDefault})`, filter: !props.selectModeSwitch? props.selectedItem===props.itemId? 'unset':'grayscale(100%':'unset', position:'relative'}}>
            <span
                style={{ position: "absolute", top: "5px", left: "5px" }}
                className={
                      props.itemSelectedMode?.filter(
                        (innerItem) => innerItem === props.itemId
                      ).length > 0
                        ? "rightSign_btn--active"
                        : "rightSign_btn"
                    }
                  >
                    <i className="fas fa-check"></i>
                  </span>
            {props.title}
        </button>
    )
}

export default StoryComponent
