import React, { useState, useEffect } from "react";
// import "./LocalAidRelativeIdentity.css";
import icon1 from "../../../assets/nation id.png";
// import icon2 from "../../../assets/edducation.png";
import icon3 from "../../../assets/ntionality.png";
import icon4 from "../../../assets/mobile number.png";
import icon5 from "../../../assets/date of birth.png";
// import icon6 from "../../../assets/gender.png";
// import icon8 from "../../../assets/occupation.png";
// import icon9 from "../../../assets/marital status.png";
// import icon10 from "../../../assets/health.png";
// import icon12 from "../../../assets/email.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
    addLocalAidRelativeAddress,
} from "../../../api/local_aid";
import {
    getAllCountries,
} from "../../../actions/volunteer";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
    applyValidation,
    resetValidation,
} from "../../../handlers/formValidation";

import { getAddressTypes, getEmirates } from "../../../api";

const LocalAidRelativeAddress = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [errorFlg, setErrorFlg] = useState(false);
    const [addressTypes, setAddressTypes] = useState([]);
    const [emirates, setEmirates] = useState([]);


    const { t } = useTranslation();
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    // const userInfo = useSelector((state) => {
    //     return state.auth.authData;
    // });

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        let mounted = true
        getAddressTypes(lng == "arab" ? 1 : 2).then(response => {
            if (mounted) {
               setAddressTypes(response.data.data); 
            }
        });
        getEmirates(lng == "arab" ? 1 : 2).then(response => {
            if (mounted) {
               setEmirates(response.data.data); 
            }
        });
        return ()=> {
            mounted = false
        }
    }, []);

    useEffect(() => {
        dispatch(getAllCountries(lng == "arab" ? 1 : 2));
    }, [lng]);

    const [userInfoLocalAid, setUserInfoLocalAid] = useState({

        building_name: "",
        flat_no: "",
        PO_BOX: "",
        phone_no: "",
        mob_no: "",
        fax_no: "",
        primary_flag: 1,
        req_year: props.reqYear,
        address_type: "",
        country_id: "",
        emirate_id: "",
        address_description: "",
        req_id: props.reqID
    });
    const [countryState, setCountryState] = useState([])
    const vlnAllCountries = useSelector((state) => state.vlnCountries);
    useEffect(() => {
        setCountryState(vlnAllCountries);
    }, [vlnAllCountries]);

    const handleChange = (e) => {
        document.getElementById("submitBtn_for_local_aid").disabled = false;

        setUserInfoLocalAid({
            ...userInfoLocalAid,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingModalShow(true)
        if (userInfoLocalAid.building_name == null || userInfoLocalAid.building_name == ""
            || userInfoLocalAid.flat_no == null || userInfoLocalAid.flat_no == ""
            || userInfoLocalAid.PO_BOX == null | userInfoLocalAid.PO_BOX == ""
            || userInfoLocalAid.phone_no == null || userInfoLocalAid.phone_no == ""
            || userInfoLocalAid.mob_no == null || userInfoLocalAid.mob_no == ""
            || userInfoLocalAid.address_type == null || userInfoLocalAid.address_type == ""
            || userInfoLocalAid.country_id == null || userInfoLocalAid.country_id == ""
            || userInfoLocalAid.emirate_id == null || userInfoLocalAid.emirate_id == ""
            || userInfoLocalAid.address_description == null || userInfoLocalAid.address_description == "") {
            toastifyMessage("All fields are mandatory!", undefined, "error")
            return false;
        }
        var dataToSend = userInfoLocalAid;
        addLocalAidRelativeAddress(dataToSend)
            .then((res) => {
                setLoadingModalShow(false)
                // toastifyMessage("Success! Please fill the next form", undefined, "success");
                props.setSubmitRefetch(props.submitRefetch + 1)
                //props.setView(4)
            })
            .catch((e) => {
                toastifyMessage("Something Went Wrong...!", undefined, "error");
            });

    };

    function getUserInfo() {
        if (
            userLogin?.Results &&
            userLogin?.Results.length > 0 &&
            userLogin?.Results[0]
        ) {
            return userLogin?.Results[0];
        }
    }

        return (
            <div
                className="local_aid_info_form_page"
                style={
                    lng === "arab"
                        ? { direction: "rtl", minHeight: "500px" }
                        : { minHeight: "500px" }
                }
            >
                <div className='identity_data_uploaded'>
                {props.allformsData?.address?.map((item,i)=>
                    <div key={i} className='identity_inserted_element'>
                      <label className='fontFor_iserted'>{t("Address")}: <span style={{}}>{item.addressType}</span></label>
                      <label className='fontFor_iserted'>{t("Emirate")}: <span style={{}}>{item.emirateName}</span></label>
                      <label className='fontFor_iserted'>{t("Building Name")}: <span style={{}}>{item.buildingName}</span></label>
                      <label className='fontFor_iserted'>{t("Phone Number")}: <span style={{fontFamily:'english_font'}}>{item.phoneNo + ' ,' + item.phoneNo}</span></label>
                      <label className='fontFor_iserted'>{t("Country")}: <span style={{}}>{item.countryName}</span></label>
                      <label className='fontFor_iserted'>{t("Flat No.")}: <span style={{fontFamily:'english_font'}}>{item.flatNo}</span></label>
                      <label className='fontFor_iserted'>{t("P.O Box")}: <span style={{fontFamily:'english_font'}}>{item.poBox}</span></label>
                    </div>
                    )}
                </div>
                <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
                    {t("Address registration")}
                </label>
                <form className="local_aid_info_form" onSubmit={handleSubmit}>
                    <div className="just_the_form_without_submit">
                        <div className="form_left_side">


                            {/* select address */}
                            <div className="input_wraper">
                                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                                <select
                                    value={userInfoLocalAid.address_type}
                                    onChange={handleChange}
                                    name="address_type"
                                    className="selectedCourse"
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                >
                                    <option value="" disabled defaultValue>
                                        {t("Select Address")}
                                    </option>
                                    {addressTypes?.map((item, i) => (
                                        <option key={item.
                                            address_id} value={item.
                                                address_id}>
                                            {item.address_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* select address */}



                            <div className="input_wraper">
                                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                                <select
                                    value={userInfoLocalAid.emirate_id}
                                    onChange={handleChange}
                                    name="emirate_id"
                                    className="selectedCourse"
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                >
                                    <option value="" disabled>
                                        {t("Select Emirate")}
                                    </option>


                                    {emirates?.map((item, i) => (
                                        <option key={item.emirate_id} value={item.emirate_id}>
                                            {item.emirate_name}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon1} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                                    value={userInfoLocalAid.building_name}
                                    name="building_name"
                                    placeholder={t("Building Name")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>

                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon4} style={{ width: "25px", marginLeft: "5px", marginRight: "5px" }} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={userInfoLocalAid.phone_no? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                                    value={userInfoLocalAid.phone_no}
                                    name="phone_no"
                                    placeholder={t("Phone Number")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>

                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon4} style={{ width: "25px", marginLeft: "5px", marginRight: "5px" }} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={userInfoLocalAid.mob_no? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                                    value={userInfoLocalAid.mob_no}
                                    name="mob_no"
                                    placeholder={t("Mobile Number")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>


                        </div>
                        <div className="form_right_side">

                            {/* select emirate */}
                            <div className="input_wraper">
                                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                                <select
                                    value={userInfoLocalAid.country_id}
                                    onChange={handleChange}
                                    name="country_id"
                                    className="selectedCourse"
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                >
                                    <option value="" disabled defaultValue>
                                        {t("Select Country")}
                                    </option>
                                    {countryState?.map((item, i) => (
                                        <option key={item.countryID} value={item.countryID}>
                                            {item.countryName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* select emirate */}

                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon1} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                                    value={userInfoLocalAid.address_description}
                                    name="address_description"
                                    placeholder={t("Address")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>



                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon1} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={userInfoLocalAid.flat_no? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                                    value={userInfoLocalAid.flat_no}
                                    name="flat_no"
                                    placeholder={t("Flat No.")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>

                            <div className="input_wraper">
                                <div className="local_aid_input_icon_wraper">
                                    <img src={icon1} className="local_aid_icon" alt="form_icon" />
                                </div>

                                <input
                                    type="text"
                                    onChange={handleChange}
                                    className={userInfoLocalAid.PO_BOX? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                                    value={userInfoLocalAid.PO_BOX}
                                    name="PO_BOX"
                                    placeholder={t("P.O Box")}
                                    required
                                    onInvalid={applyValidation}
                                    onInput={resetValidation}
                                />
                            </div>




                        </div>
                    </div>
                    <button
                        id="submitBtn_for_local_aid"
                        type="submit"
                        className="local_aid_submit"
                        disabled
                    >
                        <span style={{ flex: "16" }}>{t("Save")}</span>
                        {lng === "arab" ? (
                            <i className="fas fa-arrow-left"></i>
                        ) : (
                            <i className="fas fa-arrow-right"></i>
                        )}
                    </button>
                </form>
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        );
};

export default LocalAidRelativeAddress;