import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logOutAssets from "../../../assets/logout.png";
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import logOutAssetsBottom from "../../../assets/log-out.png";


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


function SignOutWithGoogle(props) {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch();
    // const history = useHistory()

    const onSuccess = () => {
        dispatch({ type: 'LOGOUT_WITH_GOOGLE' });
    };

    const onFailure = (res) => {
        toastifyMessage(`Logout Failed!`, JSON.stringify(res), "error");
    };

    return (
        <GoogleLogout
            clientId={clientId}
            onLogoutSuccess={onSuccess}
            onFailure={onFailure}
            render={renderProps => (
                props.top?
                    <div onClick={renderProps.onClick} style={{cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' ,position:'absolute', left:'15px', top:'15px'}}>
                        <img src={logOutAssets} style={{ height: "30px", width: "30px", transform:'rotate(-180deg)' }} alt="sponsership"/>
                    </div>:
                    <div onClick={renderProps.onClick} className="profile_page_sponsership" style={{ cursor: "pointer" }}>
                    <div style={{position: "relative", display: "flex", gap: "10px", alignSelf: "flex-start",}} className="profile_btn_content">
                      <img src={logOutAssetsBottom} className='profile_button_icon' alt="sponsership"/>
                      <div className='user_items' style={{borderBottom:'none'}}>
                        <label style={{color:'#ee4236', minHeight:'55px', cursor:'pointer'}}>{t("Log Out")}</label>
                      </div>
                    </div>
                    {lng === "arab" ? (
                      <i style={{ color: "#ee4236", cursor: "pointer" }} className="fas fa-angle-left sponserArrow"></i>
                    ) : (
                      <i style={{ color: "#ee4236", cursor: "pointer" }} className="fas fa-angle-right sponserArrow"></i>
                    )}
                  </div>
            )}
        />
    );
}

export default SignOutWithGoogle;
