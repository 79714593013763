/* eslint-disable */
import "./CountriesDisplay.css";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import ProjectDisplayComp from "../projectsDisplay/projectDisplaycomp/ProjectDisplayComp";
import CountryProComp from "./countryProjectComp/CountryProComp";
import donateIcon from "../../../../../assets/redDonateIcon.png";
import cash from "../../../../../assets/donateByCash.png";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import likeIcon from "../../../../../assets/favour.png";
import likedIcon from "../../../../../assets/favourite.png";
import { startApplePay } from "../../../../../actions/handleApplePayment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import share from "../../../../../assets/share.png";
import samPay from "../../../../../assets/Pay_button_basic_pos_RGB.png";
import { addToBag, deleteFromBag } from "../../../../../actions/BagFav";
import { validateApplePay } from "../../../../../api";
import { handleSamsungDirectPayment } from "../../../../../actions/handleSamsungPayment";
import Loading from "../../../../ui/loading";
import ShareAlertModel from "../../../../LoaclComponents/shareHeader/share_model/ShareModelAlert";
import { toastifyMessage } from "../../../../../handlers/toastifyMessage";
import DonationFinalPopUp from "../../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import {
  addToFavorites,
  deleteFromFavorites,
} from "../../../../../actions/BagFav";
import { FETCH_LOCALLY } from "../../../../../constants/actionTypes";
import LoadingModal from "../../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
const CountriesDisplay = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const [modalShow, setModalShow] = useState(false);
  const vlnBranches = useSelector((state) => state.vlnBranches);

  const [workPlace, setWorkPlace] = useState(vlnBranches);
  useEffect(() => {
    setWorkPlace(vlnBranches);
  }, [vlnBranches]);
  const { id } = useParams();
  const [showCopyCard, setshowCopyCard] = useState(false);

  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const location = useLocation();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));

  const dispatch = useDispatch();
  const userBag = useSelector((state) => state.bag.bag);
  const [bagDetails, setBagDetails] = useState(userBag);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [view2, setView2] = useState(5);
  const [displayiedCountries, setDisplayedCountries] = useState([]);
  const [display, setDisplay] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [inTheBag, setInTheBag] = useState(0);
  const [change, setChange] = useState(0);
  const [shareUrl, setShareUrl] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedIdForShareLocal, setSelectedIdForShareLocal] = useState("all");
  const [addingToCart, setAddingToCart] = useState(false);
  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");
  const [liked, setLiked] = useState(false);
  const [projectData, setProjectData] = useState({
    projectId: "",
    projectName: "",
    projectImg: "",
    projectAmount: 0,
    countryName: "",
    countryId: "",
    countryImg: "",
    projectYear: new Date().getFullYear(),
    workPlaceID: "1",
  });
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);

  const [descr, setdescr] = useState({
    title: "",
    body: "",
  });
  const [amountRanges, setAmountRanges] = useState([]);
  const [selectedAmount, setSelectedAmounts] = useState("");
  const projectsPerCountry = useSelector((state) => state.projectsForCountries);
  const allProjectsAfterSelection = useSelector(
    (state) => state.projectsCountryDetails
  );
  const [allProjectsAfterSelectionState, setAllProjectsAfterSelectionState] =
    useState([]);

  const userFav = useSelector((state) => state.favorites.favorites);
  const [favoritesDetails, setFavoritesDetails] = useState(userFav);
  useEffect(() => {
    setFavoritesDetails(userFav);
  }, [userFav]);
  const [addingToFav, setAddingToFav] = useState(false);

  const [projectsLoading, setProjectLoading] = useState(false);
  const [amountRangesLoading, setAmountRangesLoading] = useState(false);
  const [projectTypeID, setProjectTypeID] = useState("");

  // useEffect(() => {
  //     setDisplay(projectsPerCountry)
  // }, [projectsPerCountry])
  // useEffect(() => {
  //     setAllProjectsAfterSelectionState(allProjectsAfterSelection)
  // }, [allProjectsAfterSelection])

  // useEffect(() => {
  //     setAmountRanges(allProjectsAfterSelection?.map(item => item.amount))
  //     let indexFromFavShare = allProjectsAfterSelection?.map(item => item.amount).indexOf(location.state?.selectedAmount)
  //         setSelectedAmounts(`${location.state?.selectedAmount}_${indexFromFavShare}`)
  //         props.setOpenButton(true)
  //         let projectSelected = allProjectsAfterSelectionState?.filter(item => item.projTypeID === location.state?.selectedProject && item.countryID === location.state?.selectedCountry && item.amount === location.state?.selectedAmount).slice(0, 1).shift()
  //         setProjectData({
  //             projectId: projectSelected?.projectID,
  //             projectName: projectSelected?.prjTypeName,
  //             projectImg: projectSelected?.projTypeImagePath,
  //             projectAmount: projectSelected?.amount,
  //             countryName: projectSelected?.countryName,
  //             countryId: projectSelected?.countryID,
  //             countryImg: projectSelected?.countryImagePath,
  //             projectYear: new Date().getFullYear(),
  //             workPlaceID: projectData.workPlaceID
  //         })
  //         setProjectTypeID(location.state?.selectedProject)
  // }, [allProjectsAfterSelection, allProjectsAfterSelectionState])

  useEffect(() => {
    // setCountryLoading(true)
    // if (selectedProject) {
    //     // dispatch(getCountriesForProjectID(lng === 'arab' ? 1 : 2, selectedProject, setCountryLoading, props.filterBody.order === 'alpha' ? 'name' : 'amount', props.filterBody.order === 'lowToHigh' ? 'ASC' : props.filterBody.order === 'HightToLow' ? 'DESC' : 'ASC', props.filterBody.max > 10 ? props.filterBody.max : '', props.filterBody.min ? props.filterBody.min : 1, props.filterBody.projectType? props.filterBody.projectType:''))
    // }
    setdescr({
      title: "",
      body: "",
    });
    let projectsByTheSameProjectType = props.allProjects?.slice(0);
    let projectWeNeed = projectsByTheSameProjectType.filter(
      (item) => parseInt(item.countryID) === parseInt(selectedCountry)
    );

    projectWeNeed = projectWeNeed.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.projTypeID === value.projTypeID)
    );
    setDisplay(projectWeNeed);
    // if (location.state?.selectedCountry) {
    //     setSelectedCountry(location.state?.selectedCountry)
    //     let CountriesWeNeed = displayiedCountries.filter(item => item.countryID === location.state?.selectedCountry).slice(0).shift();
    //     setProjectData({
    //         projectId: projectData?.projectId,
    //         projectName: projectData?.name,
    //         projectImg: projectData?.imagePath,
    //         projectAmount: projectData?.projectAmount,
    //         countryName: CountriesWeNeed?.name,
    //         countryId: CountriesWeNeed?.countryID,
    //         countryImg: CountriesWeNeed?.imagePath,
    //         projectYear: new Date().getFullYear(),
    //         workPlaceID: projectData.workPlaceID
    //     })
    // }
    setSelectedAmounts(0);
    props.setOpenButton(false);
  }, [selectedCountry]);
  // useEffect(() => {
  //     setProjectLoading(true)
  //     if (selectedCountry) {
  //         // dispatch(getProjectsForCountries(lng === 'arab' ? 1 : 2, selectedCountry, setProjectLoading, props.filterBody.order === 'alpha' ? 'name' : 'amount', props.filterBody.order === 'lowToHigh' ? 'ASC' : props.filterBody.order === 'HightToLow' ? 'DESC' : 'ASC', props.filterBody.max > 10 ? props.filterBody.max : '', props.filterBody.min ? props.filterBody.min : 1, props.filterBody.projectType? props.filterBody.projectType:''))
  //     }

  //     setdescr({
  //         title: '',
  //         body: ''
  //     })
  //     if (location.state?.selectedProject) {
  //         setSelectedProject(location.state?.selectedProject)
  //     }
  //     setSelectedAmounts(0)
  //     props.setOpenButton(false)
  // }, [selectedCountry])

  useEffect(() => {
    if (selectedCountry === "" || selectedProject === "") {
      setAmountRanges([]);
    } else {
      // dispatch(getAllProjectsPerCountries(lng === 'arab' ? 1 : 2, selectedProject, selectedCountry, setAmountRangesLoading, props.filterBody.order === 'alpha' ? 'name' : 'amount', props.filterBody.order === 'lowToHigh' ? 'ASC' : props.filterBody.order === 'HightToLow' ? 'DESC' : 'ASC', props.filterBody.max > 10 ? props.filterBody.max : '', props.filterBody.min? props.filterBody.min:1, props.filterBody.projectType? props.filterBody.projectType:''))
      let projectsByTheSameProjectType = props.allProjects?.slice(0);

      let CountriesWeNeed = projectsByTheSameProjectType.filter(
        (item) =>
          parseInt(item.projTypeID) === parseInt(selectedProject) &&
          selectedCountry === item.countryID
      );
      setAmountRanges(CountriesWeNeed?.map((item) => item.amount));
      setProjectData({
        projectId: projectData?.projectId,
        projectName: projectData?.name,
        projectImg: projectData?.imagePath,
        projectAmount: projectData?.projectAmount,
        countryName: CountriesWeNeed?.name,
        countryId: CountriesWeNeed?.countryID,
        countryImg: CountriesWeNeed?.imagePath,
        projectYear: new Date().getFullYear(),
        workPlaceID: projectData?.workPlaceID,
      });
      setdescr({
        title: "",
        body: "",
      });
      setSelectedAmounts(0);
      props.setOpenButton(false);
    }
  }, [selectedCountry, selectedProject]);

  useEffect(() => {
    // let countriesByTheSameProjectID = props.countries_proj_sec?.slice(0);
    // const operationalArray = countriesByTheSameProjectID?.slice(0)
    // const howMany12Projects = (countriesByTheSameProjectID?.length)
    // const paginationObj = [];
    // for (let i = 0; i < (howMany12Projects / 12); i++) {
    //     const arra1 = [];
    //     for (let j = i * 12; j < ((i + 1) * 12 < howMany12Projects ? (i + 1) * 12 : howMany12Projects); j++) {
    //         arra1.push(operationalArray[j])
    //     }
    //     paginationObj.push(arra1)
    // }
    let projectsByTheSameProjectType = props.allProjects?.slice(0);
    const operationalArray = projectsByTheSameProjectType?.slice(0);
    const paginationObj = [];
    let projectsIDS = props.allProjects.map((item) => item.countryID);
    let projectWeNeed = projectsByTheSameProjectType
      .filter((item) => item.projTypeID === id)
      .slice(0)
      .shift();

    let itemWeSelect = operationalArray
      .filter((item) => item.projTypeID === projectWeNeed?.projTypeID)
      .slice(0)
      .shift();

    let uniqueProjects = [];
    for (let i = 0; i < projectsByTheSameProjectType.length; i++) {
      projectsIDS.splice(
        projectsIDS.indexOf(projectsByTheSameProjectType[i].countryID),
        1
      );
      if (projectsIDS.indexOf(projectsByTheSameProjectType[i].countryID) < 0)
        uniqueProjects.push(projectsByTheSameProjectType[i]);
    }
    const arra1 = [];
    const howMany12Projects = uniqueProjects?.length;
    for (let i = 0; i < howMany12Projects / 12; i++) {
      const arra1 = [];
      for (
        let j = i * 12;
        j <
        ((i + 1) * 12 < howMany12Projects ? (i + 1) * 12 : howMany12Projects);
        j++
      ) {
        arra1.push(uniqueProjects[j]);
      }
      paginationObj.push(arra1);
    }
    if (id === "all") {
      let itemWeNeed = props.allProjects?.slice(0).shift();
      setSelectedCountry(itemWeNeed?.countryID);
      setProjectData({
        projectId: projectData.projectId,
        projectName: projectData.projectName,
        projectImg: projectData.projectPhoto,
        projectAmount: projectData.projectAmount,
        countryName: itemWeNeed?.countryName,
        countryId: itemWeNeed?.countryID,
        countryImg: itemWeNeed?.countryImagePath,
        projectYear: new Date().getFullYear(),
        workPlaceID: projectData.workPlaceID,
      });
      setDisplayedCountries(paginationObj);
    } else {
      let projectWeNeed = props.allProjects
        .filter((item) => item.projTypeID === id)
        .slice(0, 1)
        .shift();
      if (location.state?.selectedCountry) {
        setSelectedCountry(location.state?.selectedCountry);
      } else if (projectWeNeed == undefined) {
        let itemWeNeed = props.allProjects?.slice(0).shift();
        setSelectedCountry(itemWeNeed?.countryID);
        setProjectData({
          projectId: projectData.projectId,
          projectName: projectData.projectName,
          projectImg: projectData.projectPhoto,
          projectAmount: projectData.projectAmount,
          countryName: itemWeNeed?.countryName,
          countryId: itemWeNeed?.countryID,
          countryImg: itemWeNeed?.countryImagePath,
          projectYear: new Date().getFullYear(),
          workPlaceID: projectData.workPlaceID,
        });
      } else {
        setSelectedCountry(projectWeNeed?.countryID);
        setProjectData({
          projectId: projectData.projectId,
          projectName: projectData.projectName,
          projectImg: projectData.projectPhoto,
          projectAmount: projectData.projectAmount,
          countryName: projectWeNeed?.countryName,
          countryId: projectWeNeed?.countryID,
          countryImg: projectWeNeed?.countryImagePath,
          projectYear: new Date().getFullYear(),
          workPlaceID: projectData.workPlaceID,
        });
      }

      setDisplayedCountries(paginationObj);
    }

    // setMaximumAmount(props.max)
    // setMinimumAmount(props.min)
  }, [
    props.allProjects,
    id,
    props.max,
    props.min,
    props.fullProjectsState,
    props.countries_proj_sec,
  ]);

  useEffect(() => {
    if (!userLogin) {
      if (
        currentProfile?.liked?.projects.filter(
          (item) =>
            item.projectId === projectData.projectId &&
            item.countryId === projectData.countryId &&
            item.projectAmount === projectData.projectAmount &&
            item.projectYear === projectData.projectYear
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    } else {
      if (
        favoritesDetails?.projects?.filter(
          (item) =>
            item.projectID === projectData.projectId &&
            parseInt(item.projectYear) === projectData.projectYear
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
    if (!userLogin) {
      if (
        currentProfile?.cart?.projects?.filter(
          (item) =>
            item.projectId === projectData.projectId &&
            item.countryId === projectData.countryId &&
            item.projectAmount === projectData.projectAmount &&
            item.projectYear === projectData.projectYear
        ).length > 0
      ) {
        setInTheBag(
          currentProfile?.cart?.projects?.filter(
            (item) =>
              item.projectId === projectData.projectId &&
              item.countryId === projectData.countryId &&
              item.projectAmount === projectData.projectAmount &&
              item.projectYear === projectData.projectYear
          ).length
        );
      }
    } else {
      if (
        bagDetails?.projects
          ?.filter(
            (item) =>
              item.projectID === projectData.projectId &&
              parseInt(item.projectYear) === projectData.projectYear
          )
          .slice(0)
          .shift()?.quantity
      ) {
        setInTheBag(
          bagDetails?.projects
            ?.filter(
              (item) =>
                item.projectID === projectData.projectId &&
                parseInt(item.projectYear) === projectData.projectYear
            )
            .slice(0)
            .shift()?.quantity
        );
      }
    }
  }, [
    currentProfile,
    selectedCountry,
    selectedProject,
    bagDetails,
    projectData,
    selectedAmount,
    favoritesDetails,
  ]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView2(15);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView2(13);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView2(8);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView2(6);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView2(5);
    } else if (window.innerWidth > 700 && window.innerWidth <= 1111) {
      setView2(4.2);
    } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
      setView2(4.3);
    } else if (window.innerWidth > 350 && window.innerWidth <= 600) {
      setView2(3.2);
    } else {
      setView2(1.5);
    }
  }, [width]);

  // useEffect(() => {
  //     if (selectedCountry === '' || selectedProject === '') {
  //         setAmountRanges([])
  //     } else {
  //         setAmountRangesLoading(true)
  //         // dispatch(getAllProjectsPerCountries(lng === 'arab' ? 1 : 2, selectedProject, selectedCountry, setAmountRangesLoading, props.filterBody.order === 'alpha' ? 'name' : 'amount', props.filterBody.order === 'lowToHigh' ? 'ASC' : props.filterBody.order === 'HightToLow' ? 'DESC' : 'ASC', props.filterBody.max > 10 ? props.filterBody.max : '', props.filterBody.min? props.filterBody.min:1, props.filterBody.projectType? props.filterBody.projectType:''))
  //         let projectWeNeed = display.filter(item => item.projTypeID === selectedProject).slice(0).shift();
  //         setProjectData({
  //             projectId: projectData.projectId,
  //             projectName: projectWeNeed?.name,
  //             projectImg: projectWeNeed?.imagePath,
  //             projectAmount: projectData.projectAmount,
  //             countryName: projectData.name,
  //             countryId: projectData.countryID,
  //             countryImg: projectData.imagePath,
  //             projectYear: new Date().getFullYear(),
  //             workPlaceID: projectData.workPlaceID
  //         })
  //         setdescr({
  //             title: '',
  //             body: ''
  //         })
  //         setSelectedAmounts(0)
  //         props.setOpenButton(false)
  //     }
  // }, [selectedCountry, selectedProject, props.maximumSelected])

  const handleChoosenSalary = (str) => {
    setProjectData({ ...projectData, projectAmount: str.amount });

    setSelectedAmounts(`${str.amount}_${str.index}`);
    let projectSelected = props.allProjects
      .filter(
        (item) =>
          item.projTypeID === selectedProject &&
          item.countryID === selectedCountry &&
          item.amount === str.amount
      )
      .slice(0, 1)
      .shift();
    setProjectData({
      projectId: projectSelected?.projectID,
      projectName: projectSelected?.prjTypeName,
      projectImg: projectSelected?.projTypeImagePath,
      projectAmount: projectSelected?.amount,
      countryName: projectSelected?.countryName,
      countryId: projectSelected.countryID,
      countryImg: projectSelected.countryImagePath,
      projectYear: new Date().getFullYear(),
      workPlaceID: projectData.workPlaceID,
    });
    props.setSelectedIDForShare({
      projectId: projectSelected?.projectID,
      projectName: projectSelected?.prjTypeName,
      projectImg: projectSelected?.projTypeImagePath,
      projectAmount: projectSelected?.amount,
      countryName: projectSelected?.countryName,
      countryId: projectSelected.countryID,
      countryImg: projectSelected.countryImagePath,
      projectYear: new Date().getFullYear(),

      workPlaceID: projectData.workPlaceID,
    });
    setdescr({
      title: projectSelected.shortDesc,
      body: projectSelected.longDesc,
    });
    props.setDisableUntill({
      status: false,
      dataObject: {
        projectId: projectSelected?.projectID,
        projectName: projectSelected?.prjTypeName,
        projectImg: projectSelected?.projTypeImagePath,
        projectAmount: projectSelected?.amount,
        countryName: projectSelected?.countryName,
        countryId: projectSelected.countryID,
        countryImg: projectSelected.countryImagePath,
        projectYear: new Date().getFullYear(),
        projectTypeID: projectSelected?.projTypeID,
        workPlaceID: projectData.workPlaceID,
      },
    });
    props.setOpenButton(true);
  };

  const handleLike = async (donationID) => {
    setAddingToFav(true);
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile.liked.projects.push(donationID);
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
      setAddingToFav(false);
    } else {
      await dispatch(
        addToFavorites(lng === "arab" ? 1 : 2, {
          userEmail: userLogin.Results[0]?.email,
          dontypeID: "",
          projectID: donationID.projectId,
          orptypeID: "",
          projectYear: donationID.projectYear,
          countryID: "",
        })
      );
      if (_isMounted.current) {
        setAddingToFav(false);
      }
    }
  };
  const handleDisLike = async (donationID) => {
    setAddingToFav(true);
    if (!userLogin) {
      let newProfile = {
        cart: currentProfile.cart,
        liked: {
          donations: currentProfile.liked.donations,
          sukuks: currentProfile.liked.sukuks,
          projects: currentProfile.liked.projects.filter(
            (item) =>
              !(
                item.projectId === donationID.projectId &&
                item.countryId === donationID.countryId &&
                item.projectAmount === donationID.projectAmount
              )
          ),
          orphans: currentProfile.liked.orphans,
        },
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      setChange(change + 1);
      if (_isMounted.current) {
        setAddingToFav(false);
      }
    } else {
      let elementWeNeed = favoritesDetails?.projects
        ?.filter(
          (item) =>
            item.projectID === projectData.projectId &&
            parseInt(item.projectYear) === projectData.projectYear
        )
        .slice(0, 1)
        .shift();
      if (elementWeNeed) {
        await dispatch(
          deleteFromFavorites(
            lng === "arab" ? 1 : 2,
            userLogin.Results[0]?.email,
            elementWeNeed.cart_serial
          )
        );
      }
      if (_isMounted.current) {
        setAddingToFav(false);
      }
    }
  };
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: projectData.projectAmount,
      url: window.location.href,
      item: [
        {
          template_id: projectData.projectId,
          projectYear: projectData.projectYear,
          country_id: projectData.countryId,
          amount: projectData.projectAmount,
          item_type: 2,
          dontype_id: 8,
          workPlaceID: projectData.workPlaceID,
        },
      ],
    });
    setLoadingModalShow(false);
  };

  const handleaddToBag = async (donationID) => {
    setAddingToCart(true);
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile.cart?.projects.push({
        ...donationID,
        dateOfAdding: new Date().getTime(),
        baseAmount: donationID.projectAmount,
      });
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      toastifyMessage("Added!", undefined, "success");
      setChange(change + 1);
    } else {
      await dispatch(
        addToBag({
          cart: {
            donID: "8",
            userName: userLogin.Results[0]?.name_a,
            userMob: userLogin.Results[0]?.mobile_no,
            userEmail: userLogin.Results[0]?.email,
            itemType: "2",
            amount: donationID.projectAmount,
            cart_serial: null,
            workPlaceID: projectData.workPlaceID,
            prj_name: donationID.projectName,
            projectYear: new Date().getFullYear(),
            template_id: donationID.projectId,
            country_id: donationID.countryId,
          },
          language: lng === "arab" ? 1 : 2,
        })
      );
    }
    if (_isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut");
      setTimeout(() => {
        if (_isMounted.current) {
          setAnimationClass("btn_with_text animate__animated animate__bounceIn");
          setAddingToCart(false);
          setInTheBagText("Added");
        }
    }, 1000);
    setTimeout(() => {
      if (_isMounted.current) {
        setInTheBagText("Add to Bag");
        setAnimationClass("btn_with_text");
      }
      

      //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
    }, 2500);
    }
    
  };
  const handleInBag = (str) => {
    handleaddToBag(str);
  };
  const handleLikeDisLike = (str) => {
    if (liked) {
      handleDisLike(str);
    } else {
      handleLike(str);
    }
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    let textOfPageArr = window.location.href.split("/");
    textOfPageArr.splice(textOfPageArr.length - 1, 1, selectedIdForShareLocal);
    setShareUrl(textOfPageArr.join("/"));
  }, [selectedIdForShareLocal]);

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };
  return (
    <div
      id="projects_page_display"
      style={lng === "arab" ? { direction: "rtl" } : null}
    >
      <div style={{ width: "100%" }}>
        <Carousel id="projects_carousel" style={{ width: "100%" }}>
          {displayiedCountries?.map((item, index) => (
            <Carousel.Item key={index} interval={50000}>
              <div
                className="projects_display_for_prjects_page"
                style={{ minHeight: "400px", gap: "20px" }}
              >
                {item?.map((innerItem, i) => (
                  <CountryProComp
                    projectData={projectData}
                    setProjectData={setProjectData}
                    selected={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    key={i}
                    ImagePath={innerItem?.countryImagePath}
                    Name_En={
                      innerItem.countryName?.length > 9
                        ? innerItem.countryName?.slice(0, 10) + "."
                        : innerItem.countryName
                    }
                    CountryId={innerItem?.countryID}
                  />
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="country_sliders">
        <h2 className="donation_favorites_title title_to_be_modify">
          {t("Choose a Project")}
        </h2>
        <div
          id="donation_display_mobile"
          style={{ width: "100%", padding: "20px" }}
        >
          {projectsLoading ? (
            <div className="Spinner">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loadin g...</span>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <Swiper
                loop={display?.length > view2 ? true : false}
                slidesPerView={view2}
                spaceBetween={10}
                navigation={true}
              >
                {display?.map((item, index) => (
                  <SwiperSlide className="orphan_sliders_flags" key={index}>
                    <ProjectDisplayComp
                      setProjectTypeID={setProjectTypeID}
                      projectData={projectData}
                      setProjectData={setProjectData}
                      style={{ order: index + 1 }}
                      selected={selectedProject}
                      setSelectedIdForShareLocal={setSelectedIdForShareLocal}
                      setSelectedProject={setSelectedProject}
                      setSelectedIdForShare={props.setSelectedIDForShare}
                      projectId={item?.projTypeID}
                      projectTypeId={item.projTypeID}
                      projectPhoto={item?.projTypeImagePath}
                      title={item?.prjTypeName}
                      key={item?.projectID}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </React.Fragment>
          )}
        </div>
      </div>
      {selectedProject ? (
        <div
          className="country_sliders second"
          style={{
            minHeight: "136px",
            padding:
              width > 900 ? "10px 40px 10px 40px" : "20px 10px 20px 10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h2 className="donation_favorites_title title_to_be_modify">
            {t("Choose a branch")}
          </h2>
          <select
            style={{
              // marginLeft: "45px",
              backgroundPositionX: lng === "arab" ? "0px" : "100%",
              width: width <= 900 ? "90%" : null,
            }}
            name="workPlaceID"
            value={projectData.workPlaceID}
            onChange={(e) =>
              setProjectData({ ...projectData, workPlaceID: e.target.value })
            }
            className="select-issue select-form"
          >
            {workPlace?.map((item, i) => (
              <option key={i} value={item.workplace_id}>
                {item.workplace_name}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div className="salary_choosen_projects">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {amountRanges.map((item, i) => (
            <button
              key={i}
              disabled={addingToCart}
              style={
                selectedAmount === `${item}_${i}`
                  ? {
                      transform: "scale(1.03)",
                      color: "#ffffff",
                      backgroundColor: "#ee4236",
                      boxShadow: "0px 0px 5px 2px #000000",
                    }
                  : null
              }
              id={`btn_of_${i}_of_${selectedCountry}`}
              onClick={() => handleChoosenSalary({ amount: item, index: i })}
              className="salary_choosen_project_btn payment_numbers"
            >
              {t("AED")} {numberWithCommas(item)}
            </button>
          ))}
        </div>
      </div>
      <div className="project_desc">
        <label className="project_desc_title">{descr.title}</label>
        <p
          style={
            lng === "arab" ? { textAlign: "right" } : { textAlign: "left" }
          }
          className="project_desc_content"
        >
          {descr.body}
        </p>
      </div>
      <div
        className="check_out_payment_method_btns"
        style={{ width: "100%", marginBottom: "45px" }}
      >
        <div className="btns_wraper_all">
          <div className="btn_with_text shareBtnDesk" style={{ width: "auto" }}>
            <button
              style={
                !props.openButtons
                  ? { backgroundColor: "white", filter: "grayscale(100%)" }
                  : { backgroundColor: "white" }
              }
              disabled={!props.openButtons}
              onClick={() => setshowCopyCard(true)}
              className="human_donate_ways btn1 shadowForBtn"
            >
              <img
                src={share}
                alt="donate_btn_icon_banner"
                className="donate_btn_icon_ind"
              />
            </button>
            <label className="label_to_beSmall">{t("Share")}</label>
          </div>
          <div className="btn_with_text " style={{ width: "unset" }}>
            {addingToFav ? (
              <button
                disabled={addingToFav}
                className=" btn4"
                style={{
                  minHeight: width >= 1150 ? "110px" : "100px",
                  minWidth: width >= 1150 ? "110px" : "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="containerAnime" style={{ height: "auto" }}>
                  <div
                    style={{ width: "10px", height: "10px" }}
                    className="ballFav"
                  ></div>
                  <div
                    style={{ width: "10px", height: "10px" }}
                    className="ballFav"
                  ></div>
                </div>
              </button>
            ) : (
              <button
                disabled={addingToCart}
                onClick={() => handleLikeDisLike(projectData)}
                className="human_donate_ways btn1 shadowForBtn"
                disabled={!props.openButtons}
                style={
                  !props.openButtons ? { filter: "grayscale(100%)" } : null
                }
              >
                <img
                  src={liked ? likedIcon : likeIcon}
                  alt="donate_btn_icon_banner"
                  className="donate_btn_icon_ind"
                />
              </button>
            )}
            <label className="label_to_beSmall" style={{ textAlign: "center" }}>
              {liked ? t("Remove from favorites") : t("Add to favorites")}
            </label>
          </div>
          {inTheBagText === "Added" ? (
            <div
              className={"btn_with_text animate__animated animate__bounceIn"}
              style={{ width: "unset" }}
            >
              <button
                disabled={addingToCart}
                className="human_donate_ways btn1 "
                style={{ backgroundColor: "transparent" }}
              >
                <label
                  style={{
                    fontSize: width <= 900 ? "7px" : "12px",
                    color: "#ee4236",
                  }}
                  className="label_to_beSmall"
                >
                  {t("You now have ")}{" "}
                  <span className="payment_numbers">{inTheBag}</span>{" "}
                  {t(" in your Bag")}
                </label>
              </button>
            </div>
          ) : (
            <React.Fragment>
              <div className={animationClass} style={{ width: "unset" }}>
                {addingToCart ? (
                  <Loading
                    height={"110px"}
                    mediaQ={"100px"}
                    small={"40px"}
                    animate={true}
                  />
                ) : (
                  <React.Fragment>
                    <button
                      disabled={addingToCart}
                      onClick={() => handleInBag(projectData)}
                      className="human_donate_ways btn1 shadowForBtn"
                      disabled={!props.openButtons}
                      style={
                        !props.openButtons
                          ? { filter: "grayscale(100%)" }
                          : null
                      }
                    >
                      <img
                        src={donateIcon}
                        alt="donate_btn_icon_banner"
                        className="donate_btn_icon_ind"
                      />
                    </button>
                    <label
                      className="label_to_beSmall"
                      style={{ textAlign: "center" }}
                    >
                      {t(inTheBagText)}
                    </label>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          <div className="btn_with_text " style={{ width: "unset" }}>
            <button
              disabled={addingToCart}
              onClick={() =>
                history.push(`/checkout`, {
                  from: "projects",
                  elements: [
                    {
                      title:
                        projectData.projectName +
                        " " +
                        t("at") +
                        " " +
                        projectData.countryName,
                      price: projectData.projectAmount,
                    },
                  ],
                  totalAmount: projectData.projectAmount,
                  details: projectData,
                })
              }
              className="human_donate_ways btn3 shadowForBtn"
              style={!props.openButtons ? { filter: "grayscale(100%)" } : null}
              disabled={!props.openButtons}
            >
              <img
                src={cash}
                alt="donate_btn_icon_banner"
                className="donate_btn_icon_ind"
              />
            </button>
            <label className="label_to_beSmall">{t("Donate Now")}</label>
          </div>
        </div>
        {window.ApplePaySession ? (
          <button
            style={
              !props.openButtons
                ? {
                    filter: "grayscale(100%)",
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: "0.4",
                    cursor: "unset",
                  }
                : {
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
            disabled={!props.openButtons}
            id="applepay_btn"
            className="apple-pay-button-outside apple-pay-button-black bottom_btn apple_human_btn samApple_btn"
            locale="ar-AB"
            onClick={() =>
              handleGuestPopUp(() =>
                startApplePay(
                  {
                    amount: projectData.projectAmount,
                    cart: [
                      {
                        dontype_id: "8",
                        item_type: 2,
                        amount: projectData.projectAmount,
                        projectYear: projectData.projectYear,
                        country_id: projectData.countryId,
                        template_id: projectData.projectId,
                        cart_serial: null,
                        workPlaceID: projectData.workPlaceID,
                      },
                    ],
                  },
                  dispatch,
                  history
                )
              )
            }
          ></button>
        ) : (
          <button
            disabled={addingToCart}
            style={
              !props.openButtons
                ? {
                    filter: "grayscale(100%)",
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                : {
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
            onClick={() => handleGuestPopUp(startSamsungPay)}
            className="btn btn-block btn-dark apple_human_btn width_for_orphans bottom_btn samApple_btn"
            disabled={!props.openButtons}
          >
            <img
              src={samPay}
              style={{ height: "60px", width: width <= 300 ? "100%" : "auto" }}
              alt="samsung_pay"
              className='samApple_btn_img'
            />
          </button>
        )}
      </div>
      <ShareAlertModel
        path="projects"
        shareData={{ ...projectData, projectTypeID: projectTypeID }}
        show={showCopyCard}
        onHide={() => {
          setshowCopyCard(false);
        }}
      ></ShareAlertModel>
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};

export default CountriesDisplay;
