import React, { useState, useEffect } from 'react';
import './CategoriesPage.css';
import donationPage from '../../assets/donationsPage_banner.jpg';
import projectsPage from '../../assets/projects.jpg';
import orphanPage from '../../assets/orphans.jpg';
import sukukPage from '../../assets/sukukForCategories.jpg';
import HumanPage from '../../assets/medical.jpg';
import { useTranslation } from 'react-i18next';
import LandingComp from './LandingComp';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { useDispatch, useSelector } from 'react-redux';
import LoadingModal from '../../components/Auth/loginSignPage/loadingPopUp/loadingPopUp';

const CategoriesPage = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const landingPages = useSelector((state) => state.landingPages.landingPages);
  const [landingPagesDisplay, setLandingPagesDisplay] = useState([]);
  const loading = useSelector((state) => state.landingPages.loading);

  useEffect(() => {
    setLandingPagesDisplay(landingPages);
  }, [landingPages]);

  useEffect(() => {
    console.log(landingPagesDisplay);
  }, [landingPagesDisplay]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      {/* <ShareHeader noShare={true} pageName={t("Landing Pages")} noBack={true}/> */}
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        // textToBeCopied={shareUrl}
        pageName={t('Landing Pages')}
        path={'beasponser'}
      />
      {loading ? (
        <LoadingModal
          show={loading}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      ) : (
        <div id="categories_page">
          {landingPagesDisplay.map((item) => (
            <LandingComp
              key={item.rec_serial}
              navigateTo={item.Url}
              categoryImg={item.pic_path}
              categoryTitle={item.page_name}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesPage;
