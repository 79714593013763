import { FETCH_ALL_HUMANCASES, HUMANCASES_LOADED, LOAD_HUMANCASES } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getHumanCases = (langNumber) => async (dispatch) => {
    dispatch({ type: LOAD_HUMANCASES });
    try {
        const { data } = await api.getHumanCases(langNumber);
        
        data.forEach(element => {
            element.totalCost = Math.ceil(element.totalCost)
            element.amountCollected = Math.floor(element.amountCollected)
        });
        let complete = [];
        let normal = [];
        for (let i=0; i<data.length;i++) {
            if ((data[i].amountCollected/data[i].totalCost)*100 >= 100) {
                complete.push(data[i])
            } else {
                normal.push(data[i])
            }
        }
         normal.push(...complete)
        dispatch({ type: FETCH_ALL_HUMANCASES, payload: normal })
        dispatch({ type: HUMANCASES_LOADED });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: HUMANCASES_LOADED });
    }
}