import React, { Fragment, useState, useEffect } from "react";
import "./LocalAid.css";
import Profile from "../../../assets/profile 2.png";
import relatives from "../../../assets/relative.png";
import requests from "../../../assets/requests.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import profile_img from "../../../assets/media/profile.png";
import relative_img from "../../../assets/media/relative.png";
import request_img from "../../../assets/media/request.png";
import see_arrow from "../../../assets/media/arrow.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getLocalAIDProfile,
  getPendingRequ,
  getRelatives,
} from "../../../actions/localAID";

const LocalAid = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();
  const handleGoToMakeRequest = () => {
    history.push("/localAid/submit-request");
  };

  useEffect(() => {
    if (userLogin?.Results[0]?.person_id_flag === "2") {
      if (lng === "arab") {
        dispatch(getLocalAIDProfile("AR", userLogin?.Results[0]?.person_id));
      } else {
        dispatch(getLocalAIDProfile("EN", userLogin?.Results[0]?.person_id));
      }
    }

    if (userLogin?.Results[0]?.person_id !== "") {
      if (lng === "arab") {
        dispatch(getRelatives("AR", userLogin?.Results[0]?.person_id));
      } else {
        dispatch(getRelatives("EN", userLogin?.Results[0]?.person_id));
      }
    }

    if (userLogin?.Results[0]?.person_id !== "") {
      if (lng === "arab") {
        dispatch(getPendingRequ("AR", userLogin?.Results[0]?.person_id));
      } else {
        dispatch(getPendingRequ("EN", userLogin?.Results[0]?.person_id));
      }
    }
  }, [lng]);

  //profile
  const laRProfile = useSelector((state) => state.laProfile.laRProfile);
  const [profileDetails, setProfileDetails] = useState(
    laRProfile?.Local_Aids_Info?.slice(0).shift()
  );

  useEffect(() => {
    setProfileDetails(laRProfile?.Local_Aids_Info?.slice(0).shift());
  }, [laRProfile]);

  //relative
  const laRelatives = useSelector((state) => state.laRelatives.laRelatives);
  const [relatives, setRelatives] = useState(
    laRelatives?.Local_Aids_Info?.slice(0)
  );
  useEffect(() => {
    setRelatives(laRelatives?.Local_Aids_Info?.slice(0));
  }, [laRelatives]);

  //pending
  const laPending = useSelector((state) => state.laPending);

  const [pendingRequ, setPendingRequ] = useState(
    laPending?.UserLocalAidsRequestsDetails
  );
  useEffect(() => {
    setPendingRequ(laPending?.UserLocalAidsRequestsDetails);
  }, [laPending]);

  if (userLogin?.Results[0]?.person_id_flag === "2") {
    return (
      <Fragment>
        {/* <div
          className="localAid_page_wraper"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          <ShareHeader pageName={t("Local Aid")} noShare={true} />
          <div className="local_aid_page">
            <button
              onClick={() => history.push("/localAid/localAidProfile")}
              className="local_aid_btn"
            >
              <img
                src={Profile}
                alt="profile_btn"
                className="local_aid_btn_img"
              />
              <span className="local_aid_btn_wraper">
                <span style={{ flex: "9" }}>{t("Profile")}</span>
                {lng === "arab" ? (
                  <i style={{ flex: "1" }} className="fas fa-arrow-left"></i>
                ) : (
                  <i style={{ flex: "1" }} className="fas fa-arrow-right"></i>
                )}
              </span>
            </button>
            <button
              onClick={() => history.push("/localAid/relatives")}
              className="local_aid_btn"
            >
              <img
                src={relatives}
                alt="relatives_btn"
                className="local_aid_btn_img"
              />
              <span className="local_aid_btn_wraper">
                <span style={{ flex: "9" }}>{t("Relatives")}</span>
                {lng === "arab" ? (
                  <i style={{ flex: "1" }} className="fas fa-arrow-left"></i>
                ) : (
                  <i style={{ flex: "1" }} className="fas fa-arrow-right"></i>
                )}
              </span>
            </button>
            <button
              onClick={() => history.push("/localAid/requests")}
              className="local_aid_btn"
            >
              <img
                src={requests}
                alt="profile_btn"
                className="local_aid_btn_img"
              />
              <span className="local_aid_btn_wraper">
                <span style={{ flex: "9" }}>{t("Requests")}</span>
                {lng === "arab" ? (
                  <i style={{ flex: "1" }} className="fas fa-arrow-left"></i>
                ) : (
                  <i style={{ flex: "1" }} className="fas fa-arrow-right"></i>
                )}
              </span>
            </button>
          </div>
          <button
            onClick={handleGoToMakeRequest}
            style={{ alignSelf: "center" }}
            type="button"
            className="local_aid_submit"
          >
            <span style={{ flex: "25" }}>{t("Submit a request")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </div> */}
        <div className="container">
          <div className="row local_aid_container">
            <div className="col-md-5 col-lg-3 local_aid_profile_container">
              <div className="image_section">
                <img src={profile_img} alt="profile" />
                <p>{t("PROFILE")}</p>
              </div>
              <label>{t("Profile No")}</label>
              <input
                type="text"
                defaultValue={profileDetails?.PERSON_ID}
                required
                className="form-control"
              />
              <label>{t("National ID")}</label>
              <input
                type="number"
                defaultValue={profileDetails?.NATIONAL_ID}
                required
                className="form-control"
              />
              <label>{t("Mobile No")}</label>
              <input
                type="tel"
                defaultValue={profileDetails?.PRM_MOBILE_NO}
                required
                className="form-control"
              />

              <div
                className="see_all_btn"
                onClick={() => history.push("/localAid/localAidProfile")}
              >
                <p>{t("See All")}</p>
                <img src={see_arrow} alt="arrow image" />
              </div>
            </div>
            <div className="col-md-5 col-lg-3  local_aid_relative_container">
              <div className="image_section">
                <img src={relative_img} alt="profile" />
                <p>{t("RELATIVES")}</p>
              </div>
              <div className="table_contai_main">
                <span>{t("Name")}</span>
                <span>{t("Relation")}</span>
                <span>{t("Health Status")}</span>
              </div>
              {relatives && relatives.length > 0 ? (
                relatives.slice(0, 4).map((items, index) => (
                  <div className="table_contai" key={index}>
                    <span>{items?.FAM_NAME_A}</span>
                    <span>{items?.RELATION_NAME_A}</span>
                    <span>{items?.HEALTH_STAT_NAME_A}</span>
                  </div>
                ))
              ) : (
                <div style={{ paddingBottom: "40px", paddingTop: "40px" }}>
                  {t("No relatives found")}
                </div>
              )}

              <div
                className="see_all_btn"
                onClick={() => history.push("/localAid/relatives")}
              >
                <p>{t("See All")}</p>
                <img src={see_arrow} alt="arrow image" />
              </div>
            </div>
            <div className="col-md-11 col-lg-5 local_aid_request_container">
              <div className="image_section">
                <img src={request_img} alt="profile" />
                <p>{t("REQUEST")}</p>
              </div>

              <div className="table_contai_main">
                <span>{t("Name")}</span>
                <span>{t("Request No")}</span>
                <span>{t("Year")}</span>
                <span>{t("Status")}</span>
                {/* <span>Request Classification</span> */}
              </div>

              {pendingRequ && pendingRequ.length > 0 ? (
                pendingRequ.map((itm, i) => (
                  <div className="table_contai" key={i}>
                    <span>{itm?.AIDTYPE_NAME_A}</span>
                    <span>{itm?.REQ_ID}</span>
                    <span>{itm?.REQ_YEAR}</span>
                    <span>{itm?.REQ_YEAR}</span>
                    {/* <span>Name</span> */}
                  </div>
                ))
              ) : (
                <div style={{ paddingBottom: "40px", paddingTop: "40px" }}>
                  {t("No pending requests")}
                </div>
              )}

              <div className="req_btn">
                <button
                  onClick={handleGoToMakeRequest}
                  style={{ alignSelf: "center" }}
                  type="button"
                  className="local_aid_submit"
                >
                  <span style={{ flex: "25" }}>{t("Submit a request")}</span>
                  {lng === "arab" ? (
                    <i className="fas fa-arrow-left"></i>
                  ) : (
                    <i className="fas fa-arrow-right"></i>
                  )}
                </button>
                <div
                  className="see_all_btn"
                  onClick={() => history.push("/localAid/requests")}
                >
                  <p>{t("See All")}</p>
                  <img src={see_arrow} alt="arrow image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    window.location.replace("/profile");
  }
};

export default LocalAid;
