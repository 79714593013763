import React, {useState, useEffect} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation} from 'swiper'
import 'swiper/swiper-bundle.css'
import DonationCom from '../../homePage/sections/donations/donationCom/donationCom'
import '../FavoritesPage.css'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { addToFavorites, deleteFromFavorites } from '../../../actions/BagFav'
import { FETCH_LOCALLY } from '../../../constants/actionTypes'
const DonationSection = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()

    const [change, setChange] = useState(0)
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    const [view, setView] = useState(5)

    const [donationFav, setDonationFav] = useState([])
    useEffect(() => {
        if (userLogin) {
            let donationWithoutSukuk = props.favoritesDetails?.donations?.filter(outerItem=> props.favoritesDetails?.sukuks?.filter(item=> item.dontypeID===outerItem.dontypeID).length===0)
            setDonationFav(donationWithoutSukuk)
        } else {
            setDonationFav(currentProfile?.liked?.donations)
        }
      }, [props.favoritesDetails?.donations, fetchLoaclly]);

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(()=> {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(15)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(11)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(8)  
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(6)
        } else if (window.innerWidth > 1275 && window.innerWidth <= 1600) {
            setView(5.8)
        } 
        else if (window.innerWidth > 1000 && window.innerWidth <= 1300) {
            setView(5.2)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(5)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(5) 
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(4)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(2.2)
        } else if (window.innerWidth > 330 && window.innerWidth <= 500) {
            setView(1.8)
        } else if (window.innerWidth > 190 && window.innerWidth <= 330) {
            setView(1.2)
        } else {
            setView(1)
        }
      }, [width])
      const handleLike = async (donationID) => {
        if (!userLogin) {
        if (!currentProfile) {
            localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
            currentProfile = JSON.parse(localStorage.getItem('profile'));
        }
        dispatch({ type: 'addLikeLocally', payload: donationID })
        currentProfile.liked.donations.push(donationID)
        const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: donationID.donationId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
        }

    }
    const handleDisLike = async (donationID) => {
        if (!userLogin) {
        let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.liked.sukuks, projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans } }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1)
        } else {
            let elementWeNeed = donationFav?.filter(item => item.dontypeID === donationID.donationId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }

    }

    // const handleaddToBag = async (donationID) => {
    //     if (!userLogin) {
    //         if (!currentProfile) {
    //             localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
    //             currentProfile = JSON.parse(localStorage.getItem('profile'));
    //         }
    //         currentProfile.cart?.donations.push({ ...donationID, baseAmount: donationID.amount })
    //         const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
    //         localStorage.setItem('profile', JSON.stringify(newProfile))
    //         setChange(change + 1)
    //     } else {
    //         await dispatch(addToBag({
    //             cart: {
    //                 donID: donationID.donationId, userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
    //                 userEmail: userLogin.Results[0]?.email, itemType: 0, amount: donationID.amount, recuring: donationID.recuring, cart_serial: null
    //             }, language: lng === 'arab' ? 1 : 2
    //         }))
    //     }
    // }
    // const handleRemoveFromBag = async (donationID) => {
    //     if (!userLogin) {
    //         let newProfile = { cart: { donations: currentProfile.cart.donations.filter(item => !(item.donationId === donationID.donationId && parseInt(item.baseAmount) === parseInt(donationID.amount) && item.donationFreq === donationID.donationFreq)), sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
    //         localStorage.setItem('profile', JSON.stringify(newProfile))
    //         setChange(change + 1)
    //     } else {
    //         let elementWeNeed = bagDetails?.donations?.filter(item => item.dontypeID === donationID.donationId && parseInt(item.baseAmount) === parseInt(donationID.amount) && item.recurring === donationID.donationFreq).slice(0, 1).shift()
    //         if (elementWeNeed) {
    //             await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1, ""))
    //         }
    //     }
    // }
    return (
        <div className='liked_donation_section'>
                <h4 className='donation_favorites_title'>{t("Donations")}</h4>
                <hr className='hr'></hr>
                <div id='donation_display_mobile' style={{width:'100%'}}>
                {donationFav?.length > 0 ? 
                    <React.Fragment>
                        <Swiper loop={donationFav?.length > view? true:false} slidesPerView={view} navigation={true}>
                            {donationFav?.map((item,i)=> 
                                <SwiperSlide key={i} >
                                    <DonationCom 
                                        // handleaddToBag={handleaddToBag} 
                                        // handleRemoveFromBag={handleRemoveFromBag} 
                                        handleLike={handleLike} 
                                        handleDisLike={handleDisLike} 
                                        currentProfile={currentProfile} 
                                        forFavorites={true} 
                                        altImg={userLogin? item.name:item.title} 
                                        amount={userLogin? item.baseAmount:item.amount} 
                                        donationID={userLogin? item.dontypeID:item.donationId} 
                                        DonImg={userLogin? item.imagePath:item.img} 
                                        title={userLogin? item.name:item.title}
                                        fromFav={true}
                                        edit_flag = {item.edit_flag}
                                        />
                                </SwiperSlide>
                                )}
                            
                        </Swiper>
                    </React.Fragment>
                    :
                    <div style={{textAlign:'center',marginTop:'50px', minHeight: '100px'}}>
                        <h5>
                        {t("No Favorites Found")}
                        </h5>
                    </div> 
                    }
                </div>
            </div>
    )
}

export default DonationSection
