import { t } from "i18next";
import React, {useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import PaymentMethod from "../../../userPage/add_payment_method/AddPaymentMethod";
import "./OrphanPaymentModal.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { setOrphansPaymentCard, tokenizeCard, getPaymentMethod, setOrphansPaymentCardAll, createPaymentSession, encryptPaymentData } from '../../../../api/index';
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import Loading from "../../../ui/loading";
import {FETCH_ALL_ORPHONS} from '../../../../constants/actionTypes'
import { useDispatch, useSelector } from "react-redux";
import Visa from "../../../../assets/visa.png";
import MasterCard from "../../../../assets/masterMethods.png";
import { baseURL, redirectURL } from "../../../../api/index";
function OrphanPaymentModal(props) {
  const [session, setSession] = useState(null);
  const [loadingCard, setLoadingCard] = useState(false);

    const [display, setDisplay] = useState(0)
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [cvc, setCvc] = useState("");
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [expiry, setExpiry] = useState("");
    const dispatch = useDispatch();

    const [expiryMonth, setExpiryMonth] = useState("");
    const [expiryYear, setExpiryYear] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [focus, setFocus] = useState("number");

    const [paymentMethods, setPaymentTokens] = useState([]);
    const [newCardLoading, setNewCardLoading] = useState(false);
    const [token, setToken] = useState('');
    const [checked, setChecked] = useState("adding new card");
    const[_resolve, setResolve] = useState(null);
  
    const [paymentResponse, setPaymentResponse] = useState(null);
    const[_reject, setReject] = useState(null);
  
    const [promise, setPromise] = useState(null);
  let mounted = true
    const getPaymentTokens = async () => {
        setNewCardLoading(true)
        await getPaymentMethod(userLogin?.Results[0]?.email).then(res => {
          if (mounted) {
            setPaymentTokens(res.data.data);
            setNewCardLoading(false)
          }
            
          if (res.data.data.findIndex(x=> x===props.oldcard)>=0) {
              setToken(props.oldcard)
          }
        })
      }
  useEffect(() => {
      getPaymentTokens();
    return () => {
      mounted = false
    }
      }, []);
      useEffect(() => {
        if(display === 1)
        {
        async function setUpPaymentSession() {
          setPromise(new Promise(function(resolve, reject){
            setResolve(() => resolve);
            setReject(() => reject);
           }))
        }
        setUpPaymentSession()
        
      
        }
    
      }, [display])

      useEffect(() => {
        async function addPayMethod()
        {
          if(_resolve !== null && _reject !== null)
          {
            let res = null;
            let sessionType = {
              actionType: "SAVE_ORPHAN_CARD"
            }
            res = await createPaymentSession(sessionType);
            setSession(res.data.session.id)
          if (self === top) {
        
            var antiClickjack = document.getElementById("antiClickjack");
            // antiClickjack.parentNode.removeChild(antiClickjack);
        } else {
        
            top.location = self.location;
        }
        
        PaymentSession.configure({
            session: res.data.session.id,
            fields: {
                // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
                card: {
                  number: "#card-number",
                  securityCode: "#security-code",
                  expiryMonth: "#expiry-month",
                  expiryYear: "#expiry-year",
                  nameOnCard: "#cardholder-name"
                }
            },
            //SPECIFY YOUR MITIGATION OPTION HERE
            frameEmbeddingMitigation: ["javascript"],
            callbacks: {
                initialized: function(response) {
                  setLoadingCard(true)
                    // HANDLE INITIALIZATION RESPONSE
                },
                formSessionUpdate: function(response) {
                    // HANDLE RESPONSE FOR UPDATE SESSION
                    if (response.status) {
                        if ("ok" == response.status) {
                          _resolve(response)
          
                            //check if the security code was provided by the user
                            if (response.sourceOfFunds.provided.card.securityCode) {
                            }
          
                            //check if the user entered a Mastercard credit card
                            if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
                            }
                        } else if ("fields_in_error" == response.status)  {
                          _reject(response)
          
                            if (response.errors.cardNumber) {
                            }
                            if (response.errors.expiryYear) {
                            }
                            if (response.errors.expiryMonth) {
                            }
                            if (response.errors.securityCode) {
                            }
                        } else if ("request_timeout" == response.status)  {
                          _reject(response)

                        } else if ("system_error" == response.status)  {
                          _reject(response)

                        }
                    } else {
                      _reject(response)

                    }
                    setPromise(new Promise(function(resolve, reject){
                      setResolve(() => resolve);
                      setReject(() => reject);
                     }))
                }
            },
            interaction: {
                displayControl: {
                    formatCard: "EMBOSSED",
                    invalidFieldCharacters: "REJECT"
                }
            }
         });
          }
        }
        addPayMethod()
        
      },[_reject, _resolve])
      function pay() {
        // UPDATE THE SESSION WITH THE INPUT FROM HOSTED FIELDS
        PaymentSession.updateSessionFromForm('card');
    }
    const handleInputFocus = (e) => {
        setFocus(e.target.name);
      };
      const endTheTrip = (e) => {
          setDisplay(0)
          setCvc('')
          setExpiry('')
          setExpiryMonth('')
          setExpiryYear('')
          setName('')
          setNumber('')
          setFocus('name')
        props.onHide();
      };

      const addPaymentMethod = async() => {
        
        promise.then( async() => {
          let id = "TOKEN" + Date.now();
      
        let sessionData = {};
        if(typeof props.orphan_id === 'string')
        {
          sessionData = {
            redirectUrl: window.location.origin + window.location.pathname,
            session: session,
            mco: id,
            amount: 2,
            actionType: "SAVE_ORPHAN_CARD",
            orphanUrl: new URLSearchParams({
              email:userLogin?.Results[0]?.email,
              sponsor_id: props.sponsor_id,
              device_id: "WEBAPP",
              language: lng==='arab'? 1:2
            }) + "&orphan_id=" + props.orphan_id
        }
        }
        else
        {
          sessionData = {
            redirectUrl: window.location.origin + window.location.pathname,
            session: session,
            mco: id,
            amount: 2,
            actionType: "SAVE_ORPHAN_CARD",
            orphanUrl: new URLSearchParams({
              email:userLogin?.Results[0]?.email,
              sponsor_id: props.sponsor_id,
              device_id: "WEBAPP",
              language: lng==='arab'? 1:2
            }) + props.orphan_id.map((item) => "&orphan_id=" + item ).join('')
        }
        }
        let encrypted_data = await encryptPaymentData(sessionData)

        var form = document.createElement("form");
        var element1 = document.createElement("input"); 

        form.method = "POST";
        form.action = `${redirectURL}/AuthGetData.aspx`;   

        element1.value=encrypted_data.data.data;
        element1.name="sessionData";
        form.appendChild(element1);  
        document.body.appendChild(form);

        form.submit();
        }).catch((e) => {
          if(e.status === "fields_in_error")
          {
            toastifyMessage("Please make sure all fields are correct", undefined, "error");
    
          }
          else
            toastifyMessage("Unknown error occured", undefined, "error");
         })
        await pay();
        
      }

      const handleCheck = (str) => {
          setToken(str);
      };
      const addSavedPaymentMethod = () => {
          if (!token) {
           return toastifyMessage("Please choose a payment method", undefined, "warning");
          }
        setNewCardLoading(true)
        
        props.selectmodeswitch? setOrphansPaymentCardAll(userLogin?.Results[0]?.email, props.sponsor_id, props.orphan_id, token, 'WEBAPP', lng==='arab'? 1:2).then((res)=> {
          dispatch({ type: FETCH_ALL_ORPHONS, payload: res.data.data });
          toastifyMessage("Added!", undefined, "success");
          setNewCardLoading(false)
          endTheTrip()
      })
      .catch(err=>{
          setNewCardLoading(false)
          toastifyMessage("Something went wrong...! gg", undefined, "error");
    })
    :
    setOrphansPaymentCard(userLogin?.Results[0]?.email, props.sponsor_id, props.orphan_id, token, lng==='arab'? 1:2).then((res)=> {
          dispatch({ type: FETCH_ALL_ORPHONS, payload: res.data.data });
          toastifyMessage("Added!", undefined, "success");
          setNewCardLoading(false)
          endTheTrip()
      })
      .catch(err=>{
          setNewCardLoading(false)
          toastifyMessage("Something went wrong...! gg", undefined, "error");
    })
      }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="payment_Modal"
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
            <div className='complet_info_title' style={{backgroundColor:'rgb(255 252 252)',width:'100%', display:'flex',borderTopRightRadius:'15px',borderTopLeftRadius:'15px', alignItems:'center', justifyContent:'space-between', padding:'10px', minHeight:'100px', direction: lng==='arab'? 'rtl':'ltr'}}>
                <h5>{t("Change Card")}</h5>
                <div onClick={()=> {!newCardLoading?  endTheTrip():null}} style={{width:'40px', height:'40px', borderRadius:'50%', display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #ee4236', cursor:'pointer'}}>
                    <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"
                    style={{height:'15px', width:'15px'}} alt='close_btn'/>
                </div>
            </div>
            {display===0?
            <div className='edit_payment_modal_body'>
                <div onClick={()=> setDisplay(1)} className='editPayment_amount_modal'>
                     <label className={lng==='arab'? 'force_arabic_font':'payment_numbers'} style={{color:'#ee4236', textAlign:'center', cursor:'pointer'}}>{t("New Card")}</label>   
                </div>
                <div onClick={()=> setDisplay(2)} className='editPayment_amount_modal'>
                     <label className={lng==='arab'? 'force_arabic_font':'payment_numbers'} style={{color:'#ee4236', textAlign:'center', cursor:'pointer'}}>{t("Existed Card")}</label>   
                </div>
            </div>:
            display===1?
            <div           id="antiClickjack" 
            className="paymentBody paymentBodyHere" style={{width:'100%', padding:props.frompopup? null:'10px', backgroundColor:'white', marginTop:'0px', borderBottomRightRadius:'15px', borderBottomLeftRadius:'15px'}}>
                {newCardLoading?
                <Loading/>:
                <React.Fragment>
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', direction:'ltr', backgroundColor:'white'}} className='payment_numbers'>
                {/* <Cards cvc={cvc} expiry={expiry} focused={focus} name={name} number={number}/> */}
            </div>
            <div style={{display: !loadingCard? 'none': 'inline',padding:'10px', backgroundColor:'#fff', borderBottomRightRadius:'15px', borderBottomLeftRadius:'15px', direction: lng==='arab'? 'rtl':'ltr'}}  className="addPaymentMethodForm addPaymentMethodFormHere">
                <div style={{width:'100%'}}>
                  <div>{t("Card Number")}</div>
                  <input                 
                   type="text"
                   id="card-number"
                   className="input-field"
                   title="card number"
                   aria-label="enter your card number"
                   value=""
                     tabIndex="1"
                     readOnly
                     />
                </div>
                <div style={{width:'100%'}}>
                  <div>{t("Cardholder Name")}</div>
                  <input  type="text"
                  aria-label="enter name on card" tabIndex="2" readOnly
                  className="input-field"
                  id="cardholder-name"
                  />
                </div>
                <div style={{display: "grid",gridTemplateColumns: "repeat(auto-fit,minmax(100px, 1fr))", gap: "20px", width:'100%'}}>
                  <div>
                    <div style={{fontFamily:'english_font'}}>{("MM")}</div>
                    <input
                     aria-label="two digit expiry month"
                     tabIndex="3" readOnly
                     className="input-field"
                       id="expiry-month"
                       type="text"
                    />
                  </div>
                  <div>
                  <div style={{fontFamily:'english_font'}}>{("YY")}</div>
                    <input 
                                       aria-label="two digit expiry year"  tabIndex="4" readOnly
                                       className="input-field"
                                         id="expiry-year"
                                         type="text"
      />
                  </div>
                  <div>
                  <div style={{fontFamily:'english_font'}}>{("CVC")}</div>
                    <input  aria-label="three digit CCV security code"  tabIndex="4" readOnly
                    id="security-code"
                    type="text"
                   />
                  </div>
                </div>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'5px', cursor:'pointer'}}>
                  <label onClick={addPaymentMethod} style={{minHeight:'auto', backgroundColor:'black', color:'white', height:'30px', width:'60px', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer'}}>{t("Save")} </label>
            </div>
          </div>
          <div style={{display: loadingCard? 'none': 'inline'}}>
            <Loading height="250px" />

            </div>
                </React.Fragment>
            }
            </div>:
          display===2?
          <div className='payment_methods_to_select' style={{padding:'10px', backgroundColor:'#fff', borderBottomRightRadius:'15px', borderBottomLeftRadius:'15px', direction: lng==='arab'? 'rtl':'ltr'}}>
              {newCardLoading?
              <Loading/>:
              <React.Fragment>
                <div className="payment_methods_body" style={{width:'100%', borderRadius:'0px', boxShadow: 'unset'}}>
                {paymentMethods?.map((card, index) => {
              return (
                <div key={index} className="check_box_item_wraper" onClick={() => handleCheck(card)}>
                  <div className="check_box_item">
                    <div className={token === card? "check_radio_selected": "check_radio_unselected"}></div>
                    <label className='payment_numbers label_of_title' style={{ direction: 'ltr', fontFamily:'english_font', height:'auto' }} htmlFor="visa">{card}</label>
                  </div>
                  <img src={card?.startsWith("5") ? MasterCard : Visa} alt="card" className="payment_methods_icons"/>
                </div>
              );
            })}
            </div>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'5px', cursor:'pointer'}}>
                  <label onClick={addSavedPaymentMethod} style={{minHeight:'auto', backgroundColor:'black', color:'white', height:'30px', width:'60px', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer'}}>{t("Save")} </label>
            </div>  
              </React.Fragment>
            }
              
          </div>:
          null

        }
            
      </Modal.Body>
    </Modal>
  );
}
export default OrphanPaymentModal;
