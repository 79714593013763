import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { initFacebookSdk } from "./components/Auth/facebookSignIn/initFacebookSdk";
import initialize from "./constants/firebase_key";
import dun from "./constants/dynmaic_link";
// import "./utils/stringModifier"
import { createBrowserHistory } from "history";

const store = createStore(reducers, compose(applyMiddleware(thunk)));
// const history = createBrowserHistory({ basename: process.env.REACT_APP_BASEURL });

initFacebookSdk();
initialize();
startApp();

function startApp() {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter
      //  history={history} 
       basename={process.env.REACT_APP_BASEURL}>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}
