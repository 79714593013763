import { FirebaseDynamicLinks } from "firebase-dynamic-links";

const firebaseDynamicLinks = new FirebaseDynamicLinks(
  "AIzaSyC_YbstGWfWMbSrRKgrUN8hHZ1T3jLxj08"
);
const createLink = async (path, title, description, img) => {
  const { shortLink } = await firebaseDynamicLinks.createLink({
    dynamicLinkInfo: {
      domainUriPrefix: "https://rcuae.page.link",
      link: `https://${window.location.hostname}/${path}`,
      androidInfo: {
        androidPackageName: "ae.rcuae.rcuae_app",
      },
      iosInfo: {
        iosBundleId: "ae.rcuae.rcapp",
      },
      socialMetaTagInfo: {
        socialTitle: title || description || "ERC",
        socialDescription: description || title || "ERC",
        socialImageLink: img,
      },
    },
  });
  return shortLink;
};
const convertJsonToString = (json) => {
  let res = "";
  for (var key in json) {
    if (json.hasOwnProperty(key)) {
      res += key + "=" + json[key] + "&";
    }
  }
   return res.slice(0, -1);
};
export const humanCaseLink = async (id, title, description, img) => {
  return await createLink(`humancase?id=${id}`, title, description, img);
};
export const sukukLink = async (id, title, description, img) => {
  return await createLink(`sukuk?id=${id}`, title, description, img);
};
export const genaralDonationLink = async (id, title, description, img) => {
  return await createLink(`donations?id=${id}`, title, description, img);
};
export const orphonsLink = async (data, title, description, img) => {
  return await createLink(
    `beasponser?${convertJsonToString(data)}`,
    title,
    description,
    img
  );
};
export const projectsLink = async (data, title, description, img) => {
  return await createLink(
    `projects/${JSON.parse(data).projectTypeID}`,
    title,
    description,
    img
  );
};
export const makeUrlWithData = async (path, data, title, description, img) => {
  return await createLink(
    `${path}?${convertJsonToString(data)}`,
    title,
    description,
    img
  );
};
export const shareMe = async (title, shareLink) => {
  if (navigator.share) {
    await navigator.share({
      title: title,
      url: shareLink,
    });
  }
};
