import { FETCH_ALL_BRANCHES } from "../../constants/actionTypes";

export default (vlnBranches = [], action) => {
  switch (action.type) {
    case FETCH_ALL_BRANCHES:
      return action.payload;
    default:
      return vlnBranches || [];
  }
};
