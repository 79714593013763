import { CHANGE_TRANSLATION_ARAB, CHANGE_TRANSLATION_ENG } from '../constants/actionTypes'

export default (langauge = 'eng', action) => {
    switch (action.type) {
        case CHANGE_TRANSLATION_ARAB:
            return langauge='arabic';
        case CHANGE_TRANSLATION_ENG:
            return langauge='eng'
        default:
            return langauge;
    }
}