import React, { useState, useEffect, useRef } from "react";
import "./DonationSupportPage.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import donateIcon from "../../../assets/redDonateIcon.png";
import share from "../../../assets/share.png";
import smsicon from "../../../assets/donateBySMS.png";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import cash from "../../../assets/donateByCash.png";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import samPay from "../../../assets/Pay_button_basic_pos_RGB.png";
import ShareAlertModel from "../../LoaclComponents/shareHeader/share_model/ShareModelAlert";
import likeIcon from "../../../assets/favour.png";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import likedIcon from "../../../assets/favourite.png";

import { startApplePay } from "../../../actions/handleApplePayment";
import { handleAddToBag } from "../../../actions/BagFav";
import { handleSamsungDirectPayment } from "../../../actions/handleSamsungPayment";

import SmsModal from "../../ui/smsModal/SmsModal";

import Loading from "../../ui/loading";
import NotFound from "../../NotFound/NotFound";
import { addToFavorites, deleteFromFavorites } from "../../../actions/BagFav";
import { FETCH_LOCALLY } from "../../../constants/actionTypes";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DonationFinalPopUp from "../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import Default from "../../../assets/default.jpg";

const DonationSupportPage = () => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [liked, setLiked] = useState(false);

  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  const donations = useSelector((state) => state.donations.donations);
  const loading = useSelector((state) => state.donations.loading);

  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");

  const [itemOfDonation, setItemOfDonation] = useState({});
  const [copied, setCopied] = useState(false);
  const userBag = useSelector((state) => state.bag.bag);

  const [smsModalShow, setSmsModalShow] = useState(false);
  const [showCopyCard, setshowCopyCard] = useState(false);

  const userFav = useSelector((state) => state.favorites.favorites);
  const [favoritesDetails, setFavoritesDetails] = useState(userFav);
  useEffect(() => {
    setFavoritesDetails(userFav);
  }, [userFav]);
  const [addingToFav, setAddingToFav] = useState(false);
  const [bagDetails, setBagDetails] = useState(userBag);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  const [applePaymentBody, setApplePaymentBody] = useState({});
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [scrollValue, setScrollValue] = useState(
    parseInt(itemOfDonation?.amount)
  );
  const [inTheBag, setInTheBag] = useState(false);
  const [change, setChange] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [desiableMonthYer, setDisableMonthYer] = useState(false);

  const [choosenToDonate, setChoosenToDonate] = useState({
    donationId: id,
    donationFreq: "N",
    amount: parseInt(itemOfDonation?.amount),
    title: "",
    img: "",
    edit_flag: true,
    item_type: 0,
  });
  const [addingToCart, setAddingToCart] = useState(false);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  const [imgSrc, setImgSrc] = useState(itemOfDonation?.imagePath);
  useEffect(() => {
    setImgSrc(itemOfDonation?.imagePath);
  }, [itemOfDonation?.imagePath]);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);
  // useEffect(() => {
  //   let donationWeNeed = donations?.filter((item) => item.dontypeID === id);
  //   setItemOfDonation(donationWeNeed?.slice(0, 1).shift());
  //   if (
  //     currentProfile?.cart?.donations?.filter(
  //       (item) =>
  //         item.donationId === donationWeNeed?.slice(0, 1).shift()?.dontypeID && parseInt(item.baseAmount) === parseInt(choosenToDonate.amount) && item.donationFreq === choosenToDonate.donationFreq
  //     ).length > 0
  //   ) {
  //     setInTheBag(true);
  //   } else {
  //     setInTheBag(false);
  //   }
  // }, [currentProfile, id]);
  // useEffect(() => {
  //   if (!loading && itemOfDonation && !desiableMonthYer) {
  //     if (choosenToDonate.donationFreq === "N") {
  //       document.getElementById("btn_one").style.backgroundColor = "#ee4236";
  //       document.getElementById("btn_two").style.backgroundColor = "unset";
  //       document.getElementById("btn_three").style.backgroundColor = "unset";
  //       document.getElementById("btn_one").style.color = "white";
  //       document.getElementById("btn_two").style.color = "black";
  //       document.getElementById("btn_three").style.color = "black";
  //     } else if (choosenToDonate.donationFreq === "M") {
  //       document.getElementById("btn_one").style.backgroundColor = "unset";
  //       document.getElementById("btn_two").style.backgroundColor = "#ee4236";
  //       document.getElementById("btn_three").style.backgroundColor = "unset";
  //       document.getElementById("btn_one").style.color = "black";
  //       document.getElementById("btn_two").style.color = "white";
  //       document.getElementById("btn_three").style.color = "black";
  //     } else {
  //       document.getElementById("btn_one").style.backgroundColor = "unset";
  //       document.getElementById("btn_two").style.backgroundColor = "unset";
  //       document.getElementById("btn_three").style.backgroundColor = "#ee4236";
  //       document.getElementById("btn_one").style.color = "black";
  //       document.getElementById("btn_two").style.color = "black";
  //       document.getElementById("btn_three").style.color = "white";
  //     }
  //   }
  //   if (desiableMonthYer) {
  //     document.getElementById("btn_one").style.backgroundColor = "#ee4236";
  //     document.getElementById("btn_one").style.color = "white";
  //   }
  // }, [choosenToDonate, desiableMonthYer]);
  useEffect(() => {
    //window.scrollTo(0, 0);
    let donationWeNeed = donations?.filter((item) => item.dontypeID === id);
    setItemOfDonation(donationWeNeed?.slice(0, 1).shift());
    setChoosenToDonate({
      donationId: id,
      donationFreq: "N",
      amount: parseInt(donationWeNeed?.slice(0, 1).shift()?.amount),
      title: donationWeNeed?.slice(0, 1).shift()?.dontypeName,
      img: donationWeNeed?.slice(0, 1).shift()?.imagePath,
      edit_flag: donationWeNeed?.slice(0, 1).shift()?.edit_flag,
      item_type: 0,
    });
    if (donationWeNeed?.slice(0, 1).shift()?.recurring === "N") {
      setDisableMonthYer(true);
    }
  }, [id, donations]);

  useEffect(() => {
    let donationWeNeed = donations?.filter((item) => item.dontypeID === id);
    if (!userLogin) {
      if (
        currentProfile?.liked?.donations?.filter(
          (item) =>
            item.donationId === donationWeNeed?.slice(0, 1).shift()?.dontypeID
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    } else {
      if (
        favoritesDetails?.donations?.filter(
          (item) =>
            item.dontypeID === donationWeNeed?.slice(0, 1).shift()?.dontypeID
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
    if (!userLogin) {
      if (
        currentProfile?.cart?.donations?.filter(
          (item) =>
            item.donationId ===
              donationWeNeed?.slice(0, 1).shift()?.dontypeID &&
            parseInt(item.baseAmount) === parseInt(choosenToDonate.amount) &&
            item.donationFreq === choosenToDonate.donationFreq
        ).length > 0
      ) {
        setInTheBag(
          currentProfile?.cart?.donations?.filter(
            (item) =>
              item.donationId ===
                donationWeNeed?.slice(0, 1).shift()?.dontypeID &&
              parseInt(item.baseAmount) === parseInt(choosenToDonate.amount) &&
              item.donationFreq === choosenToDonate.donationFreq
          ).length
        );
      }
    } else {
      if (
        bagDetails?.donations
          ?.filter(
            (item) =>
              item.dontypeID ===
                donationWeNeed?.slice(0, 1).shift()?.dontypeID &&
              item.recurring === choosenToDonate.donationFreq &&
              parseInt(item.baseAmount) === parseInt(choosenToDonate.amount)
          )
          .slice(0)
          .shift()?.quantity
      ) {
        setInTheBag(
          bagDetails?.donations
            ?.filter(
              (item) =>
                item.dontypeID ===
                  donationWeNeed?.slice(0, 1).shift()?.dontypeID &&
                item.recurring === choosenToDonate.donationFreq &&
                parseInt(item.baseAmount) === parseInt(choosenToDonate.amount)
            )
            .slice(0)
            .shift()?.quantity
        );
      }
    }
  }, [
    currentProfile,
    bagDetails,
    userLogin,
    donations,
    choosenToDonate,
    choosenToDonate.amount,
    id,
    choosenToDonate.donationFreq,
    change,
    userFav,
  ]);

  const handleChange = (e) => {
    setChoosenToDonate({
      ...choosenToDonate,
      amount: e.target.validity.valid ? e.target.value : choosenToDonate.amount,
    });
  };
  const handleSMS = async (donationId) => {
    setSmsModalShow(true);
    //var data = await getSMS(donationId);
  };
  const handleLikeDisLike = async (donationID) => {
    setAddingToFav(true);
    if (!liked) {
      if (!userLogin) {
        if (!currentProfile) {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              cart: {
                donations: [],
                sukuks: [],
                projects: [],
                humanCases: [],
                orphans: [],
              },
              liked: { donations: [], sukuks: [], projects: [], orphans: [] },
            })
          );
          currentProfile = JSON.parse(localStorage.getItem("profile"));
        }
        dispatch({ type: "addLikeLocally", payload: donationID });
        currentProfile.liked.donations.push(donationID);
        const newProfile = {
          cart: currentProfile.cart,
          liked: currentProfile.liked,
        };
        localStorage.setItem("profile", JSON.stringify(newProfile));
        await dispatch({ type: FETCH_LOCALLY });
        setChange(change + 1);
        if (_isMounted.current) {
          setAddingToFav(false);
        }
        
      } else {
        await dispatch(
          addToFavorites(lng === "arab" ? 1 : 2, {
            userEmail: userLogin.Results[0]?.email,
            dontypeID: donationID.donationId,
            projectID: "",
            orptypeID: "",
            projectYear: "",
            countryID: "",
          })
        );
        if (_isMounted.current) {
          setAddingToFav(false);
        }
      }
    } else {
      if (!userLogin) {
        let newProfile = {
          cart: currentProfile.cart,
          liked: {
            donations: currentProfile.liked.donations.filter(
              (item) => item.donationId !== donationID.donationId
            ),
            sukuks: currentProfile.liked.sukuks,
            projects: currentProfile.liked.projects,
            orphans: currentProfile.liked.orphans,
          },
        };
        localStorage.setItem("profile", JSON.stringify(newProfile));
        await dispatch({ type: FETCH_LOCALLY });
        setChange(change + 1);
        if (_isMounted.current) {
          setAddingToFav(false);
        }
      } else {
        let elementWeNeed = favoritesDetails?.donations
          ?.filter((item) => item.dontypeID === donationID.donationId)
          .slice(0, 1)
          .shift();
        if (elementWeNeed) {
          await dispatch(
            deleteFromFavorites(
              lng === "arab" ? 1 : 2,
              userLogin.Results[0]?.email,
              elementWeNeed.cart_serial
            )
          );
        }
        if (_isMounted.current) {
          setAddingToFav(false);
        }
      }
    }
  };
  const handleInBag = async (str) => {
    setAddingToCart(true);
    handleAddToBag("donations", str, setChange, change, dispatch);
    if (_isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut");
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text animate__animated animate__bounceIn");
      setAddingToCart(false);
      setInTheBagText("Add to Bag");
      }
      
      //setInTheBagText('Added')
    }, 1000);
    setTimeout(() => {
      if (_isMounted.current) {
        setAnimationClass("btn_with_text");
      }
      //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
    }, 2000);
    }
    
  };
  const donateNw = () => {
    if (
      choosenToDonate.amount &&
      choosenToDonate.amount !== "0" &&
      choosenToDonate.amount !== " "
    ) {
      history.push(`/checkout`, {
        from: "donations",
        elements: [
          { title: choosenToDonate.title, price: choosenToDonate.amount },
        ],
        totalAmount: choosenToDonate.amount,
        details: choosenToDonate,
      });
    }
  };
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: choosenToDonate.amount,
      url: window.location.href,
      item: [
        {
          dontype_id: choosenToDonate.donationId,
          recuring: choosenToDonate.donationFreq,
          amount: choosenToDonate.amount,
          item_type: 0,
        },
      ],
    });
    setLoadingModalShow(false);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };

  if (loading) {
    return <Loading height={"85vh"} />;
  } else if (itemOfDonation) {
    return (
      <div
        id="donation_support_page"
        style={lng === "arab" ? { alignItems: "flex-end" } : null}
      >
        <ShareHeader
          textToBeCopied={window.location.href}
          pageName={itemOfDonation?.dontypeName}
          path={"donations"}
          shareData={choosenToDonate}
        />
        <h1
          className="headerForDesktop"
          style={{
            paddingBottom: "5px",
            borderBottom: "3px solid #ee4236",
            direction: "rtl",
          }}
        >
          {itemOfDonation?.dontypeName}
        </h1>

        <div
          className="donation_support_page_display"
          style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
        >
          <div className="donation_support_page_left">
            <img
              src={imgSrc}
              onError={() => setImgSrc(Default)}
              alt="support"
              className="human_img"
            />
          </div>
          <div
            className="donation_support_page_right"
            style={lng === "arab" ? { direction: "rtl" } : null}
          >
            <div className="donation_support_page_section">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <h5
                  className="title_to_be_very_small"
                  style={
                    lng === "arab"
                      ? { fontWeight: "bold", direction: "rtl" }
                      : { fontWeight: "normal" }
                  }
                >
                  {t("Donation Frequency")}
                </h5>
                {addingToFav ? (
                  <button
                    disabled={addingToFav}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontSize: "25px",
                      display: width > 900 ? "none" : "flex",
                    }}
                  >
                    <div className="containerAnime">
                      <div className="ballFav"></div>
                      <div className="ballFav"></div>
                    </div>
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontSize: "25px",
                      display: width > 900 ? "none" : "flex",
                    }}
                    disabled={addingToCart || addingToFav}
                    onClick={() => handleLikeDisLike(choosenToDonate)}
                  >
                    {liked ? (
                      <i
                        style={{ color: "#ee4236" }}
                        className="fas fa-heart"
                      ></i>
                    ) : (
                      <i
                        style={{ color: "#808080b0" }}
                        className="far fa-heart"
                      ></i>
                    )}
                  </button>
                )}
              </div>

              <div className="donation_frequ_btns">
                <button
                  disabled={addingToCart}
                  style={
                    choosenToDonate.donationFreq === "N"
                      ? { backgroundColor: "#ee4236", color: "white" }
                      : null
                  }
                  id="btn_one"
                  onClick={() =>
                    setChoosenToDonate({
                      ...choosenToDonate,
                      donationFreq: "N",
                    })
                  }
                  className="btn btn-block btn-light btn-frequ btn-frequ-donate"
                >
                  {t("One Time")}
                </button>
                {!desiableMonthYer ? (
                  <button
                    disabled={addingToCart}
                    style={
                      choosenToDonate.donationFreq === "M"
                        ? { backgroundColor: "#ee4236", color: "white" }
                        : null
                    }
                    id="btn_two"
                    onClick={() =>
                      setChoosenToDonate({
                        ...choosenToDonate,
                        donationFreq: "M",
                      })
                    }
                    className="btn btn-block btn-light btn-frequ btn-frequ-donate"
                  >
                    {t("Monthly")}
                  </button>
                ) : null}
                {!desiableMonthYer ? (
                  <button
                    style={
                      choosenToDonate.donationFreq === "D"
                        ? { backgroundColor: "#ee4236", color: "white" }
                        : null
                    }
                    disabled={addingToCart}
                    id="btn_three"
                    onClick={() =>
                      setChoosenToDonate({
                        ...choosenToDonate,
                        donationFreq: "D",
                      })
                    }
                    className="btn btn-block btn-light btn-frequ btn-frequ-donate"
                  >
                    {t("Daily")}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="donation_support_page_section">
              <h5
                className="title_to_be_very_small"
                style={
                  lng === "arab"
                    ? { fontWeight: "bold", direction: "rtl" }
                    : { fontWeight: "normal" }
                }
              >
                {t("Donation Amount")}
              </h5>
              <div className="donation_amount_btns">
                <input
                  disabled={addingToCart}
                  type="range"
                  min={
                    isNaN(parseInt(itemOfDonation?.amount))
                      ? 10
                      : parseInt(itemOfDonation?.amount)
                  }
                  max={
                    isNaN(parseInt(itemOfDonation?.amount) * 10)
                      ? 100
                      : parseInt(itemOfDonation?.amount) * 10
                  }
                  step={itemOfDonation?.amount}
                  value={
                    isNaN(choosenToDonate.amount) ? 10 : choosenToDonate.amount
                  }
                  onChange={handleChange}
                  className="slider"
                  id="volume"
                />
                <div className="donation_amount_input ">
                  <label className="dai_mob">{t("AED")}</label>
                  <input
                    disabled={!choosenToDonate.edit_flag || addingToCart}
                    className="dai_mob translateFillMsg"
                    autoFocus
                    type="text"
                    pattern="[0-9]*"
                    value={
                      isNaN(choosenToDonate.amount)
                        ? " "
                        : choosenToDonate.amount
                    }
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
            <div className="human_donation_btns">
              <div className="human_donation_btns_above">
                <div className="btn_with_text shareBtnDesk">
                  <button
                    onClick={() => setshowCopyCard(true)}
                    style={{ backgroundColor: "transparent" }}
                    className="human_donate_ways btn1"
                  >
                    <img
                      src={share}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Share")}</label>
                </div>
                <div className="btn_with_text shareBtnDesk">
                  {addingToFav ? (
                    <button
                      disabled={addingToFav}
                      className=" btn4"
                      style={{ minHeight: "90px", minWidth: "90px" }}
                    >
                      <div className="containerAnime">
                        <div
                          style={{ width: "10px", height: "10px" }}
                          className="ballFav"
                        ></div>
                        <div
                          style={{ width: "10px", height: "10px" }}
                          className="ballFav"
                        ></div>
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => handleLikeDisLike(choosenToDonate)}
                      className="human_donate_ways btn4"
                    >
                      <img
                        src={liked ? likedIcon : likeIcon}
                        alt="donate_btn_icon_banner"
                        className="donate_btn_icon_ind"
                      />
                    </button>
                  )}
                  <label
                    className="label_to_beSmall"
                    style={{ textAlign: "center" }}
                  >
                    {liked ? t("Remove from favorites") : t("Add to favorites")}
                  </label>
                </div>

                {/*adding with animation*/}
                {inTheBagText === "Added" ? (
                  <div
                    className={
                      "btn_with_text animate__animated animate__bounceIn"
                    }
                  >
                    <button
                      disabled={addingToCart}
                      className="human_donate_ways btn1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <label className="label_to_beSmall">
                        {t("You now have ") + inTheBag + t(" in your Bag")}
                      </label>
                    </button>
                  </div>
                ) : (
                  <div className={animationClass}>
                    {addingToCart ? (
                      <Loading
                        height={"90px"}
                        mediaQ={"80px"}
                        small={"40px"}
                        animate={true}
                      />
                    ) : (
                      <React.Fragment>
                        <button
                          disabled={addingToCart}
                          onClick={() =>
                            choosenToDonate.amount &&
                            choosenToDonate.amount >= 5 &&
                            choosenToDonate.amount !== " "
                              ? handleInBag(choosenToDonate)
                              : toastifyMessage(
                                  "Can not pay less than 5 AED",
                                  undefined,
                                  "warning"
                                )
                          }
                          className="human_donate_ways btn1"
                        >
                          <img
                            src={donateIcon}
                            alt="donate_btn_icon_banner"
                            className="donate_btn_icon_ind"
                          />
                        </button>
                        <label className="label_to_beSmall">
                          {t(inTheBagText)}
                        </label>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {/*adding with animation*/}

                {width < 900 ? (
                  <div className="btn_with_text">
                    <button
                      className="human_donate_ways btn2"
                      onClick={() => handleSMS(choosenToDonate.donationId)}
                    >
                      <img
                        src={smsicon}
                        alt="donate_btn_icon_banner"
                        className="donate_btn_icon_ind"
                      />
                    </button>
                    <label className="label_to_beSmall">
                      {t("Donate by SMS")}
                    </label>
                  </div>
                ) : null}

                <div className="btn_with_text">
                  <button
                    onClick={() =>
                      choosenToDonate.amount &&
                      choosenToDonate.amount >= 5 &&
                      choosenToDonate.amount !== " "
                        ? donateNw()
                        : toastifyMessage(
                            "Can not pay less than 5 AED",
                            undefined,
                            "warning"
                          )
                    }
                    className="human_donate_ways btn3"
                  >
                    <img
                      src={cash}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Donate Now")}</label>
                </div>
              </div>
              {window.ApplePaySession ? choosenToDonate.donationFreq !== "N" ? (
                <div
                  id="applepay_btn"
                  className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn"
                  locale="ar-AB"
                  style={{ opacity:"40%", borderRadius: '30px'}}
                  disabled
                ></div>
              ): (
                <div
                  id="applepay_btn"
                  className="apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn"
                  locale="ar-AB"
                  style={{borderRadius: '30px'}}
                  onClick={() => handleGuestPopUp(()=> startApplePay({
                    amount: choosenToDonate.amount,
                    cart: [
                      {
                        dontype_id: choosenToDonate.donationId,
                        item_type: 0,
                        amount: choosenToDonate.amount,
                        recuring: choosenToDonate.recuring,
                        cart_serial: null,
                      },
                    ],
                  }, dispatch, history))
                    
                  }
                ></div>
              ) : choosenToDonate.donationFreq !== "N" ? (
                <button
                  disabled
                  style={{
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: "40%",
                  }}
                  className="btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn"
                >
                  <img
                    src={samPay}
                    style={{
                      height: "60px",
                      width: width <= 300 ? "100%" : "auto",
                    }}
                    alt="samsung_pay"
                    className='samApple_btn_img'
                  />
                </button>
              ) : (
                <button
                  style={{
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleGuestPopUp(startSamsungPay)}
                  className="btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn"
                >
                  <img
                    src={samPay}
                    style={{
                      height: "60px",
                      width: width <= 300 ? "100%" : "auto",
                    }}
                    alt="samsung_pay"
                    className='samApple_btn_img'
                  />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* {modalShow ? (
          <Modal
            show={modalShow}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="donation_pop_up_body"
          >
            <Modal.Body
              style={{ backgroundColor: "#fdfdfd", borderRadius: "10px" }}
            >
              <div
                className="modal_body_for_donation_final"
                style={lng === "arab" ? { direction: "rtl" } : null}
              >
                <label>
                  {t("Would you like to")}{" "}
                  <span style={{ fontWeight: "bold" }}>{t("Register")}</span>{" "}
                  {t("for easier donations in the future?")}
                </label>
                <div className="donation_final_popUp_btns_wraper">
                  <button
                    onClick={() => history.push("/guest")}
                    className="donation_final_popUp_btn_first"
                  >
                    {t("Not Now")}
                  </button>
                  <button
                    onClick={() => history.push("/auth")}
                    className="donation_final_popUp_btn_second"
                  >
                    {t("Yes")}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : null} */}
        <SmsModal
          show={smsModalShow}
          donationid={choosenToDonate.donationId}
          onHide={() => setSmsModalShow(false)}
        />
        <ShareAlertModel
          path="donations"
          shareData={choosenToDonate}
          show={showCopyCard}
          onHide={() => {
            setshowCopyCard(false);
          }}
        ></ShareAlertModel>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
        <DonationFinalPopUp
          show={geustModal}
          paymentfunction={functionToSend}
          onHide={() => setGuestModal(false)}
          dialogClassName="modal-60w"
        />
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default DonationSupportPage;
