import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./LatePayment.css";
import LatePyamentPageItem from "./latePayment_page_comp/LatePyamentPageItem";
import { addToBag, deleteFromBag } from '../../../actions/BagFav'
import Loading from "../../ui/loading";
import NotFound from "../../NotFound/NotFound";
import { useTranslation } from "react-i18next";

const LatePayment = (props) => {
  const [latePaymentsState, setLatePaymentsState] = useState(props.latePayments);
  
  const loading = useSelector(state => state.latePayments.latePaymentLoading);
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const { t } = useTranslation();

  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const userBag = useSelector(state => state.bag.bag)
  const dispatch = useDispatch()
  const [bagDetails, setBagDetails] = useState(userBag)
  useEffect(() => {
    setBagDetails(userBag)
  }, [userBag])
  let refElement = useRef(null)
  useEffect(() => {
    if (refElement != null) {
      props.setHeightAll(refElement.current?.offsetHeight)
    }
  }, [refElement, latePaymentsState])
  useEffect(() => {
    setLatePaymentsState(props.latePayments)
  }, [props.latePayments])
  const userInfo = useSelector((state) => {return state.auth.authData;});
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  function loadQueryParams() {
    if (props.location.search) {
      let qParms = props.location.search.replaceAll("?", "");
      let qObj = JSON.parse(
        '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      return qObj;
    }
  }
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  // useEffect(() => {
  //   if (latePaymentsState.length>0) {
  //     props.disablelate(false)
  //     setLatePayments(latePaymentsState);
  //   } else {
  //     props.disablelate(true)
  //     props.handlenavi('renewals')
  //   }
  // }, [latePaymentsState]);

  const handleaddToBag = async (item, setAddingToBag) => {
    //setAddingToBag(true)
    await dispatch(addToBag({
      cart: {
        donID: "1", userName: userLogin?.Results[0]?.name_a, userMob: userLogin?.Results[0]?.mobile_no,
        userEmail: userLogin?.Results[0]?.email, itemType: '6', amount: item.trn_amount, recuring: null, cart_serial: null,
        orphan_id: item.orphanID, sponsor_id: loadQueryParams().sponsor_id, transaction_number: item.trn_serial,
        transaction_year: item.trn_year
      }, language: lng === 'arab' ? 1 : 2
    }))
    //setAddingToBag(false)
  }
  const handleRemoveFromBag = async (items, setAddingToBag) => {
    //setAddingToBag(true)
    let elementWeNeed = bagDetails?.latePayments?.filter(item => item.trn_serial === items.trn_serial).slice(0, 1).shift()
    if (elementWeNeed) {
      await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "arab" ? 1 : 2, elementWeNeed.orphan_serial))
    }
    //setAddingToBag(false)
  }
    return (
      <React.Fragment>
        {loading?
        <Loading height='85vh' />:
        latePaymentsState.length > 0?
        <div className="latepayment_page" ref={refElement}>
          {latePaymentsState?.map((late, i) => (
            <LatePyamentPageItem
              name={late.orphan_name}
              amount={late.trn_amount}
              handleaddToBag={handleaddToBag}
              handleRemoveFromBag={handleRemoveFromBag}
              userBag={bagDetails}
              allItem={late}
              late={true}
              date={late.payment_date}
              key={late.trn_serial}
            />
          ))}
        </div>:
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '85vh', width: '100%' }}>
            <label>{t("You don't have any items in late payments")}</label>
       </div>

      }
        
      </React.Fragment>
    );
};

export default LatePayment;
