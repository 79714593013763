import React, { useEffect, useState } from 'react'
import './LocalAidRelatives.css'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getRelatives } from '../../../actions/localAID'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';

const LocalAidRelatives = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const dispatch = useDispatch();


    useEffect(() => {
        if (userLogin?.Results[0]?.person_id !== '') {
            if (lng === 'arab') {
                dispatch(getRelatives('AR', userLogin?.Results[0]?.person_id))
            } else {
                dispatch(getRelatives('EN', userLogin?.Results[0]?.person_id))
            }
        }
    }, [lng]);
    const laRelatives = useSelector(state => state.laRelatives.laRelatives);
    const loading = useSelector(state => state.laRelatives.loading);
    const [relatives, setRelatives] = useState(laRelatives?.Local_Aids_Info?.slice(0))
    useEffect(() => {
        setRelatives(laRelatives?.Local_Aids_Info?.slice(0))
    }, [laRelatives])
    const [displayOfFam, setDisplayOfFam] = useState(false)
    const handleDataToggle = (str) => {
        if (displayOfFam === str) {
            setDisplayOfFam('')
           // document.getElementById(str).style.borderBottom = 'none'
        } else {
            setDisplayOfFam(str)
           // document.getElementById(str).style.borderBottom = '1px solid gray'
        }
    }
    if (userLogin?.Results[0]?.person_id_flag === '2') {
        return (
            <div className='submit_requ_page' style={lng === 'arab' ? { direction: 'rtl', minHeight: '85vh' } : { minHeight: '85vh' }}>
                <ShareHeader noShare={true} pageName={t("Relatives")} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Relatives")}</h3>
                </div>
                {loading ? <Loading /> : <div className='relatives_display'>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'flex-start', gap:'10px', height:'100%'}}>
                    {relatives?.slice(0,(relatives?.length/2)+1)?.map((item,i) =>
                        <div key={i} className='sponsership_info_pdf_wraper noPadding'>
                            <div id={`famTwo_${item.PERSON_ID}`} className='sponsership_info_title'>
                                <label>{item.FAM_NAME_A}</label>
                                <button onClick={()=> handleDataToggle(item.PERSON_ID)} className='data_toggle_sponsership'>
                                    {displayOfFam=== item.PERSON_ID ?
                                        <i className="fas fa-angle-down"></i> :
                                        lng === "arab" ?
                                            <i className="fas fa-angle-left"></i> :
                                            <i className="fas fa-angle-right"></i>
                                    }
                                </button>
                            </div>
                            {displayOfFam=== item.PERSON_ID?
                                <div className='sponsership_info_box_body flexForRelatives'>
                                    <div className='column_for_relatives'>
                                        <label>{t("Relation")}:</label>
                                        <label style={{ fontWeight: 'bold' }}>{item.RELATION_NAME_A}</label>
                                    </div>
                                    <div className='column_for_relatives'>
                                        <label>{t("Date of Birth")}:</label>
                                        <label className='payment_numbers' style={{ fontWeight: 'bold' }}>{item.FAM_DOB}</label>
                                    </div>
                                    <div className='column_for_relatives'>
                                        <label>{t("Health Status")}:</label>
                                        <label style={{ fontWeight: 'bold' }}>{item.HEALTH_STAT_NAME_A}</label>
                                    </div>
                                </div> : null
                            }
                        </div>
                    )}
                    </div>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'flex-start', gap:'10px', height:'100%'}}>
                    {relatives?.slice((relatives?.length/2)+1)?.map((item,i) =>
                        <div key={i} className='sponsership_info_pdf_wraper noPadding'>
                            <div id={`famTwo_${item.PERSON_ID}`} className='sponsership_info_title'>
                                <label>{item.FAM_NAME_A}</label>
                                <button onClick={()=> handleDataToggle(item.PERSON_ID)} className='data_toggle_sponsership'>
                                    {displayOfFam=== item.PERSON_ID ?
                                        <i className="fas fa-angle-down"></i> :
                                        lng === "arab" ?
                                            <i className="fas fa-angle-left"></i> :
                                            <i className="fas fa-angle-right"></i>
                                    }
                                </button>
                            </div>
                            {displayOfFam=== item.PERSON_ID?
                                <div className='sponsership_info_box_body flexForRelatives'>
                                    <div className='column_for_relatives'>
                                        <label>{t("Relation")}:</label>
                                        <label style={{ fontWeight: 'bold' }}>{item.RELATION_NAME_A}</label>
                                    </div>
                                    <div className='column_for_relatives'>
                                        <label>{t("Date of Birth")}:</label>
                                        <label className='payment_numbers' style={{ fontWeight: 'bold' }}>{item.FAM_DOB}</label>
                                    </div>
                                    <div className='column_for_relatives'>
                                        <label>{t("Health Status")}:</label>
                                        <label style={{ fontWeight: 'bold' }}>{item.HEALTH_STAT_NAME_A}</label>
                                    </div>
                                </div> : null
                            }
                        </div>
                    )}
                    </div>

                </div>}
            </div>
        )
    } else {
        window.location.replace("/profile");
    }
}

export default LocalAidRelatives
