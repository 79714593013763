import { FETCH_ALL_PROJECTS_FULL_SHARE_CREATE, FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING } from '../constants/actionTypes'

export default (fullProjectsShare = {
    fullProjectsShare: [],
    loading: true
}, action) => {

    switch (action.type) {
        case FETCH_ALL_PROJECTS_FULL_SHARE_CREATE:
            return {...fullProjectsShare, fullProjectsShare: action.payload.data, loading: false};
        case FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING:
            return {...fullProjectsShare,loading: action.status};
        default:
            return fullProjectsShare;
    }
}