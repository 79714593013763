import { FETCH_PARTICIPANTS_DETALS, LOAD_VOLUNTEER_PARTICIPANT, VOLUNTEER_PARTICIPANT_LOADED } from '../../constants/actionTypes'

export default (vlnParticipantsDetails = { vlnParticipantsDetails: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_PARTICIPANTS_DETALS:
            return { ...vlnParticipantsDetails, vlnParticipantsDetails: action.payload,loading:false };
        case LOAD_VOLUNTEER_PARTICIPANT:
            return { ...vlnParticipantsDetails, loading: true }
        case VOLUNTEER_PARTICIPANT_LOADED:
            return { ...vlnParticipantsDetails, loading: false }
        default:
            return vlnParticipantsDetails;
    }
}