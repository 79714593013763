import React, { useState, useEffect } from "react";
import icon1 from "../../../assets/nation id.png";
import icon3 from "../../../assets/ntionality.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
    addLocalAidRelativeAddress, completeLocalAid
} from "../../../api/local_aid";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
    applyValidation,
    resetValidation,
} from "../../../handlers/formValidation";

import { uploadLocalAidFiles } from "../../../api";

const UploadLocalAidFile = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));

    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [imgField, setImgField] = useState('');


    const { t } = useTranslation();
    // const userInfo = useSelector((state) => {
    //     return state.auth.authData;
    // });


    const [userInfoLocalAid, setUserInfoLocalAid] = useState({
        att: [],
        req_id: props.reqID,
        req_year: props.reqYear,
    });

    const [fieldValues, setFieldValues] = useState({
        att_desc: "",
        file_type: "",
        img_pre: "",
        attt: ""
    });


    const handleChange = (e) => {

        setFieldValues({
            ...fieldValues,
            att_desc: e.target.value,
        });
    };

    const handleChangeFile = async (e) => {
        getBase64(e.target.files[0]);
        setImgField(e.target.value);

    }

    const add = () => {
        if (fieldValues.attt == null || fieldValues.attt == "") {
            toastifyMessage("Please choose file", undefined, "error");
            return false;
        }
        else if (fieldValues.att_desc == null || fieldValues.att_desc == "") {
            toastifyMessage("Please enter description", undefined, "error");
            return false;
        }
        var temp = userInfoLocalAid.att;
        temp.push(fieldValues);
        setUserInfoLocalAid({ ...userInfoLocalAid, att: temp })
        setFieldValues({
            att_desc: "",
            file_type: "",
            img_pre: "",
            attt: ""
        })
        setImgField("");
    }


    const submitForm = () => {
        setLoadingModalShow(true)
        uploadLocalAidFiles(userInfoLocalAid).then(res => {
            completeLocalAid({
                req_id: props.reqID,
                req_year: props.reqYear
            }).then(response => {
                setLoadingModalShow(false)
                // toastifyMessage("You have successfully completed local aid registration", undefined, "success");
                let newResults = userLogin.Results.slice(0).shift();
                newResults.person_id_flag = '1';
                localStorage.setItem('userLogin', JSON.stringify({Results: [newResults]}))
                window.location.reload();
                //props.setSubmitRefetch(props.submitRefetch + 1)
                //props.setView(5)
            }) .catch((e) => {
                toastifyMessage("Something Went Wrong...!", undefined, "error");
            });
        }).catch((e) => {
            toastifyMessage("Something Went Wrong...!", undefined, "error");
        });
    }

    const removeFile = (i) => {
        var temp = userInfoLocalAid.att;
        temp.splice(i, 1)
        setUserInfoLocalAid({ ...userInfoLocalAid, att: temp });
    }

    function getUserInfo() {
        if (
            userLogin?.Results &&
            userLogin?.Results.length > 0 &&
            userLogin?.Results[0]
        ) {
            return userLogin?.Results[0];
        }
    }

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFieldValues({ ...fieldValues, attt: reader.result.toString().split(',')[1], file_type: file.type.split('/')[1], img_pre: reader.result.toString().split(',')[0] });
            // return reader.result;
        };
        reader.onerror = function (error) {
        };
    }

        return (
            <div
                className="local_aid_info_form_page"
                style={
                    lng === "arab"
                        ? { direction: "rtl", minHeight: "500px" }
                        : { minHeight: "500px" }
                }
            >
                <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
                    {t("Upload attachments")}
                </label>
                {/* <form className="local_aid_info_form" onSubmit={handleSubmit}> */}
                <div className="just_the_form_without_submit">
                    <div className="form_left_side">


                        {/* select address */}
                        <div className="input_wraper">
                            <img src={icon3} className="local_aid_icon" alt="form_icon" />
                            <input
                                type="file"
                                onChange={handleChangeFile}
                                className="local_aid_input"
                                value={imgField}
                                name="PO_BOX"
                                placeholder={t("Choose file")}
                                required
                                onInvalid={applyValidation}
                                onInput={resetValidation}
                            />
                        </div>
                        {/* select address */}


                    </div>
                    <div className="form_right_side">


                        <div className="input_wraper">
                            <div className="local_aid_input_icon_wraper">
                                <img src={icon1} className="local_aid_icon" alt="form_icon" />
                            </div>

                            <input
                                type="text"
                                onChange={handleChange}
                                className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                                value={fieldValues.att_desc}
                                name="att_desc"
                                placeholder={t("Description")}
                                required
                                onInvalid={applyValidation}
                                onInput={resetValidation}
                            />
                        </div>




                    </div>
                </div>

                <button type="button" onClick={add} style={{ alignText: "center" }} className="local_aid_submit">
                    <span style={{ flex: "16" }}>{t("ADD")}</span>
                </button>

                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', flexWrap:'wrap', gap:'10px'}} className="selected-images">
                    {userInfoLocalAid.att?.map((item, i) => (
                        <div key={i} style={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center", width:'auto', gap: '7px'}} className="image-row">
                            <img src={`${item.img_pre},${item.attt}`} style={{ width: "150px", height: "100px" }} alt="" />
                            <p style={{textAlign:'center', margin:'0px', fontWeight:'bold'}}>{item.att_desc}</p>
                            <button onClick={() => removeFile(i)} style={{ background: "#ee4236", color: "white", border: "none" }}>{t("Delete")}</button>
                        </div>
                    ))}
                </div>

                {userInfoLocalAid.att.length > 0 &&
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'5px', width:'100%'}}>
                    <button
                        type="button"
                        className="local_aid_submit"
                        onClick={submitForm}
                    >
                        <span style={{ flex: "16" }}>{t("Save")}</span>
                        {lng === "arab" ? (
                            <i className="fas fa-arrow-left"></i>
                        ) : (
                            <i className="fas fa-arrow-right"></i>
                        )}
                    </button>
                    <label className='fontFor_iserted width_as_button'>*{t("Once you sumbit your files, you will not be able to go back or add new information.")}</label>
                    </div>
                    }
                {/* </form> */}
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        );
};

export default UploadLocalAidFile;