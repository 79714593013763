/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import './FavoritesCom.css'
import donateIcon from '../../../../../assets/donateicon.png'
import donateIconRed from '../../../../../assets/addedBtn.png'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import PopUpPayment from '../../popUpPayment/PopUpPayment'
import { addToBag, deleteFromBag, handleAddToBag } from '../../../../../actions/BagFav'
import Loading from '../../../../ui/loading';
import Marquee from 'react-double-marquee';
import { toastifyMessage } from "../../../../../handlers/toastifyMessage";
import { useHistory } from 'react-router-dom'
import { FETCH_LOCALLY } from '../../../../../constants/actionTypes';

const FavoritesCom = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let currentProfile = JSON.parse(localStorage.getItem("profile"));
    const [addingToFav, setAddingToFav] = useState(false);
    const history = useHistory();

    const [popUpModal, setPopUpModal] = useState(false);
    const [donationAmount, setDonationAmount] = useState(parseInt(props.amount));
    const [liked, setLiked] = useState(true)
    const [inTheBag, setInTheBag] = useState(false)
    const dispatch = useDispatch()
    const userBag = useSelector(state => state.bag.bag)
    const [bagDetails, setBagDetails] = useState(userBag)
    const [addingToCart, setAddingToCart] = useState(false);
    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])
    const [choosenToDonate, setChoosenToDonate] = useState({
        donationId: props.donationID,
        amount: props.amount,
        title: props.title,
        img: props.DonImg,
        donationFreq: "N"
    })
    useEffect(() => {
        setChoosenToDonate({
            donationId: props.donationID,
            amount: props.amount,
            title: props.title,
            img: props.DonImg,
            donationFreq: "N"
        })
    }, [props])
    useEffect(() => {
        if (!userLogin) {
            if (props.currentProfile?.liked?.donations?.filter(item => item.donationId === props.donationID).length > 0) {
                setLiked(true)
            } else {
                setLiked(false)
            }
            } else {
                if (props.donationFav?.filter(item => item.dontypeID === props.donationID).length > 0) {
                    setLiked(true)
                } else {
                    setLiked(false)
                }
            }
        if (!userLogin) {
            if (props.currentProfile?.cart?.donations?.filter(item => item.donationId === props.donationID && item.donationFreq === 'N').length > 0) {
                setInTheBag(true)
            } else {
                setInTheBag(false)
            }
        } else {
            if (bagDetails?.donations?.filter(item => item.dontypeID === props.donationID && item.recurring==="N").length > 0) {
                setInTheBag(true)
            } else {
                setInTheBag(false)
            }
        }

    }, [props.donationID, props.currentProfile, bagDetails, userLogin, props.change, props.donationFav, props.currentProfile])
    const handleLike = (donationID) => {
        if (!props.currentProfile) {
            localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
            currentProfile = JSON.parse(localStorage.getItem('profile'));
        }
        dispatch({ type: 'addLikeLocally', payload: donationID })
        currentProfile.liked.donations.push(donationID)
        const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        props.setChange(props.change + 1)
    }
    const handleDisLike = (donationID) => {
        let newProfile = { cart: props.currentProfile.cart, liked: { donations: props.currentProfile.liked.donations.filter(item => item.donationId !== donationID.donationId), sukuks: props.currentProfile.liked.sukuks, projects: props.currentProfile.liked.projects, orphans: props.currentProfile.liked.orphans } }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        props.setChange(props.change + 1)
    }
    const handleLikeDisLike = async (str) => {
        setAddingToFav(true)
        if (liked) {
            await props.handleDisLike(str)
            if (_isMounted.current) {
              setAddingToFav(false);  
            }
            
        } else {
            await props.handleLike(str)
            if (_isMounted.current) {
               setAddingToFav(false) 
            }
        }
    }
    const handleInBag = async (str) => {
        setAddingToCart(true)
        await handleAddToBag("donations", str, props.setChange, props.change, dispatch)
        if (_isMounted.current) {
           setAddingToCart(false); 
        }       
    }
    const handleClick = (op) => {
        if (op === '-') {
            if (choosenToDonate.amount > parseInt(props.amount)) {
                setChoosenToDonate({ ...choosenToDonate, amount: parseInt(choosenToDonate.amount) - parseInt(props.amount) });
            }
        } else {
            setChoosenToDonate({ ...choosenToDonate, amount: parseInt(choosenToDonate.amount) + parseInt(props.amount) });
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <div className='donation_com'>
            <button disabled={addingToCart} style={lng === 'arab' ? { marginRight: '0px', marginLeft: '-30px' } : null} onClick={() => handleInBag(choosenToDonate)} className='donate_btn_wrap'>
                {addingToCart?
                <Loading height={width<=1110? '35px':'48px'} width={width<=1110? '35px':'48px'} small={'25px'}/>:
                <img src={donateIcon} alt='icon_for_favorites' className='icon_for_donation' />
            }
            </button>
            <div className='favorites_item'>
                <div className='img_donation_wraper' style={{ position: 'relative', width: '190px' }}>
                    <img src={props.DonImg} alt={props.title} className='donation_img' onClick={() => history.push(`/donations/${props.donationID}`)}/>
                    {addingToFav?
                    <button disabled={addingToFav} style={lng === 'arab' ? { right: 'unset', left: '16px' } : null} className='like_btn'>
                        <div className="containerAnime">
                            <div className="ballFav"></div>
                            <div className="ballFav"></div>
                        </div>
                    </button>:
                    <button style={lng === 'arab' ? { right: 'unset', left: '16px' } : null} onClick={() => handleLikeDisLike(choosenToDonate)} className='like_btn'>{liked ? <i style={{ color: '#ee4236' }} className="fas fa-heart"></i> : <i style={{ color: '#808080b0' }} className="far fa-heart"></i>}</button>
                    }
                </div>
                <div className='favorites_details'>
                {props.title.length >= 20?
                        <React.Fragment>
                            <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{direction:'ltr'}}>
                                <Marquee speed={0.025} childMargin={15} direction={lng==='arab'? 'right':'left'} className='label_of_title'><label className='label_of_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
                            </div>
                        </React.Fragment>:
                        <React.Fragment>
                                <div className='marquee_wraper'><label className='label_of_title' style={lng === 'arab' ? { width: '100%', textAlign: 'right'} : { width: '100%', textAlign: 'left'}}>{props.title}</label></div>
                        </React.Fragment>
                    }
                    <div className='favorites_inc_Dec'>
                        <button disabled={addingToCart} className='dec' onClick={() => handleClick('-')}>-</button>
                        <label className='labelForFavorites label_of_title' style={{fontWeight: 'normal' }}>{t("AED")} <span className='payment_numbers' style={{ fontSize: '15px', fontWeight: 'bold', fontFamily:'english_font' }}>{choosenToDonate.amount}</span></label>
                        <button disabled={addingToCart} className='inc' onClick={() => handleClick('+')}>+</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FavoritesCom