import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";
const ProjectsRedirect = () => {
    const history = useHistory()
    function useQuery() {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }
    let [searchParams, setSearchParams] = useState();
    let query = useQuery();
    let projectWeNeed = query.get("projectTypeID")
    useEffect(()=> {
        let projectWeNeed = query.get("projectTypeID")
        let selectedC = query.get("countryId")
        let selectedP = query.get("projectTypeID")
        let selectedA = query.get("projectAmount")
        if (projectWeNeed) {
            history.push(`/projects/${projectWeNeed}`, {selectedCountry: selectedC, selectedProject:selectedP, selectedAmount: selectedA})
        } else {
          history.push('/projects/all')  
        }
    })
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        </div>
    )
}

export default ProjectsRedirect
