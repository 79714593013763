import React, { useState, useEffect } from 'react'
import './Attachment.css'
import LocalAidProfileComp from '../../LocalAidMainPage/localAidProfilePage/localAidProfileComp/LocalAidProfileComp'
import icon2 from '../../../assets/nation id.png'
import icon5 from '../../../assets/ntionality.png'
import icon10 from '../../../assets/occupation.png'
import icon6 from '../../../assets/edducation.png'
import icon7 from '../../../assets/family name.png'
import { useTranslation } from "react-i18next";
import { getAttachments } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loading from '../../ui/loading'
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'

const Attachment = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getAttachments(userLogin?.Results[0]?.vln_id))
            } else {
                dispatch(getAttachments(userLogin?.Results[0]?.vln_id))
            }
        }
    }, [lng]);
    const vlnAttachments = useSelector(state => state.vlnAttachments.vlnAttachments);
    const loading = useSelector(state => state.vlnAttachments.loading);
    const [vlnAttachment, setVlnAttachment] = useState(vlnAttachments["vln docs "]?.slice(0, 1).shift())
    useEffect(() => {
        setVlnAttachment(vlnAttachments["vln docs "]?.slice(0, 1).shift())
    }, [vlnAttachments])
    const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='file_details' style={lng === 'arab' ? { direction: 'rtl', gap: '10px' } : { gap: '10px' }}>
                <ShareHeader pageName={t("Attachments")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Attachments")}</h3>
                </div>
                {loading?
                <Loading height='500px' />:
                <React.Fragment>
                <div className='volunteer_portal_lobby_left_side' style={{padding:width>=900? '20px':'10px'}}>
                    <label style={{ fontWeight: 'bold', marginTop: '20px', alignSelf: 'flex-start', padding: '0px 10px 0px 10px' }}>{t("JOB LICENSE")}</label>
                    <LocalAidProfileComp icon={icon10} keyOf={t('Job ID')} value={vlnAttachment?.JOBLIC_id} englishFont={true}/>
                    <LocalAidProfileComp icon={icon7} keyOf={t('End Date')} value={vlnAttachment?.JOBLIC_endDate} englishFont={true}/>
                </div>
                <div className='volunteer_portal_lobby_both_sides'>
                        <div className='volunteer_portal_lobby_left_side'>
                            <label style={{ fontWeight: 'bold', marginTop: '20px' }}>{t("EDUCATION LEVEL")}</label>
                            <LocalAidProfileComp icon={icon6} keyOf={t('Education Level')} value={vlnAttachment?.EDU_level_name}/>
                            <LocalAidProfileComp icon={icon6} keyOf={t('Major')} value={vlnAttachment?.EDU_qlf_name}/>
                            <LocalAidProfileComp icon={icon7} keyOf={t('Place of Issuance')} value={vlnAttachment?.EDU_placeOfIssuance}/>
                            <LocalAidProfileComp icon={icon7} keyOf={t('Date of Issuance')} value={vlnAttachment?.EDU_dateOfIssuance} englishFont={true}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Country')} value={vlnAttachment?.EDU_country}/>
                            <label style={{ fontWeight: 'bold', marginTop: '20px' }}>{t("NATIONALITY IDENTITY")}</label>
                            <LocalAidProfileComp icon={icon2} keyOf={t('National ID')} value={vlnAttachment?.EID_nationalId} englishFont={true}/>
                            <LocalAidProfileComp icon={icon7} keyOf={t('Place of Issuance')} value={vlnAttachment?.EID_placeOfIssuance}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Start Date')} value={vlnAttachment?.EID_startDate} englishFont={true}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('End Date')} value={vlnAttachment?.EID_endDate} englishFont={true}/>
                        </div>
                        <div className='volunteer_portal_lobby_right_side'>
                            <label style={{ fontWeight: 'bold', marginTop: '20px' }}>{t("RESIDENCE")}</label>
                            <LocalAidProfileComp icon={icon2} keyOf={t('Document ID')} value={vlnAttachment?.RESDNCE_docId} englishFont={true}/>
                            <LocalAidProfileComp icon={icon7} keyOf={t('Place of Issuance')} value={vlnAttachment?.RESDNCE_placeOfIssuance}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Start Date')} value={vlnAttachment?.RESDNCE_startDate} englishFont={true}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('End Date')} value={vlnAttachment?.RESDNCE_endDate} englishFont={true}/>
                            <label style={{ fontWeight: 'bold', marginTop: '20px' }}>{t("PASSPORT")}</label>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Document ID')} value={vlnAttachment?.Passport_docId} englishFont={true}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Place of Issuance')} value={vlnAttachment?.Passport_placeOfIssuance}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('Start Date')} value={vlnAttachment?.Passport_startDate} englishFont={true}/>
                            <LocalAidProfileComp icon={icon5} keyOf={t('End Date')} value={vlnAttachment?.Passport_endDate} englishFont={true}/>
                        </div>
                    </div>
                    </React.Fragment>}
                </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default Attachment
