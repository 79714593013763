import React from "react";
import Modal from "react-bootstrap/Modal";
import "./SocialModal.css";

//Icons
import Whatsapp from "../../../assets/Whatsapp.png";
import Facebook from "../../../assets/Facebook.png";
import Instagram from "../../../assets/Instagram.png";
import Snapchat from "../../../assets/Snapchat.png";
import Twitter from "../../../assets/Twitter.png";
import Youtube from "../../../assets/Youtube.png";

function SocialModal(props) {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="SocialModalDialog"
      contentClassName="SocialModalContent"
      centered
    >
      <Modal.Body className="SocialModalBody">
        <Modal.Header style={{backgroundColor:'transparent', border:'none'}} closeButton></Modal.Header>
        <div
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "lighter",
            color:'white'
          }}
        >
          @EmiratesRC
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap:'wrap',
            marginTop: "30px",
          }}
        >

          <div className="social-img-icon">
            <img
              src={Instagram}
              style={{
                height: "100px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://www.instagram.com/emiratesrc/");
              }}
              className='socialMediaIcon'
            />
          </div>

          <div className="social-img-icon">
            <img
              src={Facebook}
              style={{
                height: "100px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://www.facebook.com/emiratesrc");
              }}
              className='socialMediaIcon'
            />
          </div>

          <div className="social-img-icon">
            <img
              src={Snapchat}
              style={{
                height: "100px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://www.snapchat.com/add/emiratesrc");
              }}
              className='socialMediaIcon'
            />
          </div>
        </div>
        <hr style={{color:'white'}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap:'wrap'
          }}
        >
          <div className="social-img-icon">
            <img
              src={Twitter}
              style={{
                height: "100px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://twitter.com/emiratesrc");
              }}
              className='socialMediaIcon'
            />
          </div>
          <div className="social-img-icon">
            <img
              src={Whatsapp}
              style={{
                height: "100px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://wa.me/9712800733");
              }}
              className='socialMediaIcon'
            />
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
}
export default SocialModal;
