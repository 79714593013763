import React, { useState, useEffect } from 'react';
import './LatePaymentPageItem.css';
import addToBag from '../../../../assets/donateicon.png';
import addedBtn from '../../../../assets/addedBtn.png';
import { useTranslation } from 'react-i18next';
import RenewelsDialog from '../../RenewelsDialog/RenewelsDialog';
import Loading from '../../../ui/loading';
const LatePyamentPageItem = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [added, setAdded] = useState(false);
  const [addingToBag, setAddingToBag] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [finishAdding, setFinishAdding] = useState(false);

  const [latePaymentData, setlatePaymentData] = useState({
    name: props.allItem?.orphan_name,
    trn_amount: props.allItem?.trn_amount,
    trn_year: props.allItem?.trn_year,
    trn_serial: props.allItem?.trn_serial,
    orphanID: props.allItem?.orphanID
  });
  useEffect(() => {
    setlatePaymentData({
      name: props.allItem?.orphan_name,
      trn_amount: props.allItem?.trn_amount,
      trn_year: props.allItem?.trn_year,
      trn_serial: props.allItem?.trn_serial,
      orphanID: props.allItem?.orphanID
    });
  }, [props]);
  useEffect(() => {
    if (
      props.userBag?.latePayments?.filter(
        (item) => item.trn_serial === props.allItem?.trn_serial
      ).length > 0
    ) {
      setAdded(true);
    } else {
      setAdded(false);
    }
  }, [props.allItem, props.userBag]);

  useEffect(() => {
    if (addToBag) {
      setAddingToBag(false);
    }
    return () => setAddingToBag(false);
  }, [props.allItem, props.userBag]);

  const handleAddLatePayment = async (item) => {
    if (props.late && added) {
      setAddingToBag(true);
      await props.handleRemoveFromBag(item);
    } else if (props.late && !added) {
      setAddingToBag(true);
      await props.handleaddToBag(item);
    } else {
      setOpenDialog(true);
    }
  };
  return (
    <div className="latePayment_page_component">
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px'
        }}
      >
        {props.img ? (
          <div style={{ margin: '0px 15px' }}>
            <img
              src={props.img}
              style={{ borderRadius: '15px' }}
              height="100px"
            />
          </div>
        ) : null}
        <div className="header_descr">
          <label style={{ fontWeight: 'bold' }}>
            {props.name} {t('needs your help')}.
          </label>

          {props.late ? (
            <label>
              {t('You havent made the payment to support him since')}{' '}
              <span className="payment_numbers">{`${
                props.date?.split('/')[0]
              }/${props.date?.split('/')[1]}/${
                props.date?.split('/')[2].split(' ')[0]
              }`}</span>
            </label>
          ) : (
            <React.Fragment>
              <label className={props.late ? 'any' : 'payment_numbers'}>
                {props.subTitle}
              </label>
            </React.Fragment>
          )}
          <label>
            {t('Total')}: {t('AED')}{' '}
            <span className="payment_numbers" style={{ fontWeight: 'bold' }}>
              {props.amount}
            </span>
          </label>
          {props.allItem?.gateway_error && (
            <label style={{ textAlign: 'start' }}>
              {t('Reason')}: {props.allItem?.gateway_error}
            </label>
          )}
        </div>
      </div>
      <div className="total_add_to_cart">
        <div className="btn_of_late_payment">
          <button
            disabled={addingToBag && props.late ? true : false}
            onClick={() => handleAddLatePayment(latePaymentData)}
            className="late_payment_add"
          >
            {addingToBag ? (
              <Loading
                height={'60px'}
                width={'60px'}
                small={'40px'}
                animate={true}
              />
            ) : (
              <img
                src={added ? addedBtn : addToBag}
                className="addBtn_img"
                alt="add_to_bag"
              />
            )}
          </button>
          {props.late && added ? (
            <label className="added_text_of_late_payment text_btn_toBeSmall">
              {t('Remove from Bag')}
            </label>
          ) : props.late && !added ? (
            <label className="added_text_of_late_payment text_btn_toBeSmall">
              {t('Add to Bag')}
            </label>
          ) : (
            <label className="added_text_of_late_payment text_btn_toBeSmall">
              {t('Add to Bag')}
            </label>
          )}
        </div>
      </div>
      <RenewelsDialog
        data={props.data}
        show={openDialog}
        onHide={() => {
          setOpenDialog(false);
        }}
        location={props.location}
      ></RenewelsDialog>
    </div>
  );
};

export default LatePyamentPageItem;
