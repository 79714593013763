import React from 'react'
import './CategoriesPage.css'
import donationPage from '../../assets/donationsPage_banner.jpg'
import projectsPage from '../../assets/projects.jpg'
import orphanPage from '../../assets/orphans.jpg'
import sukukPage from '../../assets/sukukForCategories.jpg'
import HumanPage from '../../assets/medical.jpg'
import { useTranslation } from "react-i18next";
import CategoryComp from './categoryComp/CategoryComp'
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader'



const CategoriesPage = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
            <ShareHeader noShare={true} pageName={t("Categories")} noBack={true}/>
            <div className="submit_requ_header">
                <h2>{t("Categories")}</h2>
            </div>
            <div id='categories_page' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <CategoryComp navigateTo='/humancases' categoryImg={HumanPage} categoryTitle={t('Humanitarian Cases')} />
                <CategoryComp navigateTo='/projects/all' categoryImg={projectsPage} categoryTitle={t('Charity projects')} />
                <CategoryComp navigateTo='/beasponser/all' categoryImg={orphanPage} categoryTitle={t('Sponsorships')} />
                <CategoryComp navigateTo='/donations' categoryImg={donationPage} categoryTitle={t('Donations')} />
                <CategoryComp navigateTo='/sukuk' categoryImg={sukukPage} categoryTitle={t('Sukuk')} />
            </div>
        </div>
    )
}

export default CategoriesPage
