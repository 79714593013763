
/* eslint-disable */
import React, { useState, useEffect } from "react";
import './FinalReciept.css'
import "../checkOutPage/CheckOutPage.css";
import BarCode from "../../assets/BarCode.png";
import RecieptLogo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getFinalReciept } from '../../api'
import LoadingModal from "../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { toastifyMessage } from "../../handlers/toastifyMessage";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import SuccessModal from "../projectShare/ProjectShareCreate/section/successModal/SuccessModal";
import ShareAlertModel from "../LoaclComponents/shareHeader/share_model/ShareModelAlert";
const FinalReciept = (props) => {
  const [showCopyCard, setshowCopyCard] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loadingModalShow, setLoadingModalShow] = useState(true);

  const [elements, setElemnts] = useState({});
  const [shareProject, setShareProject] = useState(null);
  const [success, setSeccess] = useState('');

  const finalRecieptDetails = useSelector(state => state.finalRecieptDetails)

  const { or } = useParams();

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = "/"
    }, 5000)

  }
  useEffect(() => {
    let mounted = true
    getFinalReciept(or, lng === 'arab' ? 1 : 2)
      .then((res) => {
        if (res.data.status) {
          if (mounted) {
            setLoadingModalShow(false)
        
            setElemnts(res.data.data)
            setSeccess(or)
            try
            {
              let oldProfile = JSON.parse(localStorage.getItem("profile"));
              if (oldProfile) {
                localStorage.setItem(
                  "profile",
                  JSON.stringify({
                    ...oldProfile,
                    cart: {
                      donations: [],
                      sukuks: [],
                      projects: [],
                      humanCases: [],
                      orphans: [],
                    },
                    liked: oldProfile.liked,
                  })
                );
              }
            }
            catch
            {

            }
          }
          
        } else {
          if (mounted) {
            setLoadingModalShow(false)
            //add error msg
            toastifyMessage("There is no order with this MCO", undefined, "error");
            redirectUser()
          }
          
        }
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          if (mounted) {
            setLoadingModalShow(false)
            toastifyMessage("Something went wrong...!", undefined, "error");
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setLoadingModalShow(false);
        }
      });
      return () => {
        mounted = false
      }
  }, [finalRecieptDetails, or]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
      if(elements?.items?.length === 1)
      {
        if(elements.items[0]?.share_id && elements.items[0]?.share_year)
        {
          setShareProject({projectShareID: elements.items[0]?.share_id, projectShareYear: elements.items[0]?.share_year})
          setShowSuccessModal(true)
        }
      }
    }, [elements])
  return (
    <div className="check_out_page" style={lng === "arab" ? { direction: "rtl", padding: width <= 900 ? '0px' : '10px' } : {padding: width <= 900 ? '0px' : '10px'}}>
      <div className="checkOut_right_side" style={{ width: width <= 1100 ? '100%' : '60%', gap:'10px',  paddingTop: width <= 900 ? '0px' : '10px' }}>
        <ShareHeader mco={true} noShare={true} pageName={or}/>
        <h5 className="title_to_be_remove" style={{ fontWeight: "bold" }}>{t("Donation Summary")}</h5>
        <div className="reciept">
          <img src={RecieptLogo} alt="ERC_logo" className="ERC_logo_for_reciept" />
          <div className="reciept_box_title">
            <label style={{ color: "#ee4236" }}>{t("Receipt")}</label>
          </div>
          <div className="userInfoDiv">
            <div className='userInfoDiv_rightSide'>
              <label className='label_of_title' style={{ color: "#ee4236" }}>{elements?.name}</label>
              <label id='EngF1' className='label_of_title' style={{ color: "#ee4236", fontFamily:'english_font' }}>{elements?.email}</label>
              <label id='EngF2' className='label_of_title' style={{ color: "#ee4236", fontFamily:'english_font' }}><span className='payment_numbers'>MCO:</span> {success}</label>
            </div>
            <div className='userInfoDiv_leftSide'>
              <label id='EngF3' className='label_of_title' style={{ color: "#ee4236", fontFamily:'english_font'}}>{elements?.date}</label>
              <label id='EngF4' className='label_of_title' style={{ color: "#ee4236", fontFamily:'english_font' }}>{elements?.mobile}</label>
            </div>
          </div>
          <table>
            <tbody>
                <tr style={{ textAlign: "center" }}>
                  <th className='label_of_title' style={{ padding: "10px" }}>{t("Type")}</th>
                  <th className='label_of_title' style={{ padding: "10px" }}>{t("Amount")}</th>
                </tr>
                {elements?.items?.map((item, i) =>
                <tr key={`service_${i}`} style={{ textAlign: "center" }}>
                  <td className='label_of_title' style={{ padding: "20px 10px 10px 10px" }}>{item?.name}</td>
                  <td className='label_of_title'  style={{ padding: "20px 10px 10px 10px" }}>{t("AED")} <span className='payment_numbers'>{item?.price}</span></td>
                </tr>
                )}
                <tr style={{ textAlign: "center" }}>
                  <td className='titleSmall_deviceSmall' style={{ padding: width>900? "20px":'10px', fontSize: "20px", fontWeight: "bold", }}>{t("Total")}</td>
                  <td className='titleSmall_deviceSmall' style={{ padding: width>900? "20px":'10px', fontSize: "20px", fontWeight: "bold", }}>{t("AED")} <span className='payment_numbers'>{elements?.total}</span></td>
                </tr>
                </tbody>
          </table>
          <div className="reciept_barCode_wraper">
            <img className="barCode" src={BarCode} alt="bar_code" />
          </div>
        </div>
      </div>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <SuccessModal
            setshowCopyCard={setshowCopyCard}
            dialogClassName="modal-60w"
            onHide={() => {
              setShowSuccessModal(false)
            }} show={showSuccessModal} />
            <ShareAlertModel
          path="projectshare"
          shareData={shareProject}
          show={showCopyCard}
          onHide={() => {
            setshowCopyCard(false);
          }}
        ></ShareAlertModel>
    </div>
  );
};

export default FinalReciept;
