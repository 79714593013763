import { FETCH_ALL_PROJECT_SHARES, PROJECT_SHARES_LOADED, LOAD_PROJECT_SHARES } from '../constants/actionTypes'

export default (projectShares = { projectShares: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_PROJECT_SHARES:
            return { ...projectShares, projectShares: action.payload, loading: false };
        case LOAD_PROJECT_SHARES:
            return { ...projectShares, loading: true };
        case PROJECT_SHARES_LOADED:
            return { ...projectShares, loading: false };
        default:
            return projectShares;
    }
}