import React, {useState, useEffect, useRef} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import { useDispatch } from 'react-redux'
import SwiperCore, {Navigation} from 'swiper'
import 'swiper/swiper-bundle.css'
import SukukCom from '../../homePage/sections/sukuk/sukukCom/sukukCom'
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag, handleAddToBag } from '../../../actions/BagFav'
import { addToFavorites, deleteFromFavorites } from '../../../actions/BagFav'
import user from '../../../reducers/user'
import { FETCH_LOCALLY } from '../../../constants/actionTypes'
import { useSelector } from 'react-redux'
import { toastifyMessage } from '../../../handlers/toastifyMessage';

const SukukSection = (props) => {
    const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const [change, setChange] = useState(0)
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    const [donationFav, setDonationFav] = useState([])
    useEffect(() => {
        if (userLogin) {
            //let sukukAll = props.favoritesDetails?.donations?.filter(outerItem=> props.favoritesDetails?.sukuks?.findIndex(item=> item.dontypeID===outerItem.dontypeID))
            setDonationFav(props.favoritesDetails?.sukuks)
        } else {
            setDonationFav(currentProfile?.liked?.sukuks)
        }
      }, [props.favoritesDetails?.sukuks, fetchLoaclly]);
    const [view, setView] = useState(5)
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(()=> {
        if (window.innerWidth > 1860 ) {
            setView(2.5)
        } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
            setView(2)
        } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
            setView(1.6)
        } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
            setView(1.1)
        } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
            setView(1.2)
        } else {
            setView(1.01)
        }
      }, [width])
      const handleLike = async (sukukID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile.liked.sukuks.push(sukukID)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: sukukID.sukId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
        }
    }
    const handleDisLike = async (sukukID) => {
        if (!userLogin) {
            let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations, sukuks: currentProfile.liked.sukuks.filter(item => item.sukId !== sukukID.sukId), projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans } }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            let elementWeNeed = donationFav?.filter(item => item.dontypeID === sukukID.sukId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }
    }

    const handleaddToBag = async (sukukID, setAddingToCart, setAnimationClass, setInTheBagText) => {
        setAddingToCart(true);
        await handleAddToBag("sukuks", sukukID, setChange, change, dispatch)
        if (_isMounted.current) {
         setAnimationClass("btn_with_text animate__animated animate__bounceOut")
        setTimeout(() => {
            if (_isMounted.current) {
               setAnimationClass("btn_with_text animate__animated animate__bounceIn")
               setAddingToCart(false);
               setInTheBagText("Add to Bag"); 
            }
        }, 1000);
         setTimeout(() => {
             if (_isMounted.current) {
              setAnimationClass("btn_with_text");   
             }
         }, 2000);   
        }
        

    }
    // const handleRemoveFromBag = (sukukID) => {
    //     let newProfile = {cart: {donations: currentProfile.cart.donations, sukuks: currentProfile.cart.sukuks.filter(item=> item.sukId!==sukukID.sukId), projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans}, liked: currentProfile.liked}
    //     localStorage.setItem('profile', JSON.stringify(newProfile))
    //     setChange(change+1)
    // }
    return (
        <div className='liked_donation_section'>
            <h4 className='donation_favorites_title'>{t("Sukuk")}</h4>
            <hr className='hr'></hr>
            {donationFav?.length > 0 ? 
            <React.Fragment>
                    <Swiper loop={donationFav?.length > view? true:false} slidesPerView={view} spaceBetween={20} navigation={true}>
                        {donationFav?.map((item,i)=> 
                            <SwiperSlide key={i}>
                                <SukukCom 
                                    donationID={userLogin? item.dontypeID:item.sukId} 
                                    donationFreq = {userLogin? 'N':item.donationFreq}
                                    recurring={item.recurring}
                                    handleaddToBag={handleaddToBag} 
                                    handleLike={handleLike} 
                                    handleDisLike={handleDisLike} 
                                    currentProfile={currentProfile}  
                                    title={userLogin? item.name:item.title}
                                    sukukImg={userLogin? item.imagePath:item.img} 
                                    amount={userLogin? item.baseAmount:item.amount}/>
                            </SwiperSlide>
                            )}
                    </Swiper>
                </React.Fragment>
                                :
                                <div style={{textAlign:'center',marginTop:'50px', minHeight: '100px'}}>
                                    <h5>
                                    {t("No Favorites Found")}
                                    </h5>
                                </div> 
                                }
            </div>
    )
}

export default SukukSection
