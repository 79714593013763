import { APIForVolunteerV3V2 } from ".";

export const createNewLocalAID = (data) =>
  APIForVolunteerV3V2.get(`local_aid_create_file`, {
    params: data,
  });

export const localAIDAddFamily = (data) =>
  APIForVolunteerV3V2.get(`insert_filefamily`, {
    params: data,
  });
export const searchLocalAID = (email, eid) =>
  APIForVolunteerV3V2.get(`link_local_aid_request`, {
    params: {
      account_link_email: email,
      local_aid_EID: eid,
    },
  });
export const linkLocalAIDOTP = (email, lid) =>
  APIForVolunteerV3V2.get(`link_local_aid_request`, {
    params: {
      account_link_email: email,
      person_id: lid,
      otp_type: "MOBILE",
    },
  });
export const linkLocalAIDConfirm = (email, lid, otp) =>
  APIForVolunteerV3V2.get(`confirm_otp_local_aid_link`, {
    params: {
      account_link_email: email,
      person_id: lid,
      otp,
    },
  });

export const getLocalAidIdentityType = (lang) =>
  APIForVolunteerV3V2.get(`local_aid_identity_types`, {
    params: {
      language: lang,
    },
  });
export const addLocalAidIdentity = (data) =>
  APIForVolunteerV3V2.get(`aid_request_add_identity`, {
    params: {
      language: data.lang,
      req_id: data.reqId,
      req_year: data.reqYear,
      identype_id: data.identType,
      iden_no: data.identityNo,
      issue_place: data.issuePlace,
      issue_date: data.issueDate,
      exp_date: data.expiryDate,
      remarks: data.remarks,
    },
  });

export const addLocalAidRelation = (data) =>
  APIForVolunteerV3V2.get(`insert_filefamily`, {
    params:
      data
    ,
  });


export const addLocalAidRelativeAddress = (data) =>
  APIForVolunteerV3V2.get(`local_aid_address`, {
    params:
      data
    ,
  });

  export const completeLocalAid = (data) =>
  APIForVolunteerV3V2.get(`local_aid_complete_request`, {
    params:
      data
    ,
  });

  export const checkLocalAidOpen = (data) =>
  APIForVolunteerV3V2.get(`local_aid_registration_status`);



// export const submitLocalAidRequest = (data) => APIForVolunteerV3V2.get(`INSERT_HLPREQST`, {
//   params: {
//     P_PERSON_ID: data.personId,
//     P_REQ_DESC: data.details,

//     P_AIDTYPE_ID: data.type,
//   },
// });