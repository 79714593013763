import React from "react";
import goo from "../../../assets/google.svg";
import { GoogleLogin } from "react-google-login";
// refresh token
import { refreshTokenSetup } from "../../../utils/refreshToken";
import { useDispatch } from "react-redux";
import { signInWithGoogle } from "../../../actions/user";
import { toastifyMessage } from '../../../handlers/toastifyMessage';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function SignInWithGoogle(props) {
  const dispatch = useDispatch();
  const onSuccess = (res) => {
    console.log("res", res.accessToken);
    refreshTokenSetup(res);
    dispatch(signInWithGoogle(res.accessToken,  res.profileObj, props.history, props.setLoadingModalShow, props.fromState, props.completeInfo, props.lodaingSwitch, props.setWait));
  };

  const onFailure = (res) => {
    if(res.error === "popup_closed_by_user")
    {
      toastifyMessage(`Login Failed: Pop-up closed by user!`, undefined, "error");
      props.setLoadingModalShow(false)
    }
  };

  return (
    <GoogleLogin
    uxMode={'popup'}
      clientId={clientId}
      render={(renderProps) => (
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={() => { renderProps.onClick(); props.setLoadingModalShow(true) }}
          disabled={renderProps.disabled}
        >
          <img
            src={goo}
            alt="google_logo"
            className="login_sign_social_logos"
          />
        </button >
      )
      }
      // redirectUri='https://ercweb.mvp-apps.ae/auth'
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={(e) => onFailure(e)}
      cookiePolicy={"single_host_origin"}
      style={{ marginTop: "100px" }}
      isSignedIn={false}
      prompt="select_account consent"
    />
  );
}

export default SignInWithGoogle;
