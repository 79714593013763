import React, { useState, useEffect, useRef } from 'react'
import './OrphanItemBag.css'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { addToBag, deleteFromBag } from '../../../../actions/BagFav'
import Loading from '../../../ui/loading';
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import { FETCH_LOCALLY } from '../../../../constants/actionTypes';

const styleForWraper = {
    filter: 'blur(8px)',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const bigWraperStyle = {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const styleForNotAv = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2000'
}
const styleForXBtn = {
    color: 'white'
}
const OrphanItemBag = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const [addingToCart, setAddingToCart] = useState(false);

    const dispatch = useDispatch()
    const [orphanItemOnBagDetails, setOrphanItemBagDetails] = useState({
        donationAmountFromUser: props.donationAmountFromUser,
        orphanCountryId: props.orphanCountryId,
        orphanCountryImg: props.orphanCountryImg,
        orphanCountryName: props.orphanCountryName,
        orphanId: props.orphanId,
        orphanImg: props.orphanImg,
        orphanName: props.orphanName,
        paymentWay: props.paymentWay,
        baseAmount: props.baseAmount,
        years: props.years,
        //branch id 1(for now)
    })
    useEffect(() => {
        setOrphanItemBagDetails({
            donationAmountFromUser: props.donationAmountFromUser,
            orphanCountryId: props.orphanCountryId,
            orphanCountryImg: props.orphanCountryImg,
            orphanCountryName: props.orphanCountryName,
            orphanId: props.orphanId,
            orphanImg: props.orphanImg,
            orphanName: props.orphanName,
            paymentWay: props.paymentWay,
            baseAmount: props.baseAmount,
            years: props.years,
        })
    }, [props.orphanId, props.donationAmountFromUser])
    const [addingToBag, setAddingToBag] = useState(false);
    const handleInBag = async (str) => {
        setAddingToCart(true);
        if (!userLogin) {
            let newProfile = {
                cart: {
                    donations: props.currentProfile.cart.donations,
                    sukuks: props.currentProfile.cart.sukuks,
                    projects: props.currentProfile.cart.projects,
                    humanCases: props.currentProfile.cart.humanCases,
                    orphans: props.currentProfile.cart.orphans.filter(item => !( item.years === str.years && item.orphanId === str.orphanId && item.orphanCountryId === str.orphanCountryId && parseInt(item.baseAmount)=== parseInt(str.baseAmount) && item.paymentWay===str.paymentWay ))
                },
                liked: props.currentProfile.liked
            }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            toastifyMessage("Removed!", undefined, "success");
            if (_isMounted.current) {
              props.setChange(props.change + 1)  
            }
        } else {
                await dispatch(deleteFromBag(userLogin.Results[0]?.email, str.cart_serial, lng === "eng" ? 2 : 1, ""))
        }
        if (_isMounted.current) {
          setAddingToCart(false);  
        }
    }

    const handleClick = async (op) => {
        setAddingToCart(true);
        if (op === '-') {
                if (!userLogin) {
                    let copyOf = props.currentProfile.cart.orphans.slice(0);
                    copyOf.splice(copyOf.findIndex(item => item.orphanId === props.orphanId && item.orphanCountryId === props.orphanCountryId && parseInt(item.baseAmount)=== parseInt(props.baseAmount) && item.paymentWay===props.paymentWay && item.years === props.years), 1)
                    let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: copyOf }, liked: props.currentProfile.liked }
                    const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                    localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                    await dispatch({type: FETCH_LOCALLY})
                    toastifyMessage("Modified!", undefined, "success");
                    if (_isMounted.current) {
                        props.setChange(props.change + 1)  
                      }
                } else {
                    if (props.quantity>1) {
                        await dispatch(addToBag({
                        cart: {
                            userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity - 1
                        }, language: lng === 'arab' ? 1 : 2
                    }, false, 'justEdit'))
                    if (_isMounted.current) {
                        props.setChange(props.change + 1)  
                      }
                    } else {
                        await dispatch(deleteFromBag(userLogin.Results[0]?.email, props.cart_serial, lng === "eng" ? 2 : 1, ""))
                    }
                    

                }
        } else {
            if (!userLogin) {
                let copyOf = props.currentProfile.cart.orphans.slice(0);
                copyOf.splice(copyOf.findIndex(item => item.orphanId === props.orphanId && item.orphanCountryId === props.orphanCountryId && parseInt(item.baseAmount)=== parseInt(props.baseAmount) && item.paymentWay===props.paymentWay && item.years === props.years), 0,
                {...orphanItemOnBagDetails, donationAmountFromUser: props.paymentWay === "N"? parseInt(props.baseAmount) * props.years * 12: parseInt(props.baseAmount), dateOfAdding: new Date().getTime()})
                let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: copyOf }, liked: props.currentProfile.liked }
                const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                await dispatch({type: FETCH_LOCALLY})
                toastifyMessage("Modified!", undefined, "success");
                if (_isMounted.current) {
                    props.setChange(props.change + 1)  
                  }
            } else {
                await dispatch(addToBag({
                    cart: {
                        userEmail: userLogin.Results[0]?.email, cart_serial: props.cart_serial, quantity: props.quantity + 1
                    }, language: lng === 'arab' ? 1 : 2
                }, false, 'justEdit'))
                if (_isMounted.current) {
                    props.setChange(props.change + 1)  
                  }
            }
        }
        if (_isMounted.current) {
            setAddingToCart(false);
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <div className='big_wraper_bag' style={props.status === 'not' ? bigWraperStyle : { width: '100%' }}>
            <button disabled={addingToCart} style={lng === 'arab' ? { right: 'unset', left: '10px', color: 'white' } : { color: 'white' }} onClick={() => handleInBag({ orphanId: props.orphanId, orphanCountryId: props.orphanCountryId, cart_serial: props.cart_serial, baseAmount: props.baseAmount, paymentWay: props.paymentWay, years: props.years })} className='removeBtn_from_bag'><i className="fas fa-times"></i></button>
            <div style={{position:'absolute', top:'0px', left: '0px', right:'0px', bottom:'0px', display: addingToCart? 'block':'none'}}>
                <Loading height={'100%'} width={'100%'}/>
            </div>
            <div className={addingToCart ? 'bag_item_com disable_page' : 'bag_item_com'} style={props.status === 'not' ? styleForWraper : null}>
                <img src={props.orphanImg} alt='item_img' className='bag_item_img_projects' />
                <div className='project_Details_bag'>
                    {lng === 'arab' ?
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'right' } : { fontWeight: 'bold', textAlign: 'right' }}>{t("Support")} {props.orphanName}</label> :
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'left' } : { fontWeight: 'bold', textAlign: 'left' }}>{t("Support")} {props.orphanName}</label>

                    }
                    <div className='country_img_wraper_Bag'>
                        <img style={width <= 1150 ? { height: '20px', width: '20px' } : { height: '20px', width: '20px' }} src={props.orphanCountryImg} className='country_Img_Bag' alt='country_img' />
                        <label style={width <= 1150 ? { fontSize: '13px' } : null}>{props.orphanCountryName.length > 8 ? props.orphanCountryName.slice(0, 7) + '.' : props.orphanCountryName}</label>
                    </div>
                    <label style={{fontSize: width<=1150? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}>{t("Payment")}: {props.paymentWay === 'N' ? t("One Time") : t("Monthly")} | {t("Quantity")}: <span className='payment_numbers'>{props.quantity} {props.paymentWay==='N'? '|': null}</span> {props.paymentWay==='N'? t("Months"):null}{props.paymentWay==='N'? ':': null} <span className='payment_numbers'>{props.paymentWay==='N'?  userLogin? props.years:props.years * 12:null}</span></label>
                </div>
                <div className='donation_inc_Dec_bag'>
                    <button disabled={addingToCart || props.status === 'not'} className='inc' onClick={() => handleClick('-')}>-</button>
                        <label className='label_to_beSmall payment_numbers' style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold', minHeight:'unset'}}>{t("AED")} {props.donationAmountFromUser}</label>
                    <button disabled={addingToCart || props.status === 'not'} className='dec' onClick={() => handleClick('+')}>+</button>
                </div>
                
            </div>
            <label style={props.status === 'not' ? styleForNotAv : { display: 'none' }}>{t("Not available")}</label>
        </div>
    )
}

export default OrphanItemBag