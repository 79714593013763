import { CREATE_USER } from '../constants/actionTypes'

export default (users = [], action) => {
    switch (action.type) {
        case CREATE_USER:
            return [...users, action.payload];    
        default:
            return users;
    }
}
