import { FETCH_ALL_TUTORIALS} from "../../constants/actionTypes";

export default (tutorials = {
    allTutorials: []
}, action) => {
    switch (action.type) {
        case FETCH_ALL_TUTORIALS:
            return {...tutorials, allTutorials: action.payload};
        default:
            return tutorials;
    }
}