import React, { useEffect, useState, useRef } from "react";
import "./EditProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

import userPlaceholer from "../../../assets/user-placeholder.jpeg";

import DatePickerModal from "./DatePickerModal";
import { editProfile, uploadProfilePic } from "../../../api/account";

// import LoadingModal from "./loadingPopUp/loadingPopUp";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import OTPModalPhone from "./OTP_MODEL_PHONE";
import { Spinner } from "react-bootstrap";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";

const EditProfile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imageLoading, setImageLoading] = useState(false);
  

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [user, setUser] = useState(userLogin?.Results[0]);
  const [change, setChange] = useState(0);
  useEffect(() => {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    setUser(userLogin?.Results[0])
  }, [change]);
  //States
  const [username, setUsername] = useState(user?.name_a || '');
  const [emiratesId, setEmiratesId] = useState(user?.emiratesID);
  const [mobileNumber, setMobileNumber] = useState(user?.mobile_no);
  const [selectedDate, setSelectedDate] = useState(user?.DOB? user?.DOB.split('/').reverse().join('-'):'');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [emiratesIdError, setEmiratesIdError] = useState("");
  const [loadingModalShow, setloadingModalShow] = useState(false);
  const isSafari = window.safari !== undefined;

  const editProfileImgRef = useRef(null);

  useEffect(() => {
    setUsername(user?.name_a || '');
    setEmiratesId(user?.emiratesID);
    setMobileNumber(user?.mobile_no);
    setSelectedDate(user?.DOB? user?.DOB.split('/').reverse().join('-'):'');
  }, [user]);

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const logOut = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  // const getBase64 = (file) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //   };
  //   reader.onerror = function (error) {
  //   };
  // };

  const handleSubmit = () => {
    //TODO: CHange mobile number will be in another page

    // if (!mobileNumber.startsWith("05")) {
    //   setMobileError(`${t("Phone numbers should start with 05.")}`);
    //   setTimeout(() => {
    //     setMobileError("");
    //   }, 1000);
    // }
    // if (emiratesId == null) {
    //   setEmiratesIdError(`${t("Emirates ID cannot be null")}`);
    //   return setTimeout(() => {
    //     setEmiratesIdError("");
    //   }, 1000);
    // } else if (emiratesId.length != "15") {
    //   setEmiratesIdError(`${t("Emirates ID has to be 15 digits long")}`);
    //   return setTimeout(() => {
    //     setEmiratesIdError("");
    //   }, 1000);
    // }
    // var DOB;
    // if (new Date(selectedDate) != "Invalid Date") {
    //   DOB = new Date(selectedDate).toLocaleDateString("en-GB", {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //   });
    // }
    var data
    if (props.fromCompleteModal) {
      if (emiratesId?.length != 15) {
        return toastifyMessage("Emirates ID has to be 15 digits long", undefined, "warning");
      } else if (!emiratesId) {
        return toastifyMessage("Emirates ID cannot be null", undefined, "warning");
      } else if (!mobileNumber) {
        return toastifyMessage("Please enter your number", undefined, "warning");
      } else if (!username) {
        return toastifyMessage("Please enter your name", undefined, "warning");
      }  else {
            if (userLogin.Results[0]?.emiratesID) {
                data = {
                  user_email: user.email,
                  new_userName: username,
                  new_DOB: selectedDate.split('-').reverse().join('/'),
                  new_EID: '',
                };
            } else {
                  data = {
                  user_email: user.email,
                  new_userName: username,
                  new_DOB: selectedDate.split('-').reverse().join('/'),
                  new_EID: emiratesId,
                };
            }
      }
    } else {
      if (emiratesId?.length != 15) {
        return toastifyMessage("\"Emirates ID\" number should be 15 digits long", undefined, "warning");
      } else if (!emiratesId) {
        return toastifyMessage("Emirates ID cannot be null", undefined, "warning");
      } else if (selectedDate.split('-')[0].length>4 || parseInt(selectedDate.split('-')[0]) <  1850) {
        return toastifyMessage("Please choose a valid date", undefined, "warning");
      } else if (!mobileNumber) {
        return toastifyMessage("Please enter your number", undefined, "warning");
      } else if (!username) {
        return toastifyMessage("Please enter your name", undefined, "warning");
      } else {
        if (userLogin.Results[0]?.emiratesID) {
          data = {
            user_email: user.email,
            new_userName: username,
            new_DOB: selectedDate.split('-').reverse().join('/'),
            new_EID: '',
          };
      } else {
            data = {
            user_email: user.email,
            new_userName: username,
            new_DOB: selectedDate.split('-').reverse().join('/'),
            new_EID: emiratesId,
          };
      }
      }
    }
    setloadingModalShow(true);
    editProfile(data)
      .then((res) => {
        if (res.data.status) {
          var newUserData = {
          Results: res.data.data,
          };
          if(props?.usertype)
          {
            let res = { Results: [{...newUserData.Results[0], login_type: "UAEPASS"}] };
            localStorage.setItem("userLogin", JSON.stringify(res));
          }
          else
          {
            localStorage.setItem("userLogin", JSON.stringify(newUserData));

          }
          if (props.fromCompleteModal) {
            window.open(props.lodaingSwitch.goTo, '_self')
          } else {
            window.location.reload();
          }
        } else {
          toastifyMessage(res.data.result, undefined, "error");
        }
        
      }) .catch((err)=> {
        setloadingModalShow(false);
        toastifyMessage("Something went wrong...!", undefined, "error");

      })
      .finally(() => {
        setloadingModalShow(false);
      });

  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "emiratesId":
        setEmiratesId(e.target.value);
        break;
      case "number":
        setMobileNumber(e.target.value.replace(/\D/, ""));
        break;
      default:
        break;
    }
  };

  const handleImageChange = () => {
    var imgInput = document.getElementById("editProfileImgInput");
    var img = document.getElementById("editProfileImgID");
    var reader = new FileReader();
    var file = imgInput.files[0];
    // getBase64(file);

    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      // Handle case where the file has an invalid extension
      toastifyMessage("Invalid file type. Only JPG, JPEG, and PNG are allowed.", undefined, "warning");
      return;
    }
    reader.onloadend = (function (file) {
      return function () {
        setImageLoading(true);
        const correctBase64 =
          reader.result.split(",")[reader.result.split(",").length - 1];
        const imageType = reader.result
          .split(",")[0]
          .split("/")
          [reader.result.split(",")[0].split("/").length - 1].split(";")[0];
        // .split("/")
        var dataToSend = {
          userID: user.email,
          picture: {
            pic_type: imageType,
            profile_pic64: correctBase64,
          },
        };
        uploadProfilePic(dataToSend)
          .then((res) => {
            img.src = /^image/.test(file.type)
              ? this.result
              : "http://i.stack.imgur.com/t9QlH.png";
          }).catch((err)=> {
            toastifyMessage(err?.response?.data?.result, undefined, "error");
          })
          .finally(() => {
            setImageLoading(false);
          });
      };
    })(file);
    reader.readAsDataURL(file);
  };

  const [showOTPModal, setShowOTPModal] = useState(false);

  const showOTPHandler = () => {
    setShowOTPModal(true);
  };

  if (userInfo) {
    return (
      <div className="media" style={props.fromCompleteModal? {height:'100%', minHeight:'unset', backgroundColor: '#fff', borderBottomLeftRadius: '15px', borderBottomRightRadius:'15px', padding:'5px'}:null}>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
        {showOTPModal ? (
          <OTPModalPhone
            show={showOTPModal}
            setChange={setChange}
            change={change}
            fromCompleteModal={props.fromCompleteModal}
            onHide={() => setShowOTPModal(false)}
          />
        ) : (
          <></>
        )}
        {props.fromCompleteModal?
        null:
          <ShareHeader noShare={true} pageName={t("Edit Profile")}/>}
          {props.fromCompleteModal?
        null:
        <div className="submit_requ_header">
          <h3 style={{ color: "gray" }}>{t("Edit Profile")}</h3>
        </div>
      }
        <div className="editProfileUserDetails" style={{width:'100%', direction:lng==='arab'? 'rtl':'ltr', display:props.fromCompleteModal?"flex":null, flexDirection: props.fromCompleteModal?"column":null, alignItems: props.fromCompleteModal? 'center':null, justifyContent:props.fromCompleteModal?'center':null, gap: '5px'}}>
          <div
            className="editProfileImgDiv"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <input
              type="file"
              accept="image/*"
              id="editProfileImgInput"
              ref={editProfileImgRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <div
              className="editProfileImgHolder"
              onClick={() => {
                editProfileImgRef.current.click();
              }}
            >
              {imageLoading ? (
                <div
                  style={{
                    width: props.fromCompleteModal? "150px":'200px',
                    height: props.fromCompleteModal? "150px":"200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                <></>
              )}
              <img
                id="editProfileImgID"
                src={user?.profile_pic ? user.profile_pic : userPlaceholer}
                style={{
                  display: imageLoading ? "none" : "block",
                  height: props.fromCompleteModal? "150px":"200px",
                  width: props.fromCompleteModal? "150px":"200px",
                  objectFit: "cover",
                  objectPosition:'top center',
                  borderRadius: "10px",
                }}
                className="editProfileImg"
              />
              <div className="uploadEditProfileDiv">{t("Edit Profile")}</div>
            </div>
          </div>
          <h4
            className="editProfileUsername"
            style={{
              direction: lng == "arab" ? "rtl" : "ltr",
            }}
          >
            {t("Hi")} {user?.name_a}
          </h4>
          <div className="editProfileCards">
          {props.fromCompleteModal?
          user?.name_a?
           null:
            <div className="editProfileCard editProfileName"
            style={{width: props.fromCompleteModal? "100%":null}}
            >
              <input
                type="text"
                name="username"
                placeholder={t("Please enter your name")}
                value={username}
                onChange={handleChange}
                className={lng==='arab'? 'force_arabic_font':'payment_numbers'}
                required
              />
              <i className="fas fa-edit editProfileIcon"></i>
            </div>
            :
            <div className="editProfileCard editProfileName">
              <input
                type="text"
                name="username"
                placeholder={t("Please enter your name")}
                className={lng==='arab'? 'force_arabic_font':'payment_numbers'}
                value={username}
                onChange={handleChange}
                required
              />
              <i className="fas fa-edit editProfileIcon"></i>
            </div>
            }
            {props.fromCompleteModal?
           null:
            <div
              className="editProfileCard editProfileDOB"
              style={{direction: lng == "arab" ? "rtl" : "ltr"}}
            >
              <input
              style={{width:'100%'}}
                type="date"
                onKeyDown={(e) => {
                  e.preventDefault();
               }}
                min={`${new Date().getFullYear() - 100}-01-02`}
                 max={`${new Date().getFullYear() - 18}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
                value={selectedDate}
                onChange={(e)=> setSelectedDate(e.target.value)}
                required
              />
            </div>}
            <div
              className="editProfileCard editProfileEmiratesId"
              style={{
                background: emiratesIdError ? "#ff7474" : "transparent",
                transition: "all 1s", width: props.fromCompleteModal? "100%":null
              }}
            >
              {!user?.emiratesID ? (
                <>
                  <input
                    type="text"
                    name="emiratesId"
                    value={emiratesId}
                    onChange={handleChange}
                    placeholder={t("Enter your emirates ID")}
                    style={{
                      background: "transparent"
                    }}
                    maxLength="15"
                    className={emiratesId? 'payment_numbers': lng==='arab'? 'force_arabic_font':'payment_numbers'}
                  />
                  <i className="fas fa-edit editProfileIcon"></i>
                </>
              ) : (
                <span
                className='payment_numbers'
                  style={{
                    color: "#a2a2a2",
                  }}
                >
                  {user?.emiratesID}
                </span>
              )}
            </div>
            {props.fromCompleteModal?
            null:
            <div className="editProfileCard editProfileEmail">
              <span
                style={{
                  color: "#a2a2a2",
                  fontFamily:'english_font'
                }}
                className='payment_numbers'
              >
                {user.email}
              </span>
              {/* <i className="fas fa-edit editProfileIcon"></i> */}
            </div>}
            <div
              className="editProfileCard editProfileMobile"
              style={{
                // background: mobileError ? "#ff7474" : "transparent",
                // transition: "all 1s",
                cursor: "pointer", width: props.fromCompleteModal? "100%":null
              }}
              onClick={showOTPHandler}
            >
              <span className={user?.mobile_no? 'payment_numbers':null} style={{color:'gray'}}>{user?.mobile_no? user?.mobile_no: props.fromCompleteModal? t("Enter your mobile number"):t("Change mobile number")}</span>
              {/* <input
                type="text"
                name="number"
                value={mobileNumber}
                onChange={handleChange}
                placeholder={t("Enter your mobile number")}
                maxLength="10"
                style={{
                  background: "transparent",
                }}
                required
              /> */}
              <i className="fas fa-edit editProfileIcon"></i>
            </div>
            {props.fromCompleteModal?
             null:
            <div className="editProfileCard editProfileAccountID">
              <span>{t("Sponsor ID")}</span>
              {user?.sponser_id ? (
                <span className='payment_numbers'>{user?.sponser_id}</span>
              ) : (
                <span>{t("N/A")}</span>
              )}
            </div>}
            {props.fromCompleteModal?
            null:
            <div className="editProfileCard editProfileVolunteerID">
              <span>{t("Volunteer ID")}</span>
              {user?.vln_id && user?.vln_id_flag === "2" ? (
                <span className='payment_numbers'>{user?.vln_id}</span>
              ) : (
                <span>{t("N/A")}</span>
              )}
            </div>}
            <div style={{ height: props.fromCompleteModal? "40px":null, width:props.fromCompleteModal? "80px":null, alignSelf: props.fromCompleteModal? 'flex-end':null}} className="editProfileSaveBtn" onClick={handleSubmit}>
              {t("Save")}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    window.location.replace("/");
  }
};

export default EditProfile;
