import React, { useState, useEffect, useRef } from "react";
import "./LatePaymentItem.css";
import addToBag from "../../../../assets/donateicon.png";
import addedBtn from "../../../../assets/addedBtn.png";
import { useTranslation } from "react-i18next";
import Loading from "../../../ui/loading";

const LatePaymentItem = (props) => {
  const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
  const [latePaymentItem, setLatePaymentItem] = useState(props.latePay)
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [latePaymentData, setlatePaymentData] = useState({
    name: props.allItem?.orphan_name,
    trn_amount: props.allItem?.trn_amount,
    trn_year: props.allItem?.trn_year,
    trn_serial: props.allItem?.trn_serial,
    orphanID: props.allItem?.orphanID
  })
  useEffect(() => {
    setlatePaymentData({
      name: props.allItem?.orphan_name,
      trn_amount: props.allItem?.trn_amount,
      trn_year: props.allItem?.trn_year,
      trn_serial: props.allItem?.trn_serial,
      orphanID: props.allItem?.orphanID
    })
  }, [props])
  const [added, setAdded] = useState(false);
  useEffect(() => {
    setLatePaymentItem(props.latePay)
  }, [props.latePay])
  const [addingToBag, setAddingToBag] = useState(false);
  const handleAddLatePaymentToBag = async (theItem) => {
    if (_isMounted.current) {
      setAddingToBag(true)
    }
    await props.handleaddToBag(theItem)
    if (_isMounted.current) {
      setAddingToBag(false)
    }
  }
  const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  return (
    <div className="latePayment_page_component">
      <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
        <img src={latePaymentItem?.imagePath || props.lateImg} alt='late_payment_display_img' className='bag_item_img'/>
        <div className="header_descr">
        {lng === "arab" ? (
            <label
              style={
                width <= 1150
                  ? { fontSize: "13px", fontWeight: "bold", textAlign: "right" }
                  : { fontWeight: "bold", textAlign: "right" }
              }
            >
              {props.allItem?.orphan_name.split(" ")[0]}{" "}
              {t("needs your help")}
            </label>
          ) : (
            <label
              style={
                width <= 1150
                  ? { fontSize: "13px", fontWeight: "bold", textAlign: "left" }
                  : { fontWeight: "bold", textAlign: "left" }
              }
            >
              {props.allItem?.orphan_name.split(" ")[0]}{" "}
              {t("needs your help")}
            </label>
          )}
        
        {props.late ? (
          <label
          style={{fontSize: width<=1150? '11px':'13px', textAlign: lng==='arab'? 'right':'left' }}
          
          >{t("You havent made the payment to support him since")} <span className='payment_numbers'>{`${props.date?.split("/")[0]}/${props.date?.split("/")[1]}/${props.date?.split("/")[2].split(" ")[0]}`}</span></label>
        ):null}
          <label>{t("Total")}: {t("AED")}{" "}<span className='payment_numbers' style={{ fontWeight: "bold" }}>{props.amount}</span></label>
          <label style={{textAlign:'start'}}>{t("Reason")}: {props.allItem?.gateway_error}</label>
      </div>
      </div>
      <div className="total_add_to_cart">
        
        <div className="btn_of_late_payment">
          <button disabled={addingToBag && props.late ? true : false} onClick={() => handleAddLatePaymentToBag(latePaymentData)} className="late_payment_add">
          {addingToBag?
                <Loading height={'60px'} width={'60px'} small={'40px'} animate={true}/>:
            <img src={addToBag} className="addBtn_img" alt="add_to_bag" />}
          </button>
          <label className="added_text_of_late_payment text_btn_toBeSmall">
              {t("Add to Bag")}
          </label>
        </div>
      </div>
      <div className="btn_of_late_payment">
        
      </div>
    </div>
  );
};

export default LatePaymentItem;
