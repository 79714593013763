import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next";

const DeleteAllConfirmation = (props) => {
  const { removeall, deletingall, ...rest } = props
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;

  return (
    <Modal
    {...rest}
    size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='donation_pop_up_body'
    >
      <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px', fontFamily: lng==='arab'? 'arab_font':'english_font' }} >
        <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
          <label style={{textAlign:'center'}}>{t("Are you sure you want to remove all contents of the bag?")}</label>
          <div className='donation_final_popUp_btns_wraper'>
            <button disabled={deletingall} onClick={() =>removeall()} className='donation_final_popUp_btn_second'>
              {deletingall? t("Loading..."):t("Yes")}
            </button>
            <button disabled={deletingall} onClick={() => props.onHide()} className='donation_final_popUp_btn_first'>
              {t("Back")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteAllConfirmation
