import { toastifyMessage } from '../../../handlers/toastifyMessage';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export const logOutFromFB = () => {
    (function ($) {
        $(document).bind('FBSDKLoaded', function () {
            window.FB.getLoginStatus(function (response) {
                try {
                    if (response.status === "connected") {
                        window.FB.api("/me/permissions", "delete", null);
                        window.FB.logout(function (response) {
                            // window.FB.Auth.setAuthResponse(null, 'unknown');
                        });
                        // window.FB.login(function (response) {
                        //     // Original FB.login code
                        // }, { auth_type: 'reauthenticate' })
                    }
                    else {
                        // sessionStorage.removeItem(`fbssls_${facebookAppId}`);
                    }
                } catch (error) {
                    toastifyMessage("Something went wrong...!", undefined, "error");
                }
            }
            )
        });
    })(jQuery);
};