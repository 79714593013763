import { FETCH_ALL_NEWS, FETCH_ALL_GALLERY, LOAD_ALL_NEWS, ENOUGH_LOADING_NEWS } from "../constants/actionTypes";
import * as api from "../api/media";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getAllNews = (langNumber) => async (dispatch) => {
  dispatch({ type: LOAD_ALL_NEWS});
  try {
    const { data } = await api.getAllNewsList(langNumber);
    dispatch({ type: FETCH_ALL_NEWS, payload: data });
  } catch (error) {
    dispatch({ type: ENOUGH_LOADING_NEWS});
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getAllGalleryImage = () => async (dispatch) => {
  try {
    const { data } = await api.getAllGalleryImageList();
    dispatch({ type: FETCH_ALL_GALLERY, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
