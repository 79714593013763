import { FETCH_ALL_NEWS, LOAD_ALL_NEWS, ENOUGH_LOADING_NEWS } from "../../constants/actionTypes";

export default (allnews = {allnews:[], loading: false}, action) => {
  switch (action.type) {
    case LOAD_ALL_NEWS:
      return {...allnews, loading:true};
      case ENOUGH_LOADING_NEWS:
        return {...allnews, loading:false};
      case FETCH_ALL_NEWS:
        return {...allnews, allnews: action.payload, loading:false};
    default:
      return allnews;
  }
};
