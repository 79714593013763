import { FETCH_ALL_SUKUK, LOAD_SUKUK, SUKUK_LOADED } from '../constants/actionTypes'

export default (sukuk = { sukuk: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_SUKUK:
            return { ...sukuk, sukuk: action.payload, loading: false };
        case LOAD_SUKUK:
            return {
                ...sukuk, loading: true
            };
        case SUKUK_LOADED:
            return {
                ...sukuk, loading: false
            };
        default:
            return sukuk;
    }
}