import { FETCH_ALL_FAVORITES, DELETE_FROM_FAVORITES, LOADED_FAVORITES, LOAD_FAVORITES } from '../../constants/actionTypes'

export default (favorites = {
    favorites: [],
    loading: false
}, action) => {
    switch (action.type) {
        case FETCH_ALL_FAVORITES:
            return { ...favorites, favorites:action.payload, loading: false };
        case DELETE_FROM_FAVORITES:
            return { ...favorites, favorites:action.payload, loading: false };
        case LOAD_FAVORITES:
            return { ...favorites, loading: true };
        case LOADED_FAVORITES:
            return { ...favorites, loading: false };
        default:
            return favorites;
    }
}