import { FETCH_ALL_GRACE } from "../constants/actionTypes";
import * as api from "../api/index";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getAllGraceList = (langNumber) => async (dispatch) => {
  try {
    const { data } = await api.getAllGraceList(langNumber);
    dispatch({ type: FETCH_ALL_GRACE, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
