import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import { toastifyMessage } from "../../handlers/toastifyMessage";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import {
  applyValidation,
  resetValidation,
} from "../../handlers/formValidation";
import i18n from "i18next";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const ContactUs = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [lookup, setLookup] = useState();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let mounted = true
  
  const contactUsLookup = (lang) => {
    api.contactUsLookup(lang).then((res) => {
      if (mounted) {
        setLookup(res.data);
      }
    });
  };
  const submitRequest = (e) => {
    e.preventDefault();

    if (type === '' || type === null || type === undefined) {
      toastifyMessage("Please select \"Issue type\".", undefined, "warning")
      return false;
    }

    if (name === '' || name === null || name === undefined) {
      toastifyMessage("Please add your name.", undefined, "warning")
      return false;
    }
    if (!validateUserame(name)) {
      toastifyMessage("\"Full Name\" length should be 2-60 characters long.", "\"Full Name\" should not contain special characters. e.g: (@,.'</>,!? etc).", 'warning');
      setLoading(false);
      return false;
    }

    if (mobile === '' || mobile === null || mobile === undefined) {
      toastifyMessage("Please add your mobile number.", undefined, "warning")
      return false;
    }
    if (mobile.slice(0,4) === '+971') {
    if (!validateMobile('0' + mobile.slice(4))) {
      const message = {
        main: "Invalid mobile number format.",
        p1: "\"Mobile number\" should be 9 digits long and start with \"5\"",
      }
      toastifyMessage(
        undefined,
        undefined, "warning",
        <div>
          {i18n.t(message.main)}
          <br /> - {i18n.t(message.p1)}
        </div >
      );
      return false;
    }
  }

    if (email === '' || email === null || email === undefined) {
      toastifyMessage("Please add your E-mail address.", undefined, "warning")
      return false;
    }
    if (!validateEmail(email)) {
      toastifyMessage("Invalid E-mail address", "Valid format: \"someone@example.com\"", "warning");
      setLoading(false);
      return false;
    }

    if (subject === '' || subject === null || subject === undefined) {
      toastifyMessage("\"Subject\" field cannot be empty!", undefined, "warning")
      return false;
    }

    else if (content === '' || content === null || content === undefined || !validateContent(content)) {
      toastifyMessage("Please describe your issue in the \"Content\" section with at least 50 characters.", undefined, "warning")
      return false;
    }

    else {
      setLoading(true);
      const query = `?personName=${name.trim()}&mobile=${mobile.slice(1)}&email=${email}&title=${subject.trim()}&content=${content.trim()}&reason_id=${type}`;
      api.contactUs(query).then((data) => {
        if (data.data.status === true) {
          toastifyMessage(
            "Request successfully submitted!",
            "Thank you for contacting us! Your request will be reviewed shortly.",
            "success");
          setName("");
          setMobile("");
          setEmail("");
          setTitle("");
          setSubject("");
          setContent("");
          setType("");
          setLoading(false);
        } else {
          toastifyMessage("Something went wrong...!", data.data.result, "error");
        }
      });
    }
  };

  const validateUserame = (username) => {
    const re = new RegExp(/^[a-zA-Z\u0621-\u064A](_(?!(\ |_))|\ (?!(_|\ ))|[a-zA-Z\u0621-\u064A]){0,61}[a-zA-Z\u0621-\u064A]$/);
    return re.test(username.trim());
  }

  const validateEmail = (email) => {
    const re =
      /^(([^\~=\^*+\/<>?()#!{}[\]\\.,;:\s@\"]+(\.[^~\^=*+\/<>?()#!{}[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase());
  };
  const validateMobile = (phone) => {
    const re = /^[\u0660-\u0669\u06F0-\u06F90-9]{10}/g;
    return re.test(phone);
  };

  const validateContent = (content) => {
    const re = /^[A-Za-z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF0-9\s\w\W'_.,\"\~`=-?!\\\/]{50,}$/;
    return re.test(content.trim());
  }

  useEffect(() => {
    contactUsLookup(lng === "arab" ? 1 : 2);
    return () => {
      mounted = false
    }
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

  return (
    <div className="contact_us">
      <ShareHeader noShare={true} pageName={t("Contact Us")} />
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("Contact Us")}</h3>
      </div>
      <div
        className="page-contents"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <form className="contact-info-container" onSubmit={submitRequest}>
          <h3 className="contactus-section-title">{t("CONTACT INFO")}</h3>
          <p className="contct-info">
            {t(
              "You can contact us any way that is convenient for you. We are available 24/7 via fax,email or telephone. You can also use a quick contact form on the right or visit our office personally. Email us with any questions or inquiries or use our contact data. We would be happy to answer your inquiries"
            )}
          </p>
          <p className="ci-title">{t("Emirates Red Crescent")}</p>
          <p className="erc-address">
            {t("Abu Dhabi")} <br />
            {t("Zayed Sports City")} <br />
            {t("PO Box")}: <span className='payment_numbers'>633 111</span>
            {'    ' + t("Abu Dhabi-United Arab Emirates")}
            <br />
            <br />
            {t("Email")}: <span className='payment_numbers'>hilal@rcuae.ae</span>
            <br />
            {t("Customer Service")}: <span className='payment_numbers'>800RED-800733</span>
          </p>
          <h3 className="contactus-section-title">{t("CONTACT FORM")}</h3>
          <select
          style={{backgroundPositionX: lng==='arab'? '0px':'100%', width: width<=900? '90%':null}}
            name="issue_type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="select-issue select-form"
          >
            <option value="">{t("Select issue type")}</option>
            {lookup?.map((item,i) => (
              <option key={i} value={item.reason_id}>{item.reason_name}</option>
            ))}
          </select>

          <input
            type="text"
            className={lng==='arab'? 'force_arabic_font select-issue':'payment_numbers select-issue'}
            // className="select-issue"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("Full Name...")}
          />
          <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <label>{t("Mobile Number")}</label>
          <PhoneInput
            className={mobile? 'payment_numbers select-issue form-100': lng==='arab'? 'force_arabic_font select-issue form-100':'payment_numbers select-issue form-100'}
            defaultCountry= 'AE'
            name="mobile_no"
            style={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            id="mobile"
            value={mobile}
            onChange={(phone)=> setMobile(phone)}
            required
            id="phone"
          />
          </div>
          <input
            className={email? 'payment_numbers select-issue form-100': lng==='arab'? 'force_arabic_font select-issue form-100':'payment_numbers select-issue form-100'}
            type="text"
            // className="select-issue"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("E-mail...")}
          />
          <input
            className={lng==='arab'? 'force_arabic_font select-issue form-100':'payment_numbers select-issue form-100'}
            type="text"
            // className="select-issue form-100"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder={t("Subject...")}
          />
          <textarea
            className="form-100"
            name=""
            id=""
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={t("Message...")}
            cols="30"
            rows="10"
          ></textarea>

          <button type="submit" className="sub-btn" disabled={loading}>
            {loading ? t("Submitting...") : t("SUBMIT A REQUEST")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
