import React, { useState, useEffect } from "react";
import "./PaymentMethod.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import mastercardIcon from "../../../assets/masterMethods.png";
import visa from "../../../assets/visa.png";

import SwipeButton from "vue-swipe-button";
import "vue-swipe-button/dist/swipeButton.css";
import { prop } from "dom7";
import Modal from 'react-bootstrap/Modal'
import Loading from '../../ui/loading';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'
// import { deletCard } from '../../../api/account'
import { deleteCard, getPaymentMethod } from '../../../api/index'
const PaymentMethod = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  const [delModal, setDelModal] = useState(false);
  const handleShow = (dt) => {
    setDelToken(dt);
    setDelModal(true)
  };
  const [loading, setLoading] = useState(true);

  const [allowToDelete, setAllowToDelete] = useState('');

  const [delToken, setDelToken] = useState();
  const handleClose = () => {
    setAllowToDelete('')
    setDelModal(false)
  };
  const [paymentTokens, setPaymentTokens] = useState([]);
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = (str) => {
    history.push();
  };

  const [added, setAdded] = useState(false);
  const [delbtn, setdelbtn] = useState({
    cardid: props.cardid
  })
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    let mounted= true
    getPaymentMethod(userInfo.Results[0].email).then(res => {
      if (mounted) {
        setPaymentTokens(res.data.data);
        setLoading(false);
      }
      
    })
    return () => {
      mounted = false
    }
  }, []);

  const deletePaymentMethod = (email, token) => {
    setLoading(true)
    deleteCard(email, token).then(res => {
      localStorage.setItem('userLogin', JSON.stringify({ Results: [{...userLogin.Results[0], user_tokens : res.data.data}]}))
      setPaymentTokens(res.data.data)
      setLoading(false);
    })
  }

  const handleShowDelete = (str) => {
    if (added) {
      setAdded(false);
    } else {
      setAdded(true);
    }
  }
  const displayDeleteIconForThis = (str) => {
    if (allowToDelete===str) {
      setAllowToDelete('')
    } else {
      setAllowToDelete(str)
    }
  }

  if (userInfo) {
    return (
      <div
        onClick={() => handleClick(props.projectType)}
        className="projects_compm"
        style={{direction: lng==='arab'? 'rtl':'ltr', minHeight:'85vh', justifyContent:'flex-start', gap:'20px'}}
      >
        <ShareHeader pageName={t("Payment Methods")} noShare={true}/>
        <div className="submit_requ_header">
          <h3 style={{ color: "gray" }}>{t("Payment Methods")}</h3>
        </div>
        {loading ? <Loading height='500px' /> : 
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', minHeight:'500px', width:'100%'}}>
          <div className="paymentMethodsAllWraper" style={{display: paymentTokens.length===0? 'flex':'grid',}}>
          {paymentTokens.length > 0 ? (
            paymentTokens.map((token, index) => (
              <div className="paymentMethod_Wraper" key={index}>
                <div className='payment_img_icon' style={{cursor:'pointer'}}>
                  <img src={token.slice(0,1)==='5'? mastercardIcon:visa} alt="doto_mastercard" style={{width:token.slice(0,1)==='5'? '60px':'60px', height:token.slice(0,1)==='5'? '46px':'20px'}}/>
                  <label style={{direction:'ltr'}} className='payment_numbers'>{token}</label>
                </div>
                <button style={lng==='arab'? {borderTopLeftRadius: '15px', borderBottomLeftRadius:'15px'}:{borderTopRightRadius: '15px', borderBottomRightRadius:'15px'}} className='delete_token_button animate__animated animate__fadeIn'
                onClick={() => handleShow(token)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

            ))
          ) : (
            <div className="PaymentM_body" >
              <h5>{t("No Payment Method Found")}</h5>
            </div>
          )}
        </div>
        </div>
        }
        <Modal show={delModal} centered onHide={handleClose} size='sm'>
          <Modal.Body style={{direction:lng==='arab'? "rtl":'ltr', display:'flex', flexDirection:'column', alignItems:'center',fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
            <label className='Bolded centered'>{t("Do you want to delete this method?")}</label>
            <br/>
            <div style={{ textAlign: "right", display:'flex', alignItems:'center', justifyContent:'space-evenly', gap:'5px' }}>
              <button style={{backgroundColor:'#000', color:'#fff', width:'80px', height:'30px', display:'flex', alignItems:'center', justifyContent:'center'}} className="btn btn-default" onClick={handleClose}>
                {t("Cancel")}
            </button>
              <button style={{ background: "red", color: "white", border: "none", borderRadius:'5px', width:'80px', height:'30px', display:'flex', alignItems:'center', justifyContent:'center' }} onClick={() => {
                deletePaymentMethod(userInfo.Results[0].email, delToken)
                handleClose();
              }}>
                {t("Delete")}
              </button>
            </div>
          </Modal.Body>

        </Modal>
        <div
          className="addPaymentCardBtn"
          onClick={() => history.push("/add-payment-method")}
        >
          {t("Add New Card")}
          <i className="fas fa-plus-circle plusCircle" className="plusCircle"></i>
        </div>

      </div>
    );
  } else {
    window.location.replace("/");
  }
};

export default PaymentMethod;
