import { FETCH_FAMILY_MEMBERS, LOAD_RELATIVES, RELATIVES_LOADED } from '../../constants/actionTypes'

export default (laRelatives = { laRelatives: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_FAMILY_MEMBERS:
            return { ...laRelatives, laRelatives: action.payload, loading: false };
        case LOAD_RELATIVES:
            return { ...laRelatives, loading: true };
        case RELATIVES_LOADED:
            return { ...laRelatives, loading: false }
        default:
            return laRelatives;
    }
}