import React from "react";
import Modal from "react-bootstrap/Modal";
import "./newsModal.css";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";

function NewsModal(props) {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="newsModalDialog"
      contentClassName="newsModalContent"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="newsModalBody" style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={props.news?.filePath}
            alt="News Image"
            style={{
              width: "auto",
              // objectFit: "cover",
              height: "inherit",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          />
        </div>
        <div
          style={{
            padding: "20px 30px",
          }}
        >
          <h4
            style={{
              direction: lng == "arab" ? "rtl" : "ltr",
              marginBottom: "50px",
              textAlign:'center'
            }}
          >
            {props.news.title}
          </h4>
          <div
            style={{
              direction: lng == "arab" ? "rtl" : "ltr",
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {props.news.content.replaceAll("&nbsp;", " ").replaceAll("&quot;", "\"")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default NewsModal;
