import React, { useState, useEffect } from "react";
import icon1 from "../../../../assets/nation id.png";
import icon8 from "../../../../assets/upload.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./vlnAttachmentsForm.css";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { uploadPassData } from "../../../../actions/volunteer";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import Loading from "../../../ui/loading";
const PassForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);
  const [profileLoading, setProfileLoading] = useState(false);
  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const handleChange = (e) => {
    props.setPassportInfo({...props.passportInfo, passport: {...props.passportInfo.passport, [e.target.name]: e.target.value}})
  };
  const handleSubmit = (e) => {

    e.preventDefault();
    
    // setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      passport: {
        passportDocumentID: props.passportInfo.passport.passportDocumentID,
        placeOfIssuance: props.passportInfo.passport.placeOfIssuance,
        startDate: props.passportInfo.passport.startDate.split("-").reverse().join("/"),
        endDate: props.passportInfo.passport.endDate.split("-").reverse().join("/"),
        fileType: props.passport.fileType,
        attachment: props.passport.attachment,
      },
    };
    if (props.passport.fileType==='' || props.passport.attachment==='') {
      setLoadingModalShow(false);
      return toastifyMessage("Upload your Attachment", undefined, "warning");
    }
    dispatch(uploadPassData(userData, setLoadingModalShow, props.setView));
    props.setPassportInfo(userData)
    
    if(props.volunteerNationality === "101")
    {
      props.setView(props.view + 2)
    }
    else {
      props.setView(props.view + 1)
    }
  };
  const goBack = () => {
    props.setView(props.view - 1)
  }
  useEffect(() => {
    if(!profileLoading)
    {
      if(props.passportReader?.result)
    {
      document.getElementById(
        "for_upl"
      ).style.backgroundImage = `url(${props.passportReader.result})`;
      document.getElementById('upload_icon').style.display='none'
      document.getElementById("for_upl").style.backgroundPosition = "center";
      document.getElementById("for_upl").style.backgroundSize = "cover";
      document.getElementById("for_upl").style.border = "1px solid white";
    }
    
    }
  }, [profileLoading])

    return (
      <div
        className="local_aid_info_form_page default_min_height"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <label
          style={{
            padding: "10px 10px 10px 20px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Insert your Passport details")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          <div className="uploading_input">
          {profileLoading?
          <Loading height='250px' mediaQ='200px' width='297px'/>:
            <label id="for_upl" htmlFor="myInputID">
            <img src={icon8} id='upload_icon' alt="upload" className="upload_icon" />
            </label>}
            <input type="file" id="myInputID" onChange={() => {props.handleUpLoadChange(setProfileLoading, props.passportReader, "myInputID", "for_upl", "fileName", "upload_icon", props.setPassport)}} />
            <label id="fileName">{t("Upload your Passport ID")}</label>
          </div>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="text"
              onChange={handleChange}
              className={props.passportInfo.passport.passportDocumentID? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
              value={props.passportInfo.passport.passportDocumentID}
              name="passportDocumentID"
              placeholder={t("Passport ID")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
            />
          </div>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="text"
              onChange={handleChange}
              className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
              value={props.passportInfo.passport.placeOfIssuance}
              name="placeOfIssuance"
              placeholder={t("Place of Issuance")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
            />
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-25px" }}
          >
            {t("Start Date")}
          </label>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="date"
              onChange={handleChange}
              className="local_aid_input"
              value={props.passportInfo.passport.startDate? props.passportInfo.passport.startDate.split("/").reverse().join("-"): props.passportInfo.passport.startDate}
              name="startDate"
              placeholder={t("Start date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              min={`${new Date().getFullYear() - 100}-01-02`}
              max={`${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
            />
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-25px" }}
          >
            {t("End Date")}
          </label>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon1} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="date"
              onChange={handleChange}
              className="local_aid_input"
              value={props.passportInfo.passport.endDate? props.passportInfo.passport.endDate.split("/").reverse().join("-"): props.passportInfo.passport.endDate}

              name="endDate"
              placeholder={t("End date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              min={`${new Date().getFullYear() - 100}-01-02`}
            />
          </div>
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
          >
            <span style={{ flex: "16" }}>{t("Next")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
          <button
            id="submitBtn_for_local_aid"
            className="local_aid_submit"
            onClick={() => goBack()}
          >
            <span style={{ flex: "16" }}>{t("Go Back")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default PassForm;
