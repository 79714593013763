import React, { useEffect, useState } from "react";
import './Events.css'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EventHomeSlider from './EventHomeSlider/EventHomeSlider'
import { useHistory } from 'react-router-dom'
import EventCalendar from "./EventCalendar/EventCalendar";
import moment from 'moment';

const Events = () => {
    const history = useHistory();
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const events = useSelector(state => state.events.events);
    const vlnEvents = useSelector(state => state.vlnEvents.vlnEvents);
    const [allEvents, setAllEvents] = useState([]);
    const loading = useSelector(state => state.events.loading);
    const [showEventCalendar, setShowEventCalendar] = useState(false)
    const getVlnDateFormat = (date) => {
        let customDate = new Date(date?.split('/')?.reverse()?.join('-'));
        let customFormat = moment(customDate).format('L')
        let timeData = moment(customDate).format('LTS');
        return customFormat + ' ' + timeData
    }
    useEffect(() => {
        let newVlnDate = vlnEvents?.map(item => {return {...item, start_date: getVlnDateFormat(item?.date), end_date: getVlnDateFormat(item?.date), title: item?.req_title}})
        console.log('all', [...events, ...newVlnDate])
        setAllEvents([...events, ...newVlnDate])
    }, [events, vlnEvents])
    return (
        events.length?<section id='events_sec'>
        <div className='section_title_div' style={lng === 'arab' ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' }}>
            <label className='all_section_title' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontWeight: 'normal', fontSize: '25px', width: '100%', textAlign: 'left' }}>{t("Events")}</label>
            <button onClick={() => setShowEventCalendar(true)} className='section_button'>{t("View All")}</button>
        </div>
          <EventHomeSlider
          events={allEvents}
          />
          <EventCalendar events={allEvents} show={showEventCalendar} onHide={() => setShowEventCalendar(false)}/>

    </section>:null
    )
}
export default Events