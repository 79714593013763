import './Languages.css'
import React, { useState, useEffect } from 'react'
import icon9 from '../../../assets/pencil.png'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getLevels, getLanguages, getVolunteerLanguages, insertVolunteerLanguage } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import DoneModal from '../../Auth/loginSignPage/doneModal/DoneModal'
import LoadingModal from '../../Auth/loginSignPage/loadingPopUp/loadingPopUp'
import Loading from '../../ui/loading';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import {
    applyValidation,
    resetValidation,
  } from "../../../handlers/formValidation";
const Languages = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const [added, setAdded] = useState(0)

    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getLevels(1))
                dispatch(getVolunteerLanguages(1, userLogin?.Results[0]?.vln_id))
                dispatch(getLanguages(1))
            } else {
                dispatch(getLevels(2))
                dispatch(getVolunteerLanguages(2, userLogin?.Results[0]?.vln_id))
                dispatch(getLanguages(2))
            }
        }
    }, [lng, added]);
    const vlnLanguages = useSelector(state => state.vlnLanguages.vlnLanguages);
    const loading = useSelector(state => state.vlnLanguages.loading);
    const [vlnAllLanguages, setAllLanguages] = useState(vlnLanguages)
    useEffect(() => {
        setAllLanguages(vlnLanguages)
    }, [vlnLanguages])

    const vlnLevel = useSelector(state => state.vlnLevels);
    const [vlnAllLevels, setVlnAllLevels] = useState(vlnLevel)

    useEffect(() => {
        setVlnAllLevels(vlnLevel)
    }, [vlnLevel])

    const vlnVolunteerLanguages = useSelector(state => state.vlnVolunteerLanguages);
    const [vlnVolunteerAllLanguage, setVlnVolunteerAllLanguage] = useState(vlnLevel)
    useEffect(() => {
        setVlnVolunteerAllLanguage(vlnVolunteerLanguages)
    }, [vlnVolunteerLanguages])


    const [languageData, setLanguageData] = useState({
        selectedLanguage: '',
        selectedLevel: '',
    })
    const handleChange = (e) => {
        setLanguageData({ ...languageData, [e.target.name]: e.target.value })
    }
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [doneModal, setDoneModal] = useState(false)
    const handleAddCourse = (e) => {
        e.preventDefault()
        setLoadingModalShow(true)
        dispatch(insertVolunteerLanguage(languageData.selectedLanguage, userLogin?.Results[0]?.vln_id, languageData.selectedLevel, setLoadingModalShow, setDoneModal))
        setAdded(added + 1)
    }
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Languages")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Languages")}</h3>
                </div>
                <form className='courses_form' onSubmit={handleAddCourse}>
                    <div className='selectCourseInputWraper'>
                        <img src={icon9} alt='selected_course' className='iconForSelectCourse' />
                        <select value={languageData.selectedLanguage} onChange={handleChange} name="selectedLanguage" className="selectedCourse" required
              onInvalid={applyValidation}
              onInput={resetValidation}>
                            <option value='' disabled defaultValue>{t("Select Language")}</option>
                            {vlnAllLanguages?.map(item =>
                                <option key={item.languageID} value={item.languageID}>{item.languageName}</option>
                            )}
                        </select>
                    </div>
                    <div className='selectCourseInputWraper'>
                        <img src={icon9} alt='selected_course' className='iconForSelectCourse' />
                        <select value={languageData.selectedLevel} onChange={handleChange} name="selectedLevel" className="selectedCourse" required
              onInvalid={applyValidation}
              onInput={resetValidation}>
                            <option value='' disabled defaultValue>{t("Select Level")}</option>
                            {vlnAllLevels?.map(item =>
                                <option key={item.levelID} value={item.levelID}>{item.levelName}</option>
                            )}
                        </select>
                    </div>
                    <br />
                    <button className='btn_for_submiting_courses'>{t("Submit")}</button>
                </form>
                {loading ? <Loading /> : <div className='courses_display'>
                    {vlnVolunteerAllLanguage?.slice(0).reverse().map(item =>
                        <div key={item.languageID} className='course_Item'>
                            <label>{t("You are")} <span style={{ fontWeight: 'bold' }}>{item.levelName}</span> {t("at")} <span style={{ fontWeight: 'bold' }}>{item.languageName}</span></label>
                        </div>
                    )}
                </div>}
                <DoneModal
                    show={doneModal}
                    dialogClassName="modal-60w"
                    onHide={() => { setDoneModal(false) }}
                />
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                />
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default Languages
