import React from 'react';
import './VolunteerPage.css';
import { Route, Switch } from 'react-router-dom';
import VolunteerPortal from './volunteerPortal/VolunteerPortal';
// import FileDetals from './fileDetails/FileDetals';
import VolunteerDetails from './volunteerDetails/VolunteerDetails';
import Participants from './participants/Participants';
import Attachment from './attachments/Attachment';
import TrainingCourses from './trainingCourses/TrainingCourses';
import Languages from './languages/Languages';
import Skills from './skills/Skills';
import AreasOfInterests from './AreasOfInterests/AreasOfInterests';
import Events from './events/events';
import OneEventPage from './events/oneEventPage/OneEventPage';
import Services from './services/Services';
import Register from './vlnRegister/Register';
import LinkAccount from './linkAccount/LinkAccount';

function VolunteerPage() {

  return (
    <div className="volunteer_page">
      <Switch>
        <Route path="/volunteer/" component={VolunteerPortal} exact />
        <Route path="/volunteer/areas-of-interest" component={AreasOfInterests} />
        <Route path="/volunteer/events" component={Events} />
        <Route path="/volunteer/event" component={OneEventPage} />
        {/* NOTE: OLD SCREEN */}
        {/* <Route path="/volunteer/file-details" component={FileDetals} /> */}
        <Route path="/volunteer/volunteer-details" component={VolunteerDetails} />
        <Route path="/volunteer/participants" component={Participants} />
        <Route path="/volunteer/attachments" component={Attachment} />

        <Route path="/volunteer/training-courses" component={TrainingCourses} />

        <Route path="/volunteer/languages" component={Languages} />
        <Route path="/volunteer/E-services" component={Services} />
        <Route path="/volunteer/skills" component={Skills} />
        <Route path="/volunteer/vln-registration" component={Register} />
        <Route path="/volunteer/link-account" component={LinkAccount} />
      </Switch>
    </div>
  );
}

export default VolunteerPage;
