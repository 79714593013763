import React from 'react';
import './ProjectsAllPage.css';
import ProjectsPage from './projectsPage/ProjectsPage';
import ProjectsRedirect from './projectsRedirect/ProjectsRedirect';
import { Route, Switch } from 'react-router-dom';



function ProjectsAllPage() {    
  
  return (
    <div className="App">
      <Switch>
        <Route path="/projects/" component={ProjectsRedirect} exact />  
        <Route path="/projects/:id" component={ProjectsPage} />
      </Switch>
    </div>
  );
}

export default ProjectsAllPage;
