import React from 'react';
import './HumanCasesAllPage.css';
import HumanCase from './humanCasePage/HumanCase';
import { Route, Switch } from 'react-router-dom';
import HumanCasesPage from './humanCasesPage/HumanCasesPage';

  

function HumanCasesAllPage() {    
  
  return (
    <div className="App">
      <Switch>
        <Route path="/humancases/" component={HumanCasesPage} exact />
        <Route path="/humancases/:id" component={HumanCase} />
      </Switch>
    </div>
  );
}

export default HumanCasesAllPage;
