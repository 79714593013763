import { FETCH_LOCAL_AID_PROFILE, LOAD_LOCAL_AID_PROFILE, LOCAL_AID_PROFILE_LOADED } from '../../constants/actionTypes'

export default (laRProfile = { laRProfile: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_LOCAL_AID_PROFILE:
            return { ...laRProfile, laRProfile: action.payload, loading: false };
        case LOAD_LOCAL_AID_PROFILE:
            return { ...laRProfile, loading: true };
        case LOCAL_AID_PROFILE_LOADED:
            return { ...laRProfile, loading: false };
        default:
            return laRProfile;
    }
}