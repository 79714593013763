import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./AccountLinkSelectionModal.css";
import {
  addExtraSponsors,
} from "../../../api";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

function AccountLinkModal(props) {
  const [sponsorsChecked, setSponsorsChecked] = useState([]);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const handleSponsor = (item) => {
    sponsorsChecked.filter((i) => item.sponsor_id === i.sponsor_id).length > 0? setSponsorsChecked(sponsorsChecked.filter((i) => i.sponsor_id !== item.sponsor_id)): setSponsorsChecked([...sponsorsChecked, item])
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
  });
  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    return re.test(phone);
  }
  const [isSendOTP, setOTPstatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  return (
    <Modal
      {...props}
      onExit={()=> {setFormData({mobile:'05', otp:''}); setOTPstatus(false)}}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body"
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          className="modal_body_for_account_selection"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          <label style={{fontWeight: 'bold', fontSize: 'larger'}}>{t("Select Account(s) to link")} </label>
          {props?.sponsorSelections?.map((item, i) => (
                <div>
                  <input onChange={() => handleSponsor(item)} style={{float: lng === "arab"? 'left': 'right'}} type="checkbox"></input>
                  <div>{item.sponsor_name} </div>
                  <div className="payment_numbers">{item.sponsor_id} </div>
                </div>

              ))}          
          {errors ? <div>{t(errors)}</div> : <div></div>}
          <div className="donation_final_popUp_btns_wraper">
            <button
              onClick={() => {
                setError("");
                setLoading(true);
                addExtraSponsors(
                  {
                    user_email: props.email,
                  sponsors: sponsorsChecked.map((item) => { return item.sponsor_id }),
                  otp: props.otp,
                  }
                )
                  .then((res) => {
                    if (res.data.status) {
                      localStorage.setItem('userLogin', JSON.stringify({Results: res.data.data}))
                      props.onHide()
                    } else {
                      setError(res.data.result);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
              className=" donation_final_popUp_btn_Third"
            >
              {isLoading ? t("Loading...") : t("Submit")}
            </button>

            <button
              onClick={() => {
                props.onHide();
              }}
              className="donation_final_popUp_btn_first"
            >
              {t("Not Now")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default AccountLinkModal;
