import React, { Fragment, useEffect, useState } from "react";
import "./events.scss";
import eventimage from "../../assets/events.svg";
import eventimage_1 from "../../assets/events_1.svg";
import qrcode from "../../assets/qrcode.svg";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import { useLocation } from 'react-router-dom';

const EventAttendance = () => {
  const [shareUrl, setShareUrl] = useState(false);
  const location = useLocation();
  const { item } = location.state || {}; 
  useEffect(() => {
    let textOfPageArr = window.location.href.split("/");
    textOfPageArr.splice(textOfPageArr.length - 1, 1);
    setShareUrl(textOfPageArr.join("/"));
  }, []);
  
  return (
    <Fragment>
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        textToBeCopied={shareUrl}
        pageName={"Event Attendance"}
        path={"beasponser"}
      />
      <div className="events_attents">
        <div className="events_left">
          <div className="title_section">
            <h1>{item?.name ?? 'EVENT TITLE'}</h1>
            <p>
              {item?.description}
            </p>
          </div>
          <div className="image_section">
            <img src={item?.images[0]} alt={eventimage} />
            {/* <img src={eventimage_1} alt="events" /> */}
          </div>
        </div>
        <div className="events_right">
          <h5>Scan or Add Pin to mark your attendance to the Event</h5>
          <div className="qrcode">
            <img src={qrcode} alt="qrcode" width="130px" />
            <p>Scan QR Code</p>
          </div>
          <div className="addpin">
            <input type="text" value={item?.registered_number}/>
            <p>Add Pin</p>
          </div>
          {/* <div className="btn_events">
            <p>Mark Attendance</p>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default EventAttendance;
