import { FETCH_ALL_COUNTRIES_PROJETCS_SEC, FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING, FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING} from '../../constants/actionTypes'

export default (countries_proj_sec = {
    countries_proj_sec: [],
    countries_proj_sec_ALL: [],
    loading: true
}, action) => {
    switch (action.type) {
        case FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING:
            return {...countries_proj_sec, loading: true};
        case FETCH_ALL_COUNTRIES_PROJETCS_SEC:
            return {...countries_proj_sec, countries_proj_sec: action.payload.filtered, countries_proj_sec_ALL: action.payload.projectsFull, loading: false};
        case FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING:
            return {...countries_proj_sec, loading: false};
        default:
            return countries_proj_sec;
    }
}