import React, {useEffect} from 'react'
import './StoryComponentOrphan.css'
import orphanDefault from '../../../assets/orphansDefaultDark.png'

const StoryComponentOrphan = (props) => {
    const handleClick = (id, amount) => {
        if (props.selectModeSwitch) {
            let currentItems = [...props.itemSelectedMode]
            if (currentItems.filter(item=> item===props.itemId).length>0) {
                currentItems = currentItems.filter(item=> item !== props.itemId)
                props.setItemSelectedMode(currentItems)
            } else {
              currentItems.push(id)
              props.setItemSelectedMode(currentItems)
            }
        } else {
            props.setSelectedItem(id)
            props.setOrphanAmount(amount)
        }
        
    }
    return (
        <button id={`Item_${props.itemId}`} onClick={()=> handleClick(props.itemId, props.amount)} className='story_component' style={{backgroundImage:`url(${props.img ?? orphanDefault})`, filter: !props.selectModeSwitch? props.selectedItem===props.itemId? 'unset':'grayscale(100%)': props.itemSelectedMode?.filter(
          (innerItem) => innerItem === props.itemId
        ).length > 0?'unset':'grayscale(100%)', position:'relative'}}>
            <span
                style={{ position: "absolute", top: "5px", left: "5px" }}
                className="rightSign_btn--active"
                  >
                    {props.itemSelectedMode?.filter(
          (innerItem) => innerItem === props.itemId
        ).length > 0 || (!props.selectModeSwitch && props.selectedItem===props.itemId)?<i className="fas fa-check"></i>:null}
                  </span>
            {props.title}
        </button>
    )
}

export default StoryComponentOrphan
