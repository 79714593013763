import { slide as Menu } from 'react-burger-menu';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './SideMenuNavi.css';
import { useDispatch } from 'react-redux';
import MenuIcon from '../../../../assets/menu.png';
import { useTranslation } from 'react-i18next';
import ENG from '../../../../assets/flag.png';
import UAE from '../../../../assets/united-arab-emirates.svg';
import fc from '../../../../assets/Asset 5@10x-8.png';
import tw from '../../../../assets/Asset 4@10x-8.png';
import ins from '../../../../assets/Asset 3@10x-8.png';
import snap from '../../../../assets/Asset 2@10x-8.png';
import youtube from '../../../../assets/Asset 1@10x-8.png';
import { useSelector } from 'react-redux';
import Whatsapp from '../../../../assets/Whatsapp.png';
import SupplierInvoiceGuide from '../../../../assets/supplier_invoice_guide.pdf';
import { getCommunityAww } from '../../../../api';

function HeaderSidebar(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [communityLinks, setCommunityLinks] = useState([]);
  const getAwwrenessLinks = async () => {
    try {
      const { data } = await getCommunityAww(lng === 'arab' ? 1 : 2);
      if (data?.status) {
        setCommunityLinks(data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (userLogin) {
      getAwwrenessLinks();
    }
  }, [lng]);
  const logOut = () => {
    if (userLogin) {
      dispatch({ type: 'LOGOUT' });
    } else {
      history.push('/auth');
    }
  };
  const Inner = (str) => {
    if (str === 'eng') {
      localStorage.setItem('language', JSON.stringify({ lang: 'en' }));
      //props.setChangeOfLanguage(str);
      window.location.reload();
    } else {
      localStorage.setItem('language', JSON.stringify({ lang: 'arab' }));
      //props.setChangeOfLanguage(str);
      window.location.reload();
    }
  };
  const openLinkInSomeWhereElse = (str) => {
    history.push(str);
  };
  const openLink = (str) => {
    setMenuOpen(false);
    openLinkInSomeWhereElse(str);
  };
  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '30px',
      height: '25px',
      left: lng === 'arab' ? 'auto' : '25px',
      right: lng === 'arab' ? '15px' : 'auto',
      transform: lng === 'arab' ? 'scaleX(-1)' : 'unset',
      top: '15px'
    },
    bmMenuWrap: {
      position: 'fixed',
      maxWidth: '80vw',
      top: '0',
      left: lng !== 'arab' ? '0' : 'auto',
      height: 'calc(100% - 55px)'
    }
  };
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }
  return (
    <Menu
      styles={styles}
      isOpen={menuOpen}
      right={lng === 'arab' ? true : false}
      className="naviForNavi"
      customBurgerIcon={<img src={MenuIcon} alt="mobile_menu" />}
    >
      <label style={{ textAlign: 'center' }}>{t('Menu')}</label>
      <div
        className="sideMenu_links"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          alignItems: 'flex-start',
          width: '100%'
        }}
      >
        {userLogin ? (
          <button
            style={
              lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
            }
            className="menu_item"
            onClick={() => history.push('/profile')}
          >
            {t('Profile')}
          </button>
        ) : null}

        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push('/favorites')}
          className="menu_item"
        >
          {t('Favorites')}
        </button>
        <button
          onClick={() => history.push('/about')}
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('About Us')}
        </button>
        <button
          onClick={() => history.push('/news')}
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('News')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/landingpages'))}
          className="menu_item"
        >
          {t('Landing Page')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push('/volunteer/vln-registration')}
          className="menu_item"
        >
          {t('Volunteering Service')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            lng === 'arab'
              ? window.open('https://forms.gle/yPmUBLsnLZHjcrgF9')
              : window.open('https://forms.gle/N67PE8ZU1HVbykMQ6')
          }
          className="menu_item"
        >
          {t('Volunteer Satisfaction and Happiness Form')}
        </button>

        <button
          onClick={() =>
            (window.location.href =
              getMobileOperatingSystem() === 'iOS'
                ? 'https://apps.apple.com/ae/app/neama/id6443719244'
                : 'https://play.google.com/store/apps/details?id=ae.rcuae.neama')
          }
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('Grace conservation')}
        </button>
        <a
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          href="https://www.alghadeeruaecrafts.ae/"
          className="menu_item"
        >
          {t('Al Ghadeer UAE Crafts')}
        </a>
        <a
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          href="https://www.emiratesrc.ae/TrainingCourses/"
          className="menu_item"
        >
          {t('Training Courses ')}
        </a>

        <a
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          href="https://www.atayaprojects.ae/"
          className="menu_item"
        >
          {t('Ataya')}
        </a>
        <button
          onClick={() => history.push('/our-locations')}
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('Our Locations')}
        </button>
      
        <button
          onClick={() => history.push('/contact-us')}
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('Contact Us')}
        </button>
        {userLogin ? (
          <button
            onClick={() => history.push('/sponsership')}
            style={
              lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
            }
            className="menu_item"
          >
            {t('Donation History')}
          </button>
        ) : null}
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            history.push(history.push(userLogin ? '/profile' : '/auth'))
          }
          className="menu_item"
        >
          {t('Beneficent Portal')}
        </button>
        {/* new */}
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/course'))}
          className="menu_item"
        >
          {t('Course')}
        </button>
        {/* <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/events-attendance'))}
          className="menu_item"
        >
          {t('Event Attendance')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/events-join'))}
          className="menu_item"
        >
          {t('Events Join')}
        </button> */}
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/donate-idea'))}
          className="menu_item"
        >
          {t('Donate Idea')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push(history.push('/donate-estate'))}
          className="menu_item"
        >
          {t('Donate With Real Estate')}
        </button>
        {/* Community links */}
        {communityLinks &&
          communityLinks.length > 0 &&
          communityLinks?.map((item, index) => (
            <button
              style={
                lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
              }
              onClick={() => window.open(item?.att_location)}
              className="menu_item"
            >
              {item?.att_name || 'Community Link'}
            </button>
          ))}
        {/* new end */}
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            history.push(
              userLogin?.Results[0]?.person_id_flag === '2'
                ? '/localAid'
                : userLogin
                ? '/profile'
                : '/auth'
            )
          }
          className="menu_item"
        >
          {t('Local Aid')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => history.push('/beasponser')}
          className="menu_item"
        >
          {t('Become a Sponsor')}
        </button>

        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            window.open('https://erp.rcuae.ae/OA_HTML/AppsLocalLogin.jsp')
          }
          className="menu_item"
        >
          {t('Supplier login')}
        </button>

        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            window.open(
              lng === 'arab'
                ? 'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=AR'
                : 'https://erp.rcuae.ae:443/OA_HTML/jsp/pos/suppreg/SupplierRegister.jsp?ouid=5055E79729E7A9FD&lang=US'
            )
          }
          className="menu_item"
        >
          {t('Register a new supplier')}
        </button>
        <a
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          href={SupplierInvoiceGuide}
          download={
            lng === 'arab' ? 'ارشادات خدمة التوريد' : 'Supplier Invoice Guide'
          }
          target="_blank"
          className="menu_item btn"
        >
          {t('Supplier Invoice Guide')}
        </a>

        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            window.open('http://portal.rcuae.ae/local_aid/user_login_log.aspx')
          }
          className="menu_item"
        >
          {t('Purchasing Committees Accreditation Portal')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            window.open('http://portal.rcuae.ae/local_aid/user_login.aspx')
          }
          className="menu_item"
        >
          {t('Portal of aid and accreditation of committees')}
        </button>
        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() => window.open('https://portal.rcuae.ae/orphans_sys/')}
          className="menu_item"
        >
          {t('Sponsorships and Orphans Affairs Portal')}
        </button>

        <button
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          onClick={() =>
            window.open('https://apex.rcuae.ae/ords/r/erc_apps/erc-hub/login')
          }
          className="menu_item"
        >
          {t('External project management system')}
        </button>

        {userLogin ? (
          <button
            onClick={() => history.push('/settings')}
            style={
              lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
            }
            className="menu_item"
          >
            {t('Settings')}
          </button>
        ) : null}
        <a
          href="/our-partners"
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {t('Our Partners')}
        </a>

        <button
          aria-controls="rcw-chat-container"
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="rcw-launcher chatFor"
        >
          {t('Live chat')}
        </button>
        <button
          onClick={logOut}
          style={
            lng === 'arab' ? { direction: 'rtl', textAlign: 'right' } : null
          }
          className="menu_item"
        >
          {userLogin ? t('Log Out') : t('Log In')}
        </button>
        {lng === 'arab' ? (
          <button
            style={lng === 'arab' ? { direction: 'rtl' } : null}
            onClick={() => Inner('eng')}
            className="dropdown-item"
            id="eng"
          >
            <img src={ENG} alt="Eng_Logo" className="lang_logo" />
            <label
              className="payment_numbers"
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: 'english_font',
                marginBottom: '-4.7px'
              }}
            >
              ENG
            </label>
          </button>
        ) : (
          <button
            style={lng === 'arab' ? { direction: 'rtl' } : null}
            onClick={() => Inner('arabic')}
            className="dropdown-item"
            id="ar"
          >
            <img src={UAE} alt="Eng_Logo" className="lang_logo" />
            <label
              className="force_arabic_font"
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: "'arab_font'"
              }}
            >
              العربية
            </label>
          </button>
        )}
        <div
          className="social_media"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            gap: '0px'
          }}
        >
          <a
            style={{ textDecoration: 'none', color: '#3b5998' }}
            href="https://m.facebook.com/emiratesrc"
          >
            <img
              style={{ height: '50px', objectFit: 'cover' }}
              src={fc}
              alt="facebook"
            />
          </a>
          <a
            style={{ textDecoration: 'none', color: '#c32aa3' }}
            href="https://www.instagram.com/emiratesrc/"
          >
            <img
              style={{ height: '50px', objectFit: 'cover' }}
              src={ins}
              alt="instagram"
            />
          </a>
          <a
            style={{ textDecoration: 'none', color: '#1da1f2' }}
            href="https://mobile.twitter.com/emiratesrc"
          >
            <img
              style={{ height: '50px', objectFit: 'cover' }}
              src={tw}
              alt="twitter"
            />
          </a>
          <a
            style={{ textDecoration: 'none', color: '#fffc00' }}
            href="https://www.snapchat.com/add/emiratesrc"
          >
            <img
              style={{ height: '50px', objectFit: 'cover' }}
              src={snap}
              alt="snapchat"
            />
          </a>
          <a
            style={{ textDecoration: 'none', color: '#fffc00' }}
            href="https://wa.me/9712800733"
          >
            <img
              style={{ height: '50px', objectFit: 'cover' }}
              src={Whatsapp}
              alt="whatsap"
            />
          </a>
        </div>
      </div>
    </Menu>
  );
}
export default HeaderSidebar;
