import { FETCH_ALL_VOLUNTEER_SERVICES, LOAD_SERVICES, SERVICES_LOADED } from '../../constants/actionTypes'

export default (vlnServices = { vlnServices: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_VOLUNTEER_SERVICES:
            return { ...vlnServices, vlnServices: action.payload, loading: false };
        case LOAD_SERVICES:
            return { ...vlnServices, loading: true };
        case SERVICES_LOADED:
            return {...vlnServices,loading:false}
        default:
            return vlnServices;
    }
}