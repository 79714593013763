/** @flow */
import React, {useState, useRef, useEffect} from 'react';
// import apple from '../../../assets/uaepass-default-dark.png'
import apple from '../../../assets/uaepass-round.svg'
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { signInWithApple, signInWithUAEPASS } from '../../../actions/user';
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import LoadingModal from "../loginSignPage/loadingPopUp/loadingPopUp";
import CompleteInfoModal from "../loginSignPage/completeInfoModal/CompleteInfoModal";
import DonationFinalPopUp from '../UAEPASSAuthPopup/UAEAuthPopup/UAEPASSAuthOptions'
import UAEVerify from '../UAEPASSAuthPopup/UAEVerifyModal/UAEPASSAuthOptions'
import UAEModalError from '../UAEPASSAuthPopup/UAEModalError/UAEPASSAuthOptions'
import * as api from "../../../api";
import * as account from "../../../api/account";
import OTPUAE from '../UAEPASSAuthPopup/LinkAccount/OTPUAE';
/** Apple Signin button */

function SignInWithUAEPASS(props) {
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [lodaingSwitch, setLoadingSwitch] = useState({ open: false, goTo: '/profile' })
    const [wait, setWait] = useState(false)
    const [uaeAuth, setUAEAuth] = useState(false)
    const [uaeAuthStep2, setUAEAuthStep2] = useState(false)
    const [uaeVerifyModal, setUAEVerifyModal] = useState(false)
    const [uaeErrorModal, setUaeErrorModal] = useState(false)
    const dispatch = useDispatch();
    const [functionToSend, setFunctionToSend] = useState(()=> {});
    const [uaeLinkBody, setUAELinkBody] = useState({});
    const [userBody, setUserBody] = useState({});
    const [isManualLink, setIsManualLink] = useState(true);
    let redirectData = {
      redirectUrl: window.location.origin + window.location.pathname,
      params: {
        fromState:  props.fromState,
      }
    }
    let param = encodeURIComponent(JSON.stringify(redirectData));
    useEffect(() => {
       setManualLink();
    }, [isManualLink])

    let setManualLink = async (email) => {
     if(!isManualLink && (email === undefined || email === null))
     {
      uaeLinkBody.isAutoLink = true;
     }
     else if (email !== undefined && email !== undefined)
     {
      uaeLinkBody.emailToLink = email;
      uaeLinkBody.isAutoLink = false;

    }
    if(uaeLinkBody.accessToken !== undefined)
    {
      if(uaeLinkBody.isAutoLink)
      {
        let res = await api.signInWithUAEPASS_Setup({uae_user: {...uaeLinkBody}})
      setUAELinkBody(res.data.data)
      dispatch(signInWithUAEPASS(res.data.data, null, setLoadingModalShow, null, setLoadingSwitch, lodaingSwitch, setWait, setUAEAuth, setUAELinkBody, 1))
      setUAEAuth(false)

      
      }
      if(!uaeLinkBody.isAutoLink)
        {
          try
          {
            let res = await api.signInWithUAEPASS_Setup({uae_user: {...uaeLinkBody}})
          if(!res.data.status)
          {
            toastifyMessage(res.data.result, undefined, "error");
          }
          setUAEAuth(false);
          setUAEAuthStep2(true)
          }
          catch
          {
            toastifyMessage("User not found", undefined, "error");
          }
        }
      // else
      // {
      //   const params = new Proxy(new URLSearchParams(window.location.search), {
      //     get: (searchParams, prop) => searchParams.get(prop),
      //   });
      //   // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      //   let code = params.code; 
      //   let state = params.state;
      //   let states = decodeURIComponent(state)
      //   const stateParams = new Proxy(new URLSearchParams(decodeURIComponent(states)), {
      //     get: (searchParams, prop) => searchParams.get(prop),
      //   });
      //   // dispatch(signInWithUAEPASS({uae_user: {...uaeLinkBody}}, null, setLoadingModalShow, stateParams.fromState, setLoadingSwitch, lodaingSwitch, setWait, setUAEAuth, setUAELinkBody, 3))
      //   setLoadingModalShow(false)

      // }
    }
    }
    useEffect(() => {
      let result = null;
      
      
      
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
          // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
          let code = params.code; 
          let state = params.state;
          let error_description = params.error_description;
          let states = decodeURIComponent(state)
          if(error_description != null)
          {
                // toastifyMessage(error_description, undefined, "error");
                setUaeErrorModal(true);
                setTimeout(() => setUaeErrorModal(false), 5000)
          }
          else
          {
            if(code != null)
          {
          var stateParams = JSON.parse(states)
          try
          {
            setLoadingModalShow(true)
            async function fetchData() {
              // You can await here
              result =  await api.signInWithUAEPASS(code, state, "http://www.emiratesrc.ae/social_sup/Social_sup.asmx/uae_pass_redirect");
              if(result?.data?.email || result?.data?.data?.email)
              {
              if(!result.data.status)
              {
                setLoadingModalShow(false);
                if(result.data.result === "SOP1")
                {
                  setUAEVerifyModal(true);
                  // toastifyMessage("Your account is unverified. Please upgrade your account following instructions on the UAE PASS app", undefined, "error");
                }
              }
              else if(result.data.accessToken)
              {
                setUAELinkBody(result.data);
                setUAEAuth(true); 
              }
              else if (result.data.status)
            {
              dispatch(signInWithUAEPASS(result.data.data, null, setLoadingModalShow, stateParams?.params?.fromState, setLoadingSwitch, lodaingSwitch, setWait, setUAEAuth, setUAELinkBody, 1))
            }
              
            
              }
              else
              {
                setLoadingModalShow(false);
                window.location.href = window.location.origin + window.location.pathname

              }
              
            }
            fetchData();
          }
          catch
          {

          }
          }
          }

    }, []);
    async function logoutFunc()
    {
      await toastifyMessage("Logging out user from UAE PASS", undefined, "error");
      setUAEAuth(false)
      setUAEAuthStep2(false)
      setTimeout(() => {window.location.href = "https://id.uaepass.ae/idshub/logout?redirect_uri=" + window.location.origin;}, 3000)

    }
    var location = new URL(window.location.href);
    let redirectLocation = window.location.origin + window.location.pathname
    if(location.href.includes("www"))
    {
        redirectLocation = window.location.origin + window.location.pathname
    }
    // const onSuccess = (res) => {
    //     dispatch(signInWithUAEPASS(res, props.history, props.setLoadingModalShow, props.fromState, props.completeInfo, props.lodaingSwitch, props.setWait))
    // };
    // const onFailure = (res) => {
    //     toastifyMessage(`Login Failed: Pop-up closed by user!`, undefined, "error");
    // };


    return (
        <>
        <button style={{ border: 'none', backgroundColor: 'transparent' }}
                    // onClick={() => { window.location.href = "https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=sandbox_stage&scope=urn:uae:digitalid:profile:general&state=" + param + "&redirect_uri=" + window.location.origin + window.location.pathname + "&acr_values=urn:safelayer:tws:policies:authentication:level:low "; props.setLoadingModalShow(true) }}
                    onClick={() => { window.location.href = "https://id.uaepass.ae/idshub/authorize?response_type=code&client_id=rcuae_web_prod&scope=urn:uae:digitalid:profile:general&state=" + param + "&redirect_uri=http://www.emiratesrc.ae/social_sup/Social_sup.asmx/uae_pass_redirect&acr_values=urn:safelayer:tws:policies:authentication:level:low "; props.setLoadingModalShow(true) }}

>
                    <img src={apple}  alt='apple_logo' className='login_sign_social_logos' style={{ background: '#30302f' }} />
                </button>

        <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <CompleteInfoModal
        show={lodaingSwitch.open}
        usertype="UAEPASS"
        onHide={() => setLoadingSwitch({ open: false, goTo: '/profile' })}
        lodaingswitchprop={lodaingSwitch}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <DonationFinalPopUp
              show={uaeAuth}
              show2={uaeAuthStep2}
              paymentfunction = {functionToSend}
              onHide={()=> {uaeAuth?setUAEAuth(false): setUAEAuthStep2(false); setLoadingModalShow(false);}}
              dialogClassName="modal-60w"
              setManualLink={setManualLink}
              setIsManualLink={setIsManualLink}
              setUAEAuth={setUAEAuth}
              setUAEAuthStep2={setUAEAuthStep2}
              logout={logoutFunc}
            />

<DonationFinalPopUp
              show={uaeAuthStep2}
              show2={uaeAuthStep2}
              paymentfunction = {functionToSend}
              onHide={()=> {uaeAuth?setUAEAuth(false): setUAEAuthStep2(false); setLoadingModalShow(false);}}
              dialogClassName="modal-60w"
              setManualLink={setManualLink}
              setIsManualLink={setIsManualLink}
              uaeLinkBody={uaeLinkBody}
              setLoadingModalShow={setLoadingModalShow}
              fromState={null}
              setLoadingSwitch={setLoadingSwitch}
              lodaingSwitch={lodaingSwitch}
              setWait={setWait}
              setUAEAuth={setUAEAuth}
              setUAEAuthStep2={setUAEAuthStep2}
            />

<UAEVerify
              show={uaeVerifyModal}
              onHide={()=> {setUAEVerifyModal(false); setLoadingModalShow(false);}}
              dialogClassName="modal-60w"
              
            />

<UAEModalError
              show={uaeErrorModal}
              onHide={()=> {setUAEVerifyModal(false); setLoadingModalShow(false);}}
              dialogClassName="modal-60w"
              
            />
            
      </>
        
        // <AppleSignin
        //     /** Auth options passed to AppleID.auth.init() */
        //     authOptions={{
        //         /** Client ID - eg: 'com.example.com' */
        //         clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
        //         /** Requested scopes, seperated by spaces - eg: 'email name' */
        //         scope: 'email name',
        //         /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        //         redirectURI: redirectLocation,
        //         /** State string that is returned with the apple response */
        //         state: 'state',
        //         /** Nonce */
        //         nonce: 'nonce',
        //         /** Uses popup auth instead of redirection */
        //         usePopup: true
        //     }} // REQUIRED
        //     /** General props */
        //     uiType="dark"
        //     /** className */
        //     className="apple-auth-btn"
        //     /** Removes default style tag */
        //     noDefaultStyle={true}
        //     /** Allows to change the button's children, eg: for changing the button text */
        //     // buttonExtraChildren="Continue with Apple"
        //     /** Extra controlling props */
        //     /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        //     onSuccess={onSuccess} // default = undefined
        //     /** Called upon signin error */
        //     onError={() => { onFailure(); props.setLoadingModalShow(false) }}
        //     /** Skips loading the apple script if true */
        //     skipScript={false} // default = undefined
        //     /** Apple image props */
        //     iconProp={{ style: { marginTop: '10px' } }} // default = undefined
        //     /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        //     render={renderProps => (
        //         <button style={{ border: 'none', backgroundColor: 'transparent' }}
        //             onClick={() => { renderProps.onClick(); props.setLoadingModalShow(true) }}
        //             disabled={renderProps.disabled}>
        //             <img src={apple} alt='apple_logo' className='login_sign_social_logos' style={{ background: '#30302f' }} />
        //         </button>
        //     )}
        // />
    );
}

export default SignInWithUAEPASS;
