import { FETCH_ALL_SKILLS, LOAD_SKILLS, SKILLS_LOADED } from '../../constants/actionTypes'

export default (vlnSkills = { vlnSkills: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_SKILLS:
            return { ...vlnSkills, vlnSkills: action.payload, loading: false };
        case LOAD_SKILLS:
            return { ...vlnSkills, loading: true };
        case SKILLS_LOADED:
            return { ...vlnSkills, loading: false }
        default:
            return vlnSkills;
    }
}