import { FETCH_ALL_INTERESTS, INTERESTS_LOADED, LOAD_INTERESTS } from '../../constants/actionTypes'

export default (vlnInterests = { vlnInterests: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_INTERESTS:
            return { ...vlnInterests, vlnInterests: action.payload, loading: false };
        case LOAD_INTERESTS:
            return { ...vlnInterests, loading: true };
        case INTERESTS_LOADED:
            return { ...vlnInterests, loading: false };
        default:
            return vlnInterests;
    }
}