import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import "./ProjectShareTermsModal.css";
const TermsModal = (props) => {  
  return (<Modal
    show={props.show} onHide={props.onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    // className={adStyle.donation_pop_up_body_forWelcome}
  >
             
    <Modal.Body>
    <iframe width="100%" height="560" src={props.url} title="ERC Terms & Policies" frameborder="0" allowfullscreen></iframe>
    </Modal.Body>
  </Modal>
  )
};
export default TermsModal;