import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileBtn from "../userPage/profileBtn/ProfileBtn";
import "./Media.css";
import gallery from "../../assets/media/gallary.png";
import social_media from "../../assets/media/social-media.png";
import news from "../../assets/media/news.png";
import SocialModel from "./social_media/SocialMedia";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";

const Media = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [isSocialOpen, setSocialOpen] = useState(false);

  return (
    <div className="media" style={lng==='arab'? {direction: 'rtl'}:null}>
      <ShareHeader pageName={t("Media")} noShare={true}/>
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("Media")}</h3>
      </div>
      <div className='Media_wraper'>
      <ProfileBtn
        goTo="/news"
        icon={news}
        title={t("News")}
      />
      <ProfileBtn
        goTo="social_media"
        setSocialOpen={setSocialOpen}
        icon={social_media}
        title={t("Social Media")}
      />
      {/* {<ProfileBtn
        goTo="/gallery"
        title={
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={gallery}
              style={{ height: "30px", width: "30px" }}
              alt="Gallery"
            />
            {t("Gallery")}
          </span>
        }
      />} */}
      </div>
      <SocialModel
        show={isSocialOpen}
        dialogClassName="modal-60w"
        onHide={() => {
          setSocialOpen(false);
        }}
      />
    </div>
  );
};

export default Media;
