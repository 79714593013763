import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './PendingModal.css'
import { useTranslation } from "react-i18next";


function PendingModal(props) {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='donation_pop_up_body'
      >
        <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div className='modal_body_for_donation_final' style={lng==='arab'? {direction:'rtl'}:null}>
              <label>{t("We are reviewing your ")} <span style={{fontWeight:'bold'}}>{t(props.accounttype)}</span> {t("Please wait.")}</label>
              <div className='donation_final_popUp_btns_wraper'>
                  <button onClick={()=> props.onHide()} className='donation_final_popUp_btn_first'>
                      {t("Done")}
                  </button>
              </div>
          </div> 
        </Modal.Body>
      </Modal>
    );
  }
  export default PendingModal;