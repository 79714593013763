import { FETCH_ALL_PROJECTS_FULL, FETCH_ALL_PROJECTS_FULL_LOADING } from '../../constants/actionTypes'

export default (fullProjects = {
    fullProjects: [],
    loading: true
}, action) => {

    switch (action.type) {
        case FETCH_ALL_PROJECTS_FULL:
            return {...fullProjects, fullProjects: action.payload, loading: false};
        case FETCH_ALL_PROJECTS_FULL_LOADING:
            return {...fullProjects,loading: action.status};
        default:
            return fullProjects;
    }
}