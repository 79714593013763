
/* eslint-disable */import React, { useEffect, useState } from 'react'
import './DonationsDisplay.css'
import DonationCom from '../../../../homePage/sections/donations/donationCom/donationCom'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { FETCH_LOCALLY } from '../../../../../constants/actionTypes';
import { addToBag, deleteFromBag , addToFavorites, deleteFromFavorites} from '../../../../../actions/BagFav'


const DonationsDisplay = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const location = useLocation()

    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const userBag = useSelector(state => state.bag.bag)
    const dispatch = useDispatch()
    const [bagDetails, setBagDetails] = useState(userBag)
    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])
    const [display, setDisplay] = useState(props.allDonationsFromHere)
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    const [change, setChange] = useState(0)
    const userFav = useSelector(state => state.favorites.favorites)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location])

    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
      currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])
    useEffect(() => {
        setDisplay(props.allDonationsFromHere)
    }, [props.allDonationsFromHere])

    const handleLike = async (donationID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile.liked.donations.push(donationID)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
         } else {
            await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: donationID.donationId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
        }
    }
    const handleDisLike = async (donationID) => {
        if (!userLogin) {
            let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.liked.sukuks, projects: currentProfile.liked.projects } }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            let elementWeNeed = favoritesDetails?.donations?.filter(item => item.dontypeID === donationID.donationId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }
    }

    const handleaddToBag = async (donationID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile.cart?.donations.push(donationID)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            await dispatch(addToBag({
                cart: {
                    donID: donationID.donationId, userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
                    userEmail: userLogin.Results[0]?.email, itemType: 0, amount: donationID.amount, recuring: donationID.recuring, cart_serial: null
                }, language: lng === 'arab' ? 1 : 2
            }))
        }

    }
    const handleRemoveFromBag = async (donationID) => {
        if (!userLogin) {
            let newProfile = { cart: { donations: currentProfile.cart.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({type: FETCH_LOCALLY})
            setChange(change + 1)
        } else {
            let elementWeNeed = bagDetails?.donations?.filter(item => item.dontypeID === donationID.donationId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1, ""))
            }
        }
    }
    return (
        <div id='donation_page_display' style={lng === 'arab' ? { direction: 'rtl' } : null}>
            <div className='donation_page_display'>
                {display?.filter(item => item.amount)?.map(item =>
                    <DonationCom currentProfile={currentProfile} setChange={setChange} change={change} handleaddToBag={handleaddToBag} handleRemoveFromBag={handleRemoveFromBag} handleLike={handleLike} handleDisLike={handleDisLike} currentProfile={currentProfile} altImg={item.dontypeName} edit_flag={item.edit_flag} amount={item.amount} donationID={item.dontypeID} key={item.dontypeID} DonImg={item.imagePath} title={item.dontypeName} />
                )}
            </div>
        </div>
    )
}

export default DonationsDisplay
