/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./HumanCase.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import donateIcon from "../../../assets/redDonateIcon.png";
import smsicon from "../../../assets/donateBySMS.png";
import cash from "../../../assets/donateByCash.png";
import { useTranslation } from "react-i18next";
import { startApplePay } from "../../../actions/handleApplePayment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import share from "../../../assets/share.png";
import samPay from "../../../assets/Pay_button_basic_pos_RGB.png";
import { addToBag, deleteFromBag } from "../../../actions/BagFav";
import ShareAlertModel from "../../LoaclComponents/shareHeader/share_model/ShareModelAlert";
import { FETCH_LOCALLY } from "../../../constants/actionTypes";
import { handleSamsungDirectPayment } from "../../../actions/handleSamsungPayment";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

import Loading from "../../ui/loading";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DonationFinalPopUp from "../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";

import SmsModal from "../../ui/smsModal/SmsModal";

import NotFound from "../../NotFound/NotFound";
const HumanCase = () => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [smsModalShow, setSmsModalShow] = useState(false);
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  const [modalShow, setModalShow] = useState(false);
  const [showCopyCard, setshowCopyCard] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const userBag = useSelector((state) => state.bag.bag);
  const [bagDetails, setBagDetails] = useState(userBag);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  const history = useHistory();
  const { pathname } = useLocation();
  const humanCases = useSelector((state) => state.humanCases.humanCases);
  const loading = useSelector((state) => state.humanCases.loading);
  let currentProfile = JSON.parse(localStorage.getItem("profile"));

  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");

  const [itemOfHumanCase, setItemOfHumanCase] = useState({});
  const [presentage, setPresentage] = useState(1);
  const [inTheBag, setInTheBag] = useState(0);
  const [change, setChange] = useState(0);
  const [copied, setCopied] = useState(false);
  const [humanCaseData, setHumanCaseData] = useState({
    humanId: "",
    title: "",
    total: "",
    finalAmount: "",
    humanImg: "",
    donationAmountFromUser: 5,
    caseYear: "",
  });
  const [addingToCart, setAddingToCart] = useState(false);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);
  useEffect(() => {
    if (!userLogin) {
      if (
        currentProfile?.cart?.humanCases?.filter((item) => item.humanId === id)
          .length > 0
      ) {
        setInTheBag(
          currentProfile?.cart?.humanCases?.filter(
            (item) => item.humanId === id
          ).length
        );
      }
    } else {
      if (
        bagDetails?.humanCases?.filter((item) => item.humanCaseID === id)
          .length > 0
      ) {
        setInTheBag(
          bagDetails?.humanCases?.filter((item) => item.humanCaseID === id)
            .length
        );
      }
    }
  }, [currentProfile, id, bagDetails]);

  useEffect(() => {
    // window.scrollTo(0, 0);
    const humanCaseWeNeed = humanCases?.filter((item) => item.caseID === id);
    setItemOfHumanCase(humanCaseWeNeed?.slice(0, 1).shift());
    setHumanCaseData({
      humanId: humanCaseWeNeed?.slice(0, 1).shift()?.caseID,
      title: humanCaseWeNeed?.slice(0, 1).shift()?.caseTitle,
      total: humanCaseWeNeed?.slice(0, 1).shift()?.amountCollected,
      finalAmount: humanCaseWeNeed?.slice(0, 1).shift()?.totalCost,
      humanImg: humanCaseWeNeed?.slice(0, 1).shift()?.imagePath,
      donationAmountFromUser:
        humanCaseWeNeed?.slice(0, 1).shift()?.amountCollected /
          humanCaseWeNeed?.slice(0, 1).shift()?.totalCost >=
        1
          ? t("Completed")
          : 5,
      caseYear: humanCaseWeNeed?.slice(0, 1).shift()?.caseYear,
    });
    setPresentage(
      humanCaseWeNeed?.slice(0, 1).shift()?.amountCollected /
        humanCaseWeNeed?.slice(0, 1).shift()?.totalCost
    );
  }, [id, humanCases]);
  const handleInBag = async (str) => {
    setAddingToCart(true);
    // if (inTheBag) {
    //   if (!userLogin) {
    //     let newProfile = {
    //       cart: {
    //         donations: currentProfile.cart.donations,
    //         sukuks: currentProfile.cart.sukuks,
    //         projects: currentProfile.cart.projects,
    //         humanCases: currentProfile.cart.humanCases.filter(
    //           (item) => !(item.humanId === str.humanId && parseInt(item.baseAmount) === parseInt(str.donationAmountFromUser))
    //         ),
    //         orphans: currentProfile.cart.orphans,
    //       },
    //       liked: currentProfile.liked,
    //     };
    //     localStorage.setItem("profile", JSON.stringify(newProfile));
    //     setChange(change + 1);
    //   } else {
    //     let elementWeNeed = bagDetails?.humanCases
    //       .filter((item) => item.humanCaseID === str.humanId && parseInt(item.baseAmount) === parseInt(str.donationAmountFromUser))
    //       .slice(0, 1)
    //       .shift();
    //     if (elementWeNeed) {
    //       await dispatch(
    //         deleteFromBag(
    //           userLogin.Results[0]?.email,
    //           elementWeNeed.cart_serial,
    //           lng === "eng" ? 2 : 1
    //         )
    //       );
    //     }
    //   }
    // } else {
    if (!userLogin) {
      if (!currentProfile) {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: { donations: [], sukuks: [], projects: [], orphans: [] },
          })
        );
        currentProfile = JSON.parse(localStorage.getItem("profile"));
      }
      currentProfile.cart?.humanCases.push({
        ...str,
        baseAmount: str.donationAmountFromUser,
      });
      const newProfile = {
        cart: currentProfile.cart,
        liked: currentProfile.liked,
      };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      await dispatch({ type: FETCH_LOCALLY });
      toastifyMessage("Added!", undefined, "success");
      setChange(change + 1);
    } else {
      await dispatch(
        addToBag({
          cart: {
            donID: "158",
            userName: userLogin.Results[0]?.name_a,
            userMob: userLogin.Results[0]?.mobile_no,
            userEmail: userLogin.Results[0]?.email,
            itemType: 4,
            amount: str.donationAmountFromUser,
            cart_serial: null,
            humanCase: str.humanId,
            humanYear: str.caseYear,
          },
          language: lng === "arab" ? 1 : 2,
        })
      );
      //}
    }
    if ( _isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut");
    setTimeout(() => {
      if ( _isMounted.current) {
        setAnimationClass("btn_with_text animate__animated animate__bounceIn");
      setAddingToCart(false);
      setInTheBagText("Add to Bag");
      }
      
      //setAnimationClass("btn_with_text");
      //setInTheBagText('Added')
    }, 1000);
    setTimeout(() => {
      //setAddingToCart(false);
      if ( _isMounted.current) {
        setAnimationClass("btn_with_text");
      }
      
      //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
      //setInTheBagText("Add to Bag");
    }, 2000);
    }
    
  };
  function amountNeeded() {
    if (
      !isNaN(itemOfHumanCase?.totalCost) &&
      !isNaN(itemOfHumanCase?.amountCollected)
    ) {
      return itemOfHumanCase?.totalCost - itemOfHumanCase?.amountCollected < 0
        ? 0
        : itemOfHumanCase?.totalCost - itemOfHumanCase?.amountCollected;
    }
  }
  const handleSMS = (caseId) => {
    setSmsModalShow(true);
    //var data = await getSMS_Human(caseId);
  };
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: humanCaseData.donationAmountFromUser,
      url: window.location.href,
      item: [
        {
          humanCase: humanCaseData.humanId,
          amount: humanCaseData.donationAmountFromUser,
          humanYear: humanCaseData.caseYear,
          dontype_id: 158,
          item_type: 4,
        },
      ],
    });
    setLoadingModalShow(false);
  };
  function amountRaised() {
    if (
      !isNaN(itemOfHumanCase?.totalCost) &&
      !isNaN(itemOfHumanCase?.amountCollected)
    ) {
      return itemOfHumanCase?.amountCollected > itemOfHumanCase?.totalCost
        ? itemOfHumanCase?.totalCost
        : itemOfHumanCase?.amountCollected;
    }
  }
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };

  if (loading) {
    return <Loading height={"85vh"} />;
  } else if (itemOfHumanCase) {
    return (
      <div id="human_case_page">
        <ShareHeader
          textToBeCopied={window.location.href}
          pageName={itemOfHumanCase?.caseTitle}
          path={"humancases"}
          shareData={humanCaseData}
        />
        <h1
          className="headerForDesktop notShown"
          style={
            lng === "arab"
              ? { padding: "40px 40px 10px 0px", textAlign: "right" }
              : { padding: "40px 0px 10px 40px" }
          }
        >
          <span
            style={{ paddingBottom: "5px", borderBottom: "3px solid #ee4236" }}
          >
            {t("Support")}
          </span>{" "}
          {itemOfHumanCase?.caseTitle}
        </h1>
        <div className="human_case_display">
          <div
            className="human_case_img"
            style={lng === "arab" ? { padding: "40px 0px 40px 40px" } : null}
          >
            <img
              src={itemOfHumanCase?.imagePath}
              alt={itemOfHumanCase?.caseTitle}
              className="human_img"
            />
            <svg
              height="123"
              width="100%"
              className="svg_board human_case_page_bar"
              style={{ marginRight: "0px" }}
            >
              {itemOfHumanCase?.amountCollected / itemOfHumanCase?.totalCost >=
              1 ? (
                <text textAnchor="end" x="98%" y="20" fill="black">
                  {t("Raised")}
                </text>
              ) : itemOfHumanCase?.amountCollected /
                  itemOfHumanCase?.totalCost >=
                0.7 ? (
                <text
                  textAnchor="end"
                  x={`${Math.round(
                    isNaN(itemOfHumanCase?.amountCollected)
                      ? 0
                      : isNaN(itemOfHumanCase?.totalCost)
                      ? 0
                      : itemOfHumanCase?.amountCollected /
                          itemOfHumanCase?.totalCost >=
                        1
                      ? 1
                      : itemOfHumanCase?.amountCollected /
                        itemOfHumanCase?.totalCost
                  
                  *98)}%`}
                  y="20"
                  fill="black"
                >
                  {t("Raised")}
                </text>
              ) : (
                <text
                  textAnchor="start"
                  x={`${Math.round(
                    isNaN(itemOfHumanCase?.amountCollected)
                      ? 0
                      : isNaN(itemOfHumanCase?.totalCost)
                      ? 0
                      : itemOfHumanCase?.amountCollected /
                          itemOfHumanCase?.totalCost >=
                        1
                      ? 1
                      : itemOfHumanCase?.amountCollected /
                        itemOfHumanCase?.totalCost
                  
                  *98)}%`}
                  y="20"
                  fill="black"
                >
                  {t("Raised")}
                </text>
              )}
              {itemOfHumanCase?.amountCollected / itemOfHumanCase?.totalCost >=
              1 ? (
                <text
                  textAnchor="end"
                  className="text"
                  x="98%"
                  y="37"
                  fill="black"
                >
                  {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(itemOfHumanCase?.totalCost)}</tspan>
                </text>
              ) : itemOfHumanCase?.amountCollected /
                  itemOfHumanCase?.totalCost >=
                0.7 ? (
                <text
                  textAnchor="end"
                  className="text"
                  x={`calc(${
                    itemOfHumanCase?.amountCollected /
                    itemOfHumanCase?.totalCost
                  }*98%)`}
                  y="37"
                  fill="black"
                >
                  {t("AED")}{" "}
                  <tspan className='payment_numbers'>{numberWithCommas(itemOfHumanCase?.amountCollected)}</tspan>
                </text>
              ) : (
                <text
                  textAnchor="start"
                  className="text"
                  x={`${Math.round(
                    isNaN(itemOfHumanCase?.amountCollected)
                      ? 0
                      : isNaN(itemOfHumanCase?.totalCost)
                      ? 0
                      : itemOfHumanCase?.amountCollected /
                          itemOfHumanCase?.totalCost >=
                        1
                      ? 1
                      : itemOfHumanCase?.amountCollected /
                        itemOfHumanCase?.totalCost
                  
                  *98)}%`}
                  y="37"
                  fill="black"
                >
                  {t("AED")}{" "}
                  <tspan className='payment_numbers'>
                  {numberWithCommas(itemOfHumanCase?.amountCollected)}</tspan>
                </text>
              )}

              {itemOfHumanCase?.amountCollected / itemOfHumanCase?.totalCost >=
              1 ? (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  textAnchor="end"
                  className="arrow"
                  x="98%"
                  y="54"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              ) : (
                <text
                  style={{ fontFamily: "FontAwesome" }}
                  className="arrow"
                  x={`${Math.round(
                    isNaN(itemOfHumanCase?.amountCollected)
                      ? 0
                      : isNaN(itemOfHumanCase?.totalCost)
                      ? 0
                      : itemOfHumanCase?.amountCollected /
                          itemOfHumanCase?.totalCost >=
                        1
                      ? 1
                      : itemOfHumanCase?.amountCollected /
                        itemOfHumanCase?.totalCost
                  
                  *98)}%`}
                  y="54"
                  fill="#ee4236"
                >
                  &#xf0dd;
                </text>
              )}
              <line
                x1="10"
                y1="71"
                x2="98%"
                y2="71"
                stroke="grey"
                strokeLinecap="round"
                strokeWidth="20"
              />
              {itemOfHumanCase?.amountCollected / itemOfHumanCase?.totalCost >=
              1 ? (
                <line
                  x1="10"
                  y1="71"
                  x2="98%"
                  y2="71"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="20"
                />
              ) : (
                <line
                  x1="10"
                  y1="71"
                  x2={`${Math.round(
                    isNaN(itemOfHumanCase?.amountCollected)
                      ? 0
                      : isNaN(itemOfHumanCase?.totalCost)
                      ? 0
                      : itemOfHumanCase?.amountCollected /
                          itemOfHumanCase?.totalCost >=
                        1
                      ? 1
                      : itemOfHumanCase?.amountCollected /
                        itemOfHumanCase?.totalCost
                  
                  *98)}%`}
                  y2="71"
                  stroke="#ee4236"
                  strokeLinecap="round"
                  strokeWidth="20"
                />
              )}
              <text x="98%" textAnchor="end" y="101" fill="black">
                {t("Goal")}
              </text>
              <text
                x="98%"
                className="text"
                textAnchor="end"
                y="118"
                fill="black"
              >
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(itemOfHumanCase?.totalCost)}</tspan>
              </text>
            </svg>
          </div>
          <div
            className="human_case_details"
            style={lng === "arab" ? { direction: "rtl" } : null}
          >
            <p
              className="humancases_p"
              style={lng === "arab" ? { textAlign: "right" } : null}
            >
              {itemOfHumanCase?.description}
            </p>
            <svg
              viewBox={width <= 280 ? "0 0 400 400" : null}
              version="1.1"
              xmlns="http://www.we.org/2000/svg"
              width="100%"
              height="310"
              className="circle_svg"
            >
              <text
                x="50%"
                textAnchor="middle"
                y="145"
                className="text_for_svg"
                fill="black"
              >
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(amountRaised())}</tspan>
              </text>
              <text
                style={lng === "arab" ? { direction: "rtl" } : null}
                x="50%"
                textAnchor="middle"
                y={width <= 1150 ? 285 : 305}
                className="text_for_svg_down"
                fill="black"
              >
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(amountRaised())}</tspan> {t("Raised of")}{" "}
                {t("AED")} <tspan className='payment_numbers'>{numberWithCommas(itemOfHumanCase?.totalCost)}</tspan>
              </text>
              <circle cx="50%" cy="130" r="110" fill="none" />
              <circle className="circle-bg_inPage" cx="50%" cy="130" r="110" />
              <circle
                className="circle_inPage"
                cx="50%"
                cy="130"
                r="110"
                strokeLinecap="round"
                strokeDasharray={`calc(${
                  itemOfHumanCase?.amountCollected / itemOfHumanCase?.totalCost
                } * ${3.14 * 2 * 110}) ${3.14 * 2 * 110}`}
              />

              <text
                className="noForMob arrow"
                textAnchor="middle"
                style={{ fontFamily: "FontAwesome", fontSize: "25px" }}
                x="15%"
                y="130"
                fill="gray"
              >
                &#xf500;
              </text>
              <text
                className="noForMob arrow payment_numbers"
                textAnchor="middle"
                x="15%"
                y="147"
                fill="gray"
              >
                {t("Needed")}
              </text>
              <text
                className="noForMob arrow"
                textAnchor="middle"
                style={{ fontWeight: "bold" }}
                x="15%"
                y="164"
                fill="#000"
              >
                {t("AED")}{" "}
                <tspan className='payment_numbers'>{numberWithCommas(amountNeeded() < 0 ? 0 : amountNeeded())}</tspan>
              </text>

              <text
                className="yesForMob arrow"
                textAnchor="middle"
                style={{ fontFamily: "FontAwesome", fontSize: "25px" }}
                x="7%"
                y="130"
                fill="gray"
              >
                &#xf500;
              </text>
              <text
                className="yesForMob arrow payment_numbers"
                textAnchor="middle"
                x="7%"
                y="147"
                fill="gray"
              >
                {t("Needed")}
              </text>
              <text
                className="yesForMob arrow payment_numbers"
                textAnchor="middle"
                style={{ fontWeight: "bold" }}
                x="7%"
                y="164"
                fill="#000"
              >
                {numberWithCommas(amountNeeded() < 0 ? 0 : amountNeeded())}
              </text>

              {/* <rect x='85%' y="120"  width="60" height="40" rx="10" fill="pink" />
                        <text className='yesForMob arrow' textAnchor="middle" style={{ fontWeight: 'normal',fontSize:'16px' }} x='92%' y="145" fill="red">29 %</text> */}
            </svg>
            <div className="human_donation_amount">
              <h4>{t("Donation Amount")}</h4>
              <input
                className={
                  lng === "arab"
                    ? humanCaseData.donationAmountFromUser === t("Completed")? "force_arabic_font donation_amount_input translateFillMsg" 
                    : humanCaseData.donationAmountFromUser ||
                      humanCaseData.donationAmountFromUser === 0
                      ? "payment_numbers donation_amount_input translateFillMsg"
                      : "force_arabic_font donation_amount_input translateFillMsg"
                    : "payment_numbers donation_amount_input translateFillMsg"
                }
                disabled={addingToCart || presentage >= 1}
                type="text"
                pattern="[0-9]*"
                value={humanCaseData.donationAmountFromUser}
                onChange={(e) => {
                  e.target.value =
                    e.target.value > amountNeeded()
                      ? (e.target.value = amountNeeded())
                      : e.target.value;

                  setHumanCaseData({
                    ...humanCaseData,
                    donationAmountFromUser: e.target.validity.valid
                      ? e.target.value
                      : humanCaseData.donationAmountFromUser,
                  });
                }}
                // className="donation_amount_input translateFillMsg"
                placeholder={t("Enter amount in AED")}
              />
            </div>
            <div className={"human_donation_btns"}>
              <div className="human_donation_btns_above">
                <div className="btn_with_text shareBtnDesk">
                  <button
                    onClick={() => setshowCopyCard(true)}
                    style={{ backgroundColor: "transparent" }}
                    className="human_donate_ways btn1"
                  >
                    <img
                      src={share}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Share")}</label>
                </div>
                {inTheBagText === "Added" ? (
                  <div
                    className={
                      "btn_with_text animate__animated animate__bounceIn"
                    }
                  >
                    <button
                      disabled={addingToCart}
                      className="human_donate_ways btn1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <label className="label_to_beSmall">
                        {t("You now have ") + inTheBag + t(" in your Bag")}
                      </label>
                    </button>
                  </div>
                ) : (
                  <div className={animationClass}>
                    {addingToCart ? (
                      <Loading
                        height={"90px"}
                        mediaQ={"80px"}
                        small={"40px"}
                        animate={true}
                      />
                    ) : (
                      <button
                        disabled={addingToCart || presentage >= 1}
                        onClick={() => {
                          if (
                            parseInt(humanCaseData.donationAmountFromUser) >= 5
                          )
                            handleInBag(humanCaseData);
                          else
                            toastifyMessage(
                              "Can not pay less than 5 AED",
                              undefined,
                              "warning"
                            );
                        }}
                        className="human_donate_ways btn1"
                      >
                        <img
                          src={donateIcon}
                          alt="donate_btn_icon_banner"
                          className="donate_btn_icon_ind"
                        />
                      </button>
                    )}
                    <label className="label_to_beSmall">
                      {t(inTheBagText)}
                    </label>
                  </div>
                )}

                {width < 900 ? (
                  <div className="btn_with_text">
                    <button
                      disabled={presentage >= 1}
                      onClick={() => handleSMS(humanCaseData.humanId)}
                      className="human_donate_ways btn2"
                    >
                      <img
                        src={smsicon}
                        alt="donate_btn_icon_banner"
                        className="donate_btn_icon_ind"
                      />
                    </button>
                    <label className="label_to_beSmall">
                      {t("Donate by SMS")}
                    </label>
                  </div>
                ) : null}

                <div className="btn_with_text">
                  <button
                    disabled={presentage >= 1}
                    onClick={() => {
                      if (!(humanCaseData.donationAmountFromUser >= 5)) {
                        toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        );
                      } else
                        history.push(`/checkout`, {
                          from: "humanCases",
                          elements: [
                            {
                              title: humanCaseData.title,
                              price: humanCaseData.donationAmountFromUser,
                            },
                          ],
                          totalAmount: humanCaseData.donationAmountFromUser,
                          details: humanCaseData,
                        });
                    }}
                    className="human_donate_ways btn3"
                  >
                    <img
                      src={cash}
                      alt="donate_btn_icon_banner"
                      className="donate_btn_icon_ind"
                    />
                  </button>
                  <label className="label_to_beSmall">{t("Donate Now")}</label>
                </div>
              </div>
              {window.ApplePaySession ? (
                <div
                  disabled={presentage >= 1}
                  id="applepay_btn"
                  className="apple_human_btn apple-pay-button-outside apple-pay-button-black apple_human_btn samApple_btn"
                  style={{width: "80%", borderRadius: '30px'}}
                  locale="ar-AB"
                  onClick={() =>
                    humanCaseData.donationAmountFromUser < 5
                      ? toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        )
                      : handleGuestPopUp(() =>
                          startApplePay(
                            {
                              cart: [
                                {
                                  donID: "158",
                                  itemType: 4,
                                  amount: humanCaseData.donationAmountFromUser,
                                  cart_serial: null,
                                  humanCase: humanCaseData.humanId,
                                  humanYear: humanCaseData.caseYear,
                                },
                              ],
                              amount: humanCaseData.donationAmountFromUser,
                            },
                            dispatch,
                            history
                          )
                        )
                  }
                ></div>
              ) : (
                <button
                  disabled={presentage >= 1}
                  style={{
                    width: "80%",
                    backgroundColor: "black",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    humanCaseData.donationAmountFromUser < 5
                      ? toastifyMessage(
                          "Can not pay less than 5 AED",
                          undefined,
                          "warning"
                        )
                      : handleGuestPopUp(startSamsungPay)
                  }
                  className="btn btn-block btn-dark apple_human_btn width_for_orphans samApple_btn"
                >
                  <img
                    src={samPay}
                    style={{
                      height: "60px",
                      width: width <= 300 ? "100%" : "auto",
                    }}
                    alt="samsung_pay"
                    className='samApple_btn_img'
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        {/* {modalShow ? (
          <Modal
            show={modalShow}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="donation_pop_up_body"
          >
            <Modal.Body
              style={{ backgroundColor: "#fdfdfd", borderRadius: "10px" }}
            >
              <div
                className="modal_body_for_donation_final"
                style={lng === "arab" ? { direction: "rtl" } : null}
              >
                <label>
                  {t("Would you like to")}{" "}
                  <span style={{ fontWeight: "bold" }}>{t("Register")}</span>{" "}
                  {t("for easier donations in the future?")}
                </label>
                <div className="donation_final_popUp_btns_wraper">
                  <button
                    onClick={() => history.push("/guest")}
                    className="donation_final_popUp_btn_first"
                  >
                    {t("Not Now")}
                  </button>
                  <button
                    onClick={() => history.push("/auth")}
                    className="donation_final_popUp_btn_second"
                  >
                    {t("Yes")}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : null} */}
        <SmsModal
          show={smsModalShow}
          humanCase={true}
          donationid={humanCaseData.humanId}
          onHide={() => setSmsModalShow(false)}
        />
        <ShareAlertModel
          path={"humancases"}
          shareData={humanCaseData}
          show={showCopyCard}
          onHide={() => {
            setshowCopyCard(false);
          }}
        ></ShareAlertModel>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
        <DonationFinalPopUp
          show={geustModal}
          paymentfunction={functionToSend}
          onHide={() => setGuestModal(false)}
          dialogClassName="modal-60w"
        />
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default HumanCase;
