import React, {useEffect, useState} from 'react'
import './LocalAidReg.css'
import LocalAidInfoForm from '../localAidRequestInfoForm/LocalAidInfoForm'
import LocalAidRelation from '../LocalAidRelation/LocalAidRelation'
import LocalAidRelativeIdentity from '../localAidRelativeIdentity/LocalAidRelativeIdentity'
import LocalAidRelativeAddress from '../RelativeAddress/RelativeAddress'
import UploadLocalAidFile from '../uploadFile/UploadLocalAidFile'
import { useLocation, useHistory } from "react-router-dom";
import Loading from '../../ui/loading'
import { useTranslation } from "react-i18next";
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader'
import icon12 from "../../../assets/email.png";
import icon1 from "../../../assets/nation id.png";
import icon2 from "../../../assets/relative.png";
import icon3 from "../../../assets/ntionality.png"
import icon4 from "../../../assets/requests.png";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

import * as api from '../../../api'
import { checkLocalAidOpen } from '../../../api/local_aid'



const LocalAidReg = () => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const { t } = useTranslation();
    const location = useLocation();
    const [loadingDisplay, setLoadingDisplay] = useState(true);
    const history = useHistory()
    const [view, setView] = useState(0);
    const [reqID, setReqID] = useState("");
    const [reqYear, setReqYear] = useState("");
    const [disableAllFromTheFirst, setDisableAllFromFirst] = useState(false);
    const [disableAllFromTheEnd, setDisableAllFromEnd] = useState(false);
    const [submitRefetch, setSubmitRefetch] = useState(0);
    const [allformsData, setAllFormsData] = useState({});
    const [removePagination, setRemovePagination] = useState(false);
    const [allowRegistration, setAllowRegistration] = useState(false)


    useEffect(() => {
      checkLocalAidOpen().then((res) => {
        if(!res.data.status)
          window.open('/profile/', "_self")
        else
          setAllowRegistration(true)
      })
        },[])
    
    useEffect(() => {
      let mounted = true
        //for redirecting
        if (!userLogin) {
          history.push('/auth', {from:'Loacal Aid'})
        } else if (userLogin?.Results[0]?.person_id_flag === "1") {
          setRemovePagination(true)
          setLoadingDisplay(false)
          setView(5)
          setDisableAllFromEnd(true)
        } else if (userLogin?.Results[0]?.person_id_flag === "2") {
            window.open('/localAid/', "_self")
        } else if (userLogin?.Results[0]?.aids_req_id && userLogin?.Results[0]?.person_id_flag === "0") {
          setDisableAllFromFirst(false)
           api.getLocalAIDData(userLogin?.Results[0]?.aids_req_id, userLogin?.Results[0]?.aids_req_year, lng==='arab'? 1:2)
              .then(async response => {
                if (mounted) {
                  if (response.data.status) {
                 setAllFormsData(response.data.data)
                  setReqID(userLogin?.Results[0]?.aids_req_id)
                  setReqYear(userLogin?.Results[0]?.aids_req_year)
                  if (response.data.data.identity?.length===0) {
                    setView(1)
                    setLoadingDisplay(false)
                    
                  } else if (response.data.data.family?.length===0) {
                    setView(2)
                    setLoadingDisplay(false)
                    
                  } else if (response.data.data.address?.length===0) {
                    setView(3)
                    setLoadingDisplay(false)
                    
                  } else if (response.data.data.attachment?.length===0) {
                    setView(4)
                    setLoadingDisplay(false)
                    
                  }
                  setLoadingDisplay(false)
                } else {
                  toastifyMessage("Something went wrong...!", undefined, "error");
                }
                }
                
              }).catch((error) => {
                toastifyMessage("Something went wrong...!", undefined, "error");
              });
          //setLoadingDisplay(false)
          
          //setView(0);
        } else if (!userLogin?.Results[0]?.aids_req_id && !userLogin?.Results[0]?.aids_req_year) {
            setLoadingDisplay(false)
            setView(0)
            setDisableAllFromFirst(true)
        } else {
            window.open('/profile/', "_self")
        }
        return () => {
          mounted = false
        }
      }, [submitRefetch]);
      if (loadingDisplay) {
          return (
              <Loading height={'85vh'}/>
          )
      } else {
          return (
            allowRegistration? <div className='LA_Register_wraper'>
            <ShareHeader noShare={true} pageName={t("Local Aid Registration")} />
            <div className='Local_aid_reg_Header'>
              <div className="submit_requ_header" style={{ borderBottom: "none" }}>
                <h2 style={{ color: "gray", fontWeight: "900" }}>{t("Local Aid registration")}</h2>
             </div>
             {removePagination?
            null:
            <div className='local_aid_steps_pagination'>
              <div className='background_line'/>
              <button className='steps_element' disabled={disableAllFromTheEnd} onClick={()=> disableAllFromTheEnd? null:setView(0)}>
                {userLogin?.Results[0]?.aids_req_id && userLogin?.Results[0]?.person_id_flag === "0"?
                <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>:
                <img src={icon12} alt='general_info_icon' className='info_step_icon'/>
              }
                
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===0? '1px solid #ee4236':'unset'}}>{t("Step one")}</label>
                
              </button>
              <button className='steps_element' disabled={disableAllFromTheEnd} onClick={()=> disableAllFromTheFirst? null:disableAllFromTheEnd? null: setView(1)}>
              {allformsData?.identity?.length>0?
                <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>:
                <img src={icon1} alt='general_info_icon' className='info_step_icon'/>}
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===1? '1px solid #ee4236':'unset'}}>{t("Step two")}</label>
              </button>
              <button className='steps_element' disabled={disableAllFromTheEnd} onClick={()=>disableAllFromTheFirst? null: disableAllFromTheEnd? null: setView(2)}>
              {allformsData?.family?.length>0?
                <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>:
                <img src={icon2} alt='general_info_icon' className='info_step_icon'/>}
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===2? '1px solid #ee4236':'unset'}}>{t("Step three")}</label>
              </button>
              <button className='steps_element' disabled={disableAllFromTheEnd} onClick={()=>disableAllFromTheFirst? null: disableAllFromTheEnd? null: setView(3)}>
              {allformsData?.address?.length>0?
                <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>:
                <img src={icon3} alt='general_info_icon' className='info_step_icon'/>}
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===3? '1px solid #ee4236':'unset'}}>{t("Step four")}</label>
              </button>
              <button className='steps_element' disabled={disableAllFromTheEnd} onClick={()=>disableAllFromTheFirst? null: disableAllFromTheEnd? null: setView(4)}>
              {allformsData?.attachment?.length>0?
                <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>:
                <img src={icon4} alt='general_info_icon' className='info_step_icon'/>}
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===4? '1px solid #ee4236':'unset'}}>{t("Step five")}</label>
              </button>
              <button className='steps_element' disabled={!disableAllFromTheEnd}>
              <svg className='info_step_icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{margin:'0px'}}>
                  <circle className="path circle_for_right" fill="none" stroke={!disableAllFromTheEnd? 'gray':"#ee4236"} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" style={{fill:'white'}} />
                  <polyline className="path check" fill="none" stroke={!disableAllFromTheEnd? 'gray':"#ee4236"} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
                <label style={{padding:'0px 0px 3px 0px', borderBottom:view===5? '1px solid #ee4236':'unset'}}>{t("Final step")}</label>
              </button>
            </div>
            }
            
            </div>
            {view===0?
            <LocalAidInfoForm submitRefetch={submitRefetch} setSubmitRefetch={setSubmitRefetch} setReqID={setReqID} setReqYear={setReqYear} setView={setView} allformsData={allformsData} view={view}/>:
            view===1?
            <LocalAidRelativeIdentity submitRefetch={submitRefetch} setSubmitRefetch={setSubmitRefetch} reqID={reqID} reqYear={reqYear} setView={setView} allformsData={allformsData} view={view}/>:
            view===2?
            <LocalAidRelation submitRefetch={submitRefetch} setSubmitRefetch={setSubmitRefetch} reqID={reqID} reqYear={reqYear}  setView={setView} allformsData={allformsData} view={view}/>:
            view===3?
            <LocalAidRelativeAddress submitRefetch={submitRefetch} setSubmitRefetch={setSubmitRefetch} reqID={reqID} reqYear={reqYear} setView={setView} allformsData={allformsData} view={view}/>:
            view===4?
            <UploadLocalAidFile submitRefetch={submitRefetch} setSubmitRefetch={setSubmitRefetch} reqID={reqID} reqYear={reqYear} setView={setView} allformsData={allformsData} view={view}/>:

            <div style={{minHeight: '700px', flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center', width:'100%', gap:'10px'}}>
            <svg className='svg_right_sign' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle className="path circle_for_right" fill="none" stroke="#ee4236" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <polyline className="path check" fill="none" stroke="#ee4236" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
            <h2>{t("Thanks for your Registration")}</h2>
            <h4 style={{textAlign:'center', direction:lng==='arab'? 'rtl':'ltr'}}><label>{t("We are now reviewing your ")} <span style={{fontWeight:'bold'}}>{t("request for creating Local Aid account, ")}</span> {t("Please wait.")}</label></h4>
          </div>
        }
        </div>: null
    )
      }
    
}

export default LocalAidReg