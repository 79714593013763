import { FETCH_ALL_GRACE } from "../../constants/actionTypes";

export default (allgrace = [], action) => {
  switch (action.type) {
    case FETCH_ALL_GRACE:
      return action.payload;
    default:
      return allgrace;
  }
};
