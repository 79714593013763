import React, { useEffect, useState } from "react";
import './projects.css'
import projectBC from '../../../../assets/project_banner.jpg'
import ProjectsCom from "./projectsCom/projectsCom";
import Carousel from 'react-bootstrap/Carousel'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Loading from "../../../ui/loading";

const Projects = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const history = useHistory()
    const projects = useSelector(state => state.fullProjects.fullProjects);
    const projectsLoading = useSelector(state => state.fullProjects.loading);
    const [first16, setFirst16] = useState([])
    const [projectsLoadingState, setProjectsLoadingState] = useState(projectsLoading)

    useEffect(() => {
        setProjectsLoadingState(projectsLoading)
    }, [projectsLoading])
    useEffect(() => {
        let projectsByTheSameProjectType = projects.slice(0);
        let projectsIDS = projects.map(item => item.projTypeID);
        let uniqueProjects = []
        for (let i = 0; i < projectsByTheSameProjectType.length; i++) {
            projectsIDS.splice(projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID), 1)
            if (projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID) < 0)
                uniqueProjects.push(projectsByTheSameProjectType[i]);
        }
        // var num1 = Math.floor(Math.random() * (uniqueProjects.length - 17));
        // var num2 = num1 + 16;
        setFirst16([...uniqueProjects?.slice(0,  16)])
    }, [projects])
    return (
        <section id='projects_sec'>
            <label className='section_title_projects' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontSize: '25px', width: '100%', textAlign: 'left' }}>{t('Charity projects')}</label>
            <div className='project_header' style={{ backgroundImage: `url(${projectBC})` }}>
                <label style={{ fontSize: '25px', color: 'white', fontWeight: 'normal', textShadow: '1px 0px 8px gray' }}>{t('Together')}</label>
                <label style={{ fontSize: '25px', color: 'white', fontWeight: 'normal', textShadow: '1px 0px 8px gray' }}>{t('Help Build The World')}</label>
                <button style={lng === 'arab' ? { direction: 'rtl', boxShadow: '#0000004d 0px 1px 5px' } : { boxShadow: '1px 0px 8px black' }} onClick={() => history.push('/projects/all')} className='project_header_btn'>{t('View All Charity Projects')} <span style={{ textAlign: 'left' }}>{lng === 'arab' ? <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-right"></i>}</span></button>
            </div>
            {!projectsLoadingState?
            <React.Fragment>
                <Carousel id='projects'>
                    <Carousel.Item interval={15000}>
                        <div className='projects_display'>
                            {first16?.slice(0, 2).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                        <div className='projects_display'>
                            {first16?.slice(2, 4).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={15000}>
                        <div className='projects_display'>
                            {first16?.slice(4, 6).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                        <div className='projects_display'>
                            {first16?.slice(6, 8).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={15000}>
                        <div className='projects_display'>
                            {first16?.slice(8, 10).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                        <div className='projects_display'>
                            {first16?.slice(10, 12).map(item =>
                                <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                            )}
                        </div>
                    </Carousel.Item>
                </Carousel> 
            <Carousel id='projects_for_Mobile'>
                <Carousel.Item interval={15000}>
                    <div className='projects_display'>
                        {first16?.slice(0, 4).map(item =>
                            <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                        )}
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={15000}>
                    <div className='projects_display'>
                        {first16?.slice(4, 8).map(item =>
                            <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                        )}
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={15000}>
                    <div className='projects_display'>
                        {first16?.slice(8, 12).map(item =>
                            <ProjectsCom key={item?.projTypeID} projectType={item?.projTypeID} img={item.projTypeImagePath} title={item?.prjTypeName} project_img_alt={item.prjTypeName} />
                        )}
                    </div>
                </Carousel.Item>
            </Carousel> 
            </React.Fragment>:
                <Loading />
            }

        </section>
    )
}
export default Projects