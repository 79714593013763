import { FETCH_EVENT_DETAILS, FETCH_LOADING_EVENT_DETAILS, FETCH_LOADED_EVENT_DETAILS } from '../../constants/actionTypes'

export default (vlnEventDetails = {
    vlnEventDetails: [],
    loading: true
}, action) => {
    switch (action.type) {
        case FETCH_EVENT_DETAILS:
            return {vlnEventDetails: action.payload, loading:false};
        case FETCH_LOADING_EVENT_DETAILS:
                return {...vlnEventDetails, loading:true};
        case FETCH_LOADED_EVENT_DETAILS:
                return {...vlnEventDetails, loading:false};
        default:
            return vlnEventDetails;
    }
}