import React, { useState, useEffect } from "react";
import "./LocalAidInfoForm.css";
import icon1 from "../../../assets/nation id.png";
import icon2 from "../../../assets/edducation.png";
import icon3 from "../../../assets/ntionality.png";
import icon4 from "../../../assets/mobile number.png";
import icon5 from "../../../assets/date of birth.png";
import icon6 from "../../../assets/gender.png";
import icon8 from "../../../assets/occupation.png";
import icon9 from "../../../assets/marital status.png";
import icon10 from "../../../assets/health.png";
import icon12 from "../../../assets/email.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createNewLocalAID } from "../../../api/local_aid";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
  getAllCountries,
  getEdu,
  getAllBranches,
  getJobs,
  getHealths,
  getMarriage,
} from "../../../actions/volunteer";
import { NEW_LAID_ACCOUNT } from "../../../constants/actionTypes";
import OTPModal from "./otp_model/OTP_MODEL";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../../handlers/formValidation";

const LocalAidInfoForm = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (lng === "arab") {
      dispatch(getAllCountries(1));
      dispatch(getEdu(1));
      dispatch(getAllBranches(1));
    } else {
      dispatch(getAllCountries(2));
      dispatch(getEdu(2));
      dispatch(getAllBranches(2));
    }
    dispatch(getJobs(lng == "arab" ? 1 : 2));
    dispatch(getHealths(lng == "arab" ? 1 : 2));
    dispatch(getMarriage(lng == "arab" ? 1 : 2));
  }, [lng]);
  const vlnBranches = useSelector((state) => state.vlnBranches);
  const [vlnAllBranches, setVlnAllBranches] = useState(vlnBranches || []);
  useEffect(() => {
    setVlnAllBranches(vlnBranches || []);
  }, [vlnBranches]);
  const vlnJobs = useSelector((state) => state.vlnJobs);
  const [vlnAllJobs, setvlnAllJobs] = useState(vlnJobs || []);
  useEffect(() => {
    setvlnAllJobs(vlnJobs || []);
  }, [vlnJobs]);
  const vlnHealths = useSelector((state) => state.vlnHealths);
  const [vlnAllHealths, setvlnAllHealths] = useState(vlnHealths || []);
  useEffect(() => {
    setvlnAllHealths(vlnHealths || []);
  }, [vlnHealths]);

  const vlnEducation = useSelector((state) => state.vlnEdu);
  const [vlnEducationAll, setVlnEducationAll] = useState(
    vlnEducation?.allDegreeTypes
  );
  const [vlnEducationAllSub, setVlnEducationAllSub] = useState(
    vlnEducation?.allDegreeDetails
  );
  // var mydate = getUserInfo()?.DOB.split("/");
  //const dateSplitted = getUserInfo()?.DOB ? getUserInfo().DOB.split("/") : null;

  const [userInfoLocalAid, setUserInfoLocalAid] = useState({
    name: props.allformsData.req_id ? props.allformsData?.name: userLogin?.Results[0]?.name_a? userLogin?.Results[0]?.name_a:'',
    familyName: props.allformsData.req_id ? props.allformsData?.familyName:"",
    dob: props.allformsData.req_id ? props.allformsData?.dob?.split('/').reverse()?.join('-') : userLogin?.Results[0]?.DOB? userLogin?.Results[0]?.DOB?.split('/').reverse().join('-') : "",
    gender: props.allformsData.req_id ? props.allformsData?.gender:"",
    mobileNo: props.allformsData.req_id ? props.allformsData?.mobile: userLogin?.Results[0]?.mobile_no? userLogin?.Results[0]?.mobile_no: "",
    countryID: props.allformsData.req_id ? props.allformsData?.countryID:"",
    marriageID: props.allformsData.req_id ? props.allformsData?.marriageID:"",
    emiratesID: props.allformsData.req_id ? props.allformsData?.emiratesID: userLogin?.Results[0]?.emiratesID?  userLogin?.Results[0]?.emiratesID: "",
    edu_levelID: props.allformsData.req_id ? props.allformsData?.educationID:"",
    qlf_ID: props.allformsData.req_id ? props.allformsData?.educationQualificationID:"",
    workplace_ID: props.allformsData.req_id ? props.allformsData?.workplaceID:"",
    job_ID: props.allformsData.req_id ? props.allformsData?.jobID:"",
    health_status_ID: props.allformsData.req_id ? props.allformsData?.healthID:"",
    email: userLogin?.Results[0]?.email? userLogin?.Results[0]?.email: "",
    password: "",
    login_flag: 1,
  });
  useEffect(() => {
    setVlnEducationAll(vlnEducation?.allDegreeTypes);
    setVlnEducationAllSub(vlnEducation?.allDegreeDetails);
  }, [vlnEducation]);
  useEffect(() => {
    if (userInfoLocalAid?.edu_levelID) {
      setVlnEducationAllSub(
        vlnEducation.allDegreeDetails?.filter((element) => {
          return element.edu_level_ID == userInfoLocalAid.edu_levelID;
        })
      );
    } else {
      setVlnEducationAllSub(vlnEducation?.allDegreeDetails);
    }
  }, [userInfoLocalAid]);
  const vlnCountries = useSelector((state) => state.vlnCountries);
  const [vlnAllCountries, setVlnAllCountries] = useState(vlnCountries);
  useEffect(() => {
    setVlnAllCountries(vlnCountries);
  }, [vlnCountries]);
  const vlnMarriage = useSelector((state) => state.vlnMarriage);
  const [vlnAllMarriage, setVlnAllMarriage] = useState(vlnMarriage);
  useEffect(() => {
    setVlnAllMarriage(vlnMarriage);
  }, [vlnMarriage]);

  const handleChange = (e) => {
    document.getElementById("submitBtn_for_local_aid").disabled = false;
    switch (e.target.name) {
      case "emiratesID":
        setUserInfoLocalAid({
          ...userInfoLocalAid,
          [e.target.name]: e.target.value.replace(/\D/, ""),
        });
        break;
      case "mobileNo":
        setUserInfoLocalAid({
          ...userInfoLocalAid,
          [e.target.name]: e.target.value.replace(/\D/, ""),
        });
        break;
      default:
        setUserInfoLocalAid({
          ...userInfoLocalAid,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateMobile(userInfoLocalAid.mobileNo)) {
      toastifyMessage(
        '\"Mobile number\" should be 10 digits long and start with \"05\"',
        undefined,
        "warning"
      );
      return false;
    }
    else if (!validateEmiratesId(userInfoLocalAid.emiratesID)) {
      toastifyMessage(
        "EmiratesID number should be 15 digits long",
        undefined,
        "warning"
      );
      return false;
    }
    if (userInfoLocalAid.dob.split('-')[0].length>4 || parseInt(userInfoLocalAid.dob.split('-')[0]) <  1850) {
      return toastifyMessage("Please choose a valid date", undefined, "warning");
    }

    let data = {
      ...userInfoLocalAid,
      dob: userInfoLocalAid?.dob.split("-")
        .reverse()
        .join("/"),
      // emiratesID: parseInt(userInfoLocalAid.emiratesID),
      emiratesID: userInfoLocalAid.emiratesID,
      mobileNo: userInfoLocalAid.mobileNo,
      job_ID: userInfoLocalAid.job_ID,
      countryID: userInfoLocalAid.countryID,
      qlf_ID: userInfoLocalAid.qlf_ID,
      workplace_ID: userInfoLocalAid.workplace_ID,
      edu_levelID: userInfoLocalAid.edu_levelID,
      marriageID: userInfoLocalAid.marriageID,
      health_status_ID: userInfoLocalAid.health_status_ID,
    };
    if (userInfoLocalAid.dob.split('-')[0].length>4 || parseInt(userInfoLocalAid.dob.split('-')[0]) <  1850) {
      return toastifyMessage("Please choose a valid date", undefined, "warning");
    }

    setLoadingModalShow(true);
    
    createNewLocalAID(data)
      .then((res) => {
        if (res.data.status) {
          // let newAcc = {...userLogin.Results[0],aids_req_id:  res.data.data.aids_req_id, aids_req_year: res.data.data.aids_req_year}
          //userLogin.Results[0].aids_req_id = res.data.data.aids_req_id
          //userLogin.Results[0].aids_req_year = res.data.data.aids_req_year
          let oldData = JSON.parse(localStorage.getItem("userLogin"));
          localStorage.removeItem("userLogin")
          const userLogin = {
            Results: [
              {
                DOB: data.dob,
                aids_req_id: res.data.data.aids_req_id,
                aids_req_year: res.data.data.aids_req_year,
                email: oldData.Results[0].email,
                emiratesID: data.emiratesID,
                extra_spons: oldData.Results[0].extra_spons,
                mobile_no: oldData.Results[0].mobile_no? oldData.Results[0].mobile_no : data.mobileNo,
                name_a: oldData.Results[0].name_a,
                person_id: oldData.Results[0].person_id,
                person_id_flag: oldData.Results[0].person_id_flag,
                profileImage: oldData.Results[0].profileImage,
                sponser_id: oldData.Results[0].sponser_id,
                uniq_id: oldData.Results[0].uniq_id,
                user_tokens: oldData.Results[0].user_tokens,
                vln_id: oldData.Results[0].vln_id,
                vln_id_flag: oldData.Results[0].vln_id_flag,
              },
            ],
          }
          // localStorage.removeItem('userLogin')
          // localStorage.setItem('userLogin', JSON.stringify({newAcc}))
          dispatch({ type: NEW_LAID_ACCOUNT, payload: userLogin });
          setLoadingModalShow(false);
          props.setReqID(res.data.data.aids_req_id)
          props.setReqYear(res.data.data.aids_req_year)
          props.setSubmitRefetch(props.submitRefetch + 1)
          //props.setView(1)
        } else {
          if (res.data.result === "You are already registered") {
            setLoadingModalShow(false);
            
            // convert based on language here
            toastifyMessage(
              lng === "arab" ?
                "انت بالفعل لديك طلب" :
                "You already have made a local aid request with the same information",
              undefined,
              "error"
            );
          }
          else {
            setLoadingModalShow(false);
            toastifyMessage(
              "Error while creating new local AID",
              undefined,
              "error"
            );
          }
        }
      })
      .catch((e) => {
        setLoadingModalShow(false);
        toastifyMessage(
          "Error while creating new local AID",
          undefined,
          "warning"
        );
      })
      .finally(() => {
        setLoadingModalShow(false);
      });
  };
  const [openOTP, setOTPOpen] = useState(false);
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    return re.test(phone);
  };

  const validateEmiratesId = (eId) => {
    const re = /^[0-9]{15}$/;
    return re.test(eId);
  };

//first form

    return (
      <div
        className="local_aid_info_form_page"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
        <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
          {t("Setup your information")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          <div className="just_the_form_without_submit">
            <div className="form_left_side">

              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img
                    src={icon1}
                    className="local_aid_icon"
                    alt="form_icon"
                  />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userInfoLocalAid.name}
                  name="name"
                  placeholder={t("Name")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  disabled={props.allformsData.req_id}
                />
              </div>

              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userInfoLocalAid.familyName}
                  name="familyName"
                  placeholder={t("Family Name")}
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
              </div>

              <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}><label>{t("Date of Birth")}</label>
                <div className="input_wraper" style={{ width: '100%' }}>
                  <div className="local_aid_input_icon_wraper">
                    <img
                      src={icon5}
                      className="local_aid_icon"
                      alt="form_icon"
                    />
                  </div>
                  <input
                    type="date"
                    onChange={handleChange}
                    className="local_aid_input"
                    value={userInfoLocalAid.dob}
                    // value="2014-10-13"
                    name="dob"
                    placeholder={t("Date of Birth")}
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                    disabled={props.allformsData.req_id}
                    onKeyDown={(e) => {
                      e.preventDefault();
                   }}
                    min={`${new Date().getFullYear() - 100}-01-02`}
                    max={`${new Date().getFullYear() - 18}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
                  />
                </div></div>

              <div className="input_wraper">
                <img src={icon6} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.gender}
                  onChange={handleChange}
                  name="gender"
                  className="selectedCourse"
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                >
                  <option value="" disabled defaultValue>
                    {t("Select your gender")}
                  </option>
                  <option value="F">{t("Female")}</option>
                  <option value="M">{t("Male")}</option>
                </select>
              </div>

              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img
                    src={icon4}
                    className="local_aid_icon"
                    alt="form_icon"
                  />
                </div>
                <input
                  type="tel"
                  onChange={handleChange}
                  className={userInfoLocalAid.mobileNo? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userInfoLocalAid.mobileNo}
                  name="mobileNo"
                  placeholder={t("Mobile Number")}
                  maxLength={10}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  disabled={props.allformsData.req_id}
                />
              </div>


              <div className="input_wraper">
                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.countryID}
                  onChange={handleChange}
                  name="countryID"
                  className="selectedCourse"
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  required
                >
                  <option value="" disabled defaultValue>
                    {t("Country")}
                  </option>
                  {vlnAllCountries?.map((item, i) => (
                    <option key={i} value={item.countryID}>
                      {item.countryName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input_wraper">
                <img src={icon9} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.marriageID}
                  onChange={handleChange}
                  name="marriageID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Select Martial Status")}
                  </option>
                  {vlnAllMarriage?.map((item, i) => (
                    <option key={i} value={item.martstat_id}>
                      {item.martstat_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form_right_side">
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img
                    src={icon1}
                    className="local_aid_icon"
                    alt="form_icon"
                  />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={userInfoLocalAid.emiratesID? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userInfoLocalAid.emiratesID}
                  name="emiratesID"
                  placeholder={t("National ID")}
                  maxLength="15"
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  disabled={props.allformsData.req_id  || userLogin?.Results[0]?.emiratesID}
                />
              </div>

              <div className="input_wraper">
                <img src={icon2} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.edu_levelID}
                  onChange={handleChange}
                  name="edu_levelID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Education Level")}
                  </option>
                  {vlnEducationAll?.map((item, i) => (
                    <option key={i} value={item.edu_level_id}>
                      {item.edu_level_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_wraper">
                <img src={icon2} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.qlf_ID}
                  onChange={handleChange}
                  name="qlf_ID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Education Qualification")}
                  </option>
                  {vlnEducationAllSub?.map((item, i) => (
                    <option key={i} value={item.qlfID}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_wraper">
                <img src={icon8} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.workplace_ID}
                  onChange={handleChange}
                  name="workplace_ID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Select Branch")}
                  </option>
                  {vlnAllBranches?.map((item, i) => (
                    <option key={item.workplace_id} value={item.workplace_id}>
                      {item.workplace_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_wraper">
                <img src={icon8} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.job_ID}
                  onChange={handleChange}
                  name="job_ID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Job")}
                  </option>
                  {vlnAllJobs?.map((item, i) => (
                    <option key={item.job_id} value={item.job_id}>
                      {item.job_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_wraper">
                <img src={icon10} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.health_status_ID}
                  onChange={handleChange}
                  name="health_status_ID"
                  className="selectedCourse"
                  required
                  disabled={props.allformsData.req_id }
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Health Status")}
                  </option>
                  {vlnAllHealths?.map((item, i) => (
                    <option
                      key={item.health_status_id}
                      value={item.health_status_id}
                    >
                      {item.health_status_name}
                    </option>
                  ))}
                </select>
              </div>


              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img
                    src={icon12}
                    className="local_aid_icon"
                    alt="form_icon"
                  />
                </div>
                <input
                  type="email"
                  onChange={handleChange}
                  className={userInfoLocalAid.email? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userInfoLocalAid.email}
                  name="email"
                  placeholder={t("E-mail")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                  disabled={props.allformsData.req_id  || userLogin?.Results[0]?.email}
                />
              </div>

            </div>
          </div>
          {props.allformsData.req_id ?
          null:
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
            

          >
            <span style={{ flex: "16" }}>{t("Submit")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        }
          
        </form>

        <OTPModal
          show={openOTP}
          onHide={() => setOTPOpen(false)}
          email={userInfoLocalAid.email}
        />
      </div>
    );
};

export default LocalAidInfoForm;
