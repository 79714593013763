import {
  ALL_ORPHONS_LATE_PAYMENTS,
  ALL_ORPHON_LATE_PAYMENTS, ALL_ORPHON_LATE_PAYMENTS_LOADING,
  ALL_ORPHON_LATE_PAYMENTS_ERROR
} from "../constants/actionTypes";

export default (
  state = {
    latePayments: [],
    latePaymentLoading: false,
    orplatePayments: [],
  },
  action
) => {
  switch (action.type) {
    case ALL_ORPHON_LATE_PAYMENTS:
      return { ...state, orplatePayments: action?.payload, latePaymentLoading: false };
    case ALL_ORPHONS_LATE_PAYMENTS:
      return { ...state, latePayments: action?.payload, latePaymentLoading: false };
    case ALL_ORPHON_LATE_PAYMENTS_ERROR:
      return { ...state, latePaymentLoading: false };
    case ALL_ORPHON_LATE_PAYMENTS_LOADING:
      return { ...state, latePaymentLoading: true };
    default:
      return state;
  }
};
