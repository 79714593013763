import React, { useState, useEffect } from 'react'
import './becomeASpon.css'
import BecomeASponCom from './becomeASponCom/becomeASponCom'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import Loading from "../../../ui/loading"

SwiperCore.use([Navigation])

const BecomeASpon = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const orphanSliders = useSelector(state => state.countriesForOrphan.countriesForOrphan);
    const [orphanAfter, setOrphanAfter] = useState([])
    const [view, setView] = useState(5)
    useEffect(() => {
        let projectsIDS = orphanSliders.map(item => item.orptypeID);
        let uniqueProjects = []
        for (let i = 0; i < orphanSliders.length; i++) {
            projectsIDS.splice(projectsIDS.indexOf(orphanSliders[i].orptypeID), 1)
            if (projectsIDS.indexOf(orphanSliders[i].orptypeID) < 0)
                uniqueProjects.push(orphanSliders[i]);
        }
        setOrphanAfter(uniqueProjects)
    }, [orphanSliders])
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(() => {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(6)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(5.8)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(5.5)
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(4.5)
        } else if (window.innerWidth > 1300 && window.innerWidth <= 1600) {
            setView(4)
        }
        else if (window.innerWidth > 1000 && window.innerWidth <= 1300) {
            setView(3.2)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(3)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(3)
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(3)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(3)
        } else if (window.innerWidth > 240 && window.innerWidth <= 500) {
            setView(3)
        } else {
            setView(2.2)
        }
    }, [width])
    return (
        <section id='becomeASpon_sec'>
            <label className='all_section_title' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontSize: '25px', width: '100%', textAlign: 'left' }}>{t("Become a Sponsor")}</label>
            {orphanAfter.length > 0 ?
                <div className='becomeASpon' style={lng === 'arab' ? { flexDirection: 'row-reverse', direction: 'rtl' } : null}>
                    <React.Fragment>
                        <Swiper loop={orphanAfter?.length > view? true:false} className='please removingMargin' style={{ width: '100%' }} slidesPerView={view} navigation={true} >
                            {orphanAfter?.map((item, i) =>
                                <SwiperSlide className='donationChild' key={i}>
                                    <BecomeASponCom key={i} becomeASponId={item.orptypeID} alt_text={item.orptypeName} image={item.orptypeImagePath} text={item.orptypeName} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </React.Fragment>

                </div> :
                <Loading />
            }
        </section>
    )
}
export default BecomeASpon