import React, {useState, useEffect} from 'react'
import './CompleteInfoModal.css'
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";

import EditProfile from '../../../userPage/EditProfile/EditProfile'

const CompleteInfoModal = (props) => {
  const { lodaingswitchprop,  ...rest } = props
    const [lodaingSwitch, setLoadingSwitch] = useState(false)
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;

    return (
        <Modal
          {...rest}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="payment_Modal"
        >
          <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
            <div className='complet_info_title' style={{backgroundColor:'rgb(255 252 252)',width:'100%', display:'flex',borderTopRightRadius:'15px',borderTopLeftRadius:'15px', alignItems:'center', justifyContent:'space-between', padding:'10px', minHeight:'100px', direction: lng==='arab'? 'rtl':'ltr'}}>
                <h5>{t("Complete Your Personal Information")}</h5>
            </div>
              {lodaingSwitch?
              <div className="Spinner">
              <div className="spinner-border text-danger" role="status">
              </div>
            </div>:
            <EditProfile usertype={props.usertype} fromCompleteModal={true} lodaingSwitch={lodaingswitchprop}/>
            }
          </Modal.Body>
        </Modal>
      );
}

export default CompleteInfoModal
