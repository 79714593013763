import React, { useState, useEffect } from "react";
import icon9 from "../../../../assets/pencil.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllInterests,
  getVolunteerInterests,
  insertVolunteerInterest,
} from "../../../../actions/volunteer";
import { useDispatch } from "react-redux";
import DoneModal from "../../../Auth/loginSignPage/doneModal/DoneModal";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import { getGroupPDF } from "../../../../api";
import Loading from "../../../ui/loading";
// TODO check if pdf download there
const InterestsForm = (props) => {
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [added, setAdded] = useState(0);
  const [volunteerPDFUrl, setVolunteerPDFUrl] = useState(null);
  const [loadingPDFShow, setLoadingPDFShow] = useState(false);

  useEffect(() => {
    setLoadingPDFShow(true)
    getGroupPDF(lng === "arab"? 1: 2).then((data) => {
      setVolunteerPDFUrl(data.data.result)
      setLoadingPDFShow(false)

    })
  }, [])
  useEffect(() => {
    if (props.index === 9) {
      if (lng === "arab") {
        dispatch(getAllInterests(1));
        dispatch(getVolunteerInterests(userLogin?.Results[0]?.vln_id, 1));
      } else {
        dispatch(getAllInterests(2));
        dispatch(getVolunteerInterests(userLogin?.Results[0]?.vln_id, 2));
      }
    }
  }, [lng, added]);
  const vlnInterests = useSelector((state) => state.vlnInterests.vlnInterests);
  const [vlnAllInterests, setVlnAllInterests] = useState(vlnInterests);
  useEffect(() => {
    setVlnAllInterests(vlnInterests);
  }, [vlnInterests]);

  const vlnVolunteerInterests = useSelector(
    (state) => state.vlnVolunteerInterests
  );
  const [vlnVolunteerAllInterests, setVlnVolunteerAllInterests] = useState(
    vlnVolunteerInterests
  );
  useEffect(() => {
    setVlnVolunteerAllInterests(vlnVolunteerInterests);
  }, [vlnVolunteerInterests]);

  const [interest, setInterest] = useState("");
  const handleChange = (e) => {
    setInterest(e.target.value);
  };
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const handleAddCourse = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    dispatch(
      insertVolunteerInterest(
        interest,
        props.vlnID,
        setLoadingModalShow,
        setDoneModal,
        setAdded,
        added
      )
    );
  };
    return (
      <div
        className="Adding_course"
        style={
          lng === "arab"
            ? { direction: "rtl", width: "100%" }
            : { width: "100%" }
        }
      >
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button disabled={loadingPDFShow} onClick={() => { window.open(volunteerPDFUrl, "_blank")}} className="btn_for_submiting_courses">
              {loadingPDFShow?
                          <Loading width={"150px"} height={'40px'} small={'30px'} animate={true} />
:t("View All Volunteer Programs")
              }
              </button>
            
          </div>
        <label
          style={{
            padding: "10px 10px 10px 20px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Insert your Interests")}
        </label>
        <form className="courses_form" onSubmit={handleAddCourse}>
          <div className="selectCourseInputWraper">
            <img
              src={icon9}
              alt="selected_course"
              className="iconForSelectCourse"
            />
            <select
              value={interest}
              onChange={handleChange}
              name="selectedSkill"
              className="selectedCourse"
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
            >
              <option value="" disabled defaultValue>
                {t("Select Program")}
              </option>
              {vlnAllInterests?.map((item) => (
                <option key={item.programID} value={item.programID}>
                  {item.programName}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <button className="btn_for_submiting_courses">{t("Add")}</button>
            <div
              onClick={() => props.setView(10)}
              style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', width:'100%', cursor:'pointer'}}
              className="btn_for_submiting_courses"
            >
              {t("Next")}
            </div>
          </div>
        </form>
        <div className="courses_display">
          {vlnVolunteerAllInterests
            ?.slice(0)
            .reverse()
            .map((item, i) => (
              <div key={i} className="course_Item">
                <label style={{ fontWeight: "bold" }}>{item.programName}</label>
              </div>
            ))}
        </div>
        <DoneModal
          show={doneModal}
          dialogClassName="modal-60w"
          onHide={() => {
            setDoneModal(false);
          }}
        />
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default InterestsForm;
