import React from 'react'
import './NotFound.css'
import notFoundBc from '../../assets/404.png'

const NotFound = () => {
    return (
        <div className='not_found_wraper'>
            <img src={notFoundBc} alt='not_found' className='notFound'/>
        </div>
    )
}

export default NotFound
