import React, { useState, useEffect } from 'react';
import './loginSign.css';
import ERCLogo from '../../../assets/logo_desktop_whiteVN.png';
import BCE2mob from '../../../assets/loginBCMob2.png';
import BCE2 from '../../../assets/loginBC.png';
import finger from '../../../assets/fingerprint-scan.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signIn, signUp } from '../../../actions/user';
import { useTranslation } from 'react-i18next';
import LoadingModal from './loadingPopUp/loadingPopUp';
import SignInWithGoogle from '../googleSignIn/SignInWithGoogle';
import SignInWithFacebook from '../facebookSignIn/SignInWithFacebook';
import SignInWithApple from '../appleSignIn/SignInWithApple';
import SignInWithUAEPASS from '../uaepassSignIn/SignInWithUAEPASS';
import { useLocation } from 'react-router-dom';
import { toastifyMessage } from '../../../handlers/toastifyMessage';
import Loading from '../../ui/loading';
import CompleteInfoModal from './completeInfoModal/CompleteInfoModal';
import {
  applyValidation,
  resetValidation
} from '../../../handlers/formValidation';
import i18n from 'i18next';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
export const LoginSign = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const [lodaingSwitch, setLoadingSwitch] = useState({
    open: false,
    goTo: '/profile'
  });
  const [wait, setWait] = useState(false);
  const [content, setContent] = useState('');
  const location = useLocation();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [fromState, setFromState] = useState(false);
  const [contentForLogin, setContentForLogin] = useState('');
  useEffect(() => {
    if (!userLogin) {
      return;
    } else if (userLogin && wait) {
      return;
    } else {
      window.open('/profile', '_self');
    }
  }, [userLogin]);
  useEffect(() => {
    setFromState(location.state?.from);
  }, [location.state?.from]);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  useEffect(() => {
    // var inputsArr = document.getElementsByClassName("form-control");

    // for (var i = 0; i < inputsArr.length; i++) {
    //   inputsArr[i].onInvalid(function () {
    //   });
    //   if (!inputsArr[i].validity.valid) {
    //     inputsArr[i].setCustomValidity(t("Fill out this field"));
    //     return;
    //   }
    // }
    if (!userLogin) {
      if (width > 900) {
        document.getElementById(
          'login_sign'
        ).style.backgroundImage = `url(${BCE2})`;
      } else {
        document.getElementById(
          'login_sign'
        ).style.backgroundImage = `url(${BCE2mob})`;
      }
    }
  }, [width]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(0);
  const [loginBody, setLoginBody] = useState({
    login_type: 'MOBAPP',
    password: '',
    user_id: ''
  });
  const [signUpBody, setSignUpBody] = useState({
    login_type: 'MOBAPP',
    passwrd: '',
    cnpasswrd: '',
    email: '',
    mobile_no: '',
    uid_no: '',
    usr_name: ''
  });
  const handleChange = (e) => {
    setSignUpBody({ ...signUpBody, [e.target.name]: e.target.value });
  };
  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (
      loginBody.user_id === '' ||
      loginBody.user_id === null ||
      loginBody.user_id === undefined ||
      loginBody.user_id.includes(' ')
    ) {
      toastifyMessage(
        'Invalid E-mail, Mobile or File number.',
        // 'Please check your credentials and try again.',
        'warning'
      );
      return false;
    }
    if (
      loginBody.user_id.startsWith('05') &&
      !loginBody.user_id.includes('@')
    ) {
      if (!validateMobile(loginBody.user_id)) {
        toastifyMessage(
          'Invalid Mobile number',
          // 'Please check your credentials and try again.',
          'warning'
        );
        return false;
      }
    }
    if (!/^\d+$/.test(loginBody.user_id) && !validateEmail(loginBody.user_id)) {
      toastifyMessage(
        'Invalid E-mail address',
        // 'Please check your credentials and try again.',
        'warning'
      );
      return false;
    }

    if (loginBody.password === '' || loginBody.password === null) {
      toastifyMessage('Password field cannot be empty!', undefined, 'warning');
      return false;
    } else {
      setLoadingModalShow(true);
      dispatch(
        signIn(
          loginBody,
          setLoadingModalShow,
          setContentForLogin,
          history,
          fromState
        )
      );
    }
  };
  const validateUsername = (username) => {
    const re = new RegExp(
      /^[a-zA-Z\u0621-\u064A](_(?!(\ |_))|\ (?!(_|\ ))|[a-zA-Z\u0621-\u064A]){0,61}[a-zA-Z\u0621-\u064A]$/
    );
    return re.test(username.toLowerCase());
  };
  const validateEmail = (email) => {
    const re = new RegExp(
      /^(([^\~=\^*+\/<>?()#!{}[\]\\.,;:\s@\"]+(\.[^~\^=*+\/<>?()#!{}[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return re.test(email.toLowerCase());
  };

  const validateMobile = (phone) => {
    const re = /^05[0-9]{8}$/;
    // return re.test(phone);
    return re.test(phone);
  };

  const validateEmiratesId = (eId) => {
    // Check if the ID is exactly 15 digits and starts with '784'
    const re = /^784[0-9]{12}$/;
    return re.test(eId);
  };

  const validatePassword = (password) => {
    const re = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,21})/
    );
    return re.test(password);
  };
  const message = {
    main: '"Password" length must be 8-20 characters long and contain',
    p1: 'at least 1 uppercase letter',
    p2: 'at least 1 lowercase letter',
    p3: 'at least 1 numeric character',
    p4: 'at least 1 special character'
  };

  // Sign up part.
  const handleSignSubmit = (e) => {
    e.preventDefault();

    if (
      signUpBody.usr_name === '' ||
      signUpBody.usr_name === null ||
      signUpBody.usr_name === undefined
    ) {
      toastifyMessage('"Name" field cannot be empty!', undefined, 'warning');
      return false;
    }

    if (
      signUpBody.email === '' ||
      signUpBody.email === null ||
      signUpBody.email === undefined
    ) {
      toastifyMessage('"E-mail" field cannot be empty!', undefined, 'warning');
      return false;
    }
    if (!validateEmail(signUpBody.email)) {
      toastifyMessage(
        'Invalid E-mail address',
        'Valid format: "someone@example.com"',
        'warning'
      );
      return false;
    }

    if (
      signUpBody.mobile_no === '' ||
      signUpBody.mobile_no === null ||
      signUpBody.mobile_no === undefined
    ) {
      toastifyMessage(
        '"Mobile number" field cannot be empty!',
        undefined,
        'warning'
      );
      return false;
    }

    if (signUpBody.mobile_no.slice(0, 4) === '+971') {
      if (!validateMobile('0' + signUpBody.mobile_no.slice(4))) {
        const message = {
          main: 'Invalid mobile number format.',
          p1: '"Mobile number" should be 9 digits long and start with "5"'
        };
        toastifyMessage(
          undefined,
          undefined,
          'warning',
          <div>
            {i18n.t(message.main)}
            <br /> - {i18n.t(message.p1)}
          </div>
        );
        return false;
      }

      // Check if Emirates ID is required
      if (
        signUpBody.uid_no === '' ||
        signUpBody.uid_no === null ||
        signUpBody.uid_no === undefined
      ) {
        toastifyMessage(
          '"Emirates ID" field cannot be empty for UAE numbers!',
          undefined,
          'warning'
        );
        return false;
      } else if (!validateEmiratesId(signUpBody.uid_no)) {
        toastifyMessage(
          '"Emirates ID" must start with "784" and be 15 digits long.',
          undefined,
          'warning'
        );
        return false;
      }
    }

    if (signUpBody.passwrd === '' || signUpBody.passwrd === null) {
      toastifyMessage(
        '"Password" field cannot be empty!',
        undefined,
        'warning'
      );
      return false;
    } else if (!validatePassword(signUpBody.passwrd)) {
      const message = {
        main: 'Invalid password',
        p1: 'Password must be at least 8 characters long',
        p2: 'Password must contain at least one uppercase letter',
        p3: 'Password must contain at least one lowercase letter',
        p4: 'Password must contain at least one number'
      };
      toastifyMessage(
        undefined,
        undefined,
        'warning',
        <div>
          {i18n.t(message.main)}:
          <br /> - {i18n.t(message.p1)}
          <br /> - {i18n.t(message.p2)}
          <br /> - {i18n.t(message.p3)}
          <br /> - {i18n.t(message.p4)}
        </div>
      );
      return false;
    } else if (signUpBody.passwrd !== signUpBody.cnpasswrd) {
      toastifyMessage('Passwords do not match!', undefined, 'warning');
      return false;
    } else {
      setLoadingModalShow(true);
      dispatch(
        signUp(
          {
            ...signUpBody,
            mobile_no:
              signUpBody.mobile_no.slice(0, 4) === '+971'
                ? '0' + signUpBody.mobile_no.slice(4)
                : signUpBody.mobile_no.slice(1)
          },
          setLoadingModalShow,
          history
        )
      );
    }
  };

  const openFromLog = () => {
    history.push('/');
  };
  const [show, setShow] = useState(false);

  const toggleShowPassword = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <div
      id="login_sign"
      style={{ backgroundImage: `url(${BCE2})`, backgroundSize: 'cover' }}
    >
      {display === 0 ? (
        <form
          className="login_form"
          onSubmit={handleLoginSubmit}
          style={lng === 'arab' ? { direction: 'rtl' } : null}
        >
          <div className="ERC_logo_border">
            <div className="ERC_logo_border_inner">
              <img
                src={ERCLogo}
                onClick={openFromLog}
                alt="ERC_logo"
                className="ERC_logo_login_sign"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <label
              style={{
                fontWeight: 'bold',
                fontSize: width <= 600 ? '17px' : '30px'
              }}
            >
              {t('Hello,')}
            </label>
            <label style={{ fontSize: width <= 600 ? '15px' : 'unset' }}>
              {t('Please Login to Continue')}...
            </label>
            <br />
            <h3 style={{ fontWeight: 'bold' }}>{t(contentForLogin)}</h3>
            <a
              href="https://portal.rcuae.ae/vln/candidates.aspx"
              style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
            >
              {t('For non-volunteer service click here')}
            </a>
          </div>
          <div className="whole_input">
            <i
              style={{ fontSize: width <= 600 ? '20px' : '30px' }}
              className="fas fa-user greyscalecolor fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="user_id"
              >
                {t('E-mail')}
              </label>
              <input
                className={
                  loginBody.user_id
                    ? 'payment_numbers form-control'
                    : lng === 'arab'
                    ? 'force_arabic_font form-control'
                    : 'payment_numbers form-control'
                }
                name="user_id"
                value={loginBody.user_id.toLowerCase()}
                onChange={(e) =>
                  setLoginBody({ ...loginBody, user_id: e.target.value })
                }
                type="text"
                // className="form-control"
                id="user_id"
              />
              <button
                type="button"
                onClick={() => history.push('/auth/get-account')}
                // href="/auth/get-account"
                id="emailHelp1"
                className="form-text blackcolor"
              >
                {t('Forgot Account?')}
              </button>
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: '#cc0000'
              }}
              className="fas fa-lock greyscalecolor fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="password"
              >
                {t('Password')}
              </label>
              <div
                className="input"
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '10px'
                }}
              >
                <input
                  name="password"
                  value={loginBody.password}
                  onChange={(e) =>
                    setLoginBody({ ...loginBody, password: e.target.value })
                  }
                  type={show ? 'text' : 'password'}
                  className="form-control"
                  id="password"
                />
                {loginBody.password && (
                  <i
                    style={{
                      fontSize: width <= 600 ? '16px' : '20px',
                      color: 'grey',
                      alignSelf: 'center',
                      cursor: 'pointer'
                    }}
                    className={show ? 'fas fa-eye-slash' : 'fas fa-eye'}
                    onClick={toggleShowPassword}
                  ></i>
                )}
              </div>
              <button
                type="button"
                onClick={() => history.push('/auth/reset-password')}
                // href="/auth/reset-password"
                id="emailHelp"
                className="form-text blackcolor"
              >
                {t('Forgot Password?')}
              </button>
            </div>
          </div>

          <button
            tabIndex="-1"
            style={{ fontSize: width <= 600 ? '15px' : null }}
            type="submit"
            className="btn btn-block btn-danger btn-lg btn_for_form btn-gradientlogin"
          >
            {t('SIGN IN')}
          </button>
          <button
            onClick={() => setDisplay(1)}
            style={{ fontSize: width <= 600 ? '15px' : null }}
            type="button"
            className="btn btn-block btn-outline-danger btn-lg btn_for_form greyborder"
          >
            {t('CREATE ACCOUNT')}
          </button>
          <button
            type="button"
            onClick={() => history.push('/auth/login-sponsor')}
            // href="/auth/reset-password"
            // id="emailHelp"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              alignSelf: 'center',
              textDecoration:'underline'
            }}
            className=" blackcolor"
          >
            {t('Do you have only a sponsor account with us?')}
          </button>
          <div className="hrs">
            <hr className="greyscalecolor" />
            {t('Or Sign in with')}:
            <hr className="greyscalecolor" />
          </div>
        </form>
      ) : (
        <form
          className="login_form"
          onSubmit={handleSignSubmit}
          style={lng === 'arab' ? { direction: 'rtl' } : null}
        >
          <div className="ERC_logo_border">
            <div
              className="ERC_logo_border_inner"
              onClick={openFromLog}
              alt="ERC_logo"
            >
              <img
                onClick={openFromLog}
                src={ERCLogo}
                alt="ERC_logo"
                className="ERC_logo_login_sign"
              />
            </div>
          </div>
          <label style={{ color: 'red' }}>{content}</label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <label
              style={{
                fontWeight: 'bold',
                fontSize: width <= 600 ? '17px' : '30px'
              }}
            >
              {t('Create Account')}
            </label>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="fas fa-user fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="name"
              >
                {t('Name')}*
              </label>
              <input
                className={
                  lng === 'arab'
                    ? 'force_arabic_font form-control'
                    : 'payment_numbers form-control'
                }
                name="usr_name"
                value={signUpBody.usr_name}
                onChange={handleChange}
                type="text"
                // className="form-control"
                id="name"
              />
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="fas fa-envelope fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="exampleInputEmail1"
              >
                {t('E-mail')}*
              </label>
              <input
                // className={signUpBody.email? 'payment_numbers form-control': lng==='arab'? 'force_arabic_font form-control':'payment_numbers form-control'}
                placeholder="someone@example.com"
                name="email"
                value={signUpBody.email.toLowerCase()}
                onChange={handleChange}
                type="text"
                className="payment_numbers form-control"
                id="exampleInputEmail1"
              />
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="fas fa-mobile-alt fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="mobile"
              >
                {t('Mobile Number')}*
              </label>
              <PhoneInput
                className="payment_numbers form-control translateFillMsg"
                defaultCountry="AE"
                name="mobile_no"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
                id="mobile"
                value={signUpBody.mobile_no}
                onChange={(phone) =>
                  setSignUpBody({ ...signUpBody, mobile_no: phone })
                }
                required
                id="phone"
              />
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="far fa-id-card fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="eID"
              >
                {t('Emirates ID')}*
              </label>
              <input
                name="uid_no"
                value={signUpBody.uid_no}
                onChange={handleChange}
                type="text"
                className="payment_numbers form-control"
                placeholder="784xxxxxxxxxxxx"
                id="eID"
                maxLength={15}
              />
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="fas fa-lock fontawesomeoutline"
            ></i>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: '10px'
              }}
            >
              <div
                style={{
                  width: '100%'
                }}
              >
                {' '}
                <label
                  style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                  htmlFor="password"
                >
                  {t('Password')}*
                </label>
                <div
                  className="input"
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '10px'
                  }}
                >
                  {' '}
                  <input
                    placeholder="***************"
                    name="passwrd"
                    value={signUpBody.passwrd}
                    onChange={handleChange}
                    type={show ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    style={{ width: '100%' }}
                  />
                  {signUpBody.passwrd && (
                    <i
                      style={{
                        fontSize: width <= 600 ? '16px' : '20px',
                        color: 'grey',
                        alignSelf: 'center',
                        cursor: 'pointer'
                      }}
                      className={show ? 'fas fa-eye-slash' : 'fas fa-eye'}
                      onClick={toggleShowPassword}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="whole_input">
            <i
              style={{
                fontSize: width <= 600 ? '20px' : '30px',
                color: 'grey'
              }}
              className="fas fa-lock fontawesomeoutline"
            ></i>
            <div className="input">
              <label
                style={{ fontSize: width <= 600 ? '15px' : 'unset' }}
                htmlFor="password"
              >
                {t('Confirm Password')}*
              </label>
              <input
                placeholder="***************"
                name="cnpasswrd"
                value={signUpBody.cnpasswrd}
                onChange={handleChange}
                type={show ? 'text' : 'password'}
                className="form-control"
                id="cnpasswrd"
              />
            </div>
          </div>
          <button
            style={{ fontSize: width <= 600 ? '17px' : null }}
            type="submit"
            className="btn btn-block btn-danger btn-lg btn_for_form btn-gradientlogin"
          >
            {t('Create Account')}
          </button>
          <button
            style={{ fontSize: width <= 600 ? '17px' : null }}
            onClick={() => setDisplay(0)}
            type="button"
            className="btn btn-block btn-outline-danger btn-lg btn_for_form greyborder"
          >
            {t('Already have an account?')}
          </button>
          <div className="hrs ">
            <hr className="greyscalecolor" />
            {t('Or Sign up with')}
            <hr className="greyscalecolor" />
          </div>
        </form>
      )}
      <div className="social_media_log">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: width > 430 ? '60px' : '20px'
          }}
        >
          <SignInWithFacebook
            fromState={fromState}
            setWait={setWait}
            lodaingSwitch={lodaingSwitch}
            completeInfo={setLoadingSwitch}
            setLoadingModalShow={setLoadingModalShow}
            setContentForLogin={setContentForLogin}
            history={history}
          />
          <SignInWithGoogle
            fromState={fromState}
            setWait={setWait}
            lodaingSwitch={lodaingSwitch}
            completeInfo={setLoadingSwitch}
            modalLoadingShow={loadingModalShow}
            setLoadingModalShow={setLoadingModalShow}
            setContentForLogin={setContentForLogin}
            history={history}
          />
          <SignInWithApple
            fromState={fromState}
            setWait={setWait}
            lodaingSwitch={lodaingSwitch}
            completeInfo={setLoadingSwitch}
            setLoadingModalShow={setLoadingModalShow}
            setContentForLogin={setContentForLogin}
            history={history}
          />
          <SignInWithUAEPASS
            fromState={fromState}
            setWait={setWait}
            lodaingSwitch={lodaingSwitch}
            completeInfo={setLoadingSwitch}
            setLoadingModalShow={setLoadingModalShow}
            setContentForLogin={setContentForLogin}
            history={history}
          />
        </div>

        {/* <SignInWithUAEPASS
          fromState={fromState}
          setWait={setWait}
          lodaingSwitch={lodaingSwitch}
          completeInfo={setLoadingSwitch}
          setLoadingModalShow={setLoadingModalShow}
          setContentForLogin={setContentForLogin}
          history={history}
        /> */}
        {/* <img
            src={finger}
            alt="fingerPrint_logo"
            className="login_sign_social_logos"
          /> */}
      </div>
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
      <CompleteInfoModal
        show={lodaingSwitch.open}
        onHide={() => setLoadingSwitch({ open: false, goTo: '/profile' })}
        lodaingswitchprop={lodaingSwitch}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default LoginSign;
