import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDewBK3yes1X5J7WsK-p-nfuOggiY9lkwA",
  authDomain: "emirates-rc.firebaseapp.com",
  projectId: "emirates-rc",
  storageBucket: "emirates-rc.appspot.com",
  messagingSenderId: "133063234325",
  appId: "1:133063234325:web:a9b2600812627cafed4f1c",
  measurementId: "G-T08MLWYE4L",
};
const initialize = async () => {
  if (await isSupported()) {
    const firebase = initializeApp(firebaseConfig);
    const analytics = getAnalytics(firebase);
    const messaging = getMessaging(firebase);
    getToken(messaging, {
      vapidKey:
        "BFC8Sef_unY1-jlwEH2t-LyOnZ22wSeYjB3tcI6RrGncCWcY3d_o95aej0rq5ApyYT5UgLeVPk6iRg0vdPOXDfo",
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI

          // ...
        }
      })
      .catch((err) => {
      });
  }
};

export default initialize;
