import { FETCH_ALL_COMPAGINS_SLIDERS, FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING, FETCH_ALL_COMPAGINS_SLIDERS_LOADING} from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getCompaginSliders = (langNumber, device_type) => async (dispatch) => {
    dispatch({ type: FETCH_ALL_COMPAGINS_SLIDERS_LOADING })
    try {
        const { data } = await api.getCompaginsSlider(langNumber, device_type);
        dispatch({ type: FETCH_ALL_COMPAGINS_SLIDERS, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: FETCH_ALL_COMPAGINS_SLIDERS_ENOUGH_LOADING })
    }
    
}