import React, {useEffect, useState} from 'react'
import './Returned.css'
import notFound from '../../../../assets/notThere.png'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {getReturnedRequ} from '../../../../actions/localAID'
import RequItem from '../requestItem/RequItem'
import Loading from '../../../ui/loading';


const Returned = () => {
    const dispatch = useDispatch();
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [innerLoading, setInnerLoading] = useState(true)

    useEffect(()=> {
        if (userLogin?.Results[0]?.person_id!=='') {
            if (lng==='arab') {
                dispatch(getReturnedRequ('AR',userLogin?.Results[0]?.person_id))
            } else {
                dispatch(getReturnedRequ('EN',userLogin?.Results[0]?.person_id))
            }
        }
      }, [lng]);
      const laReturned = useSelector(state=> state.laReturned);
    const [returnedRequ, setReturnedRequ] = useState(laReturned["Returned Requests "]?.slice(0))
    useEffect(()=> {
        setReturnedRequ(laReturned["Returned Requests "]?.slice(0))
        if (laReturned.hasOwnProperty('Returned Requests ')) {
            setInnerLoading(false)
        }
    }, [laReturned])
        return (
            <div className='pending'>
                {returnedRequ?.length===0 && !innerLoading?
                <div className='notFoundWraper'>
                    <label style={{opacity:'0.8'}}>{t("No Requests Found")}</label>
                    <img src={notFound} alt='notFound' className='noutFoundImg'/>
                </div>:
                innerLoading?
                <Loading height='600px'/>:
                <div className='completed'>
            {returnedRequ?.map((item, i)=> 
                <RequItem user_remarks={item?.user_remarks} erc_remarks={item?.erc_remarks} fromReturned={true} key={i} title={item.AIDTYPE_NAME_A} requestNumber={item.req_id} year={item.req_year} requClassi={item.CATGRP_NAME_A}/>
                )}
        </div>
            }
                
            </div>
        )
}

export default Returned
