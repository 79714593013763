import './Services.css'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { getServices } from '../../../actions/volunteer'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import Loading from '../../ui/loading';
import { toastifyMessage } from "../../../handlers/toastifyMessage";

const Services = () => {
    let userLogin = JSON.parse(localStorage.getItem('userLogin'))
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const history = useHistory()
    const { pathname } = useLocation()
    useEffect(() => {
        if (userLogin?.Results[0]?.vln_id_flag === '2') {
            if (lng === 'arab') {
                dispatch(getServices(1,userLogin?.Results[0]?.vln_id))
            } else {
                dispatch(getServices(2, userLogin?.Results[0]?.vln_id))
            }
        }
    }, [lng]);
    const vlnServices = useSelector(state => state.vlnServices.vlnServices);
    const loading = useSelector(state => state.vlnServices.loading);
    const [vlnAllServices, setVlnAllServices] = useState(vlnServices)
    useEffect(() => {
        setVlnAllServices(vlnServices)
    }, [vlnServices])
    const [selectedItem, setSelectedItem] = useState('')
    const [serviceBody, setServiceBody] = useState({
        vln_id: userLogin?.Results[0]?.vln_id,
        vln_service_cod: '',
        vln_date_from: '',
        vln_date_to: '',
        vln_desc: '',
        cost: 0,
        title: ''
    })

    const handleClick = (str) => {
        setSelectedItem(str)
        setServiceBody({...serviceBody, vln_service_cod: str.serviceCode, cost: str.serviceCost, title: str.serviceName})
    }
    const handleChange = (e) => {
        setServiceBody({...serviceBody, vln_desc: e.target.value})
    }
    const handlePayment = () => {
        if(!selectedItem)
            {
                return toastifyMessage("Please select a service", undefined, "warning");
            }
        if (selectedItem?.subservices?.length > 0)  {
            if (serviceBody.vln_date_from==='' || serviceBody.vln_date_to==='') {
                return toastifyMessage("Please choose a date", undefined, "warning");
              }
              if (serviceBody.vln_desc==='') {
                return toastifyMessage("Please choose a related service", undefined, "warning");
              }
              if (serviceBody.vln_date_from.split('-')[0].length>4 || parseInt(serviceBody.vln_date_from.split('-')[0]) <  1850) {
                return toastifyMessage("Please choose a valid date", undefined, "warning");
              }
              if (serviceBody.vln_date_to.split('-')[0].length>4 || parseInt(serviceBody.vln_date_to.split('-')[0]) <  1850) {
                return toastifyMessage("Please choose a valid date", undefined, "warning");
              }
        }
        history.push(`/checkout`, { from: pathname, elements: [{title: selectedItem?.subservices?.length > 0? serviceBody.vln_desc :serviceBody.title, price: serviceBody.cost}], totalAmount: serviceBody.cost, details: serviceBody })
    }
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
        return (
            <div className='Adding_course' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                <ShareHeader pageName={t("Services")} noShare={true} />
                <div className='submit_requ_header'>
                    <h3 style={{ color: 'gray' }}>{t("Services")}</h3>
                </div>
                {loading ? <Loading /> : <div className='relatives_display'>
                    {vlnAllServices?.map((item, i) =>
                        <div key={i} className='course_Item' style={{border: serviceBody.vln_service_cod===item.serviceCode? '1px solid #ee4236':'none'}}>
                            <label className='textSmall' onClick={() => handleClick(item)} style={{ fontWeight: 'bold', fontSize: '18px', cursor: 'pointer' }}>{item.serviceName}</label>
                            <div className='body_of_the_service' style={{ width: '100%', display: serviceBody.vln_service_cod===item.serviceCode && item.subservices.length>0? 'unset':'none' }}>
                                <label >{t("Cost")}: <span className='payment_numbers'>{item.serviceCost}</span> {t("AED")}</label>
                                <div style={{ flexDirection:'column', gap:'5px', width: '100%', display: serviceBody.vln_service_cod===item.serviceCode && item.subservices.length>0? 'flex':'none' }}>
                                    <select onChange={handleChange} name="selectedSkill" className="selectedCourse" value={serviceBody.vln_desc}>
                                        <option value='' disabled defaultValue>{t("Select Related Service")}</option>
                                        {item?.subservices?.map((item, i) =>
                                        <option key={i} value={item.servc_name}>{item.servc_name}</option>
                                        )}
                                    </select>
                                    <div className='e-service-input-wraper'>
                                        <label className='label_to_beSmall' style={{minHeight:'unset'}}>{t("Start Date")}:</label>
                                        <input className='selectedCourse' type='date' value={serviceBody.vln_date_from} onChange={(e)=> setServiceBody({...serviceBody, vln_date_from: e.target.value})}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}/>
                                    </div>
                                    <div className='e-service-input-wraper'>
                                        <label className='label_to_beSmall' style={{minHeight:'unset'}}>{t("End Date")}:</label>
                                        <input className='selectedCourse' type='date' value={serviceBody.vln_date_to} onChange={(e)=> setServiceBody({...serviceBody, vln_date_to: e.target.value})}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    )}
                </div>}
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width: '70%'}}>
                    <label style={{textAlign:'center'}}>{t("5% VAT will be included for each service")}</label>
                    <button onClick={handlePayment} className='Buyy_now_btn'>{t("Checkout")}</button>
                </div>
            </div>
        )
    } else {
        window.location.replace("/profile");
    }

}

export default Services