import React, { useState, useEffect, useRef } from 'react'
import './HumanItemBag.css'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { addToBag, deleteFromBag } from '../../../../actions/BagFav'
import Loading from '../../../ui/loading';
import { toastifyMessage } from "../../../../handlers/toastifyMessage";

const styleForWraper = {
    filter: 'blur(8px)',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const bigWraperStyle = {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const styleForNotAv = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2000'
}
const styleForXBtn = {
    color: 'white'
}

const HumanItemBag = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const dispatch = useDispatch()
    const [presentage, setPresentage] = useState(0)
    const [realAmount, setRealAmount] = useState(props.baseAmount)

    useEffect(() => {
        setPresentage((parseInt(props.raised) / parseInt(props.goal)))
    }, [props.raised, props.goal])
    const [humanItemOnBagDetails, setHumanItemBagDetails] = useState({
        baseAmount: props.baseAmount,
        donationAmountFromUser: props.donationAmountFromUser,
        finalAmount: props.goal,
        humanId: props.ID,
        humanImg: props.humanImg,
        title: props.title,
        total: props.raised
    })
    useEffect(() => {
        setHumanItemBagDetails({
        baseAmount: props.baseAmount,
        donationAmountFromUser: props.donationAmountFromUser,
        finalAmount: props.goal,
        humanId: props.ID,
        humanImg: props.humanImg,
        title: props.title,
        total: props.raised
        })
    }, [props.ID, props.donationAmountFromUser])
    useEffect(() => {
        //itemWeNeed = donations.filter(item => item.dontypeID === props.donationId).slice(0, 1).shift()
        setRealAmount(props.baseAmount)
    }, [props.ID])
    const handleClick = async (op) => {
        setAddingToCart(true);
        if (op === '-') {
            if (humanItemOnBagDetails.donationAmountFromUser > parseInt(realAmount)) {
                if (!userLogin) {
                    let copyOf = props.currentProfile.cart.humanCases.slice(0);
                    copyOf.splice(copyOf.findIndex(item => item.humanId === props.ID), 1)
                    let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: copyOf, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                    const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                    localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                    props.setChange(props.change + 1)
                } else {
                    await dispatch(addToBag({
                        cart: {
                            donID: "158", userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
                            userEmail: userLogin.Results[0]?.email, itemType: 4, amount: parseInt(props.donationAmountFromUser) - parseInt(realAmount), cart_serial: props.item.cart_serial, humanCase: props.ID, humanYear: props.item.caseYear, quantity: props.item.quantity - 1
                        }, language: lng === 'arab' ? 1 : 2
                    }, false, 'justEdit'))
                    props.setChange(props.change + 1)

                }
            }
        } else {
            if (!userLogin) {
                let copyOf = props.currentProfile.cart.humanCases.slice(0);
                copyOf.splice(copyOf.findIndex(item => item.humanId === props.ID), 0, {...humanItemOnBagDetails, donationAmountFromUser: parseInt(realAmount)})
                let newProfile = { cart: { donations: props.currentProfile.cart.donations, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: copyOf, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                props.setChange(props.change + 1)
            } else {
                await dispatch(addToBag({
                    cart: {
                        donID: "158", userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
                        userEmail: userLogin.Results[0]?.email, itemType: 4, amount: parseInt(props.donationAmountFromUser) + parseInt(realAmount), cart_serial: props.item.cart_serial, humanCase: props.ID, humanYear: props.item.caseYear, quantity: props.item.quantity + 1
                    }, language: lng === 'arab' ? 1 : 2
                }, false, 'justEdit'))
                props.setChange(props.change + 1)
            }


        }
        setAddingToCart(false);
    }
    const [addingToCart, setAddingToCart] = useState(false);
    const handleInBag = async (str) => {
        setAddingToCart(true);
        if (!userLogin) {
            let newProfile = {
                cart: {
                    donations: props.currentProfile.cart.donations,
                    sukuks: props.currentProfile.cart.sukuks,
                    projects: props.currentProfile.cart.projects,
                    humanCases: props.currentProfile.cart.humanCases.filter(item => !(item.humanId === str.id)),
                    orphans: props.currentProfile.cart.orphans
                },
                liked: props.currentProfile.liked
            }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            toastifyMessage("Removed!", undefined, "success");
            props.setChange(props.change + 1)
        } else {
            let elementWeNeed = props.bag?.humanCases.filter(item => item.humanCaseID === str.id && parseInt(item.baseAmount)===parseInt(str.baseAmount)).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1, ""))
            }
        }
        if (_isMounted.current) {
           setAddingToCart(false); 
        }
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <div className='big_wraper_bag' style={props.status === 'not' ? bigWraperStyle : { width: '100%' }}>
            <button disabled={addingToCart} style={lng === 'arab' ? { right: 'unset', left: '10px', color: 'white' } : { color: 'white' }} onClick={() => handleInBag({id: props.ID, baseAmount: props.baseAmount})} className='removeBtn_from_bag'><i className="fas fa-times"></i></button>
            <div style={{position:'absolute', top:'0px', left: '0px', right:'0px', bottom:'0px', display: addingToCart? 'block':'none'}}>
                <Loading height={'100%'} width={'100%'}/>
            </div>
            <div className={addingToCart ? 'bag_item_com disable_page' : 'bag_item_com'} style={props.status === 'not' ? styleForWraper : null}>
                <svg height='90' width='90'>
                    <defs>
                        <pattern viewBox="0 0 1 1" preserveAspectRatio="xMidYMid slice" id={`img_${props.ID}`} x="0" y="0" patternUnits="objectBoundingBox" height="100%" width="100%">
                            <image width='1' height='1' preserveAspectRatio="xMidYMid slice" className='img' x="0" y="0" xlinkHref={props.humanImg}></image>
                        </pattern>
                    </defs>
                    <circle cx="45" cy="45" r="40" fill={`url(#img_${props.ID})`} />
                    <circle className="circle-bg_bag" cx="45" cy="45" r="40" />
                    <circle className="circle_bag" cx="45" cy="45" r="40" strokeDasharray={`calc(${presentage} * ${3.14 * 2 * 40}) calc(${3.14 * 2 * 40})`} />
                </svg>
                <div className='project_Details_bag'>
                    {lng === 'arab' ?
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'right' } : { fontWeight: 'bold', textAlign: 'right' }}>{props.title}</label> :
                        <label style={width <= 1150 ? { fontSize: '13px', fontWeight: 'bold', textAlign: 'left' } : { fontWeight: 'bold', textAlign: 'left' }}>{props.title}</label>
                    }
                </div>
                <div className='donation_inc_Dec_bag'>
                    {/* {props.status === 'not' ? */}
                    {/* <button className='inc' onClick={() => handleClick('-')} disabled>-</button> : */}
                    {/*<button disabled={addingToCart || props.status === 'not'} className='inc' onClick={() => handleClick('-')}>-</button>*/}
                    {/* // } */}
                    <label className='label_to_beSmall payment_numbers' style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold' , minHeight:'unset'}}>{t("AED")} <span style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'none' }}>{numberWithCommas(props.donationAmountFromUser)}</span></label>
                    {/* {props.status === 'not' ? */}
                    {/* <button className='dec' onClick={() => handleClick('+')} disabled>+</button> : */}
                    {/*<button disabled={addingToCart || props.status === 'not'} className='dec' onClick={() => handleClick('+')}>+</button>*/}
                    {/* } */}
                </div>
            </div>
            <label style={props.status === 'not' ? styleForNotAv : { display: 'none' }}>{t("Not available")}</label>
        </div>
    )
}

export default HumanItemBag
