import { APIForVolunteerV3V2, baseURL } from ".";
import axios from "axios";


export const addToBag = (userData) =>
  axios({
    method: "post",
    url: `${baseURL}/add_cart_item`,
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json"
    },
    data: userData,
  });
  
export const getBagItems = (userID, langNumber) => axios.get(`${baseURL}/get_cart_items_new?userID=${userID}&language=${langNumber}`);
export const deleteFromBag = (userID, cartSerial, language, orphan_serial) => axios.get(`${baseURL}/remove_item_cart?userID=${userID}&cartID=${cartSerial}&language=${language}&orphan_serial=${orphan_serial}`);

//-----------------------------------------------------------------------------

export const addToFavorites = (userData) =>
  axios({
    method: "post",
    url: `${baseURL}/add_favorites`,
    mode: "no-cors",
    headers: {},
    data: userData,
  });
  export const getFavoritesItems = (userID, langNumber) => axios.get(`${baseURL}/get_favorites_new?userID=${userID}&language=${langNumber}`);
  export const deleteFromFavorites = (langNumber, userID, favID) => axios.get(`${baseURL}/delete_favorite?email=${userID}&favoriteID=${favID}&language=${langNumber}`);

