import React, { useEffect, useState } from "react";
import ProjectShareCom from "../../homePage/sections/projectShare/projectShareCom/projectShareCom";
import "./ProjectSharesPage.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import Loading from "../../ui/loading";
const ProjectSharesPage = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const loading = useSelector((state) => state.projectShares.loading);
  const history = useHistory();
  const projectShares = useSelector(
    (state) => state.projectShares.projectShares
  );
  const [projectSharesDisplay, setProjectSharesDisplay] =
    useState(projectShares);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setProjectSharesDisplay(projectShares);
  }, [projectShares]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  // }, [humanCases])

  function loadQueryParams() {
    if (props.location.search) {
      let qParms = props.location.search.replaceAll("?", "");
      let qObj = JSON.parse(
        '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      return qObj;
    }
  }
  useEffect(() => {
    if (loadQueryParams() && loadQueryParams().humanId) {
      history.push(`/humancases/${loadQueryParams().humanId}`);
    }
  }, []);

  const searchData2 = (text) => {
    setSearchText(text?.toLowerCase() ?? "");
  };

  const filteredProjectShare =
    searchText.length > 0
      ? projectSharesDisplay.filter(
          (e) =>
            e.projectName.toLowerCase().includes(searchText) ||
            e.longDesc.toLowerCase().includes(searchText)
        )
      : projectSharesDisplay;

  return (
    <div id="project_shares_display_page">
      <ShareHeader noShare={true} pageName={t("Project Shares")} />
      <div
        className="header_of_human_cases"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <h2
          style={{
            padding: "0px 0px 10px 0px",
            borderBottom: "1px solid gray",
            textAlign: "center",
          }}
        >
          {t("Project Shares")}
        </h2>
      </div>
      <div className="project-share-textfield-section-wrapper">
        <div
          className="search_input_project_share"
          style={
            lng === "arab" ? { flexDirection: "row", direction: "rtl" } : null
          }
        >
          <div style={{ width: "100%", display: "flex" }}>
            <input
              className={
                (lng === "arab" ? "force_arabic_font" : "payment_numbers") +
                " " +
                "project_share_donation_text_field_text"
              }
              onChange={(e) => searchData2(e.target.value)}
              type="text"
              placeholder={t("Search for a project")}
              style={
                lng === "arab"
                  ? {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    }
                  : null
              }
            />
            <button
              style={
                lng === "arab"
                  ? {
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }
                  : null
              }
              type="button"
            >
              <i
                style={{ color: "#ee4236", padding: "0 8px" }}
                className="fas fa-search"
              ></i>
            </button>
          </div>
        </div>
        <button className="create_share_btn" onClick={() => {history.push("project_share/all")}}>{t("Create Project")}</button>
      </div>
      {loading ? (
        <Loading height={"85vh"} />
      ) : filteredProjectShare.length > 0 ? (
        <div className="body_of_project_shares">
          {filteredProjectShare?.map((item, i) => (
            <ProjectShareCom
              key={i}
              className={"project-share-item "}
              shareID={item.projectShareID}
              projectTypeImg={item.projTypeImagePath}
              projectCountryImg={item.countryImagePath}
              title={item.projectName}
              text={item.longDesc}
              raised={item.amountCollected}
              goal={item.amount}
              shareYear={item.projectShareYear}
              projectShare={item}
            />
          ))}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "25vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 className="project_share_no_result_found_text">
            {" "}
            {t("No Results Found")}
          </h5>
        </div>
      )}
    </div>
  );
};

export default ProjectSharesPage;
