import { FETCH_ALL_SUKUK, LOAD_SUKUK, SUKUK_LOADED } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";


export const getSukuk = (langNumber, device_type) => async (dispatch) => {
    dispatch({
        type: LOAD_SUKUK
    });
    try {
        const { data } = await api.getSukuk(langNumber, device_type);
        dispatch({ type: FETCH_ALL_SUKUK, payload: data })
        dispatch({
            type: SUKUK_LOADED
        });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({
            type: SUKUK_LOADED
        });
    }
}