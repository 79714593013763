import "./ShareModelAlert.css";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { makeUrlWithData, shareMe } from "../../../../constants/dynmaic_link";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import CopyToClipboard from "react-copy-to-clipboard";
import { t } from "i18next";
import share from "../../../../assets/share.png";
import Loading from "../../../ui/loading";

function ShareAlertModel(props) {
  const [shareStatus, setshareStatus] = useState("");

  const [pathState, setPathState] = useState(props.path);
  const [shareDataState, setShareDataState] = useState(props.shareData);

  const [loading, setloading] = useState(false);

  const [loadingCopy, setloadingCopy] = useState(false);

  const [loadingWhatsap, setloadingWhatsap] = useState(false);

  const [loadingFacebook, setloadingFacebook] = useState(false);

  const [loadingtwitter, setloadingtwitter] = useState(false);

  const [showCopyCard, setshowCopyCard] = useState(false);
  const loadShareURL = () => {
    makeUrlWithData(props.path, props.shareData).then((url) => {
      setshareStatus(url);
      setloading(false);
    });
  };
  useEffect(() => {
    setPathState(props.path);
    setShareDataState(props.shareData)
  }, [props.path, props.shareData]);

  // const showMessage = async (setloadingCopy) => {
  //   try {
  //    await shareMe(props.path, shareStatus);
  //   } catch (error) { }
  //   toastifyMessage("Copied", "Url has been copied to clipboard", "success");
  //   setloadingCopy(false)
  // };

  const handleShare = async (setloadingCopy) => {
    setloadingCopy(true)
   await makeUrlWithData(pathState, shareDataState).then( async (url) => {
      setshareStatus(url);
      //setloading(false);
     navigator.clipboard.writeText(url);
      //await shareMe(pathState, url);
      toastifyMessage("Copied", "Url has been copied to clipboard", "success");
      setloadingCopy(false)
    })
  };
  const handleShareFromSocial = async (dataObject) => {
    dataObject.loadingMethod(true)
    makeUrlWithData(pathState, shareDataState).then((url) => {
      setshareStatus(url);
      if (dataObject.type==='whatsapp') {
        window.open(`https://api.whatsapp.com/send?phone=&text=${url}`);
      } else if (dataObject.type==='twitter') {
        window.open(`http://twitter.com/share?text=Time_to_share_and_care&url=${url}&hashtags=erc,redcrescent`);
      } else {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
      }
      //setloading(false);
      // await shareMe(props.path, shareStatus);
      // toastifyMessage("Copied", "Url has been copied to clipboard", "success");
      dataObject.loadingMethod(false)
    })
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onExit={() => setshareStatus('')}
      dialogClassName="popUpLoading"
      centered
    >
      <Modal.Body>
        {loading ?
          <Loading/> :
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <h3 style={{ textAlign: 'center', color: 'white' }}>{t("Share")}</h3>
            <div className="social-media">
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#25d366'}} className="social-media-btn" onClick={() => handleShareFromSocial({loadingMethod: setloadingWhatsap, type:'whatsapp'})}>
                    {loadingWhatsap?
                        <Loading height='20px' color='white'/>:
                      <i  className="fab fa-whatsapp"></i>}
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("WhatsApp")}</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#1da1f2'}} className="social-media-btn" onClick={() => handleShareFromSocial({loadingMethod: setloadingtwitter, type:'twitter'})}>
                    {loadingtwitter?
                        <Loading height='20px' color='white'/>:
                      <i  className="fab fa-twitter"></i>}
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("Twitter")}</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#1877f2'}} className="social-media-btn" onClick={() => handleShareFromSocial({loadingMethod: setloadingFacebook, type:'facbook'})}>
                    {loadingFacebook?
                        <Loading height='20px' color='white'/>:
                      <i className="fab fa-facebook-f"></i>}
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("Facebook")}</label>
                </div>
              <CopyToClipboard text={shareStatus} >
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                <button onPointerDown={()=> handleShare(setloadingCopy)} className='social-media-btn' style={{ backgroundColor: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', height: '60px', width: '60px', borderRadius: '50%' }}>
                  {loadingCopy?
                  <Loading height='20px'/>:
                  <img src={share} alt="donate_btn_icon_banner" style={{ height: '30px', width: '30px' }} />
                }
                </button>
                <label style={{color:'white'}} className='label_of_title'>{t("Copy link")}</label>
                </div>
              </CopyToClipboard>
            </div>


          </div>
        }

      </Modal.Body>
    </Modal>
  );
}
export default ShareAlertModel;
