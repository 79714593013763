import React, { useEffect, useState } from 'react';
import '../volunteerDetails/VolunteerDetails.css';
import LocalAidProfileComp from '../../LocalAidMainPage/localAidProfilePage/localAidProfileComp/LocalAidProfileComp';
import userIcon from '../../../assets/user-icon-new.png';
import { useTranslation } from 'react-i18next';
import { getVoulnteerFile } from '../../../actions/volunteer';
import { getVoulnteerEvents } from '../../../actions/volunteer';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loading from '../../ui/loading';
import ShareHeader from '../../LoaclComponents/shareHeader/ShareHeader';
import {
  applyValidation,
  resetValidation
} from '../../../handlers/formValidation';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import USIcon from '../../../assets/united-states.png';
import SAIcon from '../../../assets/saudi-arabia.png';
import { useHistory } from 'react-router-dom';
import {
  getVolunteersCount,
  getVolunteerSkills,
  getVolunteerLanguages
} from '../../../actions/volunteer';
import { baseURL } from '../../../api';
import axios from 'axios';

const VolunteerDetails = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const dispatch = useDispatch();
  useEffect(() => {
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
      if (lng === 'arab') {
        dispatch(getVoulnteerFile(userLogin?.Results[0]?.vln_id, 1));
        dispatch(getVolunteerLanguages(1, userLogin?.Results[0]?.vln_id));
      } else {
        dispatch(getVoulnteerFile(userLogin?.Results[0]?.vln_id, 2));
        dispatch(getVolunteerLanguages(2, userLogin?.Results[0]?.vln_id));
      }
    }
  }, [lng]);
  useEffect(() => {
    if (userLogin?.Results[0]?.vln_id_flag === '2') {
      if (lng === 'arab') {
        dispatch(
          getVoulnteerEvents(
            userLogin?.Results[0]?.vln_id ?? '',
            1,
            userLogin?.Results[0]?.emiratesID,
            userLogin?.Results[0]?.email
          )
        );
        dispatch(getVolunteerSkills(userLogin?.Results[0]?.vln_id, 1));
      } else {
        dispatch(
          getVoulnteerEvents(
            userLogin?.Results[0]?.vln_id ?? '',
            2,
            userLogin?.Results[0]?.emiratesID,
            userLogin?.Results[0]?.email
          )
        );
        dispatch(getVolunteerSkills(userLogin?.Results[0]?.vln_id, 2));
      }
    }
  }, [lng]);

  ///Skill Details
  const vlnVolunteerSkills = useSelector((state) => state.vlnVolunteerSkills);
  const [vlnVolunteerAllSkills, setVlnVolunteerAllSkills] =
    useState(vlnVolunteerSkills);
  useEffect(() => {
    setVlnVolunteerAllSkills(vlnVolunteerSkills);
  }, [vlnVolunteerSkills]);
  ///Skill Details

  ///Profile Details
  const vlnFileDteails = useSelector(
    (state) => state.vlnFileDteails.vlnFileDetails
  );

  const loading = useSelector((state) => state.vlnFileDteails.loading);

  const [vlnProfileDetails, setVlnProfileDetails] = useState(vlnFileDteails);
  useEffect(() => {
    setVlnProfileDetails(vlnFileDteails);
  }, [vlnFileDteails]);
  ///Profile Details

  ///Event Details
  const loadingevent = useSelector((state) => state.vlnEventsDetails.loading);
  const vlnEventsDetails = useSelector(
    (state) => state.vlnEventsDetails.vlnEventsDetails
  );
  const [vlnEventDetails, setVlnEventDetails] = useState(vlnEventsDetails);
  useEffect(() => {
    setVlnEventDetails(vlnEventsDetails);
  }, [vlnEventsDetails]);
  ///Event Details

  ///Languages///
  const vlnVolunteerLanguages = useSelector(
    (state) => state.vlnVolunteerLanguages
  );
  const [vlnVolunteerAllLanguage, setVlnVolunteerAllLanguage] = useState(
    vlnVolunteerLanguages
  );
  useEffect(() => {
    setVlnVolunteerAllLanguage(vlnVolunteerLanguages);
  }, [vlnVolunteerLanguages]);
  ///Languages///
  const [width, setWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState({
    accountNumber: userLogin?.Results[0]?.vln_id ?? 'N/A',
    phone: vlnProfileDetails?.mobile ?? 'N/A',
    email: vlnProfileDetails?.email ?? 'N/A',
    gender: vlnProfileDetails?.gender ?? 'N/A',
    ercBranch: vlnProfileDetails?.branch ?? 'N/A',
    nationality: vlnProfileDetails?.country ?? 'N/A'
  });
  const [accNumError, setAccNumError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [edit, setEdit] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [eventsToShow, setEventsToShow] = useState([]);
  useEffect(() => {
    if (vlnEventDetails && vlnEventDetails?.length > 0) {
      setEventsToShow(showAll ? vlnEventDetails : vlnEventDetails?.slice(0, 6));
    }
  }, [vlnEventDetails, showAll]);
  useEffect(() => {
    setFormData({
      accountNumber: userLogin?.Results[0]?.vln_id ?? 'N/A',
      phone: vlnProfileDetails?.mobile ?? 'N/A',
      email: vlnProfileDetails?.email ?? 'N/A',
      gender: vlnProfileDetails?.gender ?? 'N/A',
      ercBranch: vlnProfileDetails?.branch ?? 'N/A',
      nationality: vlnProfileDetails?.country ?? 'N/A'
    });
  }, [vlnProfileDetails?.email]);
  // const eventsToShow = showAll ? vlnEventDetails : vlnEventDetails?.slice(0, 6);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      })
      .toUpperCase()
      .replace(',', '');
  };
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);
  const [hoursCount, setHoursCount] = useState();

  const getVolunteersCount = async () => {
    try {
      let response;
      if (lng === 'arab') {
        response = await axios({
          method: 'POST',
          url: `${baseURL}/volunteer_participations`,
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: new URLSearchParams({
            volunteerID: userLogin?.Results[0]?.vln_id,
            language: 1
          })
        });
      } else {
        response = await axios({
          method: 'POST',
          url: `${baseURL}/volunteer_participations`,
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: new URLSearchParams({
            volunteerID: userLogin?.Results[0]?.vln_id,
            language: 2
          })
        });
      }
      if (response) {
        setHoursCount(response?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch volunteer count:', error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };
  useEffect(() => {
    if (userLogin?.Results[0]?.vln_id) {
      getVolunteersCount();
    }
  }, [lng]);
  const doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y - 5;
      ctx.font = 'bold 30px english_font_bold';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(hoursCount?.totalHoursCount, xCoor, yCoor);
    }
  };

  const doughnutLabel2 = {
    id: 'doughnutLabel2',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y + 15;
      ctx.font = '15px english_font';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Hours', xCoor, yCoor);
    }
  };

  const doughnutLabel3 = {
    id: 'doughnutLabel3',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y - 5;
      ctx.font = 'bold 30px english_font_bold';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(hoursCount?.totalParticipationsCount, xCoor, yCoor);
    }
  };

  const doughnutLabel4 = {
    id: 'doughnutLabel4',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y + 15;
      ctx.font = '15px english_font';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Partcipation', xCoor, yCoor);
    }
  };

  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data: [24, 14, 14, 10, 10, 10],
        backgroundColor: [
          'rgba(235, 54, 39, 1)',
          'rgba(177, 24, 24, 1)',
          'rgba(65, 60, 60, 1)',
          'rgba(129, 123, 123, 1)',
          'rgba(204, 202, 202, 1)',
          'rgba(255, 255, 255, 1)'

          //   'rgba(255, 99, 132, 0.2)',
          //   'rgba(54, 162, 235, 0.2)',
          //   'rgba(255, 206, 86, 0.2)',
          //   'rgba(75, 192, 192, 0.2)',
          //   'rgba(153, 102, 255, 0.2)',
          //   'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'transparent'
          //   'rgba(255, 99, 132, 1)',
          //   'rgba(54, 162, 235, 1)',
          //   'rgba(255, 206, 86, 1)',
          //   'rgba(75, 192, 192, 1)',
          //   'rgba(153, 102, 255, 1)',
          //   'rgba(255, 159, 64, 1)',
        ]
      }
    ]
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const history = useHistory();
  const handleAttendanceClick = (item) => {
    if (item?.registered) {
      history.push({
        pathname: '/events-attendance',
        state: { item }
      });
    } else {
      history.push({
        pathname: '/events-join',
        state: { item }
      });
    }
  };
  const handleSkillAdd = () => {
    history.push({
      pathname: '/volunteer/skills'
      //   state: { item }
    });
  };
  const handleAddLang = () => {
    history.push({
      pathname: '/volunteer/languages'
      //   state: { item }
    });
  };
  if (userLogin?.Results[0]?.vln_id_flag === '2') {
    return (
      <div
        className="volunteer_details"
        style={lng === 'arab' ? { direction: 'rtl' } : null}
      >
        <ShareHeader pageName={t('Volunteer Details')} noShare={true} />
        <div className="submit_requ_header">
          <h3 style={{ color: 'gray' }}>{t('Volunteer Details')}</h3>
        </div>

        {/* {loading ?
                    <Loading height='500px' /> :
                    <div className='volunteer_portal_lobby_both_sides'>
                        <div className='volunteer_portal_lobby_left_side'>
                            <LocalAidProfileComp icon={icon1} keyOf={t('Number')} value={vlnProfileDetails?.volunteerID} englishFont={true} />
                            <LocalAidProfileComp icon={icon2} keyOf={t("National ID")} value={vlnProfileDetails?.emiratesID} englishFont={true} />
                            <LocalAidProfileComp icon={icon3} keyOf={t('Gender')} value={vlnProfileDetails?.gender} englishFont={true} />
                            <LocalAidProfileComp icon={icon4} keyOf={t('Date of Birth')} value={vlnProfileDetails?.dob} englishFont={true} />
                            <LocalAidProfileComp icon={icon5} keyOf={t('Nationality')} value={vlnProfileDetails?.country} />
                        </div>
                        <div className='volunteer_portal_lobby_right_side'>
                            <LocalAidProfileComp icon={icon6} keyOf={t('Education')} value={vlnProfileDetails?.educationLevel} />
                            <LocalAidProfileComp icon={icon7} keyOf={t('Branch')} value={vlnProfileDetails?.branch} />
                            <LocalAidProfileComp icon={icon8} keyOf={t('Mobile')} value={vlnProfileDetails?.mobile} englishFont={true} />
                            <LocalAidProfileComp icon={icon9} keyOf={t('Email')} value={vlnProfileDetails?.email} englishFont={true} />
                        </div>
                    </div>
                } */}
        <div
          className="container-fluid"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <div className="row justify-content-center">
            <div
              className={
                width > 991
                  ? 'd-flex row justify-content-end col-xl-4 col-lg-5 col-md-7 col-sm-10 col-8'
                  : 'd-flex row justify-content-center col-xl-4 col-lg-5 col-md-7 col-sm-10 col-8'
              }
            >
              <div className="volunteer_info">
                <div className="volunteer_info_content">
                  <div className="volunteer_status">
                    <div className="volunteer_status_circle"></div>
                    <h4 style={{ padding: '0px 10px', margin: '0px' }}>
                      Available
                    </h4>
                  </div>
                  <button
                    onClick={() => setEdit(!edit)}
                    className="btn edit-btn"
                    style={{ color: edit ? 'red' : null }}
                  >
                    {edit ? (
                      <h6 color="red">Save</h6>
                    ) : (
                      <i className="fas fa-edit"></i>
                    )}
                  </button>
                </div>
                {vlnProfileDetails?.volunteerID && (
                  <div className="volunteer_profile">
                    <div className="volunteer_pic">
                      <img
                        src={userIcon}
                        alt="profile-image"
                        style={{ height: '144px', width: '144px' }}
                      />
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="acc"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('Account Number')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">
                            {formData.accountNumber}
                          </div>
                        ) : (
                          <input
                            name="acc"
                            value={formData.accountNumber}
                            onChange={handleChange}
                            type="text"
                            className="form-control translateFillMsg"
                            id="acc"
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              // backgroundColor: "#f8f8f8",
                              // color:  emailError ? "white" : "",
                              //emailError ? ".55" :
                              opacity: '',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="phone"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('Mobile Number')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">{formData.phone}</div>
                        ) : (
                          <input
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            type="tel"
                            className="form-control translateFillMsg"
                            id="phone"
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="email"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('Email')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">{formData.email}</div>
                        ) : (
                          <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                            className="form-control translateFillMsg"
                            // id="email" //this gives it the bottom border underline
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              opacity: '',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="gender"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('Gender')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">{formData.gender}</div>
                        ) : (
                          <input
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            type="text"
                            className="form-control translateFillMsg"
                            id="gender"
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              opacity: '',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="ercBranch"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('ERC Branch')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">
                            {formData.ercBranch}
                          </div>
                        ) : (
                          <input
                            name="ercBranch"
                            value={formData.ercBranch}
                            onChange={handleChange}
                            type="text"
                            className="form-control translateFillMsg"
                            id="ercBranch"
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              opacity: '',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                    <div className="whole_input">
                      <div className="input">
                        <label
                          htmlFor="nationality"
                          className="form_label"
                          style={{
                            fontSize: width > 500 ? '18px' : '15px'
                          }}
                        >
                          {t('Nationality')}
                        </label>
                        {!edit ? (
                          <div className="info_fields">
                            {formData.nationality}
                          </div>
                        ) : (
                          <input
                            name="nationality"
                            value={formData.nationality}
                            onChange={handleChange}
                            type="text"
                            className="form-control translateFillMsg"
                            id="nationality"
                            style={{
                              background: 'rgba(217, 217, 217, 1)',
                              opacity: '',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              transition: 'all 1s'
                            }}
                            required
                            onInvalid={applyValidation}
                            onInput={resetValidation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="volunteer_skills">
                <h5>Languages</h5>
                <div className="language_row">
                  {vlnVolunteerAllLanguage &&
                    vlnVolunteerAllLanguage?.map((lang) => (
                      <div className="language_box">
                        <img
                          src={
                            lang?.languageName?.includes('English')
                              ? USIcon
                              : SAIcon
                          }
                          height="20px"
                          width="20px"
                        />
                        <h5>{lang?.languageName ?? 'English'}</h5>
                      </div>
                    ))}
                  <button
                    className="btn language_box_btn"
                    onClick={() => handleAddLang()}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
                <h5>Skills</h5>
                <div className="skill_column">
                  {vlnVolunteerAllSkills &&
                    vlnVolunteerAllSkills?.map((skill) => (
                      <div className="skill_box">
                        <h5>{skill?.abilityName}</h5>
                      </div>
                    ))}
                  <button
                    className="btn skill_box_btn"
                    onClick={() => handleSkillAdd()}
                  >
                    <i className="fa fa-plus" />
                    <h5>Add Skill</h5>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex row justify-content-center col-xl-4 col-lg-6 col-md-7 col-sm-10 col-10">
              <div className="volunteer_events">
                <h4 style={{ margin: '5px', fontWeight: 200 }}>
                  Events for you
                </h4>
                {eventsToShow?.length > 0 ? (
                  eventsToShow?.map((items) => (
                    <div className="volunteer_event_tile">
                      <div
                        className="volunteer_event_content"
                        style={{ alignItems: 'flex-start' }}
                      >
                        <h5>{items?.name ?? 'Event 1'}</h5>
                        <p className="event-description">
                          <div className="event-description">
                            {items.description.slice(0, 20)}...
                            <span className="tooltip-text">
                              {items.description}
                            </span>
                          </div>
                        </p>
                      </div>
                      <div
                        className="volunteer_event_content"
                        style={{ alignItems: 'flex-end' }}
                      >
                        <p
                          style={{
                            fontSize: 10,
                            color: 'rgba(65, 60, 60, 1)',
                            textTransform: 'uppercase'
                          }}
                        >
                          {formatDate(items?.date_from)}
                        </p>
                        <button
                          className="btn red-btn"
                          onClick={() => handleAttendanceClick(items)}
                        >
                          <h6 style={{ color: 'white' }}>
                            {items?.registered
                              ? 'Mark Attendance'
                              : 'Join Event'}
                          </h6>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div> No Events Found</div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                  }}
                >
                  <button
                    className="btn text-btn"
                    onClick={() => setShowAll(!showAll)}
                  >
                    <p style={{ margin: '0px 10px' }}>
                      {showAll ? 'Show Less' : 'View All Events'}
                    </p>
                    <i className="fa fa-chevron-right" />
                  </button>
                </div>
              </div>
              {/* <div className="volunteer_reports">
                <h5>Reports</h5>
                <div className="report_row">
                  <button className="btn profile_report">
                    <h5>Profile Report</h5>
                  </button>
                  <button className="btn profile_certificates">
                    <h5>Certificates</h5>
                  </button>
                </div>
              </div> */}
            </div>
            <div
              className={
                width > 991
                  ? 'd-flex row justify-content-start col-xl-4 col-lg-6 col-md-7 col-sm-10 col-9'
                  : 'd-flex row justify-content-center col-xl-4 col-lg-6 col-md-7 col-sm-10 col-9'
              }
            >
              <div className="volunteer_hours">
                <h5>Total Hours of Volunteer</h5>
                <div className="chart_row">
                  <div className="hour_chart">
                    {hoursCount?.totalHoursCount && (
                      <Doughnut
                        data={data}
                        options={{
                          responsive: true,
                          cutout: '85%',
                          maintainAspectRatio: true,
                          float: 'left'
                        }}
                        plugins={[doughnutLabel, doughnutLabel2]}
                      />
                    )}
                  </div>
                  <div className="event_chart">
                    {hoursCount?.totalParticipationsCount && (
                      <Doughnut
                        data={data}
                        options={{
                          responsive: true,
                          cutout: '85%',
                          maintainAspectRatio: true,
                          float: 'right'
                        }}
                        plugins={[doughnutLabel3, doughnutLabel4]}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="volunteer_files">
                <h4>My Files</h4>
                <button className="btn volunter_file_tile">
                  <h6>National Identity</h6>
                </button>
                <button className="btn volunter_file_tile">
                  <h6>Residence</h6>
                </button>
                <button className="btn volunter_file_tile">
                  <h6>Passport</h6>
                </button>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                  }}
                >
                  <button className="btn text-btn">
                    <p style={{ margin: '0px 10px' }}>See All</p>
                    <i className="fa fa-chevron-right" />
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    window.location.replace('/profile');
  }
};

export default VolunteerDetails;
