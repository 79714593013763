import React, { useState } from 'react'
import './UAEPASSAuthOptions.css'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
// import { regularPayment } from '../../../actions/payment'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router'
import closeIcon from '../../../../assets/close.png'
import Guest from '../LinkAccount/Guest';
import OTPUAE from '../LinkAccount/OTPUAE';


const UAEPASSAuthOptions = (props) => {
  const { paymentfunction, fromcheckout, ...rest } = props
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [display, setDisplay] = useState(0)
  const history = useHistory();
  const dispatch = useDispatch()
  return (
    <Modal
      {...rest}
      size={display===0? "sm":'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='donation_pop_up_body'
    >
      <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px', padding: display !==0? '0px':null, position:'relative', fontFamily: lng==='arab'? "'arab_font":'english_font' }} >
        <div  style={{backgroundColor:'transparent', display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%', padding:'7px', position:'absolute', top:'0px', left:'0px'}}>
          <img onClick={()=> {props.onHide(); setDisplay(0)}} src={closeIcon} alt='close_icon' style={{width:'15px', height:'15px', cursor:'pointer'}}/>
        </div>
        {
        <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
          <label>{t("User cancelled the login")} </label>
          <div className='donation_final_popUp_btns_wraper'>
             {/* {<button onClick={() => window.open("https://selfcare.uaepass.ae/")} className='donation_final_popUp_btn_first'>
              {t("Verify UAE PASS Account")}
            </button>}  */}
            {/* <button onClick={() => window.location.href = "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=" + window.location.origin} className='donation_final_popUp_btn_second'>
              {t("Logout")}
            </button> */}
          </div>
        </div> }
        
      </Modal.Body>
    </Modal>
  )
}

export default UAEPASSAuthOptions
