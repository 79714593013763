import React, { Fragment, useEffect, useState } from 'react';
import './Event.scss';
import download_img from '../../assets/download_img.svg';
import qrcode from '../../assets/qrcode.svg';
import eventjoin from '../../assets/event_join.svg';
import map_pin from '../../assets/map-pin.svg';
import calaneter from '../..//assets/calander.svg';
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader';
import { useLocation } from 'react-router-dom';
import { baseURL, getCountryCode } from '../../api';
import axios from 'axios';
import { toastifyMessage } from '../../handlers/toastifyMessage';

const EventJoin = () => {
  const [shareUrl, setShareUrl] = useState(false);
  const location = useLocation();
  const { item } = location.state || {};
  useEffect(() => {
    let textOfPageArr = window.location.href.split('/');
    textOfPageArr.splice(textOfPageArr.length - 1, 1);
    setShareUrl(textOfPageArr.join('/'));
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      })
      .toUpperCase()
      .replace(',', '');
  };
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  let userLogin = JSON.parse(localStorage.getItem('userLogin'));
  const [countryCode, setCountryCode] = useState('');
  const [name, setName] = useState(userLogin?.Results[0]?.name_a ?? '');
  const [email, setEmail] = useState(userLogin?.Results[0]?.email ?? '');
  const [phone, setPhone] = useState(userLogin?.Results[0]?.mobile_no ?? '');
  const [gender, setGender] = useState(userLogin?.Results[0]?.gender ?? '');
  const [countryOptions, setCountryOptions] = useState([]);
  const getCodes = () => {
    getCountryCode(lng === 'arab' ? '1' : '2').then((res) => {
      if (res?.data?.data) {
        const options = res?.data?.data?.map((item) => {
          return {
            value: item?.countryId,
            label: item?.countryName
          };
        });
        setCountryOptions(options);
      }
    });
  };
  const JoinEvent = async () => {
    try {
      if (!(name && email && gender && phone && countryCode)) {
        toastifyMessage('Fill out all the fields', undefined, 'error');
        return
      }
      let response;
      response = await axios({
        method: 'POST',
        url: `${baseURL}/join_on_volunteer_event`,
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: new URLSearchParams({
          id: item?.id,
          name: name,
          email: email,
          userId: userLogin?.Results[0]?.person_id,
          gender: gender,
          mobile: phone,
          nationalId: userLogin?.Results[0]?.emiratesID,
          volunteerId: userLogin?.Results[0]?.vln_id,
          year: item?.year,
          serial: item?.serial,
          countryId: countryCode
        })
      });
      if (response) {
        console.log('====================================');
        console.log(response);
        console.log('====================================');
      }
    } catch (error) {
      toastifyMessage(error?.response?.data?.result, undefined, 'error');

      // console.error('Failed to fetch volunteer count:', error);
      // throw error; // Rethrow the error to handle it in the calling function
    }
  };
  useEffect(() => {
    getCodes();
  }, [lng]);
  return (
    <Fragment>
      <ShareHeader
        backTwice={location.state?.selectedCountry ? true : false}
        textToBeCopied={shareUrl}
        pageName={'Events Join'}
        path={'beasponser'}
      />

      <div className="events_join">
        <div className="events_left">
          <div className="title_section">
            <h1>{item?.name ?? 'EVENT TITLE'}</h1>
            <p>{item?.description}</p>
          </div>
          <div className="image_section">
            <img src={item?.images[0]} alt="events" />
          </div>
        </div>
        <div className="join_right">
          <h5>{item?.name ?? 'EVENT TITLE'}</h5>
          <div className="dates_join">
            <div className="child_join">
              <img src={calaneter} alt="calenter" width="20px" />
              <p>{formatDate(item?.date_from)}</p>
            </div>
            <div className="child_join">
              <img src={map_pin} alt="calenter" width="20px" />
              <p>{item?.location ?? 'Abu Dhabi'}</p>
            </div>
          </div>
          <div className="text_join">
            <p>{item?.description}</p>
          </div>
          <div className="file_join">
            <form id="myForm">
              <label>
                <input
                  type="text"
                  style={{
                    border: 0,
                    width: '-webkit-fill-available',
                    background: 'none'
                  }}
                  placeholder="Name"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={userLogin?.Results[0]?.name_a}
                  required
                />
              </label>

              <label class="btn-up">
                <input
                  type="text"
                  style={{
                    border: 0,
                    width: '-webkit-fill-available',
                    background: 'none'
                  }}
                  placeholder="Email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={userLogin?.Results[0]?.email}
                  required
                />
              </label>
              <label class="btn-up">
                <input
                  type="text"
                  style={{
                    border: 0,
                    width: '-webkit-fill-available',
                    background: 'none'
                  }}
                  placeholder="Mobile"
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={userLogin?.Results[0]?.mobile_no}
                  required
                />
              </label>
              <label class="btn-up">
                <select
                  onChange={(e) => setGender(e.target.value)}
                  style={{ border: 0, width: '-webkit-fill-available' }}
                  defaultValue={gender}
                  required
                >
                  <option>Select The Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </label>
              <label class="btn-up">
                <select
                  placeholder="Select country Code"
                  onChange={(e) => setCountryCode(e.target.value)}
                  style={{ border: 0 }}
                  required
                >
                  <option>Select country Code</option>
                  {countryOptions.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </label>
            </form>
          </div>
          <div className="btn_events" onClick={() => JoinEvent()}>
            <p>JOIN EVENT</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventJoin;
