import {
    FETCH_ALL_LANGUAGES, LOAD_ALL_LANGUAGES, ALL_LANGUAGES_LOADED
} from '../../constants/actionTypes'

export default (vlnLanguages = { vlnLanguages: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_LANGUAGES:
            return { ...vlnLanguages, vlnLanguages: action.payload, loading: false };
        case LOAD_ALL_LANGUAGES:
            return { ...vlnLanguages, loading: true };
        case ALL_LANGUAGES_LOADED:
            return { ...vlnLanguages, loading: false };
        default:
            return vlnLanguages;
    }
}