import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./SmsModal.css";
import duIcon from '../../../assets/Du_Solid_.png'
import ETIcon from '../../../assets/e&logo.svg'
import { getSMS, getSMS_Human } from "../../../actions/sms";
import {sms, smsHuman} from '../../../api'
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import Loading from "../loading";
import { useTranslation } from "react-i18next";

function SmsModal(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;

    const [loadingView, setLoadingView] = useState(false)
    const [displaySlide, setDisplaySlide] = useState(0)
    const [amountsData, setAmountsData] = useState([])
    const handleSelect = (str) => {
    setLoadingView(true)
    props.humancase?
    smsHuman(props.donationid).then((res) => {
        if (res.data["SMS DONATIONS"]) {
            let operatorData = res.data["SMS DONATIONS"].filter(item=> item.OPER===str)
            setAmountsData(operatorData)
            setLoadingView(false)
            setDisplaySlide(1)
          //setLoadingModalShow(false)
          //setElemnts(res.data.data)
        } //
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          setLoadingView(false)
          toastifyMessage("Something went wrong...!", undefined, "error");
        }
      }):
    sms(props.donationid)
      .then((res) => {
        if (res.data["SMS DONATIONS"]) {
            let operatorData = res.data["SMS DONATIONS"].filter(item=> item.OPER===str)
            setAmountsData(operatorData)
            setLoadingView(false)
            setDisplaySlide(1)
          //setLoadingModalShow(false)
          //setElemnts(res.data.data)
        } //
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          setLoadingView(false)
          toastifyMessage("Something went wrong...!", undefined, "error");
        }
      })
    }
    const handlePaymentWithSms = (str) => {
        window.location.href = `sms:${str.SHRT_CODE}?&body=${str.sms_text}`;
    }
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popUpLoading"
      onExit={()=> setDisplaySlide(0)}
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
          {loadingView?
          <Loading backgroud={props.fromcamp? 'white':'transparent'} height='154px' borderR='15px'/>:
          <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', gap:'20px', backgroundColor: props.fromcamp? 'white': 'transparent', padding:'10px', borderRadius:'15px'}}>
            {displaySlide===0?
            <React.Fragment>
            <h3 style={{textAlign:'center', color:props.fromcamp? 'black':'white'}}>{t("Choose your provider")}</h3>
            <div className="SMS_modal_wraper">
              <button onClick={()=> handleSelect('DU')} className='sms_mode_btn'>
                  <img src={duIcon} alt='du' className='sms_mode_icon'/>
              </button>
              <button onClick={()=> handleSelect('ETISALAT')} className='sms_mode_btn'>
                  <img style={{borderRadius: '8px'}} src={ETIcon} alt='du' className='sms_mode_icon'/>
              </button>
            </div>
            </React.Fragment>:
            <React.Fragment>
            <h3 style={{textAlign:'center', color:props.fromcamp? 'black':'white'}}>{t("Choose amount to donate")}</h3>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', flexWrap:'wrap', gap:'10px'}}>
                {amountsData.map((item,i)=>
                    <button key={i} className='sms_amount_btn payment_numbers' onClick={()=> handlePaymentWithSms(item)}>{item.AMNT}</button>
                )}
            </div>
            </React.Fragment>
        }
          
        </div>
        }
        
      </Modal.Body>
    </Modal>
  );
}
export default SmsModal;
