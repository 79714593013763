import { COUNTRIES_LOADED, FETCH_ALL_COUNTRIES_FOR_ORPHAN, LOAD_COUNTRIES } from '../constants/actionTypes'

export default (countriesForOrphan = { countriesForOrphan: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_COUNTRIES_FOR_ORPHAN:
            return { ...countriesForOrphan, countriesForOrphan: action.payload, loading: false };
        case LOAD_COUNTRIES:
            return { ...countriesForOrphan, loading: true };
        case COUNTRIES_LOADED:
            return { ...countriesForOrphan, loading: false };
        default:
            return countriesForOrphan;
    }
}