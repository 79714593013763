import * as api from "../api";
import {
  LOAD_BAG,
} from "../constants/actionTypes";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../handlers/toastifyMessage";
import UAParser from 'ua-parser-js';
let userLogin = JSON.parse(localStorage.getItem("userLogin"));
let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
export const handleRegularPayment = async (
  body,
  dispatch
) => {
  const parser = new UAParser();
  const result = parser.getResult();
  const getDeviceXML = () => {
    const { device, os } = new UAParser().getResult();
    return `
      <?xml version="1.0" encoding="UTF-8"?>
      <DeviceDetails>
        <DeviceType>${device.type || null}</DeviceType>
        <DeviceVersion>${os.version || null}</DeviceVersion>
      </DeviceDetails>
    `;
  };
  try {
    visitor = JSON.parse(localStorage.getItem("visitorDetails"));
    body.bag.forEach((item) => {
      if (item.amount < 1) {
        toastifyMessage(
          "Please select an amount to donate",
          undefined,
          "error"
        );
        return;
      }
    });
    var bag = [];
    body.bag.forEach((item) => {
      bag.push({
        remarks: item.remarks ? item.remarks : null,
        dontype_id:
          item.donID != null
            ? item.donID
            : item.dontype_id != null
            ? item.dontype_id
            : null,
        doner_name: item.userName
          ? item.userName
          : item.doner_name
          ? item.doner_name
          : userLogin
          ? userLogin.Results[0].name_a
          : visitor
          ? visitor.name
          : "",
        mobile: userLogin
          ? userLogin.Results[0].mobile_no
          : visitor
          ? visitor.phone
          : item.userMob
          ? item.userMob
          : item.mobile
          ? item.mobile
          : "",
        email: item.userEmail
          ? item.userEmail
          : item.email
          ? item.email
          : userLogin
          ? userLogin.Results[0].email
          : visitor
          ? visitor.email
          : "",
        orptype_id:
          item.orpTypeID != null
            ? item.orpTypeID
            : item.orptype_id != null
            ? item.orptype_id
            : null,
        wrkplc_id:
          item.workPlaceID != null
            ? item.workPlaceID
            : item.branch
            ? item.branch
            : item.wrkplc_id
            ? item.wrkplc_id
            : 1,
        prj_name: item.prj_name
          ? item.prj_name
          : item.project_name
          ? item.project_name
          : null,
        projectYear: item.projectYear ? item.projectYear : null,
        template_id: item.template_id
          ? item.template_id
          : item.projectID
          ? item.projectID
          : null,
        vln_id: item.vln_id
          ? item.vln_id
          : item.volunteer_id
          ? item.volunteer_id
          : null,
        vln_service_cod:
          item.vln_service_cod != null ? item.vln_service_cod : null,
        vln_date_from: item.vln_date_from ? item.vln_date_from : null,
        vln_date_to: item.vln_date_to ? item.vln_date_to : null,
        vln_desc: item.vln_desc ? item.vln_desc : null,
        Mastercard_order_id: item.Mastercard_order_id
          ? item.Mastercard_order_id
          : null,
        item_type:
          item.itemType != null
            ? item.itemType
            : item.item_type != null
            ? item.item_type
            : null,
        country_id: item.country_id ? item.country_id : item.countryID ? item.countryID : null,
        amount: item.amount,
        recuring:
          item.recuring != null
            ? item.recuring
            : item.recurring
            ? item.recurring
            : null,
        month_count: item.month_count != null ? item.month_count : 1,
        humanCase:
          item.humanCase != null
            ? item.humanCase
            : item.caseID
            ? item.caseID
            : null,
        humanYear: item.humanYear ? item.humanYear : null,
        valid_flag: item.valid_flag ? item.valid_flag : null,
        sponsor_id: item.sponsor_id
          ? item.sponsor_id
          : item.sponser_id
          ? item.sponser_id
          : userLogin
          ? userLogin.Results[0].sponser_id
            ? userLogin.Results[0].sponser_id
            : null
          : null,
        cart_serial: item.cart_serial != null ? item.cart_serial : null,
        orphan_id: item.orphan_id ? item.orphan_id : null,
        transaction_number: item.transaction_number
          ? item.transaction_number
          : item.trn_serial
          ? item.trn_serial
          : null,
          transaction_year: item.transaction_year
          ? item.transaction_year
          : item.trn_year
          ? item.trn_year
          : null,
          share_id: item.share_id
          ? item.share_id
          : null,
          share_year: item.share_year
          ? item.share_year
          : null,
          public_flag: item?.public_flag,
        quantity: item.quantity ? item.quantity : 1,
        poc_xml:getDeviceXML()
      });
    });
    body.bag = [...bag];
    var { data } = await api.regularPayment(body);
    if (data.status === "OK" || data.status === "Payed") {
      try {
        dispatch({ type: LOAD_BAG });
      } catch {
        toastifyMessage("Failed to dispatch", undefined, "error");
      }
      return { status: true, mco: data.Mastercard_order_id };
    } else {
      return toastifyMessage(
        data?.error_message??"Could not complete the payment due to unexpected issue",
        undefined,
        "error"
      );
    }
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};