import React from 'react'
import './LandingComp.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import ArrowRight from "../../assets/arrow-next-small.svg";
import ArrowLeft from "../../assets/arrow-prev.svg"

const CategoryComp = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const history = useHistory()
    const handleClick = (str) => {
          window.open(str) 
    }
    return (
        <div onClick={() => handleClick(props.navigateTo)} className='category_comp' >
            <div className='landing_img'>
            <img src={props.categoryImg} alt={props.categoryTitle} className='landing_img' style={{filter: "brightness(90%) contrast(70%)"}} />
            <label className={lng==='arab'?'landing_label_ar':'landing_label'} style={lng === 'arab' ? {direction:"rtl"} : null}>
                <span className='title-landing'>{props.categoryTitle}</span>
                <span style={lng === 'arab' ? { textAlign: 'left', color: 'black', width: '80%' } : { textAlign: 'right', color: 'black', width: '80%' }}>{lng === 'arab' ?
                   <img src={ArrowLeft} className="fas fa-arrow-left rightarrow-i-landing"></img> : <img src={ArrowRight} className="fas fa-arrow-right rightarrow-i-landing"></img>}
                </span>
            </label>
            </div>            
        </div>
    )
}

export default CategoryComp