import React, { useState, useEffect } from "react";
import "./LocalAidRelativeIdentity.css";
import icon1 from "../../../assets/nation id.png";
import icon2 from "../../../assets/edducation.png";
import icon3 from "../../../assets/ntionality.png";
import icon4 from "../../../assets/mobile number.png";
import icon5 from "../../../assets/date of birth.png";
import icon6 from "../../../assets/gender.png";
import icon8 from "../../../assets/occupation.png";
import icon9 from "../../../assets/marital status.png";
import icon10 from "../../../assets/health.png";
import icon12 from "../../../assets/email.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import {
  localAIDAddFamily,
  getLocalAidIdentityType,
  addLocalAidIdentity,
} from "../../../api/local_aid";
import {
  getAllCountries,
  getRelationshipStatus,
} from "../../../actions/volunteer";
import { SET_VLN_ACCOUNT } from "../../../constants/actionTypes";
import DatePickerModal from "../../userPage/EditProfile/DatePickerModal";
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../../handlers/formValidation";

const LocalAidRelativeIdentity = (props) => {

  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [identityTypes, setIdentityTypes] = useState([]);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  useEffect(() => {
    let mounted = true
    getLocalAidIdentityType(lng == "arab" ? 1 : 2)
      .then((res) => {
        if (mounted) {
          setIdentityTypes(res.data.data);
        }
      })
      .catch((e) => {
        toastifyMessage("Something went wrong...!", undefined, "error");
      });
      return () => {
        mounted = false
      }
  }, []);

  // const userInfo = useSelector((state) => {
  //   return state.auth.authData;
  // });
  // function getUserInfo() {
  //   if (
  //     userLogin?.Results &&
  //     userLogin?.Results.length > 0 &&
  //     userLogin?.Results[0]
  //   ) {
  //     return userLogin?.Results[0];
  //   }
  // }
  useEffect(() => {
    dispatch(getAllCountries(lng == "arab" ? 1 : 2));
    dispatch(getRelationshipStatus(lng == "arab" ? 1 : 2));
  }, [lng]);

  const vlnRelations = useSelector((state) => state.vlnRelations);
  const [vlnvlnRelationsAll, setVlnvlnRelationsAll] = useState(vlnRelations);
  useEffect(() => {
    setVlnvlnRelationsAll(vlnRelations);
  }, [vlnRelations]);

  const [userInfoLocalAid, setUserInfoLocalAid] = useState({
    place_of_issuance: "",
    identity_name: "",
    identity_num: "",
    relation_id: "",
    start_date: "",
    end_date: '',
    remarks: "",
  });

  const vlnCountries = useSelector((state) => state.vlnCountries);
  const [vlnAllCountries, setVlnAllCountries] = useState(vlnCountries);
  useEffect(() => {
    setVlnAllCountries(vlnCountries);
  }, [vlnCountries]);
  const handleChange = (e) => {
    document.getElementById("submitBtn_for_local_aid").disabled = false;
    setUserInfoLocalAid({
      ...userInfoLocalAid,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInfoLocalAid.end_date.split('-')[0].length>4 || parseInt(userInfoLocalAid.end_date.split('-')[0]) <  1850) {
      return toastifyMessage("Please choose a valid date", undefined, "warning");
    }
    if (userInfoLocalAid.start_date.split('-')[0].length>4 || parseInt(userInfoLocalAid.start_date.split('-')[0]) <  1850) {
      return toastifyMessage("Please choose a valid date", undefined, "warning");
    }
    setLoadingModalShow(true)
    var identityId = identityTypes.find(
      (identity) => identity.identity_name == userInfoLocalAid.identity_name
    ).identity_id;
    var dataToSend = {
      issueDate: userInfoLocalAid.start_date.split('-').reverse().join('/'),
      expiryDate: userInfoLocalAid.end_date.split('-').reverse().join('/'),
      identityNo: userInfoLocalAid.identity_num,
      identType: identityId,
      issuePlace: userInfoLocalAid.place_of_issuance,
      reqId: props.reqID,
      reqYear: props.reqYear,
      remarks: userInfoLocalAid.remarks,
      lang: lng == "arab" ? 1 : 2,
    };
    
    addLocalAidIdentity(dataToSend)
      .then((res) => {
        setLoadingModalShow(false)
        // toastifyMessage("Success! Please fill the next form", undefined, "success");
        props.setSubmitRefetch(props.submitRefetch + 1)
        //props.setView(2)
        // /localAid/local - aid - relation
      })
      .catch((e) => {
        setLoadingModalShow(false)
        toastifyMessage("Something went wrong...!", undefined, "error");
      });

    // localAIDAddFamily(data).then((res) => {
    //   if (res.data.status) {
    //     alert("Success");
    //   } else {
    //     alert(res.data.result);
    //   }
    // });
  };

  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

    return (
      <div
        className="local_aid_info_form_page"
        style={
          lng === "arab"
            ? { direction: "rtl", minHeight: "500px" }
            : { minHeight: "500px" }
        }
      >
        <div className='identity_data_uploaded'>
          {props.allformsData?.identity?.map((item,i)=>
          <div key={i} className='identity_inserted_element'>
            <label className='fontFor_iserted'>{t("Identity type")}: <span style={{}}>{item.identityType}</span></label>
            <label className='fontFor_iserted'>{t("Start Date")}: <span style={{fontFamily:'english_font'}}>{item.issueDate}</span></label>
            <label className='fontFor_iserted'>{t("End Date")}: <span style={{fontFamily:'english_font'}}>{item.expiaryDate}</span></label>
            <label className='fontFor_iserted'>{t("Identity number")}: <span style={{fontFamily:'english_font'}}>{item.identityNo}</span></label>
            <label className='fontFor_iserted'>{t("Place of Issuance")}: <span style={{}}>{item.identityPlace}</span></label>
          </div>
          )}
          
        </div>
        <label style={{ padding: "10px 10px 10px 20px", fontWeight: "bold" }}>
          {t("Insert Identity details")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          <div className="just_the_form_without_submit">
            <div className="form_left_side">
              <div className="input_wraper">
                <img src={icon3} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.identity_id}
                  onChange={handleChange}
                  name="identity_name"
                  className="selectedCourse"
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                >
                  <option value="" disabled defaultValue>
                    {t("Identity type")}
                  </option>
                  {identityTypes?.map((item, i) => (
                    <option key={item.identity_id} value={item.identity_name}>
                      {item.identity_name}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}><label>{t("Start Date")}</label>
                <div className="input_wraper" style={{ width: '100%' }}>
                  <div className="local_aid_input_icon_wraper">
                    <img
                      src={icon5}
                      className="local_aid_icon"
                      alt="form_icon"
                    />
                  </div>
                  <input
                    type="date"
                    onChange={handleChange}
                    className="local_aid_input"
                    value={userInfoLocalAid.start_date}
                    // value="2014-10-13"
                    name="start_date"
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                    onKeyDown={(e) => {
                      e.preventDefault();
                   }}
                    min={`${new Date().getFullYear() - 100}-01-02`}
                     max={`${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
                  />
                </div></div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>

                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userInfoLocalAid.place_of_issuance}
                  name="place_of_issuance"
                  placeholder={t("Place of Issuance")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
              </div>
              
            </div>
            <div className="form_right_side">
            <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>

                <input
                  type="text"
                  onChange={handleChange}
                  className={userInfoLocalAid.identity_num? 'payment_numbers local_aid_input': lng==='arab'? 'force_arabic_font local_aid_input':'payment_numbers local_aid_input'}
                  value={userInfoLocalAid.identity_num}
                  name="identity_num"
                  placeholder={t("Identity number")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
              </div>
              
                <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}><label>{t("End Date")}</label>
                <div className="input_wraper" style={{ width: '100%' }}>
                  <div className="local_aid_input_icon_wraper">
                    <img
                      src={icon5}
                      className="local_aid_icon"
                      alt="form_icon"
                    />
                  </div>
                  <input
                    type="date"
                    onChange={handleChange}
                    className="local_aid_input"
                    value={userInfoLocalAid.end_date}
                    // value="2014-10-13"
                    name="end_date"
                    required
                    onInvalid={applyValidation}
                    onInput={resetValidation}
                    onKeyDown={(e) => {
                      e.preventDefault();
                   }}
                    min={`${new Date().getFullYear() - 100}-01-02`}
                  />
                </div></div>
              <div className="input_wraper">
                <div className="local_aid_input_icon_wraper">
                  <img src={icon1} className="local_aid_icon" alt="form_icon" />
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  className={lng==='arab'? "local_aid_input force_arabic_font":"local_aid_input"}
                  value={userInfoLocalAid.remarks}
                  name="remarks"
                  placeholder={t("Remarks")}
                  required
                  onInvalid={applyValidation}
                  onInput={resetValidation}
                />
              </div>
              {/* <div className="input_wraper">
                <img src={icon9} className="local_aid_icon" alt="form_icon" />
                <select
                  value={userInfoLocalAid.relation_id}
                  onChange={handleChange}
                  name="relation_id"
                  className="selectedCourse"
                >
                  <option value="" disabled selected>
                    {t("Select Realtions Status")}
                  </option>
                  {vlnvlnRelationsAll?.map((item, i) => (
                    <option value={item.relation_id}>
                      {item.relation_name}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
            disabled
          >
            <span style={{ flex: "16" }}>{t("Save")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default LocalAidRelativeIdentity;
