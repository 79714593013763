import React, { useState, useEffect, useRef } from 'react'
import './AllSukukCom.css'
import donateIcon from '../../../assets/donateIconForBanner.png'
import applePay from '../../../assets/applePay.png'
import smsicon from '../../../assets/samsungpay.png'
import share from "../../../assets/share.png";

import cash from '../../../assets/mobile.png'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from "react-router";
import cashbills from '../../../assets/donateByCash.png'
import sukuk from '../../../assets/sukukalkhair.png'
import ShareAlertModel from '../../LoaclComponents/shareHeader/share_model/ShareModelAlert'
import donateIconRed from '../../../assets/BagRed.png'
import { handleSamsungDirectPayment } from '../../../actions/handleSamsungPayment'
import { startApplePay } from '../../../actions/handleApplePayment'
import Loading from '../../ui/loading'
import SmsModal from '../../ui/smsModal/SmsModal'
import DonationFinalPopUp from '../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp'
import LoadingModal from '../../Auth/loginSignPage/loadingPopUp/loadingPopUp'
const AllSukukCom = (props) => {
    const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [geustModal, setGuestModal] = useState(false);
    const [functionToSend, setFunctionToSend] = useState(()=> {});
    const history = useHistory();

    const [modalShow, setModalShow] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [liked, setLiked] = useState(false)
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
    const [showCopyCard, setshowCopyCard] = useState(false);

    const [inTheBagText, setInTheBagText] = useState('Add to Bag');
    const [animationClass, setAnimationClass] = useState('btn_with_text');
    const [desiableMonthYer, setDisableMonthYer] = useState(props.recurring);
    const [btnToBeStyled, setBtnToBeStyled] = useState(`all_${props.donationID}_N`);

    const [smsModalShow, setSmsModalShow] = useState(false);
    const userFav = useSelector(state => state.favorites.favorites)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    const [addingToFav, setAddingToFav] = useState(false);
    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])

    const userBag = useSelector(state => state.bag.bag)
    const [bagDetails, setBagDetails] = useState(userBag)
    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])
    const [inTheBag, setInTheBag] = useState(0)
    const [choosenToDonateSuk, setChoosenToDonateSuk] = useState({
        sukId: props.donationID,
        donationFreq: 'N',
        amount: parseInt(props.amount),
        title: props.title,
        img: props.sukukImg,
        item_type: 0
    })
    const [addingToCart, setAddingToCart] = useState(false);
    useEffect(() => {
        if (!userLogin) {    
            if (props.currentProfile?.liked?.sukuks?.filter(item => item.sukId === props.donationID).length > 0) {
                setLiked(true)
            } else {
            setLiked(false)
            }
        } else {
            if (favoritesDetails?.sukuks?.filter(item => item.dontypeID === props.donationID).length > 0) {
                setLiked(true)
            } else {
                setLiked(false)
            }
        }

        if (!userLogin) {
            if (props.currentProfile?.cart?.sukuks?.filter(item => item.sukId === props.donationID && item.donationFreq === choosenToDonateSuk.donationFreq).length > 0) {
                setInTheBag(props.currentProfile?.cart?.sukuks?.filter(item => item.sukId === props.donationID && item.donationFreq === choosenToDonateSuk.donationFreq).length)
            }
        } else {
            if (bagDetails?.sukuks?.filter(item => item.dontypeID === props.donationID && item.recurring === choosenToDonateSuk.donationFreq && parseInt(item.baseAmount) === parseInt(choosenToDonateSuk.amount)).slice(0).shift()?.quantity) {
                setInTheBag(bagDetails?.sukuks?.filter(item => item.dontypeID === props.donationID && item.recurring === choosenToDonateSuk.donationFreq && parseInt(item.baseAmount) === parseInt(choosenToDonateSuk.amount)).slice(0).shift()?.quantity)
            }
        }
    }, [props.donationID, props.currentProfile, bagDetails, choosenToDonateSuk.amount, choosenToDonateSuk.donationFreq, favoritesDetails])
    const handleLikeDisLike = async (str) => {
        setAddingToFav(true)
        if (liked) {
           await props.handleDisLike({...str, recurring: desiableMonthYer? 'N':'Y', amount: parseInt(props.amount)})
           if (_isMounted.current) {
              setAddingToFav(false) 
           }
            
        } else {
           await props.handleLike({...str, recurring: desiableMonthYer? 'N':'Y', amount: parseInt(props.amount)})
           if (_isMounted.current) {
              setAddingToFav(false) 
           }
            
        }
    }
    const sendSMS = () => {
        setSmsModalShow(true)
        //window.location.href = "sms:2441;?&body=Donate Now to Red Crescent";
    }
    const handleAddToBag = (str) => {
        props.handleaddToBag(str, setAddingToCart, setAnimationClass, setInTheBagText)
    }
    const [sukukAmount, setSukukAmount] = useState(parseInt(props.amount));
    const handleClick = (op) => {
        if (op === '-') {
            if (choosenToDonateSuk.amount > parseInt(props.amount)) {
                setChoosenToDonateSuk({ ...choosenToDonateSuk, amount: parseInt(choosenToDonateSuk.amount) - parseInt(props.amount) });
            }
        } else {
            setChoosenToDonateSuk({ ...choosenToDonateSuk, amount: parseInt(choosenToDonateSuk.amount) + parseInt(props.amount) });
        }
    }
    useEffect(() => {
        setBtnToBeStyled(`all_${props.donationID}_${choosenToDonateSuk.donationFreq}`)
        // if (!desiableMonthYer) {
        //    if (choosenToDonateSuk.donationFreq === 'N') {
        //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = '#ee4236'
        //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'white'
        //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'black'
        //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'black'
        // } else if (choosenToDonateSuk.donationFreq === 'M') {
        //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = '#ee4236'
        //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'black'
        //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'white'
        //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'black'
        // } else {
        //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = 'unset'
        //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = '#ee4236'
        //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'black'
        //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'black'
        //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'white'
        // } 
        // } else {
        //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = '#ee4236'
        //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'white'
        // }
        
    }, [choosenToDonateSuk])
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    const startSamsungPay = async () => {
        setLoadingModalShow(true);
        await handleSamsungDirectPayment({ amount: choosenToDonateSuk.amount, url: window.location.href, item: [{dontype_id: choosenToDonateSuk.sukId, amount: choosenToDonateSuk.amount, recurring: choosenToDonateSuk.donationFreq? choosenToDonateSuk.donationFreq: "N",
                            quantity: 1, item_type: 0
                            }] })
        setLoadingModalShow(false);

    }
    const handleGuestPopUp = async (options) => {
        if (userLogin || visitor) {
           options() 
        } else {
          setFunctionToSend(()=> options)
          setGuestModal(true)
        }
    }
    return (
        <div id='all_sukuk_com' style={lng === 'arab' ? { direction: 'rtl' } : null}>
            <label className='title_of_sukuk' style={lng === 'arab' ? { width: '100%', textAlign: 'right', fontSize: '20px', fontWeight: 'bold' } : { width: '100%', textAlign: 'left', fontSize: '20px', fontWeight: 'bold' }}>{props.title}</label>
            {/*addingToCart?
            <div style={{position:'absolute', left: '0', right: '0', top: '0', bottom: '0', width:'100%'}}>
                <Loading height={'100%'}/>
            </div>:null*/}
            <div className='sukuk_item' style={{border: props.neededsuk===props.donationID? '2px solid #ee4236':'unset'}}>
                <div className='img_sukuk_wraper' style={{ position: 'relative', flex: '4' }}>
                    <img src={props.sukukImg} alt={props.title} className='sukuk_item_img' />
                    {addingToFav?
                    <button disabled={addingToFav} style={lng === 'arab' ? { left: 'unset', right: '16px' } : { left: '16px', right: 'unset' }} className='like_btn_for_sukuk'>
                        <div className="containerAnime">
                            <div className="ballFav"></div>
                            <div className="ballFav"></div>
                        </div>
                    </button>:
                    <React.Fragment>
                    {lng === 'arab' ?
                        <button style={{ left: 'unset', right: '16px' }} onClick={() => handleLikeDisLike(choosenToDonateSuk)} className='like_btn_for_sukuk'>{liked ? <i style={{ color: '#ee4236' }} className="fas fa-heart"></i> : <i style={{ color: '#808080b0' }} className="fas fa-heart"></i>}</button> :
                        <button style={{ left: '16px', right: 'unset' }} onClick={() => handleLikeDisLike(choosenToDonateSuk)} className='like_btn_for_sukuk'>{liked ? <i style={{ color: '#ee4236' }} className="fas fa-heart"></i> : <i style={{ color: '#808080b0' }} className="fas fa-heart"></i>}</button>
                    }
                    </React.Fragment>  }
                    {lng === 'arab' ?
                        <button style={{ left: 'unset', right: '50px', top:'6px' }} onClick={() => setshowCopyCard(true)} className='like_btn_for_sukuk'>          
                            <img style={{ height: "22px"}} src={share} alt="shareMob" />
                        </button> :
                        <button style={{ left: '50px', right: 'unset', top:'6px' }} onClick={() => setshowCopyCard(true)} className='like_btn_for_sukuk'>          
                            <img style={{ height: "22px"}} src={share} alt="shareMob" />
                        </button>
                    }
                </div>
                <div className='sukuk_item_board_all'>
                    <div className='btn-description'>
                        {/* <label className='respon_display_none'>{t("Donation Frequency")}</label> */}
                        <div className='sukuk_item_board_time'>
                            <button style={btnToBeStyled===`all_${props.donationID}_N`? {backgroundColor: '#ee4236', color:'white'}:null} disabled={addingToCart} id={`btn_one_${props.donationID}`} onClick={() => setChoosenToDonateSuk({ ...choosenToDonateSuk, donationFreq: 'N' })} className='btn btn-block btn-light btn-frequ btn-sukuk'>{t("One Time")}</button>
                            {!desiableMonthYer?
                            <button style={btnToBeStyled===`all_${props.donationID}_M`? {backgroundColor: '#ee4236', color:'white'}:null} disabled={addingToCart} id={`btn_two_${props.donationID}`} onClick={() => setChoosenToDonateSuk({ ...choosenToDonateSuk, donationFreq: 'M' })} className='btn btn-block btn-light btn-frequ btn-sukuk'>{t("Monthly")}</button>:null
                        }
                        {!desiableMonthYer?
                        <button style={btnToBeStyled===`all_${props.donationID}_D`? {backgroundColor: '#ee4236', color:'white'}:null} disabled={addingToCart} id={`btn_three_${props.donationID}`} onClick={() => setChoosenToDonateSuk({ ...choosenToDonateSuk, donationFreq: 'D' })} className='btn btn-block btn-light btn-frequ btn-sukuk'>{t("Daily")}</button>:null
                    }
                        </div>
                    </div>
                    <div className='btn-description'>
                        {/* <label className='respon_display_none'>{t("Donation Amount")}</label> */}
                        <div className='sukuk_inc_Dec'>
                            <button className='sukukdec btn btn-light' onClick={() => handleClick('-')}>-</button>
                            <label style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold' }}>{t("AED")} <span className='payment_numbers' style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'none' }}>{choosenToDonateSuk.amount}</span></label>
                            <button className='sukukinc btn btn-light' onClick={() => handleClick('+')}>+</button>
                        </div>
                    </div>
                    <div className='sukuk_item_board_payment'>
                        {inTheBagText === 'Added' ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={'btn_with_text animate__animated animate__bounceIn'}>
                                <button disabled={addingToCart} className="btn_for_sukuk">
                                    <label style={{ fontSize: width <= 900 ? '7px' : '12px', color: '#ee4236' }}>{t("You now have ") + inTheBag + t(" in your Bag")}</label>
                                </button>
                            </div> :
                            <div className={animationClass}>
                                {addingToCart ?
                                    <Loading height={'40px'} small={'30px'} animate={true} /> :
                                    <React.Fragment>
                                        <button onClick={() => handleAddToBag(choosenToDonateSuk)} className='btn_for_sukuk'><img src={donateIcon} alt='donate_btn_icon_banner' className='sukuk_btn_img_general' /></button>
                                        <label className='btn_label_for_sukul'>{t(inTheBagText)}</label>
                                    </React.Fragment>}
                            </div>}
                        {choosenToDonateSuk.donationFreq !== "N" ?
                            <div className='btn_with_text'>
                                <button disabled className='btn_for_sukuk'><img src={window.ApplePaySession ? applePay : smsicon} style={{ opacity: "40%" }} alt='donate_btn_icon_banner' className={window.ApplePaySession ? 'apple_pay_icon' : 'sukuk_btn_img_general'} /></button>
                                <label className='btn_label_for_sukul'>{window.ApplePaySession ? t('Apple Pay') : t('Samsung Pay')}</label>
                            </div> :
                            <div className='btn_with_text'>
                                <button onClick={() => window.ApplePaySession ? handleGuestPopUp(()=> startApplePay({ amount: choosenToDonateSuk.amount, cart: [{dontype_id: choosenToDonateSuk.sukId, amount: choosenToDonateSuk.amount, recurring: choosenToDonateSuk.donationFreq? choosenToDonateSuk.donationFreq: "N",
                            quantity: 1, item_type: 0
                            }] }, dispatch, history)) : handleGuestPopUp(startSamsungPay)} className='btn_for_sukuk'><img src={window.ApplePaySession ? applePay : smsicon} alt='donate_btn_icon_banner' className={window.ApplePaySession ? 'apple_pay_icon' : 'sukuk_btn_img_general'} /></button>
                                <label className='btn_label_for_sukul'>{window.ApplePaySession ? t('Apple Pay') : t('Samsung Pay')}</label>
                            </div>
                        }

                        {width < 900 ?
                            <div className='btn_with_text'>
                                <button onClick={() => sendSMS()} className='btn_for_sukuk'><img src={cash} alt='donate_btn_icon_banner' className='sukuk_btn_img' /></button>
                                <label className='btn_label_for_sukul'>{t("SMS")}</label>
                            </div> : null
                        }
                        
                    </div>
                    <div className='sukuk_inc_Dec noWidth'>
                        <img src={sukuk} alt='donate_btn_icon_banner' className='sukuk-img' />
                        <div className='btn_with_text' style={{width:'auto'}}>
                            <button onClick={() => history.push(`/checkout`, { from: 'donations', elements: [{ title: choosenToDonateSuk.title, price: choosenToDonateSuk.amount }], totalAmount: choosenToDonateSuk.amount, details: choosenToDonateSuk })} className='btn_for_sukuk'><img src={cashbills} alt='donate_btn_icon_banner' className='sukuk_btn_img_apple' /></button>
                            <label className='btn_label_for_sukul'>{t('Donate Now')}</label>
                        </div>
                    </div>
                </div>
            </div>
            <ShareAlertModel
                path='sukuk'
                shareData={choosenToDonateSuk}
                show={showCopyCard}
                onHide={() => {
                    setshowCopyCard(false);
                }}
            />
            <SmsModal
                show={smsModalShow}
                donationid={props.donationID}
                onHide={() => setSmsModalShow(false)}
            />
            <DonationFinalPopUp
              show={geustModal}
              paymentfunction = {functionToSend}
              onHide={()=> setGuestModal(false)}
              dialogClassName="modal-60w"
            />
            <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
        </div>
    )
}

export default AllSukukCom
