import { DONATIONS_LOADED, FETCH_ALL_DONATIONS, LOAD_DONATIONS } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getDonations = (lang, device_type) => async (dispatch) => {
    dispatch({ type: LOAD_DONATIONS });
    try {
        const { data } = await api.getDonations(lang, device_type);
        data.forEach(item => {
            if(!item.amount)
                item.amount = "10"
        })
        dispatch({ type: FETCH_ALL_DONATIONS, payload: data })
        dispatch({ type: DONATIONS_LOADED })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: DONATIONS_LOADED })
    }
}