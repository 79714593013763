//React
import React, { useEffect, useState } from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";

//CSS
import "./GraceConversation.css";

//Bootstrap

//Translation
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Tab, Tabs } from "react-bootstrap";
import { getAllGraceList } from "../../actions/grace";
import Loading from '../ui/loading'
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";

const GraceConversation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const allgrace = useSelector((state) => state.allgrace);
  const [gace_conversations_list, setGalleryList] = useState(allgrace);
  useEffect(() => {
    dispatch(getAllGraceList(lng == "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    setGalleryList(allgrace);
  }, [allgrace]);
  const history = useHistory();

  return (
    <div className="ourLoactions">
      <ShareHeader pageName={t("Grace conservation")} noShare={true} />
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("Grace conservation")}</h3>
      </div>
      {gace_conversations_list.length > 0 ? <div className="grace_info_grid">
        {gace_conversations_list?.map((item, i) => {
          return <GraceCard key={i} news={item} />;
        })}
      </div> : <Loading height= '85vh' />}
    </div>
  );

  function GraceCard(props) {
    const background = props.news.url;

    return (
      <div
        onClick={() => {
          // history.push("grace_conversations/request", { id: props.news.reqID });
          history.push({
            pathname: '/grace_conversations/request',
            // search: '?query=abc',
            state: { graceId: props.news.reqID }
          });
        }}
        className="grace_info"
        onMouseEnter={() => document.getElementById(`grace_title_${props.news.reqID}`).style.color = '#ee4236'}
        onMouseLeave={() => document.getElementById(`grace_title_${props.news.reqID}`).style.color = '#fff'}
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          cursor: 'pointer'
        }}
      >
        <label id={`grace_title_${props.news.reqID}`} style={{ color: "white", margin: "auto", fontSize: '20px', transition: 'all .2s ease-in-out' }}>{props.news.reqName}</label>
      </div>
    );
  }
};

export default GraceConversation;
