import React, { useState, useEffect } from "react";
import "./ProjectShareRedirect.css";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import Loading from "../ui/loading";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import defaultImg from '../../assets/defaultA.jpg'
import notFoundBc from '../../assets/404.png'


const ProjectShareRedirect = (props) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const shareID = query.get("projectShareID");
  const shareYear = query.get("projectShareYear");

  useEffect(() => {
    window.location.href = `https://www.emiratesrc.ae/PrjShare/share_ar.aspx?recID=${shareID}&recYear=${shareYear}`;
  }, [])
  return (
    <div className='not_found_wraper'>
            Redirecting...
        </div>
  );
};

export default ProjectShareRedirect;
