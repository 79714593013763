import React, { useState, useEffect, useRef } from 'react'
import './CampainItemBag.css'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

const styleForWraper = {
    filter: 'blur(8px)',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const bigWraperStyle = {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.589)'
}
const styleForNotAv = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2000'
}

const CampainItemBag = (props) => {
    const _isMounted = useRef(true);
    useEffect(() => {
      return () => {
          _isMounted.current = false;
      }
    }, []);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const compagin = useSelector(state => state.compaginsSlider.compaginSliders)
    const [realAmount, setRealAmount] = useState(props.realAmount)
    const [donationItemOnBagDetails, setDonationItemBagDetails] = useState({
        donationId: props.donationId,
        campName: props.campainName,
        amount: props.amount,
        title: props.title,
        img: props.itemImg
    });
    const [addingToCart, setAddingToCart] = useState(false);
    useEffect(() => {
        setDonationItemBagDetails({
            donationId: props.donationId,
            campName: props.campainName,
            amount: props.amount,
            title: props.title,
            img: props.itemImg
        })
    }, [props.currentProfile])
    useEffect(() => {
        setRealAmount(props.realAmount)
    }, [props.campainName])

    const handleClick = (op) => {
        if (op === '-') {
            if (donationItemOnBagDetails.amount >  parseInt(realAmount)) {
                let copyOf = props.currentProfile.cart.donations.slice(0);
                copyOf.splice(copyOf.findIndex(item => item.donationId === props.donationId), 1, { ...donationItemOnBagDetails, amount: parseInt(props.amount) - parseInt(realAmount) })
                let newProfile = { cart: { donations: copyOf, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
                const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
                localStorage.setItem('profile', JSON.stringify(newProfileFinal))
                props.setChange(props.change + 1)
            }
        } else {
            let copyOf = props.currentProfile.cart.donations.slice(0);
            copyOf.splice(copyOf.findIndex(item => item.donationId === props.donationId), 1, { ...donationItemOnBagDetails, amount: parseInt(props.amount) + parseInt(realAmount) })
            let newProfile = { cart: { donations: copyOf, sukuks: props.currentProfile.cart.sukuks, projects: props.currentProfile.cart.projects, humanCases: props.currentProfile.cart.humanCases, orphans: props.currentProfile.cart.orphans }, liked: props.currentProfile.liked }
            const newProfileFinal = { cart: newProfile.cart, liked: newProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfileFinal))
            props.setChange(props.change + 1)

        }
    }
    const handleInBag = async(str) => {
        let newProfile = {
            cart: {
                donations: props.currentProfile.cart.donations.filter(item => !(item.donationId === str)),
                sukuks: props.currentProfile.cart.sukuks,
                projects: props.currentProfile.cart.projects,
                humanCases: props.currentProfile.cart.humanCases,
                orphans: props.currentProfile.cart.orphans
            },
            liked: props.currentProfile.liked
        }
        localStorage.setItem('profile', JSON.stringify(newProfile))
        props.setChange(props.change + 1)
    }
    return (
        <div className='big_wraper_bag' style={props.status === 'not' ? bigWraperStyle : { width: '100%', zIndex: '1000' }}>
            <button style={lng === 'arab' ? { right: 'unset', left: '10px', color: 'white' } : { color: 'white' }} onClick={() => handleInBag(props.donationId)} className='removeBtn_from_bag'><i className="fas fa-times"></i></button>
            <div className='bag_item_com campain' style={props.status === 'not' ? styleForWraper : null}>
                <img src={props.itemImg} alt='item_img' className='bag_item_img' />
                <div style={{ width: '100%', flex: '7', alignSelf: 'flex-start', fontWeight: 'bold' }}>
                    <label style={{ width: '100%', alignSelf: 'flex-start', fontWeight: 'bold' }}>{props.title}</label>
                    <label style={{ fontSize: '11px' }}>{t("Campaign")}: {props.campainName}</label>
                </div>
                <div className='donation_inc_Dec_bag'>
                    {props.status === 'not' ?
                        <button className='inc' onClick={() => handleClick('-')} disabled>-</button> :
                        <button className='inc' onClick={() => handleClick('-')}>-</button>
                    }
                    <label className='label_to_beSmall payment_numbers' style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold' }}>{t("AED")} <span style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'none' }}>{donationItemOnBagDetails.amount}</span></label>
                    {props.status === 'not' ?
                        <button className='dec' onClick={() => handleClick('+')} disabled>+</button> :
                        <button className='dec' onClick={() => handleClick('+')}>+</button>
                    }            </div>
            </div>
            <label style={props.status === 'not' ? styleForNotAv : { display: 'none' }}>{t("Not available")}</label>
        </div>
    )
}

export default CampainItemBag
