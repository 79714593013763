import { FETCH_ALL_PROJECTS, FETCH_COUNTRIES_FOR_PROJECT_TYPE, FETCH_ALL_PROJECTS_UPDATED, FETCH_ALL_COUNTRIES_PROJETCS_SEC, FETCH_ALL_PROJECTS_FOR_COUNTRY, FETCH_ALL_PROJECTS_FULL, FETCH_ALL_PROJECTS_FULL_LOADING, FETCH_ALL_PROJECTS_LOADING
    ,FETCH_ALL_PROJECTS_ENOUGH_LOADING, FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING, FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getFullProjects = (langNumber) => async (dispatch) => {
    dispatch({ type: FETCH_ALL_PROJECTS_FULL_LOADING, status: true })
    try {
        
        const { data } = await api.getFullProjects(langNumber);
        dispatch({ type: FETCH_ALL_PROJECTS_FULL, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: FETCH_ALL_PROJECTS_FULL_LOADING, status: false })
    }
}
// export const getProjects = (langNumber, orderType, orderBy, maxVal, minValu, projTypeID) => async (dispatch) => {
//     dispatch({ type: FETCH_ALL_PROJECTS_LOADING})
//     try {
//         const { data } = await api.getProjects(langNumber, orderType, orderBy, maxVal, minValu, projTypeID);
//         let projectsIDS = data.data.allProjects.map(item => item.projTypeID);
//         let uniqueProjects = []
//         for (let i = 0; i < data.data.allProjects.length; i++) {
//             projectsIDS.splice(projectsIDS.indexOf(data.data.allProjects[i].projTypeID), 1)
//             if (projectsIDS.indexOf(data.data.allProjects[i].projTypeID) < 0)
//                 uniqueProjects.push(data.data.allProjects[i]);
//         }
//         dispatch({ type: FETCH_ALL_PROJECTS, payload: {filtered: data.data.filteredProjects, projectsFull: uniqueProjects.map(item=> {return {imagePath: item.projTypeImagePath, name: item.prjTypeName, projTypeID: item.projTypeID}})} })
//     } catch (error) {
//         //toastifyMessage("Something went wrong...!", undefined, "error");
//         dispatch({ type: FETCH_ALL_PROJECTS_ENOUGH_LOADING})
//     }
// }

// export const getCountries = (langNumber, orderType, orderBy, maxVal, minVal, projTypeID) => async (dispatch) => {
//     dispatch({ type: FETCH_ALL_COUNTRIES_PROJETCS_SEC_LOADING})
//     try {
//         const { data } = await api.getCountriesForProjects(langNumber, orderType, orderBy, maxVal, minVal, projTypeID);
//         let projectsIDS = data.data.allProjects.map(item => item.projTypeID);
//         let uniqueProjects = []
//         for (let i = 0; i < data.data.allProjects.length; i++) {
//             projectsIDS.splice(projectsIDS.indexOf(data.data.allProjects[i].projTypeID), 1)
//             if (projectsIDS.indexOf(data.data.allProjects[i].projTypeID) < 0)
//                 uniqueProjects.push(data.data.allProjects[i]);
//         }
//         dispatch({ type: FETCH_ALL_COUNTRIES_PROJETCS_SEC, payload: {filtered: data.data.filteredProjects, projectsFull: uniqueProjects.map(item=> {return {imagePath: item.countryImagePath, name: item.countryName, countryID: item.countryID}})} })
//     } catch (error) {
//         //toastifyMessage("Something went wrong...!", undefined, "error");
//         dispatch({ type: FETCH_ALL_COUNTRIES_PROJETCS_SEC_ENOUGH_LOADING})
//     }
// }

// export const getCountriesForProjectID = (langNumber, projectTypeID, setCountryLoading, orderType, orderBy, maxVal, minVal) => async (dispatch) => {
//     try {
//         const { data } = await api.getCountriesForProject(langNumber, projectTypeID, orderType, orderBy, maxVal, minVal);
//         dispatch({ type: FETCH_COUNTRIES_FOR_PROJECT_TYPE, payload: data })
//         setCountryLoading(false)
//     } catch (error) {
//         //toastifyMessage("Something went wrong...!", undefined, "error");
//     }
// }
// export const getProjectsForCountries = (langNumber, countryID, setCountryLoading, orderType, orderBy, maxVal, minVal, projTypeID) => async (dispatch) => {
//     try {
//         const { data } = await api.getProjectsForCountries(langNumber, countryID, orderType, orderBy, maxVal, minVal, projTypeID);
//         dispatch({ type: FETCH_ALL_PROJECTS_FOR_COUNTRY, payload: data })
//         setCountryLoading(false)
//     } catch (error) {
//         //toastifyMessage("Something went wrong...!", undefined, "error");
//     }
// }

// export const getAllProjectsPerCountries = (langNumber, projectTypeID, countryID, setAmountRangesLoading, orderType, orderBy, maxVal, minValue, projTypeID) => async (dispatch) => {
//     try {
//         const { data } = await api.getProjectsFinal(langNumber, projectTypeID, countryID, orderType, orderBy, maxVal, minValue, projTypeID);
//         dispatch({ type: FETCH_ALL_PROJECTS_UPDATED, payload: data })
//         setAmountRangesLoading(false)
//     } catch (error) {
//         //toastifyMessage("Something went wrong...!", undefined, "error");
//     }
// }