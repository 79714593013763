import React, { useState, useEffect } from "react";
import "./TransactionItem.css";
import { useTranslation } from "react-i18next";
const TransactionItem = (props) => {
  const { t } = useTranslation();

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <div className="latePayment_page_component">
      <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
      <img src={props.item.orphan_pic} style={{ borderRadius: "15px" }} height="100px" />

      <div className="header_descr">
        <label style={{ fontWeight: "bold" }}>{props.item.orphan_name}</label>
        
        
          <label>{t(props.item.transaction_type)}</label>
        
          <label>{t("AED")}{" "}<span className='payment_numbers' style={{ fontWeight: "bold" }}>{props.item.transaction_amount}</span></label>

      </div>
      </div>
      <div className="total_add_to_cart">
        <div className="btn_of_late_payment">
        <span className='payment_numbers'>{new Date(props.item.transaction_date).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",      
      })}</span>
        </div>
      </div>
      
    </div>
  );
};

export default TransactionItem;
