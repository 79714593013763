import { FETCH_ALL_PROJECTS, FETCH_ALL_PROJECTS_LOADING, FETCH_ALL_PROJECTS_ENOUGH_LOADING} from '../../constants/actionTypes'

export default (projects = {
    projects: [],
    projectsFull: [],
    loading: true
}, action) => {
    switch (action.type) {
        case FETCH_ALL_PROJECTS:
            return {...projects, projects: action.payload.filtered, loading: false, projectsFull: action.payload.projectsFull};
        case FETCH_ALL_PROJECTS_LOADING:
            return {...projects, loading: true};
        case FETCH_ALL_PROJECTS_ENOUGH_LOADING:
            return {...projects, loading: false};
        default:
            return projects;
    }
}