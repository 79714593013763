import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NeedToRegisterModal.css";
import { useEffect, useState } from "react";
import { checkLocalAidOpen } from "../../../api/local_aid";

function NeedToRegisterModal(props) {
  const [allowRegistration, setAllowRegistration] = useState(true)

  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();

  useEffect(() => {
checkLocalAidOpen().then((res) => {
  setAllowRegistration(res.data.status)
})
  },[])
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="donation_pop_up_body"
    >
      <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
        <div
          className="modal_body_for_donation_final"
          style={lng === "arab" ? { direction: "rtl" } : null}
        >
          {props.localaid ? (
            <label className='centered'>
              {!allowRegistration? t("Local Aid Registration is currently unavailable"):
              <>
              {t("It seems you don't have a ")}{" "} 
              <span style={{ fontWeight: "bold" }}>
               {t("Local Aid account")}
             </span>{" "}
             {t(", so we recommend to create one.")}
             </>
              }
              

              
            </label>
          ) : (
            <label className='centered'>
              {t("It seems you don't have a ")}{" "}
              <span style={{ fontWeight: "bold" }}>
                {t("Volunteer account")}
              </span>{" "}
              {t(", so we recommend to create one.")}
            </label>
          )}

          <div className="donation_final_popUp_btns_wraper">
            {props.localaid ? (
              !allowRegistration? null:  <button
              onClick={() => history.push("/localAid/setup-information")}
              className=" donation_final_popUp_btn_second"
            >
              {t("Create account")}
            </button>
              
             
            ) : (
              <button
                onClick={() => history.push("/volunteer/vln-registration")}
                className=" donation_final_popUp_btn_second"
              >
                {t("Create account")}
              </button>
            )}
            {props.localaid ? (
              <button
                onClick={() =>
                  history.push("/localAid/link-account", { from: "profile" })
                }
                className=" donation_final_popUp_btn_Third"
              >
                {t("I have an account")}
              </button>
            ) : (
              <button
                onClick={() =>
                  history.push("/volunteer/link-account", { from: "profile" })
                }
                className=" donation_final_popUp_btn_Third"
              >
                {t("I have an account")}
              </button>
            )}

            <button
              onClick={() => props.onHide()}
              className="donation_final_popUp_btn_first"
            >
              {t("Not Now")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default NeedToRegisterModal;
