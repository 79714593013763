import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import "./FilterModalTransactionItem.css";
import CheckMark from "../../../../assets/checkmark_filter.png"
const FilterModalTransactionItem = (props) => { 
  const markClass = "add-check"
  const [reload, setReload] = useState(props.item)
  const handleCheck = () => {
    props.item.active = !props.item.active
    setReload(!reload)
  }
  return (<div className="orphan-filter">
  <img className="orphan-filter-image" src={props.item.orp_pic} />
  <div className="orphan-filter-text">
    <h1 className="orphan-filter-text-name">{props.item.orp_name}</h1>
    <h2 className="orphan-filter-text-id payment_numbers">{props.item.orp_id}</h2>
  </div>
  <div className={`${props.item.active?markClass:null} orphan-filter-select-mark`} style={{cursor: 'pointer', display: "flex"}}  onClick={() => handleCheck()}>
    {props.item.active?<img style={{display: 'block', margin: 'auto', height: '60%'}} src={CheckMark} />: null}
  </div>

</div>
  )
};
export default FilterModalTransactionItem;