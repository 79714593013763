import { FETCH_ALL_PROJECT_SHARES, PROJECT_SHARES_LOADED, LOAD_PROJECT_SHARES, FETCH_ALL_PROJECTS_FULL_SHARE_CREATE, FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING } from '../constants/actionTypes'
import * as api from '../api'

export const getProjectShares = (langNumber) => async (dispatch) => {
    dispatch({ type: LOAD_PROJECT_SHARES });
    try {
        const { data: {data} } = await api.getProjectShares(langNumber);
        data.forEach(element => {
            element.amount = Math.ceil(element.amount)
            element.amountCollected = Math.floor(element.amountCollected)
        });

        let complete = [];
        let normal = [];
        for (let i=0; i<data.length;i++) {
            if ((data[i].amountCollected/data[i].amount)*100 >= 100) {
                complete.push(data[i])
            } else {
                normal.push(data[i])
            }
        }
         normal.push(...complete)
        dispatch({ type: FETCH_ALL_PROJECT_SHARES, payload: normal })
        dispatch({ type: PROJECT_SHARES_LOADED });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: PROJECT_SHARES_LOADED });
    }
}


export const getShareProjects = (langNumber) => async (dispatch) => {
    dispatch({ type: FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING, status: true })
    try {
        
        const { data } = await api.getShareProjects(langNumber);
        dispatch({ type: FETCH_ALL_PROJECTS_FULL_SHARE_CREATE, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: FETCH_ALL_PROJECTS_FULL_SHARE_CREATE_LOADING, status: false })
    }
}