import React, { useEffect, useState } from "react";
import "./SwitchProjectShares.css";
import { useTranslation } from "react-i18next";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllSharedProjects } from "../../../api/account";
import Loading from "../../ui/loading";
import ProjectShareCom from "../../homePage/sections/projectShare/projectShareCom/projectShareCom";


const SwitchProjectShares = () => {
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const history = useHistory();
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  const [allSharedProjects, setAllSharedProjects] = useState([]);
  const [allSharedProjectsLoading, setAllSharedProjectsLoading] = useState(true);
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  useEffect(() => {
    if (!userLogin) {
      history.push('/')
    }
  }, [])
  useEffect(() => {
    let mounted = true
    async function fetchAllSharedProjects() {
      setAllSharedProjectsLoading(true)
      const allProjectsFromApi = await getAllSharedProjects(
        getUserInfo()?.email,
        lng === "arab" ? "1" : "2"
      );
      if (allProjectsFromApi?.data?.data?.length > 0) {
        if (mounted) {
        setAllSharedProjects(allProjectsFromApi?.data.data);
        setAllSharedProjectsLoading(false)
        }
      } else {
        if (mounted) {
          setAllSharedProjects([])
          setAllSharedProjectsLoading(false)
      }
      }
      
    }
    fetchAllSharedProjects();
    //Call APIs here
    return () => {
      mounted= false
    }
  }, []);




  return (
    <div id="project_shares_display_page">
      <ShareHeader noShare={true} pageName={t("Project Shares")} />
      <div
        className="header_of_human_cases"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <h2
          style={{
            padding: "0px 0px 10px 0px",
            borderBottom: "1px solid gray",
            textAlign: "center",
          }}
        >
          {t("Project Shares")}
        </h2>
      </div>
      <div className="project-share-textfield-section-wrapper">
        <div
          className="search_input_project_share"
          style={
            lng === "arab" ? { flexDirection: "row", direction: "rtl" } : null
          }
        >
          <div style={{ width: "100%", display: "flex" }}>
            <input
              className={
                (lng === "arab" ? "force_arabic_font" : "payment_numbers") +
                " " +
                "project_share_donation_text_field_text"
              }
              onChange={(e) => searchData2(e.target.value)}
              type="text"
              placeholder={t("Search for a project")}
              style={
                lng === "arab"
                  ? {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    }
                  : null
              }
            />
            <button
              style={
                lng === "arab"
                  ? {
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }
                  : null
              }
              type="button"
            >
              <i
                style={{ color: "#ee4236", padding: "0 8px" }}
                className="fas fa-search"
              ></i>
            </button>
          </div>
        </div>
        <button className="create_share_btn" onClick={() => {history.push("project_share/all")}}>{t("Create Project")}</button>
      </div>
      {allSharedProjectsLoading ? (
        <Loading height={"85vh"} />
      ) : allSharedProjects.length > 0 ? (
        <div className="body_of_project_shares">
          {allSharedProjects?.map((item, i) => (
            <ProjectShareCom
              key={i}
              className={"project-share-item "}
              shareID={item.projectShareID}
              projectTypeImg={item.projTypeImagePath}
              projectCountryImg={item.countryImagePath}
              title={item.projectName}
              text={item.longDesc}
              raised={item.amountCollected}
              goal={item.amount}
              shareYear={item.projectShareYear}
              projectShare={item}
            />
          ))}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "25vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 className="project_share_no_result_found_text">
            {" "}
            {t("No Results Found")}
          </h5>
        </div>
      )}
    </div>
  );
};

export default SwitchProjectShares;
