//React
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";

//CSS
import "./GraceRequest.css";

//api
import * as api from "../../../api/index";

//Packages
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
//Bootstrap

//Translation
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Tab, Tabs } from "react-bootstrap";
import { getAllGraceList } from "../../../actions/grace";
import icon1 from "../../../assets/ntionality.png";
import profileIcon from "../../../assets/profile 2.png";
import mobileIcon from "../../../assets/mobile number.png";
import eduIcon from "../../../assets/edducation.png";
import famIcon from "../../../assets/family name.png";
import gender from "../../../assets/gender.png";
import dob from "../../../assets/date of birth.png";
import contactLanguage from "../../../assets/occupation.png";
import icon8 from "../../../assets/occupation.png";
import { createVlnAccount, getAllBranches } from "../../../actions/volunteer";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
//Tostify
import { toastifyMessage } from "../../../handlers/toastifyMessage";
import {
  applyValidation,
  resetValidation,
} from "../../../handlers/formValidation";
import Loading from "../../ui/loading";

const GraceRequest = (props) => {
  const locationn = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  Geocode.setLocationType("ROOFTOP");

  const defaultProps = {
    center: {
      lat: 24.422139,
      lng: 54.445503,
    },
    zoom: 14,
  };
  const mapStyles = {
    width: "97%",
    height: "250px",
    maxWidth: "1121px",
  };
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [gracePay, setGracePay] = useState(false);

  const allgrace = useSelector((state) => state.allgrace);
  const [gace_conversations_list, setGalleryList] = useState(allgrace);

  const vlnBranches = useSelector((state) => state.vlnBranches);
  const [vlnAllBranches, setVlnAllBranches] = useState(vlnBranches);
  const [name, setName] = useState(
    userLogin ? userLogin.Results[0].name_a : ""
  );
  const [mobile, setMobile] = useState(
    userLogin ? userLogin.Results[0].mobile_no : ""
  );
  const [gracType, setGraceType] = useState(locationn?.state?.graceId);
  const [branch, setBranch] = useState(1);
  const [pickupDate, setPickupDate] = useState('');
  const [remarks, setRemarks] = useState('');
  const [language, setLanguage] = useState('');
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState(24.422139);
  const [long, setLong] = useState(54.445503);
  Geocode.enableDebug();

  const [userMainInfoVln, setUserMainInfoVln] = useState({
    name: userLogin ? userLogin.Results[0].name_a : "",
    mobile: userLogin ? userLogin.Results[0].mobile_no : "",
    email: userLogin ? userLogin.Results[0].email : "",
    workplaceID: "1",
    remarks: "",
    pickupdate: "",
    contactLanguage: "",
    type_id: "",
    longitude: "",
    latitude: ""
  });
  useEffect(() => {
    api.checkGraceOption().then((res) => {
      setGracePay(res.data.status)
    })
    dispatch(getAllGraceList(lng == "eng" ? 2 : 1));
    dispatch(getAllBranches(lng == "eng" ? 2 : 1));
    navigator.geolocation.getCurrentPosition(function (position) {
      setLong(position.coords.longitude);
      setLat(position.coords.latitude);
    });
  }, []);
  useEffect(() => {
    setGalleryList(allgrace);
  }, [allgrace]);
  useEffect(() => {
    setVlnAllBranches(vlnBranches);
  }, [vlnBranches]);
  useEffect(() => {
    setGraceType(locationn?.state?.graceId);
  }, [locationn?.state?.graceId]);

  useEffect(() => {
    setUserMainInfoVln(
      {
        name: name,
        mobile: mobile,
        // email: email,
        workplaceID: branch,
        type_id: gracType,
        contactLanguage: language,
        pickupdate: pickupDate,
        remarks: remarks,
        longitude: long,
        latitude: lat
      }
    )
  }, [name, mobile, branch, gracType, language, pickupDate, remarks, long, lat])
  const history = useHistory();

  const onMarkerClick = (marker) => { };

  const onMapClick = (mapProps, map, event) => {
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        if (address) {
          document.getElementById("textAreaForMap").innerHTML= address
          document.getElementById("textAreaForMap").disabled=false
        }
      },
      (error) => {
        //console.error(error);
        document.getElementById("textAreaForMap").disabled=false
      }
    );
    setLat(event.latLng.lat());
    setLong(event.latLng.lng());
  };

  const submitRequest = (e) => {
    e.preventDefault();
    if (!validateMobile(mobile)) {
      toastifyMessage(
        "Mobile number should be 10 digit starts with 05 ",
        undefined,
        "error"
      );
      return false;
    }
    
    setLoading(true);
    if (
      name == null ||
      mobile == null ||
      branch == null ||
      gracType == null ||
      pickupDate == null ||
      language == null ||
      name == "" ||
      mobile == "" ||
      branch == "" ||
      gracType == "" ||
      pickupDate == "" ||
      language == ""
    ) {
      toastifyMessage("All fields are mandatory!", undefined, "warning");
      setLoading(false);
      return false;
    }
    if (userMainInfoVln.pickupdate.split('-')[0].length>4 || parseInt(userMainInfoVln.pickupdate.split('-')[0]) <  1850) {
      return toastifyMessage("Please choose a valid date", undefined, "warning");
    }
    const query = `?branchID=${branch}&requestType=${gracType}&locY=${lat}&locX=${long}&remarks=${remarks}&contactName=${name}&contactPhone=${mobile}&pickupDate=${pickupDate}&language=${language}`;
    if(gracePay)
    {
      api.graceCost().then((res => {

        //khaleel for grace payment
        const dataToRoute = {
          from: "grace",
          elements: [
            { title: "grace request", price: res.data.data.amount },
          ],
          totalAmount: res.data.data.amount,
          details: {
            title: "grace request",
            amount: res.data.data.amount,
            donationId: 97,
            name: userMainInfoVln.name,
            mobile: userMainInfoVln.mobile,
            email: userMainInfoVln.email,
            workplaceID: userMainInfoVln.workplaceID,
            remarks: userMainInfoVln.remarks,
            pickupDate: userMainInfoVln.pickupdate,
            contactLanguage: userMainInfoVln.contactLanguage,
            type_id: userMainInfoVln.type_id,
            longitude: userMainInfoVln.longitude,
            latitude: userMainInfoVln.latitude
          }
        };
        history.push(`/checkout`, dataToRoute);
  
      }))
    }
    else
    {
          api.graceRequest(query).then((data) => {
            if(data.status)
            {
              toastifyMessage("Successfully added a request", undefined, "success");
              setTimeout(() => {history.push('/')}, 3000)
              
            }
            else
            {
              toastifyMessage("Something went wrong...!", undefined, "warning");
              setLoading(false);
            }
          }).catch(() => {
            toastifyMessage("Something went wrong...!", undefined, "warning");
            setLoading(false);
          }).finally(() => {
        });
    }
    // api.graceRequest(query).then((data) => {
    //   setLoading(false);
    //   if (data.data.status === true) {
    //     api.graceCost().then((res => {

    //       //khaleel for grace payment
    //       const dataToRoute = {
    //         from: "grace",
    //         elements: [
    //           { title: "grace request", price: res.data.data.amount },
    //         ],
    //         totalAmount: res.data.data.amount,
    //         details: {
    //           title: "grace request",
    //           amount: res.data.data.amount,
    //           donationId: 97,
    //           name: userMainInfoVln.name,
    //           mobile: userMainInfoVln.mobile,
    //           email: userMainInfoVln.email,
    //           workplaceID: userMainInfoVln.workplaceID,
    //           remarks: userMainInfoVln.remarks,
    //           pickupDate: userMainInfoVln.pickupdate,
    //           contactLanguage: userMainInfoVln.contactLanguage,
    //           type_id: userMainInfoVln.type_id,
    //           longitude: userMainInfoVln.longitude,
    //           latitude: userMainInfoVln.latitude
    //         }
    //       };
    //       history.push(`/checkout`, dataToRoute);

    //     }))
    //     // toastifyMessage("Success!", undefined, "success");
    //     // setName("");
    //     // setMobile("");
    //     // setEmail("");
    //     // setTitle("");
    //     // setSubject("");
    //     // setContent("");
    //     // setType("");
    //   } else {
    //     toastifyMessage(
    //       "Something went wrong...!",
    //       data.data.result,
    //       undefined,
    //       "error"
    //     );
    //   }
    // });
  };
  const validateMobile = (mobile) => {
    const re = /^05[0-9]{8}$/;
    return re.test(mobile);
  };

  return (
    <div
      className="graceInfoPage"
      style={lng === "arab" ? { direction: "rtl" } : null}
    >
      <ShareHeader noShare={true} pageName={t("Grace Request")} />
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("Grace Request")}</h3>
      </div>
      <div style={{ width: "100%" }}>
        {/* <form className="grace" onSubmit={handleSubmit}> */}
        <form
          onSubmit={submitRequest}
          className="page-contents"
          style={{ width: "100%" }}
        >
          <div className="input_wraper form-r">
            <div className="local_aid_input_icon_wraper">
              <img
                src={profileIcon}
                className="grace-form-icon"
                alt="form_icon"
              />
            </div>

            <input
              type="text"
              className={lng==='arab'? 'force_arabic_font local_aid_input translateFillMsg':'payment_numbers local_aid_input translateFillMsg'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              placeholder={t("Enter your name here")}
              onInvalid={applyValidation}
              onInput={resetValidation}
              required
            />
          </div>
          <div className="input_wraper form-r">
            <div className="local_aid_input_icon_wraper">
              <img
                src={mobileIcon}
                className="grace-form-icon-mobile"
                alt="form_icon"
              />
            </div>
            <input
              type="text"
              className={lng==='arab'? mobile? 'payment_numbers local_aid_input translateFillMsg':'force_arabic_font local_aid_input translateFillMsg':'payment_numbers local_aid_input translateFillMsg'}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              name="mobile"
              maxLength="10"
              placeholder={t("Enter your mobile number here")}
              onInvalid={applyValidation}
              onInput={resetValidation}
              required
            />
          </div>
          <div className="input_wraper form-r">
            <img
              src={famIcon}
              className="grace-form-icon-branch"
              alt="form_icon"
            />
            <select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              name="workplaceID"
              className="selectedCourse"
            >
              <option value="" disabled defaultValue>
                {t("Choose Branch")}
              </option>
              {vlnAllBranches?.map((item) => (
                <option key={item.workplace_id} value={item.workplace_id}>
                  {item.workplace_name}
                </option>
              ))}
            </select>
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-40px" }}
          >
            {t("Pick up Date")}
          </label>
          <div className="input_wraper form-r">
            <div className="local_aid_input_icon_wraper">
              <img src={dob} className="grace-form-icon-date" alt="form_icon" />
            </div>

            <input
              type="date"
              onChange={(e) => setPickupDate(e.target.value)}
              className="local_aid_input translateFillMsg"
              value={pickupDate}
              name="pickupdate"
              placeholder={t("Pick Up Date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
             min={`${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().length===1? '0'+(new Date().getMonth() + 1):new Date().getMonth() + 1}-${new Date().getDate().toString().length===1? '0'+new Date().getDate():new Date().getDate()}`}
            />

            {/* <input placeholder="Date" className="textbox-n" type="text" onfocus="(this.type='date')" id="date"></input> */}
          </div>
          <div className="input_wraper form-r">
            <div className="local_aid_input_icon_wraper">
              <img
                src={famIcon}
                className="grace-form-icon-branch"
                alt="form_icon"
              />
            </div>
            <input
              type="text"
              className={lng==='arab'? 'force_arabic_font local_aid_input translateFillMsg':'payment_numbers local_aid_input translateFillMsg'}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              name="remarks"
              placeholder={t("Remarks")}
            />
          </div>
          <div className="input_wraper form-r">
            <img src={icon1} className="grace-form-icon-lang" alt="form_icon" />
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              name="contactLanguage"
              className="selectedCourse"
            >
              <option value="" disabled>
                {t("Select contact language")}
              </option>
              <option value="1">{t("Arabic")}</option>
              <option value="2">{t("English")}</option>
            </select>
          </div>
          <div className="input_wraper form-r">
            <textarea
            disabled
              name="location"
              placeholder={t("Address")}
              className="location-grace"
              id="textAreaForMap"
              cols="5"
              rows="3"
            ></textarea>
          </div>
          <Map
            google={props.google}
            style={mapStyles}
            containerStyle={containerStyle}
            zoom={14}
            initialCenter={{ lat: lat, lng: long }}
            center={{ lat: lat, lng: long }}
            onClick={onMapClick}
          >
            <Marker
              id="test"
              key="1"
              title="test title"
              name={"Branch"}
              onClick={onMarkerClick}
              position={{
                lat: lat,
                lng: long,
              }}
            />
          </Map>
          <button
            id="submitBtn_for_grace_request"
            disabled={loading}
            className="grace_request_submit"
            style={{ marginTop: "270px" }}
          >
            {/* <span style={{ flex: "16" }}> */}
            {loading ? t("Loading...") : gracePay?t("Pay for delivery"): t("Submit")}
            {/* </span> */}
            {/* {lng === "arab" ? (
                <i className="fas fa-arrow-left"></i>
              ) : (
                <i className="fas fa-arrow-right"></i>
              )} */}
          </button>{" "}
        </form>
        {/* </form> */}
      </div>
    </div>
  );
};
const LoadingContainer = (props) => <Loading height='800px'/>;

export default GoogleApiWrapper({
  apiKey: "AIzaSyBQonMikYiu-cJ6VrDuPLIxuCPWP3gF7tY",
  LoadingContainer: LoadingContainer

})(GraceRequest);
// export default GraceRequest;
