import React, { useEffect, useState } from 'react'
import './DonationPage.css'
import DonationBanner from './section/donationBanner/DonationBanner'
import DonationsDisplay from './section/donationDisplay/DonationsDisplay'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import SL1 from '../../../assets/donationsPage_banner.jpg'
import filterIcon from '../../../assets/filter.png'
import FilterModal from '../../LoaclComponents/filterModal/FilterModal'
import Loading from '../../ui/loading';
import { useHistory } from 'react-router'
import { toastifyMessage } from "../../../handlers/toastifyMessage";

const DonationPage = (props) => {
    const history = useHistory();
    const donations = useSelector(state => state.donations.donations)
    const loading = useSelector(state => state.donations.loading);
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const { t } = useTranslation();
    const [allDonations, setAllDonations] = useState(donations)
    const [filterModalShow, setFilterModalShow] = useState(false)
    let projectsAmountArr = donations.map(item => parseInt(item.amount))
    const [maximumAmount, setMaximumAmount] = useState(Math.max(...projectsAmountArr))
    const [minimumAmount, setMinimumAmount] = useState(Math.min(...projectsAmountArr))
    const [filterBody, setFilterBody] = useState({
        order: '',
        min: 0,
        max: 0
    })
    const handleFilter = () => {
        if (parseInt(filterBody.min) > parseInt(filterBody.max) || parseInt(filterBody.min) < 0 || parseInt(filterBody.max) <0) {
            //setAllDonations([{ status: 'there is no' }]);
            return toastifyMessage("Min value is less than or equal Max!", undefined, "warning");
        }
        if (filterBody.max >= 10 && filterBody.order === '' ) {
            let newDonations = donations.filter(item => item.amount).filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min));
            if (newDonations.length === 0) {
                setAllDonations([{ status: 'there is no' }]);
            } else {
              setAllDonations(newDonations)
            }
        } else if (filterBody.max >= 10 && filterBody.order !== '') {
            let sortedDonations = [];
            if (filterBody.order === 'alpha') {
                let names = donations.map(item => item.dontypeName)
                names.sort()
                for (let i = 0; i < names.length; i++) {
                    let itemWeNeed = donations.filter(item => item.dontypeName === names[i]).slice(0, 1).shift()
                    sortedDonations.push(itemWeNeed)
                }
                let newDonations = sortedDonations.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min));
                if (newDonations.length === 0) {
                    setAllDonations([{ status: 'there is no' }]);
                } else {
                  setAllDonations(newDonations)
                }
                //setAllDonations(newDonations)
            } else if (filterBody.order === 'lowToHigh') {
                let sortedAmounts = donations.filter(item => item.amount).sort((a, b) => parseInt(a.amount - parseInt(b.amount)));
                let newDonations = sortedAmounts.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min))
                if (newDonations.length === 0) {
                    setAllDonations([{ status: 'there is no' }]);
                } else {
                  setAllDonations(newDonations)
                }
                //setAllDonations(newDonations)
            } else {
                let sortedAmounts = donations.filter(item => item.amount).sort((a, b) => { return parseInt(b.amount - parseInt(a.amount)) });
                let newDonations = sortedAmounts.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min))
                if (newDonations.length === 0) {
                    setAllDonations([{ status: 'there is no' }]);
                } else {
                  setAllDonations(newDonations)
                }
                //setAllDonations(newDonations)
            }
        } else if (filterBody.max < 10 && filterBody.order !== '') {
            let sortedDonations = [];
            if (filterBody.order === 'alpha') {
                let names = donations.map(item => item.dontypeName)
                names.sort()
                for (let i = 0; i < names.length; i++) {
                    let itemWeNeed = donations.filter(item => item.dontypeName === names[i]).slice(0, 1).shift()
                    sortedDonations.push(itemWeNeed)
                }
                setAllDonations(sortedDonations)
            } else if (filterBody.order === 'lowToHigh') {
                let sortedAmounts = donations.filter(item => item.amount).sort((a, b) => { return parseInt(a.amount - parseInt(b.amount)) });
                setAllDonations(sortedAmounts)
            } else {
                let sortedAmounts = donations.filter(item => item.amount).sort((a, b) => { return parseInt(b.amount - parseInt(a.amount)) });
                setAllDonations(sortedAmounts)
            }
        } else {
            setAllDonations(donations)
        }
        setFilterModalShow(false);
    }
    useEffect(() => {
        setAllDonations(donations)
        let projectsAmountArr = donations.filter(item => item.amount).map(item => parseInt(item.amount))
        setMaximumAmount(Math.max(...projectsAmountArr))
        setMinimumAmount(Math.min(...projectsAmountArr))
    }, [donations])
    const searchData2 = (pattern) => {
        if (!pattern) {
            setAllDonations(donations);
            return;
        }
        const fuse2 = new Fuse(donations, {
            keys: ["dontypeName"],
        });
        const result = fuse2.search(pattern);
        const matches = [];
        if (!result.length) {
            setAllDonations([{ status: 'there is no' }]);
        } else {
            result.forEach(({ item }) => {
                matches.push(item);
            });
            setAllDonations(matches);
        }
    }
    function loadQueryParams() {
        if (props.location.search) {
            let qParms = props.location.search.replaceAll("?", "");
            let qObj = JSON.parse(
                '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                function (key, value) {
                    return key === "" ? value : decodeURIComponent(value);
                }
            );
            return qObj;
        }
    }
    useEffect(() => {
        if (loadQueryParams() && loadQueryParams().donationId) {
            history.push(`/donations/${loadQueryParams().donationId}`)
        }
    }, [])
        return (
            <div id='donation_page'>

                <div id='donation_banner' style={{ backgroundImage: `url(${SL1})` }}>
                    <h1 style={{ fontWeight: '900', textShadow: 'grey 0px -1px 20px' }}>{t("Donations")}</h1>
                    {loading? null:
                    <div className='search_input' style={lng === 'arab' ? { flexDirection: 'row', direction: 'rtl' } : null}>
                        <input className={lng==='arab'? 'force_arabic_font':'payment_numbers'} onChange={(e) => searchData2(e.target.value)} type='text' placeholder={t('Search for a donation')} style={lng === 'arab' ? { borderTopRightRadius: '20px', borderBottomRightRadius: '20px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' } : null} />
                        <button style={lng === 'arab' ? { borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' } : null} type='button'><i style={{ color: '#ee4236' }} className="fas fa-search"></i></button>
                    </div>
                    }
                </div>
                {!loading?
                <React.Fragment>
                {allDonations?.slice(0)?.shift()?.status !== 'there is no' ? <DonationsDisplay allDonationsFromHere={allDonations} max={maximumAmount} min={minimumAmount} /> : <div style={{ minHeight: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}><p>{t("No donations found")}</p></div>}
                <div className='filter_wraper' onClick={() => setFilterModalShow(true)}>
                    <button onClick={() => loading? null: setFilterModalShow(true)} className='filter_btn'>
                        <img src={filterIcon} className='filter_icon' alt='filterIcon' />
                    </button>
                </div>
                </React.Fragment>:
                <Loading height='600px' />
            }
                
                <FilterModal
                    show={filterModalShow}
                    filterbody={filterBody}
                    setfilter={setFilterBody}
                    max={maximumAmount}
                    min={minimumAmount}
                    handlefilter={handleFilter}
                    dialogClassName="modal-60w"
                    onHide={() => setFilterModalShow(false)}
                />
            </div>
        )
}
export default DonationPage