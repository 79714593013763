/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './donation.css'
import DonationCom from './donationCom/donationCom'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag } from '../../../../actions/BagFav'
import { FETCH_LOCALLY } from '../../../../constants/actionTypes'
import Loading from '../../../ui/loading'
import { addToFavorites, deleteFromFavorites } from '../../../../actions/BagFav'

SwiperCore.use([Navigation, Autoplay])
const uselessFunction = () => {}
const Donation = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const [view, setView] = useState(5)
    let currentProfile = JSON.parse(localStorage.getItem('profile'));
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const userBag = useSelector(state => state.bag.bag)
    const userFav = useSelector(state => state.favorites.favorites)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])
    //const userBag = useSelector(state => state.bag.bag)
    const dispatch = useDispatch()
    const [change, setChange] = useState(0)
    const [bagDetails, setBagDetails] = useState(userBag)
    // const [favoritesDetails, setFavoritesDetails] = useState(userBag)
    const donations = useSelector(state => state.donations.donations).filter(item => item.amount);
    const loading = useSelector((state) => state.donations.loading);
    const fetchLoaclly = useSelector(state => state.fetchLocallyState)
    useEffect(() => {
        currentProfile = JSON.parse(localStorage.getItem('profile'));
    }, [change, fetchLoaclly])

    useEffect(() => {
        setBagDetails(userBag)
    }, [userBag])
    const handleLike = async (donationID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            dispatch({ type: 'addLikeLocally', payload: donationID })
            currentProfile.liked.donations.push(donationID)
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({ type: FETCH_LOCALLY })
            setChange(change + 1)
        } else {
            await dispatch(addToFavorites(lng === 'arab' ? 1 : 2, { userEmail: userLogin.Results[0]?.email, dontypeID: donationID.donationId, projectID: '', orptypeID: '', projectYear: '', countryID: '' }))
        }
    }
    const handleDisLike = async (donationID) => {
        if (!userLogin) {
            let newProfile = { cart: currentProfile.cart, liked: { donations: currentProfile.liked.donations.filter(item => item.donationId !== donationID.donationId), sukuks: currentProfile.liked.sukuks, projects: currentProfile.liked.projects, orphans: currentProfile.liked.orphans } }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({ type: FETCH_LOCALLY })
            setChange(change + 1)
        } else {
            let elementWeNeed = favoritesDetails?.donations?.filter(item => item.dontypeID === donationID.donationId).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromFavorites(lng === 'arab' ? 1 : 2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
            }
        }

    }

    const handleaddToBag = async (donationID) => {
        if (!userLogin) {
            if (!currentProfile) {
                localStorage.setItem('profile', JSON.stringify({ cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: { donations: [], sukuks: [], projects: [], orphans: [] } }))
                currentProfile = JSON.parse(localStorage.getItem('profile'));
            }
            currentProfile.cart?.donations.push({ ...donationID, baseAmount: donationID.amount })
            const newProfile = { cart: currentProfile.cart, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({ type: FETCH_LOCALLY })
            setChange(change + 1)
        } else {
            await dispatch(addToBag({
                cart: {
                    donID: donationID.donationId, userName: userLogin.Results[0]?.name_a, userMob: userLogin.Results[0]?.mobile_no,
                    userEmail: userLogin.Results[0]?.email, itemType: 0, amount: donationID.amount, recuring: donationID.recuring, cart_serial: null
                }, language: lng === 'arab' ? 1 : 2
            }))
        }
    }

    const handleRemoveFromBag = async (donationID) => {
        if (!userLogin) {
            let newProfile = { cart: { donations: currentProfile.cart.donations.filter(item => !(item.donationId === donationID.donationId && parseInt(item.baseAmount) === parseInt(donationID.amount) && item.donationFreq === donationID.donationFreq)), sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
            localStorage.setItem('profile', JSON.stringify(newProfile))
            await dispatch({ type: FETCH_LOCALLY })
            setChange(change + 1)
        } else {
            let elementWeNeed = bagDetails?.donations?.filter(item => item.dontypeID === donationID.donationId && parseInt(item.baseAmount) === parseInt(donationID.amount) && item.recurring === donationID.donationFreq).slice(0, 1).shift()
            if (elementWeNeed) {
                await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1, ""))
            }
        }
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(15)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(11)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(8)
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(6)
        } else if (window.innerWidth > 1300 && window.innerWidth <= 1600) {
            setView(5.8)
        }
        else if (window.innerWidth > 1000 && window.innerWidth <= 1300) {
            setView(5.4)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(5)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(5)
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(4)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(2.8)
        }
        else if (window.innerWidth > 350 && window.innerWidth <= 500) {
            setView(2.2)
        } else if (window.innerWidth > 250 && window.innerWidth <= 350) {
            setView(1.4)
        } else {
            setView(1)
        }
    }, [width])

    const history = useHistory();
    const handleClick = () => {
        history.push(`/donations`)
    }
    return (
        <section id='donations_sec'>
            <div className='section_title_div' style={lng === 'arab' ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' }}>
                <label className='all_section_title' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontSize: '25px', width: '100%', textAlign: 'left' }}>{t("Donations")}</label>
                {lng === 'arab' ?
                    <button onClick={handleClick} className='section_button'>{t("View All")}</button>
                    : <button onClick={handleClick} className='section_button'>{t("View All")}</button>
                }
            </div>
            {
                !loading ?
                    <div id='donation_display_mobile' style={lng === 'arab' ? { width: '100%', direction: 'rtl' } : { width: '100%' }}>
                        <React.Fragment>
                            <Swiper loop={donations?.length > view? true:false} className='please' slidesPerView={view} navigation={true}>
                                {donations?.slice(0, 16)?.map((item, index) =>

                                    <SwiperSlide className='donationChild' key={item.dontypeID}>
                                        <DonationCom setChange={setChange} change={change} handleaddToBag={handleaddToBag} handleRemoveFromBag={handleRemoveFromBag} edit_flag={item.edit_flag} handleLike={handleLike} handleDisLike={handleDisLike} currentProfile={currentProfile} altImg={item.dontypeName} amount={item.amount} donationID={item.dontypeID} key={item.dontypeID} DonImg={item.imagePath} title={item.dontypeName} />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </React.Fragment>
                    </div> : <Loading />}
        </section>
    )
}
export default Donation