import { FETCH_ALL_GALLERY } from "../../constants/actionTypes";

export default (allGallery = [], action) => {
  switch (action.type) {
    case FETCH_ALL_GALLERY:
      return action.payload;
    default:
      return allGallery;
  }
};
