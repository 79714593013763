import React, { useState, useEffect } from "react";
import "./MyBag.css";
import foodBasket from "../../assets/Cow.png";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Mousewheel } from "swiper";
import FavoritesCom from "../homePage/sections/favorites/favoritesCom/FavoritesCom";
import {RemoveAllFromBag} from '../../api'
import LatePaymentDisplay from "./latePaymentDisplay/LatePaymentDisplay";
import MyBagDisplay from "./myBagDisplay/MyBagDisplay";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import { useSelector, useDispatch } from 'react-redux'
import { toastifyMessage } from "../../handlers/toastifyMessage";
import {FETCH_ALL_BAG_ITEMS, LOAD_BAG, BAG_LOADED, REMOVE_ALL_BAG_ITEMS} from '../../constants/actionTypes'
import DeleteAllConfirmation from "./deleteAllConfirmation/DeleteAllConfirmation";
import { FETCH_LOCALLY } from '../../constants/actionTypes';

const MyBag = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch()
  const userBag = useSelector(state => state.bag.bag)
  const loading = useSelector(state => state.bag.loading)
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));

  let currentProfile = JSON.parse(localStorage.getItem('profile'));
  const [deleteAllModal, setDeleteAllModal] = useState(false)
  const fetchLoaclly = useSelector(state => state.fetchLocallyState)
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem('profile'));
  }, [fetchLoaclly])
  
  const [bagChange, setBagChange] = useState(0);
  const [deletingall, setDeletingAll] = useState(false);

  let totalOfDonationsArr = currentProfile?.cart?.donations?.map(item => parseInt(item.amount))
  let totalOfDonations = 0
  let totalOfProjectsArr = 0
  let totalOfProjects = 0
  let totalOfSukuArr = 0
  let totalOfSuku = 0
  let totalOfhumanArr = 0
  let totalOfhuman = 0
  let totalOforphansArr = 0
  let totalOforphans = 0
  const [totalInBag, setTotalInBag] = useState(0)
  useEffect(() => {
      if (currentProfile) {
          const reducer = (previousValue, currentValue) => previousValue + currentValue;
          totalOfDonations = totalOfDonationsArr?.reduce(reducer, 0)

          totalOfProjectsArr = currentProfile?.cart?.projects?.map(item => parseInt(item.projectAmount))
          totalOfProjects = totalOfProjectsArr?.reduce(reducer, 0)

          totalOfSukuArr = currentProfile?.cart?.sukuks?.map(item => parseInt(item.amount))
          totalOfSuku = totalOfSukuArr?.reduce(reducer, 0)

          totalOfhumanArr = currentProfile?.cart?.humanCases?.map(item => parseInt(item.donationAmountFromUser))
          totalOfhuman = totalOfhumanArr?.reduce(reducer, 0)

          totalOforphansArr = currentProfile?.cart?.orphans?.map(item => parseInt(item.donationAmountFromUser))
          totalOforphans = totalOforphansArr?.reduce(reducer, 0)

          setTotalInBag(totalOfDonations + totalOfProjects + totalOfSuku + totalOfhuman + totalOforphans)
      }
  }, [fetchLoaclly])
  const openDeleteAllModal = () => {
    if (userLogin) {
      if (userBag.total<=0 || !userBag.total) {
        return toastifyMessage("Your bag is empty!", undefined, "warning");
      }
    } else if (totalInBag<=0) {
      return toastifyMessage("Your bag is empty!", undefined, "warning");
    }
    setDeleteAllModal(true)
  }
  const removeAllCart = async () => {
    setDeletingAll(true)
   // if (userBag.total)
    if (userLogin) {
      if (userBag.total<=0 || !userBag.total) {
        return toastifyMessage("Your bag is empty!", undefined, "warning");
      }
      dispatch({ type: LOAD_BAG });
      RemoveAllFromBag(userLogin?.Results[0]?.email, lng === 'arab' ? 1 : 2)
      
      .then(async (res) => {
         await dispatch({ type: REMOVE_ALL_BAG_ITEMS, payload: res.data });
           toastifyMessage("Removed!", undefined, "success");
           setBagChange(bagChange+1)
           setDeleteAllModal(false)
          //window.location.reload();
      })
      .catch((e) => {
        let number = Object.keys(e).length;
        if (number > 0) {
          dispatch({ type: BAG_LOADED });
          toastifyMessage("Something went wrong...!", undefined, "error");
          setDeleteAllModal(false)
        }
      })
    } else {
      if (totalInBag<=0) {
        return toastifyMessage("Your bag is empty!", undefined, "warning");
      }
      dispatch({ type: LOAD_BAG });
        let oldProfile = JSON.parse(localStorage.getItem("profile"));
        localStorage.setItem('profile', JSON.stringify({...oldProfile, cart: { donations: [], sukuks: [], projects: [], humanCases: [], orphans: [] }, liked: oldProfile.liked}))
        await toastifyMessage("Removed!", undefined, "success");
        setDeleteAllModal(false)
        await dispatch({type: FETCH_LOCALLY})
        dispatch({ type: BAG_LOADED });
        setDeletingAll(false)
        //window.location.reload();
      }
  }
  const [view, setView] = useState(5);
  const [realViewLate, setRealViewLate] = useState(6);
  const history = useHistory()
  const [projectSwitch, setProjectSwitch] = useState(3);
  const handleClick = () => {
    if (projectSwitch === 0) {
      setProjectSwitch(1);
      document.getElementById("btn-late").style.color = "#ffffff";
      // document.getElementById("btn-late").style.transform = 'scale(1.1)'
      document.getElementById("btn-late").style.backgroundColor = '#ee4236';
      document.getElementById("btn-late").style.boxShadow = '0px 0px 15px 1px #0f0f0f';
      document.getElementById("btn-bag").style.color = "#000";
      document.getElementById("btn-bag").style.transform = 'none'
      document.getElementById("btn-bag").style.backgroundColor = '#ffffff';
      document.getElementById("btn-bag").style.boxShadow = 'none';
    } else {
      setProjectSwitch(0);
      // document.getElementById("btn-bag").style.transform = 'scale(1.1)'
      document.getElementById("btn-bag").style.backgroundColor = '#ee4236';
      document.getElementById("btn-bag").style.boxShadow = '0px 0px 15px 1px #0f0f0f';
      document.getElementById("btn-bag").style.color = "#ffffff";
      document.getElementById("btn-late").style.color = "#000";
      document.getElementById("btn-late").style.transform = 'none'
      document.getElementById("btn-late").style.backgroundColor = '#ffffff';
      document.getElementById("btn-late").style.boxShadow = 'none';
    }
  };
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView(5);
      setRealViewLate(5);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(4.5);
      setRealViewLate(4.5);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(4);
      setRealViewLate(4);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(2.5);
      setRealViewLate(2.5);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(2.5);
      setRealViewLate(2.5);
    } else if (window.innerWidth > 700 && window.innerWidth <= 1111) {
      setView(2.6);
      setRealViewLate(2.5);
    } else {
      setView(2.4);
      setRealViewLate(2.2);
    }
  }, []);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  useEffect(() => {
    if (window.innerWidth > 900) {
      setProjectSwitch(3);
    } else {
      setProjectSwitch(0);
    }
  }, [width]);
  SwiperCore.use([Pagination, Mousewheel]);
  return (
    <div id="my_bag" style={lng === "arab" ? { direction: "rtl", position: 'relative' } : { position: 'relative' }}>
      <ShareHeader noShare={true} pageName={t("My Bag")} noBack={true} fromBag={true} removeAllFromCart={true} removeAllCart={openDeleteAllModal} loading={loading}/>
      <div className="switch_project_page">
        <button
          id="btn-bag"
          onClick={handleClick}
          className="switch_btn btn-first"
        >
          {t("Donations")}
        </button>
        <button id="btn-late" onClick={handleClick} className="switch_btn">
          {t("Late Payment")}
        </button>
      </div>
      {projectSwitch === 1 ? (
        <LatePaymentDisplay />
      ) : projectSwitch === 0 ? (
        <MyBagDisplay removeAllCart={openDeleteAllModal} bagChange={bagChange}/>
      ) : (
        <div className="my_total_bag">
          <MyBagDisplay  removeAllCart={openDeleteAllModal} bagChange={bagChange}/>
          <LatePaymentDisplay />
        </div>
      )}
      <DeleteAllConfirmation
        show={deleteAllModal}
        deletingall ={deletingall}
        dialogClassName="modal-60w"
        onHide= {()=> setDeleteAllModal(false)}
        removeall={removeAllCart}
        backdrop={deletingall? "static":true}
      />
    </div>
  );
};

export default MyBag;
