/** @flow */
import React from 'react';
import apple from '../../../assets/apple.svg'
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { signInWithApple } from '../../../actions/user';
import { toastifyMessage } from '../../../handlers/toastifyMessage';

/** Apple Signin button */
function SignInWithApple(props) {
    var location = new URL(window.location.href);
    let redirectLocation = "https://emiratesrc.ae/auth"
    if(location.href.includes("www"))
    {
        redirectLocation = "https://www.emiratesrc.ae/auth"
    }
    const dispatch = useDispatch();
    const onSuccess = (res) => {
        dispatch(signInWithApple(res, props.history, props.setLoadingModalShow, props.fromState, props.completeInfo, props.lodaingSwitch, props.setWait))
    };
    const onFailure = (res) => {
        toastifyMessage(`Login Failed: Pop-up closed by user!`, undefined, "error");
    };

    return (
        <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
                /** Client ID - eg: 'com.example.com' */
                clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: redirectLocation,
                /** State string that is returned with the apple response */
                state: 'state',
                /** Nonce */
                nonce: 'nonce',
                /** Uses popup auth instead of redirection */
                usePopup: true
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={true}
            /** Allows to change the button's children, eg: for changing the button text */
            // buttonExtraChildren="Continue with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={onSuccess} // default = undefined
            /** Called upon signin error */
            onError={() => { onFailure(); props.setLoadingModalShow(false) }}
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** Apple image props */
            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
            render={renderProps => (
                <button style={{ border: 'none', backgroundColor: 'transparent' }}
                    onClick={() => { renderProps.onClick(); props.setLoadingModalShow(true) }}
                    disabled={renderProps.disabled}>
                    <img src={apple} alt='apple_logo' className='login_sign_social_logos' style={{ background: '#30302f' }} />
                </button>
            )}
        />
    );
}

export default SignInWithApple;
