import React, { useEffect, useState } from "react";
import "./SendGift.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { renderIntoDocument } from "react-dom/test-utils";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import e from "cors";

const SendGift = (props) => {
  const numberOfOrphans = props?.location?.state?.str === 'many' && props?.location?.state?.str?
  props?.location?.state?.itemSelectedMode.length: 0;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const userInfo = useSelector((state) => {
    return state.auth.authData;
  });
  function getUserInfo() {
    if (
      userInfo?.Results &&
      userInfo?.Results.length > 0 &&
      userInfo?.Results[0]
    ) {
      return userInfo?.Results[0];
    }
  }
  const history = useHistory();
  const logOut = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    switch (e.target.name) {
      case "amount":
        setAmount(e.target.value.replace(/\D/, ""));
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };
  function loadQueryParams() {
    if (props.location.search) {
      let qParms = props.location.search.replaceAll("?", "");
      let qObj = JSON.parse(
        '{"' + qParms.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      return qObj;
    }
  }
  const sponID = useSelector((state) => {
    return state.auth.extraSponID;
  });
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let arrOFIds = props?.location?.state?.itemSelectedMode.slice(0)
    let objectToSend = {}
    if (props?.location?.state?.str === 'many' && props?.location?.state?.str) {
      objectToSend = {
        from: "gifts",
        elements: props?.location?.state?.itemSelectedMode.map((item) => { return { title: lng==='arab'? "أرسل هدية/دعم":"Send Gifts/ Support", price: amount}}),
        totalAmount: arrOFIds.length * parseInt(amount),
        details: props?.location?.state?.itemSelectedMode.map((item) => {
          return {
            remarks: message,
            item_type: 7,
            orphan_id: item,
            sponsor_id: loadQueryParams().sponsor_id,
            dontype_id: "1",
            amount,
          }
        })
      }
    } else if (loadQueryParams() && loadQueryParams().orphon_id) {
      objectToSend = {
        from: "gifts",
        elements: [{ title: "Send Gifts/ Support", price: amount}],
        totalAmount: amount,
        details: [{
            remarks: message,
            item_type: 7,
            orphan_id: loadQueryParams().orphon_id,
            sponsor_id: loadQueryParams().sponsor_id,
            dontype_id: "1",
            amount,
          }]
      }
    } else {
      window.location.replace("/");
    }
    history.push(`/checkout`,objectToSend)
  };

  if (getUserInfo()) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="media">
          <ShareHeader pageName={t("Send a Gift")} noShare={true}/>
          <div className="submit_requ_header">
            <h3 style={{ color: "gray" }}>{t("Send a Gift")}</h3>
          </div>
          <div
            style={{
              textAlign: "center",
              padding:'20px',
              fontWeight: "300",
              fontSize: "1.2rem",
            }}
          >
            <span>{props?.location?.state?.str === 'many'? t("SEND A GIFT TO YOUR SPONSORS"):t("SEND A GIFT TO YOUR SPONSOR")}</span>
          </div>
          <div className="sendGiftInputs" style={{fontFamily: 'english_font'}}>
            <div className="editProfileCard editProfileName width">
              <input
              type="text"
                name="amount"
                pattern="[0-9]*"
                maxLength="8"
                required
                value={amount}
                style={{
                  direction: lng == "arab" ? "rtl" : "ltr",
                  width: "100%",
                }}
                onChange={handleChange}
                placeholder={t("Enter Amount")}
              />
            </div>
            <div className="editProfileCard editProfileName width">
              <input
                type="text"
                name="message"
                value={message}
                maxLength="200"
                style={{
                  direction: lng == "arab" ? "rtl" : "ltr",
                  width: "100%",
                }}
                onChange={handleChange}
                placeholder={t("Enter the message here")}
              />
            </div>
            {props?.location?.state?.str === 'many' && props?.location?.state?.str?
            <div className="sendGiftInputs" style={{gridRowGap:'0px', direction: lng==='arab'? 'rtl':'ltr'}}>
              <div className="editProfileNote editProfileName width">
            <span className="">
            {t("Number of Orphans")}
            </span>
            <div className="width2 payment_numbers" style={{fontFamily: 'english_font'}}>
              {numberOfOrphans}
            </div>
          </div>
          <div className="editProfileNoteCalculate editProfileName width">
            <span>
            {t("Amount to send")}
              </span>
              <div className="width2 payment_numbers" style={{fontFamily: 'english_font'}}>
              {amount}
            </div>
          </div>
          <div className="editProfileNoteCalculate editProfileName width">
            <span>
            ---------------------------------
              </span>
          </div>
          <div className="editProfileNoteCalculate editProfileName width" >
            <span>
            {t("Total")}
            </span>
            <div className="width2 payment_numbers" style={{fontFamily: 'english_font'}}>
              {numberOfOrphans * amount}
            </div>
          </div>
          </div>:null
          }
            
            <button
              type="submit"
              className="sendGiftBtn"
              style={{
                flexDirection: lng == "arab" ? "row-reverse" : "row",
              }}
            >
              <span> </span>
              <span>{t("Send Now")}</span>
              <div
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  display: "flex",
                }}
              >
                <i
                  className="fas fa-arrow-right"
                  style={{
                    transform: lng == "arab" ? "rotate(180deg)" : "none",
                  }}
                ></i>
              </div>
            </button>
          </div>
        </div>
      </form>
    );
  } else {
    window.location.replace("/");
  }
};
export default SendGift;
