import { FETCH_ALL_EVENTS, FETCH_ALL_EVENTS_LOADING } from '../constants/actionTypes'
import * as api from '../api'

export const getAllEvents = (langNumber) => async (dispatch) => {
    dispatch({ type: FETCH_ALL_EVENTS_LOADING, status: true })
    try {
        
        const { data } = await api.getEventsTracker(langNumber);
        dispatch({ type: FETCH_ALL_EVENTS, payload: data.status? data.data : [] })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: FETCH_ALL_EVENTS_LOADING, status: false })
    }
}