import React, { useEffect, useState } from "react";
import "./LocalAidMainPage.css";
import { Route, Switch } from "react-router-dom";
import LocalAid from "./localAidPage/LocalAid";
import LocalAidInfoForm from "./localAidRequestInfoForm/LocalAidInfoForm";
import SubmitRequ from "./submitRequPage/SubmitRequ";
import RequestsLobby from "./requestsLobyPage/RequestsLobby";
import LocalAidProfile from "./localAidProfilePage/LocalAidProfile";
import LocalAidRelatives from "./localAidRelativesPage/LocalAidRelatives";
import LocalAidRelativeForm from "./localAidRelativeForm/LocalAidRelativeForm";
import LocalAidRelativeIdentity from "./localAidRelativeIdentity/LocalAidRelativeIdentity";
import LinkAccount from "./linkAccount/LinkAccount";
import LocalAidRelation from "./LocalAidRelation/LocalAidRelation";
import LocalAidRelativeAddress from "./RelativeAddress/RelativeAddress";
import UploadLocalAidFile from "./uploadFile/UploadLocalAidFile";
import LocalAidReg from "./localAidReg/LocalAidReg";

function LocalAidMainPage() {
  return (
    <div className="local_aid_main_page">
      <Switch>
        <Route path="/localAid/" component={LocalAid} exact />
        <Route
          path="/localAid/setup-information"
          component={LocalAidReg}
        />
       {/* <Route
          path="/localAid/setup-relatives"
          component={LocalAidRelativeForm}
        />
        <Route
          path="/localAid/setup-relative-identity"
          component={LocalAidRelativeIdentity}
       />*/}
        <Route path="/localAid/submit-request" component={SubmitRequ} />
        <Route path="/localAid/requests" component={RequestsLobby} />
        <Route path="/localAid/localAidProfile" component={LocalAidProfile} />
        <Route path="/localAid/relatives" component={LocalAidRelatives} />
        <Route path="/localAid/link-account" component={LinkAccount} />
        <Route path="/localAid/local-aid-relation" component={LocalAidRelation} />
        {/*
        <Route path="/localAid/local-aid-relative-address" component={LocalAidRelativeAddress} />
        <Route path="/localAid/upload-file" component={UploadLocalAidFile} />*/}
      </Switch>
    </div>
  );
}

export default LocalAidMainPage;
