import React, {useEffect, useState} from 'react'
import './projectsCom.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
//import Marquee from 'react-fast-marquee'
import Marquee from 'react-double-marquee';

const ProjectsCom = (props) => {
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const { t } = useTranslation();
    const history = useHistory();
    const handleClick = (str) => {
        history.push(`/projects/${str}`)
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <div  className='projects_com'>
            <div onClick={() => handleClick(props.projectType)} className='projects_com_box'>
                <img src={props.img} alt={props.project_img_alt} className='project_img' style={{ height: '300px', width: '400px', borderRadius: '10px', objectFit: 'fill' }} />
            </div>
            {props.title.length >= 20?
            <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{direction:'ltr', width: width>=975? '400px': width>=470? '120px': '100px'}}>
                <Marquee speed={0.025} childMargin={15} direction={lng==='arab'? 'right':'left'} style={{width:'100%'}} className='label_of_title'><label className='label_of_title' >{props.title ? props.title : 'Loading'}</label></Marquee>
            </div>:          
            <div className='marquee_wraper' style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}><label className='label_of_title' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', textAlign: 'center' }}>{props.title}</label></div>
            }
        </div>
    )
}
export default ProjectsCom