import React, { useState, useEffect } from "react";
import "./ProjectsPage.css";
import SL1 from "../../../assets/projectHeader.png";
import serachIcon from "../../../assets/search.png";

import ProjectsDisplay from "./sections/projectsDisplay/ProjectsDisplay";
import { useTranslation } from "react-i18next";
import CountriesDisplay from "./sections/countriesDisplay/CountriesDisplay";
import { useSelector, useDispatch } from "react-redux";
import Fuse from "fuse.js";
import FilterModal from "../../LoaclComponents/filterModal/FilterModal";
import filterIcon from "../../../assets/filter.png";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

const ProjectsPage = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [projectSwitch, setProjectSwitch] = useState(0);
//   const location = useLocation()
  const projects = useSelector((state) => state.fullProjects.fullProjects);
  const [uniqueProjects, setUniqueProjects] = useState([])
  const projectsForInouts = useSelector((state) => state.fullProjects.fullProjects);
  const projectsLoading = useSelector((state) => state.fullProjects.loading);
  const countries_proj_sec_real = useSelector((state) => state.countries_proj_sec.countries_proj_sec);
  const countries_proj_sec_realـALL = useSelector((state) => state.countries_proj_sec.countries_proj_sec_ALL);
  const countries_proj_sec_realLoading = useSelector((state) => state.countries_proj_sec.loading);
  const [projectsLoadingState, setProjectsLoadingState] = useState(projectsLoading);
  
  const [countriesLoadingState, setCountriesLoadingState] = useState(projectsLoading);
  const [countriesALLState, setCountriesAllState] = useState(countries_proj_sec_realـALL);
  useEffect(() => {
    setCountriesLoadingState(countries_proj_sec_realLoading);
  }, [countries_proj_sec_realLoading]);
  useEffect(() => {
    setCountriesAllState(countries_proj_sec_realـALL);
  }, [countries_proj_sec_realـALL]);
  useEffect(() => {
    setProjectsLoadingState(projectsLoading);
  }, [projectsLoading]);
  const fullProjects = useSelector((state) => state.fullProjects.fullProjects);
//   const dispatch = useDispatch();
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [openButtons, setOpenButton] = useState(false);
  const [allProjects, setAllProjects] = useState(projects);
  const [allProjectsForInputs, setAllProjectsForInputs] = useState(projectsForInouts);

  const [fullProjectsState, setFullProjectsState] = useState(projects);
  let projectsAmountArr = projects?.map((item) => parseInt(item.amount));
  const [countries_proj_sec, setCountries_proj_sec] = useState([]);
  const [disableUntill, setDisableUntill] = useState({ status: true, dataObject: {} });

  const [shareUrl, setShareUrl] = useState(false);
  const [selectedIDForShare, setSelectedIDForShare] = useState(false);

  const [maximumAmount, setMaximumAmount] = useState(
    Math.max(...projectsAmountArr)
  );
  const [minimumAmount, setMinimumAmount] = useState(
    Math.min(...projectsAmountArr)
  );
  const [maximumSelected, setMaximumSelected] = useState(
    Math.max(...projectsAmountArr)
  );

//   const history = useHistory();
  const [filterBody, setFilterBody] = useState({
    order: "",
    min: 0,
    max: 0,
    projectType:''
  });

  const [filteredUniqueProjects, setFilteredUniqueProjects] =  useState(allProjects);
  const [loadedProjects, setLoadedProjects] = useState(false);
  
useEffect(() => {
  if(!projectsLoading)
  {
    //Getting all unique Projects
    let projectsByTheSameProjectType = projects.slice(0);
    let projectsIDS = projects.map(item => item.projTypeID);
    let uniqueProjects = []
    for (let i = 0; i < projectsByTheSameProjectType.length; i++) {
        projectsIDS.splice(projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID), 1)
        if (projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID) < 0)
            uniqueProjects.push(projectsByTheSameProjectType[i]);
    }

    //Getting all unique countries
    let projectsByTheSameCountry = projects.slice(0);
    let countriesIDS = projects.map(item => item.countryID);
    let uniqueCountries = []
    for (let i = 0; i < projectsByTheSameCountry.length; i++) {
      countriesIDS.splice(countriesIDS.indexOf(projectsByTheSameCountry[i].countryID), 1)
        if (countriesIDS.indexOf(projectsByTheSameCountry[i].countryID) < 0)
        uniqueCountries.push(projectsByTheSameCountry[i]);
    }
    setUniqueProjects(uniqueProjects)
    setLoadedProjects(true);
    setAllProjects(projects);
  }
}, [projects])

useEffect(() => {
  if(!projectsLoading)
  {
    //Getting all unique Projects
    let projectsByTheSameProjectType = allProjects.slice(0);
    let projectsIDS = allProjects.map(item => item.projTypeID);
    let uniqueProjects = []
    for (let i = 0; i < projectsByTheSameProjectType.length; i++) {
        projectsIDS.splice(projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID), 1)
        if (projectsIDS.indexOf(projectsByTheSameProjectType[i].projTypeID) < 0)
            uniqueProjects.push(projectsByTheSameProjectType[i]);
    }

    //Getting all unique countries
    let projectsByTheSameCountry = allProjects.slice(0);
    let countriesIDS = allProjects.map(item => item.countryID);
    let uniqueCountries = []
    for (let i = 0; i < projectsByTheSameCountry.length; i++) {
      countriesIDS.splice(countriesIDS.indexOf(projectsByTheSameCountry[i].countryID), 1)
        if (countriesIDS.indexOf(projectsByTheSameCountry[i].countryID) < 0)
        uniqueCountries.push(projectsByTheSameCountry[i]);
    }
    setFilteredUniqueProjects(uniqueProjects)
    // setLoadedProjects(true);
  }
}, [allProjects])
  

//   // useEffect(() => {
//   //   setAllProjectsForInputs(projectsForInouts);
//   // }, [projectsForInouts]);

//   // // useEffect(() => {
//   // //   window.scrollTo(0, 0);
//   // // }, []);
//   // useEffect(() => {
//   //   setAllProjects(projects);
//   // }, [projects, projectSwitch]);
//   // useEffect(() => {
//   //   setCountries_proj_sec(countries_proj_sec_real);
//   // }, [countries_proj_sec_real, projectSwitch]);
  useEffect(() => {
    let projectsAmountArr = projects?.map((item) => parseInt(item.amount));
    setMaximumAmount(Math.max(...projectsAmountArr));
    setMinimumAmount(Math.min(...projectsAmountArr));
    setMaximumSelected(Math.max(...projectsAmountArr));
  }, [projects]);
  const handleClick = () => {
    // dispatch(getProjects(lng === "arab" ? 1 : 2, "", "", "", 1, ''));
    // dispatch(getCountries(lng === "arab" ? 1 : 2, "", "", "", 1, ''));
    setFilterBody({
      order: "",
      min: 0,
      max: 0,
    });
    if (projectSwitch === 0) {
      setProjectSwitch(1);
      document.getElementById("btn-country").style.color = "#ffffff";
      // document.getElementById("btn-country").style.transform = 'scale(1.1)'
      document.getElementById("btn-country").style.backgroundColor = '#ee4236';
      document.getElementById("btn-country").style.boxShadow = '0px 0px 15px 1px #0f0f0f';
      document.getElementById("btn-project").style.color = "#000000";
      document.getElementById("btn-project").style.transform = 'none'
      document.getElementById("btn-project").style.backgroundColor = '#ffffff';
      document.getElementById("btn-project").style.boxShadow = 'none';
    } else {
      setProjectSwitch(0);
      // document.getElementById("btn-project").style.transform = 'scale(1.1)'
      document.getElementById("btn-project").style.backgroundColor = '#ee4236';
      document.getElementById("btn-project").style.boxShadow = '0px 0px 15px 1px #0f0f0f';
      document.getElementById("btn-project").style.color = "#ffffff";
      document.getElementById("btn-country").style.color = "#000000";
      document.getElementById("btn-country").style.transform = 'none'
      document.getElementById("btn-country").style.backgroundColor = '#ffffff';
      document.getElementById("btn-country").style.boxShadow = 'none';
    }
  };
  const searchData = async (pattern) => {
    setFilterBody({
      order: "",
      min: 0,
      max: 0,
    });
    if (projectSwitch === 0) {
      if (!pattern) {
        setAllProjects(projects);
        return;
      }
      else if (pattern.length < 3) {
      const fuse = new Fuse(projects, {
        keys: ["prjTypeName"],
      });
      setProjectsLoadingState(true)
       const result = await fuse.search(pattern);
      const matches = [];
      if (!result.length) {
        setProjectsLoadingState(false)
        setAllProjects([{ status: 'no results' }]);
      } else {
       await result.forEach(({ item }) => {
          matches.push(item);
        });
        setProjectsLoadingState(false)
        setAllProjects(matches);
      }
    }
    else
    {
      const fuse = new Fuse(projects, {
        keys: ["prjTypeName"],
      });
      setProjectsLoadingState(true)
       const result = projects.filter((item) => 
       item.prjTypeName.toLowerCase().startsWith(pattern.toLowerCase())
     )
      const matches = [];
      if (!result.length) {
        setProjectsLoadingState(false)
        setAllProjects([{ status: 'no results' }]);
      } else {
       await result.forEach((item) => {
          matches.push(item);
        });
        setProjectsLoadingState(false)
        setAllProjects(matches);
      }
    }
    } else {
      if (!pattern) {
        setAllProjects(projects);
        return;
      }
      else if(pattern.length < 3 )
      {
        const fuse = new Fuse(projects, {
          keys: ["countryName"],
        });
        setCountriesLoadingState(true)
        const result = await fuse.search(pattern);
        const matches = [];
        if (!result.length) {
          setCountriesLoadingState(false)
          setAllProjects([{ status: 'no results' }]);
        } else {
         await result.forEach(({ item }) => {
            matches.push(item);
          });
          setCountriesLoadingState(false)
          setAllProjects(matches);
        }
      }
      else
      {
        const fuse = new Fuse(projects, {
          keys: ["countryName"],
        });
        setCountriesLoadingState(true)
        const result = projects.filter((item) => 
       item.countryName.toLowerCase().startsWith(pattern.toLowerCase())
     )
        const matches = [];
        if (!result.length) {
          setCountriesLoadingState(false)
          setAllProjects([{ status: 'no results' }]);
        } else {
         await result.forEach((item) => {
            matches.push(item);
          });
          setCountriesLoadingState(false)
          setAllProjects(matches);
        }
      }
    }
  };
  
  const handleFilter = () => {
    let count = 0;
    if (parseInt(filterBody.min) > parseInt(filterBody.max) || parseInt(filterBody.min) < 0 || parseInt(filterBody.max) <0) {
      //setAllDonations([{ status: 'no results' }]);
      return toastifyMessage("Min value is less than or equal Max!", undefined, "warning");
  }
  let projectTypes = projects;
  if(filterBody?.projectType >= 1)
  {
    projectTypes = projects.filter(item => item.projTypeID === filterBody.projectType)
  }

if (filterBody.max >= 10 && filterBody.order === ''  ) {

    let newDonations = projectTypes.filter(item => item.amount).filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min));
    if (newDonations.length === 0) {
      setAllProjects([{ status: 'no results' }]);
    } else {
      setAllProjects(newDonations)
    }
} else if (filterBody.max >= 10 && filterBody.order !== '') {
    let sortedDonations = [];
    if (filterBody.order === 'alpha') {
      let allProjects = projectTypes
      let newProj = projectTypes;

      let names = [...new Set(projectTypes.map(item => item.prjTypeName))]
      names.sort()
      for (let i = 0; i < names.length; i++) {
        let itemWeNeed = newProj.filter(item => item.prjTypeName === names[i]);
        for(let k = 0; k < itemWeNeed.length; k++)
        {
          sortedDonations.push(itemWeNeed[k]);
        }
      }
        let newDonations = allProjects.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min));
        if (newDonations.length === 0) {
          setAllProjects([{ status: 'no results' }]);
        } else {
          setAllProjects(newDonations)
        }
        
        //setAllDonations(newDonations)
    } else if (filterBody.order === 'lowToHigh') {
        let sortedAmounts = projectTypes.filter(item => item.amount).sort((a, b) => parseInt(a.amount - parseInt(b.amount)));
        let newDonations = sortedAmounts.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min))
        if (newDonations.length === 0) {
          setAllProjects([{ status: 'no results' }]);
        } else {
          setAllProjects(newDonations)
        }
        //setAllDonations(newDonations)
    } else {
        let sortedAmounts = projectTypes.filter(item => item.amount).sort((a, b) => { return parseInt(b.amount - parseInt(a.amount)) });
        let newDonations = sortedAmounts.filter(item => parseInt(item.amount) <= parseInt(filterBody.max)).filter(item=> parseInt(item.amount) >= parseInt(filterBody.min))
        if (newDonations.length === 0) {
          setAllProjects([{ status: 'no results' }]);
        } else {
          setAllProjects(newDonations)
        }
        //setAllDonations(newDonations)
    }
} else if (filterBody.max < 10 && filterBody.order !== '') {
    let sortedDonations = [];
    if (filterBody.order === 'alpha') {
        let names = [...new Set(projectTypes.map(item => item.prjTypeName))]
        names.sort()
        let newProj = projectTypes;
        for (let i = 0; i < names.length; i++) {
          let itemWeNeed = newProj.filter(item => item.prjTypeName === names[i]);
          for(let k = 0; k < itemWeNeed.length; k++)
          {
            sortedDonations.push(itemWeNeed[k]);
          }
        }
        setAllProjects(sortedDonations)
    } else if (filterBody.order === 'lowToHigh') {
        let sortedAmounts = projectTypes.filter(item => item.amount).sort((a, b) => { return parseInt(a.amount - parseInt(b.amount)) });
        setAllProjects(sortedAmounts)
    } else {
        let sortedAmounts = projectTypes.filter(item => item.amount).sort((a, b) => { return parseInt(b.amount - parseInt(a.amount)) });
        setAllProjects(sortedAmounts)
    }
} 
else if(filterBody.order === 'alpha')
{
  let sortedDonations = [];

            let allProjects = projectTypes
            let names = allProjects.map(item => item.prjTypeName)
            names.sort()
            for (let i = 0; i < names.length; i++) {
                let itemWeNeed = allProjects.filter(item => item.prjTypeName === names[i]).slice(0, 1).shift()
                sortedDonations.push(itemWeNeed)
            }
            setAllProjects(sortedDonations)
        }
        else {
          setAllProjects(projectTypes)
        }

    
    setFilterModalShow(false);
  };

  // useEffect(() => {
  //   let textOfPageArr = window.location.href.split("/");
  //   textOfPageArr.splice(
  //     textOfPageArr.length - 1,
  //     1,
  //     selectedIDForShare ? selectedIDForShare : "all"
  //   );
  //   setShareUrl(textOfPageArr.join("/"));
  // }, [selectedIDForShare]);

return (
  <div id="projects_page">
    <div
      id="projects_banner"
      style={{
        backgroundImage: `url(${SL1})`,
        backgroundPosition: "center bottom",
        backgroundSize: "cover",
      }}
    >
      <h3>{t("Help Build The World")}</h3>
      <h1 style={{ fontWeight: "bold" }}>{t("Charity projects")}</h1>
      <div
        className="search_input_for_projects"
        style={
          lng === "arab" ? { flexDirection: "row", direction: "rtl" } : null
        }
      >
        <input
        className={lng==='arab'? 'force_arabic_font':'payment_numbers'}
          onChange={(e) => searchData(e.target.value)}
          type="text"
          placeholder={projectSwitch===0? t("Search for a project"):t("Search for a country")}
          style={
            lng === "arab"
              ? {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }
              : null
          }
        />
        <button
          style={
            lng === "arab"
              ? {
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                minHeight:'44px'
              }
              : {minHeight:'44px'}
          }
          type="button"

        >
          <img style={{height:'20px', width:'20px'}} src={serachIcon} alt='favorites_icon'/>
          {/* {<i style={{ color: "#ee4236" }} className="fas fa-search"></i>} */}
        </button>
      </div>
    </div>
    <ShareHeader
    backTwice = {location.state?.selectedProject? true:false}
      textToBeCopied={shareUrl}
      pageName={t("Charity projects")}
      path={'projects'}
      disableUntill={disableUntill.status}
      shareData={disableUntill.dataObject}
    />
    
    <div id="switch_project_page">
      <button
        id="btn-project"
        onClick={handleClick}
        className="switch_btn btn-first"
      >
        {t("Projects")}
      </button>
      <button id="btn-country" onClick={handleClick} className="switch_btn">
        {t("Countries")}
      </button>
    </div>
    <div
        className="search_input_for_projects mobileOnly"
        style={
          lng === "arab" ? { flexDirection: "row", direction: "rtl" } : null
        }
      >
        <input
          onChange={(e) => searchData(e.target.value)}
          className={lng==='arab'? 'force_arabic_font':'payment_numbers'}
          type="text"
          placeholder={projectSwitch===0? t("Search for a project"):t("Search for a country")}
          style={
            lng === "arab"
              ? {
                width: '100%',
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }
              : {width: '100%'}
          }
        />
        <button
          style={
            lng === "arab"
              ? {
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }
              : null
          }
          type="button"
        >
          <i style={{ color: "#ee4236" }} className="fas fa-search"></i>
        </button>
      </div>
    {projectSwitch === 0?  (
      allProjects?.slice(0)?.shift()?.status === 'no results' ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
          <p>{t("No projects found")}</p>
        </div> : 
        allProjects.length===0 && !projectsLoading?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
          <p>{t("No projects found")}</p>
        </div> : 
        /*  <ProjectsDisplay setSelectedIdForShare={setSelectedIdForShare} allProjects={allProjects} max={maximumAmount} min={minimumAmount}/>:*/
        <ProjectsDisplay
          setDisableUntill={setDisableUntill}
          filterBody={filterBody}
          projectsLoadingState={projectsLoadingState}
          setSelectedIDForShare={setSelectedIDForShare}
          maximumSelected={maximumSelected}
          countries_proj_sec={countries_proj_sec}
          allProjects={allProjects}
          openButtons={openButtons}
          setOpenButton={setOpenButton}
          projectSwitch={projectSwitch}
        />
    ) : (
      allProjects?.slice(0)?.shift()?.status === 'no results' ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
          <p>{t("No countries found")}</p>
        </div> :
        allProjects.length===0 && !projectsLoading?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
          <p>{t("No countries found")}</p>
        </div> :
        <CountriesDisplay
          setDisableUntill={setDisableUntill}
          filterBody={filterBody}
          setSelectedIDForShare={setSelectedIDForShare}
          countriesLoadingState={countriesLoadingState}
          maximumSelected={maximumSelected}
          allProjects={allProjects}
          countries_proj_sec={countries_proj_sec}
          fullProjectsState={fullProjectsState}
          openButtons={openButtons}
          setOpenButton={setOpenButton}
          projectSwitch={projectSwitch}
        />
    )}
    <div className="filter_wraper" onClick={() => setFilterModalShow(true)}>
      <button onClick={() => setFilterModalShow(true)} className="filter_btn">
        <img src={filterIcon} className="filter_icon" alt="filterIcon" />
      </button>
    </div>
    <FilterModal
        show={filterModalShow}
        filterbody={filterBody}
        setfilter={setFilterBody}
        fromproject={'true'}
        max={maximumAmount}
        min={minimumAmount}
        uniqueprojects={uniqueProjects}
        setallprojects={setAllProjects}
        handlefilter={handleFilter}
        dialogClassName="modal-60w"
        onHide={() => setFilterModalShow(false)}
        // fromProjects={true}
      />
  </div>
);
};

export default ProjectsPage;
