import { FETCH_ALL_LANDING, LOAD_LANDING, LANDING_LOADED } from '../constants/actionTypes'

export default (landingPages = { landingPages: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ALL_LANDING:
            return { ...landingPages, landingPages: action.payload, loading: false };
        case LOAD_LANDING:
            return {
                ...landingPages, loading: true
            };
        case LANDING_LOADED:
            return {
                ...landingPages, loading: false
            };
        default:
            return landingPages;
    }
}