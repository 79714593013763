import React, { useState, useEffect } from "react";
import "./ShareHeader.css";
import share from "../../../assets/share.png";
import { useHistory } from "react-router-dom";
import ShareAlertModel from "./share_model/ShareModelAlert";
import { useSelector, useDispatch } from 'react-redux'
import Marquee from 'react-double-marquee';
import favorites from "../../../assets/qalb.png";
import bagIcon from "../../../assets/bag.png";
const ShareHeader = (props) => {
  const history = useHistory();
  const [showCopyCard, setshowCopyCard] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;


  
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  //path
  //shareData
  return (
    <div
      className="headerForMob_sharing"
      style={
        props.forAuth
          ? { backgroundColor: "transparent", boxShadow: "none" }
          : null
      }
    >
      
      {props.noBack ?
        null :
        <button onClick={() => props.backTwice? history.go(-2):history.goBack()} className='backBtn_forMobHeader'>
          <i className=" backHeader fas fa-arrow-left"></i>
        </button>
      }
      {props.noTitle ? null : (
        props.pageName?.length >= 36?
          <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{direction:'ltr', minHeight:'auto', width:'90%'}} >
              <Marquee speed={0.025} childMargin={15} direction={lng==='arab'? 'right':'left'} className='label_of_title'><label className={props.mco? 'payment_numbers':null} style={{ textAlign: "center" }}>{props.pageName}</label></Marquee>
          </div>:          
          <div><label className={props.mco? 'payment_numbers':null} style={{ textAlign: "center" }}>{props.pageName}</label></div>
      )}
      {props.noShare ? null : (
        // <CopyToClipboard text={shareStatus} onCopy={() => showMessage()}>
        <button
          disabled={props.disableUntill}
          className="sharingBtn_mob"
          onClick={() => setshowCopyCard(true)}
        >
          <img style={{ height: "20px", filter: props.disableUntill? 'grayscale(100%)':"unset" }} src={share} alt="shareMob" />
        </button>
        // </CopyToClipboard>
      )}
      {props.removeAllFromCart?
      <button disabled={props.loading} onClick={() => props.removeAllCart()} style={
        width <= 1150
          ? {
            position: "absolute",
            right: "50px",
            border: "none",
            backgroundColor: "transparent",
          }
          : { display: "none" }
      }>
          <i style={{ fontSize: "27px", color: "#ee4236" }} className=" backHeader fas fa-trash"></i>
      </button>:null
    }
      {props.fromFavorites ? (
        <button
          onClick={() => history.push("/mybag")}
          id="noMobAtAll"
          style={
            width <= 1150
              ? {
                position: "absolute",
                right: "5px",
                border: "none",
                backgroundColor: "transparent",
              }
              : { display: "none" }
          }
        >
          <img style={{height:'30px', width:'30px'}} src={bagIcon} alt='favorites_icon'/>
        </button>
      ) : null}
      {props.fromBag ? (
        <button
          onClick={() => history.push("/favorites")}
          id="noMobAtAll"
          style={
            width <= 1150
              ? {
                position: "absolute",
                right: "5px",
                border: "none",
                backgroundColor: "transparent",
              }
              : { display: "none" }
          }
        >
          <img style={{height:'30px', width:'30px'}} src={favorites} alt='favorites_icon'/>
        </button>
      ) : null}
      <ShareAlertModel
        path={props.path}
        shareData={props.shareData}
        show={showCopyCard}
        onHide={() => {
          setshowCopyCard(false);
        }}
      ></ShareAlertModel>
    </div>
  );
};

export default ShareHeader;
