/* eslint-disable import/no-anonymous-default-export */
import {
  AUTH,
  CLEAR_SPONSER_ACCOUNT,
  FETCH_ALL_DONATIONSSUMMARY,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_ALL_ORPHONS,
  FETCH_SPONSORED_FOLLOW_UPS,
  LOGOUT,
  LOGOUT_WITH_GOOGLE,
  SET_SPONSER_ACCOUNT,
  SET_LAID_ACCOUNT,
  NEW_LAID_ACCOUNT,
  DONATIONS_LOADING_FALSE,
  TRANSACTIONS_LOADING,TRANSACTIONS_LOADING_FALSE,
  DONATIONS_LOADING, LOAD_NOTIFICATIONS,
  NOTIFICATIONS_LOADED,
  LOAD_SPONSOR,
  SPONSOR_LOADED,
  LOAD_ORPHANS,
  ORPHANS_LOADED,
  SPONSOR_FOLLOWUPS_LOADED,
  LOAD_SPONSOR_FOLLOWUPS,
  FETCH_ALL_TRANSACTIONSSUMMARY
} from "../constants/actionTypes";
import { logOutFromFB } from "../components/Auth/facebookSignIn/logoutFromFacebook";

export default (
  state = {
    authData: null,
    donations: [],
    transactions: [],
    followupsReports: [],
    notifications: [],
    myorphons: [],
    orphonsLoading: false,
    extraSponID: null,
    donationLoading: false,
    notificationLoading: false,
    sponsorLoading: false,
    sponsorFollowUpLoading: false
  },
  action
) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("userLogin", JSON.stringify({ ...action?.data }));
      sessionStorage.setItem("loggedInMsg", "true");
      return { ...state, authData: action?.data };
    case LOGOUT:
      var users = JSON.parse(localStorage.getItem("userLogin"));
      localStorage.removeItem("userLogin");
      if(users.Results[0]?.login_type === "UAEPASS")
      {
        window.location.href = "https://id.uaepass.ae/idshub/logout?redirect_uri=" + window.location.origin;
      }
      else
      {
        logOutFromFB();
        window.FB.logout();
        window.location.reload();
      }
      return { ...state, authData: null };
    case LOGOUT_WITH_GOOGLE:
      localStorage.removeItem("userLogin");
      window.location.reload();
      return { ...state, authData: null };
    case FETCH_ALL_DONATIONSSUMMARY:
      return { ...state, donations: action?.payload ?? [] };
    case FETCH_ALL_TRANSACTIONSSUMMARY:
      return { ...state, transactions: action?.payload ?? [] };
    case FETCH_SPONSORED_FOLLOW_UPS:
      return { ...state, followupsReports: action?.payload, sponsorFollowUpLoading: false };
    case LOAD_SPONSOR_FOLLOWUPS:
      return { ...state, sponsorFollowUpLoading: true }
    case SPONSOR_FOLLOWUPS_LOADED:
      return { ...state, sponsorFollowUpLoading: false }
    case FETCH_ALL_NOTIFICATIONS:
      return { ...state, notifications: action?.payload?.data };

    case LOAD_NOTIFICATIONS:
      return { ...state, notificationLoading: true };
    case NOTIFICATIONS_LOADED:
      return { ...state, notificationLoading: false };
    case FETCH_ALL_ORPHONS:
      return { ...state, myorphons: action?.payload || [], sponsorLoading: false, orphonsLoading: false };
    case LOAD_ORPHANS:
      return { ...state, orphonsLoading: true };
    case ORPHANS_LOADED:
      return { ...state, orphonsLoading: false };
    case LOAD_SPONSOR:
      return { ...state, sponsorLoading: true };
    case SPONSOR_LOADED:
      return { ...state, sponsorLoading: false }
    case "ADD_CARD":
      var userTokens = JSON.parse(localStorage.getItem("user_tokens"));
      if (userTokens == null) userTokens = [];
      userTokens.push(action.payload);
      localStorage.setItem("user_tokens", JSON.stringify(userTokens));
      return {
        ...state,
        arr: [...state.authData.user_tokens, action.payload],
      };
    case "DELETE_CARD":
      // var userTokens = JSON.parse(localStorage.getItem("user_tokens"));
      const newUserTokens = action.payload;
      // userTokens = userTokens.filter(function (ele) {
      //   return ele.token != action.payload;
      // });
      localStorage.setItem("user_tokens", JSON.stringify(newUserTokens));
      return {
        ...state,
        arr: [...state.authData.user_tokens, action.payload],
      };
    // case SET_LAID_ACCOUNT:
    //   if (action?.payload) {
    //     state.authData = { Results: action?.payload };
    //     state = {
    //       ...state,
    //     };
    //   }
    // localStorage.setItem("userLogin", JSON.stringify(state.authData));
    // return { ...state };
    case NEW_LAID_ACCOUNT:
      if (action?.payload) {
        //state.authData.Results[0].aids_req_id = action?.payload;
        //state.authData.Results[0].person_id_flag = "1";

      }
      localStorage.setItem("userLogin", JSON.stringify(action.payload));
      return { ...state };
    case SET_SPONSER_ACCOUNT:
      return {
        ...state,
        extraSponID: action?.payload,
        myorphons: [],
        followupsReports: [],
      };
    case CLEAR_SPONSER_ACCOUNT:
      return { ...state, extraSponID: "" };
    case DONATIONS_LOADING:
      return { ...state, donationLoading: true };
    case TRANSACTIONS_LOADING:
      return { ...state, transactionsLoading: true };
    case DONATIONS_LOADING_FALSE:
      return { ...state, donationLoading: false };
    case TRANSACTIONS_LOADING_FALSE:
      return { ...state, transactionsLoading: false };
    default:
      const user = localStorage.getItem("userLogin");
      if (user) {
        state.authData = JSON.parse(user);
      }
      return state;
  }
};
