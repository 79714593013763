import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import './FilterModal.css'
import filterIcon from '../../../assets/filter.png'
import { useTranslation } from "react-i18next";

function FilterModal(props) {
    const { setfilter, handlefilter, setallprojects, uniqueprojects, ...rest } = props
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;

    const [fontSwitch, setFontSwitch] = useState(lng==='arab'? 'force_arabic_font':'payment_numbers')
    const { t } = useTranslation();
    const [checked, setChecked] = useState('')
    const handleFilterChange = (e) => {
        props.setfilter({ ...props.filterbody, max: e.target.value })
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const handleCheck = (str) => {
        if (str === 'alpha') {
            if (checked === 'alpha') {
               setChecked('')
               props.setfilter({ ...props.filterbody, order: '' }) 
            } else {
                setChecked('alpha')
               props.setfilter({ ...props.filterbody, order: 'alpha' }) 
            }
        } else if (str === 'lowToHigh') {
            if (checked === 'lowToHigh') {
                setChecked('')
                props.setfilter({ ...props.filterbody, order: '' }) 
             } else {
                 setChecked('lowToHigh')
                props.setfilter({ ...props.filterbody, order: 'lowToHigh' }) 
             }
        } else {
            if (checked === 'HightToLow') {
                setChecked('')
                props.setfilter({ ...props.filterbody, order: '' }) 
             } else {
                 setChecked('HightToLow')
                props.setfilter({ ...props.filterbody, order: 'HightToLow' }) 
             }
        }
        
    }
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    return (
        <Modal
            {...rest}
            size="lg"
            centered
            className='popUp'
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{fontFamily: lng==='arab'? "'arab_font":'english_font'}}>
                <div className='filter_modal' style={lng === 'arab' ? { backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px', direction: 'rtl' } : { backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
                    <div className='filter_inner_wraper' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: '10px', width: '100%' }}>
                        <div className='headerForAnimate animate__animated animate__fadeInUp'>
                            <h4 style={{ textAlign: 'center' }}>{t("Sorting")}</h4>
                            <div className='payment_methods_body' style={{ backgroundColor: 'transparent', boxShadow: 'unset', width: '100%' }}>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item'>
                                        <input onChange={() => handleCheck('alpha')} type="checkbox" id="alpha" name="order" className='check_input' checked={checked === 'alpha' ? true : false} />
                                        <label className='filter_choises' htmlFor="alpha">{t("Alphabetically")}</label>
                                    </div>
                                </div>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item'>
                                        <input onChange={() => handleCheck('lowToHigh')} type="checkbox" id="lowToHigh" className='check_input' name="order" checked={checked === 'lowToHigh' ? true : false} />
                                        <label className='filter_choises' htmlFor="lowToHigh">{t("Low amount to high amount")}</label>
                                    </div>
                                </div>
                                <div className='check_box_item_wraper'>
                                    <div className='check_box_item'>
                                        <input onChange={() => handleCheck('HightToLow')} type="checkbox" id="HightToLow" className='check_input' name="order" checked={checked === 'HightToLow' ? true : false} />
                                        <label className='filter_choises' htmlFor="HightToLow">{t("High amount to low amount")}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='headerForAnimate animate__animated animate__fadeInUp'>
                            <h4 style={{ textAlign: 'center' }}>{t("Filter")}</h4>
                            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
                            <div className='filter_max_min_wraper'>
                                <label style={{minHeight: width<=260? '48px':'unset', display:'flex', alignItems:'flex-end', justifyContent:'center'}}>{t("Max")}:</label>
                                <input className={lng==='arab'? props.filterbody.max || props.filterbody.max===0? 'payment_numbers':'force_arabic_font':'payment_numbers'} type='text' pattern='[0-9]*' value={props.filterbody.max} onChange={(e) => setfilter({ ...props.filterbody, max: e.target.validity.valid ? e.target.value : props.filterbody.max })} placeholder={t('Maximum Value')} style={{ backgroundColor: 'white', padding: '5px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', border: '1px solid lightgray', width:'100%' }} />
                            </div>
                            <div className='filter_max_min_wraper'>
                                <label style={{minHeight: width<=260? '48px':'unset', display:'flex', alignItems:'flex-end', justifyContent:'center'}}>{t("Min")}:</label>
                                <input className={lng==='arab'? props.filterbody.min || props.filterbody.min===0? 'payment_numbers':'force_arabic_font':'payment_numbers'} type='text' pattern='[0-9]*' value={props.filterbody.min} onChange={(e) => setfilter({ ...props.filterbody, min: e.target.validity.valid ? e.target.value : props.filterbody.min })} placeholder={t('Minimum Value')} style={{ backgroundColor: 'white', padding: '5px', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', border: '1px solid lightgray', width:'100%' }} />
                            </div>
                            </div>
                            <input style={{ height: '10px' }} type="range" min={props.min} max={props.max} step={10} value={props.filterbody.max} onChange={handleFilterChange} className="slider" />
                            {props.fromproject?
                            <select
                              value={props.filterbody.projectType}
                              onChange={(e)=> setfilter({ ...props.filterbody, projectType: e.target.value })}
                              name="projectType"
                              style={{boxShadow: '1px 0px 8px gray', borderRadius:'10px'}}
                              className="selectedCourse"
                              required
                            >
                              <option value="" disabled defaultValue>
                                {t("Select project type")}
                              </option>
                              <option value="" defaultValue>
                                {t("All")}
                              </option>
                              {props.uniqueprojects?.map((item,i) => (
                                <option key={i} value={item.projTypeID}>
                                  {item.prjTypeName}
                                </option>
                              ))}
                            </select>:null
                        }
                            
                        </div>
                    </div>
                    <button onClick={() => handlefilter()} className='doneBtn'>{t("Done")}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default FilterModal;