import { SHOW_TUTORIAL, HIDE_TUTORIAL} from "../../constants/actionTypes";

export default (tutorials = {
    showTutorial: false,
    tutorialCategory: ""
}, action) => {
    switch (action.type) {
        case SHOW_TUTORIAL:
            return {showTutorial: true, tutorialCategory: action.payload};
        case HIDE_TUTORIAL:
            return {showTutorial: false};
        default:
            return tutorials;
    }
}