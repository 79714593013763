import React from 'react'
import './SelectMode.css'
import { useTranslation } from "react-i18next";

const SelectMode = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div className='select_mode'>
      <h3 style={{width:'100%', textAlign:'center', color:'white', position:'absolute', top:'20px'}}>{t("Please select")}</h3>
      <div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly', width:'100%', color:'#fff', position:'absolute', right:0, bottom:'150px', zIndex:'9997'}}>
          <h1 className="payment_numbers">{props.selectedNum}</h1>
          <div className='select_mode_btn'>
            <button onClick={()=> props?.display===0?  props.setItemSelectedMode(props?.orphonsListMonthly?.map(item=> item.orp_id)):props.setItemSelectedMode(props?.orphonsListFull?.map(item=> item.orp_id))}>{t("Select all")}</button>
          </div>
          <div className='select_mode_close_btn'>
            <button onClick={()=> {props?.setSelectModeSwitch(false); props?.setItemSelectedMode([])}}>{t("Close")}</button>
          </div>
      </div>
    </div>
  )
}

export default SelectMode