export const TRANSLATIONS_EN = {
    "عربى": "عربى",
    "English": "English",
    "Donate to": "Donate to",
    "Hello,": "Hello,",
    "Please Login to Continue": "Please Login to Continue",
    "Forgot Account": "Forgot Account",
    "Forgot Password": "Forgot Password",
    "SIGN IN": "SIGN IN",
    "Create an Account": "Create an Account",
    "Or Sign in with": "Or Sign in with",
    "Emirates Red Crescent": "Emirates Red Crescent",
    "Hi, User": "Hi, User",
    "My Sponsors": "My Sponsors",
    "Be a Volunteer": "Be a Volunteer",
    "Local Aid": "Local Aid",
    "Recent Donation": "Recent Donation",
    "Sponsor Profile": "Sponsor Profile",
    "Charity": "Charity",
    "Projects": "Projects",
    "Donations": "Donations",
    "Payment Method": "Payment Method",
    "Account": "Account",
    "Beneficiary No.": "Beneficiary No.",
    "Date of Birth": "Date of Birth",
    "Nationality": "Nationality",
    "Sponsor Date": "Sponsor Date",
    "Beneficiary Message": "Beneficiary Message",
    "Send Gifts/ Support": "Send Gifts/ Support",
    "Call": "Call",
    "AED": "AED",
    "Pay": "Pay",
    "Add to Bag": "Add to Bag",
    "Family Status": "Family Status",
    "Educational Status": "Educational Status",
    "Supervisor": "Supervisor",
    "Support History": "Support History",
    "Kindly select the Payment Option": "Kindly select the Payment Option",
    "Select the Country": "Select the Country",
    "Monthly Payment": "Monthly Payment",
    "Full Payment": "Full Payment",



    "Register": "Register",
    "Please fill all the fields": "Please fill all the fields",
    "CREATE ACCOUNT": "CREATE ACCOUNT",
    "Humantarian Cases": "Humantarian Cases",
    "Donate Now": "Donate Now",
    "Sukuk / Vouchers": "Sukuk / Vouchers",
    "Become a Sponsor": "Become a Sponsor",
    "Family": "Family",
    "Together": "Together",
    "Help Build The World!": "Help Build The World!",
    "View All Projects": "View All Projects",
    "Providing": "Providing",
    "Food for the Poor": "Food for the Poor",
    "Tap to Donate": "Tap to Donate",
    "Support Kids": "Support Kids",
    "Support Annjke's medical expenses": "Support Annjke's medical expenses",
    "Read More": "Read More",
    "Goal": "Goal",
    "Apple Pay": "Apple Pay",
    "SMS": "SMS",
    "Mosque": "Mosque",
    "Well": "Well",
    "Class Room": "Class Room",
    "Ambulance": "Ambulance",
    "Providing ": "Providing ",
    "Raised": "Raised",
    "Country": "Country",
    "Haaj for Individual": "Haaj for Individual",
    "Cow": "Cow",
    "Building Construction": "Building Construction",
    "Air Conditioner": "Air Conditioner",
    "Project Description": "Project Description",
    "Digging a well that will cover a sub urban area": "Digging a well that will cover a sub urban area",
    "Donate now": "Donate now",
    "Add to bag": "Add to bag",
    "Donate by SMS": "Donate by SMS",
    "Google Pay": "Google Pay",
    "Categories": "Categories",
    "Sponsorships": "Sponsorships",
    "Sukuk": "Sukuk",
    "Humanitarian Cases": "Humanitarian Cases",
    "6 Months": "6 Months",
    "1 Year": "1 Year",
    "2 Years": "2 Years",
    "3 Years": "3 Years",
    "Medical Suk": "Medical Suk",
    "Daily": "Daily",
    "Monthly": "Monthly",
    "Yearly": "Yearly",
    "My Bag": "My Bag",
    "Donation Summary": "Donation Summary",
    "Profile": "Profile",
    "Email, Mobile, ID, File Number": "Email, Mobile, ID, File Number",
    "Password": "Password",
    "Full Name": "Full Name",
    "Email": "Email",
    "Confirm Password": "Confirm Password",
    "Kindly Select the Payment Option": "Kindly Select the Payment Option",
    "Search for a donation..": "Search for a donation..",
    "Winter Clothes": "Winter Clothes",
    "Student desks": "Student desks",
    "DONATE NOW": "DONATE NOW",
    "Total Donation": "Total Donation",
    "Select the Project": "Select the Project",
    "Success!": "Success!",
    "Logged in as": "Logged in as",


    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum ligula sem, ut commodo diam gravida eu. Donec at mollis ex. Duis vel nisi non neque accumsan lacinia eu eget velit. Nam ac lorem sem. Nulla pretium sodales ultricies. Morbi elementum enim vel nisi lacinia, eget auctor nisi congue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec egestas neque sed imperdiet malesuada.",

    "": ""



}