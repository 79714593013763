import { GET_ALL_HEALTHS } from "../../constants/actionTypes";

export default (vlnHealths = [], action) => {
  switch (action.type) {
    case GET_ALL_HEALTHS:
      return action.payload || [];
    default:
      return vlnHealths || [];
  }
};
