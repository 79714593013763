import React, { useState, useEffect } from "react";
import SwiperCore, { Pagination, Mousewheel } from "swiper";
import DonationItemBag from "../myBagDisplay/donationItemBag/DonationItemBag";
import ProjectItemBag from "../myBagDisplay/projectItemBag/ProjectItemBag";
import SukukItemBag from "../myBagDisplay/sukukItemBag/SukukItemBag";
import HumanItemBag from "../myBagDisplay/humanItemBag/HumanItemBag";
import OrphanItemBag from "./orphanItemBag/OrphanItemBag";
import CampainItemBag from "./campainItemBag/CampainItemBag";
import LatePaymentItemBag from "./latePaymentItemBag/LatePaymentItemBag";
import "../MyBag.css";
import "./MyBagDisplay.css";
import { useLocation, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { addToBag, deleteFromBag } from "../../../actions/BagFav";
import Loading from "../../ui/loading";
import ShareHeader from "../../LoaclComponents/shareHeader/ShareHeader";
import DonationFinalPopUp from "../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import { toastifyMessage } from "../../../handlers/toastifyMessage";

const MyBagDisplay = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [modalShow, setModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const location = useLocation();
  const history = useHistory();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [change, setChange] = useState(0);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);
  const dispatch = useDispatch();
  const userBag = useSelector((state) => state.bag.bag);
  const loading = useSelector((state) => state.bag.loading);
  const [bagLoading, setBagLoading] = useState(false);
  const [bag, setBAg] = useState(userLogin ? userBag : currentProfile?.cart);

  const { pathname } = useLocation();

  let totalOfDonationsArr = currentProfile?.cart?.donations?.map((item) =>
    parseInt(item.amount)
  );
  let totalOfDonations = 0;
  let totalOfProjectsArr = 0;
  let totalOfProjects = 0;
  let totalOfSukuArr = 0;
  let totalOfSuku = 0;
  let totalOfhumanArr = 0;
  let totalOfhuman = 0;
  let totalOforphansArr = 0;
  let totalOforphans = 0;
  let totalLatePayment = 0;
  let totalLatePaymentArr = 0;
  let totalRenewalsArr = 0;
  let totalRenewals = 0;

  const [totalInBag, setTotalInBag] = useState(0);
  useEffect(() => {
    if (userLogin) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      totalOfDonationsArr = userBag?.donations?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfDonations = totalOfDonationsArr?.reduce(reducer, 0);

      totalOfProjectsArr = userBag?.projects?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfProjects = totalOfProjectsArr?.reduce(reducer, 0);

      totalOfSukuArr = userBag?.sukuks?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfSuku = totalOfSukuArr?.reduce(reducer, 0);

      totalOfhumanArr = userBag?.humanCases?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOfhuman = totalOfhumanArr?.reduce(reducer, 0);

      totalOforphansArr = userBag?.orphans?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalOforphans = totalOforphansArr?.reduce(reducer, 0);

      totalLatePaymentArr = userBag?.latePayments?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalLatePayment = totalLatePaymentArr?.reduce(reducer, 0);

      totalRenewalsArr = userBag?.renewalPayments?.map((item) =>
        parseInt(item.displayAmount)
      );
      totalRenewals = totalRenewalsArr?.reduce(reducer, 0);

      setTotalInBag(
        totalOfDonations +
          totalOfProjects +
          totalOfSuku +
          totalOfhuman +
          totalOforphans +
          totalLatePayment +
          totalRenewals
      );
    } else if (currentProfile) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      totalOfDonations = totalOfDonationsArr?.reduce(reducer, 0);

      totalOfProjectsArr = currentProfile?.cart?.projects?.map((item) =>
        parseInt(item.projectAmount)
      );
      totalOfProjects = totalOfProjectsArr?.reduce(reducer, 0);

      totalOfSukuArr = currentProfile?.cart?.sukuks?.map((item) =>
        parseInt(item.amount)
      );
      totalOfSuku = totalOfSukuArr?.reduce(reducer, 0);

      totalOfhumanArr = currentProfile?.cart?.humanCases?.map((item) =>
        parseInt(item.donationAmountFromUser)
      );
      totalOfhuman = totalOfhumanArr?.reduce(reducer, 0);

      totalOforphansArr = currentProfile?.cart?.orphans?.map((item) =>
        parseInt(item.donationAmountFromUser)
      );
      totalOforphans = totalOforphansArr?.reduce(reducer, 0);

      setTotalInBag(
        totalOfDonations +
          totalOfProjects +
          totalOfSuku +
          totalOfhuman +
          totalOforphans
      );
    } else {
      setTotalInBag(0);
    }
  }, [userBag, change, fetchLoaclly]);

  useEffect(() => {
    if (userLogin) {
      setBAg(userBag);
    } else if (currentProfile) {
      let newBag = {
        donations: [],
        projects: [],
        sukuks: [],
        humanCases: [],
        orphans: [],
        latePayments: [],
      };
      for (const property in currentProfile?.cart) {
        for (let i = 0; i < currentProfile?.cart[property].length; i++) {
          if (property === "donations") {
            let index = newBag.donations.findIndex(
              (x) =>
                currentProfile?.cart[property][i].donationId === x.donationId &&
                currentProfile?.cart[property][i].donationFreq ===
                  x.donationFreq
            );
            if (index < 0) {
              newBag.donations.push({
                ...currentProfile?.cart[property][i],
                quantity: 1,
              });
            } else {
              newBag.donations.splice(index, 1, {
                ...currentProfile?.cart[property][i],
                quantity: newBag.donations[index].quantity + 1,
                amount:
                  parseInt(newBag.donations[index].amount) +
                  parseInt(currentProfile?.cart[property][i].amount),
              });
            }
          } else if (property === "projects") {
            let index = newBag.projects.findIndex(
              (x) =>
                currentProfile?.cart[property][i].projectId === x.projectId &&
                parseInt(currentProfile?.cart[property][i].projectYear) ===
                  parseInt(x.projectYear)
            );
            if (index < 0) {
              newBag.projects.push({
                ...currentProfile?.cart[property][i],
                quantity: 1,
              });
            } else {
              newBag.projects.splice(index, 1, {
                ...currentProfile?.cart[property][i],
                quantity: newBag.projects[index].quantity + 1,
                projectAmount:
                  parseInt(newBag.projects[index].projectAmount) +
                  parseInt(currentProfile?.cart[property][i].projectAmount),
              });
            }
          } else if (property === "sukuks") {
            let index = newBag.sukuks.findIndex(
              (x) =>
                currentProfile?.cart[property][i].sukId === x.sukId &&
                currentProfile?.cart[property][i].donationFreq ===
                  x.donationFreq
            );
            if (index < 0) {
              newBag.sukuks.push({
                ...currentProfile?.cart[property][i],
                quantity: 1,
              });
            } else {
              newBag.sukuks.splice(index, 1, {
                ...currentProfile?.cart[property][i],
                quantity: newBag.sukuks[index].quantity + 1,
                amount:
                  parseInt(newBag.sukuks[index].amount) +
                  parseInt(currentProfile?.cart[property][i].amount),
              });
            }
          } else if (property === "humanCases") {
            let index = newBag.humanCases.findIndex(
              (x) => currentProfile?.cart[property][i].humanId === x.humanId
            );
            if (index < 0) {
              newBag.humanCases.push(currentProfile?.cart[property][i]);
            } else {
              newBag.humanCases.splice(index, 1, {
                ...currentProfile?.cart[property][i],
                donationAmountFromUser:
                  parseInt(newBag.humanCases[index].donationAmountFromUser) +
                  parseInt(
                    currentProfile?.cart[property][i].donationAmountFromUser
                  ),
              });
            }
          } else if (property === "orphans") {
            let index = newBag.orphans.findIndex(
              (x) =>
                currentProfile?.cart[property][i].orphanId === x.orphanId &&
                currentProfile?.cart[property][i].orphanCountryId ===
                  x.orphanCountryId &&
                parseInt(currentProfile?.cart[property][i].baseAmount) ===
                  parseInt(x.baseAmount) &&
                currentProfile?.cart[property][i].paymentWay === x.paymentWay &&
                currentProfile?.cart[property][i].years === x.years
            );
            if (index < 0) {
              newBag.orphans.push({
                ...currentProfile?.cart[property][i],
                quantity: 1,
              });
            } else {
              newBag.orphans.splice(index, 1, {
                ...currentProfile?.cart[property][i],
                quantity: newBag.orphans[index].quantity + 1,
                donationAmountFromUser:
                  parseInt(newBag.orphans[index].donationAmountFromUser) +
                  parseInt(
                    currentProfile?.cart[property][i].donationAmountFromUser
                  ),
              });
            }
          }
        }
      }
      setBAg(newBag);
    } else {
      setBAg([]);
    }
  }, [userLogin, userBag, change, loading, props.bagChange, fetchLoaclly]);

  function donateNow() {
    history.push("/checkout", { from: pathname });
  }
  const [width, setWidth] = useState(window.innerWidth);

  const handleGuestPopUp = async (options) => {
    if (userLogin || visitor) {
      options();
    } else {
      setFunctionToSend(() => options);
      setGuestModal(true);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div
      id="my_bag_left_side"
      style={
        lng === "arab"
          ? { borderLeft: "1px solid rgba(128, 128, 128, 0.336)" }
          : { borderRight: "1px solid rgba(128, 128, 128, 0.336)" }
      }
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h5 className="title_to_be_none" style={{ fontWeight: "bold" }}>
          {t("Donations")}
        </h5>
        <button
          disabled={loading}
          onClick={() => props.removeAllCart()}
          style={
            width > 900
              ? {
                  position: "absolute",
                  right: "5px",
                  border: "none",
                  backgroundColor: "transparent",
                }
              : { display: "none" }
          }
        >
          <i
            style={{ fontSize: "27px", color: "#ee4236" }}
            className=" backHeader fas fa-trash"
          ></i>
        </button>
      </div>
      {loading ? (
        <Loading height="calc(100vh - 300px)" />
      ) : totalInBag > 0 ? (
        <div style={{ width: "100%" }}>
          <div className="items_on_bag_display">
            <div className="forMob_bag">
              {bag?.renewalPayments?.map((item, i) => (
                <LatePaymentItemBag
                  allItem={item}
                  bag={bag}
                  key={i}
                  renewalItem={true}
                />
              ))}
              {bag?.latePayments?.map((item, i) => (
                <LatePaymentItemBag allItem={item} bag={bag} key={i} />
              ))}
              {bag?.donations?.map((item, i) =>
                item?.campName ? (
                  <CampainItemBag
                    key={`camp_${i}`}
                    donationId={item.donationId}
                    title={item.title}
                    campainName={item.campName}
                    realAmount={item.realAmount}
                    amount={item.amount}
                    itemImg={item.img}
                    change={change}
                    setChange={setChange}
                    currentProfile={currentProfile}
                  />
                ) : (
                  <DonationItemBag
                    key={`don_${i}`}
                    status={userLogin ? (item.valid ? null : "not") : null}
                    donationId={userLogin ? item.dontypeID : item.donationId}
                    title={userLogin ? item.name : item.title}
                    amount={userLogin ? item.displayAmount : item.amount}
                    itemImg={userLogin ? item.imagePath : item.img}
                    recurring={
                      item.recurring
                        ? item.recurring
                        : item.donationFreq
                        ? item.donationFreq
                        : "N"
                    }
                    baseAmount={item.baseAmount}
                    cart_serial={userLogin ? item.cart_serial : null}
                    change={change}
                    quantity={parseInt(item.quantity)}
                    setChange={setChange}
                    currentProfile={currentProfile}
                    bag={bag}
                    item={item}
                  />
                )
              )}
              {bag?.projects?.map((item, i) => (
                <ProjectItemBag
                  key={`proj${i}`}
                  projectId={userLogin ? item.projectID : item.projectId}
                  status={userLogin ? (item.valid ? null : "not") : null}
                  baseAmount={userLogin ? item.baseAmount : item.baseAmount}
                  projectAmount={
                    userLogin ? item.displayAmount : item.projectAmount
                  }
                  projectYear={userLogin ? item.projectYear : item.projectYear}
                  projectPhoto={userLogin ? item.projectImage : item.projectImg}
                  title={userLogin ? item.projectName : item.projectName}
                  countryTitle={userLogin ? item.countryName : item.countryName}
                  flag={userLogin ? item.countryImage : item.countryImg}
                  cart_serial={userLogin ? item.cart_serial : item.dateOfAdding}
                  quantity={parseInt(item.quantity)}
                  countryId={userLogin ? item.countryID : item.countryId}
                  change={change}
                  bag={bag}
                  setChange={setChange}
                  currentProfile={currentProfile}
                  item={item}
                />
              ))}
              {bag?.sukuks?.map((item, i) => (
                <SukukItemBag
                  key={`suk_${i}`}
                  sukId={userLogin ? item.dontypeID : item.sukId}
                  status={userLogin ? (item.valid ? null : "not") : null}
                  sukukImg={userLogin ? item.imagePath : item.img}
                  title={userLogin ? item.name : item.title}
                  donationFreq={userLogin ? item.recurring : item.donationFreq}
                  amount={userLogin ? item.displayAmount : item.amount}
                  baseAmount={item.baseAmount}
                  cart_serial={userLogin ? item.cart_serial : null}
                  change={change}
                  bag={bag}
                  setChange={setChange}
                  currentProfile={currentProfile}
                  baseFreq={userLogin ? null : item.baseFreq}
                  quantity={parseInt(item.quantity)}
                  item={item}
                />
              ))}
              {bag?.humanCases.map((item, i) => (
                <HumanItemBag
                  key={`human${i}`}
                  donationAmountFromUser={
                    userLogin ? item.displayAmount : item.donationAmountFromUser
                  }
                  status={userLogin ? (item.valid ? null : "not") : null}
                  fromMyBag={true}
                  ID={userLogin ? item.humanCaseID : item.humanId}
                  humanImg={userLogin ? item.imagePath : item.humanImg}
                  title={userLogin ? item.caseTitle : item.title}
                  raised={userLogin ? item.amountCollected : item.total}
                  goal={userLogin ? item.totalAmount : item.finalAmount}
                  baseAmount={item.baseAmount}
                  change={change}
                  bag={bag}
                  setChange={setChange}
                  quantity={parseInt(item.quantity)}
                  currentProfile={currentProfile}
                  item={item}
                />
              ))}
              {bag?.orphans.map((item, i) => (
                <OrphanItemBag
                  key={`orphan_${i}`}
                  donationAmountFromUser={
                    userLogin ? item.displayAmount : item.donationAmountFromUser
                  }
                  status={userLogin ? (item.valid ? null : "not") : null}
                  orphanCountryId={
                    userLogin ? item.countryID : item.orphanCountryId
                  }
                  orphanCountryImg={
                    userLogin ? item.countryImage : item.orphanCountryImg
                  }
                  orphanCountryName={
                    userLogin ? item.countryName : item.orphanCountryName
                  }
                  orphanId={userLogin ? item.orphanTypeID : item.orphanId}
                  orphanImg={userLogin ? item.orpTypeImage : item.orphanImg}
                  orphanName={userLogin ? item.orpTypeName : item.orphanName}
                  paymentWay={userLogin ? item.recurring : item.paymentWay}
                  quantity={parseInt(item.quantity)}
                  cart_serial={userLogin ? item.cart_serial : item.dateOfAdding}
                  baseAmount={item.baseAmount}
                  years={userLogin ? parseInt(item.month_count) : item.years}
                  change={change}
                  //dateOfAdding= {userLogin? null:item.dateOfAdding}
                  bag={bag}
                  key={i}
                  setChange={setChange}
                  currentProfile={currentProfile}
                />
              ))}
            </div>
          </div>
          <div className="total_amount_for_bag" id="donate_now_btn">
            <div className="text_of_total_amount">
              <label style={{ fontSize: width>500?"18px":'16px', textAlign: lng==='arab'? 'right':'left' }}>{t("Total Donation")}</label>
              <label style={{ fontSize: "14px" }}>
                {t("AED")}{" "}
                <span
                  className="payment_numbers"
                  style={{ fontWeight: "bold", fontSize: width>500?"20px":'17px' }}
                >
                  {isNaN(totalInBag) ? 0 : totalInBag}
                </span>
              </label>
            </div>
            <button
              onClick={() =>
                totalInBag > 0
                  ? handleGuestPopUp(donateNow)
                  : toastifyMessage("Your bag is empty!", undefined, "warning")
              }
              className="Donate_now_add_to_cart smallBtn_smallDevice"
            >
              {t("Donate Now")}
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "calc(100vh - 300px)",
            width: "100%",
          }}
        >
          <label>{t("You don't have any items in your bag")}</label>
        </div>
      )}
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
    </div>
  );
};

export default MyBagDisplay;
