import { FETCH_VOLUNTEER_FILE_DETAILS, LOAD_VOLUNTEER, VOLUNTEER_LOADED } from '../../constants/actionTypes'

export default (vlnFileDetails = { vlnFileDetails: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_VOLUNTEER_FILE_DETAILS:
            // return action.payload;
            return { ...vlnFileDetails, vlnFileDetails: action.payload, loading: false };
        case LOAD_VOLUNTEER:
            return { ...vlnFileDetails, loading: true };
        case VOLUNTEER_LOADED:
            return { ...vlnFileDetails, loading: false };
        default:
            return vlnFileDetails;
    }
}
