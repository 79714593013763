import React, { useState, useEffect } from 'react'
import './humanitarian.css'
import HumanitarianCom from './humanitarianCom/humanitarianCom'
import Carousel from 'react-bootstrap/Carousel'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { LOADING } from '../../../../constants/actionTypes'
import Loading from '../../../ui/loading'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper'
SwiperCore.use([Navigation, Autoplay, Pagination])

const Humanitarian = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem('language'))?.lang;
    const dispatch = useDispatch()
    const humanCases = useSelector(state => state.humanCases.humanCases);
    const [humanCasesDisplay, setHumanCasesDisplay] = useState(humanCases);
    useEffect(() => {
        setHumanCasesDisplay(humanCases)
    }, [humanCases])
    const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <section id='humanitarian'>
            <label className='all_section_title_hum' style={lng === 'arab' ? { fontWeight: 'bold', fontSize: '25px', width: '100%', textAlign: 'right' } : { fontSize: '25px', width: '100%', textAlign: 'left' }}>{t('Humanitarian Cases')}</label>
            {humanCasesDisplay.length > 0 ? 
            <Swiper id='human' autoplay={{delay: 15000}}  loop={true} className='please' slidesPerView={1} navigation={true} pagination={{ clickable: true }}>
                {humanCasesDisplay?.map((item,i) =>
                    <SwiperSlide style={{ padding: width<=250? '0px': '10px 10px 0px 10px' }} key={i}>
                        <HumanitarianCom
                            key={i}
                            ID={item.caseID}
                            humanImg={item.imagePath}
                            title={item.caseTitle}
                            text={item.description}
                            raised={item.amountCollected}
                            goal={item.totalCost}
                            caseYear={item.caseYear}
                        />
                    </SwiperSlide>
                )}
            </Swiper> : <Loading />}
        </section>

    )
}
export default Humanitarian