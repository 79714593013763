import React, { useState, useEffect } from "react";
import icon8 from "../../../../assets/upload.png";
import icon10 from "../../../../assets/occupation.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./vlnAttachmentsForm.css";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import { uploadEduData, uploadEidDetails, uploadIntro, uploadJobData, uploadPassData, uploadProfilePic, uploadResiData } from "../../../../actions/volunteer";
import {
  applyValidation,
  resetValidation,
} from "../../../../handlers/formValidation";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import Loading from "../../../ui/loading";
import Select from 'react-select'

//TODO Job should be dropdown
const JobForm = (props) => {
  const volunteerJobsNew = useSelector((state) => state.volunteerJobsNew);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    container: styles => ({ ...styles,  width: window.innerWidth }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const dispatch = useDispatch();
  const [idWeNeed, setIdWeNeed] = useState(props.vlnID);
  const [profileLoading, setProfileLoading] = useState(false);
  useEffect(() => {
    setIdWeNeed(props.vlnID);
  }, [props.vlnID]);

  const [loadingModalShow, setLoadingModalShow] = useState(false);

  const handleChange = (e) => {
    props.setJobInfo({...props.jobInfo, jobLicense: {...props.jobInfo.jobLicense, [e.target.name]: e.target.value}})
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoadingModalShow(true);
    let userData = {
      volunteerID: idWeNeed,
      jobLicense: {
        jobID: props.jobInfo.jobLicense.jobID,
        endDate: props.jobInfo.jobLicense.endDate.split("-").reverse().join("/"),
        fileType: props.job.fileType,
        attachment: props.job.attachment,
      },
    };
    props.setJobInfo(userData)
    // await dispatch(uploadProfilePic(props.pictureInfo, setLoadingModalShow));
    // await dispatch(uploadPassData(props.passportInfo, setLoadingModalShow));
    // await dispatch(uploadEidDetails(props.nationalInfo, setLoadingModalShow));

    // await dispatch(uploadEduData(props.educationInfo, setLoadingModalShow));
    // await dispatch(uploadIntro(props.introInfo, setLoadingModalShow));
    // if(props.volunteerNationality !== "101")
    //   await dispatch(uploadResiData(props.residenceInfo, setLoadingModalShow));
   dispatch(uploadJobData(userData, setLoadingModalShow));

    props.setView(props.view + 1)
  };
  const goBack = () => {
    props.setView(props.view - 1)
  }
  useEffect(() => {
    if(!profileLoading)
    {
      if(props.jobReader?.result)
    {
      document.getElementById(
        "for_upl"
      ).style.backgroundImage = `url(${props.jobReader.result})`;
      document.getElementById('upload_icon').style.display='none'
      document.getElementById("for_upl").style.backgroundPosition = "center";
      document.getElementById("for_upl").style.backgroundSize = "cover";
      document.getElementById("for_upl").style.border = "1px solid white";
    }
    
    }
  }, [profileLoading])
    return (
      <div
        className="local_aid_info_form_page default_min_height"
        style={lng === "arab" ? { direction: "rtl" } : null}
      >
        <label
          style={{
            padding: "10px 10px 10px 20px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {t("Insert your Job details")}
        </label>
        <form className="local_aid_info_form" onSubmit={handleSubmit}>
          <div className="uploading_input">
          {profileLoading?
          <Loading height='250px' mediaQ='200px' width='297px'/>:
            <label id="for_upl" htmlFor="myInputID">
            <img src={icon8} id='upload_icon' alt="upload" className="upload_icon" />
            </label>
          }
            <input type="file" id="myInputID" onChange={() => {props.handleUpLoadChange(setProfileLoading, props.jobReader, "myInputID", "for_upl", "fileName", "upload_icon", props.setJob)}} />
            <label id="fileName">{t("Upload your Job License")}</label>
          </div>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon10} className="local_aid_icon" alt="form_icon" />
            </div>
            <Select placeholder={t("Job")} onChange={(e) => handleChange({target: {value: e.value, name: "jobID"}})} styles={customStyles} options={volunteerJobsNew.map((item) => {
                  return {value: item.job_id, label: item.job_name}
                })} />
          </div>
          <label
            style={{ width: "90%", alignSelf: "center", marginBottom: "-25px" }}
          >
            {t("End Date")}
          </label>
          <div className="input_wraper" style={{ alignSelf: "center" }}>
            <div className="local_aid_input_icon_wraper">
              <img src={icon10} className="local_aid_icon" alt="form_icon" />
            </div>
            <input
              type="date"
              onChange={handleChange}
              className="local_aid_input"
              value={props.jobInfo.jobLicense.endDate? props.jobInfo.jobLicense.endDate.split("/").reverse().join("-"): props.jobInfo.jobLicense.endDate}
              name="endDate"
              id="endDate"
              placeholder={t("End Date")}
              required
              onInvalid={applyValidation}
              onInput={resetValidation}
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              min={`${new Date().getFullYear() - 100}-01-02`}
            />
          </div>
          <button
            id="submitBtn_for_local_aid"
            type="submit"
            className="local_aid_submit"
          >
            <span style={{ flex: "16" }}>{t("Submit")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
          <button
            id="submitBtn_for_local_aid"
            className="local_aid_submit"
            onClick={() => goBack()}
          >
            <span style={{ flex: "16" }}>{t("Go Back")}</span>
            {lng === "arab" ? (
              <i className="fas fa-arrow-left"></i>
            ) : (
              <i className="fas fa-arrow-right"></i>
            )}
          </button>
        </form>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
        />
      </div>
    );
};

export default JobForm;
