import * as api from "../api";
import {
  BAG_LOADED,
  FETCH_ALL_BAG_ITEMS,
  LOAD_BAG,
} from "../constants/actionTypes";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const handleSamsungPayment = async (body) => {
  if (body.amount < 1) {
    toastifyMessage("Please select an amount to donate", undefined, "error");
    return;
  }
  var callback = new URL(body.url);

  var params = {
    amount: body.amount,
    serviceId: process.env.REACT_APP_SAMSUNG_SERVICE_ID,
    callback: callback.origin + "?status=checkout",
    cancel: callback.origin + "/checkout" + "?status=samsung_fail",
    countryCode: "AE",
  };
  //will redirect if successful to Samsung Pay page
  await api
    .validateSamsungPay(body)
    .then((response) => {
      var data = response.data;
      window.SamsungPay.connect(
        data.id,
        data.href,
        params.serviceId,
        params.callback,
        params.cancel,
        params.countryCode,
        data.encInfo.mod,
        data.encInfo.exp,
        data.encInfo.keyId
      );
    })
    .catch((error) => {
      console.error("Error:", error);
      // Added sdk in public/index.html
    });
};
export const handleSamsungDirectPayment = async (body) => {
  if (body.item.length > 1) {
    let itemsAboveFive = body.item.filter((item) => item.amount >= 5);
    if (itemsAboveFive.length < body.item.length) {
      toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
      return;
    }
  }
  if (body.amount < 5) {
    toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
    return;
  }
  localStorage.setItem("samsungDirectPay", JSON.stringify({ item: body.item }));
  var callback = new URL(body.url);
  body.url = callback.origin;
  var params = {
    amount: body.amount,
    serviceId: process.env.REACT_APP_SAMSUNG_SERVICE_ID,
    callback: callback.origin + "?status=direct",
    cancel: callback.origin + "?status=samsung_fail",
    countryCode: "AE",
  };

  //will redirect if successful to Samsung Pay page
  await api
    .validateSamsungPay(body)
    .then((response) => {
      var data = response.data;
      window.SamsungPay.connect(
        data.id,
        data.href,
        params.serviceId,
        params.callback,
        params.cancel,
        params.countryCode,
        data.encInfo.mod,
        data.encInfo.exp,
        data.encInfo.keyId
      );
    })
    .catch((error) => {
      console.error("Error:", error);
      // Added sdk in public/index.html
    });
};
export const getSamsungToken = async (body) => {
  var url = new URL(window.location.href);
  var ref_id = url.searchParams.get("ref_id");
  var result = {};

  if (ref_id) {
    await api.getSamsungPay(ref_id).then((response) => {
      result = response.data;
    });
  } else result.status = false;
  return result;
};
export const completeSamsungPay = async (body, dispatch, recieptFor) => {
  try {
    var { data } = await api.regularPayment(body);
    if (data.status === "OK" || data.status === "Payed") {
      dispatch({ type: LOAD_BAG });

      //let oldProfile = localStorage.getItem()
      let oldProfile = JSON.parse(localStorage.getItem("profile"));
      if (oldProfile && recieptFor === "many") {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            ...oldProfile,
            cart: {
              donations: [],
              sukuks: [],
              projects: [],
              humanCases: [],
              orphans: [],
            },
            liked: oldProfile.liked,
          })
        );
      }
      var res = await api.confirmPayment({
        MCO: data.Mastercard_order_id,
        token: "",
      });
      //dispatch({ type: FETCH_ALL_BAG_ITEMS, payload: res.data.data });
      dispatch({ type: BAG_LOADED });
      return { status: true, mco: data.Mastercard_order_id };
    } else {
      toastifyMessage("Something went wrong...!", undefined, "error");
    }
  } catch (error) {
    toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
