/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import donateIcon from "../../../../assets/donateIconForBannerWhite2.png";
import SMSIcon from "../../../../assets/sms.png";
import { FETCH_LOCALLY } from "../../../../constants/actionTypes";
import "./PopUpPayment.css";
import { useTranslation } from "react-i18next";
import {
  addToBag,
  deleteFromBag,
  handleAddToBag,
} from "../../../../actions/BagFav";
import samPay from "../../../../assets/Pay_button_basic_rev_RGB.png";
import { handleSamsungDirectPayment } from "../../../../actions/handleSamsungPayment";
import { toastifyMessage } from "../../../../handlers/toastifyMessage";
import { startApplePay } from "../../../../actions/handleApplePayment";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../../ui/loading";
import SmsModal from "../../../ui/smsModal/SmsModal";
import LoadingModal from "../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import DonationFinalPopUp from "../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";

const PopUpPayment = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [modalShow, setModalShow] = useState(false);
  const userBag = useSelector((state) => state.bag.bag);
  const [bagDetails, setBagDetails] = useState(userBag);
  const [addingToCart, setAddingToCart] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [smsModalShow, setSmsModalShow] = useState(false);
  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  const donations = useSelector((state) => state.donations.donations);
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const [change, setChange] = useState(0);
  const [inTheBag, setInTheBag] = useState(0);
  const fetchLoaclly = useSelector((state) => state.fetchLocallyState);
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem("profile"));
  }, [change, fetchLoaclly]);
  const [realAmountForOurDonation, setRealAmountForOurDonation] = useState(
    parseInt(props.amount)
  );

  const [donationData, setDonationData] = useState({
    amount: parseInt(props.amount),
    donationId: props.donationid,
    img: props.img,
    title: props.title,
    donationFreq: "N",
  });
  const [humanCaseData, setHumanCaseData] = useState({
    humanId: props.donationid,
    title: props.title,
    total: props.total,
    finalAmount: props.finalAmount,
    humanImg: props.img,
    donationAmountFromUser: 0,
    caseYear: props.caseYear,
  });
  // useEffect(() => {
  //   if (!props.from === "humanCases") {
  //     let donationWeNeed = donations
  //       .filter((item) => item.dontypeID === props.donationid)
  //       .slice(0, 1)
  //       .shift();
  //     setRealAmountForOurDonation(parseInt(donationWeNeed?.amount));
  //   }
  // }, [donations, props.donationid]);
  useEffect(() => {
    if (props.from === "humanCases") {
      setHumanCaseData({
        humanId: props.donationid,
        title: props.title,
        total: props.total,
        finalAmount: props.finalAmount,
        humanImg: props.img,
        donationAmountFromUser: "10",
        caseYear: props.caseYear,
      });
    } else {
      setDonationData({
        amount: parseInt(props.amount),
        donationId: props.donationid,
        img: props.img,
        title: props.title,
        donationFreq: "N",
      });
      setRealAmountForOurDonation(parseInt(props.amount));
    }
  }, [props]);

  useEffect(() => {
    if (props.from === "humanCases") {
      if (!userLogin) {
        if (
          currentProfile?.cart?.humanCases?.filter(
            (item) =>
              item.humanId === props.donationid &&
              parseInt(item.baseAmount) ===
                parseInt(humanCaseData.donationAmountFromUser)
          ).length > 0
        ) {
          setInTheBag(
            currentProfile?.cart?.humanCases?.filter(
              (item) =>
                item.humanId === props.donationid &&
                parseInt(item.baseAmount) ===
                  parseInt(humanCaseData.donationAmountFromUser)
            ).length
          );
        }
      } else {
        if (
          bagDetails?.humanCases?.filter(
            (item) => item.humanCaseID === props.donationid
          ).length > 0
        ) {
          setInTheBag(
            bagDetails?.humanCases?.filter(
              (item) => item.humanCaseID === props.donationid
            ).length
          );
        }
      }
    } else {
      if (!userLogin) {
        if (
          props.currentprofile?.cart?.donations?.filter(
            (item) =>
              item.donationId === props.donationid && item.donationFreq === "N"
          ).length > 0
        ) {
          setInTheBag(
            props.currentprofile?.cart?.donations?.filter(
              (item) =>
                item.donationId === props.donationid &&
                item.donationFreq === "N"
            ).length
          );
        }
      } else {
        if (
          bagDetails?.donations
            ?.filter(
              (item) =>
                item.dontypeID === props.donationid &&
                item.recurring === "N" &&
                parseInt(item.baseAmount) === parseInt(donationData.amount)
            )
            .slice(0)
            .shift()?.quantity
        ) {
          setInTheBag(
            bagDetails?.donations
              ?.filter(
                (item) =>
                  item.dontypeID === props.donationid &&
                  item.recurring === "N" &&
                  parseInt(item.baseAmount) === parseInt(donationData.amount)
              )
              .slice(0)
              .shift()?.quantity
          );
        } else {
          setInTheBag(0);
        }
      }
    }
  }, [
    props.donationid,
    props.currentprofile,
    currentProfile,
    bagDetails,
    userLogin,
    donationData.amount,
    humanCaseData.donationAmountFromUser,
  ]);

  const handleClick = (op) => {
    if (donationData.amount) {
      if (op === "-") {
        if (donationData.amount > realAmountForOurDonation) {
          setDonationData({
            ...donationData,
            amount:
              parseInt(donationData.amount) -
              parseInt(realAmountForOurDonation),
          });
        }
      } else {
        setDonationData({
          ...donationData,
          amount:
            parseInt(donationData.amount) + parseInt(realAmountForOurDonation),
        });
      }
    }
  };
  const handleApplePay = (donationID) => {
    if (props.from === "humanCases") {
      startApplePay(
        {
          cart: [
            {
              donID: "158",
              itemType: 4,
              amount: donationID.donationAmountFromUser,
              cart_serial: null,
              humanCase: donationID.humanId,
              humanYear: donationID.caseYear,
            },
          ],
          amount: donationID.donationAmountFromUser,
        },
        dispatch,
        history
      );
    } else {
      startApplePay(
        {
          cart: [
            {
              dontype_id: donationID.donationId,
              item_type: 0,
              amount: donationID.amount,
              recuring: donationID.recuring,
              cart_serial: null,
            },
          ],
          amount: donationID.amount,
        },
        dispatch,
        history
      );
    }
  };
  const donateNow = async (donationID) => {
    if (donationID.amount >= 5) {
      if (userLogin || visitor) {
        await handleApplePay(donationID);
      } else {
        setModalShow(true);
      }
    } else {
      toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
    }
  };

  const handleChoosenAmountByBtn = (donation) => {
    setDonationData({ ...donationData, amount: donation });
  };
  const handleaddToBag = async (donationID) => {
    if (props.from !== "humanCases") {
      if (donationID.amount >= 5) {
        setAddingToCart(true);
        await handleAddToBag(
          "donations",
          donationID,
          setChange,
          change,
          dispatch
        );
        if (_isMounted.current) {
          setAddingToCart(false);
        }
        props.onHide();
      } else {
        return toastifyMessage(
          "Can not pay less than 5 AED",
          undefined,
          "warning"
        );
      }
    } else {
      if (donationID.donationAmountFromUser > 0) {
        if (!userLogin) {
          if (!currentProfile) {
            localStorage.setItem(
              "profile",
              JSON.stringify({
                cart: {
                  donations: [],
                  sukuks: [],
                  projects: [],
                  humanCases: [],
                  orphans: [],
                },
                liked: { donations: [], sukuks: [], projects: [], orphans: [] },
              })
            );
            currentProfile = JSON.parse(localStorage.getItem("profile"));
          }
          currentProfile.cart?.humanCases.push({
            ...donationID,
            donationAmountFromUser: parseInt(donationID.donationAmountFromUser),
            baseAmount: parseInt(donationID.donationAmountFromUser),
          });
          const newProfile = {
            cart: currentProfile.cart,
            liked: currentProfile.liked,
          };
          localStorage.setItem("profile", JSON.stringify(newProfile));
          await dispatch({ type: FETCH_LOCALLY });
          setChange(change + 1);
          toastifyMessage("Added!", undefined, "success");
          //props.setchange(props.change+1)
          props.onHide();
        } else {
          setAddingToCart(true);
          await dispatch(
            addToBag(
              {
                cart: {
                  donID: "158",
                  userName: userLogin.Results[0]?.name_a,
                  userMob: userLogin.Results[0]?.mobile_no,
                  userEmail: userLogin.Results[0]?.email,
                  itemType: 4,
                  amount: parseInt(donationID.donationAmountFromUser),
                  cart_serial: null,
                  humanCase: donationID.humanId,
                  humanYear: donationID.caseYear,
                },
                language: lng === "arab" ? 1 : 2,
              },
              props.onHide
            )
          );
          setAddingToCart(false);
        }
      }
    }
  };

  // const handleRemoveFromBag = async (donationID) => {
  //     if (props.from !== 'humanCases') {
  //         if (!userLogin) {
  //             let newProfile = { cart: { donations: currentProfile.cart.donations.filter(item => !(item.donationId === donationID.donationId && parseInt(item.baseAmount)=== parseInt(donationID.amount) && item.donationFreq===donationID.donationFreq)), sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases, orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
  //             localStorage.setItem('profile', JSON.stringify(newProfile))
  //             setChange(change + 1)
  //             props.setchange(props.change + 1)
  //             props.onHide()
  //         } else {
  //             setAddingToCart(true)
  //             let elementWeNeed = bagDetails?.donations?.filter(item => item.dontypeID === donationID.donationId && parseInt(item.baseAmount)=== parseInt(donationID.amount) && item.recurring===donationID.donationFreq).slice(0, 1).shift()
  //             if (elementWeNeed) {
  //                 await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "eng" ? 2 : 1, props.onHide))
  //             }
  //             setAddingToCart(false)
  //         }
  //     } else {
  //         if (!userLogin) {
  //             let newProfile = { cart: { donations: currentProfile.cart.donations, sukuks: currentProfile.cart.sukuks, projects: currentProfile.cart.projects, humanCases: currentProfile.cart.humanCases.filter(item => !(item.humanId === donationID.humanId && parseInt(item.baseAmount) === parseInt(donationID.donationAmountFromUser))), orphans: currentProfile.cart.orphans }, liked: currentProfile.liked }
  //             localStorage.setItem('profile', JSON.stringify(newProfile))
  //             setChange(change + 1)
  //             //props.setchange(props.change+1)
  //             props.onHide()
  //         } else {
  //             setAddingToCart(true)
  //             let elementWeNeed = bagDetails?.humanCases.filter(item => item.humanCaseID === donationID.humanId && parseInt(item.baseAmount) === parseInt(donationID.donationAmountFromUser)).slice(0, 1).shift()
  //             if (elementWeNeed) {
  //                 await dispatch(deleteFromBag(userLogin.Results[0]?.email, elementWeNeed.cart_serial, lng === "arab" ? 1 : 2, props.onHide))
  //             }
  //             setAddingToCart(false)
  //         }
  //     }
  // }
  const handleSMS = async (donationId) => {
    setSmsModalShow(true);
    //var data = await getSMS(donationId);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const startSamsungPay = async () => {
    if (donationData.amount >= 5) {
      setLoadingModalShow(true);
      await handleSamsungDirectPayment({
        amount:
          props.from === "humanCases"
            ? humanCaseData.donationAmountFromUser
            : donationData.amount,
        url: window.location.href,
        item:
          props.from === "humanCases"
            ? [
                {
                  humanCase: humanCaseData.humanId,
                  dontype_id: 158,
                  amount: humanCaseData.donationAmountFromUser,
                  humanYear: humanCaseData.caseYear,
                  item_type: 4,
                },
              ]
            : [
                {
                  amount: donationData.amount,
                  donationId: donationData.donationId,

                  recurring: donationData.donationFreq,
                  item_type: 0,
                },
              ],
      });
      setLoadingModalShow(false);
    } else {
      toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
    }
  };
  const handleGuestPopUp = async (options) => {
    if (donationData.amount >= 5) {
      if (userLogin || visitor) {
        options();
      } else {
        setFunctionToSend(() => options);
        setGuestModal(true);
      }
    } else {
      toastifyMessage("Can not pay less than 5 AED", undefined, "warning");
    }
  };

  return (
    <Modal {...props} size="lg" centered className="popUp">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body
        style={{ fontFamily: lng === "arab" ? "'arab_font" : "english_font" }}
      >
        {props.from === "humanCases" ? (
          <svg
            height="180"
            width="180"
            version="1.1"
            xmlns="http://www.we.org/2000/svg"
          >
            <g transform="translate(0,20)">
              <defs>
                <pattern
                  viewBox="0 0 1 1"
                  preserveAspectRatio="xMidYMid slice"
                  id={`img_${props.ID}`}
                  x="0"
                  y="0"
                  patternUnits="objectBoundingBox"
                  height="100%"
                  width="100%"
                >
                  <image
                    width="1"
                    height="1"
                    preserveAspectRatio="xMidYMid slice"
                    className="img"
                    x="0"
                    y="0"
                    href={props.img}
                    xlinkHref={props.img}
                  ></image>
                </pattern>
              </defs>
              <circle cx="90" cy="73" r="80" fill={`url(#img_${props.ID})`} />
              <circle
                className="circle-bg"
                cx="90"
                cy="73"
                strokeWidth="7"
                r="80"
              />
              <circle
                className="circle"
                cx="90"
                cy="73"
                r="80"
                stroke="red"
                strokeWidth="7"
                strokeDasharray={`calc(${props.presentage} * ${
                  3.14 * 2 * 80
                }) calc(${3.14 * 2 * 80})`}
              />
            </g>
          </svg>
        ) : (
          <div className="donation_item">
            <div
              style={{
                backgroundImage: `url(${props.img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: width >= 900 ? "160px" : "120px",
                width: width >= 900 ? "200px" : "160px",
              }}
              className="donation_img_forBanner"
            ></div>
          </div>
        )}
        {props.from !== "humanCases" ? (
          <div className="choosen_amount_for_mob">
            <button
              disabled={addingToCart}
              style={
                parseInt(donationData.amount) === realAmountForOurDonation
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : { backgroundColor: "white", color: "black" }
              }
              onClick={() => handleChoosenAmountByBtn(realAmountForOurDonation)}
              className="spin_popUp_for_mob donationPopUpBtn"
            >
              <span style={{ fontSize: "10px", opacity: "0.7" }}>
                {t("AED")}
              </span>
              <span
                className="payment_numbers"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {realAmountForOurDonation}
              </span>
            </button>
            <button
              disabled={addingToCart}
              style={
                parseInt(donationData.amount) === realAmountForOurDonation * 2
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : { backgroundColor: "white", color: "black" }
              }
              onClick={() =>
                handleChoosenAmountByBtn(realAmountForOurDonation * 2)
              }
              className="spin_popUp_for_mob donationPopUpBtn"
            >
              <span style={{ fontSize: "10px", opacity: "0.7" }}>
                {t("AED")}
              </span>
              <span
                className="payment_numbers"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {realAmountForOurDonation * 2}
              </span>
            </button>
            <button
              disabled={addingToCart}
              style={
                parseInt(donationData.amount) === realAmountForOurDonation * 3
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : { backgroundColor: "white", color: "black" }
              }
              onClick={() =>
                handleChoosenAmountByBtn(realAmountForOurDonation * 3)
              }
              className="spin_popUp_for_mob donationPopUpBtn"
            >
              <span style={{ fontSize: "10px", opacity: "0.7" }}>
                {t("AED")}
              </span>
              <span
                className="payment_numbers"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {realAmountForOurDonation * 3}
              </span>
            </button>
            <button
              disabled={addingToCart}
              style={
                parseInt(donationData.amount) === realAmountForOurDonation * 4
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : { backgroundColor: "white", color: "black" }
              }
              onClick={() =>
                handleChoosenAmountByBtn(realAmountForOurDonation * 4)
              }
              className="spin_popUp_for_mob donationPopUpBtn"
            >
              <span style={{ fontSize: "10px", opacity: "0.7" }}>
                {t("AED")}
              </span>
              <span
                className="payment_numbers"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {realAmountForOurDonation * 4}
              </span>
            </button>
            <button
              disabled={addingToCart}
              style={
                parseInt(donationData.amount) === realAmountForOurDonation * 5
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : { backgroundColor: "white", color: "black" }
              }
              onClick={() =>
                handleChoosenAmountByBtn(realAmountForOurDonation * 5)
              }
              className="spin_popUp_for_mob donationPopUpBtn"
            >
              <span style={{ fontSize: "10px", opacity: "0.7" }}>
                {t("AED")}
              </span>
              <span
                className="payment_numbers"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                {realAmountForOurDonation * 5}
              </span>
            </button>
          </div>
        ) : null}

        <div
          style={{ justifyContent: "center", gap: "20px" }}
          className="sukuk_inc_Dec"
        >
          {props.from === "humanCases" ? null : (
            <button
              disabled={addingToCart}
              style={{ backgroundColor: "white", color: "black" }}
              className="sukukdec btn btn-light"
              onClick={() => handleClick("-")}
            >
              -
            </button>
          )}
          {props.edit_flag ? (
            <div
              style={{
                padding: "5px 30px 5px 30px",
                borderRadius: width <= 250 ? "10px" : "30px",
                minWidth: "40px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                backgroundColor: "white",
                flexDirection: width <= 250 ? "column" : "row",
              }}
            >
              {props.from === "humanCases" ? (
                parseInt(props.total) >= parseInt(props.finalAmount) ? (
                  <label>{t("Donation completed")}</label>
                ) : (
                  <label>{t("AED")}</label>
                )
              ) : (
                <label
                  style={{
                    fontSize: "15px",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  {t("AED")}
                </label>
              )}
              {props.from === "humanCases" ? (
                parseInt(props.total) >= parseInt(props.finalAmount) ? null : (
                  <input
                    disabled={addingToCart}
                    className="translateFillMsg"
                    type="text"
                    pattern="[0-9]*"
                    onChange={(e) =>
                      setHumanCaseData({
                        ...humanCaseData,
                        donationAmountFromUser: e.target.validity.valid
                          ? e.target.value
                          : humanCaseData.donationAmountFromUser,
                      })
                    }
                    value={humanCaseData.donationAmountFromUser}
                    style={{
                      border: "none",
                      fontSize: "15px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      width: "114px",
                    }}
                  />
                )
              ) : (
                <input
                  disabled={addingToCart}
                  className="translateFillMsg"
                  type="text"
                  pattern="[0-9]*"
                  onChange={(e) =>
                    setDonationData({
                      ...donationData,
                      amount: e.target.validity.valid
                        ? e.target.value
                        : donationData.amount,
                    })
                  }
                  value={donationData.amount}
                  style={{
                    border: "none",
                    fontSize: "20px",
                    fontWeight: "bold",
                    width: width <= 350 ? "50px" : "114px",
                    paddingTop: "2px",
                    borderRadius: width <= 250 ? "10px" : "30px",
                  }}
                />
              )}
            </div>
          ) : (
            <label
              style={{
                padding: "5px 30px 5px 30px",
                borderRadius: width <= 250 ? "10px" : "30px",
                backgroundColor: "white",
                fontSize: "15px",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              {t("AED")}{" "}
              <span
                className="payment_numbers"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStyle: "none",
                }}
              >
                {donationData.amount}
              </span>
            </label>
          )}
          {props.from === "humanCases" ? null : (
            <button
              disabled={addingToCart}
              style={{ backgroundColor: "white", color: "black" }}
              className="sukukinc btn btn-light"
              onClick={() => handleClick("+")}
            >
              +
            </button>
          )}
        </div>
        <div className="popUp_btns">
          {window.ApplePaySession ? (
            <div
              id="applepay_btn"
              className="apple-pay-button-outside apple-pay-button-white"
              onClick={() =>
                handleGuestPopUp(() =>
                  startApplePay(
                    props.from === "humanCases"
                      ? {
                          humanCase: humanCaseData.humanId,

                          amount: humanCaseData.donationAmountFromUser,
                          humanYear: humanCaseData.caseYear,
                          item_type: 4,
                          dontype_id: 158,
                        }
                      : {
                          amount: donationData.amount,
                          cart: {
                            amount: donationData.amount,
                            donationId: donationData.donationId,

                            recurring: donationData.donationFreq,
                            item_type: 0,
                          },
                          item: donationData,
                        }
                  )
                )
              }
              style={{ width: "80%", height: "44px" }}
              locale="ar-AB"
            ></div>
          ) : (
            <button
              style={{
                width: "80%",
                backgroundColor: "white",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleGuestPopUp(startSamsungPay)}
              className="btn btn-block btn-dark apple_human_btn"
            >
              <img
                src={samPay}
                style={{
                  height: "44px",
                  width: width <= 250 ? "100%" : "auto",
                }}
                alt="samsung_pay"
              />
            </button>
          )}
          <div
            className="mobBtn_display_none"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              width: "80%",
            }}
          >
            <button
              disabled={addingToCart}
              style={
                lng === "arab"
                  ? { direction: "rtl", width: "100%" }
                  : { direction: "ltr", width: "100%" }
              }
              onClick={handleSMS}
              className="btn btn-block btn-outline-light popUP_outline"
            >
              <React.Fragment>
                <span style={{ width: "100%" }}>{t("Donate by SMS")}</span>
                <img
                  style={{
                    width: "12px",
                    marginRight: lng === "arab" ? "5px" : "0px",
                    marginLeft: lng === "arab" ? "5px" : "0px",
                  }}
                  src={SMSIcon}
                  alt="donate_btn_icon_banner"
                  className="donate_btn_icon_banner"
                />
              </React.Fragment>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "80%",
            }}
          >
            <button
              style={{ width: "100%" }}
              disabled={addingToCart}
              onClick={() =>
                handleaddToBag(
                  props.from === "humanCases"
                    ? props.total >= props.finalAmount
                      ? toastifyMessage(
                          t("This human Case is complete"),
                          undefined,
                          "error"
                        )
                      : humanCaseData
                    : donationData
                )
              }
              className="btn btn-block btn-outline-light popUP_outline"
            >
              {addingToCart ? (
                <Loading small={"30px"} height={"30px"} color={"white"} />
              ) : (
                <React.Fragment>
                  <span style={{ width: "100%" }}>{t("Add to Bag")}</span>
                  <img
                    src={donateIcon}
                    alt="donate_btn_icon_banner"
                    className="donate_btn_icon_banner"
                  />
                </React.Fragment>
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
      {/* {modalShow ? <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='donation_pop_up_body'
            >
                <Modal.Body style={{ backgroundColor: '#fdfdfd', borderRadius: '10px' }} >
                    <div className='modal_body_for_donation_final' style={lng === 'arab' ? { direction: 'rtl' } : null}>
                        <label>{t("Would you like to")} <span style={{ fontWeight: 'bold' }}>{t("Register")}</span> {t("for easier donations in the future?")}</label>
                        <div className='donation_final_popUp_btns_wraper'>
                            <button onClick={() => history.push('/guest')} className='donation_final_popUp_btn_first'>
                                {t("Not Now")}
                            </button>
                            <button onClick={() => history.push('/auth')} className='donation_final_popUp_btn_second'>
                                {t("Yes")}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> : null} */}
      <SmsModal
        show={smsModalShow}
        fromcamp="true"
        donationid={donationData.donationId}
        onHide={() => setSmsModalShow(false)}
      />
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </Modal>
  );
};

export default PopUpPayment;
