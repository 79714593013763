import React, { useEffect, useState, useRef } from "react";
import "./Renewals.css";
import LatePyamentPageItem from "../latePayment_page_Display/latePayment_page_comp/LatePyamentPageItem";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Renewals = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [spInfo, setSpInfo] = useState(props.renewal);

  let refElementR = useRef(null)
  useEffect(() => {
    if (refElementR != null) {
      props.setHeightAll(refElementR.current?.offsetHeight)
    }
  }, [refElementR, spInfo])
useEffect(()=> {
  setSpInfo(props.renewal)
}, [props.renewal])
  // useEffect(() => {
  //   if (props.props && props.props.pay_mthd == "1") {
  //     props.disablerenew(false)
  //     setSpInfo(props.props);
  //   } else if (props.disablelate) {
  //     // history.goBack()
  //   } else {
  //     props.disablerenew(true)
  //     props.handlenavi('late')
  //   }
    
  // }, [props.props]);
  return spInfo?.length>0 ? (
    <div className="renewals" ref={refElementR}>
      {spInfo.map((item,i) => 
      <LatePyamentPageItem
        key={i}
        name={item?.orphon?.orp_name}
        amount={item?.orphon?.trn_amount}
        Her={false}
        date={"2 months"}
        subTitle={item?.orphon?.trn_date}
        img={item?.orphon?.orp_pic}
        allItem={item?.orphon}
        data={item?.orphon}
        location={props.location}
      />
      )}
      
    </div>
  ) : (
    <div style={{  minHeight: '85vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p>{t("No renewals available for this orphans")}</p></div>
  );
};

export default Renewals;
