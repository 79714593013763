import {
  FETCH_VOLUNTEER_EVENT_DETAILS,
  LOAD_VOLUNTEER,
  VOLUNTEER_LOADED
} from '../../constants/actionTypes';
export default (
  vlnEventsDetails = { vlnEventsDetails: {}, loading: false },
  action
) => {
  switch (action.type) {
    case FETCH_VOLUNTEER_EVENT_DETAILS:
      return {
        ...vlnEventsDetails,
        vlnEventsDetails: action.payload,
        loading: false
      };
    case LOAD_VOLUNTEER:
      return { ...vlnEventsDetails, loading: true };
    case VOLUNTEER_LOADED:
      return { ...vlnEventsDetails, loading: false };
    default:
      return vlnEventsDetails;
  }
};
