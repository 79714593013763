import { GET_ALL_JOBS_NEW } from "../../constants/actionTypes";

export default (volunteerJobsNew = [], action) => {
  switch (action.type) {
    case GET_ALL_JOBS_NEW:
      return action.payload || [];
    default:
      return volunteerJobsNew || [];
  }
};
