import React from "react";
import Modal from "react-bootstrap/Modal";
import "./LoadingPopUp.css";

function LoadingModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popUpLoading"
    >
      <Modal.Body>
        <div className="Spinner">
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default LoadingModal;
