import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './DoneModal.css'
import { useTranslation } from "react-i18next";

function DoneModal(props) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;


  return (
    <Modal
      {...props}
      size="sm"
      centered
    >
      <Modal.Body style={{fontFamily: lng==='arab'? 'arab_font':'english_font'}}>
        <svg className='svg_right_sign' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle className="path circle_for_right" fill="none" stroke="#ee4236" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <polyline className="path check" fill="none" stroke="#ee4236" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
        </svg>
        <p  style={{fontFamily: lng==='arab'? 'arab_font':'english_font'}} className="success">{t("Done")}</p>
      </Modal.Body>
    </Modal>
  );
}
export default DoneModal;