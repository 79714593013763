import { FETCH_ALL_LANDING, LOAD_LANDING, LANDING_LOADED } from '../constants/actionTypes'
import * as api from '../api'


export const getLandingPages = (langNumber) => async (dispatch) => {
    dispatch({
        type: LOAD_LANDING
    });
    try {
        const { data } = await api.getLandingPages(langNumber);
        dispatch({ type: FETCH_ALL_LANDING, payload: data?.data })
        dispatch({
            type: LANDING_LOADED
        });
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({
            type: LANDING_LOADED
        });
    }
}