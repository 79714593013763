import { COUNTRIES_LOADED, FETCH_ALL_COUNTRIES_FOR_ORPHAN, LOAD_COUNTRIES } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getOrphans = (langNumber) => async (dispatch) => {
    dispatch({ type: LOAD_COUNTRIES });
    try {
        const { data } = await api.getOrphans(langNumber);
        dispatch({ type: FETCH_ALL_COUNTRIES_FOR_ORPHAN, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: COUNTRIES_LOADED });
    }
}