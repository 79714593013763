import React, { useState, useEffect } from "react";
import "./SponsershipsPage.css";
import SwitchSponsers from "./switchSponsershipsDisplay/SwitchSponsers";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {getSPFollowups, getSPOrphonsList, getSPAllLatepayments} from "../../actions/user";
import selectIcon from '../../assets/select-all.png'
import questionIcon from '../../assets/question_mark.svg'

import Loading from "../ui/loading";
import SelectMode from "./switchSponsershipsDisplay/selectMode/SelectMode";
import { SHOW_TUTORIAL } from "../../constants/actionTypes";

const SponsershipsPage = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));



  //get all sponsors...
  const myorphons = useSelector((state) => {return state.auth.myorphons;});
  const loading = useSelector((state) => {return state.auth.sponsorLoading;});
  const userInfo = useSelector((state) => {return state.auth.authData;});
  const sponID = useSelector((state) => {return state.auth.extraSponID;});


  const [noFull, setNoFull] = useState(true);
  const [noMonthly, setNoMonthly] = useState(true);
  const [noBank, setNoBank] = useState(true);
  const [display, setDisplay] = useState(0);
  const [orphonsListFull, setorphonsListFull] = useState([]);
  const [orphonsListMonthly, setorphonsListMonthly] = useState([]);
  const [orphonsListBank, setorphonsListBank] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [selectModeSwitch, setSelectModeSwitch] = useState(false);
  const [itemSelectedMode, setItemSelectedMode] = useState([]);

  useEffect(() => {
    if (sponserId()?.sponser_id) {
      dispatch(
        getSPOrphonsList(sponserId()?.sponser_id, lng === "arab" ? "ARA" : "ENG")
      );
    }
  }, []);
  useEffect(() => {
      setLoadingState(loading)
  }, [loading]);
  function getUserInfo() {
    if (userInfo?.Results && userInfo?.Results.length > 0 && userInfo?.Results[0]) {
      return userInfo?.Results[0];
    }
  }
  function sponserId() {
    if (sponID) return sponID;
    return getUserInfo();
  }
  useEffect(() => {
    setorphonsListFull(myorphons.filter(item=> item.pay_mthd==='1'));
    setorphonsListMonthly(myorphons.filter(item=> item.pay_mthd==='3'))
    setorphonsListBank(myorphons.filter(item=> item.pay_mthd==='2'))
  }, [myorphons]);
  const handleNavigation = (str) => {
    if (str === "spon") {
      setDisplay(0);
    } else if (str==='full') {
      setDisplay(1);
    } else if (str==='bank'){ 
      setDisplay(4);
    } 
    else { 
      setDisplay(3);
    } 
  };
  useEffect(() => {
    if (!userLogin) {
      history.push('/')
    }
    if (!loadingState ) {
        if (myorphons.filter(item=> item.pay_mthd==='1').length!==0) {
        setNoFull(false)
        handleNavigation('full')
      }  if ( myorphons.filter(item=> item.pay_mthd==='2').length!==0) {
        setNoBank(false)
        handleNavigation('bank')
      } if (myorphons.filter(item=> item.pay_mthd==='3').length!==0) {
        setNoMonthly(false)
        handleNavigation('spon')
      }
    }
  }, [myorphons, loadingState]);

    return (
      <div id="sponserships_page" style={{position: 'relative'}}>
        <ShareHeader pageName={t("Sponsorships")} noShare={true} />
        <div
          className="sponsership_page_navigation"
          style={{direction:lng === "arab" ? "rtl":null}}
        >
          {selectModeSwitch?
          <SelectMode setSelectModeSwitch={setSelectModeSwitch} setItemSelectedMode={setItemSelectedMode}
          orphonsListMonthly={orphonsListMonthly}
          orphonsListFull={orphonsListFull} display={display}/>:
          null
        }
          <button onClick={()=> {
            if(selectModeSwitch) 
            {
              setItemSelectedMode([]);
              setSelectModeSwitch(false);
            }
            else
              setSelectModeSwitch(true)
          }} className='selectButton'>
            <img src={selectIcon} alt='select' className='select_icon'/>
            <label>{t("Select")}</label>
          </button>
          <button onClick={()=>     dispatch({ type: SHOW_TUTORIAL, payload: "SPONSOR"})} className='toolTip'>
            <img src={questionIcon} alt='select' className='select_icon'/>
          </button>
          {!noMonthly?
          <button
          disabled={noMonthly}
            onClick={() => handleNavigation("spon")}
            id="navi_spon"
            className={display===0? "sponsership_navigation_item":'sponsership_navigation_item-disabled'}
            style={{color: display===0? '#ee4236':'rgb(87, 86, 86)', borderBottom: display===0? "5px solid #ee4236":'none'}}
          >
            {t("Monthly Payment")}
          </button>
          :
          null}
          {!noFull?
          <button
          disabled={noFull}
            onClick={() => handleNavigation("full")}
            id="navi_proj"
            className={display===1? "sponsership_navigation_item":'sponsership_navigation_item-disabled'}
            style={{color: display===1? '#ee4236':'rgb(87, 86, 86)', borderBottom: display===1? "5px solid #ee4236":'none'}}
          >
            {t("Full Payment")}
          </button>
          :
          null}
          {!noBank?
          <button
          disabled={noBank}
            onClick={() => handleNavigation("bank")}
            id="navi_proj"
            className={display===4? "sponsership_navigation_item":'sponsership_navigation_item-disabled'}
            style={{color: display===4? '#ee4236':'rgb(87, 86, 86)', borderBottom: display===4? "5px solid #ee4236":'none'}}
          >
            {t("Bank Payment")}
          </button>:null}

        </div>
        {loadingState?
        <Loading height='85vh'/>:
        <React.Fragment>
        {display === 0 ? 
          <SwitchSponsers itemSelectedMode={itemSelectedMode} setItemSelectedMode={setItemSelectedMode} selectModeSwitch={selectModeSwitch} orphonsList={orphonsListMonthly} myorphons={myorphons} loadingState={loadingState} type={"Monthly"} />
         : 
         display === 1 ?
         <SwitchSponsers itemSelectedMode={itemSelectedMode} setItemSelectedMode={setItemSelectedMode} selectModeSwitch={selectModeSwitch} orphonsList={orphonsListFull} myorphons={myorphons} loadingState={loadingState} type={"Full"}/>
         :
         display === 4 ?
          <SwitchSponsers itemSelectedMode={itemSelectedMode} setItemSelectedMode={setItemSelectedMode} selectModeSwitch={selectModeSwitch} orphonsList={orphonsListBank} myorphons={myorphons} loadingState={loadingState} type={"Bank"}/>
          :
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', minHeight:'85vh'}}>
              <h5>{t("You don't have any sponsors")}</h5>
          </div>
         }
        </React.Fragment>}

      </div>
    );
};

export default SponsershipsPage;
