import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import "react-spring-bottom-sheet/dist/style.css";

const EventBottomSheet = (props) => {
  return (
    <BottomSheet open={props.open} onDismiss={props.onHide} header={<div><h6>{props.weekday}</h6><h6>{props.day}</h6><h6>{props.month}</h6><h6>{props.event.title}</h6></div>}
        style={{fontFamily: 'english_font', direction: 'ltr'}}                      >
    
      <div  style={{display: "flex",justifyContent: "center",cursor: "pointer", textAlign: "center", padding: "20px 20px 20px 20px"}}>
          {props.event.description}
      </div>
      <div style={{ height: "100px" }}></div>
          </BottomSheet>
  )
}

export default EventBottomSheet