import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './AddingCardModal.css';
import PaymentMethod from '../../userPage/add_payment_method/AddPaymentMethod';
import { t } from 'i18next';

const AddCardModal = (props) => {
  const { handleregularpayment_inner, frompopup, nooption, ...rest } = props;
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const [lodaingSwitch, setLoadingSwitch] = useState(false);
  const handleclose = () => {
    props.onHide();
    window.location.reload();
  };
  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="payment_Modal"
    >
      <Modal.Body
        style={{ fontFamily: lng === 'arab' ? "'arab_font" : 'english_font' }}
      >
        <div
          className="complet_info_title"
          style={{
            backgroundColor: 'rgb(255 252 252)',
            width: '100%',
            display: 'flex',
            borderTopRightRadius: '15px',
            borderTopLeftRadius: '15px',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
            minHeight: '100px',
            direction: lng === 'arab' ? 'rtl' : 'ltr'
          }}
        >
          <h5 style={{ marginBottom: '0px' }}>{t('Add New Card')}</h5>
          <div
            onClick={handleclose}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ee4236',
              cursor: 'pointer'
            }}
          >
            <img
              src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"
              style={{ height: '15px', width: '15px' }}
              alt="close_btn"
            />
          </div>
        </div>
        {lodaingSwitch ? (
          <div className="Spinner">
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <PaymentMethod
            paymentamount={props.paymentamount}
            fromCompleteModal={true}
            frompopup={frompopup}
            lodaingSwitch={props.lodaingSwitch}
            noOption={nooption}
            handleRegularPayment_Inner={handleregularpayment_inner}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddCardModal;
