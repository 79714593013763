import { FETCH_ALL_ORPHAN_SLIDERS } from '../constants/actionTypes'
import * as api from '../api'
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getOrphanSliders = (langString) => async (dispatch) => {
    try {
        const { data } = await api.getOrphanSliders(langString);
        dispatch({ type: FETCH_ALL_ORPHAN_SLIDERS, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
    }
}