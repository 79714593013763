//React
import React, { useEffect, useState } from "react";

//React Router
import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";

//CSS
import "./OurLocations.css";

//Bootstrap

//Translation
import { useTranslation } from "react-i18next";

//Packages
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

//Assets
import marker from "../../assets/location.png";

//Actions
import { getAllMapLocations } from "../../actions/brachLocation";
import { Tab, Tabs } from "react-bootstrap";
import ShareHeader from "../LoaclComponents/shareHeader/ShareHeader";
import Loading from "../ui/loading";

const OurLocations = (props) => {
  const branchLocations = useSelector((state) => state.branchLocations);
  const [availableLocations, setAvailableLocations] = useState(branchLocations);

  const [active, setActive] = useState([true, false]);

  // useEffect(() => {
  //   if (isMobile) {
  //     var x = document.getElementsByClassName("nav-link");
  //     for (var doc in x) {
  //       if (typeof x[doc] == "object") {
  //         x[doc].style.color = "black";
  //       }
  //     }
  //     document.getElementById(active).style.color = "red";
  //   }
  // }, [active]);

  //To know whether screen is in mobile view or desktop view
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  useEffect(() => {
    let mounted = true
    const handler = (e) => {
      if (mounted) {
        setIsMobile(e.matches);
      }
    };
    window
      .matchMedia("(max-width: 900px)")
      .addEventListener("change", handler);
      return ()=> {
        mounted = false
      }
  }, []);

  const defaultProps = {
    center: {
      lat: 24.422139,
      lng: 54.445503,
    },
    zoom: 14,
  };
  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setAvailableLocations(branchLocations);
  }, [branchLocations]);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const MapView = () => {
    return (
      <div className="our_branches">
        {availableLocations.length > 0 ? (
          <Map
            google={props.google}
            style={mapStyles}
            zoom={defaultProps.zoom}
            initialCenter={defaultProps.center}
          >
            {availableLocations?.map((item, i) => {
              return (
                <Marker
                  id={item?.branch_id}
                  key={i}
                  title={item?.branch_name}
                  name={"Branch"}
                  position={{
                    lat: item?.branch_latiu,
                    lng: item?.branch_longtu,
                  }}
                />
              );
            })}
          </Map>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const ListView = () => {
    return (
      <div className="branch_info_grid">
        {availableLocations?.map((item, i) => {
          return <Branch key={i} branch={item} />;
        })}
      </div>
    );
  };

  const [key, setKey] = useState("map-view");
  return (
    <div className="ourLoactions">
      <ShareHeader noShare={true} pageName={t("Our Locations")} />
      <div className="submit_requ_header">
        <h3 style={{ color: "gray" }}>{t("Our Locations")}</h3>
      </div>
      <div style={{ width: '100%', paddingTop: '10px' }}>
        {isMobile ? (
          <Tabs id="controlled-tab-example" activeKey={key} transition={true} onSelect={(k) => { k == "map-view" ? setActive([true, false]) : setActive([false, true]); setKey(k); }}
            className="mb-3" style={{ marginTop: "20px", }} fill justify>
            <Tab id="map-view" eventKey="map-view" tabClassName={"customTabLocation"}
              title={<span style={{ display: "flex",fontSize: '12px', direction: lng === 'arab' ? 'rtl' : "ltr", justifyContent: "center", alignItems: "center", columnGap: "10px", color: active[0] == true ? "red" : "black" }}><i className="fas fa-map-marked-alt"></i>{t("Map View")}</span>}>
              <MapView />
            </Tab>
            <Tab
              id="list-view"
              eventKey="list-view"
              title={
                <span
                
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: '12px',
                    alignItems: "center",
                    columnGap: "10px",
                    color: active[1] == true ? "red" : "black",
                    direction: lng === 'arab' ? 'rtl' : "ltr"
                  }}
                >
                  <i className="fas fa-list"></i>
                  {t("List of Locations")}
                </span>
              }
            >
              <ListView />
            </Tab>
          </Tabs>
        ) : (
          <>
            <MapView />
            <ListView />
          </>
        )}
      </div>
    </div>
  );
  function Branch(props) {
    const branch = props.branch;
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
      <div
        className="branch_info"
        style={
          lng === "arab"
            ? {
              gridTemplateColumns: '40% 60%',
            }
            : { gridTemplateColumns: '60% 40%'}
        }
      >
        <div
          className="branch_card"
          style={
            lng === "arab"
              ? {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                width: 'auto', order: '1'
              }
              : { order: 'unset'}
          }
        >
          <div
            className="branch_info_title"
            style={{
              color: "black",
              width: 'auto',
              textAlign: lng==='arab'? 'right': 'left'
            }}
          >
            {branch.branch_name}
          </div>
          <div className="branch_info_subtitle" style={lng === 'arab' ? { textAlign: 'right' } : { textAlign: 'left' }}>{branch.branch_addr}</div>
        </div>
        <div
          className="branch_card branch_info_subtitle"
          style={{
            display: "flex",
            justifyContent: lng === "arab" ? 'flex-start' : "flex-end",
            width: 'auto'
            , alignItems: lng === "arab" ? "flex-start" : "flex-end"
          }}
        >
          <div className="get_direction_btn"
            style={{ flexDirection: lng === "arab" ? "row-reverse" : "row"}}
            onClick={() => {
              window.open(
                "https://maps.google.com?q=" +
                branch.branch_latiu +
                "," +
                branch.branch_longtu
              );
            }}
          >
            <span style={lng === 'arab' ? { textAlign: 'right' } : { textAlign: 'left' }}>{t("Get Direction")}</span>
            {lng === "arab" ? (
              <i style={{ color: "#ee4236" }} className="fas fa-angle-left"></i>
            ) : (
              <i style={{ color: "#ee4236" }} className="fas fa-angle-right"></i>
            )}
          </div>
        </div>
      </div>
    );
  }
};
const LoadingContainer = (props) => <Loading height='800px'/>;

export default GoogleApiWrapper({
  apiKey: "AIzaSyBQonMikYiu-cJ6VrDuPLIxuCPWP3gF7tY",
  LoadingContainer: LoadingContainer
})(OurLocations);
// export default OurLocations;
