import {
  FETCH_ALL_MAP_LOCATIONS,
  GET_ABOUT_US,
} from "../constants/actionTypes";
import * as api from "../api/branches";
import { toastifyMessage } from "../handlers/toastifyMessage";

export const getAllMapLocations = (langNumber) => async (dispatch) => {
  try {
    const { data } = await api.getAllBrancheLocations(langNumber);
    dispatch({ type: FETCH_ALL_MAP_LOCATIONS, payload: data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
export const getAboutUS = (langNumber) => async (dispatch) => {
  try {
    const { data } = await api.getAboutUs(langNumber);
    dispatch({ type: GET_ABOUT_US, payload: data.data });
  } catch (error) {
    //toastifyMessage("Something went wrong...!", undefined, "error");
  }
};
