/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./AllSukukPage.css";
import AllSukukCom from "./allSukukCom/AllSukukCom";
import sukukLogo from "../../assets/sukuklogo.png";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addToBag, deleteFromBag, handleAddToBag } from '../../actions/BagFav'
import ShareHeader from '../LoaclComponents/shareHeader/ShareHeader'
import Loading from '../ui/loading'
import { toastifyMessage } from "../../handlers/toastifyMessage";
import { addToFavorites, deleteFromFavorites } from '../../actions/BagFav'
import { FETCH_LOCALLY } from "../../constants/actionTypes";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
const AllSukukPage = () => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const sukuk = useSelector(state => state.sukuk.sukuk);
  const [sukukDisplay, setSukukDisplay] = useState([])
  const loading = useSelector(state => state.sukuk.loading);
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const userBag = useSelector(state => state.bag.bag)
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let neededSuk = query.get("sukId")

  useEffect(() => {
    if (neededSuk) {
      let sukWeNeedForDisplayFirst = sukuk?.filter(item=> item.dontypeID===neededSuk).slice(0).shift()
      let allSukukWithout = sukuk?.filter(item=> item.dontypeID!==neededSuk)
      allSukukWithout.unshift(sukWeNeedForDisplayFirst)
      setSukukDisplay(allSukukWithout)
    } else {
      setSukukDisplay(sukuk)
    }
  }, [sukuk])

  const dispatch = useDispatch()
  const [bagDetails, setBagDetails] = useState(userBag)

  useEffect(() => {
    setBagDetails(userBag)
  }, [userBag])
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])
  let currentProfile = JSON.parse(localStorage.getItem('profile'));
  
  const [change, setChange] = useState(0)
  const fetchLoaclly = useSelector(state => state.fetchLocallyState)
  useEffect(() => {
    currentProfile = JSON.parse(localStorage.getItem('profile'));
  }, [change, fetchLoaclly])

  const userFav = useSelector(state => state.favorites.favorites)
    const [favoritesDetails, setFavoritesDetails] = useState(userFav)
    const [addingToFav, setAddingToFav] = useState(false);
    useEffect(() => {
        setFavoritesDetails(userFav)
    }, [userFav])

  const handleLike = async (sukukID) => {
    if (!userLogin) {
        if (!currentProfile) {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              cart: {
                donations: [],
                sukuks: [],
                projects: [],
                humanCases: [],
                orphans: [],
              },
              liked: { donations: [], sukuks: [], projects: [], orphans: [] },
            })
          );
          currentProfile = JSON.parse(localStorage.getItem("profile"));
        }
        currentProfile.liked.sukuks.push(sukukID);
        const newProfile = {
          cart: currentProfile.cart,
          liked: currentProfile.liked,
        };
        localStorage.setItem("profile", JSON.stringify(newProfile));
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1);
        
    } else {
      await dispatch(addToFavorites(lng==='arab'? 1:2,{userEmail:userLogin.Results[0]?.email, dontypeID: sukukID.sukId, projectID:'', orptypeID:'', projectYear: '', countryID: ''}))
    }
  };
  const handleDisLike = async (sukukID) => {
    if (!userLogin) {
        let newProfile = {
          cart: currentProfile.cart,
          liked: {
            donations: currentProfile.liked.donations,
            sukuks: currentProfile.liked.sukuks.filter(
              (item) => item.sukId !== sukukID.sukId
            ),
            projects: currentProfile.liked.projects,
            orphans: currentProfile.liked.orphans,
          },
        };
        localStorage.setItem("profile", JSON.stringify(newProfile));
        await dispatch({type: FETCH_LOCALLY})
        setChange(change + 1);
        
      } else {
          let elementWeNeed = favoritesDetails?.sukuks?.filter(item => item.dontypeID === sukukID.sukId).slice(0, 1).shift()
          if (elementWeNeed) {
              await dispatch(deleteFromFavorites(lng==='arab'? 1:2, userLogin.Results[0]?.email, elementWeNeed.cart_serial))
          }
      }
  };

  const handleaddToBag = async (sukukID, setAddingToCart, setAnimationClass, setInTheBagText) => {
    setAddingToCart(true);
    await handleAddToBag("sukuks", sukukID, setChange, change, dispatch)
    if (_isMounted.current) {
      setAnimationClass("btn_with_text animate__animated animate__bounceOut")
        setTimeout(() => {
          if (_isMounted.current) {
            setAnimationClass("btn_with_text animate__animated animate__bounceIn")
          setAddingToCart(false);
          setInTheBagText("Add to Bag");
          }
          
          //setInTheBagText('Added')
        }, 1000);
         setTimeout(() => {
           if (_isMounted.current) {
             setAnimationClass("btn_with_text");
           }
          
          //setAnimationClass("btn_with_text animate__animated animate__bounceIn");
          
         }, 2000);
    }
        
  };
  // const handleRemoveFromBag = async (sukukID) => {
  //   if (!userLogin) {
  //     let newProfile = {
  //       cart: {
  //         donations: currentProfile.cart.donations,
  //         sukuks: currentProfile.cart.sukuks.filter(
  //           (item) => !(item.sukId === sukukID.sukId && parseInt(item.baseAmount) === parseInt(sukukID.amount) && item.donationFreq === sukukID.donationFreq)
  //         ),
  //         projects: currentProfile.cart.projects,
  //         humanCases: currentProfile.cart.humanCases,
  //         orphans: currentProfile.cart.orphans,
  //       },
  //       liked: currentProfile.liked,
  //     };
  //     localStorage.setItem("profile", JSON.stringify(newProfile));
  //     setChange(change + 1);
  //   } else {
  //     let elementWeNeed = bagDetails?.sukuks
  //       ?.filter((item) => item.dontypeID === sukukID.sukId && parseInt(item.baseAmount) === parseInt(sukukID.amount) && item.recurring === sukukID.donationFreq)
  //       .slice(0, 1)
  //       .shift();
  //     if (elementWeNeed) {
  //       await dispatch(
  //         deleteFromBag(
  //           userLogin.Results[0]?.email,
  //           elementWeNeed.cart_serial,
  //           lng === "eng" ? 2 : 1
  //         )
  //       );
  //     }
  //   }
  // };
    return (
      <div id='all_sukuk_page' style={lng === 'arab' ? { direction: 'rtl' } : null}>
        <ShareHeader noShare={true} pageName={t("Sukuk")} />
        <div id='ad_for_sukuk'>
          <img src={sukukLogo} alt='sukuk_logo' className='sukuk_logo' />
          <p style={{ flex: '2' }}>
            {t("The Emirates Red Crescent Authority, in cooperation with Abu Dhabi Islamic Bank, in launching the first initiative of its kind, Sukuk Al-Khair are sustainable humanitarian financial instruments, The Authority invsts it and takes advantage of the investment profits to serve various humanitarian programs.")}
          </p>
        </div>
        {loading?
        <Loading height='85vh' />:
        <div className='all_sukuk_page_display'>
          {sukukDisplay?.map(item =>
            <AllSukukCom neededsuk={neededSuk} recurring={item?.recurring} handleaddToBag={handleaddToBag} handleLike={handleLike} handleDisLike={handleDisLike} currentProfile={currentProfile} donationID={item?.dontypeID} key={item?.dontypeID} title={item?.dontypeName} sukukImg={item?.imagePath} amount={item?.amount} />
          )}
        </div>
      }
        
      </div>
    )
  }

export default AllSukukPage;
