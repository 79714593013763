import React, { useState } from 'react';
import './ProfileBtn.css';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import PendingModal from '../pendingModal/PendingModal';

const ProfileBtn = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem('language'))?.lang;
  const history = useHistory();

  return (
    <button
      onClick={() =>
        props.logoutMaan
          ? props.logoutMaan()
          : props.goTo === 'pendingFromLA'
          ? [
              props.setAccountType('request for creating Local Aid account, '),
              props.setPendingModalShow(true)
            ]
          : props.goTo === 'pendingfromVln'
          ? [
              props.setAccountType(
                'request for creating Volunteer Service account, '
              ),
              props.setPendingModalShow(true)
            ]
          : props.goTo === 'goWithoutPending'
          ? history.push('/localAid/setup-information')
          : props.goTo === 'needToRegisterFromVln'
          ? [props.setToBeTrue(false), props.setNeedToRegisterModal(true)]
          : props.goTo === 'needToRegisterFromLocalAid'
          ? [props.setToBeTrue(true), props.setNeedToRegisterModal(true)]
          : props.goTo.slice(0, 4) === 'need'
          ? history.push('/volunteer/vln-registration', {
              stepNo: parseInt(props.goTo.slice(4))
            })
          : props.goTo === 'social_media'
          ? props.setSocialOpen(true)
          : props.goTo === 'onclick'
          ? props.onTap()
          : history.push(props.goTo)
      }
      className="profile_btn"
    >
      <div className="profile_page_sponsership" style={{ cursor: 'pointer' }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            gap: '10px',
            alignSelf: 'flex-start'
          }}
          className="profile_btn_content"
        >
          {props.icon === 'none' ? null : (
            <img
              style={{ opacity: props.opacity ? '0.6' : null }}
              src={props.icon}
              className="profile_button_icon"
              alt="sponsership"
            />
          )}

          <div
            className="user_items"
            style={{ borderBottom: props.last_child ? 'none' : null }}
          >
            <label
              className={
                props.email && !props.last_child ? 'payment_numbers' : null
              }
              style={{
                color: props.email ? '#ee4236' : '#000',
                minHeight: '55px',
                cursor: 'pointer',
                textAlign: lng === 'arab' ? 'right' : 'left'
              }}
            >
              {props.title}
            </label>
          </div>
        </div>
        {lng === 'arab' ? (
          <i
            style={{ color: '#ee4236', cursor: 'pointer' }}
            className="fas fa-angle-left sponserArrow"
          ></i>
        ) : (
          <i
            style={{ color: '#ee4236', cursor: 'pointer' }}
            className="fas fa-angle-right sponserArrow"
          ></i>
        )}
      </div>
    </button>
  );
};

export default ProfileBtn;
